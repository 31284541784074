import React, { Component, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Image from "react-bootstrap/Image";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage, saveToLocalStorage } from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { Switch } from "@mui/material";
import CalendarView from "./CalendarView";
import useFecth from "../../hook/useFecth";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

// class VendorEOI extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       operator_id: null,
//       isCalendarView: false,
//       isLoading: false,
//       tempMenuDetails: [],
//       showVendorMenuAddScreen: false,
//       showVendorMenuEditScreen: false,
//       mealProp: [],
//       menuDetails: [],
//       isLoading: false,
//       selectedBrandMeals: [],
//       tempSelectedBrandMeals: [],
//       selectedVendorId: -1,
//       selectedVendorName: "",
//     };
//     this.abortController = new AbortController();
//   }

//   componentDidMount() {
//     this.getMenu();
//   }
//   componentWillUnmount() {
//     this.abortController.abort();
//   }

//   getMenu() {
//     this.setState({ isLoading: true });
//     var url = config.BASE_URI + apiPaths.adminData;
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       payload: {
//         body: {
//           query_type: "fetch_menu_for_operator_stalls",
//           operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
//         },
//       },
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//       signal: this.abortController.signal,
//     };

//     fetch(url, requestOptions)
//       .then((response) => response.json())
//       .then((dataResponse) => {
//         if (!this.abortController.signal.aborted) {
//           this.setState({ isLoading: false });
//           if (dataResponse.success) {
//             this.setState({
//               menuDetails: dataResponse.menu_details,
//               tempMenuDetails: dataResponse.menu_details,
//             });
//           }
//         }
//       })
//       .catch((error) => {
//         Sentry.captureException(error);
//         console.error(error);
//         if (!this.abortController.signal.aborted) {
//           this.setState({ isLoading: false });
//         }
//       });
//   }
//   render() {
//     const {
//       menuDetails,
//       isLoading,
//       selectedVendorId,
//       isCalendarView,
//     } = this.state;
//     const { eventsList } = this.props;

//     return (
//       <div>
//         <Container
//           maxWidth={!isCalendarView ? 1110 : 2000}
//           style={{
//             borderWidth: 1,
//             borderColor: "grey",
//             borderStyle: "solid",
//             borderRadius: 5,
//             padding: 15,
//           }}
//         >
//           <div>
//             Show Calendar
//             <Switch
//               checked={isCalendarView}
//               onChange={(e) => {
//                 this.setState({
//                   isCalendarView: e.target.checked,
//                 });
//               }}
//             />
//           </div>
//           {isLoading == false ? (
//             <>
//               <Row>
//                 <Col
//                   xs={12}
//                   md={3}
//                   lg={3}
//                   style={{
//                     backgroundColor: "#f1f1f1",
//                     borderRightWidth: 1,
//                     borderLeftWidth: 0,
//                     borderTopWidth: 0,
//                     borderBottomWidth: 0,
//                     borderStyle: "solid",
//                     borderColor: "black",
//                     padding: 0,
//                   }}
//                 >
//                   <div>
//                     <h3
//                       style={{
//                         padding: 20,
//                         borderBottomWidth: 1,
//                         borderTopWidth: 1,
//                         borderLeftWidth: 0,
//                         borderRightWidth: 0,
//                         borderColor: "black",
//                         borderStyle: "solid",
//                       }}
//                     >
//                       Trucks
//                     </h3>
//                   </div>
//                   {menuDetails &&
//                     menuDetails.map((item) => {
//                       return (
//                         <div
//                           key={item.stalls__id}
//                           style={
//                             selectedVendorId == item.stalls__id
//                               ? {
//                                   backgroundColor: "white",
//                                   cursor: "pointer",
//                                   padding: 20,
//                                   borderBottomWidth: 1,
//                                   borderTopWidth: 0,
//                                   borderLeftWidth: 0,
//                                   borderRightWidth: 0,
//                                   borderColor: "black",
//                                   borderStyle: "solid",
//                                 }
//                               : {
//                                   cursor: "pointer",
//                                   padding: 20,
//                                   borderBottomWidth: 1,
//                                   borderTopWidth: 0,
//                                   borderLeftWidth: 0,
//                                   borderRightWidth: 0,
//                                   borderColor: "black",
//                                   borderStyle: "solid",
//                                 }
//                           }
//                           onClick={() => {
//                             this.setState(
//                               {
//                                 selectedBrandMeals: item.meals,
//                                 tempSelectedBrandMeals: item.meals,
//                                 selectedVendorId: item.stalls__id,
//                                 selectedVendorName: item.title,
//                                 showVendorMenuAddScreen: false,
//                                 showVendorMenuEditScreen: false,
//                               },
//                               () =>
//                                 saveToLocalStorage(
//                                   "selectedVendorId",
//                                   item.stalls__id
//                                 )
//                             );
//                           }}
//                         >
//                           {item.stalls__title}
//                         </div>
//                       );
//                     })}
//                 </Col>
//                 <Col xs={12} md={9} lg={9} className="mobile-padding">
//                   {isCalendarView ? (
//                     <>
//                       {eventsList && eventsList.length > 0 ? (
//                         <CalendarView
//                           eventsList={eventsList
//                             .filter((event) =>
//                               event.vendor
//                                 ?.map((vendor) => vendor.id)
//                                 .find((id) => id == selectedVendorId)
//                             )
//                             .map((event) => ({
//                               ...event.eventDetails,
//                               eoi_stat: event.vendor.find(
//                                 (v) => v.id == selectedVendorId
//                               )?.eoi_stat,
//                             }))}
//                         />
//                       ) : (
//                         <div>No results</div>
//                       )}
//                     </>
//                   ) : (
//                     <>
//                       {eventsList && eventsList.length > 0 ? (
//                         <Row>
//                           {eventsList.map((event, i) => {
//                             const vendorIds =
//                               event && event.vendor
//                                 ? event.vendor.map((vendor) => vendor.id)
//                                 : [];
//                             const hasVendor = vendorIds.includes(
//                               this.state.selectedVendorId
//                             );
//                             if (hasVendor) {
//                               return (
//                                 <Col xs={12} md={12} lg={12}>
//                                   <Media
//                                     key={i}
//                                     style={{
//                                       padding: 10,
//                                       borderBottomWidth: 1,
//                                       borderTopWidth: 0,
//                                       borderLeftWidth: 0,
//                                       borderRightWidth: 0,
//                                       borderColor: "black",
//                                       borderStyle: "solid",
//                                     }}
//                                   >
//                                     <Image
//                                       style={{ width: 120 }}
//                                       src={
//                                         event.eventDetails.eoi_cover &&
//                                         event.eventDetails.eoi_cover != "" &&
//                                         event.eventDetails.eoi_cover != null &&
//                                         event.eventDetails.eoi_cover != "NULL"
//                                           ? AWS_URL +
//                                             "/" +
//                                             event.eventDetails.eoi_cover
//                                           : require("../../assets/img/empty_logo.png")
//                                       }
//                                     ></Image>
//                                     <Media.Body style={{ marginLeft: 10 }}>
//                                       <Row>
//                                         <Col xs={12} md={6} lg={6}>
//                                           <h5>
//                                             {event.eventDetails.eoi_event_name}
//                                           </h5>
//                                         </Col>
//                                       </Row>
//                                     </Media.Body>
//                                   </Media>
//                                 </Col>
//                               );
//                             }
//                           })}
//                         </Row>
//                       ) : (
//                         <div>No results</div>
//                       )}
//                     </>
//                   )}
//                 </Col>
//               </Row>
//             </>
//           ) : (
//             <p>Loading...</p>
//           )}
//         </Container>
//       </div>
//     );
//   }
//   onSearch = (e) => {
//     let menuItems = this.state.tempSelectedBrandMeals;
//     menuItems = menuItems.filter((el) => {
//       return el.meal_name.search(e.target.value) != -1;
//     });
//     this.setState({ selectedBrandMeals: menuItems });
//   };
// }

const VendorEOI = () => {
  const abortController = new AbortController();

  const { events, vendors } = useFecth("Operator");

  const [isLoading, setIsLoading] = useState(false);
  const [menuDetails, setMenuDetails] = useState([]);
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(-1);
  const [eventsList, setEventsList] = useState([]);

  const getAppliedEvents = () => {
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_eoi_stall_applications_for_operator",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          const eventList = [];
          const appliedEventList = [];
          events.map((eoi) => {
            const newEvent = {
              eventDetails: eoi,
              vendor: [],
            };
            const oldEvent = {
              eventDetails: eoi,
              vendor: [],
            };
            dataResponse.data.forEach((appliedEvent) => {
              if (appliedEvent.eoi_id === eoi.id) {
                if (appliedEvent.vendor_outlets.length > 0) {
                  console.log(appliedEvent.vendor_outlets);
                  appliedEvent.vendor_outlets.map((element) => {
                    const matchingVendor = vendors.find(
                      (vendor) => vendor.id === element
                    );
                    if (matchingVendor) {
                      const updatedVendor = {
                        ...matchingVendor,
                        eoi_stat: "pending",
                      };
                      newEvent.vendor.push(updatedVendor);
                    }
                  });
                }
                if (appliedEvent.accepted_outlets.length > 0) {
                  appliedEvent.accepted_outlets.map((element) => {
                    const matchingVendor = vendors.find(
                      (vendor) => vendor.id === element
                    );
                    if (matchingVendor) {
                      const updatedVendor = {
                        ...matchingVendor,
                        eoi_stat: "accepted",
                      };
                      newEvent.vendor.push(updatedVendor);
                    }
                  });
                }
                if (appliedEvent.rejected_outlets.length > 0) {
                  appliedEvent.rejected_outlets.map((element) => {
                    const matchingVendor = vendors.find(
                      (vendor) => vendor.id === element
                    );
                    if (matchingVendor) {
                      const updatedVendor = {
                        ...matchingVendor,
                        eoi_stat: "rejected",
                      };
                      newEvent.vendor.push(updatedVendor);
                    }
                  });
                }
              }
            });
            eventList.push(oldEvent);
            appliedEventList.push(newEvent);
          });
          setEventsList([...eventList]);
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  useEffect(() => {
    getMenu();
    if (events && events.length) getAppliedEvents();

    return () => {
      abortController.abort();
    };
  }, [events]);

  const getMenu = () => {
    setIsLoading(true);
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_menu_for_operator_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
          if (dataResponse.success) {
            setMenuDetails(dataResponse.menu_details);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  return (
    <div>
      <VendorNavBar />
      <Container
        maxWidth={!isCalendarView ? 1110 : 2000}
        style={{
          borderWidth: 1,
          borderColor: "grey",
          borderStyle: "solid",
          borderRadius: 5,
          padding: 15,
        }}
      >
        <div>
          Show Calendar
          <Switch
            checked={isCalendarView}
            onChange={(e) => {
              setIsCalendarView(e.target.checked);
            }}
          />
        </div>
        {isLoading == false ? (
          <>
            <Row>
              <Col
                xs={12}
                md={3}
                lg={3}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderStyle: "solid",
                  borderColor: "black",
                  padding: 0,
                }}
              >
                <div>
                  <h3
                    style={{
                      padding: 20,
                      borderBottomWidth: 1,
                      borderTopWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                  >
                    Trucks
                  </h3>
                </div>
                {menuDetails &&
                  menuDetails.map((item) => {
                    return (
                      <div
                        key={item.stalls__id}
                        style={
                          selectedVendorId == item.stalls__id
                            ? {
                                backgroundColor: "white",
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                            : {
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                        }
                        onClick={() => {
                          setSelectedVendorId(item.stalls__id);
                        }}
                      >
                        {item.stalls__title}
                      </div>
                    );
                  })}
              </Col>
              <Col xs={12} md={9} lg={9} className="mobile-padding">
                {isCalendarView ? (
                  <>
                    {eventsList && eventsList.length > 0 ? (
                      <CalendarView
                        eventsList={eventsList
                          .filter((event) =>
                            event.vendor
                              ?.map((vendor) => vendor.id)
                              .find((id) => id == selectedVendorId)
                          )
                          .map((event) => ({
                            ...event.eventDetails,
                            eoi_stat: event.vendor.find(
                              (v) => v.id == selectedVendorId
                            )?.eoi_stat,
                          }))}
                      />
                    ) : (
                      <div>No results</div>
                    )}
                  </>
                ) : (
                  <>
                    {eventsList && eventsList.length > 0 ? (
                      <Row>
                        {eventsList.map((event, i) => {
                          const vendorIds =
                            event && event.vendor
                              ? event.vendor.map((vendor) => vendor.id)
                              : [];
                          const hasVendor = vendorIds.includes(
                            selectedVendorId
                          );
                          if (hasVendor) {
                            return (
                              <Col xs={12} md={12} lg={12}>
                                <Media
                                  key={i}
                                  style={{
                                    padding: 10,
                                    borderBottomWidth: 1,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderColor: "black",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <Image
                                    style={{ width: 120 }}
                                    src={
                                      event.eventDetails.eoi_cover &&
                                      event.eventDetails.eoi_cover != "" &&
                                      event.eventDetails.eoi_cover != null &&
                                      event.eventDetails.eoi_cover != "NULL"
                                        ? AWS_URL +
                                          "/" +
                                          event.eventDetails.eoi_cover
                                        : require("../../assets/img/empty_logo.png")
                                    }
                                  ></Image>
                                  <Media.Body style={{ marginLeft: 10 }}>
                                    <Row>
                                      <Col xs={12} md={6} lg={6}>
                                        <h5>
                                          {event.eventDetails.eoi_event_name}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Media.Body>
                                </Media>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    ) : (
                      <div>No results</div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Container>
    </div>
  );
};

export default Sentry.withErrorBoundary(VendorEOI, {
  fallback: <ErrorFallbackComponent />,
});
