import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import api from "../../services/api";

import config from "../../services/apiConfig";
import moment from "moment";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/UI/Button/index";
import {
  sendSms,
  loginPhone,
  getUserProfile,
  updateCartTicket,
  updateCartTotal,
  updateOrderType,
  updateOrderLater,
  updateOrderLocation,
  updateEventId,
  changeTab,
  calculateDelivery,
  getDelivery,
  updateDeliveryCharges,
  orderStatus,
  getPromoCode,
  applyPromoCode,
  updateOrderDate,
  updateOrderTime,
  validatePromoCode,
  updateSelectedVendor,
  clearSelectedVendor,
  logout,
  updateUser,
  updateCartProducts,
} from "../../actions";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { applyToken } from "../../services/api";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { hasEventExpired } from "../../helper";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class EventScreen extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isQuantityPickerModalOpen: false,
    quantityPicker: 0,
    eventDetails: {},
    isOpenFoodSuggestionModal: false,
    isOpenLoginModal: false,
    buttonPressed: false,
    code: "",
    phone: "",
    ticketState: {},
    productsTotalState: 0.0,
    isOpenProfileModal: false,
  };
  componentDidMount() {
    const { appReducer } = store.getState();
    const { eventTicket, productsTotal } = appReducer;
    const { ticketState, productsTotalState } = this.state;
    // this.props.dispatch(clearSelectedVendor())
    this.getEventDetails();
    if (this.isEmpty(ticketState)) {
      this.setState({ ticketState: eventTicket }, () => {});
    }
    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal }, () => {});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appReducer } = store.getState();
    const { eventTicket } = appReducer;
    const { dispatch } = this.props;

    if (this.state.ticketState !== eventTicket) {
      dispatch(updateCartTicket(this.state.ticketState));
      // dispatch(updateCartTotal(this.calculateTotalPrice()))
    }
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  emptyCart = (redirect, vendor, eventDetails) => {
    const { appReducer } = store.getState();
    const { eventTicket } = appReducer;
    const { dispatch } = this.props;
    this.setState({ ticketState: {}, productsTotalState: 0 }, () => {
      dispatch(updateCartTicket({}));
      dispatch(updateCartTotal(0.0));
      dispatch(updateCartProducts({}));
      this.setState({ isOpenFoodSuggestionModal: false });

      this.props.dispatch(clearSelectedVendor());
      if (redirect) {
        saveToLocalStorage("selectedEvent", JSON.stringify(eventDetails));
        this.props.dispatch(
          updateSelectedVendor({
            vendorData: vendor,
            selectedVendor: vendor.id,
          })
        );
        window.location.href =
          "/event-menu/?vendorId=" + vendor.id + "&eventId=" + eventDetails.id;
      }
    });
  };

  getEventDetails = () => {
    const { appReducer } = store.getState();
    const { products } = appReducer;
    var eventId = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    var url = config.BASE_URI + apiPaths.customerEvents + eventId + "/";

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((eventDetailsResponse) => {
        this.setState({ eventDetails: eventDetailsResponse });
        saveToLocalStorage(
          "selectedEvent",
          JSON.stringify(eventDetailsResponse)
        );
        saveToLocalStorage("serviceFee", eventDetailsResponse.service_fee);
        if (this.isEmpty(products)) {
          if (
            eventDetailsResponse &&
            eventDetailsResponse.vendor &&
            eventDetailsResponse.vendor.length > 0
          ) {
            this.props.dispatch(
              updateSelectedVendor({
                vendorData: eventDetailsResponse.vendor[0],
                selectedVendor: eventDetailsResponse.vendor[0].id,
              })
            );
          }
        }
      });
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var url = config.BASE_URI + apiPaths.customerEvents;
    // var eventId = window.location.href.substring(window.location.href.lastIndexOf(":") + 1)
    // var raw = JSON.stringify(
    //     {
    //         "payload":
    //         {
    //             "body":
    //             {
    //                 "query_type": 'specific_event',
    //                 "event_id":eventId,

    //             }
    //         }
    //     });

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };

    // fetch(url, requestOptions)
    //     .then(response => response.json())
    //     .then(response => {
    //         if (response.success == true) {
    //             this.setState({ eventDetails: response.event_details })
    //         }
    //     })
  };

  toggleAddToCartModal = () => {
    this.state.isQuantityPickerModalOpen
      ? this.setState({ isQuantityPickerModalOpen: false })
      : this.setState({ isQuantityPickerModalOpen: true });
  };

  toggleFoodSuggestionModal = () => {
    this.state.isOpenFoodSuggestionModal
      ? this.setState({ isOpenFoodSuggestionModal: false })
      : this.setState({ isOpenFoodSuggestionModal: true });
  };

  calculateTotalPrice = () => {
    const { eventDetails, quantityPicker, ticketState } = this.state;
    const { appReducer } = store.getState();
    const { product, productsTotal } = appReducer;
    var ticket = Object.values(ticketState)[0];
    if (ticket && ticket.quantity) {
      return (
        Number(Number(eventDetails.ticket_price) * Number(ticket.quantity)) +
        Number(productsTotal)
      );
    } else {
      return (
        Number(Number(eventDetails.ticket_price) * Number(quantityPicker)) +
        Number(productsTotal)
      );
    }
  };

  addProduct = (key, product, currency) => {
    const { eventDetails } = this.state;
    saveToLocalStorage("selectedEvent", JSON.stringify(eventDetails));
    const { ticketState } = this.state;
    var no_products_in_cart = Object.values(ticketState);
    if (no_products_in_cart.length == 0) {
      void this.setState(
        ({
          ticketState: {
            [key]: cartProduct = { quantity: 0, ticketPrice: 0 },
            ...restOfProducts
          },
        }) => ({
          ticketState: {
            ...restOfProducts,
            [key]: {
              ...product,
              quantity: product.quantity + cartProduct.quantity,
              ticketPrice: eventDetails.ticket_price,
            },
          },
        }),
        () => {}
      );
    } else {
      for (var i = 0; i < no_products_in_cart.length; i++) {
        var value = no_products_in_cart[i];

        var match = false;
        var matchKey = -1;
        if (value.id == product.id) {
          match = true;
          matchKey = Object.keys(this.state.ticketState)[i];
          break;
        }
      }
      if (match) {
        // same product. Increase quanity
        void this.setState(
          ({
            ticketState: {
              [matchKey]: cartProduct = { quantity: 0, ticketPrice: 0 },
              ...restOfProducts
            },
          }) => ({
            ticketState: {
              ...restOfProducts,
              [matchKey]: {
                ...product,
                quantity: product.quantity + cartProduct.quantity,
                ticketPrice: eventDetails.ticket_price,
              },
            },
          }),
          () => {}
        );
      } else {
        var key = product.id + "_" + Date.now();
        this.setState(
          { ticketState: { ...this.state.ticketState, [key]: product } },
          () => {}
        );
      }
    }
  };
  render() {
    const { appReducer } = store.getState();
    const { products } = appReducer;
    const { eventDetails, ticketState } = this.state;
    var startDate = null;
    var endDate = null;
    const { loggedIn, username, userId, dispatch } = this.props;
    const iconButtonStyle = {
      backgroundColor: theme.colors.backgroundColor,
      fontSize: 40,
      padding: 8,
      borderRadius: 20,
      color: theme.colors.iconColor,
      marginLeft: 10,
      marginRight: 10,
    };

    try {
      startDate = moment(eventDetails.start_date, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      endDate = moment(eventDetails.end_date, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
    } catch (err) {}

    return (
      <div>
        <ResponsiveNavBar
          showBack={true}
          backUrl={"/"}
          ticketState={ticketState}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        <Container>
          {eventDetails.detail != "Not found." ? (
            <>
              <Row style={{ marginTop: 10 }}>
                <Col lg={8}>
                  <Carousel controls={false}>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={
                          eventDetails.cover &&
                          eventDetails.cover != null &&
                          eventDetails.cover != ""
                            ? eventDetails.cover
                            : require("../../assets/img/default_cover.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col lg={12}>
                      <h4 style={{ paddingTop: 20 }}>{startDate}</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <h4>{eventDetails.name}</h4>
                    </Col>
                  </Row>
                  <div className="mobile-only">
                    <Row>
                      <Col lg={12}>
                        <p style={{ marginBottom: 0 }}>
                          {startDate} - {endDate}
                        </p>
                        <p>
                          {eventDetails.start_time +
                            " - " +
                            eventDetails.end_time}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <p>{eventDetails.location}</p>
                      </Col>
                    </Row>
                  </div>

                  <Row className="marginTop80">
                    {eventDetails &&
                    eventDetails.is_selling_ticket &&
                    !hasEventExpired(eventDetails, "tickets") ? (
                      <Col lg={12}>
                        <Button
                          onClick={() => {
                            this.toggleAddToCartModal();
                          }}
                          block
                          className="button-enabled-pink-style"
                          style={{ marginTop: 20 }}
                        >
                          {this.isEmpty(ticketState)
                            ? "Book Tickets Now"
                            : "Add More Tickets"}
                        </Button>
                      </Col>
                    ) : null}
                    {eventDetails &&
                    eventDetails.event_status != "listing_only" ? (
                      <>
                        {eventDetails &&
                        eventDetails.is_selling_ticket &&
                        hasEventExpired(eventDetails, "tickets") ? (
                          <Col lg={12}>
                            <Button
                              block
                              disabled
                              className="button-enabled-pink-style"
                              style={{ marginTop: 20 }}
                            >
                              Book Tickets Now (Closed)
                            </Button>
                          </Col>
                        ) : null}
                        {console.log(eventDetails)}
                        {console.log(hasEventExpired(eventDetails, "food"))}
                        {eventDetails &&
                        eventDetails.vendor &&
                        eventDetails.vendor.length > 0 &&
                        !eventDetails.ordering_closed &&
                        !hasEventExpired(eventDetails, "food") ? (
                          <Col lg={12}>
                            <Button
                              onClick={() => {
                                this.toggleFoodSuggestionModal();
                              }}
                              block
                              className="button-enabled-pink-style"
                              style={{ marginTop: 20 }}
                            >
                              Pre Order Food
                            </Button>
                          </Col>
                        ) : (
                          <Col lg={12}>
                            <Button
                              onClick={() => {}}
                              block
                              disabled
                              className="button-enabled-pink-style"
                              style={{ marginTop: 20 }}
                            >
                              Ordering Window Closed
                            </Button>
                          </Col>
                        )}
                      </>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col lg={8}>
                  <h5>About this event</h5>
                  {eventDetails.description &&
                    eventDetails.description
                      .split("\n")
                      .map((str) => <p>{str}</p>)}
                  {eventDetails &&
                  eventDetails.vendor &&
                  eventDetails.vendor.length > 0 &&
                  !eventDetails.ordering_closed &&
                  !hasEventExpired(eventDetails, "food") ? (
                    <div style={{ display: "block" }}>
                      <h5>Participating Trucks</h5>
                      {eventDetails.vendor.map((vendor, vendorIndex) => {
                        return (
                          <Media className={"card-effect"}>
                            <img
                              alt={vendor.title}
                              height={150}
                              width={150}
                              className="mr-3"
                              src={
                                vendor.logo &&
                                vendor.logo != null &&
                                vendor.logo != ""
                                  ? vendor.logo
                                  : require("../../assets/img/empty_logo.png")
                              }
                              style={{ overflow: "hidden" }}
                            />
                            <Media.Body>
                              <h6 style={{ marginTop: 15, textAlign: "left" }}>
                                {vendor.title}
                              </h6>
                              {vendor.kitchens &&
                                vendor.kitchens.map((kitchen) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <img
                                        src={require("../../assets/img/icon-cuisine.png")}
                                        height={30}
                                        width={30}
                                        style={{ flex: 1 }}
                                      />
                                      <p style={{ flex: 18, marginLeft: 10 }}>
                                        {kitchen.title}
                                      </p>
                                    </div>
                                  );
                                })}
                            </Media.Body>
                          </Media>
                        );
                      })}
                    </div>
                  ) : null}
                </Col>
                <Col lg={4} className="desktop-only">
                  <Row>
                    <Col lg={12}>
                      <h5>Date and Time</h5>
                    </Col>
                    <Col lg={12}>
                      <p style={{ marginBottom: 0 }}>
                        {startDate} - {endDate}
                      </p>
                      <p>
                        {eventDetails.start_time +
                          " - " +
                          eventDetails.end_time}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <h5>Location</h5>
                    </Col>
                    <Col lg={12}>
                      <p>{eventDetails.location}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.renderQuantityPickerModal()}
              {this.renderFoodSuggestionModal()}
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <h6>Not Found</h6>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }

  renderQuantityPickerModal = () => {
    const {
      isQuantityPickerModalOpen,
      quantityPicker,
      eventDetails,
      ticketState,
    } = this.state;
    const selectedItem = {
      name: "Event Ticket",
      description: eventDetails.description,
      ticket_img: eventDetails.ticket_img,
      ticket_description: eventDetails.ticket_description,
    };
    return (
      <Modal
        show={isQuantityPickerModalOpen}
        onHide={this.toggleAddToCartModal.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", width: "90%", margin: "auto" }}
        >
          <Card style={{ height: 500, marginTop: 20, overflowY: "scroll" }}>
            {!this.isEmpty(ticketState) ? (
              <Card.Text style={{ padding: 10 }}>
                You already have 1 or more tickets in your cart. You can add
                more by clicing the button below
              </Card.Text>
            ) : null}
            <div style={{ minHeight: 250, maxHeight: 250, overflow: "hidden" }}>
              <Card.Img
                onError={this.addDefaultSrc}
                className="align-middle"
                src={
                  selectedItem.ticket_img
                    ? selectedItem.ticket_img
                    : require("../../assets/img/default_ticket.jpg")
                }
              />
            </div>
            <Card.Body variant="bottom">
              <Card.Title>{selectedItem.name}</Card.Title>
              <Card.Text>{selectedItem.ticket_description}</Card.Text>
              <Row style={{ marginBottom: 10 }}>
                <Col
                  className="text-center"
                  xs={3}
                  onClick={() => {
                    this.setState((prevState, props) => ({
                      quantityPicker: prevState.quantityPicker - 1,
                    }));
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: theme.colors.borderColor,
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    -
                  </div>
                </Col>
                <Col xs={6}>
                  {" "}
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    {this.state.quantityPicker}
                  </div>{" "}
                </Col>
                <Col
                  className="text-center"
                  xs={3}
                  onClick={() => {
                    this.setState((prevState, props) => ({
                      quantityPicker: prevState.quantityPicker + 1,
                    }));
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: theme.colors.borderColor,
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    +
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              const startDate = moment(
                eventDetails.start_date,
                "DD MMM YYYY"
              ).format("DD MMM YYYY");
              const eventType = "5";
              const productId = eventType + eventDetails.id + startDate;
              const product = {
                name: selectedItem.name,
                id: productId,
                path: require("../../assets/img/empty_logo.png"),
                prices: { AUD: Number(eventDetails.ticket_price) },
                currency: "AUD",
                imageSrc: selectedItem.ticket_img,
                properties: {},
                propertiesToShowInCart: [],
                quantity: this.state.quantityPicker,
                additionalCost: 0.0,
              };
              this.addProduct(productId, product, "AUD");
              this.setState({
                isQuantityPickerModalOpen: false,
                isOpenFoodSuggestionModal: true,
              });
            }}
            block
            disabled={Number(quantityPicker) > 0 ? false : true}
            className="button-enabled-blue-style"
            style={{ marginTop: 20 }}
          >
            Add to cart
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderFoodSuggestionModal = () => {
    const { isOpenFoodSuggestionModal, eventDetails, ticketState } = this.state;
    const { loggedIn, username, userId, dispatch } = this.props;
    const { appReducer } = store.getState();
    const { selectedVendor, products } = appReducer;
    var eventDate = moment(eventDetails.start_date, "DD MMM YYYY").format(
      "DD MMM YYYY"
    );
    var ticket = Object.values(ticketState)[0];
    var selectedVendorData = "";
    if (getFromLocalStorage("selectedVendorData") != "undefined") {
      selectedVendorData = JSON.parse(
        getFromLocalStorage("selectedVendorData")
      );
    }
    const handleVendorClick = (vendor, eventDetails) => {
      // Only proceed if the vendor is accepting orders
      if (vendor.accepting_orders) {
        saveToLocalStorage("eventDetails", JSON.stringify(eventDetails));
        this.props.dispatch(
          updateSelectedVendor({
            vendorData: vendor,
            selectedVendor: vendor.id,
          })
        );
        saveToLocalStorage("vendorId", vendor.id);
        window.location.href = `/preorder-screen/vendor=${vendor.id}&event=${eventDetails.id}`;
      }
    };
    
    return (
      <Modal
        show={isOpenFoodSuggestionModal}
        onHide={() => {
          this.toggleFoodSuggestionModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", width: "90%", margin: "auto" }}
        >
          <Card className="card-conatiner">
            <Card.Body variant="bottom">
              <Card.Title>
                {this.isEmpty(ticketState)
                  ? "Pre Order Food"
                  : "Complete your order"}
              </Card.Title>
              <Card.Text>
                Pre-Order Food Now! Click on any of the food trucks below and
                pre order your food.
              </Card.Text>
              {eventDetails.vendor && eventDetails.vendor.length > 0 ? (
                <>
                  <Card.Title>Participating Food Trucks</Card.Title>
                  {eventDetails.vendor.map((vendor) => (
                    <>
                    <Media
                    as="li"
                    className="food-trucks-container"
                    style={{
                      cursor: vendor.accepting_orders
                        ? "pointer"
                        : "not-allowed", // Change cursor based on online order acceptance
                      padding: 5,
                      opacity: vendor.accepting_orders ? 1 : 0.5, // Dim the appearance if not accepting orders
                    }}
                    onClick={() => handleVendorClick(vendor, eventDetails)}
                  >
                        <img
                          width={64}
                          height={64}
                          className="align-self-start mr-3"
                          src={
                            vendor.logo &&
                            vendor.logo != "" &&
                            vendor.logo != null
                              ? vendor.logo
                              : require("../../assets/img/empty_logo.png")
                          }
                          alt="Generic placeholder"
                        />
                        <Media.Body style={{ textAlign: "left" }}>
                          <h5>{vendor.title}</h5>
                        </Media.Body>
                      </Media>
                    </>
                  ))}
                </>
              ) : null}
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <Card.Title style={{ textAlign: "center", width: "100%" }}>
            OR
          </Card.Title>
          <ButtonGroup
            aria-label="Toolbar with button groups"
            style={{ width: "100%" }}
          >
            <Button
              style={{
                width: "45%",
                marginTop: 20,
                marginBottom: 10,
                marginRight: 10,
                borderRadius: 40,
              }}
              onClick={() => {
                this.setState({ isOpenFoodSuggestionModal: false }, () => {
                  if (loggedIn == false || username == "" || userId == "") {
                    this.setState({ isOpenLoginModal: true });
                  } else {
                    dispatch(updateOrderType("Event"));
                    dispatch(updateOrderLater("true"));
                    dispatch(updateOrderDate(eventDate));
                    dispatch(updateOrderTime(eventDetails.timing));
                    dispatch(updateOrderLocation(eventDetails.location));
                    dispatch(updateEventId(eventDetails.id));
                    window.location.href = "/checkout";
                  }
                });
              }}
              block
              className="button-enabled-blue-style"
            >
              Checkout Now ${Number(this.calculateTotalPrice()).toFixed(2)}
            </Button>
            <Button
              style={{
                width: "45%",
                marginTop: 20,
                marginBottom: 10,
                marginRight: 10,
                borderRadius: 40,
              }}
              onClick={() => {
                this.emptyCart(false, null, null);
              }}
              block
              className="button-enabled-blue-style"
            >
              Clear Cart
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    );
  };
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(EventScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
