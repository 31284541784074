import React, { useState, useEffect } from "react";
import { List, Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OrganiserEditPrice from "../../screens/OrganiserEditPrice";

const MenuModal = (props) => {
  console.log(props);
  const [showAdminMenuEditScreen, set_showAdminMenuEditScreen] = useState(
    false
  );
  const [mealProp, set_mealProp] = useState([]);
  return (
    <Box style={{ overflow: "scroll", maxHeight: 500 }}>
      <Grid container spacing={2}>
        {showAdminMenuEditScreen && (
          <OrganiserEditPrice menu={mealProp} vendorId={props.vendorId} />
        )}
        {props.menu.map((item) => (
          <>
            <Grid item xs={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  width="200"
                  image={item.image}
                />
                <CardContent>
                  {!item.image && item.description ? (
                    <p style={{ color: "red" }}>* Meal image is missing</p>
                  ) : !item.description && item.image ? (
                    <p style={{ color: "red" }}>
                      * Meal description is missing
                    </p>
                  ) : !item.image && !item.description ? (
                    <p style={{ color: "red" }}>
                      * Meal image and description are missing
                    </p>
                  ) : null}

                  <Typography gutterBottom variant="body3">
                    {item.name}
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="body3">
                    Price:{item.price}
                  </Typography>
                  <br />
                  <Button
                    onClick={() => {
                      set_showAdminMenuEditScreen(true);
                      set_mealProp({ item });
                    }}
                  >
                    Edit
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default MenuModal;
