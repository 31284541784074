import React, { Component } from "react";
import {
  CURRENCY,
  TRANSACTION_FEE,
  EVENT_FEE,
  APP_FEE,
  KITCHACO_DELIVERY_FEE,
  SQUARE_ACCESS_TOKEN,
  SQUARE_API_VERSION,
  PAYMENT_URL,
  EMAIL_ORDER_NOTIFICATIONS,
  SQUARE_APP_ID,
  LOCATION_ID,
} from "../../../assets/Constants";
import * as Sentry from "@sentry/react";
import { payment, orderNow } from "../../../actions";
import moment from "moment";
import {
  store,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store/index";
import { applyToken } from "../../../services/api";
const styles = {
  name: {
    verticalAlign: "top",
    display: "none",
    margin: 0,
    border: "none",
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#000",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
  },
  leftCenter: {
    float: "left",
    textAlign: "center",
  },
  blockRight: {
    display: "block",
    float: "right",
  },
  center: {
    textAlign: "center",
  },
};

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
      paymentStatusState: "",
      paymentSourceState: "",
      paymentReceiptNumberState: "",
      paymentErrorState: "",
      paymentErrorDetailsState: "",
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentTotalCharges: 0.0,
      deliveryCharges: 0.0,
      isPaymentLoading: false,
    };
    this.requestCardNonce = this.requestCardNonce.bind(this);
    var selectedVendorData = JSON.parse(
      getFromLocalStorage("selectedVendorData")
    );
  }

  orderNowDishes = () => {
    const { appReducer } = store.getState();
    var selectedVendorData = JSON.parse(
      getFromLocalStorage("selectedVendorData")
    );
    const {
      username,
      userId,
      orderLater,
      orderDate,
      orderType,
      deliveryCharges,
      orderLocation,
      products,
      productsTotal,
      paymentStatus,
      paymentSource,
      paymentReceiptNumber,
      paymentError,
      paymentErrorDetails,
      paymentWttCharges,
      paymentTxnCharges,
      paymentTotalCharges,
    } = appReducer;

    const { productsState, orderDateState } = this.props;
    const newDishes = [];
    for (var key in productsState) {
      newDishes.push({
        quantity: productsState[key].quantity,
        id: productsState[key].id,
        request: productsState[key].properties.SpecialRequest.toString(),
        combo: productsState[key].properties.Combo.toString(),
        subitem: productsState[key].properties.Subitem.toString(),
      });
    }

    let date = orderDate;
    let time = orderDate;
    if (orderDate === null) {
      let temp = orderDateState.split("T");
      date = moment(orderDateState).format("DD MMM YYYY");
      time = moment(orderDateState).format("hh:mm A");
    } else {
      let temp = orderDate.split("T");
      date = moment(orderDateState).format("DD MMM YYYY");
      time = moment(orderDate).format("hh:mm A");
    }
    const data = {
      customer_name: username,
      customer: userId,
      vendor: selectedVendorData.id,
      meals: [...newDishes],
      payment_status: paymentStatus,
      payment_source: paymentSource,
      payment_receipt_number: paymentReceiptNumber,
      payment_wtt_charges: paymentWttCharges,
      payment_txn_charges: paymentTxnCharges,
      payment_total_amount: paymentTotalCharges,
      order_date: date,
      order_time: time,
      order_special_request: "",
      vendor_name: selectedVendorData.title,
      order_later:
        typeof orderLater == "string" ? JSON.parse(orderLater) : orderLater,
      order_type: orderType,
      delivery_location: orderLocation,
      kitchaco: Number(getFromLocalStorage("selectedLocationId")),
      discount: Number(getFromLocalStorage("discount")).toFixed(2),
      promo_code: getFromLocalStorage("applyPromoCode"),
      delivery_charge: this.props.deliveryCharges,
    };
    this.props.dispatch(orderNow(data)).then((orderResponse) => {
      if (orderResponse && orderResponse["payload"]) {
        var response = orderResponse["payload"];
        if (response.status === "Received") {
          window.location.reload(true);
        }
      } else {
        alert("There was an error processing your order! Please try again!");
        window.location.reload(true);
      }
    });

    // deleteFromLocalStorage('paymentStatus', "")
    // deleteFromLocalStorage('paymentSource', "")
    // deleteFromLocalStorage('paymentReceiptNumber', "")
    // deleteFromLocalStorage('paymentWttCharges', "0.0")
    // deleteFromLocalStorage('paymentTxnCharges', "0.0")
    // deleteFromLocalStorage('paymentTotalCharges', "0.0")
    // deleteFromLocalStorage('paymentError', "")
  };

  requestCardNonce() {
    if (this.state.isPaymentLoading == false) {
      this.setState({ isPaymentLoading: true }, () => {
        this.paymentForm.requestCardNonce();
      });
    }
  }

  makePayment = (
    amount_money,
    source_id,
    idempotency_key,
    currency,
    app_fee_money,
    dishes
  ) => {
    let data = {
      url: PAYMENT_URL,
      token: SQUARE_ACCESS_TOKEN,
      square_api_version: SQUARE_API_VERSION,
      crossDomain: true,
      method: "POST",
      body: JSON.stringify({
        source_id: source_id,
        idempotency_key: idempotency_key,
        amount_money: {
          amount: amount_money,
          currency: currency,
        },
        app_fee_money: {
          amount: app_fee_money,
          currency: currency,
        },
        autocomplete: true,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + SQUARE_ACCESS_TOKEN,
        "Square-Version": SQUARE_API_VERSION,
      },
    };

    this.props.dispatch(payment(data)).then((response) => {
      var paymentResponse = response.payload;
      if (paymentResponse != undefined) {
        if (
          paymentResponse.payment != undefined &&
          paymentResponse.payment.status != "CANCELLED" &&
          paymentResponse.payment.status != "FAILED"
        ) {
          this.setState(
            {
              paymentStatusState: paymentResponse.payment.status,
              paymentSourceState: paymentResponse.payment.source_type,
              paymentReceiptNumberState: paymentResponse.payment.receipt_number,
              paymentWttChargesState:
                paymentResponse.payment.app_fee_money.amount / 100,
              paymentTxnChargesState:
                Math.round(TRANSACTION_FEE * amount_money * 100) / 100 / 100,
              paymentTotalChargesState:
                paymentResponse.payment.total_money.amount / 100,
              paymentError: "",
            },
            () => {
              this.sendEmailConfirmation(dishes, amount_money);
              applyToken(getFromLocalStorage("token"));
              this.orderNowDishes();
            }
          );
        } else {
          var error = "Payment Error! Please try again.";
          if (paymentResponse.errors[0].code === "INVALID_EXPIRATION") {
            error = "Invalid Card Expiry. Please try again!";
          } else if (paymentResponse.errors[0].code === "GENERIC_DECLINE") {
            error = "Card Declined. Please try again!";
          } else if (paymentResponse.errors[0].code === "CVV_FAILURE") {
            error = "Invalid CVV. Please try again!";
          }
          this.setState(
            { paymentError: "FAILED", paymentErrorDetails: error },
            () => {
              window.location.reload(true);
              // this.onCardEntryCancel();
            }
          );
          alert(error);
        }
      }
    });
  };

  sendEmailConfirmation = (dishes, amount_money) => {
    const { appReducer } = store.getState();

    let dishesContent =
      "<table style='width:100%'><tr><td>KitchaCo Location:</td><td></td><td>" +
      getFromLocalStorage("selectedLocation") +
      "</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>";
    for (var key in dishes) {
      dishesContent = dishesContent + "<tr><td>" + dishes[key].name;
      if (
        dishes[key].properties.Subitem &&
        dishes[key].properties.Subitem.length > 0 &&
        dishes[key].properties.Subitem[0] != null
      ) {
        dishesContent =
          dishesContent +
          '\n<span style="font-size:12px">with ' +
          dishes[key].properties.Subitem[0] +
          "</span>";
      }
      dishesContent =
        dishesContent +
        "</td><td>x" +
        dishes[key].quantity +
        "</td><td>$" +
        dishes[key].prices.AUD +
        "</td></tr>";
      dishes[key].properties.Combo.forEach((combo) => {
        if (combo.length > 0) {
          dishesContent =
            dishesContent +
            "<tr><td>+  " +
            combo +
            "</td><td>x" +
            dishes[key].quantity +
            "</td><td>" +
            "</td></tr>";
        }
      });
      if (
        dishes[key].properties.SpecialRequest &&
        dishes[key].properties.SpecialRequest.length > 0 &&
        dishes[key].properties.SpecialRequest[0] != null
      ) {
        dishesContent =
          dishesContent +
          "<tr><td>Special Request:</td><td>&nbsp;</td><td>" +
          dishes[key].properties.SpecialRequest[0] +
          "</td></tr>";
      }
      dishesContent =
        dishesContent +
        "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>";
    }
    dishesContent =
      dishesContent +
      "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Sub Total:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
      (
        Number(Number(this.props.productsTotal)) + Number(this.props.discount)
      ).toFixed(2) +
      "</b></td></tr>";
    if (this.props.applyPromoCode != "" || this.props.applyPromoCode != null) {
      dishesContent =
        dishesContent +
        "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Discount(" +
        this.props.applyPromoCode +
        "):</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>-$" +
        Number(this.props.discount).toFixed(2) +
        "</b></td></tr>";
    }

    if (this.props.orderType == "delivery") {
      dishesContent =
        dishesContent +
        "<tr><td style='color:\"black\"'><b>Delivery Fee:</b></td><td></td><td style='color:\"black\"'><b>$" +
        this.props.deliveryCharges +
        "</b></td></tr>";
    }

    dishesContent =
      dishesContent +
      "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Total:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
      Number(
        Number(this.props.productsTotal) + Number(this.props.deliveryCharges)
      ).toFixed(2) +
      "</b></td></tr>";

    if (this.props.orderType == "delivery") {
      dishesContent =
        dishesContent +
        "<tr><td style='color:\"black\"'><b>Your Location:</b></td><td></td><td style='color:\"black\"'>" +
        appReducer.orderLocation +
        "</td></tr>";
    }

    dishesContent =
      dishesContent +
      "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>Order Type:</td><td>&nbsp;</td><td>" +
      this.props.orderType +
      "</td></tr>";

    if (this.props.orderLaterState == true) {
      let temp = this.props.orderDateState.split("T");
      let date = temp[0];
      let time = moment(this.props.orderDateState).format("hh:mm A");

      dishesContent =
        dishesContent +
        "<tr><td>Order Date:</td><td>&nbsp;</td><td>" +
        date +
        "</td></tr>";

      dishesContent =
        dishesContent +
        "<tr><td>Order Time:</td><td>&nbsp;</td><td>" +
        time +
        "</td></tr>";
    }

    dishesContent =
      dishesContent +
      "<tr><td style='color:\"black\"'><b>Receipt Number:</b></td><td></td><td style='color:\"black\"'><b>" +
      this.state.paymentReceiptNumberState +
      "</b></td></tr></table>";
    let data = {
      method: "POST",
      body: JSON.stringify({
        key: "qbQdvvA2NEPa0rdn9fgKoQ",
        template_name: "2020-kitchaco-inovice-template-do-not-alter-h",
        template_content: [
          {
            name: "YOUR_MERGETAG",
            content: "<p>Testing</p>",
          },
        ],
        message: {
          html: "<p>Example HTML content</p>",
          text: "Example text content",
          subject: "KitchaCo Invoice",
          from_email: "noreply@kitchaco.com",
          from_name: "KitchaCo",
          to: [
            {
              email: appReducer.email
                ? appReducer.email
                : EMAIL_ORDER_NOTIFICATIONS,
              name: appReducer.username,
              type: "to",
            },
          ],
          headers: {
            "Reply-To": "noreply@kitchaco.com",
          },
          important: false,
          track_opens: null,
          track_clicks: null,
          auto_text: null,
          auto_html: null,
          inline_css: null,
          url_strip_qs: null,
          preserve_recipients: null,
          view_content_link: null,
          bcc_address: appReducer.email ? EMAIL_ORDER_NOTIFICATIONS : null,
          tracking_domain: null,
          signing_domain: null,
          return_path_domain: null,
          merge: true,
          merge_language: "mailchimp",
          global_merge_vars: [
            {
              name: "YOUR_MERGETAG",
              content: dishesContent,
            },
          ],
        },
        async: false,
        ip_pool: "Main Pool",
        send_at: "2020-03-17 11:00:00",
      }),
    };

    return fetch(
      "https://mandrillapp.com/api/1.0/messages/send-template.json",
      data
    )
      .then((response) => response.json())
      .then((emailResponse) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  componentDidMount() {
    const config = {
      applicationId: SQUARE_APP_ID,
      locationId: LOCATION_ID,
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Helvetica Neue",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#000",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale",
        },
      ],
      applePay: {
        elementId: "sq-apple-pay",
      },
      masterpass: {
        elementId: "sq-masterpass",
      },
      googlePay: {
        elementId: "sq-google-pay",
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "• • • •  • • • •  • • • •  • • • •",
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV",
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY",
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Zip",
      },
      callbacks: {
        methodsSupported: (methods) => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay,
            });
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay,
            });
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass,
            });
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "AUD",
            countryCode: "AU",
            total: {
              label: "MERCHANT NAME",
              amount: "100",
              pending: false,
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "100",
                pending: false,
              },
            ],
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          const {
            user,
            productsTotal,
            deliveryCharges,
            orderType,
            productsState,
          } = this.props;
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            errors.forEach(function(error) {});

            return;
          }
          // PAYMENT STEP 1 - Initialise
          var source_id = nonce;
          var idempotency_key = "" + user + "" + Date.now();
          var currency = CURRENCY;

          var amount_money = Number(productsTotal) + Number(deliveryCharges);

          //PAYMENT STEP 3 - Convert the amount_money to cents
          amount_money = parseInt(
            (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
          ); // rounded in cents

          // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
          // make sure this is also in cents

          var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

          // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
          app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

          // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery
          if (orderType == "delivery") {
            app_fee_money += KITCHACO_DELIVERY_FEE;
          }
          this.makePayment(
            amount_money,
            source_id,
            idempotency_key,
            currency,
            app_fee_money,
            productsState
          );

          this.setState({
            nonce: nonce,
          });
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("error").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("error").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand,
                });
              } else {
                this.setState({
                  cardBrand: "",
                });
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function() {
          document.getElementById("name").style.display = "inline-flex";
        },
      },
    };
    this.paymentForm = new this.props.paymentForm(config);
    this.paymentForm.build();
  }

  render() {
    const { appReducer } = store.getState();
    const { isPaymentLoading } = this.state;
    //  const{orderDate} = appReducer
    //  const {orderDateState} = this.props;
    //  let date = orderDate
    //  let time = orderDate
    //  if (orderDate === null) {
    //    let temp = orderDateState.split("T")
    //    date = temp[0]
    //    time = moment(orderDateState).format("hh:mm A")
    //  }
    //  else {
    //    let temp = orderDate.split("T")
    //    date = temp[0]
    //    time = moment(orderDate).format("hh:mm A")
    //  }
    return (
      <div className="checkout-container">
        <div id="form-container">
          <div id="sq-walletbox">
            <button
              style={{ display: this.state.applePay ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-apple-pay"
            ></button>
            <button
              style={{ display: this.state.masterpass ? "block" : "none" }}
              className="wallet-button"
              id="sq-masterpass"
            ></button>
            <button
              style={{ display: this.state.googlePay ? "inherit" : "none" }}
              className="wallet-button"
              id="sq-google-pay"
            ></button>
            <hr />
          </div>

          <div id="sq-ccbox">
            <p>
              <span style={styles.leftCenter}>Enter Card Info Below </span>
              <span style={styles.blockRight}>
                {this.state.cardBrand.toUpperCase()}
              </span>
            </p>
            <div id="cc-field-wrapper">
              <div id="sq-card-number"></div>
              <input type="hidden" id="card-nonce" name="nonce" />
              <div id="sq-expiration-date"></div>
              <div id="sq-cvv"></div>
            </div>
            <input
              id="name"
              style={styles.name}
              type="text"
              placeholder="Name"
            />
            <div id="sq-postal-code"></div>
          </div>
          <button
            className="button-credit-card"
            onClick={this.requestCardNonce}
          >
            {isPaymentLoading == true ? "Loading..." : "Pay"}
          </button>
        </div>
        <p style={styles.center} id="error"></p>
      </div>
    );
  }
}
