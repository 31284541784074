import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./style.css";

const ModalDate = ({ startDate, endDate, onSave }) => {
  console.log(startDate, endDate);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [datesArray, setDatesArray] = useState([]); // Moved datesArray inside the component to manage it as state

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleSave = () => {
    toggleModal(); // Close the modal
    onSave(selectedDates); // Pass selectedDates back to the parent component
  };

  useEffect(() => {
    localStorage.removeItem("selected_dates");
    // Assuming calculateDates will update datesArray state
    calculateDates();
  }, [startDate, endDate]); // Added startDate and endDate as dependencies to recalculate dates when they change

  useEffect(() => {
    // Use useEffect to correctly handle the async nature of state updates
    localStorage.setItem("selected_eoi_dates", JSON.stringify(selectedDates));
    console.log(selectedDates);
  }, [selectedDates]); // This effect runs whenever selectedDates changes

  const calculateDates = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];

    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      // Format each date to '12 Apr 2024' format
      const formattedDate = dt
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");

      dates.push(formattedDate);
    }
    console.log(dates);

    setDatesArray(dates);
  };

  const handleDateSelect = (date) => {
    setSelectedDates((prevDates) =>
      prevDates.includes(date)
        ? prevDates.filter((d) => d !== date)
        : [...prevDates, date]
    );
  };

  return (
    <div>
      <Button onClick={toggleModal}>Select Dates</Button>
      {modalOpen && (
        <div className="modalp">
          <div className="modalp-content">
            <div>
              {datesArray.map((date) => (
                <button
                  key={date}
                  onClick={() => handleDateSelect(date)}
                  className={
                    selectedDates.includes(date) ? "selected date" : "date"
                  }
                >
                  {date}
                </button>
              ))}
            </div>
            <div>Selected Dates: {selectedDates.join(", ")}</div>
            <div className="modalp-actions">
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={toggleModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalDate;
