import React, { useState, useEffect } from "react";
import Footer from "../../components/UI/Footer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Divider from "@mui/material/Divider";
import "./style.css"; // Import your CSS file

function AppPrivacyPolicy() {
  return (
    <div>
      <Navbar
        variant="dark"
        expand="lg"
        style={{ backgroundColor: "#2859a5", marginBottom: "20px" }}
      >
        <Navbar.Brand href="/">
          <Image
            style={{ height: 40 }}
            src={require("../../assets/img/welcome-logo.png")}
            fluid
          />
        </Navbar.Brand>
      </Navbar>
      <h1>PRIVACY POLICY FOR WTT APP</h1>
      {/* <Container maxWidth="md" style={{ padding: 20 }}>
        <h4 maxWidth="md" style={{ fontSize: 20 }}>
          Introduction/overview /Data collection and uses/choice and
          transparency/Update to this notice
        </h4>
      </Container> */}
      <Container
        maxWidth="md"
        style={{ backgroundColor: "#F3F1EF", padding: 20 }}
      >
        {/* <Box sx={{ bgcolor: "#cfe8fc", height: "100vh" }} /> */}
        <h4 style={{ color: "#ef3e6d" }}>I. Introduction</h4>
        When you use Where the truck, you trust us with your personal data.
        We’re committed to keeping that trust. That starts with helping you
        understand our privacy practices. This notice describes the personal
        data we collect, how it’s used and shared, and your choices regarding
        this data. We recommend that you read this, which highlights key points
        about our privacy practices. <div>Last modified: June 01, 2020</div>
        <div> Effective date: July 03, 2020</div>
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>II. Overview</h4>
        <h5 style={{ color: "#2959a5" }}>A. Scope:</h5> This notice applies to
        users of Where the truck’s services anywhere in the world, including
        users of Where the truck’s apps, websites, features, or other services.
        This notice describes how Where the truck and its affiliates collect and
        use personal data. This notice applies to all users of our apps,
        websites, features, or other services anywhere in the world. This notice
        specifically applies to:
        <ul>
          <li>
            <strong>
              <em>Delivery recipients:</em>
            </strong>{" "}
            individuals who request or receive food, or other products and
            services
          </li>
          <li>
            <strong>
              <em>Delivery partners: </em>
            </strong>{" "}
            individuals who provide, or submit applications to Where the truck
            to provide, delivery or other services
          </li>
        </ul>
        This notice also governs Where the truck’s other collections of personal
        data in connection with Where the truck’s services. For example, we may
        collect the contact information of individuals who use accounts owned by
        Where the truck for Business customers or of owners or employees of
        Where the truck, food truck partners, or we may collect other personal
        data in connection with our mapping technology and features. All those
        subject to this notice is referred to as “users” in this notice. In
        addition, please note the following: For California users: Information
        regarding Where the truck’s privacy practices related to the California
        Consumer Privacy Act (CCPA) is available on request.
        <ul>
          <li>
            <strong>
              <em>For users in Mexico:</em>
            </strong>{" "}
            Please contact us for information regarding Where the truck’s
            privacy practices required under Mexico’s Mexican Personal Data
            Protection Law (Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares).
          </li>
          <li>
            <strong>
              <em>For customers without an Where the truck account: </em>
            </strong>{" "}
            The personal data of the customer who makes the food order arranged
            by the owners of an Where the truck account, such as when a customer
            takes a delivery arranged by the owner of an Where the truck account
            (such as a hospital or healthcare provider), is processed in
            accordance with our contract with the owner of that account, and not
            as otherwise described in this notice. Such processing may include
            sharing of customers location data with the owner of that account.
            Please contact the owner of such account for more information.
          </li>
        </ul>
        Our data practices are subject to applicable laws in the places in which
        we operate. This means that we engage in the practices described in this
        notice in a particular country or region only if permitted under the
        laws of those places. Please contact us at hello@wherethetruck.com.au or
        through the addresses below with any questions regarding our practices
        in a particular country or region.
        <h5 style={{ color: "#2959a5" }}>
          B. Data controller and transfer
        </h5>{" "}
        Where the truck and Australian Mobile food vendors group pty ltd. are
        the data controllers for the personal data collected in connection with
        use of Where the truck’s services in the European Economic Area and the
        United Kingdom. Where the truck – Australian Mobile food vendors group
        pty ltd. is the data controller for the personal data collected in
        connection with use of Where the truck’s services anywhere else. Where
        the truck- Australian mobile food vendors group pty ltd are the data
        controllers for the personal data collected in connection with use of
        Where the truck’s services in the European Economic Area and the United
        Kingdom. Where the truck Australian mobile food vendors group pty ltd.
        is the data controller for the personal data collected in connection
        with use of Where the truck’s services anywhere else. We process
        personal data inside and outside of Australia. The personal data of
        users outside of Australia is transferred on the basis of mechanisms
        approved under applicable laws, such as the Standard Contractual
        Clauses. Questions, comments, and complaints about Where the truck’s
        data practices can be submitted to hello@wherethetruck.com.au.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>III. Data collections and uses </h4>
        <h5 style={{ color: "#2959a5" }}>A. The data we collect</h5> Where the
        truck collects:
        <ul>
          <li>
            {" "}
            Data provided by users to Where the truck, such as during account
            creation
          </li>
          <li>
            {" "}
            Data created during use of our services, such as location, app
            usage, and device data
          </li>
          <li>
            {" "}
            Data from other sources, such as Where the truck partners and third
            parties that use Where the truck APIs
          </li>
        </ul>
        The following data is collected by or on behalf of Where the truck:
        <div>
          <strong>1. Data provided by users.</strong> This includes:
        </div>
        <ul>
          <li>
            {" "}
            <strong>
              <em>User Profile:</em>
            </strong>{" "}
            We collect data when users create or update their Where the truck
            accounts. This may include their name, email, phone number, login
            name and password, address, profile picture, payment or banking
            information (including related payment verification information),
            government identification documents, including driver’s license
            numbers and images, birthdate, signature, and photo. This also
            includes vehicle or insurance information of food truck drivers and
            food truck delivery partners, and user settings. We may use the
            photos submitted by drivers and/or delivery partners to verify their
            identities, such as through facial recognition technologies. For
            more information, please see the section titled “How we use personal
            data.”
          </li>
          <li>To provide electronic receipts for transactions.</li>
          <li>
            <strong>
              <em>Demographic data: </em>
            </strong>
            We may collect demographic data about users, including through user
            surveys. In some countries, we may also receive demographic data
            about users from third parties.
          </li>
          <li>
            <strong>
              <em>User content: </em>
            </strong>
            We collect the information users submit when they contact Where the
            truck customer support, provide ratings or compliments for other
            users or food truck partners, or otherwise contact Where the truck.
            This may include feedback, photographs or other recordings collected
            by users.
          </li>
        </ul>
        <strong> 2. Data created during use of our services.</strong> This
        includes:
        <ul>
          <li>
            {" "}
            <strong>
              <em>Location data:</em>
            </strong>{" "}
            We collect precise or approximate location data from a user’s mobile
            device if enabled by the user to do so. For Food truck drivers and
            delivery partners, Where the truck collects this data when the Where
            the truck app is running in the foreground (app open and on-screen)
            or background (app open but not on-screen) of their mobile device.
            For pick-up customers, delivery recipients, and renters, Where the
            truck collects this data when the Where the truck app is running in
            the foreground. In certain regions (which do not include the
            European Union), Where the truck may also collect this data when the
            Where the truck app is running in the background of the user’s
            mobile device. Pick-up customers, delivery recipients, and renters
            may use the Where the truck apps without enabling Where the truck to
            collect location data from their mobile devices. However, this may
            affect some functionality available in the Where the truck apps. For
            example, a user who has not enabled location data collection will
            have to manually enter their address. In addition, the location data
            collected from a food truck delivery driver during a trip will be
            linked to the food trucks account account, even if they have not
            enabled location data to be collected from their device, including
            for purposes of receipt generation, customer support, fraud
            detection, insurance, and litigation.
          </li>
          <li>
            <strong>
              <em>Transaction information: </em>
            </strong>
            We collect transaction information related to the use of our
            services, including the type of services requested or provided,
            order details, delivery information, date and time the service was
            provided, amount charged, distance travelled, and payment method.
            Additionally, if someone uses your promotion code, we may associate
            your name with that person.
          </li>
          <li>
            <strong>
              <em>Usage data: </em>
            </strong>
            We collect data about how users interact with our services. This
            includes data such as access dates and times, app features or pages
            viewed, app crashes and other system activity, type of browser, and
            third-party sites or services used before interacting with our
            services. In some cases, we collect this data through cookies,
            pixels, tags, and similar tracking technologies that create and
            maintain unique identifiers. To learn more about these technologies,
            please ask for our cookie notice at Hello@wherethetruck.com.au.
          </li>
          <li>
            <strong>
              <em>Device data: </em>
            </strong>
            We may collect data about the devices used to access our services,
            including the hardware models, device IP address, operating systems
            and versions, software, preferred languages, unique device
            identifiers, advertising identifiers, serial numbers, device motion
            data, and mobile network data.
          </li>
          <li>
            <strong>
              <em>Communications data: </em>
            </strong>
            We enable users to communicate with each other and Where the truck
            through Where the truck’s mobile apps and websites. For example, we
            enable food truck drivers and there appointed Customers, and Food
            trucks/stall holders or delivery partners and delivery recipients,
            to call, text, or send other files to each other (generally without
            disclosing their telephone numbers to each other). To provide this
            service, Where the truck receives some data regarding the calls,
            texts, or other communications, including the date and time of the
            communications and the content of the communications. Where the
            truck may also use this data for customer support services
            (including to resolve disputes between users), for safety and
            security purposes, to improve our products and services, and for
            analytics.
          </li>
        </ul>
        <strong>3. Data from other sources. </strong>This includes:
        <ul>
          <li>User feedback, such as ratings, feedback, or compliments.</li>
          <li>
            Users participating in our referral programs. For example, when a
            user refers another person, we receive the referred person’s
            personal data from that user.
          </li>
          <li>
            Where the truck account owners who request services for or on behalf
            of other users, or who enable such users to request or receive
            services through their accounts. This includes owners of Where the
            truck for Business accounts.
          </li>
          <li>
            Users or others providing information in connection with claims or
            disputes.
          </li>
          <li>
            Where the truck business partners through which users create or
            access their Where the truck account, such as payment providers,
            social media services, or apps or websites that use Where the
            truck’s APIs or whose APIs Where the truck uses.
          </li>
          <li>
            Vendors who help us verify users’ identity, background information,
            and eligibility to work, for regulatory, safety, and security
            purposes.
          </li>
          <li>
            Insurance, vehicle, or financial services providers for food truck
            drivers and/or their allocated delivery partners.
          </li>
          <li>
            Partner transportation companies (for Food Truck drivers or their
            delivery partners who use our services through an account associated
            with such a company).
          </li>
          <li>Publicly available sources.</li>
          <li>Marketing service providers.</li>
        </ul>
        Where the truck may combine the data collected from these sources with
        other data in its possession.
        <h5 style={{ color: "#2959a5" }}>B. How we use personal data</h5>
        Where the truck collects and uses data to enable reliable events and
        convenient transportation, delivery, and other products and services. We
        also use the data we collect:
        <ul>
          <li>To enhance the safety and security of our users and services</li>
          <li>For customer support</li>
          <li>For research and development</li>
          <li>To enable communications between users</li>
          <li>To send marketing and non-marketing communications to users</li>
          <li>In connection with legal proceedings</li>
        </ul>
        Where the truck does not sell or share user personal data with third
        parties for their direct marketing, except with users’ consent.
        <div>
          Where the truck uses the data, it collects for purposes including:
        </div>
        <strong>1. Providing services and features</strong>
        <div>
          Where the truck uses the data we collect to provide, personalize,
          maintain, and improve our products and services as well as improve the
          events we are associated with.
        </div>
        <div>This includes using the data to:</div>
        <ul>
          <li>Create and update users’ accounts.</li>
          <li>
            Verify food truck drivers’ and their delivery partners’ identity,
            background history, and eligibility to work.
          </li>
          <li>Enable transportation, deliveries, and other services.</li>
          <li>Offer, process, or facilitate payments for our services.</li>
          <li>
            Offer, obtain, provide, or facilitate insurance, vehicle, invoicing,
            or financing solutions in connection with our services.
          </li>
          <li>
            Track and share the progress of food truckdrivers and their delivery
            drivers or deliveries.
          </li>
          <li>
            Enable features that allow users to share information with other
            people, such as when a user submit a compliment about a food truck
            driver, or their delivery partner, refer a friend to Where the
            truck.
          </li>
          <li>
            Enable features to personalize users’ Where the truck accounts, such
            as creating bookmarks for favourite places, and to enable quick
            access to previous destinations. We may, for example, present an
            Where the truck user with personalized food trucks or food
            recommendations based on their prior orders. Please see the section
            of this notice titled “Choice and transparency” to learn how to
            object to this use of personal data.
          </li>
          <li>
            Perform internal operations necessary to provide our services,
            including to troubleshoot software bugs and operational problems; to
            conduct data analysis, testing, and research; and to monitor and
            analyse usage and activity trends.
          </li>
        </ul>
        <strong>2. Safety and security</strong>
        <div>
          We use personal data to help maintain the safety, security, and
          integrity of our services and users. This includes:
        </div>
        <ul>
          <li>
            Screening food truck drivers, their drivers, and stall holders
            before enabling their use of our services and at subsequent
            intervals, including through reviews of background checks, where
            permitted by law, to help prevent use of our services by unsafe food
            truck drivers and/or their delivery drivers.
          </li>
          <li>
            In certain regions, using information derived from driver’s license
            photos, and other photos submitted to Where the truck, for safety
            and security purposes. This includes Where the truck’s Real-Time ID
            Check feature, which prompts food truck drivers and their delivery
            partners to share a selfie before going online to help ensure that
            their delivery driver using the app matches the Where the truck
            account we have on file. This also includes comparing photographs
            that we have on file against photographs (i) of other users to
            prevent identity-borrowing, and (ii) from public databases to verify
            user identity.
          </li>
          <li>
            Using device, location, profile, usage, and other data to prevent,
            detect, and combat fraud or unsafe activities.
          </li>
        </ul>
        <strong>3. Customer support</strong>
        <div>
          {" "}
          Where the truck uses the information, we collect (including recordings
          of customer support calls with notice to and the consent of the user)
          to provide customer support, including to:
        </div>
        <ul>
          <li>Direct questions to the appropriate customer support person</li>
          <li>Investigate and address user concerns</li>
          <li>
            Monitor and improve our customer support responses and processes
          </li>
        </ul>
        <div>
          <strong>4. Research and development</strong>
          <div>
            {" "}
            We may use the data we collect for testing, research, analysis,
            product development, and machine learning to improve the user
            experience. This helps us to improve and enhance the safety and
            security of our services, improve our ability to prevent the use of
            our services for illegal or improper purposes, develop new features
            and products, and facilitate insurance and finance solutions in
            connection with our services.
          </div>
        </div>
        <div>
          <strong>5. Enabling communications between users</strong>
          <div>
            {" "}
            For example, a Food Truck driver may message or call a user or their
            delivery driver to confirm a pickup location, a food truck driver
            may contact their delivery driver to retrieve a lost item, or a Food
            truck Driver or their delivery driver may call a delivery recipient
            with information about their order.
          </div>
        </div>
        <div>
          <strong>6. Marketing</strong>
          <div>
            {" "}
            Where the truck may use the data, we collect to market our services
            to our users. This includes sending users communications about Where
            the truck services, features, promotions, sweepstakes, studies,
            surveys, news, updates, and events. We may also send communications
            to our users about products and services offered by Where the truck
            partners. For example, if a user has placed an Where the truck
            order, we may provide recommendations, promotions, or ads about
            similar food offered by other Where the truck partners. Although we
            may send users communications about Where the truck partners’
            products and services, we do not sell users’ personal data to, or
            share it with, such partners or others for purposes of their own
            direct marketing or advertising, except with users’ consent. We may
            use the data we collect to personalize the marketing communications
            (including advertisements) that we send, including based on user
            location, past use of Where the truck’s services, and user
            preferences and settings.
          </div>
        </div>
        <div>
          <strong>7. Non-marketing communications</strong>
          <div>
            {" "}
            Where the truck may use the data we collect to generate and provide
            users with receipts; inform them of changes to our terms, services,
            or policies; or send other communications that aren’t for the
            purpose of marketing the services or products of Where the truck or
            its partners.
          </div>
        </div>
        <div>
          <strong>8. Legal proceedings and requirements</strong>
          <div>
            {" "}
            We may use the personal data we collect to investigate or address
            claims or disputes relating to use of Where the truck’s services, or
            as otherwise allowed by applicable law, or as requested by
            regulators, government entities, and official inquiries.
          </div>
        </div>
        <h5 style={{ color: "#2959a5" }}>
          C. Cookies and third-party technologies
        </h5>{" "}
        Where the truck and its partners use cookies and other identification
        technologies on our apps, websites, emails, and online ads for purposes
        described in this notice. Cookies are small text files that are stored
        on browsers or devices by websites, apps, online media, and
        advertisements. Where the truck uses cookies and similar technologies
        for purposes such as:
        <ul>
          <li> Authenticating users</li>
          <li> Remembering user preferences and settings</li>
          <li> Determining the popularity of content</li>
          <li>
            {" "}
            Delivering and measuring the effectiveness of advertising campaigns
          </li>
          <li>
            {" "}
            Analysing site traffic and trends, and generally understanding the
            online behaviours and interests of people who interact with our
            services
          </li>
        </ul>
        We may also allow others to provide audience measurement and analytics
        services for us, to serve advertisements on our behalf across the
        Internet, and to track and report on the performance of those
        advertisements. These entities may use cookies, web beacons, SDKs, and
        other technologies to identify the devices used by visitors to our
        websites, as well as when they visit other online sites and services.
        <h5 style={{ color: "#2959a5" }}>
          D. Data sharing and disclosure
        </h5>{" "}
        Some of Where the truck’s products, services, and features require that
        we share data with other users or at a user’s request. We may also share
        data with our affiliates, subsidiaries, and partners, for legal reasons
        or in connection with claims or disputes.
        <div>Where the truck may share the data, we collect:</div>
        <strong>1. With other users</strong>
        <div>
          <em>This includes sharing:</em>
        </div>
        <ul>
          <li>
            users’ first name, rating, and pickup and/or drop-off locations with
            food truck drivers and their delivery drivers.
          </li>
          <li>
            Delivery recipients’ first name, delivery address, and order
            information with food truck drivers and their delivery drivers. We
            may also share ratings and feedback, or other information to the
            extent required by law, with the Food Truck Driver partner and their
            delivery partner.
          </li>
          <li>
            For food truck rivers and their delivery partners, we may share data
            with the (users delivery recipient(s), and Food truck Drivers(s),
            including name and photo; and vehicle photo; location average rating
            provided by users; length of use of the Where the truck app; contact
            information (depending upon applicable laws); and Food Truck driver
            or their delivery Driver profile, including compliments and other
            feedback submitted by past users. We also provide (users) delivery
            recipients with receipts containing information such as a breakdown
            of amounts charged, Food Truck Name or their delivery partner first
            name, photo, route map, and such other information required on
            invoices in the country or region where the Food Truck driver or
            their delivery driver operates.
          </li>
        </ul>
        <strong>2. At the user’s request</strong>
        <div>
          <em>This includes sharing data with:</em>
        </div>
        <ul>
          <li>
            Other people at the user’s request. For example, we share a user’s
            ETA and location with a friend when requested by that user.
          </li>
          <li>
            Where the truck business partners. For example, if a user requests a
            service through a partnership or promotional offering made by a
            third party, Where the truck may share certain data with those third
            parties. This may include, for example, other services, platforms,
            apps or websites that integrate with our APIs; vehicle suppliers or
            services; those with an API or service with which we integrate; or
            Food Truck Drivers or other Where the truck business partners and
            their users in connection with promotions, contests, or specialized
            services.
          </li>
        </ul>
        <strong>3. With the general public</strong>
        <div>
          Questions or comments from users submitted through public forums such
          as Where the truck blogs and Where the truck social media pages may be
          viewable by the public, including any personal data included in the
          questions or comments submitted by a user.
        </div>
        <strong>4. With the Where the truck account owner</strong>
        <div>
          If a user requests a Food Truck in my street service or places an
          order using an account owned by another party, we may share their
          order or trip information, including real-time location data, with the
          owner of that account. This occurs, for example, when:
        </div>
        <ul>
          <li>
            A user uses their employer’s Where the truck for Business profile,
            such as when they book a food truck in my street service arranged
            through the FTIMS feature button.
          </li>
          <li>
            A food truck driver or their delivery driver uses an account owned
            by or associated food truck driver.
          </li>
          <li>
            A user pays for and arranges for a friend or under a Family Profile
          </li>
        </ul>
        <strong>5. With Where the truck subsidiaries and affiliates</strong>
        <div>
          We share data with our subsidiaries and affiliates to help us provide
          our services or conduct data processing on our behalf. For example,
          Where the truck processes and stores data from other countries that it
          may share with new international subsidiaries and affiliates.
        </div>
        <strong>
          6. With Where the truck service providers and business partners
        </strong>
        <div>
          Where the truck provides data to vendors, consultants, marketing
          partners, research firms, and other service providers or business
          partners. These include:
        </div>
        <ul>
          <li>Payment processors and facilitators</li>
          <li>Cloud storage providers</li>
          <li>
            Marketing partners and marketing platform providers, including
            social media advertising services
          </li>
          <li>Data analytics providers</li>
          <li>
            Research partners, including those performing surveys or research
            projects in partnership with Where the truck or on Where the truck’s
            behalf
          </li>
          <li>
            Vendors that assist Where the truck to enhance the safety and
            security of its apps
          </li>
          <li>
            Consultants, lawyers, accountants, and other professional service
            providers
          </li>
          <li>Fleet partners</li>
          <li>Insurance and financing partners</li>
          <li>Airports</li>
          <li>Lime and other local providers</li>
          <li>
            Food Truck drivers or their delivery partners and/or their point of
            sale providers
          </li>
          <li>Vehicle solution vendors or third-party vehicle suppliers</li>
        </ul>
        <strong>7. For legal reasons or in the event of a dispute</strong>
        <div>
          Where the truck may share users’ personal data if we believe it’s
          required by applicable law, regulation, operating license or
          agreement, legal process or governmental request, or where the
          disclosure is otherwise appropriate due to safety or similar concerns.
          This includes sharing personal data with law enforcement officials,
          public health officials, other government authorities, airports (if
          required by the airport authorities as a condition of operating on
          airport property), or other third parties as necessary to enforce our
          Terms of Service, user agreements, or other policies; to protect Where
          the truck’s rights or property or the rights, safety, or property of
          others; or in the event of a claim or dispute relating to the use of
          our services. If you use another person’s credit card, we may be
          required by law to share your personal data, including trip or order
          information, with the owner of that credit card.{" "}
        </div>
        <div>
          This also includes sharing personal data with others in connection
          with, or during negotiations of, any merger, sale of company assets,
          consolidation or restructuring, financing, or acquisition of all or a
          portion of our business by or into another company.
        </div>
        <strong>8. With consent</strong>
        <div>
          Where the truck may share a user’s personal data other than as
          described in this notice if we notify the user and they consent to the
          sharing.
        </div>
        <h5 style={{ color: "#2959a5" }}>E. Data retention and deletion</h5>{" "}
        <div>
          Where the truck retains user profile, transaction, and other personal
          data for as long as a user maintains their Where the truck account.
        </div>
        <div>
          Where the truck may retain certain user data after receiving an
          account deletion request if necessary, such as to comply with legal
          requirements.
        </div>
        <div>
          {" "}
          Where the truck retains user profile, transaction, and other
          information for as long as a user maintains their Where the truck
          account. For Food Truck drivers and their delivery drivers, Where the
          truck also retains vehicle and background check information (to the
          extent permitted by law) for as long as they maintain their Where the
          truck account.
        </div>
        <div>
          {" "}
          Users delivery recipients may request deletion of their account at any
          time through the Settings > Privacy menus in the Where the truck app,
          or through Where the truck’s support team (head office) Following such
          requests, Where the truck deletes the data that it is not required to
          retain for purposes of regulatory, tax, insurance, litigation, or
          other legal requirements. For example, Where the truck retains
          location, device, and usage data for these purposes for a minimum of 7
          years; while it retains such data, it may also use it for purposes of
          safety, security, fraud prevention and detection, and research and
          development. In certain circumstances, Where the truck may be unable
          to delete a user’s account, such as if there’s an outstanding credit
          on the account or an unresolved claim or dispute. Upon resolution of
          the issue preventing deletion, Where the truck will delete the account
          as described above.
        </div>
        <div>
          {" "}
          Where the truck may also retain certain information if necessary, for
          purposes of safety, security, and fraud prevention. For example, if
          Where the truck deactivates a user’s account because of unsafe
          behaviour or security incidents, Where the truck may retain certain
          information about that account to prevent that user from opening a new
          Where the truck account in the future.
        </div>
        <h5 style={{ color: "#2959a5" }}>F. Grounds for processing</h5>{" "}
        <div>
          We only collect and use personal data where we have lawful grounds to
          do so. These include processing user personal data to provide
          requested services and features, for purposes of Where the truck’s
          legitimate interests or those of other parties, to fulfill our legal
          obligations, or based on consent.
        </div>
        <div>
          We collect and use personal data only where we have one or more lawful
          grounds for doing so. Such grounds may vary depending on where our
          users are located, but generally include processing personal data:
        </div>
        <strong>a. To provide requested services and features</strong>
        <div>
          In order to provide our services, we must collect and use certain
          personal data. This includes:
        </div>
        <ul>
          <li>
            User profile data, which we use to establish and maintain user
            accounts; verify user identity; communicate with users about their
            trips, orders, and accounts; and enable users to make payments or
            receive earnings
          </li>
          <li>
            Food truck driver and their delivery partner location data, which we
            use to track trips and assist with navigation
          </li>
          <li>
            Usage data, which is necessary to maintain, optimize, and enhance
            Where the truck’s services, including to determine incentives,
            connect users and food Truck drivers
          </li>
          <li>Transaction information</li>
          <li>Information relating to customer support</li>
        </ul>
        <strong>
          b. For purposes of the legitimate interests of Where the truck or
          other parties
        </strong>
        <div>
          This includes using personal data to maintain and enhance our users’
          safety and security. For example, we use personal data to prevent use
          of our services by users who have engaged in inappropriate or
          dangerous behaviour, such as by retaining data of banned users to
          prevent their use of Where the truck’s apps.
        </div>
        <div>
          This also includes purposes such as combating fraud; improving our
          services, direct marketing, research, and development; and enforcing
          Where the truck’s Terms of Service.
        </div>
        <div>
          In addition, it includes using personal data to the extent necessary
          for the interests of other people or the general public, such as in
          connection with legal or insurance claims, and to protect the rights
          and safety of others.
        </div>
        <strong>c. To fulfill Where the truck’s legal obligations</strong>
        <div>
          For example, Where the truck is subject to laws and regulations in
          many cities and countries that require it to collect and retain data
          about our users’ purchases, and to provide copies of such data to the
          government or other authorities. We collect and use personal data to
          comply with such laws.
        </div>
        <div>
          Where the truck may also share data with law enforcement regarding
          criminal acts or threats to public safety, or requests by third
          parties pursuant to legal processes. For more information about such
          sharing, please contact us at hello@wherethetruck.com.au.
        </div>
        <strong>d. With consent</strong>
        <div>
          Where the truck may collect and use personal data based on the user’s
          consent. For example, we may collect personal data through voluntary
          surveys. Responses to such surveys are collected on the basis of
          consent and will be deleted once no longer necessary for the purposes
          collected.
        </div>
        <div>
          A user who has provided consent to a collection or use of their
          personal data can revoke it at any time. However, the user will not be
          able to use any service or feature that requires collection or use of
          that personal data.
        </div>
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>IV. Choice and transparency </h4>
        Where the truck enables users to access and control the data that Where
        the truck collects, including through:
        <ul>
          <li>Account settings</li>
          <li>Device permissions</li>
          <li>In-app ratings pages</li>
        </ul>
        <div>
          Where the truck also enables users to request access to or copies of
          their data, changes or updates to their accounts, deletion of their
          accounts, or that Where the truck restrict its processing of user
          personal data.
        </div>
        <h5 style={{ color: "#2959a5" }}>A. Privacy settings</h5>{" "}
        <div>
          Settings menus in the Where the truck app for users give them the
          ability to set or update their location-sharing preferences and their
          preferences for receiving mobile notifications from Where the truck.
          Information about these settings, how to set or change these settings,
          and the effect of turning off these settings is described below.
        </div>
        <ul>
          <li>Location data</li>
        </ul>
        <div>
          Where the truck uses user device location services to make it easier
          to find a Food truck anywhere in Australia. Location data helps
          improve our services, including pickups, navigation, and customer
          support. It also allows us to support event organisers in managing
          traffic flows and people movement at events
        </div>
        <div>
          Users may enable or disallow Where the truck to collect location data
          from their mobile devices through the profile account button in the
          Where the truck app. Users and delivery recipients can enable or
          disallow such collections through the settings on their mobile device.
        </div>
        <ul>
          <li>Share Live Location (users of FTIMS)</li>
        </ul>
        <div>
          Users who have enabled Where the truck to collect location data from
          their mobile device may also enable Where the truck to share their
          location with their Food truck drivers from the time the requested is
          made to the start of the services. This can help improve Service for
          both Food Truck drivers and users, particularly in crowded areas.
        </div>
        <ul>
          <li>Notifications: account and trip updates</li>
        </ul>
        <div>
          Where the truck provides users with trip status notifications and
          updates related to activity on their account. These notifications are
          a necessary part of using the Where the truck app and cannot be
          disabled. However, users may choose the method by which they receive
          these notifications through the Settings > Privacy menus in the Where
          the truck app.
        </div>
        <ul>
          <li>Notifications: discounts and news</li>
        </ul>
        <div>
          Users may enable Where the truck to send push notifications about
          discounts and news from Where the truck. Push notifications may be
          enabled or disabled through the Settings > Privacy menus in the Where
          the truck app.
        </div>
        <ul>
          <li>Communications from Food truck driver</li>
        </ul>
        <div>
          Delivery recipients who have opted into communications from food truck
          vendors partners may stop sharing their data with each food truck
          driver in their Where the truck account settings.
        </div>
        <h5 style={{ color: "#2959a5" }}>B. Device permissions</h5>{" "}
        <div>
          Most mobile device platforms (iOS, Android, etc.) have defined certain
          types of device data that apps cannot access without the device
          owner’s permission, and these platforms have different methods for how
          that permission can be obtained. iOS devices notify users the first
          time the Where the truck app requests permission to access certain
          types of data and gives users the option to grant or refuse
          permission. Android devices notify users of the permissions that the
          Where the truck app seeks before their first use of the app and use of
          the app constitutes a grant of such permission.
        </div>
        <h5 style={{ color: "#2959a5" }}>C. Marketing opt-outs</h5>{" "}
        <div>
          Users may opt out of receiving promotional emails from Where the
          truck. Users may also opt out of receiving emails and other messages
          from Where the truck by following the unsubscribe instructions in
          those messages. We may still send users who have opted out
          non-promotional communications, such as receipts for rides or
          information about their account.
        </div>
        <h5 style={{ color: "#2959a5" }}>D. User data requests</h5>{" "}
        <div>
          Where the truck provides users with a variety of ways to learn about,
          control, and submit questions and comments about Where the truck’s
          handling of their data. To make a request, please email
          hello@wherethetruck.com.au.
        </div>
        <ul>
          <li>
            Accessing data: Users can ask for an explanation of the data we
            collect from them and how we use it.
          </li>
          <li>
            Receiving data: Users can ask for a copy of data that Where the
            truck collects from them with their consent or as necessary to
            provide our services.
          </li>
          <li>
            Changing or updating data: Users can edit the name, phone number,
            email address, payment method, and photo associated with their
            account through the Settings menu in Where the truck’s apps. They
            may also ask that Where the truck change or update their data,
            including if they believe such data is inaccurate or incomplete.
          </li>
          <li>
            Deleting data: Users may request deletion of their account at any
            time through the Settings > Privacy menus in the Where the truck
            app, or through
          </li>
          <li>
            Objections, restrictions, and complaints: Users may request that we
            stop using all or some of their personal data, or that we limit our
            use of their data. Where the truck may continue to process data
            after such objection or request to the extent required or permitted
            by law.
          </li>
        </ul>
        <div>
          In addition, depending on their location, users may have the right to
          file a complaint relating to Where the truck’s handling of their
          personal data with the data protection authority in their country.
        </div>
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>V. Updates to this notice</h4>
        <div>
          We may occasionally update this notice. Use of our services after an
          update constitutes consent to the updated notice to the extent
          permitted by law.
        </div>
        <div>
          We may occasionally update this notice. If we make significant
          changes, we will notify users in advance of the changes through the
          Where the truck apps or through other means, such as email. We
          encourage users to periodically review this notice for the latest
          information on our privacy practices. After such notice, use of our
          services by users in countries outside the European Union will be
          understood as consent to the updates to the extent permitted by law.
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default AppPrivacyPolicy;
