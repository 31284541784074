import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'
class PinComponent extends Component {
    render() {
        return (
            <div>
                <LazyLoad

                    width={100}
                    height={100}
                    debounce={false}
                    offsetVertical={500}
                >
                    <Img
                        style={{ width: 32 }}
                        src={[
                            this.props.logo,
                            require('../../assets/img/empty_logo.png')
                        ]}

                    />
                </LazyLoad>
            </div>
        );
    }
}

export default PinComponent;