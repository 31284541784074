const { NODE_ENV } = process.env;

//const devUrl = "http://3.26.39.128:8080";

const development = {
  // BASE_URI: "http://3.26.39.128:8080",
  // devUrl: "http://3.26.39.128:8080",
  BASE_URI: "http://13.54.79.34:8080",
  devUrl: "http://13.54.79.34:8080",
};
// TODO: set the production values
const production = {
  BASE_URI: "https://wherethetruckat.com",
  devUrl: "https://wherethetruckat.com",
};

//export default NODE_ENV === "development" ? development : production;

export default production;
// export default development;
