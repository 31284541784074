import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import {
  store,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store/index";
import * as Sentry from "@sentry/react";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
function EditableTable(props) {
  console.log(props);
  const [rows, setRows] = useState([]);
  const [systemWages_Total, set_systemWages_Total] = useState(0.0);
  const [systemWages_subTotal, set_systemWages_subTotal] = useState(0.0);
  const [systemWages_GST, set_systemWages_GST] = useState(0.0);
  const [isGSTsystemWages, set_isGSTsystemWages] = useState("false");
  const [siteFee_subTotal, set_siteFee_subTotal] = useState(0.0);
  const [siteFee_GST, set_siteFee_GST] = useState(0.0);
  const [siteFee_Total, set_siteFee_Total] = useState(0.0);
  const [isGSTsiteFee, set_isGSTsiteFee] = useState("false");
  const [subTotalExpenses, set_subTotalExpenses] = useState(0.0);
  const [gstExpenses, set_gstExpenses] = useState(0.0);
  const [totalEventExpenses, set_totalEventExpenses] = useState(0.0);
  const [eventId, set_eventId] = useState(props.eventId);
  const [eventName, set_eventName] = useState(props.eventName);
  const [vendorId, set_vendorId] = useState(props.vendorId);
  const [vendorName, set_vendorName] = useState(props.vendorName);
  const [totalExpenses, set_totalExpenses] = useState(0.0);
  const [totalStockUsed, set_totalStockUsed] = useState(0.0);
  const [totalSales, set_totalSales] = useState(0.0);
  const [updatorName, set_updatorName] = useState("");
  const [updatedTime, set_updatedTime] = useState("");
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const addRow = () => {
    console.log(rows);
    setRows([
      ...rows,
      {
        title: "",
        isGST: false,
        subtotal: 0.0,
        grandtotal: 0.0,
        percentage: 0.0,
      },
    ]);
  };

  const getTotalExpenses = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_event_organiser_expenses",
          // vendor_id: parseFloat(getFromLocalStorage("selectedVendorId")),
          // event_id: parseFloat(getFromLocalStorage("selectedEventId")),
          event_id: parseInt(eventId),
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        set_totalSales(20000);
        if (response.success) {
          console.log(response);
          console.log(response.shifts_data.total_cost);
          if (
            response.event_expenses_details &&
            response.event_expenses_details.length === 0
          ) {
            console.log("in here");
            setFetchedExpenses([]);
            console.log(fetchedExpenses);
            set_totalStockUsed(0.0);
            set_totalEventExpenses(0.0);
            set_totalSales(response.total_sales);
            set_totalStockUsed(response.total_cost_for_stock_used);
            set_updatorName(
              response.event_expenses_details.operator__first_name +
                " " +
                response.event_expenses_details.operator__last_name
            );
            set_updatedTime(response.event_expenses_details.timestamp);
            set_siteFee_Total(0.0);
            set_siteFee_subTotal(0.0);
            set_siteFee_GST(0.0);
            set_subTotalExpenses(0.0);
            set_gstExpenses(0.0);
            set_totalExpenses(0.0);
          } else {
            setFetchedExpenses(response.event_expenses_details.expenses_list);
            set_totalStockUsed(response.total_cost_for_stock_used);
            set_totalEventExpenses(
              response.event_expenses_details.total_expenses_for_the_event
            );
            set_totalSales(response.total_sales);
            set_updatorName(
              response.event_expenses_details.operator__first_name +
                " " +
                response.event_expenses_details.operator__last_name
            );
            set_updatedTime(response.event_expenses_details.timestamp);
            set_siteFee_Total(response.event_expenses_details.total_site_fees);
            set_siteFee_subTotal(
              response.event_expenses_details.sub_total_site_fees
            );
            set_siteFee_GST(response.event_expenses_details.gst_on_site_fees);
            set_subTotalExpenses(
              response.event_expenses_details.sub_total_event_costs
            );
            set_gstExpenses(
              response.event_expenses_details.gst_total_event_costs
            );
            set_totalExpenses(
              response.event_expenses_details.total_event_costs
            );
          }
          if (response.shifts_data && response.shifts_data.length !== 0) {
            const totalCostFormatted = Number(
              response.shifts_data.total_cost
            ).toFixed(2);

            set_systemWages_Total(totalCostFormatted);
            set_systemWages_subTotal(totalCostFormatted);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };

  const postExpenses = () => {
    var url = config.BASE_URI + apiPaths.operatorData;
    // Filter out rows with blank titles
    const filteredRows = rows.filter((row) => row.title.trim() !== "");

    // Calculate the total of event expenses
    const eventExpensesTotal = filteredRows.reduce(
      (total, row) => total + row.grandtotal,
      0
    );

    // Calculate the total GST value for event expenses
    const gstExpensesTotal = filteredRows.reduce((total, row) => {
      if (row.isGST) {
        return total + (row.grandtotal - row.subtotal);
      }
      return total;
    }, 0);

    // Calculate the total of all expenses including site fees
    let totalExpenses = eventExpensesTotal + parseFloat(siteFee_Total);
    totalExpenses = totalExpenses + parseFloat(systemWages_Total);

    // Calculate the total GST value for site fees
    const siteFeeGST = parseFloat(siteFee_GST);

    // Calculate the total GST value for all expenses
    const totalGST = gstExpensesTotal + siteFeeGST;

    const subTotal = totalExpenses - totalGST;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_event_expenses",
          vendor_id: parseFloat(getFromLocalStorage("selectedVendorId")),
          event_id: parseFloat(getFromLocalStorage("selectedEventId")),
          operator_id: parseFloat(
            JSON.parse(getFromLocalStorage("Operator"))[0].id
          ),
          sub_total_site_fees: parseFloat(siteFee_subTotal),
          total_site_fees: parseFloat(siteFee_Total),
          gst_on_site_fees: parseFloat(siteFeeGST),
          sub_total_system_wages: parseFloat(systemWages_subTotal),
          total_system_wages: parseFloat(systemWages_Total),
          gst_on_system_wages: parseFloat(systemWages_GST),
          additional_expenses: filteredRows.map((row) => ({
            title: row.title,
            isGST: row.isGST,
            grandtotal: row.grandtotal,
            subtotal: row.subtotal,
            gst: row.isGST ? (row.grandtotal - row.subtotal).toFixed(2) : 0, // Include GST value
            percentage: row.grandtotal
              ? ((row.grandtotal / totalSales) * 100).toFixed(2)
              : 0,
          })),
          sub_total_expenses: parseFloat(subTotalExpenses),
          gst_expenses: gstExpensesTotal.toFixed(2),
          total_expenses: totalExpenses,
          total_gst: totalGST, // Include total GST value
          subTotal: subTotal,
          timestamp: moment(new Date()).format("DD MMM YYYY hh:mm A"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          alert("Expenses added successfully");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    const pdfWidth = 595;
    const pdfHeight = 842;
    html2canvas(input, {
      scale: 3,
      useCORS: true,
      width: input.scrollWidth,
      height: input.scrollHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p", // Portrait orientation
        unit: "pt",
        format: [pdfWidth, pdfHeight],
      });
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("report.pdf");
    });
  };

  const updateRow = (index, key, value) => {
    const newRows = [...rows];
    newRows[index][key] = value;
    if (key === "isGST" || key === "grandtotal") {
      if (newRows[index]["isGST"]) {
        newRows[index]["subtotal"] = parseFloat(
          (newRows[index]["grandtotal"] / 1.1).toFixed(2)
        );
      } else {
        newRows[index]["subtotal"] = parseFloat(newRows[index]["grandtotal"]);
      }
    }
    newRows[index]["percentage"] = (
      (newRows[index]["grandtotal"] / totalSales) *
      100
    ).toFixed(2);
    console.log("hello", newRows);
    setRows(newRows);
  };

  useEffect(() => {
    const stockUsed =
      totalStockUsed > 0
        ? [
            {
              title: "Stock",
              isGST: true,
              subtotal: (totalStockUsed / 1.1).toFixed(2), // Calculate assuming the amount includes GST
              grandtotal: totalStockUsed.toFixed(2),
              percentage: ((totalStockUsed / totalSales) * 100).toFixed(2),
            },
          ]
        : [];

    if (fetchedExpenses) {
      const newRows = [
        ...fetchedExpenses.map((expense) => ({
          title: expense.title,
          isGST: expense.is_gst_applied,
          subtotal: expense.sub_total,
          grandtotal: expense.total,
          percentage: expense.percentage,
        })),
        ...stockUsed, // Add the stock row at the end
      ];
      setRows(newRows);
    }
  }, [fetchedExpenses, totalStockUsed, totalSales]);

  useEffect(() => {
    const rows = [];
    setRows(rows);
    console.log("in");
  }, []);

  useEffect(() => {
    set_eventId(props.eventId);
    set_vendorId(props.vendorId);
    set_vendorName(props.vendorName);
  }, [props.eventId, props.vendorId]);
  useEffect(() => {
    set_vendorName(props.vendorName);
  }, [props.vendorName]);
  useEffect(() => {
    set_eventName(props.eventName);
  }, [props.eventName]);
  useEffect(() => {
    console.log("changed 2", eventId, vendorId);
    getTotalExpenses(); // Call getTotalExpenses whenever eventId and vendorId change
  }, [eventId, vendorId]);
  useEffect(() => {
    console.log(totalEventExpenses, totalStockUsed, totalSales);
  }, [totalEventExpenses]);
  useEffect(() => {
    console.log(rows);
  }, [rows]);

  useEffect(() => {
    // Calculate the total of all event expenses including initial conversions to ensure valid numbers
    const eventExpensesTotal = rows.reduce(
      (total, row) => total + Number(row.grandtotal), // Ensure grandtotal is treated as a number
      0
    );

    // Ensure siteFee_Total is treated as a number and then calculate newTotalExpenses
    const newTotalExpenses = parseFloat(
      (eventExpensesTotal + Number(siteFee_Total)).toFixed(2)
    );

    // Calculate subTotalExpenses by reducing over rows and ensure subtotal is treated as a number
    const newSubTotalExpenses = parseFloat(
      rows
        .reduce((total, row) => {
          if (!row.isGST) {
            return total + Number(row.grandtotal);
          }
          return total + Number(row.subtotal);
        }, Number(siteFee_subTotal))
        .toFixed(2)
    );

    // Calculate gstExpenses by reducing over rows where GST is applicable
    const newGstExpenses = parseFloat(
      rows
        .reduce((total, row) => {
          if (row.isGST) {
            return total + (Number(row.grandtotal) - Number(row.subtotal));
          }
          return total;
        }, Number(siteFee_GST))
        .toFixed(2)
    );

    // Update the state with the new values
    set_totalExpenses(newTotalExpenses);
    set_subTotalExpenses(newSubTotalExpenses);
    set_gstExpenses(newGstExpenses);
  }, [rows, siteFee_Total, siteFee_subTotal, siteFee_GST]);

  const csvDataDownload = [
    ...rows.map((vendor) => ({
      Expense: vendor.title,
      "Gross ($)": vendor.grandtotal,
      "Net ($)": vendor.subtotal,
      "GST ($)": vendor.subtotal - vendor.grandtotal,
      Percentage:
        totalSales !== 0
          ? ((vendor.grandtotal / totalSales) * 100).toFixed(2) + "%"
          : "0.00%",
    })),
    {
      Expense: "Site Fee",
      "Gross ($)": siteFee_Total,
      "Net ($)": siteFee_subTotal,
      "GST ($)": siteFee_GST,
      Percentage:
        totalSales !== 0
          ? ((siteFee_Total / totalSales) * 100).toFixed(2) + "%"
          : "0.00%",
    },
    {
      Expense: "System Wages",
      "Gross ($)": systemWages_Total,
      "Net ($)": systemWages_subTotal,
      "GST ($)": systemWages_GST,
      Percentage:
        totalSales !== 0
          ? ((systemWages_Total / totalSales) * 100).toFixed(2) + "%"
          : "0.00%",
    },
    {},
    { Description: "Total Expenses:", Value: totalExpenses },
    { Description: "Sub Total Expenses:", Value: subTotalExpenses },
    { Description: "GST:", Value: gstExpenses },
    { Description: "Total Stock Used:", Value: totalStockUsed },
    { Description: "Total Sales:", Value: totalSales },
    {
      Description:
        totalSales - totalExpenses - totalStockUsed > 0
          ? "Gross Profit"
          : "Gross Loss",
      Value: totalSales - totalExpenses - totalStockUsed,
    },
  ];
  const jsonToXLSX = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };
  return (
    <div>
      <div style={{ padding: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col xs={3} md={3} lg={3}>
            Total Event Expenses: ${totalExpenses}
          </Col>
          <Col xs={3} md={3} lg={3}>
            Sub Total Event Expenses: ${subTotalExpenses}
          </Col>
          <Col xs={3} md={3} lg={3}>
            Gst On Event Expenses: ${gstExpenses}
          </Col>
        </Row>
      </div>
      <div style={{ padding: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col xs={3} md={3} lg={3}>
            Total Stock Used: ${totalStockUsed}
          </Col>
          <Col xs={3} md={3} lg={3}>
            Total Sales: ${totalSales}
          </Col>
        </Row>
      </div>
      <div style={{ padding: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col xs={4} md={4} lg={4}>
            <b>Expense</b>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <b>Gross ($)</b>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <b>Net($)</b>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <b>GST($)</b>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <b>Percentage</b>
          </Col>
        </Row>
      </div>
      {rows.map((row, index) => (
        <Row key={index + 1}>
          <Col xs={4} md={4} lg={4}>
            <Form.Group className="mb-3">
              <Row>
                <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                  GST?
                  <Form.Check
                    type="checkbox"
                    checked={row.isGST}
                    onChange={() => updateRow(index, "isGST", !row.isGST)}
                  />
                </Col>
                <Col xs={8} md={8} lg={8} style={{ paddingRight: 0 }}>
                  <Form.Control
                    type="text"
                    placeholder="eg: Utility"
                    value={row.title}
                    onChange={(e) => updateRow(index, "title", e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="20.00"
                value={row.grandtotal}
                onChange={(e) =>
                  updateRow(index, "grandtotal", parseFloat(e.target.value))
                }
              />
            </Form.Group>
          </Col>
          <Col xs={2} md={2} lg={2}>
            {row.subtotal}
          </Col>
          <Col xs={2} md={2} lg={2}>
            {row.isGST ? (row.grandtotal - row.subtotal).toFixed(2) : 0.0}
          </Col>
          <Col xs={2} md={2} lg={2}>
            {row.grandtotal
              ? totalSales !== 0
                ? ((row.grandtotal / totalSales) * 100).toFixed(2) + "%"
                : "0.00%"
              : "0.00%"}
          </Col>
        </Row>
      ))}
      <div style={{ padding: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col xs={5} md={5} lg={5}></Col>
          <Col xs={3} md={3} lg={3}>
            <b>
              {totalSales - totalExpenses - totalStockUsed > 0
                ? "Gross Profit"
                : "Gross Loss"}
              :{totalSales - totalExpenses - totalStockUsed}
            </b>
          </Col>
          <Col xs={2} md={2} lg={2}></Col>
          <Col xs={2} md={2} lg={2}></Col>
        </Row>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Row>
          <Col className="d-flex">
            <Button onClick={addRow}>Add Row</Button>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button onClick={postExpenses} style={{ marginRight: "10px" }}>
              Update
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <div>
              <p>
                {updatorName && updatedTime
                  ? `Last Updated by ${updatorName} on ${updatedTime} `
                  : "Not Updated"}
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div id="pdf-content">
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={require("../../assets/img/logo-round.png")}
            alt="Logo"
            style={{ maxWidth: "100px" }}
          />
        </div>
        <h3 style={{ textAlign: "center" }}>Event Organiser Expenses Report</h3>
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <p>Total Sales: ${totalSales}</p>
          <p>Event: {eventName}</p>
        </div>
        <div>
          <Row className="padding-all-sides">
            <Col>
              <strong>Expense</strong>
            </Col>
            <Col>
              <strong>Gross ($)</strong>
            </Col>
            <Col>
              <strong>Net ($)</strong>
            </Col>
            <Col>
              <strong>GST ($)</strong>
            </Col>
            <Col>
              <strong>Percentage</strong>
            </Col>
          </Row>
          {rows.map((row, index) => (
            <Row key={index} className="padding-all-sides">
              <Col>{row.title}</Col>
              <Col>{row.grandtotal}</Col>
              <Col>{row.subtotal}</Col>
              <Col>
                {row.isGST ? (row.grandtotal - row.subtotal).toFixed(2) : 0.0}
              </Col>
              <Col>
                {row.grandtotal
                  ? totalSales !== 0
                    ? ((row.grandtotal / totalSales) * 100).toFixed(2) + "%"
                    : "0.00%"
                  : "0.00%"}
              </Col>
            </Row>
          ))}
          <Row className="padding-all-sides">
            <Col>
              <strong>Total Expenses</strong>
            </Col>
            <Col>
              <strong>{totalExpenses}</strong>
            </Col>
          </Row>
        </div>
      </div>
      <div className="center-button-report">
        <CsvDownloadButton
          data={csvDataDownload}
          onClick={() => jsonToXLSX(csvDataDownload, "event_expenses")}
          filename="expenses.csv"
          className="button-report"
          style={{
            background: "#ef3e6d",
            backgroundColor: "#ef3e6d",
            borderRadius: "6px",
            border: "1px solid #ef3e6d",
            display: "inline-block",
            cursor: "pointer",
            color: "#ffffff",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "6px 24px",
            textDecoration: "none",
          }}
        >
          Download Data
        </CsvDownloadButton>
      </div>
      <div className="center-button-report">
        <Button onClick={downloadPDF}>Download PDF</Button>
      </div>
    </div>
  );
}
export default EditableTable;
