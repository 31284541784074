import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import WTTImage from "../../../../assets/img/EVENT_REPORT.png";

const styles = StyleSheet.create({
  head: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `2px solid #3959a5`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  heading: {
    fontSize: 24,
    color: "#3959a5",
    fontWeight: "bold",
  },
  heading2: {
    paddingTop: 10,
    fontSize: 18,
    color: "#3959a5",
    fontWeight: "bold",
  },
  heading3: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    color: "#3959a5",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    margin: 10,
    padding: 30,
  },
  section: {
    // margin: 10,
    // padding: 30,
  },
  header: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold",
  },
  content: {
    fontSize: 12,
    marginBottom: 5,
  },
  contentQuestion: {
    fontSize: 13,
    marginBottom: 5,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  table: {
    marginTop: 10,
    flexDirection: "column",
    marginHorizontal: 20,
    marginBottom: 30,
    // borderColor: "black",
    // borderWidth: 1,
    // borderTopWidth: 0,
    // borderRightWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  post: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    flexDirection: "column",
    gap: 5,
  },
  postSummary: {
    fontStyle: "italic",
    fontSize: 10,
    paddingBottom: 5,
  },
  postInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  postUser: {
    fontWeight: "semibold",
    fontSize: 10,
    color: "#3959a5",
    flex: 1,
  },
  postDate: {
    fontSize: 10,
    color: "#333",
  },
  tableCell: {
    flex: 1,
    textAlign: "center",
    padding: 5,
    borderColor: "black",
    borderWidth: 1,
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    fontSize: 10,
  },
  headerCell: {
    fontWeight: "bold",
    fontSize: 12,
  },
});
const styles1 = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  detailsSection: {
    marginBottom: 30,
  },
  detail: {
    fontSize: 16,
    marginBottom: 5,
  },
  detailValue: {
    fontWeight: "bold",
  },
  tocSection: {
    marginTop: 20,
  },
  tocTitle: {
    fontSize: 20,
    marginBottom: 10,
  },
  tocItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  tocText: {
    fontSize: 16,
  },
  tocPage: {
    fontSize: 16,
  },
  contentSection: {
    alignItems: "center",
    padding: 20, // Add some padding around the content
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `2px solid #3959a5`,
    paddingBottom: 10,
  },
  salesReport: {
    fontSize: 24,
    color: "#3959a5",
    fontWeight: "bold",
  },
  contentSection: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
  iconAndText: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    display: "flex",
    justifyContent: "flex-start",
  },
  contentText: {
    fontSize: 14,
    marginLeft: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 24,
    bottom: 25,
    right: 50,
    color: "#3959a5",
  },
  footer: {
    borderTop: `2px solid #3959a5`,
    position: "absolute",
    bottom: 65,
    left: 40,
    right: 40,
  },
});

const SalesPredictionPdf = ({
  event,
  vendor,
  targetAmount,
  itemSalesDist,
  stockReqs,
  isBufferAdded,
}) => {
  const renderItemSalesDist = () => {
    if (!itemSalesDist) {
      return <Text></Text>;
    }

    return itemSalesDist && itemSalesDist.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>

            <Text style={styles.heading}>Projected Sales To Reach Forcasted Revenue</Text>
          </View>
          <View style={styles.table}>
            {/* Table header */}
            <View wrap={false} style={styles.tableRow} fixed>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Item Name
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Item Price
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Percentage of Total (%)
              </Text>

              <Text style={[styles.tableCell, styles.headerCell]}>
                Required Qty
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Avg Qty. per Event
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Revene Contribution
              </Text>
            </View>

            {/* Table rows for each vendor */}
            {itemSalesDist.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item.item_name ?? ""}</Text>
                <Text style={styles.tableCell}>{item.item_price ?? ""}</Text>
                <Text style={styles.tableCell}>
                  {item.percentage_of_total ?? ""}
                </Text>
                <Text style={styles.tableCell}>
                  {item.required_quantity ?? ""}
                </Text>
                <Text style={styles.tableCell}>
                  {item.average_quantity_per_event ?? ""}
                </Text>
                <Text style={styles.tableCell}>
                  {item.revenue_contribution?.toFixed(2) ?? ""}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderStockReqs = () => {
    if (!stockReqs) {
      return <Text></Text>;
    }

    return stockReqs && stockReqs.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>
              Stock Required To Reach Forcasted Revenue
            </Text>
          </View>
          <View>
            <Text style={{ marginTop: 10, fontStyle: "italic" }}>
              {isBufferAdded
                ? "NOTE: Please bare in mind there is a 10% buffer added to the stock"
                : ""}
            </Text>

          </View>
          <View style={styles.table}>
            {/* Table header */}
            <View wrap={false} style={styles.tableRow} fixed>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Item Name
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>Qty</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Percentage of Total (%)
              </Text>
              <Text style={[styles.tableCell, styles.headerCell]}>Unit</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>
                Measurement Type
              </Text>
            </View>

            {/* Table rows for each vendor */}
            {stockReqs.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item.item_name ?? ""}</Text>
                <Text style={styles.tableCell}>
                  {item.quantity
                    ? isBufferAdded
                      ? item.quantity?.toFixed(1)
                      : (item.quantity * 0.9)?.toFixed(1)
                    : ""}
                </Text>
                <Text style={styles.tableCell}>
                  {item.percentage_of_total ?? ""}
                </Text>
                <Text style={styles.tableCell}>{item.unit ?? ""}</Text>
                <Text style={styles.tableCell}>
                  {item.measurement_type ?? ""}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Sales Prediction
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {event?.name ?? ""}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {event.start_date} - {event.end_date}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Vendor: {vendor?.title}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Target Amount: {targetAmount}
            </Text>
          </div>
        </View>
      </Page>

      {renderItemSalesDist()}
      {renderStockReqs()}
    </Document>
  );
};

export default SalesPredictionPdf;
