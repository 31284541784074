import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table, Button, Alert, Modal } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Footer from "../../components/UI/Footer";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import EOIPage from "../../components/UI/EOI/index";
import "./style.css";
import { AWS_URL } from "../../assets/Constants";

const HealthDepartmentAccessByEventScreen = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [eoiName, setEoiName] = useState(null);
  const [isOpenEOIModal, set_isOpenEOIModal] = useState(false);
  const location = useLocation();

  const handleClose = () => {
    set_isOpenEOIModal(false);
  };

  const handleOpen = () => {
    set_isOpenEOIModal(true);
  };

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const hashcode = query.get("hashcode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          config.BASE_URI + apiPaths.organiserData,
          {
            payload: {
              body: {
                query_type: "fetch_departmental_access",
                hashcode: hashcode,
              },
            },
          }
        );

        if (response.data.success) {
          setData(response.data.vendors[0]);
          setEoiName(response.data.eoi_name);
        } else {
          setError(response.data.err);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [hashcode]);

  const downloadAllStatements = async () => {
    const zip = new JSZip();
    const folder = zip.folder("StatementsOfTrades");

    for (const vendor of data) {
      if (vendor.statement_of_trade_file) {
        const url = `${AWS_URL}/${vendor.statement_of_trade_file}`;
        const filename = `${vendor.title}_StatementOfTrade.pdf`;
        const response = await fetch(url);
        const blob = await response.blob();
        folder.file(filename, blob);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "StatementsOfTrades.zip");
    });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ResponsiveNavBar
        showBack={false}
        backUrl={"/"}
        ticketState={{}}
        products={{}}
        loggedIn={false}
        username={""}
        userd={""}
        dispatch={() => {}}
      ></ResponsiveNavBar>
      <Container>
        <h1>Food Safety Documents For {eoiName}</h1>
        <Alert variant="info">
          <h4>Health Department Notice</h4>
          <p>
            This page provides an overview of the food safety documentation required for the event "{eoiName}". The table below lists all vendors along with links to their Food Safety Certificates, Statements of Trade, and Council Registrations. You can also download all Statements of Trade in a single ZIP file by clicking the button below.
          </p>
        </Alert>

        <Button variant="primary" onClick={downloadAllStatements}>
          Download All Statements of Trades
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Food Safety Certificate</th>
              <th>Statement Of Trades</th>
              <th>Council Registration</th>
            </tr>
          </thead>
          <tbody>
            {data.map((vendor, index) => (
              <tr key={index}>
                <td>{vendor.title}</td>
                <td>
                  {vendor.food_safety_certificate ? (
                    <a
                      href={`${AWS_URL}/${vendor.food_safety_certificate}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Food Safety Certificate
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {vendor.statement_of_trade_file ? (
                    <a
                      href={`${AWS_URL}/${vendor.statement_of_trade_file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Statement Of Trade
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {vendor.food_refistration_docs ? (
                    <a
                      href={`${AWS_URL}/${vendor.food_refistration_docs}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Council Registration
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal show={isOpenEOIModal} onHide={handleClose}>
        <EOIPage handleClose={handleClose} />
      </Modal>
      <Footer />
    </>
  );
};

export default HealthDepartmentAccessByEventScreen;
