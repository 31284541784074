import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import axios from "axios";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { AWS_URL } from "../../assets/Constants";
import { format, parse } from "date-fns";

const EditableForm = ({ selectedBooking }) => {
  const { control, handleSubmit, register, setValue } = useForm(); // Include 'register' here

  useEffect(() => {
    if (selectedBooking) {
      Object.keys(selectedBooking).forEach((key) => {
        if (key === "event_date" && selectedBooking[key]) {
          const dateValue = new Date(selectedBooking[key]);
          setValue(key, isNaN(dateValue) ? new Date() : dateValue); // Fallback to current date if invalid
        } else {
          setValue(key, selectedBooking[key]);
        }
      });
    }
  }, [selectedBooking, setValue]);
  useEffect(() => {
    if (selectedBooking) {
      // Set all form values based on selectedBooking
      Object.keys(selectedBooking).forEach((key) => {
        // Your existing code for setting other form values...
      });

      // Specifically handle runsheet_status with setValue
      setValue("runSheetStatus", selectedBooking.runsheet_status || "");
    }
  }, [selectedBooking, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append all fields to FormData, but handle document file inputs conditionally
    Object.keys(data).forEach((key) => {
      if (key.includes("document_option")) {
        // Only append the file input if a new file is selected (check if it's a File object)
        if (data[key] instanceof FileList && data[key].length > 0) {
          formData.append(key, data[key][0]);
        }
        // Do not append anything if no new file is selected
      } else {
        // For all other fields, append the value directly
        formData.append(key, data[key]);
      }
    });

    // Submit formData to your API endpoint
    console.log(formData); // For debugging; replace with API call
    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.editRunsheet,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle response
      if (response.data.success) {
        alert("Runsheets edited successfully!");
        // Optionally, handle additional success actions like redirecting
      } else {
        alert("Error please try again!");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("Error please try again!");
    }
  };

  // Utility to parse a time string to 24-hour format
  function parseTimeForInput(timeStr) {
    if (!timeStr || !timeStr.match(/(\d{1,2}):(\d{2})\s?(AM|PM)?/i)) return "";

    let [hours, minutes] = timeStr.split(/[: ]/);
    let meridian = timeStr.match(/AM|PM/i);

    if (meridian) {
      meridian = meridian[0].toUpperCase();
      hours = parseInt(hours, 10);
      if (meridian === "PM" && hours < 12) hours += 12;
      if (meridian === "AM" && hours === 12) hours = 0;
    } else {
      hours = parseInt(hours, 10);
    }

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  }

  // Utility to format a time string to "11:30 AM/PM" for submission
  function formatTimeForSubmit(timeStr) {
    if (!timeStr) return "";

    const [hours24, minutes] = timeStr.split(":");
    const hours = parseInt(hours24, 10);
    const meridian = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;

    return `${adjustedHours}:${minutes} ${meridian}`;
  }

  const convertTo24HourFormat = (timeStr) => {
    if (!timeStr) return "";

    // Check if timeStr includes AM/PM
    const isPM = timeStr.includes("PM");
    let [hours, minutes] = timeStr.match(/\d+/g);

    hours = parseInt(hours, 10) % 12;
    if (isPM) hours += 12;

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  useEffect(() => {
    if (selectedBooking) {
      Object.keys(selectedBooking).forEach((key) => {
        if (key.endsWith("_date")) {
          // Assuming date strings are in 'dd MMM yyyy' format
          const parsedDate = parse(
            selectedBooking[key],
            "dd MMM yyyy",
            new Date()
          );
          setValue(key, isNaN(parsedDate) ? new Date() : parsedDate);
        } else if (key.endsWith("_time")) {
          // Convert 12-hour time strings to 24-hour format for <input type="time">
          setValue(key, convertTo24HourFormat(selectedBooking[key]));
        } else {
          setValue(key, selectedBooking[key]);
        }
      });
    }
  }, [selectedBooking, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-field">
        <label htmlFor="runSheetStatus">Runsheet Status:</label>
        <select
          {...register("runSheetStatus")}
          defaultValue={selectedBooking?.runsheet_status || ""}
          className="form-field"
        >
          <option value="">Select Runsheet Status</option>
          <option value="Job pending: Enquiry made - awaiting confirmation from the client">
            Job pending: Enquiry made - awaiting confirmation from the client
          </option>
          <option value="Job confirmed - awaiting client payment">
            Job confirmed - awaiting client payment
          </option>
          <option value="Job confirmed - awaiting Runsheet information">
            Job confirmed - awaiting Runsheet information
          </option>
          <option value="Job confirmed - Runsheet complete - ready to print">
            Job confirmed - Runsheet complete - ready to print
          </option>
          <option value="Job confirmed - Runsheet complete and payment received - ready to print">
            Job confirmed - Runsheet complete and payment received - ready to
            print
          </option>
          <option value="Job Cancelled by organiser">
            Job Cancelled by organiser
          </option>
          <option value="Job cancelled by client">
            Job Cancelled by client
          </option>
        </select>
      </div>

      {/* Event Date */}
      <div className="form-field">
        <label>Event Date:</label>
        <Controller
          name="event_date"
          control={control}
          render={({ field }) => (
            <DatePicker
              placeholderText="Select event date"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              dateFormat="dd MMM yyyy"
            />
          )}
        />
      </div>
      {/* Event Start Time */}
      <div className="form-field">
        <label>Event Start Time:</label>
        <Controller
          name="start_time"
          control={control}
          render={({ field }) => <input type="time" {...field} />}
        />
      </div>
      {/* Event End Time */}
      <div className="form-field">
        <label>Event End Time:</label>
        <Controller
          name="end_time"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="time"
              {...field}
              value={parseTimeForInput(field.value)}
            />
          )}
        />
      </div>
      <div className="form-field">
        <label htmlFor="location" className="form-label">
          Location:
        </label>
        <textarea
          className="form-textarea"
          placeholder="Enter Location here..."
          rows="4"
          cols="75"
          id="location"
          name="location"
          {...register("location")} // Use 'register' to connect this textarea to react-hook-form
          defaultValue={
            selectedBooking?.location.replace(/\\r\\n/g, "\n") || ""
          }
        ></textarea>
      </div>
      {/* Event Service Fee */}
      <div className="form-field">
        <label>Event Service Fee:</label>
        <input
          type="number"
          {...register("event_service_fee")} // Corrected to use 'register'
          defaultValue={selectedBooking?.event_service_fee || 0}
          placeholder="Enter service fee"
        />
      </div>
      {/* Is Full Day Event */}
      <div className="form-field">
        <label>Is Full Day Event?:</label>
        <Controller
          name="full_day_event"
          control={control}
          defaultValue={selectedBooking?.full_day_event || false}
          render={({ field }) => (
            <input type="checkbox" {...field} checked={field.value} />
          )}
        />
      </div>
      {/* Power */}
      <div className="form-field">
        <label>Power:</label>
        <input
          {...register("power")}
          defaultValue={selectedBooking?.power || ""}
          placeholder="Specify power requirements"
        />
      </div>
      {/* Are Extra Items Allowed */}
      <div className="form-field">
        <label>Are Extra Items Allowed?:</label>
        <Controller
          name="are_extra_items_allowed"
          control={control}
          defaultValue={selectedBooking?.are_extra_items_allowed || false}
          render={({ field }) => (
            <input type="checkbox" {...field} checked={field.value} />
          )}
        />
      </div>
      {/* Event Special Request Required */}
      <div className="form-field">
        <label>Event Special Request Required?:</label>
        <Controller
          name="event_special_request_required"
          control={control}
          defaultValue={
            selectedBooking?.event_special_request_required || false
          }
          render={({ field }) => (
            <input type="checkbox" {...field} checked={field.value} />
          )}
        />
      </div>
      {/* Bump In Date */}
      <div className="form-field">
        <label>Bump In Date:</label>
        <Controller
          name="bump_in_date"
          control={control}
          render={({ field }) => (
            <DatePicker
              placeholderText="Select bump in date"
              onChange={(date) => field.onChange(date)}
              selected={field.value ? new Date(field.value) : null}
              dateFormat="dd MMM yyyy"
            />
          )}
        />
      </div>
      {/* Bump In Start Time */}
      <div className="form-field">
        <label>Bump In Start Time:</label>
        <Controller
          name="bump_in_start_time"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="time"
              {...field}
              value={parseTimeForInput(field.value)}
            />
          )}
        />
      </div>
      {/* Bump In End Time */}
      <div className="form-field">
        <label>Bump In End Time:</label>
        <Controller
          name="bump_in_end_time"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="time"
              {...field}
              value={parseTimeForInput(field.value)}
            />
          )}
        />
      </div>
      {/* Bump Out Date */}
      <div className="form-field">
        <label>Bump Out Date:</label>
        <Controller
          name="bump_out_date"
          control={control}
          render={({ field }) => (
            <DatePicker
              placeholderText="Select bump out date"
              onChange={(date) => field.onChange(date)}
              selected={field.value || new Date()} // Fallback to current date
              dateFormat="dd MMM yyyy"
            />
          )}
        />
      </div>
      {/* Bump Out Start Time */}
      <div className="form-field">
        <label>Bump Out Start Time:</label>
        <Controller
          name="bump_out_start_time"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="time"
              {...field}
              value={parseTimeForInput(field.value)}
            />
          )}
        />
      </div>
      {/* Bump Out End Time */}
      <div className="form-field">
        <label>Bump Out End Time:</label>
        <Controller
          name="bump_out_end_time"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="time"
              {...field}
              value={parseTimeForInput(field.value)}
            />
          )}
        />
      </div>
      <div className="form-field">
        <label htmlFor="truck_manager">Truck Manager:</label>
        <input
          type="text"
          id="truck_manager"
          {...register("truck_manager")}
          defaultValue={selectedBooking?.truck_manager || ""}
          placeholder="Enter truck manager's name"
        />
      </div>

      <div className="form-field">
        <label htmlFor="site_contact">Site Contact:</label>
        <input
          type="text"
          id="site_contact"
          {...register("site_contact")}
          defaultValue={selectedBooking?.site_contact || ""}
          placeholder="Enter site contact's name"
        />
      </div>

      <div className="form-field">
        <label htmlFor="site_contact_number">Site Contact Number:</label>
        <input
          type="tel"
          id="site_contact_number"
          {...register("site_contact_number")}
          defaultValue={selectedBooking?.site_contact_number || ""}
          placeholder="Enter site contact's number"
        />
      </div>

      <div className="form-field">
        <label htmlFor="extend_cut_off_by">Extend Cut Off By:</label>
        <input
          type="number"
          id="extend_cut_off_by"
          {...register("extend_cut_off_by")}
          defaultValue={selectedBooking?.extend_cut_off_by || 0}
          placeholder="Enter extension time in hours"
        />
      </div>

      <div className="form-field">
        <label htmlFor="truck_manager_email">Truck Manager Email:</label>
        <input
          type="email"
          id="truck_manager_email"
          {...register("truck_manager_email")}
          defaultValue={selectedBooking?.truck_manager_email || ""}
          placeholder="Enter truck manager's email"
        />
      </div>

      <div className="form-field">
        <label htmlFor="other_notes" className="form-label">
          Other Notes:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter other notes here..."
          id="other_notes"
          name="other_notes"
          {...register("other_notes")}
          defaultValue={selectedBooking?.other_notes || ""}
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="beverage_notes" className="form-label">
          Beverage Notes:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter beverage notes here..."
          id="beverage_notes"
          name="beverage_notes"
          {...register("beverage_notes")}
          defaultValue={selectedBooking?.beverage_notes || ""}
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="vouchers_notes" className="form-label">
          Vouchers Notes:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter vouchers notes here..."
          id="vouchers_notes"
          name="vouchers_notes"
          {...register("vouchers_notes")}
          defaultValue={selectedBooking?.vouchers_notes || ""}
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="bump_in_out_notes" className="form-label">
          Bump In/Out Notes:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter bump in/out notes here..."
          id="bump_in_out_notes"
          name="bump_in_out_notes"
          {...register("bump_in_out_notes")}
          defaultValue={selectedBooking?.bump_in_out_notes || ""}
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="multiple_day_trading_options" className="form-label">
          Multiple Day Trade Options:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter options..."
          id="multiple_day_trading_options"
          name="multiple_day_trading_options"
          {...register("multiple_day_trading_options")}
          defaultValue={selectedBooking?.multiple_day_trading_options || ""}
        ></textarea>
      </div>
      <div className="form-field">
        <label
          htmlFor="multiple_day_trading_time_options"
          className="form-label"
        >
          Multiple Day Trading Time Options:
        </label>
        <textarea
          className="form-textarea"
          rows="4"
          cols="75"
          placeholder="Enter options..."
          id="multiple_day_trading_time_options"
          name="multiple_day_trading_time_options"
          {...register("multiple_day_trading_time_options")}
          defaultValue={
            selectedBooking?.multiple_day_trading_time_options || ""
          }
        ></textarea>
      </div>
      <div className="form-field">
        <label htmlFor="first_additional_document_name" className="form-label">
          First Additional Document Name:
        </label>
        <input
          placeholder="Document Name"
          type="text"
          id="first_additional_document_name"
          name="first_additional_document_name"
          {...register("first_additional_document_name")}
          defaultValue={selectedBooking?.first_additional_document_name || ""}
        />
      </div>
      <div className="form-field">
        <label htmlFor="firstAdditionDocumentOption" className="form-label">
          First Additional Document Option:
        </label>
        {selectedBooking?.first_addition_document_option ? (
          <a
            className="form-link"
            href={
              AWS_URL + "/" + selectedBooking.first_addition_document_option
            }
            target="_blank"
            rel="noopener noreferrer" // For security reasons
          >
            View/Download Current Document
          </a>
        ) : (
          <p>No document uploaded</p>
        )}
        <input
          className="form-input"
          type="file"
          id="first_addition_document_option"
          name="first_addition_document_option"
          {...register("first_addition_document_option")}
        />
      </div>
      <div className="form-field">
        <label htmlFor="first_additional_document_name" className="form-label">
          First Additional Document Name:
        </label>
        <input
          placeholder="Document Name"
          type="text"
          id="first_additional_document_name"
          name="first_additional_document_name"
          {...register("first_additional_document_name")}
          defaultValue={selectedBooking?.first_additional_document_name || ""}
        />
      </div>
      <div className="form-field">
        <label htmlFor="first_addition_document_option" className="form-label">
          First Additional Document Option:
        </label>
        {selectedBooking?.first_addition_document_option ? (
          <a
            className="form-link"
            href={
              AWS_URL + "/" + selectedBooking.first_addition_document_option
            }
            target="_blank"
            rel="noopener noreferrer" // For security reasons
          >
            View/Download Current Document
          </a>
        ) : (
          <p>No document uploaded</p>
        )}
        <input
          className="form-input"
          type="file"
          id="first_addition_document_option"
          name="first_addition_document_option"
          {...register("first_addition_document_option")}
        />
      </div>
      <div className="form-field">
        <label htmlFor="second_additional_document_name" className="form-label">
          Second Additional Document Name:
        </label>
        <input
          placeholder="Document Name"
          type="text"
          id="second_additional_document_name"
          name="second_additional_document_name"
          {...register("second_additional_document_name")}
          defaultValue={selectedBooking?.second_additional_document_name || ""}
        />
      </div>
      <div className="form-field">
        <label htmlFor="second_addition_document_option" className="form-label">
          Second Additional Document Option:
        </label>
        {selectedBooking?.second_addition_document_option ? (
          <a
            className="form-link"
            href={
              AWS_URL + "/" + selectedBooking.second_addition_document_option
            }
            target="_blank"
            rel="noopener noreferrer" // For security reasons
          >
            View/Download Current Document
          </a>
        ) : (
          <p>No document uploaded</p>
        )}
        <input
          className="form-input"
          type="file"
          id="second_addition_document_option"
          name="second_addition_document_option"
          {...register("second_addition_document_option")}
        />
      </div>
      <div className="form-field">
        <label htmlFor="third_additional_document_name" className="form-label">
          Third Additional Document Name:
        </label>
        <input
          placeholder="Document Name"
          type="text"
          id="third_additional_document_name"
          name="third_additional_document_name"
          {...register("third_additional_document_name")}
          defaultValue={selectedBooking?.third_additional_document_name || ""}
        />
      </div>
      <div className="form-field">
        <label htmlFor="third_addition_document_option" className="form-label">
          Third Additional Document Option:
        </label>
        {selectedBooking?.third_addition_document_option ? (
          <a
            className="form-link"
            href={
              AWS_URL + "/" + selectedBooking.third_addition_document_option
            }
            target="_blank"
            rel="noopener noreferrer" // For security reasons
          >
            View/Download Current Document
          </a>
        ) : (
          <p>No document uploaded</p>
        )}
        <input
          className="form-input"
          type="file"
          id="third_addition_document_option"
          name="third_addition_document_option"
          {...register("third_addition_document_option")}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button type="submit" variant="contained" color="primary">
          Update Booking
        </Button>
      </div>
    </form>
  );
};

export default EditableForm;
