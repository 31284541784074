import React from "react";
import "./style.css";
import "./Body";
import "./Component/style1.css";
import Footer from "../../components/UI/Footer";
import { useRef, useState, useEffect, useCallback, memo } from "react";
import ChatBotApp from "../../screens/ChatBotApp";
import DatePicker from "react-datepicker";
import UploadDocs from "../UploadDocs";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import RegisterStall from "../RegisterStall";
import EventSection from "./Component/EventSection";
import CateringSection from "./Component/CateringSection";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import VendorNavBar from "./Component/VendorNavBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { TouchBackend } from "react-dnd-touch-backend";
import contact from "./contact.png";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getFromLocalStorage, saveToLocalStorage } from "../../store";
import update from "immutability-helper";
import { ComponentTypes, ItemTypes } from "./constant/Constant";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StallGalleryMobile from "./Component/StallGalleryMobile";
const VendorApplyEventScreen = memo(() => {
  const [cateringDroppedVendor, setCateringDroppedVendor] = useState(12);
  const [showCateringDetails, setShowCateringDetails] = useState(null);
  const states = ["ACT", "NSW", "VIC", "SA", "TAS", "NT", "WA", "QLD"];
  const [cuisines, setAvailableCuisines] = useState([]);
  const [appliedVendorEvents, setAppliedVendorEvents] = useState([]);
  const [appliedVendorCatering, setAppliedVendorCatering] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [pastEventsList, setPastEventsList] = useState([]);
  const [appliedEventsList, setAppliedEventsList] = useState([]);
  const [appliedPastEventsList, setAppliedPastEventsList] = useState([]);
  const [appliedVendorPastEvents, setAppliedVendorPastEvents] = useState([]);
  const [cateringList, setCateringList] = useState([]);
  const [catering, setCatering] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedState, setSelectedState] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [tempCateringList, setTempCateringList] = useState("");
  const [is_active, setActive] = useState(false);
  const [fetchedVendors, setFetchedVendors] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [operatorId, setOperatorId] = useState("");
  const [activeComponent, setActiveComponent] = useState(
    getFromLocalStorage("activeComponent") != "undefined" &&
      getFromLocalStorage("activeComponent") != "" &&
      getFromLocalStorage("activeComponent") != null
      ? getFromLocalStorage("activeComponent")
      : "home"
  );
  const [showForm, setShowForm] = useState(false);
  const [showBot, setShowBot] = useState(false);
  const chatBotRef = useRef(null);
  const handleFormClose = () => {
    setShowForm(false);
  };
  const handleBotClose = () => {
    setShowBot(false);
  };
  const handleLogoClick = () => {
    // Function to handle logo click event
    setShowBot(true); // Update state to show ChatBotApp
  };
  const handleCloseClick = () => {
    setShowBot(false);
  };
  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => {
    setShowUpload(false);
  };
  const style2 = {
    zIndex: 999,
  };

  useEffect(() => {
    const eventList = [];

    catering.map((eoi) => {
      const newEvent = {
        accept: "stallItem",
        eventDetails: eoi,
        vendorCatering: [],
      };

      appliedVendorCatering.forEach((appliedEvent) => {
        if (appliedEvent.eoi_id__id === eoi.id) {
          const matchingVendor = fetchedVendors.find(
            (vendor) => vendor.id === appliedEvent.vendor_outlets
          );
          if (matchingVendor) {
            newEvent.vendor.push(matchingVendor);
          }
        }
      });
      console.log(newEvent);
      cateringList.push(newEvent);
    });

    setCateringList([...eventList]);
    setOperatorId(JSON.parse(getFromLocalStorage("Operator"))[0].id);
  }, [appliedVendorCatering, fetchedVendors]);

  const getAppliedEvents = () => {
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_current_eoi_vendor_applications_v2",

          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          setAppliedVendorEvents(
            dataResponse && dataResponse.eoi ? dataResponse.eoi : []
          );
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  // const getAppliedPastEvents = () => {
  //   const url = config.BASE_URI + apiPaths.operatorData;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     payload: {
  //       body: {
  //         query_type: "get_eoi_stall_past_applications_for_operator",
  //         operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
  //       },
  //     },
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   fetch(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((dataResponse) => {
  //       if (dataResponse.success == true) {
  //         setAppliedVendorPastEvents(dataResponse.data);
  //       } else {
  //       }
  //     })
  //     .catch((error) => {
  //       Sentry.captureException(error);
  //       console.error(error);
  //     });
  // };

  // const getPastEOIs = () => {
  //   const url = config.BASE_URI + apiPaths.operatorData;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     payload: {
  //       body: {
  //         query_type: "get_past_eois",
  //       },
  //     },
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   fetch(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((dataResponse) => {
  //       if (dataResponse.success == true) {
  //         setPastEvents(dataResponse.eoi);
  //         // const transformedEventsList = dataResponse.eoi.map((event) => ({
  //         //   accept: "stallItem",
  //         //   eventDetails: {
  //         //     ...event,
  //         //   },
  //         //   vendor: [],
  //         // }));
  //         // setPastEventsList(transformedEventsList);
  //       } else {
  //       }
  //     })
  //     .catch((error) => {
  //       Sentry.captureException(error);
  //       console.error(error);
  //     });
  // };
  const getPastEOIsV2 = () => {
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_past_eois_v2",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          setPastEvents(dataResponse.eoi);
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_details_v3",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setFetchedVendors(
            result.stalls
              ? result.stalls.map((stall) => ({
                  ...stall,
                  id: stall.user_ptr_id,
                }))
              : []
          );
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  const getAppliedCatering = () => {
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_catering_stall_applications_for_operator",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          setAppliedVendorCatering(dataResponse.data);
          console.log(dataResponse.data);
          console.log(appliedVendorCatering);
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-available-cuisine",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(
            response.availableCuisines.map((object) => object.cuisine)
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchVendorIds();
    getAvailableCuisines();
    getAppliedEvents();
    getAppliedCatering();
    // getAppliedPastEvents();
    // getPastEOIs();
    getPastEOIsV2();
  }, []);

  const getCateringEvents = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_current_catering_requests_for_operators",
          start_date: startDate,
          end_date: endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          const newCateringList = dataResponse.catering_requests.map((item) => {
            return {
              accept: "stallItem",
              eventDetails: item,
              vendorCatering: [],
            };
          });
          setCateringList(newCateringList);
          setTempCateringList(newCateringList);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const fetchVendorDetails = (vendorID) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_vendor_details",
          vendor_ids: [vendorID],
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse);
          return dataResponse;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const handleDrop = useCallback(
    (index, item) => {
      return;
      setAppliedVendorEvents((st) => {
        const tempEvents = [...st];
        console.log(tempEvents, "TEMP");
        const vndr = {};
        if (item && item.logo && item.logo.includes("bucket.s3.amazonaws")) {
          const lastSlashIndex = item.logo.lastIndexOf("/");
          vndr.vendor_outlets__logo = item.logo.substring(lastSlashIndex + 1);
        }
        vndr.vendor_outlets__title = item.title;
        vndr.vendor_outlets__id = item.id;
        vndr.vendor_outlets = item.id;
        if (
          tempEvents &&
          tempEvents[index] &&
          tempEvents[index].pending_applications
        )
          tempEvents[index].pending_applications = [
            ...tempEvents[index].pending_applications,
            vndr,
          ];
        return [...tempEvents];
      });
      // setEventsList(
      //   update(eventsList, {
      //     [index]: {

      //       vendor: {
      //         $push: [item],
      //       },
      //     },
      //   })
      // );
    },
    [eventsList]
  );
  // const handleDropPast = useCallback(
  //   (index, item) => {
  //     setPastEventsList(
  //       update(pastEventsList, {
  //         [index]: {
  //           vendor: {
  //             $push: [item],
  //           },
  //         },
  //       })
  //     );
  //   },
  //   [pastEventsList]
  // );
  const handleDropPast = useCallback(() => {}, []);

  const handleDropCatering = useCallback(
    (index, item) => {
      saveToLocalStorage("droppedVendor", item.id);

      setCateringList(
        update(cateringList, {
          [index]: {
            vendorCatering: (arr = []) => {
              if (!Array.isArray(arr)) {
                return [item];
              }
              return [...arr, item];
            },
          },
        })
      );
    },
    [cateringList]
  );

  const removeVendor = useCallback(
    (index, vendor) => {
      const editList = [...eventsList];
      const result = editList[index].vendor.filter(
        (item) => item.id !== vendor.id
      );
      editList.splice(index, 1, {
        accept: ItemTypes.STALLITEM,
        eventDetails: eventsList[index].eventDetails,
        vendor: result,
      });
      setEventsList(editList);
    },
    [eventsList]
  );

  // const removeVendorPast = useCallback(
  //   (index, vendor) => {
  //     const editList = [...pastEventsList];
  //     const result = editList[index].vendor.filter(
  //       (item) => item.id !== vendor.id
  //     );
  //     editList.splice(index, 1, {
  //       accept: ItemTypes.STALLITEM,
  //       eventDetails: pastEventsList[index].eventDetails,
  //       vendor: result,
  //     });
  //     setPastEventsList(editList);
  //   },
  //   [pastEventsList]
  // );
  const removeVendorPast = useCallback(() => {}, []);

  const removeVendorCatering = useCallback(
    (index, vendorCatering) => {
      const editList = [...cateringList];
      const vendors_arr = JSON.parse(getFromLocalStorage("vendors_arr"));
      const updatedVendorsArr = vendors_arr.filter(
        (vendor) => vendor.vendor_id !== vendorCatering.id
      );
      const result = editList[index].vendorCatering.filter(
        (item) => item.id !== vendorCatering.id
      );
      editList.splice(index, 1, {
        accept: "stallItem",
        eventDetails: cateringList[index].eventDetails,
        vendorCatering: result,
      });
      setCateringList(editList);
      saveToLocalStorage("vendors_arr", JSON.stringify(updatedVendorsArr));
    },
    [cateringList]
  );
  const hasNative =
    document && (document.elementsFromPoint || document.msElementsFromPoint);

  function getDropTargetElementsAtPoint(x, y, dropTargets) {
    return dropTargets.filter((t) => {
      const rect = t.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
    enableMouseEvents: true,
  };

  const handleChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(event.target.value);
    let templist = tempCateringList;
    if (selectedState) {
      templist = tempCateringList.filter((item) =>
        item.eventDetails.location.includes(selectedState)
      );
    }
    setCateringList(templist);
  };
  const handleCuisineChange = (event) => {
    const cuisineType = event.target.value;
    setCuisineType(event.target.value);
    let templist = tempCateringList;
    if (cuisineType) {
      templist = tempCateringList.filter((item) =>
        item.eventDetails.cuisine.includes(cuisineType.toLowerCase())
      );
    }
    setCateringList(templist);
  };

  const canDrop = (list, itemId) => {
    if (list && list.some((vendor) => vendor.id === itemId)) {
      return false;
    } else {
      return true;
    }
  };
  const handleDragEnd = (event) => {
    if (
      event.over &&
      event.over.id &&
      event.active &&
      event.active.data &&
      event.active.data.current
    ) {
      if (
        event.over.data.current &&
        event.over.data.current.componentType === ComponentTypes.EVENT
      ) {
        const eventIndex = eventsList.findIndex((e) => {
          return e.eventDetails.id === event.over.id;
        });
        if (
          eventIndex > -1 &&
          canDrop(eventsList[eventIndex]?.vendor, event.active.data.current.id)
        ) {
          handleDrop(eventIndex, event.active.data.current);
          console.log("ITEM ADDED TO EVENT");
        }
      } else if (
        event.over.data.current &&
        event.over.data.current.componentType === ComponentTypes.CATERING
      ) {
        setShowCateringDetails(event.over.id);

        const eventIndex = cateringList.findIndex((e) => {
          return e.eventDetails.id === event.over.id;
        });
        if (
          eventIndex > -1 &&
          canDrop(
            cateringList[eventIndex]?.vendor,
            event.active.data.current.id
          )
        ) {
          setCateringDroppedVendor(event.active.data.current.id);
          handleDropCatering(eventIndex, event.active.data.current);
          console.log("ITEM ADDED TO CATERING");
        }
      }
    }
  };

  const ApplyEventComponent = ({ children, layout }) => (
    <>
      {children}
      <Stack direction="row" spacing={1}>
        <Box
          sx={{ maxWidth: 275 }}
          style={{ display: "block", margin: "0 auto" }}
        >
          <Card variant="outlined" onClick={() => setShowForm(true)}>
            <React.Fragment>
              <CardContent>
                <Typography
                  sx={{ fontSize: 16 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Add New Outlet
                </Typography>
                <AddCircleOutlineIcon
                  sx={{ fontSize: 60 }}
                  style={{ display: "block", margin: "0 auto" }}
                >
                  New Outlet
                </AddCircleOutlineIcon>
              </CardContent>
            </React.Fragment>
          </Card>
        </Box>
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingLeft: "35%",
        }}
      >
        <label className="container">
          <input
            type="checkbox"
            className="noneCheckbox"
            onChange={(e) => setActive(e.target.checked)}
          />
          <span className="checkbox-label"></span>
        </label>
      </div>
      {is_active === false && (
        <>
          <h1>Active Events</h1>
          <div className="legend-container">
            <div className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: "#39fc60" }}
              ></span>
              <span className="legend-text">Approved</span>
            </div>
            <div className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: "red" }}
              ></span>
              <span className="legend-text">Rejected</span>
            </div>
            <div className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: "#ffcc00" }}
              ></span>
              <span className="legend-text">Processing</span>
            </div>
          </div>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#2959a5",
                color: "white",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  ACTIVE EOIs
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EventSection
                  layout={layout}
                  operator={operatorId}
                  events={appliedVendorEvents.map((eoi) => ({
                    accept: ItemTypes.STALLITEM,
                    eventDetails: eoi,
                  }))}
                  removeVendor={removeVendor}
                  vendors={fetchedVendors}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#2959a5",
                color: "white",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                >
                  PAST EOIs
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EventSection
                  layout={layout}
                  operator={operatorId}
                  events={pastEvents.map((eoi) => ({
                    accept: ItemTypes.STALLITEM,
                    eventDetails: eoi,
                  }))}
                  removeVendor={removeVendor}
                  vendors={fetchedVendors}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {is_active && (
        <>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <p>Start Date</p>
              <DatePicker
                dateFormat={"dd/MM/yyyy"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <p>End Date</p>
              <DatePicker
                dateFormat={"dd/MM/yyyy"}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={new Date()}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <Button
                style={{ marginTop: 30 }}
                onClick={() => getCateringEvents()}
              >
                Apply
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <select value={selectedState} onChange={handleChange}>
                <option value="">All States</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <select value={cuisineType} onChange={handleCuisineChange}>
                <option value="">All Cuisines</option>
                {cuisines.map((cuisine) => (
                  <option key={cuisine} value={cuisine}>
                    {cuisine}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <CateringSection
            layout={layout}
            showCateringDetails={showCateringDetails}
            setShowCateringDetails={setShowCateringDetails}
            droppedVendor={cateringDroppedVendor}
            setDroppedVendor={setCateringDroppedVendor}
            events={cateringList}
            vendors={fetchedVendors}
            onDrop={handleDropCatering}
            removeVendor={removeVendorCatering}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <VendorNavBar />
      <div className="body">
        <Container>
          <Row>
            <Col>
              {activeComponent === "home" && (
                <>
                  {/* <div className="dnd-desktop"> */}
                  {/* <DndProvider backend={HTML5Backend}> */}
                  <ApplyEventComponent layout="DESKTOP">
                    <StallGalleryMobile
                      stallList={fetchedVendors}
                      operator={operatorId}
                    />
                  </ApplyEventComponent>
                  {/* </DndProvider> */}
                  {/* </div> */}
                  {/* <div className="dnd-mobile">
                    <DndProvider
                      backend={TouchBackend}
                      options={backendOptions}
                    >
                      <ApplyEventComponent layout="MOBILE">
                        <StallGalleryMobile
                          stallList={fetchedVendors}
                          operator={operatorId}
                        />
                      </ApplyEventComponent>
                    </DndProvider>
                  </div> */}
                </>
              )}
              {/* {activeComponent === "eoi" && (
                <VendorEOI eventsList={appliedEventsList} />
              )} */}

              {/* {activeComponent === "menu" && <OperatorMenuScreen />} */}
              {/* {activeComponent === "catering" && <OperatorCateringScreen />} */}
              {/* {activeComponent === "documents" && <OperatorDocuments />} */}
              {/* {activeComponent === "pos" && <POSManagement />} */}
              {/* {activeComponent === "faqs" && <FAQPage />} */}
              {/* {activeComponent === "check-in" && (
                <PreEventChecklist
                  vendors={vendors}
                  operatorId={operatorId}
                  events={appliedEventsList}
                />
              )}
              {activeComponent === "check-out" && (
                <PostEventChecklist
                  vendors={vendors}
                  operatorId={operatorId}
                  events={appliedEventsList}
                />
              )} */}

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showForm}
                onClose={handleFormClose}
                className="modalnvm"
              >
                <Box className="modalnvm-content">
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 10, float: "right" }}
                    onClick={handleFormClose}
                  />
                  <RegisterStall />
                </Box>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showUpload}
                onClose={handleUploadClose}
                className="modalnvm"
              >
                <Box className="modalnvm-content">
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 10, float: "right" }}
                    onClick={handleUploadClose}
                  />
                  <UploadDocs />
                </Box>
              </Modal>
              <div>
                {/* Render your logo here with an onClick handler */}

                <div className="image-container">
                  <h5>Chat with us!</h5>
                  <div className="coin">
                    <img src={contact} alt="Logo" onClick={handleLogoClick} />
                  </div>
                </div>
                {/* Render the ChatBotApp component with a ref */}
                <div className="image-container">
                  {showBot && (
                    <CloseIcon
                      className="plzHover"
                      fontSize="large"
                      style={{ margin: 10, float: "right" }}
                      onClick={handleCloseClick}
                    />
                  )}
                  {showBot && (
                    <Container sx={style2}>
                      <ChatBotApp ref={chatBotRef} />
                    </Container>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
});

export default Sentry.withErrorBoundary(VendorApplyEventScreen, {
  fallback: <ErrorFallbackComponent />,
});
