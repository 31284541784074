import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import "./style.css";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import DeviceIdentifier from "react-device-identifier";

const datesArray = [];
const ModalDate = (props) => {
  const { eoi_id } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    localStorage.removeItem("selected_eoi_dates");
    datesArray.splice(0, datesArray.length);
    fetchDates();
  }, []);

  const fetchDates = async () => {
    let dates = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_dates_for_current_eoi",
          eoiID: eoi_id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const dates = data.eoi_dates;
        dates.map((date) => {
          datesArray.push([
            date.start_date,
            date.event_name,
            date.expected_attendence_for_event,
          ]);
        });
      });
  };

  const handleDateSelect = (date) => {
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
      localStorage.setItem("selected_eoi_dates", JSON.stringify(selectedDates));
    }
  };

  return (
    <div>
      <Button onClick={toggleModal}>Select Dates</Button>
      {modalOpen && (
        <div className="modalp">
          <div className="modalp-content">
            <DeviceIdentifier isMobile={true}>
              <p>Please long press the dates to know about the events</p>
            </DeviceIdentifier>
            <div>
              {datesArray.map((date) => (
                <Tooltip
                  title={`Event Name: ${date[1]}, Expected Attendence: ${date[2]}`}
                >
                  <button
                    key={date[0]}
                    onClick={() => handleDateSelect(date[0])}
                    className={
                      selectedDates.includes(date[0]) ? "selected date" : "date"
                    }
                  >
                    {date[0]}
                  </button>
                </Tooltip>
              ))}
            </div>
            <div>Selected Dates: {selectedDates.join(", ")}</div>
            <div className="modalp-actions">
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={toggleModal}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={toggleModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalDate;
