import React, { Component, useState } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  FormControl,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Card,
} from "react-bootstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultsList";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { BUSY_TIMES } from "./utils";
import ImageUploader from "react-images-upload";
import { ShareSocial } from "react-share-social";
import * as Sentry from "@sentry/react";

class AdminCreateEventStockOrder extends Component {
  state = {
    searchVendor: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedEventSearch:
      getFromLocalStorage("selectedEventSearch") != "undefined" &&
      getFromLocalStorage("selectedEventSearch") != "" &&
      getFromLocalStorage("selectedEventSearch") != null
        ? getFromLocalStorage("selectedEventSearch")
        : "",
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    events: [],
    eventName: "",
    eventType: "public",
    eventStatus: "active",
    eventStartDateTime: "",
    eventEndDateTime: "",
    busyStartTime: "02:00 AM",
    busyEndTime: "02:00 AM",
    eventLocation: "",
    coverImage: "",
    onlineOrdering: true,
    selectedVendorList: [],
    filteredEvents: [],
    selectedVendorName: [],
    vendorList: [],
    tempVendorList: [],
    eventDescription: "",
    coverImage: [],
    showSuccess: false,
    eventId: -1,
    errorArray: [],
  };

  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState(
      {
        coverImage: pictureDataURLs,
      },
      () => {}
    );
  };

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events_for_stock",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
              filteredEvents: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                // this.getVendorsInEvent();
              }
              //   this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_admin_stock_list",
          kitchaco_location: 0,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            vendorList: response.data,
            tempVendorList: response.data,
          });
        }
      });
  };

  saveEvent = () => {
    const {
      eventStartDateTime,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      eventName,
      eventType,
      eventStatus,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      selectedEventId,
      events,
    } = this.state;

    // if (
    //   eventType == 0 ||
    //   selectedEventId == "" ||
    //   eventLocation == "" ||
    //   eventStartDateTime == "" ||
    //   eventEndDateTime == "" ||
    //   eventStatus == 0 ||
    //   selectedVendorList.length <= 0
    // ) {
    //   alert("Please enter all the required fields");
    //   return;
    // }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var stringVendor = selectedVendorList.map((i) => i.toString());
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "save_event_stock",
          event_id: selectedEventId,
          vendors: stringVendor,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              showSuccess: true,
            });
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn ||
      isEventCoordinatorLoggedIn ||
      isAccountantLoggedIn ||
      isStockAdminLoggedIn
    ) {
      this.getAllVendors();
      this.getAllEvents();
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      eventStartDateTime,
      searchVendor,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      eventName,
      eventType,
      eventStatus,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      selectedEventId,
      events,
      selectedEventSearch,
      filteredEvents,
    } = this.state;
    const setFilteredEvents = (data) => {
      console.log(data);
      this.setState({ filteredEvents: data });
    };
    const selectedEventP = (data) => {
      console.log(data);
      this.setState({ selectedEventSearch: data });
    };
    const selectedEventQ = (data) => {
      console.log(data);
      this.setState({ selectedEventId: data });
    };
    return (
      <>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Row>
            <Col xs={12} md={12} xs={12}>
              <p>Select the list of stock items for the given event</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formEventName">
                  {/* <Form.Label>Select Event</Form.Label> */}
                  <div className="App">
                    <div className="search-bar-container">
                      <SearchBar
                        eventList={events}
                        placeHolder={"Search Events"}
                        setFilteredEvents={setFilteredEvents}
                        selectedEventId={this.state.selectedEventId} // Pass the selected event ID
                        selectedEventSearch={this.state.selectedEventSearch}
                      />
                      <SearchResultsList
                        filteredEvents={filteredEvents}
                        selectedEventP={selectedEventP}
                        selectedEventQ={selectedEventQ}
                      />
                    </div>
                  </div>
                  {console.log(events)}
                  {/* <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedEventId}
                      onChange={(e) => {
                        this.setState({ selectedEventId: "" + e.target.value });
                        saveToLocalStorage(
                          "selectedEventId",
                          "" + e.target.value
                        );
                      }}
                    >
                      <option value={0}>Select Event</option>
                      {filteredEvents.map((e) => {
                        return (
                          <option value={"" + e.id}>
                            {e.name +
                              " - " +
                              e.start_date +
                              " (" +
                              e.event_status +
                              ")"}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group> */}
                </Form.Group>

                <Form.Group
                  as={Col}
                  controlId="my_multiselect_field"
                  style={{ paddingLeft: 0 }}
                >
                  <Form.Label>*Stock List</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text value={searchVendor}>
                      <img
                        style={{ width: 20 }}
                        src={require("../../assets/img/search.gif")}
                      ></img>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search for Stock Items"
                      aria-label="Search for vendors"
                      onChange={(e) => {
                        var searchString = e.target.value;
                        if (searchString == "") {
                          this.setState({
                            vendorList: this.state.tempVendorList,
                          });
                        } else {
                          var result = vendorList.filter((obj) => {
                            if (
                              obj.name.search(new RegExp(searchString, "i")) !=
                              -1
                            ) {
                              return obj;
                            }
                          });
                          this.setState({ vendorList: result });
                        }
                      }}
                    />
                  </InputGroup>
                  {selectedVendorName.length > 0 ? (
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                      {selectedVendorName.map((vendorName) => {
                        return (
                          <Col style={{ marginTop: 10 }} xs={4} md={4} lg={4}>
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "black",
                              }}
                            >
                              {vendorName}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null}
                  <Form.Check
                    type="checkbox"
                    id="clear"
                    label="Clear"
                    checked={
                      this.state.selectedVendorList &&
                      this.state.tempVendorList &&
                      this.state.selectedVendorList.length === 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ selectedVendorList: [] });
                      }
                    }}
                  />
                  <div style={{ height: "200px", overflowY: "auto" }}>
                    {this.state.vendorList.map((vendor) => (
                      <Form.Check
                        key={vendor.id}
                        type="checkbox"
                        id={`vendor-${vendor.name}`}
                        label={vendor.name}
                        checked={
                          this.state.selectedVendorList &&
                          this.state.vendorList &&
                          this.state.selectedVendorList.includes(
                            vendor.user_ptr_id
                          )
                        }
                        onChange={(e) => {
                          const vendorId = vendor.id;
                          const vendorName = vendor.name;
                          const selectedVendorList = this.state.selectedVendorList.slice();
                          if (e.target.checked) {
                            selectedVendorList.push(vendorId);
                            selectedVendorName.push(vendorName);
                          } else {
                            const index = selectedVendorList.indexOf(vendorId);
                            if (index >= 0) {
                              selectedVendorList.splice(index, 1);
                            }
                          }
                          this.setState({ selectedVendorList });
                        }}
                      />
                    ))}
                  </div>
                  {/* {selectedVendorName.length > 0 ? (
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                      {selectedVendorName.map((selectedName) => {
                        return (
                          <Col style={{ marginTop: 10 }} xs={4} md={4} lg={4}>
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "black",
                              }}
                            >
                              {selectedName}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null}
                  <Form.Control
                    as="select"
                    multiple
                    value={selectedVendorList}
                    onChange={(e) =>
                      this.setState({
                        selectedVendorList: [].slice
                          .call(e.target.selectedOptions)
                          .map((item) => item.value),
                        selectedVendorName: [].slice
                          .call(e.target.selectedOptions)
                          .map((item) => item.text),
                      })
                    }
                  >
                    {vendorList &&
                      vendorList.length > 0 &&
                      vendorList.map((vendor) => {
                        return (
                          <option value={vendor.user_ptr_id}>
                            {vendor.title + " - " + vendor.email}
                          </option>
                        );
                      })}
                  </Form.Control> */}
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Stock Collection Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={eventDescription}
                    onChange={(e) => {
                      this.setState({ eventDescription: e.target.value });
                    }}
                  />
                </Form.Group> */}

                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.saveEvent();
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
          {this.renderSuccess()}
        </Container>
        <Footer />
      </>
    );
  }
  renderSuccess() {
    const { showSuccess, eventId, errorArray } = this.state;
    var url = window.location.href.replace("/events", "");
    return (
      <>
        <Modal
          show={showSuccess}
          onHide={() => {
            window.location.reload();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Submitted Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body variant="bottom" style={{ padding: 5 }}>
                <Row>
                  <Col xs={12}>
                    <p>Event Stock List has been submitted successfully.</p>
                    {errorArray.length > 0 ? (
                      <>
                        <p style={{ color: "red" }}>Warning</p>
                        {errorArray.map((item) => {
                          return (
                            <p style={{ color: "red" }}>{item["message"]}</p>
                          );
                        })}
                      </>
                    ) : null}

                    {/* <ShareSocial
                      style={{ padding: 0 }}
                      url={url + "/event:" + eventId}
                      socialTypes={["facebook", "email", "linkedin", "twitter"]}
                    /> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminCreateEventStockOrder, {
    fallback: <ErrorFallbackComponent />,
  })
);
