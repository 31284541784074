import React from "react";
import { Bar } from "react-chartjs-2";

const CostByDayChart = ({ shiftData }) => {
  if (!shiftData || !shiftData.cost_by_day) {
    console.log("null");
    return null;
  }

  const data = {
    labels: Object.keys(shiftData.cost_by_day),
    datasets: [
      {
        label: "Total Cost by Day",
        data: Object.values(shiftData.cost_by_day).map((day) => day.total_cost),
        backgroundColor: "rgba(0, 123, 255, 1)",
        hoverBackgroundColor: "rgba(0, 123, 255, 1)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function(context) {
            return `Date: ${context[0].label}`;
          },
          label: function(context) {
            const date = context.label;
            const dayData = shiftData.cost_by_day[date];
            let labels = [`Total Cost: $${dayData.total_cost.toFixed(2)}`];

            Object.entries(dayData.user_costs).forEach(([user, cost]) => {
              labels.push(
                `${user}: $${cost.toFixed(2)}, Hours: ${dayData.hours_worked[
                  user
                ].toFixed(2)}, Avg: ${dayData.avg_cost_per_hour[user].toFixed(
                  2
                )}`
              );
            });

            return labels;
          },
        },
        displayColors: false,
      },
    },
  };

  return <Bar data={data} options={options} />;
};
export default CostByDayChart;
