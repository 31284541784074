import React, { Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import Image from "react-bootstrap/Image";
import "./style.css";
import { store, getFromLocalStorage } from "../../store/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../../components/UI/Button/index";
import { theme } from "../../components/UI/theme";
import "date-fns";
import { applyToken } from "../../services/api";
import { getAllOrders, logout } from "../../actions";
import Maps from "./components/Maps";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";

class OrdersScreen extends Component {
  orderStatusId = 0;
  constructor(props) {
    super(props);
    this.state = {
      orders: new Array(),
      showMap: false,
      selectedOrder: {},
      selectedOrderId: -1,
      selectedOrderKitchacoLatLng: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    applyToken(getFromLocalStorage("token"));
    this.getAllOrders();

    // this.checkOrderStatus.bind(this)
    // this.orderStatusId=setInterval(this.checkOrderStatus.bind(this), 30000);
  }

  getAllOrders = () => {
    this.setState({ isLoading: true });
    const { userId } = this.props;
    var url = config.BASE_URI + apiPaths.getAllOrders;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      body: { query_type: "customer_all_orders", customer_phone: userId },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((eventDetailsResponse) => {
        this.setState({ isLoading: false });
        this.setState({ orders: eventDetailsResponse.orders });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  checkOrderStatus() {
    const { dispatch } = this.props;
    applyToken(getFromLocalStorage("token"));
    dispatch(getAllOrders()).then((response) => {
      if (response.payload) {
        var data = response.payload;
        this.setState({ orders: data }, () => {});
      }
    });
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  logout = () => {
    this.props.dispatch(logout());
    window.location.reload();
  };

  render() {
    const { appReducer } = store.getState();
    const { orders, isLoading } = this.state;
    const { loggedIn, username, userId, dispatch } = this.props;

    return (
      <div>
        {/* <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/"><Image style={{ height: 40 }} src={require("../../assets/img/welcome-logo.png")} fluid /></Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }}>

              <Nav.Link onClick={() => { window.location.href = "/" }} style={{ marginTop: 10 }}>Events</Nav.Link>
              <Nav.Link onClick={() => { window.location.href = "/orders" }} style={{ marginTop: 10 }}>My Orders</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }} style={{ marginTop: 10 }}>Logout</Nav.Link>

            </Nav>

          </Navbar.Collapse>
        </Navbar> */}

        <ResponsiveNavBar
          showBack={true}
          backUrl={"/"}
          ticketState={{}}
          products={{}}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        <Container fluid>
          <Box m={2}>
            {orders.map((order, index) => {
              let subTotal = order.meals.reduce(
                (acc, meal) => acc + meal.meal.price * meal.meal.quantity,
                0
              );
              return (
                <Accordion
                  key={order.order.id}
                  sx={{
                    mb: 2,
                    border: "1px solid #d2d8dd",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: "#f1f1f1",
                      color: "#000",
                      padding: "0 16px",
                    }}
                  >
                    <Box flexGrow={1}>
                      <Typography variant="h6" component="div">
                        Pickup from {order.order.vendor__title}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {order.order.order_date} - {order.order.order_time}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#fff" }}>
                    <Box>
                      <Typography variant="body1" component="div">
                        <strong>Order #{order.order.id}</strong>
                      </Typography>
                      {order.meals.map((meal, idx) => (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          key={idx}
                          mt={1}
                        >
                          <Typography variant="body2">
                            {meal.meal.meal__name}
                          </Typography>
                          <Typography variant="body2">
                            ${meal.meal.price.toFixed(2)}
                          </Typography>
                        </Box>
                      ))}
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="body2">Subtotal</Typography>
                        <Typography variant="body2">
                          ${subTotal.toFixed(2)}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={1}>
                        <Typography variant="body2">
                          <strong>Total</strong>
                        </Typography>
                        <Typography variant="body2">
                          <strong>${subTotal.toFixed(2)}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Container>
      </div>
    );
  }

  renderMapsModal() {
    const {
      showMap,
      selectedOrderId,
      selectedOrderKitchacoLatLng,
      selectedOrder,
    } = this.state;
    const { dispatch } = this.props;
    const order = selectedOrder;
    var sub_total = 0.0;
    return (
      <Modal
        show={showMap}
        onHide={() => {
          this.setState({ showMap: false });
        }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tracking Order #{selectedOrderId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Maps
            orderId={selectedOrderId}
            dispatch={dispatch}
            kitchacoLatLng={selectedOrderKitchacoLatLng}
          ></Maps>
          {order.meals &&
            order.meals.map((meal, index) => {
              var item = new Array();
              sub_total += Number(meal.quantity) * Number(meal.meal.price);
              return (
                <Row style={{ marginTop: 5 }} key={index}>
                  <Col lg={6}>
                    <Row>
                      <Col lg={12}>
                        {" "}
                        <h6>{meal.meal.name}</h6>
                      </Col>
                    </Row>
                    {meal.combo != "" &&
                      meal.combo.split(",").map((combo_item) => {
                        item = meal.meal.combo.filter((c) => {
                          return c.name == combo_item;
                        });

                        return (
                          <Row key={item[0].id}>
                            <Col lg={12}>
                              <h6 style={{ color: "#7c7d80", fontSize: 14 }}>
                                {item[0].name}
                              </h6>
                            </Col>
                          </Row>
                        );
                      })}
                    {meal.subitem != "" && (
                      <Row>
                        <Col lg={12}>
                          {" "}
                          <h6 style={{ color: "#7c7d80", fontSize: 14 }}>
                            {meal.subitem}
                          </h6>
                        </Col>
                      </Row>
                    )}
                    {meal.special_request != "" && (
                      <Row>
                        <Col lg={12}>
                          {" "}
                          <h6 style={{ color: "#7c7d80", fontSize: 14 }}>
                            {meal.special_request}
                          </h6>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col lg={3}>
                    <Row>
                      <Col lg={12}>
                        <h6 style={{ textAlign: "right" }}>x{meal.quantity}</h6>
                      </Col>
                    </Row>
                    {item &&
                      item.map((c) => {
                        sub_total += Number(meal.quantity) * Number(c.price);
                        return (
                          <Row key={c.id}>
                            <Col lg={12}>
                              <h6 style={{ textAlign: "right" }}>
                                x{meal.quantity}
                              </h6>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                  <Col lg={3}>
                    <Row>
                      <Col lg={12}>
                        <h6 style={{ textAlign: "right" }}>
                          x{meal.meal.price}
                        </h6>
                      </Col>
                    </Row>
                    {item &&
                      item.map((c) => {
                        return (
                          <Row key={c.id}>
                            <Col lg={12}>
                              <h6 style={{ textAlign: "right" }}>${c.price}</h6>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              );
            })}
          <Row style={{ marginTop: 5 }}>
            <Col lg={{ number: 5, offset: 4 }}>
              <h6 style={{ textAlign: "right" }}>Sub Total</h6>
            </Col>
            <Col lg={3}>
              <h6 style={{ textAlign: "right" }}>
                ${Number(sub_total).toFixed(2)}
              </h6>
            </Col>
          </Row>
          {order.order_type == "delivery" && (
            <Row style={{ marginTop: 5 }}>
              <Col lg={{ number: 5, offset: 4 }}>
                <h6 style={{ textAlign: "right" }}>Delivery Charge</h6>
              </Col>
              <Col lg={3}>
                <h6 style={{ textAlign: "right" }}>${order.delivery_charge}</h6>
              </Col>
            </Row>
          )}
          {order.discount != "0.00" && (
            <Row style={{ marginTop: 5 }}>
              <Col lg={{ number: 5, offset: 4 }}>
                <h6 style={{ textAlign: "right" }}>Discount</h6>
              </Col>
              <Col lg={3}>
                <h6 style={{ textAlign: "right" }}>${order.discount}</h6>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: 5 }}>
            <Col lg={{ number: 5, offset: 4 }}>
              <h6 style={{ textAlign: "right" }}>Total</h6>
            </Col>
            <Col lg={3}>
              <h6 style={{ textAlign: "right" }}>
                ${order.payment_total_amount}
              </h6>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col lg={6}>
              <h5 className={"kitchaco-title"}>Order Status</h5>
            </Col>
            <Col lg={6}>
              <h5 className={"kitchaco-title"}>{order.status}</h5>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OrdersScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
