import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { getFromLocalStorage } from "../../store";
import "./style.css";
import { UNITS } from "../../assets/Constants";

const NewStockItemModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { showModal, setShowModal, handleAddItemSuccess } = props;

  const [vendors, setVendors] = useState([]);
  const [qtyTypes, setQtyTypes] = useState([]);
  const [measurementType, setMeasurementType] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemWeight, setItemWeight] = useState("");
  const [qtyType, setQtyType] = useState("");

  const [vendorId, setVendorId] = useState(() => props.vendorId);

  const handleClose = () => {
    clearState();
    setShowModal(false);
  };

  const fetchVendorIds = () => {
    setIsLoading(true);
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };
  const fetchQtyTypes = () => {
    setIsLoading(true);
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_qty_types",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorStockData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setQtyTypes(result.qty_types);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    if (showModal) fetchQtyTypes();
    if (showModal) fetchVendorIds();
  }, [showModal]);

  const clearState = () => {
    setVendorId("");
    setitemName("");
    setItemPrice("");
    setItemWeight("");
    setMeasurementType("");
  };

  const handleSubmit = () => {
    setIsLoading(true);

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_stocklist_product",
          operator_id: Number(operatorId),
          vendor_id: Number(vendorId),
          stock_item_name: itemName,
          stock_item_price: itemPrice,
          qty_type_id: qtyType,
          price: itemPrice,
          weight_value: itemWeight,
          measurment_type: measurementType,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        console.log(dataResponse);
        if (dataResponse.success == true) {
          handleAddItemSuccess({
            stock_item_name: itemName,
            stock_item_price: itemPrice,
            qty_type_id: qtyType,
            price: itemPrice,
            weight_value: itemWeight,
            measurment_type: measurementType,
          });
          handleClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Something went wrong. Please try again.");
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <>
      <Modal
        dialogClassName="closing-modal"
        scrollable
        show={showModal}
        style={{ position: "relative" }}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Stock Item</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 1)", // Optional: Add a semi-transparent background
              zIndex: 9999, // Ensure it covers everything
            }}
          >
            <img src={require("../../assets/img/loading.gif")} alt="Loading" />
          </div>
        ) : null}
        <Modal.Body style={{ position: "relative" }}>
          <form className="closing-form-container form-style-5">
            <div className="closing-form-item">
              <label>Select Vendor:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  const selectedVendorId = e.target.value;
                  setVendorId(selectedVendorId);
                  // if (selectedVendorId) getAllEvents(Number(selectedVendorId));
                }}
                value={vendorId || ""}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor, index) => (
                  <option key={index} value={vendor?.user_ptr_id}>
                    {vendor?.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="closing-form-item">
              <label>Stock Item Name:</label>
              <input
                placeholder="Item Name:"
                type="text"
                id="item_name"
                name="item_name"
                value={itemName}
                onChange={(e) => {
                  setitemName(e.target.value);
                }}
              />
            </div>
            <div className="closing-form-item">
              <label>Stock Item Price:</label>
              <input
                value={itemPrice}
                onChange={(e) => {
                  setItemPrice(e.target.value);
                }}
                placeholder="Item Price:"
                type="number"
                id="item_price"
                name="item_price"
                //     value={meal_price}
                //     onChange={handleMealPriceChange}
              />
            </div>
            <div className="closing-form-item">
              <label>Weight:</label>
              <input
                value={itemWeight}
                onChange={(e) => {
                  setItemWeight(e.target.value);
                }}
                placeholder="Weight:"
                type="number"
                id="weight"
                name="weight"
                //     value={meal_price}
                //     onChange={handleMealPriceChange}
              />
            </div>
            <div className="closing-form-item">
              <label>Quantity Type:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  setQtyType(e.target.value);
                }}
                value={qtyType || ""}
              >
                <option value="">Select Quantity Type</option>
                {qtyTypes.map((type, index) => (
                  <option key={type.id} value={type.id}>
                    {type.qty_type}
                  </option>
                ))}
              </select>
            </div>
            <div className="closing-form-item">
              <label>Measurement Type:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  setMeasurementType(e.target.value);
                }}
                value={measurementType || ""}
              >
                <option value="">Select Measurement Type</option>
                {UNITS.map((unit, index) => (
                  <option key={index} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              !vendorId ||
              !itemName ||
              !itemPrice ||
              !itemWeight ||
              !measurementType ||
              !qtyType
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewStockItemModal;
