import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
Font.register({
  family: "Roboto",
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  container: {
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  info: {
    marginBottom: "20px",
  },
  salesTable: {
    marginTop: "20px",
  },
  tableHeader: {
    backgroundColor: "#e9ecef",
    padding: "5px",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #ccc",
    padding: "5px",
  },
  tableCell: {
    flex: 1,
    textAlign: "center",
  },
});

const PdfCreator = ({ vendor, EOI, organiser }) => {
  console.log(vendor, EOI, organiser);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Image
              style={{
                width: "180px",
                height: "200px",
                paddingBottom: "50px",
                resizeMode: "contain",
              }}
              src="https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png"
              cache={false}
            />
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Food Truck Vendor Catering Agreement
            </Text>
            <Text style={{ fontSize: 12, paddingBottom: "5px" }}>
              Australian Mobile Food Vendors Group Pty Ltd
            </Text>
            <Text
              style={{
                fontSize: 12,
                paddingBottom: "10px",
                fontWeight: "bold", // This will apply bold styling to the text
                fontStyle: "normal",
              }}
            >
              ABN 19 163 764 047 ("AMFVG")
            </Text>
            <Text style={{ fontSize: 12, paddingBottom: "10px" }}>and</Text>
            <Text style={{ fontSize: 12, paddingBottom: "5px" }}>
              The party whose name appears in Item 2 of Schedule 1
            </Text>
            {vendor.map((item) => (
              <>
                <strong>
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      marginLeft: 20,
                    }}
                  >
                    {item.title && item.abn_number
                      ? `("${item.title} - ${item.abn_number}")`
                      : item.title
                      ? `("${item.title} - ")`
                      : item.abn_number
                      ? `(" - ${item.abn_number}")`
                      : ""}
                  </Text>
                </strong>
              </>
            ))}
          </div>

          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 12,
              textAlign: "center",
              marginBottom: 520,
              marginTop: 20,
            }}
          >
            This page has been intentionally left blank
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 2 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>

          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            THIS AGREEMENT is made on the date specified in Item 1 of Schedule 1
            of this Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            BETWEEN
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            1. Australian Mobile Food Vendors Group Pty Ltd ABN 19 163 764 047
            of 29 – 31 Malua Street, Reservior VIC 3073 (AMFVG)
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            and
          </Text>
          {vendor.map((item) => (
            <>
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 10,
                }}
              >
                {item.title && item.abn_number
                  ? `2. The party named in Item 2 of Schedule 1(${item.title} - ${item.abn_number})`
                  : item.title
                  ? `2. The party named in Item 2 of Schedule 1(${item.title} - )`
                  : item.abn_number
                  ? `2. The party named in Item 2 of Schedule 1( - ${item.abn_number})`
                  : "2. The party named in Item 2 of Schedule 1"}
              </Text>
            </>
          ))}
          <Text></Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            RECITALS
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            A. AMFVG intends conducting and managing the Event on the Event Date
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            B. The Food Truck Vendor has submitted an expression of interest to
            AMFVG to conduct and operate its Food Truck Vending Business at the
            Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            C. AMFVG has agreed to permit the Food Truck Vendor to occupy and
            use the Vendor Site to conduct its Food Truck Vending Business at
            the Event in accordance with the terms and conditions of this
            Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            IT IS AGREED AS FOLLOWS:
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 10,
              marginTop: 10,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            1 Definition of Words and Interpretation
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            1.1 Meaning of Words
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            The following definitions apply unless the context otherwise
            requires
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Agreement means this agreement and includes all annexures and
            Schedules
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            AMFVG means the Australian Mobile Food Vendor Group Pty Ltd, AMFVG
            staff and any authorised person or persons who act on behalf of
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Cancellation Fee means an amount payable by the Food Truck Vendor if
            the Food Truck Vendor cancels attending the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Daily Stock Sheet means the daily report by the Food Truck Vendor
            detailing all products sold by the Food Truck Vendor at the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Default Rate means a rate of interest of 10% per annum calculated
            daily
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            EFTPOS means electronic funds transfer at point of sale facilities
            or system
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Event means the event described in item 4 of the Schedule
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Event Date means the period between the dates set out in Item 5 of
            Schedule 1, both dates inclusive.
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Fees mean the Site Fee, the Site Service Fee, the Utility Fee, the
            Hire Fee and the cancellation fees and the amount by which, if any,
            the Turnover Fee exceeds the Site Fee.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 30,
            }}
          >
            Food Truck means any van, trailer, trolley or other mobile food unit
            that is registered with a local council or is fit to be registered
            under the requirements of “Mobile Food Cart Specifications” or
            “Mobile Food Vehicle Specifications” as provided by the local
            council and State government or authority within which the Venue is
            located
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 3 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Food Truck Vending Business means the business referred to in item 3
            of the Schedule permitted by AMFVG to be conducted at the Event by
            the Food Truck Vendor on and from the Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Food Truck Vendor means the person named in Item 2 of Schedule 1
            and, where the context permits, includes the Food Truck Vendor’s
            Personnel and other persons claiming through or under the Food Truck
            Vendor.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Food Truck Vendor’s Property means all property brought onto the
            Vendor Site by the Food Truck Vendor or its Personnel, including the
            Food Truck, fixtures, fittings, signs, equipment and goods
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Goods and Services Tax (GST) means any tax in the nature of a tax
            on, or on the supply of goods, real property, services or any other
            thing levied, imposed or assessed by the Commonwealth of Australia
            or any State or Territory of Australia or municipal authority which
            may operate at any time during the Term
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Gross Sales means the total GST inclusive sales (in dollars) of all
            products sold by the Food Truck Vendor at the Event, including the
            value of approved meal vouchers collected
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Hire Fee means the cost of supplying hire infrastructure set out in
            Item 8 of Schedule 1
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Induction Form means the induction form provided by AMFVG to the
            Food Truck Vendor to be completed by the Food Truck Vendor to the
            satisfaction of AMFVG and returned to AMFVG at least ____ days prior
            to the Event Date
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Intellectual Property Rights means all rights in the nature of
            intellectual and industrial property (which is to be understood in
            the broadest possible sense), and includes, without limitation
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. inventions, discoveries and technical information;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. patents;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. trade and service marks;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. copyright
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. design
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. trade secrets;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. confidential information
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. domain names
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. venue layouts
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            j. business and trading names
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            k. rights of any of the kinds mentioned in the preceding paragraphs,
            whether registered, registrable or not
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            l. application and registrations for any of the kinds of rights
            mentioned in the preceding paragraphs; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            m. rights of the kind mentioned in the preceding paragraphs,
            irrespective of where geographically those rights may arise or
            subsist.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 4 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Manager means a person appointed by AMFVG to manage the Event and,
            where the context permits, any of the Manager’s Personnel.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Personnel of a party means that party's employees, agents,
            contractors, consultants, directors and officers and any of them
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 15,
            }}
          >
            Policies means the AMFVG standards set out in Schedule 2 and any
            amendments to or revisions of those standards.
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Property means the property upon which the Event is being held.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Right of Occupancy means the right to occupy granted by AMFVG to the
            Food Truck Vendor pursuant to clause 2.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Schedules mean the schedules attached to and forming part of this
            Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Sign means any and all signage exposure including any advertisement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Site Fee means the amount set out in Item 6 of Schedule 1
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Site Service Fee means the amount set out in Item 6 of Schedule 1
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Supplementary Stock Sheet means an additional stock sheet detailing
            all products sold once the Daily Stock Sheet had been submitted to
            AMFVG.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Tax means a tax, levy or impost, sales tax and any other similar
            indirect tax or levy imposed by any Commonwealth, State or municipal
            authority excluding Goods and Services Tax but including any novel
            tax which may be introduced after the date of this Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Term means the period between the dates set out in item 7 of
            Schedule 1, both dates included
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Turnover Fee means the amount set out in Item 8 of Schedule 1.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            $ means Australian dollars
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Utility Fee means the cost of supplying power calculated by AMFVG on
            the Food Truck Vendor’s power requirements set out in Item 8 of
            Schedule 1 and the cost of collecting and removing sullage if
            required
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Vendor Site means the space allocated by AMFVG to the Food Truck
            Vendor to conduct its Food Truck Vending Business at the Venue.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Venue means the location of the Property described in item 11 of the
            Schedule at which the Event is held.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            1.2 Interpretation
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            In this Agreement, headings are for convenience only and do not
            affect interpretation. The following rules apply unless the context
            requires otherwise
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The singular includes the plural and conversely
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. A gender includes all genders
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. If a word or phrase is defined, its other grammatical forms have
            a corresponding meaning
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. A reference to "includes" or "including" should be construed
            without limitation.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 5 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. A reference to a person, corporation, trust, partnership,
            unincorporated body or other entity includes any of them
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. A reference to a clause, Schedule or Annexure is a reference to a
            clause of, or a schedule or annexure to, this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. A reference to an agreement or document (including, without
            limitation, a reference to this Agreement) is to the agreement or
            document as amended, varied, supplemented, novated or replaced,
            except to the extent prohibited by this Agreement or that other
            agreement or document
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. A reference to a party to this Agreement or another agreement or
            document includes the party's successors, permitted substitutes and
            assigns (and, where applicable, the party's legal personal
            representatives)
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. A reference to legislation or to a provision of legislation
            includes a modification or re-enactment of it, a legislative
            provision substituted for it and a regulation or statutory
            instrument issued under it
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            j. A reference to conduct includes an omission, statement and
            undertaking, whether or not in writing
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            k. A reference to an "agreement" includes any undertaking, deed,
            agreement and legally enforceable arrangement whether or not in
            writing and a reference to a document includes an agreement (as so
            defined) in writing and any certificate, notice, instrument and
            document of any kind
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            l. A reference to "writing" includes a facsimile transmission,
            e-mail and any means of reproducing words in a tangible and
            permanently visible form
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            m. A reference to a "year" or to a "month" is to a calendar year or
            a calendar month respectively.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            n. A reference to "party" is a reference to a party to this
            Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            o. A promise or agreement by two or more persons binds each person
            individually and all those persons jointly
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            p. Examples are descriptive only and not exhaustive.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            q. No rule of construction applies to the disadvantage of a party
            only because that party was responsible for preparing this deed or a
            provision in this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            r. A reference to AMFVG includes the Manager and AMFVG’s Personnel
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            s. A reference to an “authority” means any State or local or federal
            government statutory or public authority or any person or body
            having jurisdiction over a matter offering, touching or concerning
            any of the terms or conditions of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            2 Right of Occupancy
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            AMFVG grants the Food Truck Vendor the right during the Term to
            occupy and use the Vendor Site to conduct its Food Truck Vending
            Business at the Event subject to the terms and conditions set out in
            this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            3 Nature of Right of Occupancy
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            3.1 Food Truck Vendor’s rights are Contractual and Personal
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 30,
            }}
          >
            The Food Truck Vendor’s rights under this Agreement
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 6 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. are contractual rights only and nothing in this Agreement shall
            be deemed to constitute the Food Truck Vendor or its Personnel as
            employees, servants or agents of AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. are personal to the Food Truck Vendor who cannot assign, license,
            sublicense, transfer, dispose of or otherwise deal with all or any
            part of its rights, benefits or obligations under this Agreement to
            or in favour of any other person
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. are not exclusive and do not give the Food Truck Vendor any right
            to exclusive possession of the Vendor Site; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. do not create any tenancy or give the Food Truck Vendor any
            leasehold interest in the Vendor Site or any other rights except as
            provided for in this Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            3.2 AMFVG’s Rights
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            AMFVG may, at all times, exercise all its rights including the right
            to possess, control and use the Vendor Site, except in so far as the
            exercise of those rights would prevent the Food Truck Vendor from
            enjoying the benefits of its Right of Occupancy.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            3.3 Exercise of AMFVG Rights
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            AMFVG may:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. exercise its rights and perform its obligations under this
            Agreement itself
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. sublicense, novate or assign some or all of its rights or
            obligations under this Agreement to a third party; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must pay to AMFVG the Site Fee regardless
            of whether the Food Truck Vendor occupies or uses the Vendor Site
            during the Venue
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. In consideration for the Right of Occupancy granted to the Food
            Truck Vendor pursuant to clause 2 of this Agreement, the Food Truck
            Vendor shall pay Fees to AMFVG as provided for in this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must pay to AMFVG the Site Fee regardless
            of whether the Food Truck Vendor occupies or uses the Vendor Site
            during the Venue
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Site Fee and Site Service Fee is payable on the date or dates
            set out in Item 6 of Schedule 1
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Turnover Fee, Utility Fee and Hire Fee is payable on the date
            or dates set out in item 8 of Schedule 1.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. If the Turnover Fee is less than or equal to the Site Fee, the
            Food Truck Vendor shall pay the Site Fee plus Utility Fee, Hire Fee
            and Site Service Fee only
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Food Truck Vendor must pay to AMFVG a cancellation fee based
            on
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. Cancellation at or within 14 calendar days before the Event
            commences
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.2 Bond
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must pay to AMFVG the Bond (if any)
            indicated in Item 6 of Schedule 1 by the date shown in Item 6
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 7 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. The Bond shall be refundable only if AMFVG is satisfied that
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. the Vendor Site has been left in a clean and tidy state;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. no damage has occurred
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. the Fee has been paid in full
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            v. all other monies for which the Food Truck Vendor is responsible
            under this Agreement have been paid.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.3 Provision of Gross Sales Figures
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG reserves the right to provide cash registers to the Food
            Truck Vendor and, if provided, must at all times throughout the Term
            be used by the Food Truck Vendor at the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. If 4.3 (a) is not applicable, the Food Truck Vendor must install
            and make use of appropriate cash register(s) within the Vendor Site.
            All transactions executed throughout the Event must be recorded on
            the cash register(s). The cash register must produce daily reports
            including time, date and sequential cash register numbers (Z Read)
            as well as individual product sales breakdowns and daily totals.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor must provide, with each Daily Stock Sheet,
            a Z read, showing details prior to opening for business and at close
            of business, for each cash register used at the Vendor Site.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. If the Food Truck Vendor or any person acting on behalf or in
            association with the Food Truck Vendor sells further products after
            providing the Daily Stock Sheet and related Z reads from the Vendor
            Site, the Food Truck Vendor must provide AMFVG with a Supplementary
            Stock Sheet identifying the number and value of all further sales.
            Any Supplementary Stock Sheet must be provided to AMFVG no later
            than one (1) working day after the conclusion of the further Sales
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. The Food Truck Vendor must complete a Daily Stock Sheet with full
            details of all products sold at the Vendor Site and hand the Daily
            Stock Sheet in to the AMFVG as requested
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Daily Stock Sheet and related Z read and, where applicable,
            the Supplementary Stock Sheet must be certified as correct by a
            responsible officer of the Food Truck Vendor.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.4 AMFVG’s Legal Costs and Stamp Duty
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Each party shall bear its own costs arising out of the negotiation,
            preparation and execution of this Agreement. All stamp duty
            (including fines, penalties and interest) which may be payable on or
            in connection with this Agreement and any instrument executed under
            this Agreement shall be borne by the Food Truck Vendor.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.5 Interest on late payments
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            If the Food Truck Vendor fails to pay any amount payable by it under
            this Agreement by due date, the Food Truck Vendor must pay interest
            at the Default Rate on the unpaid amount accrued daily from the time
            it fell due until the amount has been paid in full
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.6 Adjustments and Errors
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
            }}
          >
            If either the Food Truck Vendor or AMFVG proves an error in any
            calculation, the party in error must correct the error and any
            necessary adjusting payment must be made as soon as possible.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 8 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.7 GST
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. In this clause, GST, Taxable Supply, Consideration, Tax Invoice,
            Input Tax Credit, Adjustment Note and Adjustment Venue each have the
            meaning given to those terms in Section 195-1 of the A New Tax
            System (Goods and Services Tax) Act 1999 (Cth)
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The parties acknowledge that, unless expressed otherwise in this
            Agreement, all Consideration payable under this Agreement is
            expressed exclusive of GST
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. If GST is payable on a Taxable Supply made under, by reference to
            or in connection with this Agreement by one party (Supplier) to
            another (Recipient), the Recipient providing Consideration for the
            Taxable Supply must also pay the GST payable in respect of that
            Taxable Supply as additional Consideration, unless the Consideration
            for the Taxable Supply is expressly agreed to be GST inclusive. No
            payment in respect of the Taxable Supply is required until the
            Supplier has provided a Tax Invoice or Adjustment Note, as the case
            may be, to the Recipient
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. To the extent that any supply made under or in connection with
            this Agreement is a Taxable Supply, the Consideration for that
            Taxable Supply will be increased by an amount determined by the
            Supplier, not exceeding the amount of that Consideration (or its
            market value) multiplied by the rate at which GST is imposed in
            respect of the Taxable Supply. The amount so determined must be paid
            by the Recipient of the Taxable Supply even if the Recipient
            disputes the determination.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. If an Adjustment Venue occurs following a determination under
            clause 4.7(d), the Supplier must make a further determination under
            clause 4.7(d) of the amount of Consideration payable and if the GST
            component of that Consideration differs from the amount originally
            determined, the amount of the difference must be paid by, refunded
            to or credited to the Recipient, as the case may be
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Supplier must issue a Tax Invoice to the Recipient of a
            Taxable Supply to which this clause applies no later than fourteen
            (14) days following payment of the GST inclusive Consideration
            determined under clause 4.7(d).
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. Any reference in the calculation of Consideration or of any
            indemnity, reimbursement or similar amount to a cost, expense or
            other liability incurred by a party, must exclude the amount of any
            Input Tax Credit entitlement of that party in relation to the
            relevant cost, expense or other liability. A party will be assumed
            to have an entitlement to a full Input Tax Credit unless it
            demonstrates otherwise prior to the date on which the Consideration
            must be provided.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. This clause will continue to apply after expiration or
            termination of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            4.8 Inspection of the Food Truck Vendor’s Accounts, Records and Cash
            Registers
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. During the Event, the Food Truck Vendor acknowledges that AMFVG,
            or their authorised representatives, have the right to enter the
            Vendor Site without notice to the Food Truck Vendor to inspect
            and/or audit the Food Truck Vendor’s accounts, records and cash
            registers to ensure that the amounts payable by the Food Truck
            Vendor to AMFVG under this Agreement are correct. For this purpose,
            AMFVG is entitled to take copies of or extracts from any such
            records, and to have them inspected by its advisors. The Food Truck
            Vendor must fully disclose its accounts and records to AMFVG for
            this purpose
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            b. Post the Event, the Food Truck Vendor acknowledges that AMFVG has
            the right, after giving reasonable notice to the Food Truck Vendor,
            to inspect and/or audit the Food Truck Vendor’s accounts, records
            and cash registers to ensure that the amounts payable by the Food
            Truck Vendor to AMFVG under this Agreement are correct. For this
            purpose, AMFVG is entitled to take copies of or extracts from any
            such records, and to have them inspected by its advisors. The Food
            Truck Vendor must fully disclose its accounts and records to AMFVG
            for this purpose
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 9 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5 Food Truck Vendor’s Obligations
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.1 Compliance with Laws, Regulations and Directions
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            The Food Truck Vendor must, at its cost and expense, comply and
            ensure its Personnel comply with
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.2 Notification & Compliance
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            The Food Truck Vendor agrees:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. To immediately pass on to the AMFVG all notices, orders or
            directions issued by any authority which affect or relate to the
            Venue or Event or to the provision of food or services at the Event,
            regardless of whether the notice, order or direction is addressed by
            or requires compliance by either or both the Food Truck Vendor and
            the AMFVG or any other person. A copy of each such notice, order or
            direction (if an oral notice or, order or direction, details
            thereof), must be given by the Food Truck Vendor to AMFVG within 24
            hours of receipt of the notice, order or direction. If the above
            notices, orders or directions issued by any authority relate
            directly to the Food Truck Vendor, they are forthwith to be fully
            complied with by the Food Truck Vendor.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. To allow the relevant local council or any other relevant
            authority to notify AMFVG of any written or oral notice, order or
            direction issued to the Food Truck Vendor as it relates to the Food
            Truck Vendor’s presence and/or performance at the Venue including
            the results of any tests undertaken by the council or authority
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. Not to do or omit anything to be done that may in any way
            endanger the Property, or any person, equipment or goods (whether
            belonging to the Food Truck Vendor or any other person) at the Venue
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.3 Food Safety Obligations
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. When the Food Truck Vendor sells or samples food it must comply
            with the Food Truck Vendor’s obligations under the relevant State
            and local legislation and regulations. The Food Truck Vendor must
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. Obtain and provide evidence to AMFVG of registration as a food
            premises under the relevant State and local legislation and
            regulations
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. Lodge a completed and signed ‘Statement of Trade’ under
            Streatrader, provide a copy to AMFVG and throughout the Term keep a
            copy available for inspection by AMFVG on the Vendor Site;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. Comply with the requirements of State and local legislation and
            regulations and relevant municipal health services guidelines with
            respect to the operation of temporary food premises at special
            events
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. Comply with all AMFVG standards set out in the Policies
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            v. Comply with AMFVG’s food safety directions and complete any
            required food safety records as required by AMFVG;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            vi. Maintain and make available throughout the Term for inspection
            by AMFVG at the Vendor Site a current detailed list of each item of
            ingredients relating to the products on the Food Truck Vendor’s menu
            including but not limited to compound ingredients used
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            vii. Maintain and make available throughout the Term for inspection
            by AMFVG at the Vendor Site an allergy listing as defined and known
            in the Food Standard Australian New Zealand Code in respect of
            products on the Food Truck Vendor’s menu
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. Without limitation to 5.3(a) above, the Food Truck Vendor must
            ensure that any food that is pre-packed for sale at the Venue by the
            Food Truck Vendor, complies with relevant compositional and
            labelling requirements of the Food Standard Australian New Zealand
            Code.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 10 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.4 Exclusive Supply Rights
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG reserves, at its discretion, the right to appoint exclusive
            and preferred supply rights at the Event and will inform the Food
            Truck Vendor of any exclusive supply terms and conditions prior to
            the commencement of the Event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.5 Selling
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must confine the conduct of its Food Truck
            Vending Business at the Vendor Site to and in accordance with the
            description of its business as set out in item 3 of Schedule 1 and
            must not promote, distribute or offer for sample or sale any
            products or services from the Vendor Site other than in accordance
            with this clause 5.5
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. All products to be sold by the Food Truck Vendor must first be
            approved by AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor must promptly provide, upon request by
            AMFVG, any additional information regarding the product
            specifications
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor must ensure that all products sold or given
            away from the Vendor Site are safe and fit for human consumption and
            are in accordance with all relevant health and food safety laws,
            rules and regulations.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. The Food Truck Vendor may not, without AMFVG’s prior written
            approval, offer products for sale if the purchaser does not intend
            to take immediate possession of those products. If the Food Truck
            Vendor wishes to otherwise accept orders for products, AMFVG may, at
            AMFVG’s discretion and if it consents, require the Food Truck Vendor
            to pay for this right as a condition to obtaining AMFVG’s approval
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Food Truck Vendor will supply the Manager with a complete
            list of products and proposed pricing of products, to be sold by the
            Food Truck Vendor at the Vendor Site. The full product list is
            subject to approval by AMFVG. AMFVG may recommend (but not require)
            changes to the pricing. AMFVG reserves the right to delete items
            from product list and may, at its discretion, approve only part of
            the menu/ product submission
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. The Food Truck Vendor must prominently display at the Vendor
            Site, a notice setting out the price (including GST) of all products
            for sale at the Vendor Site.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. The Food Truck Vendor may not offer for sample or sale or sell
            any products that AMFVG has not approved in writing to the Food
            Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. The Food Truck Vendor may not solicit donations for any reason
            whatsoever at the Venue without the prior written approval of AMFVG.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            j. The Food Truck Vendor must promptly notify AMFVG of any complaint
            relating to the products or services supplied by the Food Truck
            Vendor at the Event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.6 Cash Registers
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 70,
            }}
          >
            Where AMFVG has supplied the Food Truck Vendor with cash registers,
            the Food Truck Vendor must not:
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 11 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. Cause any undue damage to the cash registers and shall be
            responsible to pay all costs associated with the repair and/or
            replacement of any damaged or lost cash registers which were in its
            possession or under its control prior, during or after the Event
            Date
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. Tamper with, or attempt to tamper with, the cash registers
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. Access, or attempt to access, the software or internal mechanisms
            of the cash registers;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. Attempt to code or re-code the cash registers;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. Modify, or attempt to modify, the cash registers in any way
            including the:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. till rolls; (ii) data; (iii) cash register chip; or (iv) wireless
            connectivity; are responsibility of the vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. Use, or attempt to use, the cash registers in any way other than
            what it was designed for or how it was delivered to the Food Truck
            Vendor by AMFVG;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. Modify or attempt to modify, any of the records produced by the
            cash registers; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. Attempt to alter the cash registers in any way. The vendor will
            not have another register or terminal in the food truck during the
            event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.7 EFTPOS
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must, at its own expense, provide EFTPOS
            square terminal facilities and ensure that EFTPOS square terminal
            facilities are available for use by customers at each point of sale
            location on the Vendor Site. Terminals must bee connected to the WTT
            software only
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. AMFVG reserves the right to install EFTPOS facilities (at the
            Food Truck Vendors expense) if the Food Truck Vendor fails to
            provide EFTPOS facilities designed to be compatible to the WTT
            vendor POS. Associated costs and fees will be payable on demand by
            the Food Truck Vendor to AMFVG for, including but not limited to,
            hire and installation costs and merchant fees
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor must:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. Ensure that the EFTPOS facilities are at all times during the
            Event in working order. Repairs to service or replacement facilities
            must be implemented within 24 hours; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. Clearly display on signage at each point of sale location on the
            Vendor Site that EFTPOS is available.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. AMFVG will not be responsible or liable to the Food Truck Vendor
            for the consequences of any electronic or technical failure of third
            party equipment or services at the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.8 Staffing
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 30,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must ensure that the Vendor Site is staffed
            by a sufficient number of persons required to facilitate reasonable
            service at all times during the Event. If the Food Truck Vendor does
            not occupy and staff its Vendor Site to the satisfaction of AMFVG
            following notification from AMFVG, clause 5.8(e) shall apply.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 12 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor will ensure that all of its Personnel
            maintain:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. appropriate standards of dress, and presentation; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. personal hygiene to ensure food safety and compliance with
            health legislation and regulations, and the Food Truck Vendor and
            its Personnel will comply with all reasonable requirements of AMFVG
            in this regard
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor will ensure that its Personnel, who wish to
            smoke, shall only do so within smoking areas at the Venue designated
            by AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor will ensure that its Personnel are not
            involved in any serious misconduct while at the Venue, including but
            not limited to
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. Theft
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. Fraud
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. Assault
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. Antisocial or unacceptable workplace behaviour
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            v. Using illegal substances; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            c. Intoxication; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            vii. Any other unlawful conduct
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. Should the Food Truck Vendor fail to comply with any of the
            provisions set out in clauses 5.8(a) to 5.8(d) above and fail to
            rectify such default following receipt of a default notice, AMFVG
            shall be entitled to terminate the Agreement and the Food Truck
            Vendor’s right to occupy the Vendor Site shall immediately revert to
            AMFVG who shall then be entitled to occupy, or offer the right to
            occupy, the Vendor Site to such other person as AMFVG sees fit. In
            the event of termination, the Food Truck Vendor will not be entitled
            to a refund of any Fees paid to AMFVG. AMFVG shall be entitled to
            terminate the Agreement forthwith without a default notice where the
            default referred to above is not capable of rectification
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.9 Vehicle Access and Registration
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG reserves the right at its discretion to determine at any
            time the access and egress rights of vehicles into and from the
            Property and to refuse to permit any vehicle to access the Property
            and/or to order the removal of any vehicle from the Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor acknowledges that no vehicle will be
            permitted to enter or leave the Property during the Event without
            the prior consent of AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor will ensure that any vehicle belonging to
            the Food Truck Vendor or delivering or collecting goods on behalf of
            the Food Truck Vendor enters and leaves the Property at specific
            times designated by AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor acknowledges and will ensure that any
            vehicle accessing the Property on the Food Truck Vendor’s behalf
            must proceed directly to and from its destination and must be parked
            in a way so as to minimise inconvenience to any other person and as
            otherwise directed by the Manager.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            e. The Food Truck Vendor acknowledges and will ensure that no
            vehicle, which accesses the Property on the Food Truck Vendor’s
            behalf, is permitted within any building on the Property without
            AMFVG’s prior approval.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 13 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Food Truck Vendor acknowledges and agrees that, should it
            fail to comply with clause 5.9 of this Agreement, AMFVG may, at its
            discretion, cancel a pass issued to the Food Truck Vendor (or to a
            third party at the Food Truck Vendor’s request) and/or remove from
            the Property any vehicle belonging to the Food Truck Vendor or any
            one acting on its behalf
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. The Food Truck Vendor acknowledges and agrees that if AMFVG
            removes a vehicle from the Property pursuant to this clause 5.9(f),
            the Food Truck Vendor shall have no claim against AMFVG for any loss
            or inconvenience arising out of or in connection with such removal,
            and agrees to indemnify AMFVG against any claims made by the Food
            Truck Vendor or by any third parties in connection with such removal
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. The Food Truck Vendor shall procure that its Food Truck on the
            Vendor Site shall throughout the Term be comprehensively insured for
            its full replacement value and currently registered by the relevant
            State road transportation authority.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor agrees to comply with all terms and
            conditions of the AMFVG’s signage standards set out in the Policies.
            In particular, the Food Truck Vendor agrees and acknowledges that:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. all Signs must be of a professional standard
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. all Signs are subject to the prior written approval of AMFVG;
            and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. no hand-written Signs are permitted
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. Placement of Food Truck Vendor’s Signs must not obscure the clear
            line of sight to emergency evacuation or warning signage. If
            required, the cost of temporary replacement or relocation of
            emergency signs will be at the expense of the Food Truck Vendor.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor may only erect or display a Sign at the
            Venue if it conforms with the signage requirements set out in the
            Policies and if the Food Truck Vendor has the prior written approval
            of AMFVG.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. AMFVG reserves the right to vary or amend Policies, including any
            Sign specifications, from time to time.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. If AMFVG requires any Sign to be altered or removed then, upon
            being notified by AMFVG to alter or remove a Sign, the Food Truck
            Vendor must promptly take such action at the Food Truck Vendor’s own
            cost and expense
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. All the Food Truck Vendor’s Signs must be located within the
            Vendor Site unless otherwise approved by AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. The Food Truck Vendor may not enter into, or hold itself out to
            any third party as being permitted to enter into, an agreement with
            a third party to advertise or promote the third party’s products or
            services on or in proximity to the Vendor Site, unless the Food
            Truck Vendor has received AMFVG’s prior written consent
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.11 Erection of any Structure, Stall, Market Umbrellas or Exhibit
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must submit to AMFVG all plans for any
            structure, stalls, market umbrellas or exhibit to be erected on the
            Vendor Site at least twenty one (21) days prior to the commencement
            of the Event. The Food Truck Vendor must receive AMFVG’s written
            approval to the construction of any structure, stall, market
            umbrellas or exhibit on the Vendor Site prior to construction and
            the commencement of the Event Date.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 30,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor acknowledges that AMFVG does not possess
            the required expertise in assessing the safety or fitness for the
            Food Truck Vendor’s intended purpose of any proposed structure,
            stall, market umbrellas or exhibit, and that the Food Truck Vendor
            must ensure that all structures, stalls, market umbrellas or
            exhibits meet the Food Truck Vendor’s needs, are safe, and comply
            with all applicable laws and regulations. The Food Truck Vendor
            agrees that the Food Truck Vendor shall have no claim of any nature
            whatsoever against AMFVG if AMFVG gives approval to the Food Truck
            Vendor pursuant to clause 5.11(a).
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 14 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor may arrange with AMFVG for a mutually
            convenient time for the Food Truck Vendor to inspect and measure the
            Vendor Site prior to the commencement of the Venue and the Food
            Truck Vendor acknowledges that it does not rely on any information
            provided by AMFVG in relation to the nature and condition of the
            Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. AMFVG may direct the routing, installation and location of any
            structure, stall, market umbrellas or exhibit and the Food Truck
            Vendor must comply with AMFVG’s directions
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. The Food Truck Vendor must complete any structure, stall, market
            umbrellas or exhibit prior to the commencement of the Event. If the
            Food Truck Vendor does not complete any structure, stall, market
            umbrellas or exhibit by such time as directed by AMFVG, AMFVG may
            inform the Food Truck Vendor accordingly in writing, and upon that
            notice being given, the right to occupy the Vendor Site immediately
            reverts to AMFVG, who will then be entitled to occupy or offer the
            right to occupy the Vendor Site to such other person as AMFVG sees
            fit
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. The Food Truck Vendor must not paint any surface of any
            structure, or drive any nails or screws into, or otherwise interfere
            with, any structure on the Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. The Food Truck Vendor must take all reasonable precautions to
            ensure that no structure is damaged. For example, the Food Truck
            Vendor must cover any surface with protective material that may
            otherwise be marked by grease or dirt.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            h. For any marquee, tent, market umbrellas or similar structure,
            Form 1507a “Certificate of Compliance – Design” must be completed by
            the manufacturer and submitted to AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. (i) Any marquee, tent, market umbrellas or similar structure must
            be secured by weights consistent with the recommendations of the
            HRIA“Temporary Structure/ Marquee Weighting Guide” and no
            penetration of the ground surface will be permitted
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.12 Removal of any Structure, Stall, Market Umbrellas, Exhibit or
            Sign
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. Unless AMFVG directs otherwise, the Food Truck Vendor must not
            remove from or alter or interfere with any structure, stall, market
            umbrellas, exhibit or Sign at the Venue during the Term
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. Without limiting 5.12(a), the Food Truck Vendor may only remove
            from the Venue any structure, stall, market umbrellas, exhibit or
            Sign belonging to the Food Truck Vendor, but this may only be done
            during times approved by AMFVG and as directed by AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor must clean and return to AMFVG the Vendor
            Site in the same condition that it was in prior to the Event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor must remove any structure, stall, market
            umbrellas, exhibit, Sign, waste material or other object to the
            satisfaction of AMFVG within such timeframe as directed by AMFVG and
            make good any damage caused by the removal.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. Any object(s) not removed in accordance with 5.12(b) to (d) will
            become the property of AMFVG, who may:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. at the Food Truck Vendor’s expense, remove and dispose of the
            objects as AMFVG sees fit and the Food Truck Vendor will have no
            claim against AMFVG in relation thereto and indemnifies AMFVG
            against any claim for any loss caused by such removal or disposal;
            or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            ii. charge the Food Truck Vendor for the use of the Vendor Site for
            such time that any object remains on the Vendor Site subsequent to
            the expiration of the Term, at AMFVG’s prevailing daily cancellation
            fee.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 15 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.13 Flammable Substances
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must not:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. store any flammable goods at the Vendor Site other than gas
            bottles, nor do or permit any act to be done which may invalidate
            any insurance policy for the Property and the Event, of which the
            Vendor Site forms part
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. erect any Sign made of flammable material; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. use any electrical stacking equipment or other heavy equipment
            except with the prior written permission of AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. All gas bottles stored or used by the Food Truck Vendor at the
            Vendor Site must be stored and used by the Food Truck Vendor and its
            Personnel in accordance with the relevant State and Territories Code
            of Practice for the safe storage and use of LP Gas at public events
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.14 Connection to Services
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor is responsible for connecting, at its own
            expense, any electrical, gas, water, drainage or other utility
            service connections from the nearest point at which the service is
            made available at the Venue by AMFVG. The connections must comply
            with all regulations of the relevant authority and all applicable
            laws and may not occur without the prior written approval of AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must obtain AMFVG’s prior written approval
            to operate any electrical apparatus that may use over four kilowatts
            on single phase or ten kilowatts on three phases and such electrical
            apparatus must be tested and tagged as current and safe for use
            prior to apparatus and equipment being used at the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. AMFVG has the right to disconnect any electrical or gas or other
            utility connection that it considers to be unsafe, or which may be
            overloading the service lines and the Food Truck Vendor releases
            AMFVG from all claims and liabilities in relation thereto
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor acknowledges that AMFVG does not possess
            any expertise in assessing the safety or fitness for the Food Truck
            Vendor’s purpose of the required service connection, and that the
            Food Truck Vendor must ensure that all service connections meet the
            Food Truck Vendor’s needs, are safe, and comply with all applicable
            laws and regulations
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. The Food Truck Vendor acknowledges that electricity, gas, water,
            drainage and other utility services are provided by third party
            service providers over whom AMFVG has no control, and who are solely
            responsible for the supply to the Food Truck Vendor of such
            services. Accordingly, the Food Truck Vendor acknowledges that AMFVG
            does not and cannot represent or guarantee to the Food Truck Vendor:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. the merchantability of any such services
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. the fitness of any such services for the Food Truck Vendor’s
            intended purposes; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 70,
              marginLeft: 40,
            }}
          >
            iii. the supply or continued supply of any such services to the Food
            Truck Vendor during the Term or at any other time, and releases
            AMFVG from all claims and liabilities in relation thereto
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 16 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.15 Cleaning
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. During the Venue the Food Truck Vendor must, at the Food Truck
            Vendor’s expense, keep the Vendor Site (and, where applicable, the
            surrounding area to a distance of three metres from the Vendor Site)
            clean and tidy, store all waste in proper receptacles and make sure
            it is removed regularly, and at a minimum, daily.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The washing down of any equipment or the cleaning of
            paint-brushes will not be permitted on the Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. The Food Truck Vendor shall ensure that no spillage or overflow
            occurs in respect of sullage tanks. The cost of any cleanup in
            respect thereof and in respect of the use and spillage by the Food
            Truck Vendor of deep frying oil shall be born by the Food Truck
            Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.16 Maintenance and Repair
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must, at its cost and expense, throughout
            the Term keep the Vendor Site and the Food Truck Vendor’s Property
            in good repair and condition and in compliance with the Food
            Standard Australian New Zealand Code
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. During the Term the Food Truck Vendor must promptly report to
            AMFVG any damage to the Vendor Site or to any structure or thing at
            the Venue caused by the conduct of the Food Truck Vendor, its
            Personnel or any other person granted or permitted access to the
            Vendor Site by the Food Truck Vendor, and shall bear the cost of any
            necessary repairs.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. On expiry of the Term the Food Truck Vendor must leave the Vendor
            Site in good repair and condition, except for fair and reasonable
            wear and tear, having regard to the condition of the Vendor Site at
            commencement of the Event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.17 Avoid Damage and Danger
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor will comply at all times with the
            provisions of the relevant occupational health and safety
            legislation, standards and codes of practice and as otherwise
            provided for in the Policies
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must not do or omit to do anything
            (including keeping anything on the Vendor Site) that may in any way
            endanger the Property, any person, or any equipment or goods
            (whether belonging to the Food Truck Vendor or anyone else) at the
            Venue.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. AMFVG may require the Food Truck Vendor to remove any item or
            thing from the Venue that, in AMFVG’s opinion, is or is likely to or
            may become dangerous to the Property or any person or thing at the
            Venue. If the Food Truck Vendor fails to remove such item or thing
            upon request, AMFVG may arrange for its removal at the Food Truck
            Vendor’s expense
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.18 Notify Danger or Risk
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. promptly inform AMFVG if it is aware of any hazard or risk to the
            Vendor Site or the Property; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. promptly comply with any instructions given by AMFVG, the police,
            the fire brigade or any other emergency authority (for example, to
            leave the Vendor Site).
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.19 Security
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must use its best endeavours to keep the
            Vendor Site and the Food Truck Vendor’s Property safe and secure and
            to protect it against theft.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must obtain AMFVG’s prior written approval
            for any night security person that the Food Truck Vendor intends to
            use.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 17 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. AMFVG may enter the Vendor Site for any purpose concerning
            security, but this does not make AMFVG responsible in any way for
            its security.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. The Food Truck Vendor accepts full responsibility for the
            security of the Food Truck Vendor’s Property retained or stored on
            the Vendor Site and AMFVG will not be liable for any damage or loss
            to the Food Truck Vendor’s Property or stock, exhibits or personal
            belongings and property stored on the Vendor Site resulting from any
            cause whatsoever, either prior, during or subsequent to the Term
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.20 No Nuisance
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must not do or omit to do anything that is
            or may reasonably be deemed by AMFVG to be an annoyance, nuisance or
            disturbance to AMFVG other food truck vendors at the Venue or anyone
            else participating at or visiting the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must not store any items on the Property
            outside of the Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.21 Noise
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor may not use any amplifier, loud-speaker or
            similar appliance within or outside the Vendor Site without AMFVG’s
            prior written approval. (b) AMFVG reserves the right to require the
            Food Truck Vendor to immediately stop any noise being emitted from
            the Vendor Site which AMFVG reasonably considers to be offensive or
            an annoyance, nuisance or disturbance.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.22 Balloons
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor must ensure that no balloons, whether inflated
            or not, are brought onto the Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.23 People using Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor must at all times ensure that all persons
            occupying or using the Vendor Site (including the Food Truck
            Vendor’s Personnel) comply with the terms of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.24 Acknowledgement by the Food Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor acknowledges and agrees with AMFVG that the
            Policies form part of this Agreement and that a breach of the
            provisions of any of the Policies constitutes a breach of this
            Agreement and may have an adverse environmental effect and impact on
            the Property and the surrounding inhabitants and that the Food Truck
            Vendor has:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            a. read and understood the attached Policies; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            b. brought the terms of Policies to the attention of the Food Truck
            Vendor's Personnel and contractors
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.25 Reputation of AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor and its Personnel must not do or omit to do
            any act or thing which might reasonably be expected to reduce or
            diminish the good name and reputation of AMFVG or any of its
            sponsors or any other food truck vendor, the business of AMFVG or
            the goodwill of any AMFVG event.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.26 Induction Guidelines
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must ensure that it has completed the
            AMFVG’s online Induction Form (link provided by AMF) and that all of
            its Personnel are familiar with the procedures set out in that
            portal. (b) The Food Truck Vendor and its Personnel must comply at
            all times with the Policies and all relevant OH&S legislation,
            Australian Standards and Compliance Codes
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 18 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            5.27 Ambush Marketing
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor must not engage in ambush marketing of the
            products or services of any other food truck vendor at the Venue or
            any sponsor of the Event or AMFVG (as advised by AMFVG prior to the
            Venue). AMFVG will be entitled, at its discretion, to terminate this
            Agreement, retain the Fees and remove the Food Truck Vendor from the
            Venue should the Food Truck Vendor engage in ambush marketing.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6 AMFVG’s Rights
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6.1 AMFVG May Enter and Inspect
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            AMFVG may enter the Vendor Site to inspect it and the Food Truck on
            the Vendor Site or to identify any necessary maintenance, repairs or
            work, at any reasonable time and with reasonable notice. If there is
            an emergency, AMFVG may enter at any time without notice.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6.2 AMFVG May Do Things On Food Truck Vendor’s Behalf
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            AMFVG may carry out any of the Food Truck Vendor’s obligations on
            the Food Truck Vendor’s behalf, if the Food Truck Vendor does not
            perform those obligations on time. The Food Truck Vendor must
            promptly reimburse AMFVG for the cost and expense of doing so. 6.3
            Repairs and Work AMFVG must give the Food Truck Vendor notice of any
            proposed repairs, maintenance or works required to be carried out
            and cause as little obstruction to the Food Truck Vendor’s use of
            the Vendor Site as is reasonably possible in the circumstances and
            shall not be liable to the Food Truck Vendor for any loss or
            inconvenience in relation thereto
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6.4 Removal of Food Truck Vendor’s Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG, acting reasonably, may require the Food Truck Vendor to
            remove any of the Food Truck Vendor’s property including its Food
            Truck and any structure, stall, market umbrellas or exhibit from the
            Vendor Site or the Property
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must promptly obey AMFVG’s requirements
            under clause 6.4(a).
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. If the Food Truck Vendor fails to promptly obey AMFVG’s
            requirements, AMFVG may arrange for the removal of the Food Truck
            Vendor’s Property at the Food Truck Vendor’s own cost and expense
            and the Food Truck Vendor will have no claim against AMFVG for any
            loss caused by the exercise of AMFVG’s rights under this clause 6.4
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6.5 Relocating Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG reserves the right at its discretion to relocate part or
            all of the Vendor Site to another area on the Property at any time.
            Insofar as possible, AMFVG will endeavour to ensure that the
            relocated Vendor Site is of comparable convenience to the Food Truck
            Vendor and the Food Truck Vendor releases AMFVG from any claims or
            losses in relation thereto
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. In the event AMFVG directs the Food Truck Vendor that the Vendor
            Site must be relocated, the provisions of the Agreement will
            continue to apply.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            6.6 Making Rules
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG reserves the right at its discretion to make any rules as
            to the use of the Vendor Site and the Property provided that they
            are not inconsistent with the Food Truck Vendor’s rights under this
            Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must comply with any rules AMFVG makes
            under clause 6.6(a) and notifies to the Food Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 19 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            7 Insurance
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            7.1 Insurance Policies
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. The Food Truck Vendor must take out and keep current during the
            Term public and product liability insurance policies for at least
            the amount specified in Item 9 of Schedule 1, for any one occurrence
            and in the aggregate for products liability
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. The Food Truck Vendor must, at its own cost and expense, effect
            and maintain throughout the Term an insurance policy to cover the
            Food Truck Vendor’s Property in the possession, custody or control
            of the Food Truck Vendor which is to be used or, which is intended
            to be used, for the purposes of performing its obligations under
            this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. With respect to each insurance policy required under this clause
            7.1
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. The Food Truck Vendor must ensure that the policies are taken out
            with an APRA approved insurance company
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. The Food Truck Vendor must produce to AMFVG for inspection as
            required by AMFVG the policies, certificates of currency and receipt
            for the payment of the last renewal of the premium at any time
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. The Food Truck Vendor must notify AMFVG of any cancellation or
            threatened cancellation of its insurance required under this clause
            7.1 by giving AMFVG fourteen (14) days prior written notice;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. The Food Truck Vendor must ensure that AMFVG, and if required by
            AMFVG, the owner of the Property are included as insured parties on
            the insurance policies; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            v. The Food Truck Vendor must ensure that each insurance policy
            provides that: A. the insurer will treat notice of any occurrence
            given by one insured party as notice given by all insured parties;
            and B. failure by one insured party to disclose any material
            information or to comply with the conditions of the policy will not
            prejudice the rights of any other insured party.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. If the Food Truck Vendor fails to comply with its insurance
            obligations under this clause 7.1, AMFVG by notice may require it to
            do so and if, within seven (7) days of the request by AMFVG, the
            Food Truck Vendor has not produced a current insurance policies,
            certificates of currency or receipts in respect of its insurance
            conforming with the requirements of this clause 7.1, AMFVG reserves
            the right to terminate Food Truck Vendor’s Right of Occupancy in
            accordance with clause 10 of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            7.2 Food Truck Vendor Not to Void Insurance
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor must not, and it must procure that its
            Personnel must not, do or omit to do anything on the Vendor Site or
            on the Property, which may invalidate or render void or voidable any
            insurance policies of AMFVG or any other insurance policies taken
            out pursuant to this clause 7
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            7.3 Statutory Compulsory Insurances
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor must at its own cost and expense, effect and
            maintain and comply with the terms and all requirements at law of
            all statutory compulsory insurances, including those insurance
            policies required to be effected with respect to
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. workers compensation; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            b. the use of motor vehicles.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 20 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            8 Risk, Release and Indemnity
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            8.1 Risk
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor acknowledges that it uses the Vendor Site at
            the Food Truck Vendor’s sole risk
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            8.2 Release
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor releases AMFVG and its Personnel to the full
            extent permitted by law from all demands, actions, claims,
            liabilities, loss, damage, cost or expense for or resulting from
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. the cancellation by AMFVG of all or any part of the Event , or
            postponement of the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. the exercise by AMFVG of any of its rights provided for under
            this Agreement;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. any loss suffered by the Food Truck Vendor due to the failure of
            supply of electricity or any other services at the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. any loss of or damage to the Food Truck Vendor’s property or to
            the property of a third party regardless of how the loss or damage
            was caused
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. any loss suffered by the Food Truck Vendor as a result of the
            Food Truck Vendor occupying and using the Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            f. the death of or injury to any person occupying, being present in
            or in proximity to, or using the Vendor Site; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            g. the theft of the Food Truck Vendor’s property while on the Vendor
            Site, except to the extent that the loss, death, injury, loss or
            damage is proven to have been caused by or contributed to by AMFVG’s
            negligence
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            8.3 Indemnity by Food Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor indemnifies AMFVG and its Personnel against
            all demands, actions, claims, liabilities, loss cost and expense
            that may be incurred or sustained by AMFVG and its Personnel as a
            result of any act, matter or thing done, permitted or omitted to be
            done by the Food Truck Vendor or its Personnel pursuant to or in
            connection with this Agreement including but not limited to:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. the Food Truck Vendor's use or misuse of the Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. the Food Truck Vendor’s sale or supply of products or goods from
            the Vendor Site
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. any act done (or anything omitted to be done) by or on behalf of
            the Food Truck Vendor:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            i. at or in connection with the Vendor Site or the Venue
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            ii. in connection with the Event; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            iii. arising out of or in connection with the exercise of the Food
            Truck Vendor’s rights pursuant to this Agreement, as a result of
            which any other person alleges that the act or omission (as the case
            may be) infringes the Intellectual Property Rights of that other
            person (whether or not that allegation is tenable)
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. any death, injury, loss or damage from any cause occurring within
            the Vendor Site or caused by or contributed to by the Food Truck
            Vendor or its Personnel, except to the extent that the death,
            injury, loss or damage is proven to have been caused by or
            contributed to by AMFVG’s negligence; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            e. incorrect information in the Induction Form completed and
            provided by the Food Truck Vendor to AMFVG
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 21 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            8.4 Continuing Indemnity
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The parties agree that for the avoidance of doubt, the indemnities
            given by the Food Truck Vendor to AMFVG in this clause 8 survive the
            termination or any assignment or novation of this Agreement,
            irrespective of how the termination, assignment or novation occurs
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            9 Confidentiality
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            9.1 The terms and conditions of this Agreement and all information
            provided under or in connection with this Agreement (Confidential
            Information) are confidential.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            9.2 Each party undertakes to the other that it and its Personnel
            will not, without the consent of the other party, which consent may
            not be unreasonably withheld or delayed, disclose Confidential
            Information to any person, unless disclosure is required by law. 9.3
            Each party must take all steps reasonably necessary to ensure that
            Confidential Information is disclosed only to such Personnel as
            require that knowledge in order to carry out their obligations in
            accordance with this Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            10 Ending the Right of Occupancy
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            10.1 Grounds for Termination
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            AMFVG may terminate the Right of Occupancy immediately by notice in
            writing to the Food Truck Vendor if:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. the Food Truck Vendor fails to pay any part of the Fees payable
            under clause 4.1 by the due dates as directed by AMFVG;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. the Food Truck Vendor becomes insolvent;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. except for clause 10.1(e), the Food Truck Vendor breaches any
            other provision of this Agreement and such breach is not remedied
            within twenty-four (24) hours of receiving verbal or written notice
            from AMFVG requiring the Food Truck Vendor to remedy the breach; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. the Vendor Site is destroyed or damaged such that
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. it is not convenient to AMFVG for the Food Truck Vendor to use or
            access the Vendor Site; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. the Food Truck Vendor becomes insolvent;
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. the Food Truck Vendor fails to comply with the provisions of
            clauses 4.3
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            10.2 Termination
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. Without limiting clause 10.1, if, in AMFVG’s opinion, the Food
            Truck Vendor has committed a Serious Breach (as hereinafter defined)
            of this Agreement AMFVG may terminate the Right of Occupancy:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. immediately, by written notice to the Food Truck Vendor; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 70,
              marginLeft: 40,
            }}
          >
            ii. at AMFVG’s discretion, upon one (1) hours verbal or written
            notice to the Food Truck Vendor, calling upon it to remedy that
            Serious Breach of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 22 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. For the purposes of this clause a Serious Breach is any breach
            that
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. endangers or harms the safety of any person or animal at or in
            the immediate vicinity of the Venue; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. endangers or causes damage to any property at or in the
            immediate vicinity of the Venue; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. involves the commission of a criminal offence; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. involves the intentional misuse of cash registers; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            v. involves failure to comply with the auditing or reporting
            requirements under this Agreement; or
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            vi. is likely to or intended to cause a material disruption to the
            Venue ; or (vii) is likely to or intended to bring the Event or
            AMFVG into disrepute
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            10.3 AMFVG’s Rights upon Termination of Right of Occupancy
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. Termination of the Right of Occupancy is without prejudice to and
            will not affect the accrued rights or remedies of either of the
            parties arising in any way out of this Agreement up to the date of
            termination
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. If the Right of Occupancy is terminated pursuant to any of the
            provisions of this Agreement, AMFVG may recover damages from the
            Food Truck Vendor for any loss AMFVG suffers because of the Food
            Truck Vendor’s breach. The loss may include professional fees
            incurred by AMFVG, loss of the Fees or any part thereof from the
            date AMFVG terminates the Right of Occupancy until the date the
            Right of Occupancy would have expired if AMFVG had not terminated it
            earlier or loss of other revenue.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. AMFVG will not be liable to the Food Truck Vendor for any loss
            suffered by the Food Truck Vendor by reason of the termination of
            the Right of Occupancy and shall not be liable to refund any money
            received from the Food Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. If the Right of Occupancy is terminated, the Food Truck Vendor
            remains liable to forthwith pay AMFVG any unpaid amounts it owes to
            AMFVG, including any unpaid part of the Fees.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            e. Upon termination of the Right of Occupancy
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. the right to occupy the Vendor Site immediately reverts to AMFVG
            and AMFVG will be free to occupy or offer the right to occupy the
            Vendor Site to such other person or persons as AMFVG sees fit; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. AMFVG reserves the right in its discretion to take whatever
            steps it deems necessary to remove the Food Truck Vendor from the
            Vendor Site and the Property, at the Food Truck Vendor’s cost.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            11 Privacy Notice
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. AMFVG may collect personal information (within the meaning of the
            Privacy Act 1988 and other applicable privacy legislation) about the
            Food Truck Vendor and its Personnel for the purpose of administering
            and providing services under or connected with this Right of
            Occupancy, including, if necessary, the recovery of any money owing
            under or in relation to this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 50,
              marginLeft: 20,
            }}
          >
            b. The personal information collected may be shared with other
            bodies such as sponsors, service providers (e.g. telecommunications
            providers), insurance providers or debt collection agencies, for the
            purposes mentioned in clause 11(a). The information may also be
            disclosed to any entity that may subsequently administer the Venue
            or if required or authorised by law
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 23 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. Persons about whom such personal information is collected
            (including the Food Truck Vendor if the Food Truck Vendor is a
            natural person) have certain rights of access to their personal
            information held by AMFVG and can enquire about obtaining such
            access by contacting AMFVG by telephoning 1300 162 636 or writing to
            Australian Mobile Food Vendors Group Pty Ltd, 29 Malua Street,
            Reservoir, Victoria 3073.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            d. AMFVG may conduct surveillance of the Property and may use closed
            circuit television (CCTV) filming the Property for security and
            safety. A person entering the Property may be photographed, filmed,
            taped and/or subjected to monitoring by CCTV and it is deemed that a
            person, by entering the Property, consents to AMFVG or third parties
            appointed by AMFVG photographing, filming or taping.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12 General
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.1 Application of this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The terms of this Agreement bind AMFVG and the Food Truck Vendor to
            the extent applicable both prior to, during and after the Term
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.2 Serving Notices
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. A notice, demand, consent or other communication (Notice) given
            or made under this Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            b. A Notice will be taken to be duly given or made:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. in the case of delivery by person, when delivered
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. in the case of delivery by post, on the third day after the date
            it is posted;
          </Text>

          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iii. in the case of fax, on receipt by the sender of a successful
            transmission control report from the dispatching machine showing the
            relevant number of pages, the correct destination fax machine number
            and the result of the transmission as "OK";
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            iv. in the case of email, on the day that the email was sent
            provided no notice has been received by the sender of a delivery
            failure, but if the result is that a Notice is sent after 4.00pm
            (local time) it will be taken to have been duly given or made at 9
            am (local time) on the next business day in that place
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            c. Where any Notice under this Agreement is required or permitted to
            be given by AMFVG to the Food Truck Vendor, it may be given on
            behalf of AMFVG by the Manager
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.3 AMFVG’s Consents and Approvals
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            Unless expressly stated in a particular provision:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            a. where AMFVG’s consent or approval or exercise of its discretion
            is required or permitted under this Agreement, AMFVG may:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            i. give or withhold the consent or approval or exercise the
            discretion in its sole and absolute discretion; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            ii. give the consent or approval or exercise the discretion either
            conditionally or unconditionally; and
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            b. a consent or approval binds AMFVG only if it is in writing and
            signed by AMFVG or by its authorised representative.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 24 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.4 Further Action by Food Truck Vendor
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            Without limiting any other provision of this Agreement, the Food
            Truck Vendor must do all things and execute all deeds, instruments,
            transfers or other documents as may be necessary or desirable and do
            anything else that is necessary or that AMFVG reasonably requests to
            give full effect to the provisions of this Agreement and the
            transactions contemplated by it.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.5 Entire Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            This Agreement contains the entire agreement of the parties with
            respect to its subject matter and supersedes all prior agreements
            and understandings between the parties.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.6 Variation of Agreement
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            No amendment or variation of this Agreement is valid or binding on a
            party unless made in writing and executed by both parties.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.7 Survival of Provisions
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            The rights and obligations of the parties will not merge on the
            completion of any transaction contemplated by this Agreement. They
            will survive the execution and delivery of any assignment or other
            document entered into for the purpose of implementing any such
            transaction. Accordingly, a provision of this Agreement that has not
            been met when the Right of Occupancy ends, or can have effect after
            that date, continues to apply after the Right of Occupancy ends
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.8 Severability of Provisions
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            Any provision of this Agreement which is prohibited or unenforceable
            in any jurisdiction is ineffective as to that jurisdiction to the
            extent of the prohibition or unenforceability. That does not
            invalidate the remaining provisions of this Agreement nor affect the
            validity or enforceability of that provision in any other
            jurisdiction
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.9 AMFVG’s Right of Set-off
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            If at any time AMFVG has any liability to pay money to the Food
            Truck Vendor, whether under this Agreement or otherwise, AMFVG may
            elect to set-off that liability (either in whole or in part) against
            any amount due or due and payable by the Food Truck Vendor to AMFVG
            under this Agreement.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.10 No Waiver
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            No failure to exercise nor any delay in exercising any right, power
            or remedy by a party operates as a waiver. A single or partial
            exercise of any right, power or remedy does not preclude any other
            or further exercise of that or any other right, power or remedy. A
            waiver is not valid or binding on the party granting that waiver
            unless made in writing.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.11 Governing Laws and Jurisdiction
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            This Agreement is governed by the laws of the State within which the
            Event is held. Each party submits to the non-exclusive jurisdiction
            of courts exercising jurisdiction there in connection with matters
            concerning this Agreement and waives any rights to claim that those
            courts are an inconvenient forum.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            12.12 No Representation
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            The Food Truck Vendor acknowledges that the AMFVG and its Personnel
            have made no representations, promises, warranties, guarantees or
            undertakings to induce the Food Truck Vendor to enter into this
            Agreement.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 25 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text style={{ fontSize: 17, paddingBottom: "10px" }}>
            Schedule 1
          </Text>
          <Text style={{ fontSize: 15, marginBottom: 5 }}> Item 1:</Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 10,
            }}
          >
            DATE:{" "}
            {new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
          <Text style={{ fontSize: 15, marginBottom: 5 }}> Item 2:</Text>
          <Text style={{ fontSize: 12, marginBottom: 5 }}>
            {" "}
            Food Truck Vendor Details:
          </Text>
          {vendor.map((item) => (
            <>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 20,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 10,
                }}
              >
                Vendor Food Truck: {item.title}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 20,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 10,
                }}
              >
                Food Truck Vending Business: {item.title}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Food Truck ABN: {item.abn_number}
              </Text>
              <Text style={{ fontSize: 15, marginBottom: 5 }}> Item 3:</Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Postal Address: {item.address}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Email: {item.email}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Phone Number: {item.phone}
              </Text>
              <Text style={{ fontSize: 15, marginBottom: 10 }}> Item 4:</Text>
              <Text style={{ fontSize: 12, marginBottom: 5 }}>
                {" "}
                Event Details:
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Event Name: {EOI.eoi_event_name}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Event Address:{EOI.event_organiser_address}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Bump In:
                {`${moment().format(
                  "DD-MM-YYYY, h:mm a",
                  EOI.bump_in_date_time
                )}`}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Bump Out:
                {`${moment().format(
                  "DD-MM-YYYY, h:mm a",
                  EOI.bump_out_date_time
                )}`}
              </Text>
              <Text style={{ fontSize: 15, marginBottom: 10 }}> Item 5:</Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Duration:{" "}
                {`${moment
                  .duration(
                    moment(EOI.event_end_date_time).diff(
                      moment(EOI.event_start_date_time)
                    )
                  )
                  .humanize()}`}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                From:
                {`${moment().format(
                  "DD-MM-YYYY, h:mm a",
                  EOI.event_start_date_time
                )}`}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                To:
                {`${moment().format(
                  "DD-MM-YYYY, h:mm a",
                  EOI.event_end_date_time
                )}`}
              </Text>
              {/* <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Power:{" "}
                {`${item.number_of_10_amp} x 10 AMPS | ${item.number_of_15_amp} x  15 AMPS | ${item.number_of_20_amp} x 20 AMPS | ${item.number_of_32_amp} x 32 AMPS`}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontStyle: "normal",
                  marginLeft: 20,
                  marginBottom: 5,
                }}
              >
                Water Access: {item.water_access}
              </Text> */}
            </>
          ))}
          <Text style={{ fontSize: 15, marginBottom: 10 }}> Item 6:</Text>
          <Text style={{ fontSize: 12, marginBottom: 5 }}> Event Fees:</Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            {"Upfront Site Fees: "}
            {`${EOI.site_fees}`}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            {"Commission: "}
            {`${EOI.non_members_commission}`}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 40,
            }}
          >
            {"Bond:"}
            {`${EOI.bond_amount}`}
          </Text>
          {/* <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 100,
            }}
          >
            Services: {EOI.service}
          </Text> */}
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              paddingTop: 40,
            }}
          >
            Page 26 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text style={{ fontSize: 15, marginBottom: 10 }}> Item 7:</Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Site Prepration Fee: N/A
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Power Fee:{" "}
            {`${EOI.number_of_10_amp} x 10 AMPS | ${EOI.number_of_15_amp} x  15 AMPS | ${EOI.number_of_20_amp} x 20 AMPS | ${EOI.number_of_32_amp} x 32 AMPS`}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Utility Fee: TBA (1 x Water Point, Sullage Pump Out) per food truck
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Shared Cool Room Hire Fee: $750 per Coolroom 23rd march last day to
            book a coolroom
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Coffee provider:
          </Text>
          <Text style={{ fontSize: 15, marginBottom: 10 }}> Item 8:</Text>
          <Text style={{ fontSize: 12, marginBottom: 5 }}>
            Insurance: Public and Product Liability
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Policy Number:
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Insured With:
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontStyle: "normal",
              marginLeft: 20,
              marginBottom: 5,
            }}
          >
            Cover Limit: $20 million
          </Text>

          <Text
            style={{
              fontSize: 15,
              marginBottom: 10,
            }}
          >
            Executed as an Agreement:{" "}
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            I hereby confirm that I have read and understand The Food Truck
            Vendor’s Catering Agreement in full, I am an authorised office
            holder of the Food Truck Vendor with appropriate delegated authority
            to enter into and make agreements on the Food Truck Vendor’s behalf.
            Consequently, my signature below represents agreement and acceptance
            of this Agreement in its entirety by the Food Truck Vendor I am
            representing{" "}
          </Text>
          <Text
            style={{
              fontSize: 15,
              fontWeight: 20,
              fontStyle: "normal",
              marginBottom: 10,
            }}
          >
            Date of Agreement: _________ / _____________ / 20 _____________
          </Text>
          <Text> </Text>
          <Text
            style={{
              fontSize: 15,
              fontWeight: 20,
              fontStyle: "normal",
            }}
          >
            (Contracter)Signed By :________________________________________
          </Text>
          <Text> </Text>
          <Text
            style={{
              fontSize: 15,
              fontWeight: 20,
              fontStyle: "normal",
            }}
          >
            (Contractee)Signed By :________________________________________
          </Text>
          <Text> </Text>
          <Text style={{ fontSize: 17, paddingBottom: "20px" }}>
            Schedule 2
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            POLICIES
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 70,
              marginLeft: 20,
            }}
          >
            Refer to Vendor Standards.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 27 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text style={{ fontSize: 17, paddingBottom: "20px" }}>
            Schedule 3
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            1. The Food Truck Vendor will pay AMFVG 25% of the Gross Receipts
            (inc GST) from the Permitted Activity, ‘Gross Receipts’ being the
            amount equal to:
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            a. All revenue received or receivable by Vendor or any person on
            behalf of Supplier for or in respect of the food trucks; less
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            b. Any credit card or other banking fees imposed on the collection
            of that revenue
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            Commission concessions on revenue as follows
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            Royalty: The parties agree the following tiered royalty structure in
            relation to the total Gross Receipts in respect of the Permitted
            Activity at the Melbourne international Flower and garden show
            during the Permitted Activity Period (to be reconciled by AMFVG):
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            25% of all Gross Receipt if the total Gross Receipts is $65,000 or
            more
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            For example, if $84,572 in Gross Receipts are received then a
            royalty of 25%, or $21,143.00 will be payable. Gross Receipts means
            an amount equal to all revenue received or receivable by AMFVG or
            any person on behalf of AMFVG for or in respect of the Permitted
            Activity (including any credit card or other banking fees or
            merchant fees including payment platform or online ordering fees
            imposed on the collection of that revenue).
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 40,
            }}
          >
            For the avoidance of doubt, the parties agree that any credit card
            or other banking or merchant fees (including payment platform or
            online ordering fees imposed on the collection of revenue) incurred
            by AMFVG in respect of the Permitted Activity will be deducted from
            any monies to be paid to the Licensee following the Event
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            2. AMFVG will collect all receipts in respect of the Food Truck
            Vendors
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            3. AMFVG will provide accurate and detailed daily reports to the
            Food Truck Vendors during Event regarding the Gross Receipts
            collected from the food trucks for the preceding day.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            4. AMFVG will conduct a post-Event reconciliation for the Food Truck
            Vendors and advise the Supplier of the total Gross Receipts and the
            Royalty which will be retained by AMFVG within 5 days of the end of
            the Event. The Food Truck Vendor will then issue a valid tax invoice
            to AMFVG for the retained Royalty and other amounts and AMFVG will
            make payment of the balance within 7 days of receipt of the valid
            tax invoice from The Food Truck Vendor.
          </Text>
          <Text style={{ fontSize: 15, paddingBottom: "20px" }}>Others</Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            5. The Food Truck Vendor must pay the costs of any utilities or
            other services incurred in respect of the operation of the Permitted
            Activity at the Venue, including without limitation water, waste,
            sullage, gas, electricity and or other. AMFVG will issue the
            Supplier with tax invoices for any such amount payable to AMFVG
            following the conclusion of the Event. Any such invoices will be
            payable and deducted at payout.
          </Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 40,
              marginLeft: 20,
            }}
          >
            6. IMG Beverage Specification and Price Listing (MIFGS require ALL
            Beverages to be purchased via AMFVG Exclusively on behalf of IMG.
            Amfvg has elected to provide vendors with a larger margin range via
            a can and water bottle arrangement. This can be purchased via the
            WTT vendor portal with orders required prior to the commencement of
            the event, no other provider be authorized on site and same range
            products will not be permitted to be sourced via external providers
            should any vendor purchase via other third-party the vendor will be
            closed immediately and forfeit its right to continue trading at the
            event. It will also be charged for any shortfall in purchase price
            via a review and audit to sales data. AMFVG will compare supermarket
            pricing and charge the vendor the difference for sales achieved up
            until this point.
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 28 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
          <Text> </Text>
          <Image
            source={{
              uri:
                "https://pbs.twimg.com/profile_images/471174556665655296/S6CZ9-wZ_400x400.png",
            }}
            style={{
              width: 90,
              height: 90, // Adjust the size as needed
              resizeMode: "contain", // Ensures the image aspect ratio is not distorted
            }}
          />

          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              marginBottom: 4,
            }}
          >
            Food Truck Vendor's Catering Agreement
          </Text>
          <View
            style={{
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              marginBottom: 20,
            }}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
              marginLeft: 20,
            }}
          >
            7. You will receive 1 POS tablet as part of your subscription. 1
            additional tablet can be provided subject to approval. Should you
            require any more than 2 POS terminals below fees will apply:
          </Text>
          <Image
            style={{
              width: "400px",
              height: "200px",
              paddingBottom: "50px",
              resizeMode: "contain",
            }}
            source={require("../../assets/img/price_list.png")}
            cache={false}
          />
          <Text
            style={{
              fontSize: 10,
              marginBottom: 325,
              marginLeft: 20,
            }}
          ></Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
            }}
          >
            Page 29 of 29
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            AMFVG
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Privileged Information, Private and Confidential
          </Text>
          <Text
            style={{
              fontSize: 10,
              textAlign: "left",
              marginBottom: 70,
            }}
          >
            Confidential document cannot be reproduced in any form, excerpt,
            whole, by any media. Remains the property of the AMFVG
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfCreator;
