import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import { Form, Container, Button, Row, Col } from "react-bootstrap";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import * as Sentry from "@sentry/react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class CateringAnalytics extends Component {
  state = {
    searchVendor: "",
    filteredVendors: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      startDate,
      endDate,
      selectedVendorSearch,
      filteredVendors,
    } = this.state;
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };
    if (selectedEvent) {
    }
    // let date_range = this.getDateRange(
    //   start_date,
    //   end_date
    // );
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <div className="App">
                    <div className="search-bar-container">
                      <SearchBarVendor
                        vendorList={vendors}
                        placeHolder={"Search Vendors"}
                        setFilteredVendors={setFilteredVendors}
                        selectedVendorId={this.state.selectedVendorId} // Pass the selected event ID
                        selectedVendorSearch={this.state.selectedVendorSearch}
                      />
                      <SearchResultsListVendor
                        filteredVendors={filteredVendors}
                        selectedVendorP={selectedVendorP}
                        selectedVendorQ={selectedVendorQ}
                      />
                    </div>
                  </div>
                  {console.log(vendors)}
                  {/* <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId: "" + e.target.value },
                          () => {
                            // this.getOrders();
                          }
                        );
                        saveToLocalStorage(
                          "selectedVendorId",
                          "" + e.target.value
                        );
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group> */}
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Button
                    style={{ marginTop: 30 }}
                    onClick={() => this.getOrders()}
                  >
                    Apply
                  </Button>
                </Col>

                {selectedVendorId != "0" ? (
                  <Col xs={12} md={3} lg={3}>
                    <Form.Label>Select Time</Form.Label>
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={this.state.selectedDate}
                        onChange={(e) => {
                          this.setState(
                            { selectedDate: e.target.value },
                            () => {
                              this.getOrders();
                            }
                          );
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Sales:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    ${Number(total_sales).toFixed(2) + "/" + total_orders}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Cash</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCash).toFixed(2) + "/" + totalCashOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Card</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCard).toFixed(2) + "/" + totalCardOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{total_orders}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunds:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>${Number(canceled_sales).toFixed(2)}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunded Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{canceled_orders}</h5>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Sales Less Refund:</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>
                      $
                      {Number(
                        Number(total_sales) - Number(canceled_sales)
                      ).toFixed(2)}
                    </b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Cash Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCash) - Number(canceled_sales_cash)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Card Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCard) - Number(canceled_sales_card)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Orders Less Refunded Orders</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>{total_orders - canceled_orders}</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Bar
                    data={{
                      labels: this.state.salesByHoursLabels,
                      datasets: [
                        {
                          label: "Sales by hours ($)",
                          data: this.state.salesByHoursData,
                          backgroundColor: this.getBackgroundColor(
                            this.state.salesByHoursLabels.length
                          ),

                          borderWidth: 1,
                        },
                      ],
                    }}
                    height={400}
                    width={600}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Col>
              </Row>

              <hr />

              {this.state.selectedVendorId != undefined &&
              this.state.selectedVendorId != 0 ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserVendorsPieChart
                      vendorName={this.state.vendorName}
                      vendorData={this.state.meal_freq}
                    ></EventOrganiserVendorsPieChart>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserVendorsPieChart
                      vendorName={this.state.salesByVendorName}
                      vendorData={this.state.salesByVendorTotal}
                      isBool={true}
                    ></EventOrganiserVendorsPieChart>
                  </Col>
                </Row>
              )}
              <div className="my-3">
                <h3>Items Sold:</h3>
                {this.state.newMealFrequency[0] && (
                  <TableData
                    tableData={this.state.newMealFrequency}
                  ></TableData>
                )}
              </div>
              <div
                style={{
                  height: window.innerHeight / 3,
                  overflow: "scroll",
                  borderWidth: 1,
                  borderColor: "black",
                  borderStyle: "solid",
                }}
              ></div>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(CateringAnalytics, {
    fallback: <ErrorFallbackComponent />,
  })
);
