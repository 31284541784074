import React, { useState } from "react";
import StarRating from "../../components/StarRating"; // Import your StarRating component from the correct path
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
function EventOrganiserFeedback() {
  const [ratings, setRatings] = useState(0);
  const [feedbackData, setFeedbackData] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
  });

  const handleRatingChange = (newRating) => {
    setRatings(newRating);
  };

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({
      ...feedbackData,
      [name]: value,
    });
  };

  const submitFeedback = () => {
    const hashcode = new URLSearchParams(window.location.search).get(
      "hashcode"
    );

    const payload = {
      body: {
        query_type: "post_truck_manager_event_feedback",
        hashcode: hashcode,
        ratings_data: ratings,
        feedbackData: feedbackData,
      },
    };

    const url = config.BASE_URI + apiPaths.organiserData;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payload }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          alert("Feedback submitted successfully.");
          window.location.reload();
        } else {
          alert("Please try again!");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Please Try again!");
      });
  };

  return (
    <div className="event-feedback-container">
      <h1>Add Ratings to the page</h1>
      <StarRating ratingNumber={ratings} setNewRating={handleRatingChange} />
      <h2>Add a comment section to the page</h2>
      <textarea
        name="comment"
        value={feedbackData.comment}
        onChange={handleFeedbackChange}
        rows="4"
        cols="50"
        placeholder="Add your comment here"
      ></textarea>
      <h2>Please answer the below questions</h2>
      <div>
        <p>
          How satisfied were you with your overall experience as a food truck
          vendor at our event?
        </p>
        <input
          type="text"
          name="question1"
          value={feedbackData.question1}
          onChange={handleFeedbackChange}
          placeholder="Your answer"
        />
      </div>
      <div>
        <p>
          How would you rate the organization and communication leading up to
          the event?
        </p>
        <input
          type="text"
          name="question2"
          value={feedbackData.question2}
          onChange={handleFeedbackChange}
          placeholder="Your answer"
        />
      </div>
      <div>
        <p>
          Did the event meet your expectations in terms of foot traffic and
          sales opportunities?
        </p>
        <input
          type="text"
          name="question3"
          value={feedbackData.question3}
          onChange={handleFeedbackChange}
          placeholder="Your answer"
        />
      </div>
      <div>
        <p>Was vendors bump in effective?</p>
        <input
          type="text"
          name="question4"
          value={feedbackData.question4}
          onChange={handleFeedbackChange}
          placeholder="Your answer"
        />
      </div>
      <button onClick={submitFeedback} className="submit-button">
        Submit Feedback
      </button>
    </div>
  );
}

export default EventOrganiserFeedback;
