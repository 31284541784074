import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SideBar from "../UI/SideBar";
import { store, getFromLocalStorage } from "../../store";
import { theme } from "../UI/theme";
import { getSelectedVendor } from "../../actions";
import { Redirect } from "react-router-dom";
import InstaFeed from "../UI/InstaFeed";
import { applyToken } from "../../services/api";
import { updateUserLocation, getAllOrders } from "../../actions";

class ExploreComponent extends Component {
  state = {
    selectedVendor: -1,
  };
  getTrucksByKitchen(vendorData, filters, selectedLocation) {
    var exploreVendorListing = [];
    vendorData.map((truck, i) => {
      if (truck.kitchaco.location == selectedLocation) {
        try {
          if (
            filters.Kitchen.some((item) => item.id === truck.kitchens[0].id)
          ) {
            var obj = {};
            if (
              exploreVendorListing.some(
                (item) => truck.kitchens[0].title in item
              )
            ) {
              exploreVendorListing.map((t, i) => {
                if (truck.kitchens[0].title in t) {
                  var arr = t[truck.kitchens[0].title];
                  arr.push(truck);
                  var new_obj = {};
                  new_obj[truck.kitchens[0].title] = arr;
                  exploreVendorListing.remove(i);

                  exploreVendorListing.push(obj);
                }
              });
            } else {
              var arr = [];
              arr.push(truck);
              obj[truck.kitchens[0].title] = arr;
              exploreVendorListing.push(obj);
            }
          }
        } catch (err) {}
      } else {
      }
    });

    return exploreVendorListing;
  }

  // sort_by_key = (array, key) => {
  // var tempArray = new Array()
  // for(var k1 in array){
  //     var a1 = array[k1]
  //     for (var k2 in a1){
  //         tempArray=a1[k2]

  //     }
  // }
  // return tempArray.sort(function (a, b) {
  //     var x = a[key]; var y = b[key];
  //     return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  // });
  // }

  componentDidMount() {
    applyToken(getFromLocalStorage("token"));
    this.props.dispatch(getAllOrders());
  }

  getSelectedVendor = (selectedVendor) => {
    this.setState({ selectedVendor: selectedVendor });
  };

  render() {
    const { selectedCategory } = this.props;
    const { appReducer } = store.getState();
    const { vendorData, filters, selectedLocation } = appReducer;

    var truckByKitchen = this.getTrucksByKitchen(
      vendorData,
      filters,
      selectedLocation
    );
    if (this.state.selectedVendor != -1) {
      return (
        <Redirect
          push
          to={{
            pathname: "/vendor:" + this.state.selectedVendor,
          }}
        />
      );
    }

    return (
      <div>
        <Container fluid>
          <Row>
            <Col
              xs={3}
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
              }}
            >
              <SideBar
                vendorData={vendorData}
                filters={filters}
                selectedCategory={selectedCategory}
                getSelectedVendor={this.getSelectedVendor.bind(this)}
              ></SideBar>
            </Col>
            <Col xs={9} style={{ backgroundColor: "#c4c4c473" }}>
              <Container>
                {truckByKitchen.map((truck, i) => (
                  <div
                    key={i}
                    style={{
                      paddingBottom: 40,
                      paddingTop: 15,
                      borderBottom: "1px solid #C7CACE",
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <h4 style={{ textAlign: "left" }}>
                          {Object.keys(truck)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      {truck[Object.keys(truck)].map((truck, i) => {
                        return (
                          <Col
                            xs={4}
                            key={i}
                            style={{ cursor: "pointer", marginTop: 10 }}
                          >
                            <Card
                              style={{ height: 180 }}
                              onClick={this.getSelectedVendor.bind(
                                this,
                                truck.id
                              )}
                            >
                              <div
                                style={{
                                  minHeight: 88,
                                  maxHeight: 88,
                                  overflow: "hidden",
                                }}
                              >
                                <Card.Img
                                  className="align-middle"
                                  src={
                                    truck.image
                                      ? truck.image
                                      : require("../../assets/img/empty_logo.png")
                                  }
                                />
                              </div>
                              <Card.Body variant="bottom">
                                <Card.Title>{truck.title}</Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ))}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ExploreComponent;
