import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import moment from "moment";
import * as Sentry from "@sentry/react";
import "react-phone-input-2/lib/style.css";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { AWS_URL } from "../../assets/Constants";
import PaymentForm from "./components/PaymentForm.js";

import {
  SQUARE_SCRIPT_JS,
  SQUARE_APP_ID,
  LOCATION_ID,
} from "../../assets/Constants";
import setRandomInterval from "set-random-interval";
import Toast from "react-bootstrap/Toast";
import { Facebook } from "react-spinners-css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LiveChat from "react-livechat";

var interval = "";

class SalesPage extends Component {
  state = {
    selectedCuisine: "Mexican",
    selectThisPackClicked: false,
    availablePacks: [
      {
        vendor_id: 867,
        vendor_name: "Truck 1",
        vendor_packages: [
          {
            package_name: "Small Combo",
            package_description:
              "1x Small Veggie/Chicken Burger\n1 Small Chips and Drink",
            package_price: 25.0,
            package_people: 50,
          },
          {
            package_name: "Large Combo",
            package_description:
              "1x Large Veggie/Chicken Burger\n1 Large Chips and Drink",
            package_price: 35.0,
            package_people: 50,
          },
        ],
      },
      {
        vendor_id: 867,
        vendor_name: "Truck 2",
        vendor_packages: [
          {
            package_name: "Small Combo",
            package_description: "1x Small Veggie/Chicken Wrap and a Drink",
            package_price: 20.0,
            package_people: 50,
          },
          {
            package_name: "Large Combo",
            package_description: "1x Large Veggie/Chicken Wrap and a Drink",
            package_price: 30.0,
            package_people: 50,
          },
        ],
      },
    ],
    selectedPackId: -1,
    selectedPack: null,
    fakeLoader: true,
    loader: false,
    isPaymentModalOpen: false,
    displayNext: "block",
    showOnly:
      getFromLocalStorage("showOnly") != null &&
      !isNaN(getFromLocalStorage("showOnly"))
        ? getFromLocalStorage("showOnly")
        : "2",
    showOnlyArr:
      getFromLocalStorage("showOnlyArr") != null
        ? getFromLocalStorage("showOnlyArr")
        : null,
    showNewTruckToast: false,
    total_trucks: 0,
    loadMessage:
      getFromLocalStorage("loadMessage") != null &&
      getFromLocalStorage("loadMessage") != "null"
        ? getFromLocalStorage("loadMessage")
        : "We are contacting food trucks for their quotes...",
    selectedTruckClicked: false,
    selectedTruck: null,
    vendor_id: -1,
    specificTruck: false,
  };

  componentWillMount() {
    const that = this;
    let sqPaymentScript = document.createElement("script");
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = SQUARE_SCRIPT_JS;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true,
      });
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  componentDidMount() {
    var cuisine = "";
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    cuisine = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );

    this.setState({ selectedCuisine: cuisine }, () => {
      this.getCateringPackages(cuisine);
    });
    setTimeout(() => {
      this.setState({ fakeLoader: false });
    }, 5000);

    // getToken()
    // onMessageListener((payload) => {

    //     alert()
    // })

    deleteFromLocalStorage("loadMessage", null);
  }

  componentWillUnmount() {
    try {
      interval.clear();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  }

  onLoad = async () => {
    if (!this.props.paymentForm) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = this.props.paymentForm;
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    let card;
    try {
      card = await this.initializeCard(payments);
    } catch (error) {
      Sentry.captureException(error);
      console.error("initialize card error!", error);
      return;
    }
  };

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    this.setState({
      card: card,
    });
    return card;
  }

  async tokenize(card) {
    const tokenResult = await card.tokenize();
    if (tokenResult.status === "OK") {
      console.log("tokenize result ", tokenResult);
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  getShowOnly = (cuisine) => {
    var arr = JSON.parse(getFromLocalStorage("showOnlyArr"));
    console.log("showOnly", arr);

    if (arr == null) {
      return 2;
    } else {
      for (var i = 0; i < arr.length; i++) {
        console.log("showOnly ", arr[i].cuisine);
        if (arr[i].cuisine.toLowerCase() == cuisine) {
          return arr[i].showOnly;
        }
      }
    }
  };

  updateShowOnly = (cuisine, showOnly) => {
    var arr = JSON.parse(getFromLocalStorage("showOnlyArr"));
    if (arr == null) {
      arr.push({ cuisine: cuisine, showOnly: showOnly });
      saveToLocalStorage("showOnlyArr", JSON.stringify(arr));
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].cuisine.toLowerCase() == cuisine) {
          arr.splice(i, 1);
          arr.push({ cuisine: cuisine, showOnly: showOnly });
          saveToLocalStorage("showOnlyArr", JSON.stringify(arr));
          break;
        }
      }
    }
  };

  getCateringPackages = (cuisine) => {
    this.setState({ loader: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "catering-packs",
          cuisine: cuisine,
          showOnly: Number(this.getShowOnly(cuisine)),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(raw);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("showOnly", response);
        if (response.success == true) {
          response.data.reverse();
          response.data.push({
            vendor_id: -1,
            vendor_name: "Not happy with these trucks",
          });
          this.setState(
            {
              availablePacks: response.data,
              total_trucks: response.total_trucks,
            },
            () => {
              this.setState({ loader: false });

              if (
                Number(this.getShowOnly(cuisine)) <
                Number(this.state.total_trucks)
              ) {
                console.log("showOnly timer started");
                interval = setRandomInterval(
                  () => {
                    var currentTime = moment(new Date()).format("hh:mm:ss A");
                    console.log("Hello World! ", currentTime);
                    this.setState({ showNewTruckToast: true }, () => {
                      // this.updateShowOnly(this.state.selectedCuisine,5)
                    });
                  },
                  90000,
                  120000
                );
              }
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ loader: false });
      });
  };

  sendRequestToVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "request_new_vendors",
          booking_request_id: getFromLocalStorage("bookingRequestId"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(raw);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        alert(
          "A request has been sent to more " +
            this.state.selectedCuisine +
            " trucks. You may receive the response within seconds. Alternatively we will emai you any further available trucks within 1 business day "
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  togglePaymentModal = () => {
    this.state.isPaymentModalOpen
      ? this.setState({ isPaymentModalOpen: false }, () => {
          if (getFromLocalStorage("bookingRequestId") == "") {
            window.location.href = "/book-a-truck";
          }
        })
      : this.setState({ isPaymentModalOpen: true });
  };

  toggleSelectedTruckClicked = (selectedTruckClicked) => {
    this.setState({ selectedTruckClicked: selectedTruckClicked });
  };
  toggleSelectedThisPackClicked = (selectedThisPackClicked) => {
    this.setState({ selectedThisPackClicked: selectedThisPackClicked });
  };

  render() {
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    console.log(dispatch);
    const {
      availablePacks,
      selectedCuisine,
      selectThisPackClicked,
      loader,
      fakeLoader,
      showNewTruckToast,
      selectedTruckClicked,
    } = this.state;
    console.log("Nyra selectedTruckClicked", selectedTruckClicked);
    return (
      <div>
        <ResponsiveNavBar
          showBack={!selectThisPackClicked}
          backUrl={"/book-a-truck#cuisine"}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
          selectedTruckClicked={selectedTruckClicked}
          selectThisPackClicked={selectThisPackClicked}
          toggleSelectedThisPackClicked={this.toggleSelectedThisPackClicked}
          toggleSelectedTruckClicked={this.toggleSelectedTruckClicked}
        ></ResponsiveNavBar>
        {loader == false && fakeLoader == false ? (
          <>
            <Container style={{ marginBottom: 80 }}>
              {selectedTruckClicked ? (
                this.renderSelectedTruck()
              ) : (
                <>
                  <Toast
                    style={{
                      position: "fixed",
                      top: 10,
                      right: 10,
                      zIndex: 100,
                      borderRadius: 10,
                    }}
                    onClose={() => this.setState({ showNewTruckToast: false })}
                    show={showNewTruckToast}
                    delay={3000}
                  >
                    <Toast.Header>
                      <strong className="mr-auto">
                        Truckin' Awesome! We have a {selectedCuisine} food truck
                        that has just provided a quote.{" "}
                      </strong>
                      <small>just now</small>
                    </Toast.Header>
                    <Toast.Body>
                      <Row>
                        <Col xs={2} md={2} lg={2}>
                          <img
                            src={require("../../assets/img/logo.png")}
                            style={{ width: 40 }}
                            className="rounded mr-2"
                            alt=""
                          />
                        </Col>
                        <Col xs={8} md={8} lg={8}>
                          <p>
                            {" "}
                            You can select this option or we can reach out to
                            more of our truckin' mates
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <Col
                          xs={6}
                          md={6}
                          lg={6}
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#ef3f6d",
                              width: "100%",
                            }}
                            className="button-enabled-pink-style"
                            onClick={() => {
                              var showOnlyTemp = this.getShowOnly(
                                selectedCuisine
                              );
                              showOnlyTemp = Number(showOnlyTemp) + 1;
                              this.updateShowOnly(
                                selectedCuisine,
                                showOnlyTemp
                              );
                              saveToLocalStorage(
                                "loadMessage",
                                "Fetching new truck quote"
                              );
                              window.location.reload();
                            }}
                          >
                            Show
                          </Button>
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          lg={6}
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              width: "100%",
                              borderColor: "transparent",
                              color: "black",
                            }}
                            className="button-enabled-pink-style"
                            onClick={() => {
                              this.setState({ showNewTruckToast: false });
                            }}
                          >
                            No Thanks
                          </Button>
                        </Col>
                      </Row>
                    </Toast.Body>
                  </Toast>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <h3 style={{ textAlign: "center" }}>
                        <b>Pick your truck</b>
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        Select the truck or swipe to find the truck for your
                        needs
                      </p>
                    </Col>
                  </Row>
                </>
              )}
              {this.renderPaymentModal()}
            </Container>
          </>
        ) : (
          this.renderLoader()
        )}
      </div>
    );
  }

  renderSelectedTruck() {
    const { selectedTruck, selectThisPackClicked } = this.state;
    console.log("Nyra ", selectedTruck);

    return <div>{this.renderFinalConfirmation()}</div>;
  }

  renderLoader() {
    const { loadMessage } = this.state;
    return (
      <div style={{ padding: 10, textAlign: "center" }}>
        <img
          src={require("../../assets/img/loading_new.gif")}
          alt="loader"
          className="loader"
        ></img>

        <p
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        >
          {loadMessage}
        </p>
      </div>
    );
  }

  renderFinalConfirmation() {
    console.log("selectedPack ", this.state.selectedPack);
    var sub_total =
      Number(this.state.selectedPack.package_price) *
      Number(getFromLocalStorage("bookingNumberOfGuests"));
    var min_fee =
      Number(this.state.selectedPack.package_people) *
      Number(this.state.selectedPack.package_price);
    var min_call_out_fee = 0;
    if (min_fee - sub_total > 0) {
      min_call_out_fee = min_fee - sub_total;
    }
    return (
      <div>
        <p>Please confirm your details below</p>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Name
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingName")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Email
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingEmail")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Phone
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingPhone")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Location
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingLocation")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Event Type
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingEventType")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Start Date & Time
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingStartDateTime")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            End Date & Time
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingEndDateTime")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Estimated Number of Guests
          </Col>
          <Col xs={6} md={6} lg={6}>
            {getFromLocalStorage("bookingNumberOfGuests")}
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} lg={6}>
            Cuisine
          </Col>
          <Col xs={6} md={6} lg={6}>
            {this.state.selectedCuisine}
          </Col>
        </Row>
        <p>
          <b>Pack Details</b>
        </p>
        <Row style={{ marginTop: 10 }}>
          <Col xs={12} md={12} lg={12}>
            <Card>
              <Card.Body>
                <Card.Subtitle style={{ whiteSpace: "pre-wrap" }}>
                  {this.state.selectedPack.package_description}
                </Card.Subtitle>
                <Card.Subtitle style={{ marginTop: 10 }}>
                  <h4>
                    {"$" + this.state.selectedPack.package_price}{" "}
                    <span style={{ fontSize: 12 }}>{"/ person"}</span>{" "}
                  </h4>{" "}
                </Card.Subtitle>
                <Card.Subtitle style={{ marginTop: 10 }}>
                  <p>
                    {Number(getFromLocalStorage("bookingNumberOfGuests")) +
                      " people"}
                  </p>{" "}
                </Card.Subtitle>
                <Card.Subtitle style={{ marginTop: 10 }}>
                  <p>
                    {"Sub Total $" +
                      Number(
                        Number(this.state.selectedPack.package_price) *
                          Number(getFromLocalStorage("bookingNumberOfGuests"))
                      ).toFixed(2)}
                  </p>{" "}
                </Card.Subtitle>
                {min_call_out_fee > 0 ? (
                  <Card.Subtitle style={{ marginTop: 10 }}>
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          This pack is for min{" "}
                          {this.state.selectedPack.package_people} people so a
                          min calliut fee will be applied to your order
                        </Tooltip>
                      }
                    >
                      <p>
                        {" Call Out Fee $" +
                          Number(min_fee - sub_total).toFixed(2)}
                      </p>
                    </OverlayTrigger>
                  </Card.Subtitle>
                ) : null}
                <Card.Subtitle style={{ marginTop: 10 }}>
                  <p>
                    {"Total $" +
                      Number(sub_total + min_call_out_fee).toFixed(2)}
                  </p>{" "}
                </Card.Subtitle>
              </Card.Body>
              <Button
                style={{
                  width: "50%",
                  margin: "auto",
                  marginBottom: 20,
                  backgroundColor: "#ef3f6d",
                }}
                className="button-enabled-pink-style"
                onClick={() => {
                  //this.confirmBooking(this.state.selectedPack.id)

                  this.togglePaymentModal();
                }}
              >
                Pay ${Number(sub_total + min_call_out_fee).toFixed(2)} & Request
                Booking
              </Button>
              <Card.Subtitle style={{ marginTop: 10, padding: 10 }}>
                <i>
                  <p>
                    *Once you request your booking we will send you a
                    confirmation email with all the details{" "}
                  </p>
                </i>
              </Card.Subtitle>
              <Button
                style={{
                  width: "50%",
                  margin: "auto",
                  marginBottom: 20,
                  backgroundColor: "#cad3d9",
                  color: "black",
                  borderColor: "#cad3d9",
                }}
                className="button-enabled-pink-style"
                onClick={() => {
                  this.setState({ selectThisPackClicked: false });
                }}
              >
                No, Select another pack
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPaymentModal = () => {
    const { isPaymentModalOpen } = this.state;

    const { dispatch, userId } = this.props;
    var total = 0;
    var sub_total = 0;
    var min_fee = 0;
    var min_call_out_fee = 0;
    if (this.state.selectedPack) {
      sub_total =
        Number(this.state.selectedPack.package_price) *
        Number(getFromLocalStorage("bookingNumberOfGuests"));
      min_fee =
        Number(this.state.selectedPack.package_price) *
        Number(this.state.selectedPack.package_people);

      if (min_fee - sub_total > 0) {
        min_call_out_fee = min_fee - sub_total;
      }
      if (this.state.selectedPack && this.state.selectedPack.package_price) {
        total = Number(Number(sub_total) + Number(min_call_out_fee)).toFixed(2);
      }
    }
    return (
      <Modal
        show={isPaymentModalOpen}
        onHide={this.togglePaymentModal.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Payment Details Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            style={{
              height: 500,
              marginTop: 20,
              overflowY: "scroll",
              border: "none",
            }}
          >
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Card.Title></Card.Title>
              <Row>
                <Col xs={12}>
                  <PaymentForm
                    paymentForm={window.Square.payments(
                      SQUARE_APP_ID,
                      LOCATION_ID
                    )}
                    user={userId}
                    total={total}
                    dispatch={dispatch}
                    packId={
                      this.state.selectedPack && this.state.selectedPack.id
                    }
                    selectedCuisine={this.state.selectedCuisine}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(SalesPage);
