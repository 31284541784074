import React, { useState, useEffect } from "react";
import CateringCard from "./CateringCard";
import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import { Row, Col, Container, Button } from "react-bootstrap";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../constant/Constant";
import Paper from "@mui/material/Paper";
import "./style.css";
import CateringCardMobile from "./CateringCardMobile";
const CateringSection = ({
  events,
  onDrop,
  removeVendor,
  vendors,
  droppedVendor,
  setDroppedVendor,
  showCateringDetails,
  setShowCateringDetails,
  layout,
}) => {
  return (
    <div>
      {events.map((item, index) => {
        if (index % 2 === 0) {
          if (index < events.length - 1) {
            return (
              <>
                <Row key={item.eventDetails?.id}>
                  <Col
                    xs={12}
                    md={6}
                    lg={6}
                    style={{ cursor: "pointer", marginTop: 30 }}
                  >
                    {layout === "DESKTOP" ? (
                      <CateringCard
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        key={item.eventDetails?.id}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={item}
                        keyIndex={index}
                        accept={item.accept}
                        onDrop={(item) => onDrop(index, item)}
                        removeVendor={removeVendor}
                      />
                    ) : (
                      <CateringCardMobile
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        key={item.eventDetails?.id}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={item}
                        keyIndex={index}
                        accept={item.accept}
                        onDrop={(item) => onDrop(index, item)}
                        removeVendor={removeVendor}
                      />
                    )}
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={6}
                    style={{ cursor: "pointer", marginTop: 30 }}
                  >
                    {layout === "DESKTOP" ? (
                      <CateringCard
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        key={item.eventDetails?.id}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={events[index + 1]}
                        keyIndex={index + 1}
                        accept={events[index + 1].accept}
                        onDrop={(item) => onDrop(index + 1, item)}
                        removeVendor={removeVendor}
                      />
                    ) : (
                      <CateringCardMobile
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        key={item.eventDetails?.id}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={events[index + 1]}
                        keyIndex={index + 1}
                        accept={events[index + 1].accept}
                        onDrop={(item) => onDrop(index + 1, item)}
                        removeVendor={removeVendor}
                      />
                    )}
                  </Col>
                </Row>
              </>
            );
          } else {
            return (
              <>
                <Row key={item.eventDetails?.id}>
                  <Col
                    xs={12}
                    md={6}
                    lg={6}
                    style={{ cursor: "pointer", marginTop: 30 }}
                  >
                    {layout === "DESKTOP" ? (
                      <CateringCard
                        key={item.eventDetails?.id}
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={item}
                        keyIndex={index}
                        accept={item.accept}
                        onDrop={(item) => onDrop(index, item)}
                        removeVendor={removeVendor}
                      />
                    ) : (
                      <CateringCardMobile
                        key={item.eventDetails?.id}
                        showCateringDetails={showCateringDetails}
                        setShowCateringDetails={setShowCateringDetails}
                        droppedVendor={droppedVendor}
                        setDroppedVendor={setDroppedVendor}
                        vendors={vendors}
                        className="card-style"
                        style={{ margin: "10px" }}
                        event={item}
                        keyIndex={index}
                        accept={item.accept}
                        onDrop={(item) => onDrop(index, item)}
                        removeVendor={removeVendor}
                      />
                    )}
                  </Col>
                </Row>
              </>
            );
          }
        }
      })}
    </div>
  );
};

export default CateringSection;
