import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import "date-fns";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";

import Card from "react-bootstrap/Card";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class OrganiserPayout extends Component {
  state = {
    searchVendor: "",
    filteredVendors: [],
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : 0,
    adminSalesInvoiceFortnightPeriod:
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "undefined" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != null
        ? getFromLocalStorage("adminSalesInvoiceFortnightPeriod")
        : 0,
    dateperiodId: 0,
    datePeriod: 0,
    externalOperators: [],
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    salesInvoiceFornightPeriod: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    salesReport: [],
    selectEvent: {},
    eventReport: [],
    eventList: [],
    tempEventReport: [],
    isLoading: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    grossKitchacoInStoreCashSales: 0,
    grossKitchacoInStoreCardSales: 0,
    kitchaco_commission_percentage: 0,
    grossKitchacoOnlineDeliverySales: 0,
    grossKitchacoOnlinePickupSales: 0,
    grossUberEatsSales: 0,
    grossDoordashSales: 0,
    grossDeliverooSales: 0,
    grossMenulogSales: 0,
    grossStockPurchased: 0,
    gstStockPurchased: 0,
    netStockPurchased: 0,
    adminAdjustmentStockPurchased: 0,
    deliveryChargeStockPurchased: 0,
    labelMiscCharges1: "",
    grossMiscCharges1: 0,
    netMiscCharges1: 0,
    gstMiscCharges1: 0,

    isGstMiscCharges1: false,
    labelMiscCharges2: "",
    grossMiscCharges2: 0,
    netMiscCharges2: 0,
    gstMiscCharges2: 0,

    isGstMiscCharges2: false,
    labelMiscCharges3: "",
    grossMiscCharges3: 0,
    netMiscCharges3: 0,
    gstMiscCharges3: 0,

    isGstMiscCharges3: false,
    labelMiscCharges4: "",
    grossMiscCharges4: 0,
    netMiscCharges4: 0,
    gstMiscCharges4: 0,

    isGstMiscCharges4: false,
    labelMiscCharges5: "",
    grossMiscCharges5: 0,
    isGstMiscCharges5: false,
    netMiscCharges5: 0,
    gstMiscCharges5: 0,

    in_store_cash_commission: 0,
    in_store_card_commission: 0,
    online_pickup_commission: 0,
    online_delivery_commission: 0,
    uber_eats_commission: 0,
    doordash_commission: 0,
    deliveroo_commission: 0,
    menulog_commission: 0,

    sendEmailAs: "wtt",
    approved_by: "",
    approved_on: "",

    grossKitchacoInStoreCashSalesArray: [],
    grossKitchacoInStoreCardSalesArray: [],
    grossKitchacoOnlineDeliverySalesArray: [],
    grossKitchacoOnlinePickupSalesArray: [],
    grossUberEatsSalesArray: [],
    grossDoordashSalesArray: [],
    grossDeliverooSalesArray: [],
    grossMenulogSalesArray: [],
    salesApprovedInvoiceDetails: [],
    errorMessage: "Please select a fortnight period above to show reports",
    lessCashSalesSelectedCheckbox: true,
    lessCardSalesSelectedCheckbox: false,
    cardSales: 0,
    cashSales: 0,
    voucherSales: 0,
    pendingVendors: [],
  };

  componentDidMount() {
    this.getEvents();
    this.getVendorsInEvent();
    const {
      isOrganiserLoggedIn,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      adminSalesInvoiceFortnightPeriod,
    } = this.state;
    if (isOrganiserLoggedIn != "true") {
      window.location.href = "/organiser";
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.FilteredVendors !== this.state.FilteredVendors) {
      console.log("FilteredVendors has changed:", this.state.FilteredVendors);
    }
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  onChangeExternalOperator(e) {
    this.setState({ adminExternalOperator: e.target.value }, () => {
      saveToLocalStorage(
        "adminExternalOperator",
        this.state.adminExternalOperator
      );
      this.getSalesReport();
    });
  }

  getTargetedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.organiserData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_participated_vendors",
          event_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          selectEvent: response.data,
        });
        // this.getAllVendors(vendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onChangeDatePeriod(e) {
    console.log(e);
    const selectedPeriodIndex = e.target.value - 1;
    console.log(selectedPeriodIndex - 1);
    this.setState({ adminSalesInvoiceFortnightPeriod: e.target.value }, () => {
      const {
        salesInvoiceFornightPeriod,
        adminSalesInvoiceFortnightPeriod,
        dateperiodId,
      } = this.state;
      console.log(salesInvoiceFornightPeriod);
      console.log(adminSalesInvoiceFortnightPeriod);
      try {
        const selectedPeriod = salesInvoiceFornightPeriod.find(
          (period) => period.id == adminSalesInvoiceFortnightPeriod
        );
        console.log(selectedPeriod);

        const startDate = selectedPeriod.start_date;
        const endDate = selectedPeriod.end_date;
        this.setStartDate(startDate);
        this.setEndDate(endDate);

        // Save selected period to local storage and get the sales report
        saveToLocalStorage("adminSalesInvoiceFortnightPeriod", e.target.value);
        this.getSalesReport(startDate, endDate);
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
          saveToLocalStorage(
            "adminSalesInvoiceFortnightPeriod",
            adminSalesInvoiceFortnightPeriod
          );
        });
      }
    });
  }

  getSalesReport(startt, endd) {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
    } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "get_sales_report_for_event",
      start_date: moment(startt).format("DD MMM YYYY"),
      end_date: moment(endd).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      event: JSON.parse(getFromLocalStorage("selectedEventId")),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          console.log(dataResponse.total_event_sales["Total Card Sales"]);
          const processedEventReport = Object.entries(
            dataResponse.vendor_sales_dict
          ).map(([vendorName, salesData]) => {
            // Create a new object to store the modified key-value pairs
            let modifiedSalesData = {
              wtt_commission: 0,
              labelMiscCharges1: "",
              labelMiscCharges2: "",
              labelMiscCharges3: "",
              labelMiscCharges4: "",
              labelMiscCharges5: "",
              gstMiscCharges1: 0,
              gstMiscCharges2: 0,
              gstMiscCharges3: 0,
              gstMiscCharges4: 0,
              gstMiscCharges5: 0,
              grossMiscCharges1: 0,
              grossMiscCharges2: 0,
              grossMiscCharges3: 0,
              grossMiscCharges4: 0,
              grossMiscCharges5: 0,
              net_misc_charges1: 0,
              net_misc_charges2: 0,
              net_misc_charges3: 0,
              net_misc_charges4: 0,
              net_misc_charges5: 0,
              is_gst1: false,
              is_gst2: false,
              is_gst3: false,
              is_gst4: false,
              is_gst5: false,
              cash_sales: 0,
              card_sales: 0,
              voucher_sales: 0,
              total_payout: 0,
              wtt_payout: 0,
              total_gst: 0,
              approved_by: "",
              timestamp: "",
            };

            modifiedSalesData["cash_sales"] = salesData["Cash Sales"];
            modifiedSalesData["card_sales"] = salesData["Card Sales"];
            modifiedSalesData["voucher_sales"] = salesData["Voucher Sales"];

            // Iterate over each key in the salesData object
            if (
              salesData.existing_payout &&
              salesData.existing_payout.length > 0
            ) {
              console.log(salesData.existing_payout.id);
              const payout = salesData.existing_payout[0];
              modifiedSalesData["labelMiscCharges1"] =
                payout["label_misc_charges1"];
              modifiedSalesData["labelMiscCharges2"] =
                payout["label_misc_charges2"];
              modifiedSalesData["labelMiscCharges3"] =
                payout["label_misc_charges3"];
              modifiedSalesData["labelMiscCharges4"] =
                payout["label_misc_charges4"];
              modifiedSalesData["labelMiscCharges5"] =
                payout["label_misc_charges5"];
              modifiedSalesData["grossMiscCharges1"] =
                payout["gross_misc_charges1"];
              modifiedSalesData["grossMiscCharges2"] =
                payout["gross_misc_charges2"];
              modifiedSalesData["grossMiscCharges3"] =
                payout["gross_misc_charges3"];
              modifiedSalesData["grossMiscCharges4"] =
                payout["gross_misc_charges4"];
              modifiedSalesData["grossMiscCharges5"] =
                payout["gross_misc_charges5"];
              modifiedSalesData["gstMiscCharges1"] =
                payout["gst_misc_charges1"];
              modifiedSalesData["gstMiscCharges2"] =
                payout["gst_misc_charges2"];
              modifiedSalesData["gstMiscCharges3"] =
                payout["gst_misc_charges3"];
              modifiedSalesData["gstMiscCharges4"] =
                payout["gst_misc_charges4"];
              modifiedSalesData["gstMiscCharges5"] =
                payout["gst_misc_charges5"];
              modifiedSalesData["net_misc_charges1"] =
                payout["net_misc_charges1"];
              modifiedSalesData["net_misc_charges2"] =
                payout["net_misc_charges2"];
              modifiedSalesData["net_misc_charges3"] =
                payout["net_misc_charges3"];
              modifiedSalesData["net_misc_charges4"] =
                payout["net_misc_charges4"];
              modifiedSalesData["net_misc_charges5"] =
                payout["net_misc_charges5"];
              modifiedSalesData["is_gst1"] = payout["gst_misc_charges1"] > 0;
              modifiedSalesData["is_gst2"] = payout["gst_misc_charges2"] > 0;
              modifiedSalesData["is_gst3"] = payout["gst_misc_charges3"] > 0;
              modifiedSalesData["is_gst4"] = payout["gst_misc_charges4"] > 0;
              modifiedSalesData["is_gst5"] = payout["gst_misc_charges5"] > 0;
              // ... similarly map other fields from payout to modifiedSalesData
              modifiedSalesData["cash_sales"] = payout["wtt_cash_sales"];
              modifiedSalesData["card_sales"] = payout["wtt_card_sales"];
              modifiedSalesData["voucher_sales"] = payout["wtt_voucher_sales"];
              modifiedSalesData["total_payout"] = payout["total_payout"];
              modifiedSalesData["wtt_payout"] = payout["total_wtt_payout"];
              modifiedSalesData["total_gst"] = payout["total_gst"];
              modifiedSalesData["wtt_commission"] =
                payout["total_wtt_commission"];
              modifiedSalesData["approved_by"] = payout["approved_by"];
              modifiedSalesData["timestamp"] = payout["timestamp"];
            } else {
              const vendorDetails = {
                id: salesData.id,
                vendorName: vendorName, // Assuming the vendor name is directly in salesData
                cardSales: salesData["Card Sales"],
                cashSales: salesData["Cash Sales"],
                voucherSales: salesData["Voucher Sales"],
              };

              const newPendingVendors = [
                ...this.state.pendingVendors,
                vendorDetails,
              ];
              console.log(newPendingVendors);

              // Update the state with this new array
              this.setState({ pendingVendors: newPendingVendors });
            }

            // Return the new object with the vendor name and modified sales data
            return {
              vendor_name: vendorName,
              vendor_id: salesData.id,
              // less_cash_sales_collected: 0,
              // less_card_collected: 0,
              ...modifiedSalesData,
            };
          });

          console.log("Processed Event Report:", processedEventReport);
          let cardSales = 0,
            cashSales = 0,
            voucherSales = 0;
          let existingPayoutDataFound = false;

          for (const report of processedEventReport) {
            if (
              report.wtt_cash_sales ||
              report.wtt_card_sales ||
              report.wtt_voucher_sales
            ) {
              cardSales = report.wtt_card_sales || 0;
              cashSales = report.wtt_cash_sales || 0;
              voucherSales = report.wtt_voucher_sales || 0;
              existingPayoutDataFound = true;
              break; // Break the loop once the data is found
            }
          }

          if (!existingPayoutDataFound) {
            // Fall back to total event sales if no existing payout data is found
            cardSales =
              dataResponse.total_event_sales["Total Card Sales"] ||
              processedEventReport.card_sales;
            cashSales =
              dataResponse.total_event_sales["Total Cash Sales"] ||
              processedEventReport.cash_sales;
            voucherSales =
              dataResponse.total_event_sales["Total Voucher Sales"] ||
              processedEventReport.voucher_sales;
          }

          this.setState({
            eventReport: processedEventReport,
            tempEventReport: processedEventReport,
            cardSales: cardSales,
            cashSales: cashSales,
            voucherSales: voucherSales,
          });
          // this.setState({
          //   eventReport: Object.entries(dataResponse.vendor_sales_dict).map(
          //     ([vendorName, salesData]) => {
          //       return { vendor_name: vendorName, ...salesData };
          //     }
          //   ),
          //   cardSales: dataResponse.total_event_sales["Total Card Sales"],
          //   cashSales: dataResponse.total_event_sales["Total Cash Sales"],
          //   voucherSales: dataResponse.total_event_sales["Total Voucher Sales"],
          // });

          this.setState(
            {
              salesReport: dataResponse.sales_data,
              grossStockPurchased: 0,
              netStockPurchased: 0,
              gstStockPurchased: 0,
              grossKitchacoOnlinePickupSales:
                dataResponse.gross_kitchaco_online_pickup_sales,
              adminAdjustmentStockPurchased: 0,
              deliveryChargeStockPurchased: 0,
              in_store_cash_commission:
                dataResponse.commission_rate_data.in_store_cash_commission,
              in_store_card_commission:
                dataResponse.commission_rate_data.in_store_card_commission,
              online_pickup_commission:
                dataResponse.commission_rate_data.online_pickup_commission,
              online_delivery_commission:
                dataResponse.commission_rate_data.online_delivery_commission,
              uber_eats_commission: 0,
              doordash_commission: 0,
              deliveroo_commission: 0,
              menulog_commission: 0,
            },
            () => {
              const { salesReport } = this.state;

              var grossUberEatsSales = 0;
              var grossDoordashSales = 0;
              var grossDeliverooSales = 0;
              var grossMenulogSales = 0;
              for (var i = 0; i < salesReport.length; i++) {
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;
                var deliveroo_adjustment = 0;
                if (!isNaN(salesReport[i].deliveroo_adjustments)) {
                  deliveroo_adjustment = salesReport[i].deliveroo_adjustments;
                }
                grossUberEatsSales = 0;
                grossDoordashSales = 0;
                grossDeliverooSales = 0;
                grossMenulogSales = 0;
                var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray;
                var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray;
                var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray;
                var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray;
                var tempGrossUberEatsSalesArray = grossUberEatsSalesArray;
                var tempGrossDoordashSalesArray = grossDoordashSalesArray;
                var tempGrossDeliverooSalesArray = grossDeliverooSalesArray;
                var tempGrossMenulogSalesArray = grossMenulogSalesArray;

                tempGrossKitchacoInStoreCashSalesArray[i] =
                  salesReport[i].in_store_cash_sales;
                tempGrossKitchacoInStoreCardSalesArray[i] =
                  salesReport[i].in_store_card_sales;
                tempGrossOnlinePickupSalesArray[i] =
                  salesReport[i].online_pickup_sales;
                tempGrossOnlineDeliverySalesArray[i] =
                  salesReport[i].online_delivery_sales;
                tempGrossUberEatsSalesArray[i] =
                  salesReport[i].uber_total_sales;
                tempGrossDoordashSalesArray[i] =
                  salesReport[i].doordash_total_sales;
                tempGrossDeliverooSalesArray[i] =
                  Number(salesReport[i].deliveroo_total_sales) +
                  Number(salesReport[i].deliveroo_adjustments);
                tempGrossMenulogSalesArray[i] =
                  salesReport[i].menulog_total_sales;
              }
              this.setState({
                grossUberEatsSales: grossUberEatsSales,
                grossDoordashSales: grossDoordashSales,
                grossDeliverooSales: grossDeliverooSales,
                grossMenulogSales: grossMenulogSales,
                grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                grossDoordashSalesArray: tempGrossDoordashSalesArray,
                grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                grossMenulogSalesArray: tempGrossMenulogSalesArray,
              });

              if (
                dataResponse.sales_data[0].status != "unavailable" &&
                dataResponse.sales_data[0].status != "generated"
              ) {
                this.setState(
                  {
                    grossKitchacoInStoreCashSales: Number(
                      dataResponse.sales_data[0].in_store_cash_sales
                    ).toFixed(2),
                    grossKitchacoInStoreCardSales: Number(
                      dataResponse.sales_data[0].in_store_card_sales
                    ).toFixed(2),
                    grossKitchacoOnlinePickupSales: Number(
                      dataResponse.sales_data[0].total_voucher_sale
                    ).toFixed(2),
                    grossKitchacoOnlineDeliverySales: 0,
                    // totalRevenueForLocation: Number(dataResponse.sales_data[0].total_revenue).toFixed(2),

                    grossKitchacoInStoreCashFee: Number(
                      dataResponse.sales_data[0].wtt_cash_commission
                    ).toFixed(2),
                    grossKitchacoInStoreCardFee: Number(
                      dataResponse.sales_data[0].wtt_card_commission
                    ).toFixed(2),
                    grossKitchacoOnlinePickupFee: Number(
                      dataResponse.sales_data[0].wtt_voucher_commission
                    ).toFixed(2),
                    approved_by: dataResponse.approved_by,
                    approved_on: dataResponse.approved_on,
                    labelMiscCharges1:
                      dataResponse.sales_data[0].label_misc_charges1,
                    grossMiscCharges1:
                      dataResponse.sales_data[0].gross_misc_charges1,
                    gstMiscCharges1:
                      dataResponse.sales_data[0].gst_misc_charges1,
                    netMiscCharges1:
                      dataResponse.sales_data[0].net_misc_charges1,

                    labelMiscCharges2:
                      dataResponse.sales_data[0].label_misc_charges2,
                    grossMiscCharges2:
                      dataResponse.sales_data[0].gross_misc_charges2,
                    gstMiscCharges2:
                      dataResponse.sales_data[0].gst_misc_charges2,
                    netMiscCharges2:
                      dataResponse.sales_data[0].net_misc_charges2,

                    labelMiscCharges3:
                      dataResponse.sales_data[0].label_misc_charges3,
                    grossMiscCharges3:
                      dataResponse.sales_data[0].gross_misc_charges3,
                    gstMiscCharges3:
                      dataResponse.sales_data[0].gst_misc_charges3,
                    netMiscCharges3:
                      dataResponse.sales_data[0].net_misc_charges3,

                    labelMiscCharges4:
                      dataResponse.sales_data[0].label_misc_charges4,
                    grossMiscCharges4:
                      dataResponse.sales_data[0].gross_misc_charges4,
                    gstMiscCharges4:
                      dataResponse.sales_data[0].gst_misc_charges4,
                    netMiscCharges4:
                      dataResponse.sales_data[0].net_misc_charges4,

                    labelMiscCharges5:
                      dataResponse.sales_data[0].label_misc_charges5,
                    grossMiscCharges5:
                      dataResponse.sales_data[0].gross_misc_charges5,
                    gstMiscCharges5:
                      dataResponse.sales_data[0].gst_misc_charges5,
                    netMiscCharges5:
                      dataResponse.sales_data[0].net_misc_charges5,
                    lessCardSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_card_sales,
                    lessCashSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_cash_sales,
                  },
                  () => {
                    const {
                      gstMiscCharges1,
                      gstMiscCharges2,
                      gstMiscCharges3,
                      gstMiscCharges4,
                      gstMiscCharges5,
                    } = this.state;
                    if (Number(gstMiscCharges1) > 0) {
                      this.setState({ isGstMiscCharges1: true });
                    }
                    if (Number(gstMiscCharges2) > 0) {
                      this.setState({ isGstMiscCharges2: true });
                    }
                    if (Number(gstMiscCharges3) > 0) {
                      this.setState({ isGstMiscCharges3: true });
                    }
                    if (Number(gstMiscCharges4) > 0) {
                      this.setState({ isGstMiscCharges4: true });
                    }
                    if (Number(gstMiscCharges5) > 0) {
                      this.setState({ isGstMiscCharges5: true });
                    }
                  }
                );
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;
                // for (var i = 0; i < dataResponse.sales_approved_invoice_details.length; i++) {
                //   var sales = dataResponse.sales_approved_invoice_details[i]

                //   var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray
                //   var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray
                //   var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray
                //   var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray
                //   var tempGrossUberEatsSalesArray = grossUberEatsSalesArray
                //   var tempGrossDoordashSalesArray = grossDoordashSalesArray
                //   var tempGrossDeliverooSalesArray = grossDeliverooSalesArray
                //   var tempGrossMenulogSalesArray = grossMenulogSalesArray

                //   tempGrossKitchacoInStoreCashSalesArray[i] = sales.kitchaco_in_store_cash_sales
                //   tempGrossKitchacoInStoreCardSalesArray[i] = sales.kitchaco_in_store_card_sales
                //   tempGrossOnlinePickupSalesArray[i] = sales.kitchaco_online_pickup_sales
                //   tempGrossOnlineDeliverySalesArray[i] = sales.kitchaco_online_delivery_sales
                //   tempGrossUberEatsSalesArray[i] = sales.uber_eats_sales
                //   tempGrossDoordashSalesArray[i] = sales.doordash_sales
                //   tempGrossDeliverooSalesArray[i] = sales.deliveroo_sales
                //   tempGrossMenulogSalesArray[i] = sales.menulog_sales

                // }
                this.setState({
                  grossUberEatsSales: grossUberEatsSales,
                  grossDoordashSales: grossDoordashSales,
                  grossDeliverooSales: grossDeliverooSales,
                  grossMenulogSales: grossMenulogSales,
                  grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                  grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                  grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                  grossDoordashSalesArray: tempGrossDoordashSalesArray,
                  grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                  grossMenulogSalesArray: tempGrossMenulogSalesArray,
                  salesApprovedInvoiceDetails:
                    dataResponse.sales_approved_invoice_details,
                });
              } else {
                this.setState({
                  grossKitchacoInStoreCashSales:
                    dataResponse.gross_kitchaco_in_store_cash_sales,
                  grossKitchacoInStoreCardSales:
                    dataResponse.gross_kitchaco_in_store_card_sales,
                  grossKitchacoOnlinePickupSales:
                    dataResponse.gross_kitchaco_online_pickup_sales,
                  grossKitchacoOnlineDeliverySales:
                    dataResponse.gross_kitchaco_online_delivery_sales,
                  labelMiscCharges1: "",
                  grossMiscCharges1: 0,
                  netMiscCharges1: 0,
                  gstMiscCharges1: 0,

                  isGstMiscCharges1: false,
                  labelMiscCharges2: "",
                  grossMiscCharges2: 0,
                  netMiscCharges2: 0,
                  gstMiscCharges2: 0,

                  isGstMiscCharges2: false,
                  labelMiscCharges3: "",
                  grossMiscCharges3: 0,
                  netMiscCharges3: 0,
                  gstMiscCharges3: 0,

                  isGstMiscCharges3: false,
                  labelMiscCharges4: "",
                  grossMiscCharges4: 0,
                  netMiscCharges4: 0,
                  gstMiscCharges4: 0,

                  isGstMiscCharges4: false,
                  labelMiscCharges5: "",
                  grossMiscCharges5: 0,
                  isGstMiscCharges5: false,
                  netMiscCharges5: 0,
                  gstMiscCharges5: 0,
                  lessCashSalesSelectedCheckbox: true,
                  lessCardSalesSelectedCheckbox: false,
                });
              }
            }
          );
          console.log();
        } else {
          this.setState({
            eventReport: [],
            tempEventReport: [],
            cardSales: 0,
            cashSales: 0,
            voucherSales: 0,
            salesReport: [],
            grossStockPurchased: 0,
            netStockPurchased: 0,
            gstStockPurchased: 0,
            grossKitchacoOnlinePickupSales: 0,
            adminAdjustmentStockPurchased: 0,
            deliveryChargeStockPurchased: 0,
            in_store_cash_commission: 0,
            in_store_card_commission: 0,
            online_pickup_commission: 0,
            online_delivery_commission: 0,
            uber_eats_commission: 0,
            doordash_commission: 0,
            deliveroo_commission: 0,
            menulog_commission: 0,
            // Reset any other state variables that may have been affected by the failed query
            errorMessage:
              "Report for this location and period hasn't been generated yet. Please select a different location or period or come back later for this report.",
          });
          // window.location.reload()
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
            this.getSalesInvoiceFornightPeriod();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.organiserData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_past_events",
          organiser_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
        },
      },
    });
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse.data);
        this.setState({ eventList: dataResponse.data });
      });
  };

  getExternalOperatorData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "external_operators",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ externalOperators: dataResponse.vendors }, () => {
            this.getSalesInvoiceFornightPeriod();
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  }

  getSalesInvoiceFornightPeriod() {
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_date_period",
          event_id: parseInt(getFromLocalStorage("selectedEventId")),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          const flattenedWeekPeriods = dataResponse.data.week_periods.flat();
          console.log(flattenedWeekPeriods);
          this.setState(
            {
              salesInvoiceFornightPeriod: flattenedWeekPeriods,
            },
            () => {
              const {
                salesInvoiceFornightPeriod,
                adminSalesInvoiceFortnightPeriod,
                startDate,
                endDate,
              } = this.state;
              console.log(startDate, endDate);
              try {
                var start_date = moment(startDate).format("DD MMM YYYY");
                var end_date = moment(endDate).format("DD MMM YYYY");
                if (
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].start_date != start_date ||
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].end_date != end_date
                ) {
                  this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                    saveToLocalStorage(
                      "adminSalesInvoiceFortnightPeriod",
                      adminSalesInvoiceFortnightPeriod
                    );
                  });
                  console.log(adminSalesInvoiceFortnightPeriod);
                }
              } catch (e) {
                Sentry.captureException(e);
                console.error(e);
                this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                  saveToLocalStorage(
                    "adminSalesInvoiceFortnightPeriod",
                    adminSalesInvoiceFortnightPeriod
                  );
                });
              }
              this.getSalesReport(startDate, endDate);
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  }

  render() {
    const {
      isOrganiserLoggedIn,

      vendorName,
    } = this.state;

    return (
      <>
        {isOrganiserLoggedIn === "true"
          ? // Render your component when either isSuperAdminLoggedIn or isAccountantLoggedIn is "true"
            this.renderMainComponent()
          : // Render the login component when neither isSuperAdminLoggedIn nor isAccountantLoggedIn is "true"
            window.location.replace("/organiser")}
      </>
    );
  }

  saveSalesInvoice = (
    grossTotalRevenue,
    gst,
    netUberEatsFee,
    netDoordashFee,
    netDeliverooFee,
    netMenulogFee,
    grossKitchacoInStoreCashFee,
    grossKitchacoInStoreCardFee,
    grossKitchacoOnlinePickupFee,
    netKitchacoInStoreCardFee,
    netKitchacoOnlinePickupFee,
    netKitchacoOnlineDeliveryFee,
    payoutResult,
    grossTotalSalesLessExpenditure
  ) => {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      superAdminEmail,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
    } = this.state;

    var kitchacoTotalSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    var kitchacoOnlineSales =
      Number(grossKitchacoOnlinePickupSales) +
      Number(grossKitchacoOnlineDeliverySales);
    var kitchacoInStoreSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales);
    let currentDate = moment().format("DD MMM YYYY");
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    alert(superAdminEmail);
    var raw = JSON.stringify({
      query_type: "update_and_approve_sales_report",
      vendor: parseInt(selectedVendorId),
      approved_by: "frank@amfvg.com.au",
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      kitchaco_total_sales: Number(kitchacoTotalSales).toFixed(2),
      kitchaco_online_sales: kitchacoOnlineSales,
      paid_on: currentDate,
      kitchaco_instore_sales: kitchacoInStoreSales,
      uber_total_sales: grossUberEatsSales,
      deliveroo_total_sales: grossDeliverooSales,
      doordash_total_sales: grossDoordashSales,
      menulog_total_sales: grossMenulogSales,
      total_revenue: Number(grossTotalRevenue).toFixed(2),
      total_gst: gst,
      stock_ex_gst: netStockPurchased,
      uber_commission_ex_gst: netUberEatsFee,
      doordash_commission_ex_gst: netDoordashFee,
      deliveroo_commission_ex_gst: netDeliverooFee,
      menulog_commission_ex_gst: netMenulogFee,
      in_store_cash_commission: grossKitchacoInStoreCashFee,
      in_store_card_commission_ex_gst: netKitchacoInStoreCardFee,
      online_pickup_commission_ex_gst: netKitchacoOnlinePickupFee,
      online_delivery_commission_ex_gst: netKitchacoOnlineDeliveryFee,
      kitchaco_in_store_cash_sales: grossKitchacoInStoreCashSales,
      kitchaco_in_store_card_sales: grossKitchacoInStoreCardSales,
      kitchaco_online_pickup_sales: grossKitchacoOnlinePickupSales,
      kitchaco_online_delivery_sales: grossKitchacoOnlineDeliverySales,
      label_misc_charges1: labelMiscCharges1,
      gross_misc_charges1: grossMiscCharges1,
      net_misc_charges1: netMiscCharges1,
      gst_misc_charges1: gstMiscCharges1,
      label_misc_charges2: labelMiscCharges2,
      gross_misc_charges2: grossMiscCharges2,
      net_misc_charges2: netMiscCharges2,
      gst_misc_charges2: gstMiscCharges2,
      label_misc_charges3: labelMiscCharges3,
      gross_misc_charges3: grossMiscCharges3,
      net_misc_charges3: netMiscCharges3,
      gst_misc_charges3: gstMiscCharges3,
      label_misc_charges4: labelMiscCharges4,
      gross_misc_charges4: grossMiscCharges4,
      net_misc_charges4: netMiscCharges4,
      gst_misc_charges4: gstMiscCharges4,
      label_misc_charges5: labelMiscCharges5,
      gross_misc_charges5: grossMiscCharges5,
      net_misc_charges5: netMiscCharges5,
      gst_misc_charges5: gstMiscCharges5,
      total_payout_inc_gst: payoutResult,
      uber_total_sales_array: grossUberEatsSalesArray,
      deliveroo_total_sales_array: grossDeliverooSalesArray,
      doordash_total_sales_array: grossDoordashSalesArray,
      menulog_total_sales_array: grossMenulogSalesArray,
      kitchaco_in_store_cash_sales_array: grossKitchacoInStoreCashSalesArray,
      kitchaco_in_store_card_sales_array: grossKitchacoInStoreCardSalesArray,
      kitchaco_online_pickup_sales_array: grossKitchacoOnlinePickupSalesArray,
      kitchaco_online_delivery_sales_array: grossKitchacoOnlineDeliverySalesArray,
      sales_report: salesReport,
      sales_approved_invoice_details: this.state.salesApprovedInvoiceDetails,
      total_earnings: grossTotalSalesLessExpenditure,
      less_in_store_cash_sales: lessCashSalesSelectedCheckbox,
      less_in_store_card_sales: lessCardSalesSelectedCheckbox,
      wtt_cash_commission: grossKitchacoInStoreCashFee,
      wtt_card_commission: grossKitchacoInStoreCardFee,
      wtt_voucher_commission: grossKitchacoOnlinePickupFee,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Approved Successfully");
          // window.location.reload()
        }
      });
  };

  organiserApproval = (vendor) => {
    console.log(vendor);
    const cardSales = parseFloat(vendor.card_sales) || 0;
    const voucherSales = parseFloat(vendor.voucher_sales) || 0;
    const wttCommission = parseFloat(vendor.wtt_commission) || 1; // Default to 1 to avoid division by zero

    // Calculate the total sales divided by 11
    const totalSales = cardSales / 11 + voucherSales / 11;

    // Sum of GST miscellaneous charges
    const gstCharges =
      parseFloat(vendor.gstMiscCharges1) +
      parseFloat(vendor.gstMiscCharges2) +
      parseFloat(vendor.gstMiscCharges3) +
      parseFloat(vendor.gstMiscCharges4) +
      parseFloat(vendor.gstMiscCharges5);

    // Adjust for commission, ensuring no division by zero
    const commissionAdjustment =
      wttCommission !== 0
        ? cardSales / wttCommission / 11 + voucherSales / wttCommission / 11
        : 0;

    // Calculate the final result (this will be the updated total GST)
    const totalGST = totalSales - gstCharges - commissionAdjustment;

    // Update the total_gst field in the vendor object
    vendor.time_stamp = moment;
    vendor.total_gst = totalGST.toFixed(2);
    vendor.total_payout = (
      vendor.card_sales +
      vendor.voucher_sales -
      ((vendor.voucher_sales +
        vendor.cash_sales +
        vendor.card_sales +
        Number(vendor.grossMiscCharges1) +
        Number(vendor.grossMiscCharges2) +
        Number(vendor.grossMiscCharges3) +
        Number(vendor.grossMiscCharges4) +
        Number(vendor.grossMiscCharges5)) /
        100) *
        vendor.wtt_commission
    ).toFixed(2);
    vendor.wtt_payout = (
      ((vendor.voucher_sales +
        vendor.cash_sales +
        vendor.card_sales +
        Number(vendor.grossMiscCharges1) +
        Number(vendor.grossMiscCharges2) +
        Number(vendor.grossMiscCharges3) +
        Number(vendor.grossMiscCharges4) +
        Number(vendor.grossMiscCharges5)) /
        100) *
      vendor.wtt_commission
    ).toFixed(2);
    vendor.wtt_cash_sales = (
      Number(vendor.cash_sales) *
      (Number(vendor.wtt_commission) / 100)
    ).toFixed(2);
    vendor.wtt_card_sales = (
      Number(vendor.card_sales) *
      (Number(vendor.wtt_commission) / 100)
    ).toFixed(2);
    vendor.wtt_voucher_sales = (
      Number(vendor.voucher_sales) *
      (Number(vendor.wtt_commission) / 100)
    ).toFixed(2);
    let currentDate = moment().format("DD MMM YYYY");
    console.log(currentDate);
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_sales_payout_for_event",
          approved_by: JSON.parse(getFromLocalStorage("Organiser"))[0].email,
          organiser_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
          weekly_sales_period_id: getFromLocalStorage(
            "adminSalesInvoiceFortnightPeriod"
          ),
          event_id: getFromLocalStorage("selectedEventId"),
          vendor_id: vendor.vendor_id ? vendor.vendor_id : vendor.id,
          reports_data: vendor,
          timestamp: moment(currentDate).format("DD MMM YYYY"),
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Approved Successfully");
        }
      });
  };

  sendEmail = (
    grossTotalRevenue,
    gst,
    netUberEatsFee,
    netDoordashFee,
    netDeliverooFee,
    netMenulogFee,
    grossKitchacoInStoreCashFee,
    grossKitchacoInStoreCardFee,
    grossKitchacoOnlinePickupFee,
    grossKitchacoOnlineDeliveryFee,
    payoutResult,
    grossTotalSalesLessExpenditure
  ) => {
    const {
      adminExternalOperator,
      startDate,
      endDate,
      adminSalesInvoiceFortnightPeriod,
      superAdminEmail,
      selectedVendorId,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
    } = this.state;
    var kitchacoTotalSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    var kitchacoOnlineSales =
      Number(grossKitchacoOnlinePickupSales) +
      Number(grossKitchacoOnlineDeliverySales);
    var kitchacoInStoreSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales);
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const weekNumber = moment(startDate).isoWeek();
    var raw = JSON.stringify({
      query_type: "email_sales_report",
      vendor: parseInt(selectedVendorId),
      approved_by: superAdminEmail,
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      vendor: parseInt(selectedVendorId),
      approved_by: "frank@amfvg.com.au",
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      kitchaco_total_sales: Number(kitchacoTotalSales).toFixed(2),
      kitchaco_online_sales: kitchacoOnlineSales,
      kitchaco_instore_sales: kitchacoInStoreSales,
      uber_total_sales: grossUberEatsSales,
      deliveroo_total_sales: grossDeliverooSales,
      doordash_total_sales: grossDoordashSales,
      menulog_total_sales: grossMenulogSales,
      total_revenue: Number(grossTotalRevenue).toFixed(2),
      total_gst: gst,
      stock_ex_gst: netStockPurchased,
      uber_commission_ex_gst: netUberEatsFee,
      doordash_commission_ex_gst: netDoordashFee,
      deliveroo_commission_ex_gst: netDeliverooFee,
      menulog_commission_ex_gst: netMenulogFee,
      in_store_cash_commission: Number(grossKitchacoInStoreCashFee).toFixed(2),
      in_store_card_commission_ex_gst: Number(
        grossKitchacoInStoreCardFee
      ).toFixed(2),
      online_pickup_commission_ex_gst: Number(
        grossKitchacoOnlinePickupFee
      ).toFixed(2),
      online_delivery_commission_ex_gst: Number(
        grossKitchacoOnlineDeliveryFee
      ).toFixed(2),
      kitchaco_in_store_cash_sales: Number(
        grossKitchacoInStoreCashSales
      ).toFixed(2),
      kitchaco_in_store_card_sales: grossKitchacoInStoreCardSales,
      kitchaco_online_pickup_sales: grossKitchacoOnlinePickupSales,
      kitchaco_online_delivery_sales: grossKitchacoOnlineDeliverySales,

      label_misc_charges1: labelMiscCharges1,
      gross_misc_charges1: grossMiscCharges1,
      net_misc_charges1: netMiscCharges1,
      gst_misc_charges1: gstMiscCharges1,
      label_misc_charges2: labelMiscCharges2,
      gross_misc_charges2: grossMiscCharges2,
      net_misc_charges2: netMiscCharges2,
      gst_misc_charges2: gstMiscCharges2,
      label_misc_charges3: labelMiscCharges3,
      gross_misc_charges3: grossMiscCharges3,
      net_misc_charges3: netMiscCharges3,
      gst_misc_charges3: gstMiscCharges3,
      label_misc_charges4: labelMiscCharges4,
      gross_misc_charges4: grossMiscCharges4,
      net_misc_charges4: netMiscCharges4,
      gst_misc_charges4: gstMiscCharges4,
      label_misc_charges5: labelMiscCharges5,
      gross_misc_charges5: grossMiscCharges5,
      net_misc_charges5: netMiscCharges5,
      gst_misc_charges5: gstMiscCharges5,

      total_payout_inc_gst: payoutResult,

      uber_total_sales_array: grossUberEatsSalesArray,
      deliveroo_total_sales_array: grossDeliverooSalesArray,
      doordash_total_sales_array: grossDoordashSalesArray,
      menulog_total_sales_array: grossMenulogSalesArray,
      kitchaco_in_store_cash_sales_array: grossKitchacoInStoreCashSalesArray,
      kitchaco_in_store_card_sales_array: grossKitchacoInStoreCardSalesArray,
      kitchaco_online_pickup_sales_array: grossKitchacoOnlinePickupSalesArray,
      kitchaco_online_delivery_sales_array: grossKitchacoOnlineDeliverySalesArray,
      sales_report: salesReport,
      sales_approved_invoice_details: this.state.salesApprovedInvoiceDetails,
      total_earnings: grossTotalSalesLessExpenditure,
      week_number: weekNumber,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to your registered email address.");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  handleKitchacoInStoreCashSalesArray = (e, index) => {
    const { grossKitchacoInStoreCashSalesArray } = this.state;
    let tempArray = grossKitchacoInStoreCashSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoInStoreCashSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoInStoreCashSalesArray } = this.state;
      for (var i = 0; i < grossKitchacoInStoreCashSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoInStoreCashSalesArray[i]);
      }
      this.setState({ grossKitchacoInStoreCashSales: temp });
    });
  };
  handleChangeCash(event, vendorIndex) {
    // event.target.value will contain the new cash sales value
    const newCashSales = parseFloat(event.target.value);

    // Create a new array based on the current salesReport
    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      // Check if the current vendor is the one being edited
      if (index === vendorIndex) {
        // Update the cash_sales for this vendor
        return { ...vendor, cash_sales: newCashSales };
      }
      return vendor;
    });
    console.log("updatedCash", tempEventReport);
    // Update the state with the new tempSalesReport array
    this.setState({ tempEventReport });
  }
  handleChangeCard(event, vendorIndex) {
    // event.target.value will contain the new cash sales value
    const newCardSales = parseFloat(event.target.value);

    // Create a new array based on the current salesReport
    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      // Check if the current vendor is the one being edited
      if (index === vendorIndex) {
        // Update the cash_sales for this vendor
        return { ...vendor, card_sales: newCardSales };
      }
      return vendor;
    });
    console.log("updatedCard", tempEventReport);
    // Update the state with the new tempSalesReport array
    this.setState({ tempEventReport });
  }
  handleChangeVoucher(event, vendorIndex) {
    // event.target.value will contain the new cash sales value
    const newVoucherSales = parseFloat(event.target.value);

    // Create a new array based on the current salesReport
    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      // Check if the current vendor is the one being edited
      if (index === vendorIndex) {
        // Update the cash_sales for this vendor
        return { ...vendor, voucher_sales: newVoucherSales };
      }
      return vendor;
    });
    console.log("updatedVoucher", tempEventReport);
    // Update the state with the new tempSalesReport array
    this.setState({ tempEventReport });
  }
  handleChangeCommission(event, vendorIndex) {
    // event.target.value will contain the new cash sales value
    const newVoucherSales = parseFloat(event.target.value);

    // Create a new array based on the current salesReport
    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      // Check if the current vendor is the one being edited
      if (index === vendorIndex) {
        // Update the cash_sales for this vendor
        return { ...vendor, wtt_commission: newVoucherSales };
      }
      return vendor;
    });
    console.log("updatedCommission", tempEventReport);
    // Update the state with the new tempSalesReport array
    this.setState({ tempEventReport });
  }

  handleChangeWTTChange(event, vendorIndex) {
    // event.target.value will contain the new cash sales value
    const newVoucherSales = parseFloat(event.target.value);

    // Create a new array based on the current salesReport
    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      // Check if the current vendor is the one being edited
      if (index === vendorIndex) {
        // Update the cash_sales for this vendor
        return { ...vendor, wtt_commission: newVoucherSales };
      }
      return vendor;
    });
    console.log("updatedCommission", tempEventReport);
    // Update the state with the new tempSalesReport array
    this.setState({ tempEventReport });
  }

  handleChangeGST(event, vendorIndex, labelField) {
    const fieldNumber =
      labelField && /\d$/.test(labelField)
        ? labelField[labelField.length - 1]
        : "1";

    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      if (index === vendorIndex) {
        const gstMiscChargesField = `gstMiscCharges${fieldNumber}`;
        const netMiscChargesField = `net_misc_charges${fieldNumber}`;
        const grossMiscChargesField = `grossMiscCharges${fieldNumber}`;
        const isGstString = `is_gst${fieldNumber}`;

        // Correctly access the gross misc charges
        const grossMiscCharges = Number(vendor[grossMiscChargesField]) || 0;
        const gstAmount = grossMiscCharges / 11;
        const netAmount = grossMiscCharges - gstAmount;

        if (vendor[isGstString]) {
          return {
            ...vendor,
            [isGstString]: false,
            [gstMiscChargesField]: 0,
            [netMiscChargesField]: Number(grossMiscCharges.toFixed(2)),
          };
        } else {
          return {
            ...vendor,
            [isGstString]: true,
            [gstMiscChargesField]: Number(gstAmount.toFixed(2)),
            [netMiscChargesField]: Number(netAmount.toFixed(2)),
          };
        }
      }
      return vendor;
    });

    console.log("updatedisGST", tempEventReport);
    this.setState({ tempEventReport });
  }

  handleChangeLabel(event, vendorIndex, labelField) {
    console.log(labelField);
    const newLabelValue = String(event.target.value);

    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      if (index === vendorIndex) {
        return { ...vendor, [labelField]: newLabelValue };
      }
      return vendor;
    });

    console.log("updatedLabel", tempEventReport);
    this.setState({ tempEventReport });
  }

  handleChangeGrossMisc(event, vendorIndex, labelField) {
    const newGrossValue = parseFloat(event.target.value);

    let tempEventReport = this.state.tempEventReport.map((vendor, index) => {
      if (index === vendorIndex) {
        // Determine the number part from the labelField (e.g., "grossMiscCharges1" => "1")
        const fieldNumber = labelField.match(/\d+$/)[0];
        const isGstField = `is_gst${fieldNumber}`;
        const gstMiscChargesField = `gstMiscCharges${fieldNumber}`;
        const netMiscChargesField = `net_misc_charges${fieldNumber}`;

        const updatedVendor = { ...vendor, [labelField]: newGrossValue };

        // Check if GST is applicable
        if (vendor[isGstField]) {
          const gstAmount = newGrossValue / 11;
          const netAmount = newGrossValue - gstAmount;
          updatedVendor[gstMiscChargesField] = parseFloat(gstAmount.toFixed(2));
          updatedVendor[netMiscChargesField] = parseFloat(netAmount.toFixed(2));
        } else {
          // If GST is not applicable, net amount is same as gross amount
          updatedVendor[netMiscChargesField] = parseFloat(
            newGrossValue.toFixed(2)
          );
          updatedVendor[gstMiscChargesField] = 0;
        }

        return updatedVendor;
      }
      return vendor;
    });

    console.log("updatedGrossMisc", tempEventReport);
    this.setState({ tempEventReport });
  }

  handleKitchacoInStoreCardSalesArray = (e, index) => {
    const { grossKitchacoInStoreCardSalesArray } = this.state;
    let tempArray = grossKitchacoInStoreCardSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoInStoreCardSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoInStoreCardSalesArray } = this.state;
      for (var i = 0; i < grossKitchacoInStoreCardSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoInStoreCardSalesArray[i]);
      }
      this.setState({ grossKitchacoInStoreCardSales: temp });
    });
  };

  handleKitchacoOnlinePickupSalesArray = (e, index) => {
    const { grossKitchacoOnlinePickupSalesArray } = this.state;
    let tempArray = grossKitchacoOnlinePickupSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoOnlinePickupSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoOnlinePickupSalesArray } = this.state;
      for (var i = 0; i < grossKitchacoOnlinePickupSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlinePickupSalesArray[i]);
      }
      this.setState({ grossKitchacoOnlinePickupSales: temp });
    });
  };

  handleKitchacoOnlineDeliverySalesArray = (e, index) => {
    const { grossKitchacoOnlineDeliverySalesArray } = this.state;
    let tempArray = grossKitchacoOnlineDeliverySalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoOnlineDeliverySalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoOnlineDeliverySalesArray } = this.state;
      for (var i = 0; i < grossKitchacoOnlineDeliverySalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlineDeliverySalesArray[i]);
      }
      this.setState({ grossKitchacoOnlineDeliverySales: temp });
    });
  };

  handleUberEatsSalesArray = (e, index) => {
    const { grossUberEatsSalesArray } = this.state;
    let tempArray = grossUberEatsSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossUberEatsSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossUberEatsSalesArray } = this.state;
      for (var i = 0; i < grossUberEatsSalesArray.length; i++) {
        temp = Number(temp) + Number(grossUberEatsSalesArray[i]);
      }
      this.setState({ grossUberEatsSales: temp });
    });
  };
  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };

  renderMainComponent() {
    const {
      datePeriod,
      selectEvent,
      vendorName,
      isOrganiserLoggedIn,
      vendors,
      selectedVendorId,
      salesReport,
      externalOperators,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod,
      isLoading,
      isSuperAdminLoggedIn,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      grossStockPurchased,
      gstStockPurchased,
      netStockPurchased,
      adminAdjustmentStockPurchased,
      deliveryChargeStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,
      kitchaco_commission_percentage,
      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      in_store_cash_commission,
      in_store_card_commission,
      online_pickup_commission,
      online_delivery_commission,
      uber_eats_commission,
      doordash_commission,
      deliveroo_commission,
      menulog_commission,
      approved_by,
      approved_on,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      errorMessage,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
      selectedVendorSearch,
      filteredVendors,
      cardSales,
      cashSales,
      voucherSales,
      eventReport,
      tempEventReport,
      handleChangeCash,
      handleChangeCard,
      handleChangeVoucher,
      handleChangeCommission,
      handleChangeWTTChange,
      handleChangeGST,
      setStartDate,
      setEndDate,
    } = this.state;
    console.log(tempEventReport);
    console.log(cardSales, cashSales, voucherSales, eventReport);
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };

    var grossKitchacoInStoreCashFee = 0;
    var grossKitchacoInStoreCardFee = 0;
    var grossKitchacoOnlineDeliveryFee = 0;
    var grossKitchacoOnlinePickupFee = 0;
    var grossUberEatsFee = 0;
    var grossDoordashFee = 0;
    var grossDeliverooFee = 0;
    var grossMenulogFee = 0;

    var gstKitchacoInStoreCashSales = 0;
    var gstKitchacoInStoreCardSales = 0;
    var gstKitchacoOnlineDeliverySales = 0;
    var gstKitchacoOnlinePickupSales = 0;
    var gstUberEatsSales = 0;
    var gstDoordashSales = 0;
    var gstDeliverooSales = 0;
    var gstMenulogSales = 0;

    var gstKitchacoInStoreCashFee = 0;
    var gstKitchacoInStoreCardFee = 0;
    var gstKitchacoOnlineDeliveryFee = 0;
    var gstKitchacoOnlinePickupFee = 0;
    var gstUberEatsFee = 0;
    var gstDoordashFee = 0;
    var gstDeliverooFee = 0;
    var gstMenulogFee = 0;

    var netKitchacoInStoreCashSales = 0;
    var netKitchacoInStoreCardSales = 0;
    var netKitchacoOnlineDeliverySales = 0;
    var netKitchacoOnlinePickupSales = 0;
    var netUberEatsSales = 0;
    var netDoordashSales = 0;
    var netDeliverooSales = 0;
    var netMenulogSales = 0;
    var netKitchacoInStoreCashFee = 0;
    var netKitchacoInStoreCardFee = 0;
    var netKitchacoOnlineDeliveryFee = 0;
    var netKitchacoOnlinePickupFee = 0;
    var netUberEatsFee = 0;
    var netDoordashFee = 0;
    var netDeliverooFee = 0;
    var netMenulogFee = 0;

    var grossTotalRevenue = 0;
    var netTotalRevenue = 0;
    var gstTotalRevenue = 0;

    var grossTotalRevenueWithoutCashSales = 0;
    var netTotalRevenueWithoutCashSales = 0;
    var gstTotalRevenueWithoutCashSales = 0;

    var grossTotalPurchases = 0;
    var netTotalPurchases = 0;
    var gstTotalPurchases = 0;

    var grossTotalSalesLessExpenditure = 0;
    var gstTotalSalesLessExpenditure = 0;
    var netTotalSalesLessExpenditure = 0;

    var payoutResult = 0;
    var gst = 0;
    var netPayment = 0;
    var operatorIsPaid = 0;
    var gstToBePaidToATO = 0;
    var operatorReceivesFromFrank = 0;
    var operatorPaysATO = 0;

    if (lessCardSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCardSales = 0;
      netKitchacoInStoreCardSales = 0;
    }

    if (lessCashSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCashSales = 0;
      netKitchacoInStoreCashSales = 0;
    }

    gstKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 1.1
    ).toFixed(2);

    gstKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 1.1
    ).toFixed(2);

    gstUberEatsSales = Number(Number(grossUberEatsSales) / 11).toFixed(2);
    netUberEatsSales = Number(Number(grossUberEatsSales) / 1.1).toFixed(2);

    gstDoordashSales = Number(Number(grossDoordashSales) / 11).toFixed(2);
    netDoordashSales = Number(Number(grossDoordashSales) / 1.1).toFixed(2);

    gstDeliverooSales = Number(Number(grossDeliverooSales) / 11).toFixed(2);
    netDeliverooSales = Number(Number(grossDeliverooSales) / 1.1).toFixed(2);

    gstMenulogSales = Number(Number(grossMenulogSales) / 11).toFixed(2);
    netMenulogSales = Number(Number(grossMenulogSales) / 1.1).toFixed(2);

    grossTotalRevenue =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    netTotalRevenue =
      Number(netKitchacoInStoreCashSales) +
      Number(netKitchacoInStoreCardSales) +
      Number(netKitchacoOnlineDeliverySales) +
      Number(netKitchacoOnlinePickupSales);
    gstTotalRevenue =
      Number(gstKitchacoInStoreCashSales) +
      Number(gstKitchacoInStoreCardSales) +
      Number(gstKitchacoOnlinePickupSales);

    grossTotalRevenueWithoutCashSales = grossTotalRevenue;
    netTotalRevenueWithoutCashSales = netTotalRevenue;
    gstTotalRevenueWithoutCashSales = gstTotalRevenue;

    if (lessCashSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales - grossKitchacoInStoreCashSales;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCashSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCashSales;
    }
    if (lessCardSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales -
        grossKitchacoInStoreCardSales -
        grossKitchacoOnlinePickupFee;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCardSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCardSales;
    }

    grossKitchacoInStoreCashFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCashSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCashFee = Number(
      Number(kitchaco_commission_percentage) / 11
    ).toFixed(2);
    netKitchacoInStoreCashFee = Number(
      Number(grossKitchacoInStoreCashFee) / 1.1
    ).toFixed(2);

    grossKitchacoInStoreCardFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCardSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 11
    ).toFixed(2);
    netKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlineDeliveryFee = Number(
      Number(online_delivery_commission) *
        Number(grossKitchacoOnlineDeliverySales)
    ).toFixed(2);
    gstKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlinePickupFee = Number(
      Number(online_pickup_commission) * Number(grossKitchacoOnlinePickupSales)
    ).toFixed(2);
    gstKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 1.1
    ).toFixed(2);

    grossUberEatsFee = Number(
      Number(uber_eats_commission) * Number(grossUberEatsSales)
    ).toFixed(2);
    gstUberEatsFee = Number(Number(grossUberEatsFee) / 11).toFixed(2);
    netUberEatsFee = Number(Number(grossUberEatsFee) / 1.1).toFixed(2);

    grossDoordashFee = Number(
      Number(doordash_commission) * Number(grossDoordashSales)
    ).toFixed(2);
    gstDoordashFee = Number(Number(grossDoordashFee) / 11).toFixed(2);
    netDoordashFee = Number(Number(grossDoordashFee) / 1.1).toFixed(2);

    grossDeliverooFee = Number(
      Number(deliveroo_commission) * Number(grossDeliverooSales)
    ).toFixed(2);
    gstDeliverooFee = Number(Number(grossDeliverooFee) / 11).toFixed(2);
    netDeliverooFee = Number(Number(grossDeliverooFee) / 1.1).toFixed(2);

    grossMenulogFee = Number(
      Number(menulog_commission) * Number(grossMenulogSales)
    ).toFixed(2);
    gstMenulogFee = Number(Number(grossMenulogFee) / 11).toFixed(2);
    netMenulogFee = Number(Number(grossMenulogFee) / 1.1).toFixed(2);

    grossTotalPurchases = Number(
      Number(grossKitchacoInStoreCashFee) +
        Number(grossKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(grossMiscCharges1) +
        Number(grossMiscCharges2) +
        Number(grossMiscCharges3) +
        Number(grossMiscCharges4) +
        Number(grossMiscCharges5)
    ).toFixed(2);

    netTotalPurchases = Number(
      Number(netKitchacoInStoreCashFee) +
        Number(netKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(netMiscCharges1) +
        Number(netMiscCharges2) +
        Number(netMiscCharges3) +
        Number(netMiscCharges4) +
        Number(netMiscCharges5)
    ).toFixed(2);

    gstTotalPurchases = Number(
      Number(gstKitchacoInStoreCashFee) +
        Number(gstKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(gstMiscCharges1) +
        Number(gstMiscCharges2) +
        Number(gstMiscCharges3) +
        Number(gstMiscCharges4) +
        Number(gstMiscCharges5)
    ).toFixed(2);

    grossTotalSalesLessExpenditure = Number(
      Number(grossTotalRevenue) - Number(grossTotalPurchases)
    ).toFixed(2);
    netTotalSalesLessExpenditure = Number(
      Number(netTotalRevenue) - Number(netTotalPurchases)
    ).toFixed(2);
    gstTotalSalesLessExpenditure = Number(
      Number(gstTotalRevenue) - Number(gstTotalPurchases)
    ).toFixed(2);

    payoutResult = Number(
      Number(grossTotalRevenueWithoutCashSales) - Number(grossTotalPurchases)
    ).toFixed(2);
    netPayment = Number(Number(payoutResult) - Number(gst)).toFixed(2);
    gst = Number(Number(gstTotalRevenue) - Number(gstTotalPurchases)).toFixed(
      2
    );

    return (
      <>
        <AdminNavBar
          style={{ margin: "auto" }}
          isSuperAdminLoggedIn={false}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></AdminNavBar>{" "}
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={6} lg={6}>
                {vendors && vendors.length > 0 ? (
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Events</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedEventId}
                      onChange={(e) => {
                        this.setState({ selectedEventId: e.target.value });
                        saveToLocalStorage(
                          "selectedEventId",
                          "" + e.target.value
                        );
                        console.log(e.target.value);
                        this.getSalesInvoiceFornightPeriod();
                      }}
                    >
                      <option value={0}>Select Event</option>
                      {this.state.eventList?.map((event, key) => (
                        <option key={key} value={event.id}>
                          {event.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : null}
              </Col>

              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={8} md={8} lg={8}>
                    {salesInvoiceFornightPeriod &&
                    salesInvoiceFornightPeriod.length > 0 ? (
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>Date Period</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          onChange={this.onChangeDatePeriod.bind(this)}
                          defaultValue={adminSalesInvoiceFortnightPeriod}
                        >
                          <option value={0}>Select Date Period</option>
                          {salesInvoiceFornightPeriod.map(
                            (salesInvoicePeriod, i) => {
                              return (
                                <option
                                  key={i}
                                  value={salesInvoiceFornightPeriod[i].id}
                                >
                                  {salesInvoiceFornightPeriod[i].start_date +
                                    " - " +
                                    salesInvoiceFornightPeriod[i].end_date}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {datePeriod !== 1 ? (
              // JSX code when datePeriod is not equal to 1
              <div>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={12} md={6} lg={6}>
                    <div
                      style={{
                        borderWidth: 1,
                        borderColor: "#bdffbe",
                        borderStyle: "solid",
                        borderRadius: 5,
                        padding: 15,
                        background: "#bdffbe",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <h2>Your Wallet</h2>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Sales:</td>
                            <td>
                              {(cashSales + cardSales + voucherSales).toFixed(
                                2
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Cash Sales:</td>
                            <td>{cashSales}</td>
                          </tr>
                          <tr>
                            <td>Total Card Sales:</td>
                            <td>{cardSales}</td>
                          </tr>
                          <tr>
                            <td>Total Voucher Value:</td>
                            <td>{voucherSales}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div
                      style={{
                        borderWidth: 0,
                        borderColor: "#fdff9e",
                        borderStyle: "solid",
                        borderRadius: 5,
                        padding: 15,
                        backgroundColor: "#fdff9e",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <h2>Pending</h2>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Sales:</td>
                            <td>
                              {this.state.pendingVendors
                                .slice(0, -1)
                                .reduce(
                                  (acc, vendor) =>
                                    acc +
                                    (vendor.cardSales || 0) +
                                    (vendor.cashSales || 0) +
                                    (vendor.voucherSales || 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Cash Sales:</td>
                            <td>
                              {this.state.pendingVendors
                                .slice(0, -1)
                                .reduce(
                                  (acc, vendor) =>
                                    acc + (vendor.cashSales || 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Card Sales:</td>
                            <td>
                              {this.state.pendingVendors
                                .slice(0, -1)
                                .reduce(
                                  (acc, vendor) =>
                                    acc + (vendor.cardSales || 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Voucher Value:</td>
                            <td>
                              {this.state.pendingVendors
                                .slice(0, -1)
                                .reduce(
                                  (acc, vendor) =>
                                    acc + (vendor.voucherSales || 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>{errorMessage}</p>
                </Col>
              </Row>
            )}

            {adminSalesInvoiceFortnightPeriod != 1 ? (
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>
                    You can generate the report using the form below and email
                    it to any email id.
                  </p>
                  {this.state.pendingVendors
                    ?.slice(0, -1)
                    .map((vendor, index) => {
                      console.log("vendor", vendor);
                      return (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                          >
                            <Typography>{vendor.vendorName}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Card Sales: {vendor.cardSales}
                              <br />
                              Cash Sales: {vendor.cashSales}
                              <br />
                              Voucher Sales: {vendor.voucherSales}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}

                  <h5>
                    <b>Revenue By Vendors</b>
                  </h5>
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: "grey",
                      borderStyle: "solid",
                      borderRadius: 5,
                      padding: 15,
                    }}
                  >
                    <div>
                      {tempEventReport &&
                        tempEventReport.map((vendor, index) =>
                          index !== tempEventReport.length - 1 ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}a-content`}
                                id={`panel${index}a-header`}
                              >
                                <Typography>{vendor.vendor_name}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ padding: 10 }}>
                                  <Row style={{ marginBottom: 10 }}>
                                    <Col xs={3} md={3} lg={3}></Col>
                                    <Col xs={3} md={3} lg={3}>
                                      <b>Gross ($)</b>
                                    </Col>
                                    <Col xs={3} md={3} lg={3}>
                                      <b>Net($)</b>
                                    </Col>
                                    <Col xs={3} md={3} lg={3}>
                                      <b>GST($)</b>
                                    </Col>
                                  </Row>
                                </div>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Cash Sales</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        value={vendor.cash_sales}
                                        onChange={(e) =>
                                          this.handleChangeCash(e, index)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.cash_sales
                                      ? (
                                          Number(vendor.cash_sales) / 1.1
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.cash_sales
                                      ? (
                                          Number(vendor.cash_sales) / 11
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Card Sales</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        value={vendor.card_sales}
                                        onChange={(e) =>
                                          this.handleChangeCard(e, index)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.card_sales
                                      ? (
                                          Number(vendor.card_sales) / 1.1
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.card_sales
                                      ? (
                                          Number(vendor.card_sales) / 11
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Voucher Sales</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        value={vendor.voucher_sales}
                                        onChange={(e) =>
                                          this.handleChangeVoucher(e, index)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.voucher_sales
                                      ? (
                                          Number(vendor.voucher_sales) / 1.1
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.voucher_sales
                                      ? (
                                          Number(vendor.voucher_sales) / 11
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Wtt Commission %</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        value={vendor.wtt_commission}
                                        onChange={(e) =>
                                          this.handleChangeCommission(e, index)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <h4>Less</h4>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Total Wtt In Store Cash Fee</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.cash_sales && vendor.wtt_commission
                                      ? (
                                          Number(vendor.cash_sales) *
                                          (Number(vendor.wtt_commission) / 100)
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.wtt_commission && vendor.cash_sales
                                      ? (
                                          (Number(vendor.cash_sales) *
                                            (Number(vendor.wtt_commission) /
                                              100)) /
                                          1.1
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.wtt_commission && vendor.cash_sales
                                      ? (
                                          (Number(vendor.cash_sales) *
                                            (Number(vendor.wtt_commission) /
                                              100)) /
                                          11
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Total Wtt In Store Card Fee</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.card_sales && vendor.wtt_commission
                                      ? (
                                          Number(vendor.card_sales) *
                                          (Number(vendor.wtt_commission) / 100)
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>

                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.wtt_commission && vendor.card_sales
                                      ? (
                                          (Number(vendor.card_sales) *
                                            (Number(vendor.wtt_commission) /
                                              100)) /
                                          1.1
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.wtt_commission && vendor.card_sales
                                      ? (
                                          (Number(vendor.card_sales) *
                                            (Number(vendor.wtt_commission) /
                                              100)) /
                                          11
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <p>Total Wtt In Store Voucher Fee</p>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.voucher_sales &&
                                    vendor.wtt_commission
                                      ? (
                                          Number(vendor.voucher_sales) *
                                          (Number(vendor.wtt_commission) / 100)
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.voucher_sales &&
                                    vendor.wtt_commission
                                      ? (
                                          Number(vendor.voucher_sales) /
                                          Number(vendor.wtt_commission).toFixed(
                                            2
                                          ) /
                                          1.1
                                        ).toFixed(2)
                                      : 0}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.wtt_commission &&
                                    vendor.voucher_sales
                                      ? (
                                          (Number(vendor.voucher_sales) *
                                            (Number(vendor.wtt_commission) /
                                              100)) /
                                          11
                                        ).toFixed(2)
                                      : "0.00"}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <h5>Total Revenue</h5>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {(
                                      vendor.voucher_sales +
                                      vendor.cash_sales +
                                      vendor.card_sales
                                    ).toFixed(2)}
                                  </Col>
                                </Row>
                                <h4>Add Misc Charges Below</h4>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Row>
                                        <Col
                                          xs={4}
                                          md={4}
                                          lg={4}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="GST?"
                                            checked={vendor.is_gst1}
                                            onChange={(e) =>
                                              this.handleChangeGST(
                                                e,
                                                index,
                                                "is_gst1"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={6}
                                          lg={6}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="eg:Utility"
                                            value={vendor.labelMiscCharges1}
                                            onChange={(e) =>
                                              this.handleChangeLabel(
                                                e,
                                                index,
                                                "labelMiscCharges1"
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="20"
                                        value={vendor.grossMiscCharges1}
                                        onChange={(e) =>
                                          this.handleChangeGrossMisc(
                                            e,
                                            index,
                                            "grossMiscCharges1"
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.net_misc_charges1}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.gstMiscCharges1}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Row>
                                        <Col
                                          xs={4}
                                          md={4}
                                          lg={4}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="GST?"
                                            checked={vendor.is_gst2}
                                            onChange={(e) =>
                                              this.handleChangeGST(
                                                e,
                                                index,
                                                "is_gst2"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={6}
                                          lg={6}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="eg:Utility"
                                            value={vendor.labelMiscCharges2}
                                            onChange={(e) =>
                                              this.handleChangeLabel(
                                                e,
                                                index,
                                                "labelMiscCharges2"
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="20"
                                        value={vendor.grossMiscCharges2}
                                        onChange={(e) =>
                                          this.handleChangeGrossMisc(
                                            e,
                                            index,
                                            "grossMiscCharges2"
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.net_misc_charges2}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.gstMiscCharges2}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Row>
                                        <Col
                                          xs={4}
                                          md={4}
                                          lg={4}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="GST?"
                                            checked={vendor.is_gst3}
                                            onChange={(e) =>
                                              this.handleChangeGST(
                                                e,
                                                index,
                                                "is_gst3"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={6}
                                          lg={6}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="eg:Utility"
                                            value={vendor.labelMiscCharges3}
                                            onChange={(e) =>
                                              this.handleChangeLabel(
                                                e,
                                                index,
                                                "labelMiscCharges3"
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="20"
                                        value={vendor.grossMiscCharges3}
                                        onChange={(e) =>
                                          this.handleChangeGrossMisc(
                                            e,
                                            index,
                                            "grossMiscCharges3"
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.net_misc_charges3}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.gstMiscCharges3}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Row>
                                        <Col
                                          xs={4}
                                          md={4}
                                          lg={4}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="GST?"
                                            checked={vendor.is_gst4}
                                            onChange={(e) =>
                                              this.handleChangeGST(
                                                e,
                                                index,
                                                "is_gst4"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={6}
                                          lg={6}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="eg:Utility"
                                            value={vendor.labelMiscCharges4}
                                            onChange={(e) =>
                                              this.handleChangeLabel(
                                                e,
                                                index,
                                                "labelMiscCharges4"
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="20"
                                        value={vendor.grossMiscCharges4}
                                        onChange={(e) =>
                                          this.handleChangeGrossMisc(
                                            e,
                                            index,
                                            "grossMiscCharges4"
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.net_misc_charges4}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.gstMiscCharges4}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Row>
                                        <Col
                                          xs={4}
                                          md={4}
                                          lg={4}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="GST?"
                                            checked={vendor.is_gst5}
                                            onChange={(e) =>
                                              this.handleChangeGST(
                                                e,
                                                index,
                                                "is_gst5"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={6}
                                          lg={6}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="eg:Utility"
                                            value={vendor.labelMiscCharges5}
                                            onChange={(e) =>
                                              this.handleChangeLabel(
                                                e,
                                                index,
                                                "labelMiscCharges5"
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="20"
                                        value={vendor.grossMiscCharges5}
                                        onChange={(e) =>
                                          this.handleChangeGrossMisc(
                                            e,
                                            index,
                                            "grossMiscCharges5"
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.net_misc_charges5}
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {vendor.gstMiscCharges5}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <h5>Total WTT Earnings</h5>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {(
                                      ((vendor.voucher_sales +
                                        vendor.cash_sales +
                                        vendor.card_sales +
                                        Number(vendor.grossMiscCharges1) +
                                        Number(vendor.grossMiscCharges2) +
                                        Number(vendor.grossMiscCharges3) +
                                        Number(vendor.grossMiscCharges4) +
                                        Number(vendor.grossMiscCharges5)) /
                                        100) *
                                      vendor.wtt_commission
                                    ).toFixed(2)}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <h5>Total payout</h5>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {(
                                      vendor.card_sales +
                                      vendor.voucher_sales -
                                      ((vendor.voucher_sales +
                                        vendor.cash_sales +
                                        vendor.card_sales +
                                        Number(vendor.grossMiscCharges1) +
                                        Number(vendor.grossMiscCharges2) +
                                        Number(vendor.grossMiscCharges3) +
                                        Number(vendor.grossMiscCharges4) +
                                        Number(vendor.grossMiscCharges5)) /
                                        100) *
                                        vendor.wtt_commission
                                    ).toFixed(2)}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={3} md={3} lg={3}>
                                    <h5>GST</h5>
                                  </Col>
                                  <Col xs={3} md={3} lg={3}>
                                    {(() => {
                                      // Convert to number using parseFloat and handle non-numeric values
                                      const cardSales =
                                        parseFloat(vendor.card_sales) || 0;
                                      const voucherSales =
                                        parseFloat(vendor.voucher_sales) || 0;
                                      const wttCommission =
                                        parseFloat(vendor.wtt_commission) || 1; // Default to 1 to avoid division by zero

                                      // Calculate the total sales divided by 11
                                      const totalSales =
                                        cardSales / 11 + voucherSales / 11;

                                      // Sum of GST miscellaneous charges
                                      const gstCharges =
                                        parseFloat(vendor.gstMiscCharges1) +
                                        parseFloat(vendor.gstMiscCharges2) +
                                        parseFloat(vendor.gstMiscCharges3) +
                                        parseFloat(vendor.gstMiscCharges4) +
                                        parseFloat(vendor.gstMiscCharges5);

                                      // Adjust for commission, ensuring no division by zero
                                      const commissionAdjustment =
                                        wttCommission !== 0
                                          ? cardSales / wttCommission / 11 +
                                            voucherSales / wttCommission / 11
                                          : 0;

                                      // Calculate the final result
                                      const result =
                                        totalSales -
                                        gstCharges -
                                        commissionAdjustment;

                                      // Return the result formatted to 2 decimal places
                                      return result.toFixed(2);
                                    })()}
                                  </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{ marginTop: 20 }}>
                                  <Col xs={4} md={4} lg={4}>
                                    <h4>Report Configuartion</h4>
                                    <p>
                                      {vendor.approved_by != ""
                                        ? "Already Approved on " +
                                          vendor.timestamp +
                                          " by " +
                                          vendor.approved_by
                                        : ""}
                                    </p>
                                    <Button
                                      onClick={() => {
                                        this.organiserApproval(vendor);
                                      }}
                                    >
                                      {vendor.approved_by !== ""
                                        ? "Update"
                                        : "Approve"}
                                    </Button>
                                  </Col>
                                </Row>
                              </AccordionDetails>
                            </Accordion>
                          ) : null
                        )}

                      <Row>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            <b>Total Sales</b>
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            {tempEventReport &&
                              tempEventReport.map((vendor, index) =>
                                index === tempEventReport.length - 1 ? (
                                  <div key={index}>
                                    {(
                                      vendor.total_card_sales +
                                      vendor.total_cash_sales +
                                      vendor.total_voucher_sales
                                    ).toFixed(2)}
                                  </div>
                                ) : null
                              )}
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}></Col>
                        <Col xs={3} md={3} lg={3}></Col>
                      </Row>
                      <Row>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            <b>Total Card Sales</b>
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            {tempEventReport &&
                              tempEventReport.map((vendor, index) =>
                                index === tempEventReport.length - 1 ? (
                                  <div key={index}>
                                    {vendor.total_card_sales
                                      ? vendor.total_card_sales.toFixed(2)
                                      : "0.00"}
                                  </div>
                                ) : null
                              )}
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}></Col>
                        <Col xs={3} md={3} lg={3}></Col>
                      </Row>
                      <Row>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            <b>Total Cash Sales</b>
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            {tempEventReport &&
                              tempEventReport.map((vendor, index) =>
                                index === tempEventReport.length - 1 ? (
                                  <div key={index}>
                                    {vendor.total_cash_sales
                                      ? vendor.total_cash_sales.toFixed(2)
                                      : "0.00"}
                                  </div>
                                ) : null
                              )}
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}></Col>
                        <Col xs={3} md={3} lg={3}></Col>
                      </Row>
                      <Row>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            <b>Total Voucher Sales</b>
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}>
                          <h5>
                            {tempEventReport &&
                              tempEventReport.map((vendor, index) =>
                                index === tempEventReport.length - 1 ? (
                                  <div key={index}>
                                    {vendor.total_voucher_sales
                                      ? vendor.total_voucher_sales.toFixed(2)
                                      : "0.00"}
                                  </div>
                                ) : null
                              )}
                          </h5>
                        </Col>
                        <Col xs={3} md={3} lg={3}></Col>
                        <Col xs={3} md={3} lg={3}></Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>{errorMessage} </p>
                </Col>
              </Row>
            )}
          </Container>
        )}
        <Footer />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OrganiserPayout, {
    fallback: <ErrorFallbackComponent />,
  })
);
