import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'


import { theme } from '../theme';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CustomButton from '../Button/index';

import {
    changeTab,
    loginPhone,
    sendSms,
    loginModalToggle,
    profileModalToggle,
    logout,
    updateUser,
    getUserProfile,
    changeSelectedLocation,
    getAllLocations,
    redirectFromWebsite,
    getSelectedVendor
} from '../../../actions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpInput from 'react-otp-input';
import './style.css'
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../../store'
import { GOOGLE_API_KEY } from '../../../assets/Constants';
import { applyToken } from '../../../services/api';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);


class ResponsiveVendorNavBar extends Component {
    state = {
        isOpenLoginModal: false,
        isOpenProfileModal: false,
        usernameState: '',
        emailState: ''
    }

    render() {
        const{showBack,backUrl} = this.props
        return (
            <>
                <Navbar expand="lg" style={{backgroundColor:'#2859a5',color:'white'}}>
                    {showBack == false ?
                        <Navbar.Brand href="/"><Image style={{ height: 40 }} src={require("../../../assets/img/welcome-logo.png")} fluid /></Navbar.Brand>
                        :
                        <Navbar.Brand href={backUrl}><Image style={{ height: 40 }} src={require("../../../assets/img/back-btn.png")} fluid /></Navbar.Brand>
                    }

                </Navbar>

            </>
        )
    }

}

export default ResponsiveVendorNavBar;









