import React from "react";
import FileUploader from "../FileUploader";
import { Form, Accordion, Card } from "react-bootstrap";
import EditableTable from "../EditableTable";
import "../../style.css";

const ElectricalAppliance = (props) => {
  const { formik, viewOnly } = props;
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        5. ELECTRICAL APPLIANCE LIST
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          {!viewOnly ? (
            <div className="title-heading">
            <Form.Text>
              Please list all electrical appliances you wish to use throughout
              the event.
            </Form.Text>

            <Form.Text className="mt-3">
              All electrical equipment must have a current Electrical Tag (Test
              and Tag) attached.
            </Form.Text>

            <Form.Text className="mt-3">
              Any equipment not listed on this form will not be used on the day.
            </Form.Text>

            <Form.Text className="mt-3">
              An electrician will inspect your stall and check your equipment
              against what is included on this form.
            </Form.Text>

            <Form.Text className="mt-3">
              Please fill in the following information for each piece of
              equipment you will be using.
            </Form.Text>

            <Form.Text className="mt-3">
              Every piece of equipment MUST be listed and the following
              information is mandatory:
            </Form.Text>

            <Form.Text className="mt-3 ml-3 font-weight-bold">
              Description: Please tell us what it is, eg. Urn, cash register,
              cold room, stove, hot plate, etc.
            </Form.Text>

            <Form.Text className="mt-3 ml-3 font-weight-bold">
              Watts/Amps: On every piece of equipment a compliance plate will be
              visible, on this plate information about the wattage or amperage
              will be found eg, 50Hz 700w see here. 2400 watts is 10 amp and
              3600 watts is 15 amp
            </Form.Text>

            <Form.Text className="mt-3 ml-3 font-weight-bold">
              Test and Tag: All equipment must also have a current electrical
              tag. The information must be up date and include the following
              Test Date, and Test Due Date.
            </Form.Text>

            <Form.Text className="mt-3">
              All this must be included as the form below
            </Form.Text>

            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col" rowSpan="2" style={{ verticalAlign: "top" }}>
                    Description
                  </th>
                  <th scope="col" rowSpan="2" style={{ verticalAlign: "top" }}>
                    Watt/Amps
                  </th>
                  <th scope="col" rowSpan="1" colSpan="2">
                    Test and Tag Date
                  </th>
                </tr>

                <tr>
                  <th scope="col" rowSpan="1">
                    Test date
                  </th>
                  <th scope="col" rowSpan="1">
                    Due date
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Kettle</td>
                  <td>2400w</td>
                  <td>1/2/12</td>
                  <td>1/5/12</td>
                </tr>
              </tbody>
            </table>
          </div>
          ) : (
            <>
            </>
          )}

          {!viewOnly ? (
            <EditableTable formik={formik} />
          ) : (
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col" rowSpan="2" style={{ verticalAlign: "top" }}>
                    Description
                  </th>
                  <th scope="col" rowSpan="2" style={{ verticalAlign: "top" }}>
                    Watt/Amps
                  </th>
                  <th scope="col" rowSpan="1" colSpan="2">
                    Test and Tag Date
                  </th>
                </tr>

                <tr>
                  <th scope="col" rowSpan="1">
                    Test date
                  </th>
                  <th scope="col" rowSpan="1">
                    Due date
                  </th>
                </tr>
              </thead>
              <tbody>
                {formik.values.electricalAppliances.map(item => (
                  <tr key={item.idx}>
                    <td>{item.description}</td>
                    <td>{item.watts}</td>
                    <td>{item.testDate}</td>
                    <td>{item.dueDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <br></br>

          <Form.Text
            style={{ color: "blue", fontSize: "1.2rem" }}
            className="font-weight-bold"
          >
            Remember, only tested and tagged appliances and equipment listed on
            this form will be permitted on the day. No other items will be
            allowed This will be cross referenced to the power you request &
            detailed in your confirmation letter
          </Form.Text>

          <br></br>

          {!viewOnly ? (
            <div className="title-heading">
              <h6>Upload your Electrical Certificate</h6>
              {formik.touched.electricalCert && formik.errors.electricalCert ? (
                <div style={{ color: "red" }}>
                  {formik.errors.electricalCert}
                </div>
              ) : null}
              <FileUploader
                allowMultiple={false}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"electricalCert"}
                allowFileTypeValidation={false}
                defaultValue={formik.values.electricalCert}
              />
              <h6>Upload your Gas Certificate</h6>
              {formik.touched.gasCert && formik.errors.gasCert ? (
                <div style={{ color: "red" }}>{formik.errors.gasCert}</div>
              ) : null}
              <FileUploader
                allowMultiple={false}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"gasCert"}
                allowFileTypeValidation={false}
                defaultValue={formik.values.gasCert}
              />
            </div>
          ) : (
            <div className="title-heading">
            <h6>Electrical Certificate: </h6>
            {formik.values.electricalCert.map(({item}) => (
              <img src={item && item != null && item != "" ? item : require("../../../../assets/img/default_cover.jpg")} alt="Electrical Certificate" height="200px" width="200px"
              onError={(e)=>{
                  const reader = new FileReader();
                  reader.onload = function(){
                      e.target.src = reader.result;
                  }
                  reader.readAsDataURL(item);
              }}
            />
            ))}
            <h6>Gas Certificate: </h6>
            {/* {(Array.isArray(formik.values.gasCert))? (
              formik.values.gasCert.map(({item}) => (
              <img src={item.name && item.name != null && item.name != "" ? item.name : require("../../../../assets/img/default_cover.jpg")} alt="Gas Certificate" height="200px" width="200px"
              onError={(e)=>{
                  const reader = new FileReader();
                  reader.onload = function(){
                      e.target.src = reader.result;
                  }
                  reader.readAsDataURL(item.name);
               }}
            />
            ))
            ):(
              <img
              className="d-block w-100"
              src={formik.values.gasCert && formik.values.gasCert != null && formik.values.gasCert != "" ? formik.values.gasCert : require("../../../../assets/img/default_cover.jpg")}
              alt="Gas Certificate"
              height="200px"
              width="200px"
            />
            )} */}
            <img
              className="d-block w-100"
              src={formik.values.gasCert && formik.values.gasCert != null && formik.values.gasCert != "" ? formik.values.gasCert : require("../../../../assets/img/default_cover.jpg")}
              alt="Gas Certificate"
              height="200px"
              width="200px"
            />
          </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ElectricalAppliance;
