import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { getFromLocalStorage } from "../../store";
import { AWS_URL } from "../../assets/Constants";


class AdminPendingEventApplicationScreen extends Component {
  state = {
    isSuperAdminLoggedIn: this.getLoggedInState("isSuperAdminLoggedIn"),
    isAccountantLoggedIn: this.getLoggedInState("isAccountantLoggedIn"),
    isStockAdminLoggedIn: this.getLoggedInState("isStockAdminLoggedIn"),
    isEventCoordinatorLoggedIn: this.getLoggedInState("isEventCoordinatorLoggedIn"),
    subscriptionList: [],
    tempSubscriptionList: [],
    availableStatus: ["processing", "rejected", "accepted"],
    selectedStatus: "",
    isOpenStatusChangeModal: false,
    selectedSubscription: null,
    isLoadingChangeStatus: false,
    searchCompany: "",
    isLoading: false,
  };

  getLoggedInState(key) {
    return getFromLocalStorage(key) !== "undefined" &&
      getFromLocalStorage(key) !== "" &&
      getFromLocalStorage(key) !== null
      ? getFromLocalStorage(key)
      : "false";
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getDashboardData();
    } else {
      window.location.href = "/admin";
    }
  }

  getDashboardData = () => {
    this.setState({ isLoading: true });
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_subscription_forms",
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          this.setState({
            subscriptionList: dataResponse.subscription_list,
            tempSubscriptionList: dataResponse.subscription_list,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeSubscriptionStatus = (e, subscription) => {
    this.setState({
      selectedSubscription: subscription,
      selectedStatus: e.target.value,
      isOpenStatusChangeModal: true,
    });
  };

  onChangeStatusOnServer = (subscriptionId, status) => {
    this.setState({ isLoadingChangeStatus: true });
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_subscription_status",
          subscription_id: subscriptionId,
          status,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeStatus: false, isOpenStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Status updated successfully!");
          this.getDashboardData();
        } else {
          alert("Error updating status. Please try again.");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingChangeStatus: false, isOpenStatusChangeModal: false });
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        />
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      subscriptionList,
      tempSubscriptionList,
      availableStatus,
      isLoadingChangeStatus,
      isLoading,
      searchCompany,
      selectedSubscription,
      selectedStatus,
      isOpenStatusChangeModal,
    } = this.state;

    return (
      <div>
        {!isLoading ? (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <InputGroup className="mb-3">
              <InputGroup.Text value={searchCompany}>
                <img
                  style={{ width: 20 }}
                  src={require("../../assets/img/search.gif")}
                  alt="search"
                />
              </InputGroup.Text>
              <FormControl
                placeholder="Search Company Name"
                aria-label="Search for company name"
                onChange={(e) => {
                  const searchString = e.target.value;
                  if (searchString === "") {
                    this.setState({ tempSubscriptionList: subscriptionList });
                  } else {
                    const result = subscriptionList.filter((obj) =>
                      obj.company_name.toLowerCase().includes(searchString.toLowerCase())
                    );
                    this.setState({ tempSubscriptionList: result });
                  }
                }}
              />
            </InputGroup>

            <Row className="table-header-row">
              <Col xs={12} md={2} className="table-header-col">
                Company Name
              </Col>
              <Col xs={12} md={2} className="table-header-col">
                Contact Name
              </Col>
              <Col xs={12} md={2} className="table-header-col">
                Email
              </Col>
              <Col xs={12} md={2} className="table-header-col">
                Mobile Number
              </Col>
              <Col xs={12} md={2} className="table-header-col">
                Status
              </Col>
              <Col xs={12} md={2} className="table-header-col">
                Action
              </Col>
            </Row>

            {tempSubscriptionList.map((item) => (
              <Accordion key={item.id}>
                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                  <Row className="table-row">
                    <Col xs={12} md={2} className="table-col">
                      {item.company_name}
                    </Col>
                    <Col xs={12} md={2} className="table-col">
                      {item.contact_name}
                    </Col>
                    <Col xs={12} md={2} className="table-col">
                      {item.email}
                    </Col>
                    <Col xs={12} md={2} className="table-col">
                      {item.mobile_number}
                    </Col>
                    <Col xs={12} md={2} className="table-col">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        onChange={(e) => this.onChangeSubscriptionStatus(e, item)}
                        defaultValue={item.application_status}
                      >
                        {availableStatus.map((status, i) => (
                          <option key={i} value={status}>
                            {status}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col xs={12} md={2} className="table-col">
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({
                            selectedSubscription: item,
                            selectedStatus: item.application_status,
                            isOpenStatusChangeModal: true,
                          })
                        }
                      >
                        View Details
                      </Button>
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id} style={{ padding: 20, backgroundColor: "#eff2f5" }}>
                  <div>
                    <Row>
                      <Col xs={6}>ABN Number</Col>
                      <Col xs={6}>{item.abn_number}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Trading Name</Col>
                      <Col xs={6}>{item.trading_name}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Phone</Col>
                      <Col xs={6}>{item.phone}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Current State</Col>
                      <Col xs={6}>{item.current_state}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Company Address</Col>
                      <Col xs={6}>{item.company_address}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Suburb</Col>
                      <Col xs={6}>{item.suburb}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>State</Col>
                      <Col xs={6}>{item.state}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Postcode</Col>
                      <Col xs={6}>{item.postcode}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Outlet Type</Col>
                      <Col xs={6}>{item.outlet_type}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Outlet Dimensions</Col>
                      <Col xs={6}>{item.outlet_dimensions}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Number of 10 Amps</Col>
                      <Col xs={6}>{item.number_of_10_amps}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Number of 15 Amps</Col>
                      <Col xs={6}>{item.number_of_15_amps}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Number of 20 Amps</Col>
                      <Col xs={6}>{item.number_of_20_amps}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Number of 32 Amps</Col>
                      <Col xs={6}>{item.number_of_32_amps}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Generator Preferred</Col>
                      <Col xs={6}>{item.generator_preffered ? "Yes" : "No"}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Bank Account Name</Col>
                      <Col xs={6}>{item.bank_account_name}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Bank Account Number</Col>
                      <Col xs={6}>{item.bank_account_number}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>BSB Number</Col>
                      <Col xs={6}>{item.bsb_number}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Type of Business</Col>
                      <Col xs={6}>{item.type_of_buisness}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Services Interested In</Col>
                      <Col xs={6}>{item.services_intrested_in}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Website URL</Col>
                      <Col xs={6}>{item.website_url}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Instagram Username</Col>
                      <Col xs={6}>{item.instagram_username}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Facebook Username</Col>
                      <Col xs={6}>{item.facebook_username}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Public Liability State of Operations</Col>
                      <Col xs={6}>{item.public_liablity_state_of_operations}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Public Liability Expiry Date</Col>
                      <Col xs={6}>{item.public_liablity_expiry_date}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Workers Compensation Expiry Date</Col>
                      <Col xs={6}>{item.workers_componsation_expiry_date}</Col>
                    </Row>
                    <Row>
                      <Col xs={6}>Vehicle Gas Compliance</Col>
                      <Col xs={6}>{item.vehicle_gas_compliance ? "Yes" : "No"}</Col>
                    </Row>
                    <Row>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.public_liablity_document
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Public Liablity
                                </a>
                              </Col>
                              {item.outlet_photo != null ? (
                                <Col xs={2} md={2} lg={2}>
                                  <img
                                    style={{ width: "100%" }}
                                    src={AWS_URL + "/" + item.outlet_photo}
                                    alt="Outlet Photo"
                                  />
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.workers_componsation_document
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Workers Componsation 
                                </a>
                              </Col>
                              {item.outlet_photo != null ? (
                                <Col xs={2} md={2} lg={2}>
                                  <img
                                    style={{ width: "100%" }}
                                    src={AWS_URL + "/" + item.logo}
                                    alt="Logo"
                                  />
                                </Col>
                              ) : null}
                            </Row>
                    <Row>
                      <Col xs={6}>Application Date</Col>
                      <Col xs={6}>{item.application_date}</Col>
                    </Row>

                  </div>
                </Accordion.Collapse>
              </Accordion>
            ))}

            {this.renderStatusChangeModal(selectedSubscription, selectedStatus, isLoadingChangeStatus)}
          </Container>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <Footer />
      </div>
    );
  }

  renderStatusChangeModal(subscription, status, isLoading) {
    return (
      <Modal
        show={this.state.isOpenStatusChangeModal}
        onHide={() => this.setState({ isOpenStatusChangeModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Change Status of {subscription?.company_name} to {status}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", width: "90%", margin: "auto" }}>
          <p>
            Are you sure you want to change the status of {subscription?.company_name} to{" "}
            {status}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              <Button
                onClick={() => this.onChangeStatusOnServer(subscription.id, status)}
              >
                Yes
              </Button>
              <Button
                onClick={() => this.setState({ isOpenStatusChangeModal: false })}
              >
                No
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminPendingEventApplicationScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
