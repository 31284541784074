import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SignaturePad from "../ReactSignatureCanvas/index.js";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";

class OperatorPendingSubscription extends Component {
  state = {
    isAgreed: false,
    showModal: false,
    signatureData: null,
    operatorSign: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    availableStatus: ["processing", "rejected", "approved", "Finalized Paid"],
    selectedBookingNewStatus: "",
    selectedBooking: null,
    isOpenBookingStatusChangeModal: false,
    isOpenBookingStatusChangeModal: false,
    isLoadingChangeBookingStatus: false,
    isLoadingChangeBookingStatus: false,
    isLoadingSendingVendorRequest: false,
    vendorCateringPacks: [],
    selectedCateringPack: {},
    requestSent: [],
    isOpenCustomerEmailDetails: false,
    requestSentIds: [],
    isLoadingVendor: false,
    searchVendor: "",
    searchNonCuisineVendor: "",
    vendorListSearchDisplay: "none",
    nonCuisineVendorListSearchDisplay: "none",
    selectedCuisine: "",
    adminNotesChanged: [],
    isLoading: false,
    requestState: "all",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
  };

  handleAgreementChange = (event) => {
    const isAgreed = event.target.checked;
    this.setState({ isAgreed, showModal: isAgreed });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  sendSignatureToServer = async (id, signatureData) => {
    try {
      // const base64Response1 = await fetch(this.state.operatorSign); // Use the signatureData parameter
      // const blob1 = await base64Response1.blob();
      // const file1 = new File([blob1], "operator_signature.jpg", {
      //   type: "image/jpeg",
      // });
      let currentDate = moment().format("DD MMM YYYY");
      const base64Response = await fetch(signatureData); // Use the signatureData parameter
      const blob = await base64Response.blob();
      const filename = `${this.state.operatorName}_${this.state.id}_${moment(
        currentDate
      ).format("DD MMM YYYY")}.jpg`;
      const file = new File([blob], filename, {
        type: "image/jpeg",
      });

      // Prepare FormData with the signature file and additional data
      const formData = new FormData();
      formData.append("operator_signature", file);
      formData.append("contract_id", id);
      formData.append("operator_name", this.state.operatorName);
      formData.append("operator_position", this.state.operatorPosition);
      formData.append("timestamp", moment(currentDate).format("DD MMM YYYY"));

      // Send the signature file and additional data to the server
      const response = await axios.post(
        config.BASE_URI + apiPaths.signSubscription,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success === true) {
        alert("You have successfully signed the contract.");
        this.setState({
          selectedVendorId: "",
          operatorName: "",
          operatorPosition: "",
          isAgreed: false,
          showModal: false,
          operatorSign: "",
        });
        //window.location.reload();
      } else {
        alert("Error sending signature to server:");
        //window.location.reload();
      }
    } catch (error) {
      alert("There was an error processing your signature. Please try again.");
      //window.location.reload();
    }
  };

  saveSignature = (signatureData, id) => {
    if (this.state.operatorName === "" || this.state.operatorPosition === "") {
      alert("Please enter your name and position");
    } else {
      this.setState({ signatureData }, () => {
        this.sendSignatureToServer(id, signatureData); // Assuming you modify this function to accept id and event
        this.handleCloseModal();
        this.setState({ isAgreed: false });
      });
      // this.setState(
      //   {
      //     operatorSign: signatureData,
      //   },
      //   () => {
      //     this.handleCloseModal();
      //   }
      // );
    }
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    this.fetch_vendor_ids();
    this.getDashboardData();
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.getDashboardData(this.state.requestState);
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendors = (cuisine, booking_request_id) => {
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisine_specific_vendors",
          cuisine: cuisine,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          dataResponse.vendors.sort(function(a, b) {
            var nameA = a.title,
              nameB = b.title;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.setState({
            vendors: dataResponse.vendors,
            nonCuisineVendors: dataResponse.non_cuisine_vendors,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_vendor_pending_subscription",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.pending_aggrements });
          console.log(dataResponse);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { isOperatorLoggedIn, stockUserLoggedIn } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {stockUserLoggedIn == "false" || isOperatorLoggedIn == "false"
          ? this.renderHome()
          : this.renderHome()}
        <Container></Container>
      </div>
    );
  }

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
        } else {
          alert(result.error);
        }
      });
  };

  renderNavBar() {
    const { stockUserLoggedIn, isOperatorLoggedIn, stockUserData } = this.state;
    return (
      <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn}></VendorNavBar>
    );
  }

  renderLogin() {
    const { email, password, isEventCoordinatorLoggedIn } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      isOperatorLoggedIn,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
      vendor_ids,
      selectedVendorId,
      stallNameNotes,
      isAgreed,
      showModal,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h2>WTT Confirm Membership</h2>
                  <h3>
                    Operator{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].first_name}{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
                  </h3>
                </Col>
              </Row>
              {bookingRequests.map((item) => (
                <Accordion key={item.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{item.vendor__title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <>
                        {item.draft_agreement ? (
                          <iframe
                            src={AWS_URL + "/" + item.draft_agreement}
                            style={{
                              width: "100%",
                              height: "700px",
                              border: "none",
                            }}
                            title={`Document - ${item.vendor__title}`}
                          ></iframe>
                        ) : (
                          <Typography>No contract available</Typography>
                        )}
                        <div>
                          <label
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={isAgreed}
                              onChange={this.handleAgreementChange}
                              style={{ marginRight: "4px" }} // Added a small right margin to the checkbox
                            />
                            I agree to the above terms
                          </label>

                          <Modal
                            show={showModal}
                            onHide={this.handleCloseModal}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Signature Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="name-field">
                                <label htmlFor="operatorName">Your Name:</label>
                                <input
                                  type="text"
                                  id="operatorName"
                                  onChange={(e) =>
                                    this.setState({
                                      operatorName: e.target.value,
                                    })
                                  }
                                  placeholder="Full Name"
                                />
                              </div>
                              <div className="name-field">
                                <label htmlFor="operatorPosition">
                                  Position:
                                </label>
                                <input
                                  type="text"
                                  id="operatorPosition"
                                  onChange={(e) =>
                                    this.setState({
                                      operatorPosition: e.target.value,
                                    })
                                  }
                                  placeholder="Position"
                                />
                              </div>
                              <SignaturePad
                                onSave={(signatureData) =>
                                  this.saveSignature(signatureData, item.id)
                                }
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <button onClick={this.handleCloseModal}>
                                Close
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(OperatorPendingSubscription);
