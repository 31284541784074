import React, { Component } from "react";

class EditableTable extends Component {

    constructor(props) {
        super(props);
        const rows = props.formik.values.electricalAppliances;
        const rowsVisited = props.formik.values.electricalAppliances.map(() => {
          return {
          idx: false,
          description: false,
          watts: false,
          testDate: false,
          dueDate: false,}});
        this.state = {rows: rows,
        rowsVisited: rowsVisited};
        this.dueDateReadOnly=true;
        this.formik = props.formik;

    }

    handleChange = idx => e => {
        const { name, value } = e.target;
        if (name === "testDate") {
          this.dueDateReadOnly = false;
        }

        const rows = [...this.state.rows];
        rows[idx] = {
          ...rows[idx],
          [name]: value
        };

        this.setState({...this.state, rows: rows}, () => this.formik.setFieldValue("electricalAppliances", this.state.rows));
    
      };

      handleClick = idx => e =>  {
        const { name, value } = e.target;
        const rowsVisited = [...this.state.rowsVisited];
        rowsVisited[idx] = {
          ...rowsVisited[idx],
          [name]: true
        };
        const newState = {...this.state, rowsVisited: rowsVisited};
        this.setState(newState, () => this.formik.setFieldTouched("electricalAppliances", this.state.rowsVisited));
      }

    handleAddRow = (e) => {
      e.preventDefault();
        const item = {
          idx: '',
          description: '',
          watts: '',
          testDate: '',
          dueDate: '',
        };

        const visited = {
          idx: true,
          description: false,
          watts: false,
          testDate: false,
          dueDate: false,
        };

        this.setState({
          rows: [...this.state.rows, item],
          rowsVisited: [...this.state.rowsVisited, visited]
        });
      };

    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows];
        rows.splice(idx, 1);

        const rowsVisited = [...this.state.rowsVisited];
        rowsVisited.splice(idx, 1);
        this.setState({ rows, rowsVisited }, () => {
          this.formik.setFieldValue("electricalAppliances", this.state.rows);
          this.formik.setFieldTouched("electricalAppliances", this.state.rowsVisited);
        });
    };

    render() {
        return (
          <div>
            <div className="container" style={{overflow: "auto"}}>
              <div className="row clearfix">
                <div className="column">
                  <table
                    className="table table-bordered table-hover"
                    id="tab_logic"
                    style={{minWidth: "1200px"}}
                  >
                    <thead>
                      <tr>
                        <th className="text-center"> ID </th>
                        <th className="text-center"> Description </th>
                        <th className="text-center"> Watts </th>
                        <th className="text-center"> Test Date </th>
                        <th className="text-center"> Due Date </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rows.map((item, idx) => (
                        <tr id="addr0" key={idx}>
                          <td>{idx}</td>

                          <td>
                            <input
                              type="text"
                              name="description"
                              value={this.state.rows[idx].description}
                              onChange={this.handleChange(idx)}
                              onClick={this.handleClick(idx)}
                              className="form-control"
                            />
                            
                            {this.state.rowsVisited[idx]["description"] && !this.state.rows[idx]["description"] ? (<div style={{color : "red"}}>{"Required"}</div>) : null }
                
                          </td>
                  
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="watts"
                              value={this.state.rows[idx].watts}
                              onChange={this.handleChange(idx)}
                              onClick={this.handleClick(idx)}
                              className="form-control"
                            />

                            {this.state.rowsVisited[idx]["watts"] && !this.state.rows[idx]["watts"] ? (<div style={{color : "red"}}>{"Required"}</div>) : null }
                            { this.state.rows[idx]["watts"] && !/^[1-9]\d*(\.\d+)?$/i.test(this.state.rows[idx]["watts"]) ? (<div style={{color : "red"}}>{"Invalid Number"}</div>) : null}
                          </td>
                          <td>
                            <input
                              type="date"
                              name="testDate"
                              value={this.state.rows[idx].testDate}
                              onChange={this.handleChange(idx)}
                              onClick={this.handleClick(idx)}
                              className="form-control"
                            />

                            {this.state.rowsVisited[idx]["testDate"] && !this.state.rows[idx]["testDate"] ? (<div style={{color : "red"}}>{"Required"}</div>) : null }
                          </td>
                          <td>
                            <input
                              type="date"
                              name="dueDate"
                              value={this.state.rows[idx].dueDate}
                              onChange={this.handleChange(idx)}
                              onClick={this.handleClick(idx)}
                              className="form-control"
                              readOnly={this.dueDateReadOnly}
                              min={this.state.rows[idx].testDate}
                            />

                            {this.state.rowsVisited[idx]["dueDate"] && !this.state.rows[idx]["dueDate"] ? (<div style={{color : "red"}}>{"Required"}</div>) : null }
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={this.handleRemoveSpecificRow(idx)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button onClick={this.handleAddRow} className="btn btn-primary">
                    Add Item
                  </button>

                </div>
              </div>
            </div>
          </div>
        );
      }
    
}

export default EditableTable;
