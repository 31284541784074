export const theme = {
  colors: {
    primaryColor:'#2859a5',
    textColorDark:'#000000',
    textColorLight:'#ffffff',
    backgroundColor:'#EDF0F3',
    iconColor:'#5a5c60',
    borderColor:'#c7cace'
  },
  layout: {
    baseIndent: 24,
    paddingTop: 20,
  },
};
