import React from "react";
import ReactStars from "react-rating-stars-component";

function StarRating(props) {
  const fourthExample = {
    size: 60,
    isHalf: true,
    char: "★",
    value: props.ratingNumber / 2,
    onChange: (newValue) => {
      props.setNewRating(newValue);
    },
  };
  return (
    <div className="App">
      <ReactStars {...fourthExample} />
    </div>
  );
}
export default StarRating;
