import React, { useState } from "react";
import * as XLSX from "xlsx"; // Import all exports from 'xlsx'
import Dropzone from "react-dropzone";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import stocklist from "./stocklist.xlsx";

const ExcelFileUploader = () => {
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [deletePrevious, set_deletePrevious] = useState(false);

  const handleFormClose = () => {
    setShowForm(false);
  };

  const handleFileDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("We support only xlsx format. Please upload an Excel file.");
      return;
    }
    const reader = new FileReader();

    reader.onload = function(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row contains the column headers
      const headers = parsedData[0];
      const rows = parsedData.slice(1);

      const result = rows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      setData(result);
    };

    reader.readAsBinaryString(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.length > 0) {
      const stockArray = data.map((item) => ({
        Name: item.Name,
        Description: item.Description,
        SKUCode: item["SKU Code"],
        StockCategory: item["Stock Category"],
        Price: item.Price,
        BrandName: item["Brand Name"],
        ProductName: item["Product Name"],
      }));

      postAddCatering(stockArray);
    } else {
      alert("Please upload an Excel file before submitting.");
    }
  };
  const postAddCatering = async (stockArray) => {
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "upload_stocks",
          stock_array: stockArray,
          supplier_id: 1,
          deletePrevious: deletePrevious,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const dataResponse = await response.json();
      if (dataResponse.success === true) {
        alert("Stock List Uploaded Successfully");
        window.location.reload();
      } else {
        alert("Please try again");
        window.location.reload();
      }
    } catch (error) {
      alert("Please try again");
      window.location.reload();
    }
  };

  return (
    <div>
      <Dropzone onDrop={handleFileDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              Drag and drop an Excel file here, or{" "}
              <strong>click to select one</strong>
            </p>
            <Button>Select</Button>
          </div>
        )}
      </Dropzone>
      <div
        style={{
          paddingBottom: "20px",
          paddingTop: "20px",
          paddingLeft: "40%",
        }}
      >
        <Button onClick={() => setShowForm(true)}>Submit</Button>
      </div>
      <div
        style={{
          paddingBottom: "20px",
        }}
      >
        <a
          href={stocklist}
          downloaded="Sample-stock-list"
          style={{
            paddingBottom: "10%",
            paddingLeft: "40%",
          }}
        >
          <Button>Sample Excel Doc</Button>
        </a>
      </div>
      <Row className="table-header-row">
        <Col
          className="desktop-only"
          xs={12}
          md={1}
          lg={1}
          className="table-header-col"
        >
          Name
        </Col>
        <Col xs={12} md={2} lg={2} className="table-header-col">
          Description
        </Col>
        <Col xs={12} md={1} lg={1} className="table-header-col">
          SKU Code
        </Col>
        <Col xs={12} md={2} lg={2} className="table-header-col">
          Stock Category
        </Col>
        <Col xs={12} md={2} lg={2} className="table-header-col">
          Price
        </Col>
        <Col xs={12} md={2} lg={2} className="table-header-col">
          Brand Name
        </Col>
        <Col xs={12} md={2} lg={2} className="table-header-col">
          Product Name
        </Col>
      </Row>
      {data.map((item, index) => (
        <Row key={"orderData" + index} className="table-row">
          <Col xs={12} md={1} lg={1} className="table-col">
            <span className="desktop-only">{item.Name}</span>
          </Col>

          <Col xs={12} md={2} lg={2} className="table-col">
            {item.Description}
          </Col>

          <Col xs={12} md={1} lg={1} className="table-col">
            <span>
              <b className="mobile-only">Qty:</b> {item["SKU Code"]}
            </span>
          </Col>

          <Col xs={12} md={2} lg={2} className="table-col">
            <span>
              <b className="mobile-only">Price:</b> {item["Stock Category"]}
            </span>
          </Col>

          <Col xs={12} md={2} lg={2} className="table-col">
            <span>
              <b className="mobile-only">Sub Total:</b> {item.Price}
            </span>
          </Col>

          <Col xs={12} md={2} lg={2} className="table-col">
            <span>
              <b className="mobile-only">GST:</b> {item["Brand Name"]}
            </span>
          </Col>

          <Col xs={12} md={2} lg={2} className="table-col">
            <span>
              <b className="mobile-only">Total:</b> {item["Product Name"]}
            </span>
          </Col>
        </Row>
      ))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showForm}
        onClose={handleFormClose}
        className="modalnvm"
      >
        <Box className="modalnvm-content">
          <CloseIcon
            className="plzHover"
            fontSize="large"
            style={{ margin: 10, float: "right" }}
            onClick={handleFormClose}
          />
          <label>
            <input
              className="paddingPlease"
              type="checkbox"
              checked={deletePrevious}
              onChange={(e) => set_deletePrevious(e.target.checked)}
            />
            <span className="paddingPlease">
              Would you like to delete the previous stock catelog?
            </span>
          </label>
          <span style={{ paddingRight: "40%" }}>
            <Button onClick={handleSubmit}>Confirm</Button>
          </span>
        </Box>
      </Modal>
    </div>
  );
};

export default ExcelFileUploader;
