import React from "react";
import Register from "../../components/UI/RegisterPage/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

const RegisterPage = () => {
  return (
    <>
      <Register />
    </>
  );
};

export default Sentry.withErrorBoundary(RegisterPage, {
  fallback: <ErrorFallbackComponent />,
});
