import React, { Component } from "react";
import { connect } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

class DriverApp extends Component {
  componentDidMount() {
    if (isAndroid) {
      window.location.href =
        "market://details?id=at.wherethetruck.WhereTheTruckAt";
    } else if (isIOS) {
      window.location.href =
        "itms-apps://apps.apple.com/au/app/where-the-truck-food-finder/id1473745445";
    } else {
      window.location.href = "https://www.wherethetruck.at";
    }
  }

  render() {
    return <div>Redirecting...</div>;
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(DriverApp, {
    fallback: <ErrorFallbackComponent />,
  })
);
