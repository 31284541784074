import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PlacesAutocomplete from "react-places-autocomplete";
import FileUploader from "../../../screens/VendorApplyEventScreen/Component/FileUploader";
import { useSelector, useDispatch } from "react-redux";
import { createEOIEvent } from "../../../actions/EventOrganiser/action";
import * as Sentry from "@sentry/react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const ErrorMessage = ({ formik, eventItem }) => {
  if (formik.touched[eventItem] && formik.errors[eventItem]) {
    return <div style={{ color: "red" }}>{formik.errors[eventItem]}</div>;
  } else {
    return null;
  }
};
const SupplierInformation = ({ formik, getOrganiserStatus }) => {
  const [warehouseAddress, setWarehouseAddress] = useState(undefined);
  const [siteArea, setSiteArea] = useState(undefined);
  const [EOIInformation, setEOIInformation] = useState({
    abnNumber: "",
    contactNumber: "",
    description: "",
    email: "",
    eventContract: [],
    eventFile: [],
    warehouseAddress: "",
    eventLogo: [],
    eventName: "",
    numberOfEvents: "",
    numberOfVendors: "",
    siteArea: "",
  });
  const dispatch = useDispatch();
  const EOI = useSelector((state) => state.organiser.newEOIDetail);

  const handleChange = (e, fieldName) => {
    const EOI = { fieldName: e };
    dispatch(createEOIEvent(EOI));
    formik.setFieldValue(fieldName, e.target.value);
  };
  const handleTouched = (fieldName) => {
    formik.setFieldTouched(fieldName, true);
  };

  const handleLocation = (result) => {
    formik.setFieldValue("warehouseAddress", result);
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{ margin: "10px 10px 10px 10px" }}>
      {!getOrganiserStatus && (
        <Typography
          variant="h4"
          fontStyle="italic"
          style={{ marginBottom: 20 }}
        >
          Apply your Supplier Account
        </Typography>
      )}

      <Divider variant="middle" />
      <Grid container spacing={3}>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            value={EOI.eventName}
            color="secondary"
            label="Organisation Name"
            InputLabelProps={{ shrink: true }}
            placeholder="Company Name"
            onChange={(e) => handleChange(e, "eventName")}
            onClick={() => handleTouched("eventName")}
          />

          <ErrorMessage formik={formik} eventItem="eventName" />
        </Grid>

        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            value={EOI.eventName}
            color="secondary"
            label="Company Description"
            InputLabelProps={{ shrink: true }}
            placeholder="Company Description"
            onChange={(e) => handleChange(e, "description")}
            onClick={() => handleTouched("description")}
          />
        </Grid>

        <Grid item lg={12}>
          <TextField
            fullWidth
            value={EOI.siteArea}
            color="secondary"
            label="Warehouse Address"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e, "siteArea")}
            onClick={() => handleTouched("siteArea")}
          />

          <ErrorMessage formik={formik} eventItem="siteArea" />
        </Grid>
        <Grid item lg={12}>
          <PlacesAutocomplete
            id="event_location"
            value={warehouseAddress}
            onChange={(location) => {
              setWarehouseAddress(location);
            }}
            onSelect={(location) => {
              geocodeByAddress(location)
                .then((results) => {
                  setWarehouseAddress(location);
                  handleLocation(results[0]);
                  getLatLng(results[0]);
                })

                .catch((error) => {
                  Sentry.captureException(error);
                  console.error(error);
                });
            }}
            searchOptions={{
              componentRestrictions: { country: "au" },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <TextField
                  label="Company Location"
                  {...getInputProps({
                    placeholder: "Enter your location ",
                    className: "location-search-input",
                  })}
                />
                <ErrorMessage formik={formik} eventItem="warehouseAddress" />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          cursor: "pointer",
                        }
                      : {
                          cursor: "pointer",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="Email"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e, "email")}
            onClick={() => handleTouched("email")}
          />

          <ErrorMessage formik={formik} eventItem="email" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="Contact Number"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e, "contactNumber")}
            onClick={() => handleTouched("contactNumber")}
          />

          <ErrorMessage formik={formik} eventItem="contactNumber" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="ABN Number"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e, "abnNumber")}
            onClick={() => handleTouched("abnNumber")}
          />

          <ErrorMessage formik={formik} eventItem="abnNumber" />
        </Grid>

        {/* <Typography style={{ marginTop: 20 }}>
          Organisation terms and conditions:
        </Typography>

        <Grid item lg={12} md={12} xs={12}>
          <FileUploader
            allowMultiple={false}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"eventFile"}
            allowFileTypeValidation={true}
            defaultValue={formik.eventFile}
          />

          <ErrorMessage formik={formik} eventItem="eventFile" />
        </Grid> */}
        <Typography style={{ marginTop: 20 }}>Company logo:</Typography>
        <Grid item lg={12} md={12} xs={12}>
          <FileUploader
            allowMultiple={false}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"eventLogo"}
            allowFileTypeValidation={true}
            defaultValue={formik.eventFile}
          />
          <ErrorMessage formik={formik} eventItem="eventLogo" />
        </Grid>
        {/* <Typography style={{ marginTop: 20 }}>
          Organisation contract form:
        </Typography>
        <Grid item lg={12} md={12} xs={12}>
          <FileUploader
            allowMultiple={false}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"eventContract"}
            allowFileTypeValidation={true}
            defaultValue={formik.eventFile}
          />

          <ErrorMessage formik={formik} eventItem="eventContract" />
        </Grid> */}
        {/* <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            type="number"
            label="Total number of Events"
            onChange={(e) => handleChange(e, "numberOfEvents")}
            onClick={() => handleTouched("numberOfEvents")}
          />

          <ErrorMessage formik={formik} eventItem="numberOfEvents" />
        </Grid> */}

        {/* <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            type="number"
            label="Total Number of Vendors"
            onChange={(e) => handleChange(e, "numberOfVendors")}
            onClick={() => handleTouched("numberOfVendors")}
          />
          <ErrorMessage formik={formik} eventItem="numberOfVendors" />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default SupplierInformation;
