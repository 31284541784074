import React, { Component } from "react";
import Container from "@mui/material/Container";
import YoutubeEmbed from "../EOIVideo";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
class FAQPage extends Component {
  render() {
    return (
      <div>
        <VendorNavBar />
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <div>
            <details>
              <summary>
                How do I create add my Food Truck Outlet in WTT?
              </summary>
              <div>
                To add an outlet, click the "Add new Outlet" button in the menu
                and fill the details of the outlet. Please make sure all the
                field marked as important are filled and press "submit" button.
              </div>
              <YoutubeEmbed embedId="V7kMG2Mo7Hg" />
            </details>
            <details>
              <summary>How to upload my documents in WTT?</summary>
              <div>
                To add the documents, click the 'Documents' button in the menu
                and upload your documents. Please make sure all the field marked
                as important are filled and press 'submit' button.
              </div>
              <YoutubeEmbed embedId="upgHaOkNL0Y" />
            </details>
            <details>
              <summary>How do I apply for an event?</summary>
              <div>
                Applying for an event is easy! Simply browse the events listed
                and select the date(s) you are interested in by hovering over
                the dates to see the name of the event. Then, drag and drop your
                food truck outlet into the selected event(s) to express your
                interest in participating. Our event coordinators will review
                your application and confirm your participation. If you have any
                questions or encounter any issues during the application
                process, please feel free to reach out to us for assistance.
                <YoutubeEmbed embedId="kw1RNB4HRGk" />
              </div>
            </details>
            <details>
              <summary>How do I apply for a catering event?</summary>
              <div>
                Participating in a catering event is a straightforward process.
                Begin by exploring the available catering events by selecting
                the date range. Once you have identified the catering event(s)
                you're interested in, simply drag and drop your stall into the
                selected event(s) to indicate your desire to participate also
                select the catering pack you want to apply for that particular
                event. Our dedicated team of coordinators will carefully review
                your application and provide confirmation for your involvement.
                If you have any inquiries or encounter any challenges throughout
                the application procedure, please do not hesitate to contact us
                for guidance and support.
                <YoutubeEmbed embedId="qdf_LcKRX6E" />
              </div>
            </details>
            <details>
              <summary>How to upload my Event Menu?</summary>
              <div>
                Firstly, click the "Events Menu" in the menu bar and select your
                stall. You'll see an "Add" button in the top right corner; click
                it, and you'll be directed to a form. Fill in the item details,
                then click "Submit." You can also edit the details by pressing
                the "Edit" button.
                <YoutubeEmbed embedId="jQm28kPp_DY" />
              </div>
            </details>
            <details>
              <summary>How to upload my Catering Menu?</summary>
              <div>
                Firstly, click the "Catering Menu" in the menu bar and select
                your stall. You'll see an "Add" button in the top right corner;
                click it, and you'll be directed to a form. Fill in the item
                details, then click "Submit." You can also edit the details by
                pressing the "Edit" button.
                <YoutubeEmbed embedId="mSi0Jl0FYHc" />
              </div>
            </details>
            <details>
              <summary>How do I apply for a catering event?</summary>
              <div>
                Participating in a catering event is a straightforward process.
                Begin by exploring the available catering events by selecting
                the date range. Once you have identified the catering event(s)
                you're interested in, simply drag and drop your stall into the
                selected event(s) to indicate your desire to participate also
                select the catering pack you want to apply for that particular
                event. Our dedicated team of coordinators will carefully review
                your application and provide confirmation for your involvement.
                If you have any inquiries or encounter any challenges throughout
                the application procedure, please do not hesitate to contact us
                for guidance and support.
                <YoutubeEmbed embedId="qdf_LcKRX6E" />
              </div>
            </details>
            <details>
              <summary>
                When can I expect to receive a notification regarding my
                Expression of Interest (EOI)?
              </summary>
              <div>
                Our event coordinators will review your EOI and typically
                contact you within a couple of working days. We appreciate your
                patience while we carefully consider each EOI submission. If you
                have not received a notification within a week, please reach out
                to us for an update.
              </div>
            </details>
            <details>
              <summary>
                How do I order stocks and utilities for an event?
              </summary>
              <div>
                To place an order for stock, click on 'event purchases' in the
                menu bar and then select 'event stock purchase' from the
                dropdown menu. On the stock ordering page, select the outlet for
                which you want to order the stocks for the event you're
                participating in. Then select the required quantities of the
                drinks you wish to purchase. Please note that all drinks come in
                packs of different quantities. Finally, click the 'place order'
                button to submit your order. You will receive a notification
                confirming whether your order has been successful or not.
                <YoutubeEmbed embedId="hpn-J-xXeUM" />
              </div>
            </details>
          </div>
        </Container>
      </div>
    );
  }
}

export default Sentry.withErrorBoundary(FAQPage, {
  fallback: <ErrorFallbackComponent />,
});
