import React, { useState, useEffect } from "react";
import config from "../services/apiConfig";
import { apiPaths } from "../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../store";
const useFecth = (userRole = null) => {
  const [vendors, setVendors] = useState([]);
  const [events, setEvents] = useState([]);
  let newVendor = [];
  function fetchList(role) {
    if (userRole == null) {
      return;
    }
    const userList = JSON.parse(getFromLocalStorage(role));

    const vendorRequestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const uniqueUserList = userList
      .map((item) => ({ ...item, stallList: [item.stalls] }))
      .reduce((accumulator, current) => {
        // Check if the ID is already in the accumulator
        if (!accumulator.some((item) => item.id === current.id)) {
          accumulator.push(current); // Add the current object if ID is unique
        } else if (
          !accumulator.some((item) => item.stalls === current.stalls)
        ) {
          const idx = accumulator.findIndex((item) => item.id === current.id);
          if (idx > -1) {
            accumulator[idx].stallList.push(current.stalls);
          }
        }

        return accumulator; // Return the accumulator for the next iteration
      }, []);
    uniqueUserList.forEach((item) => {
      const eoiUrl = config.BASE_URI + apiPaths.adminData;

      // fetch(eventUrl, requestOptions).then((response) =>
      //   response.json().then((responseData) => {
      //     if (newEoi.some((event) => event.id === responseData.id)) {
      //       return;
      //     } else {
      //       newEoi.push(responseData);
      //       setEvents([...newEoi]);
      //     }
      //   })
      // );

      const headers = new Headers();

      headers.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_current_eoi_vendor_applications",
          },
        },
      });

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: raw,
        redirect: "follow",
      };

      fetch(eoiUrl, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(data.eoi, data.eoi.length);
          setEvents(data.eoi);
        });

      item.stallList.forEach((item) => {
        const vendorUrl = config.BASE_URI + apiPaths.explore.trucksList + item;

        fetch(vendorUrl, vendorRequestOptions)
          .then((response) => response.json())
          .then((responseData) => {
            if (!newVendor.some((vendor) => vendor.id === responseData.id)) {
              newVendor.push(responseData);
              setVendors([...newVendor]);
            }
          });
      });
    });
  }

  useEffect(() => {
    fetchList(userRole);
  }, [userRole]);
  return { vendors, events };
};

export default useFecth;
