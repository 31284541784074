import { apiPaths } from "../services/apiPath";
import api, { applyToken } from "../services/api";
import { getFromLocalStorage } from "../store";

//REDIRECT_FROM_WEBSITE
export const REDIRECT_FROM_WEBSITE = "REDIRECT_FROM_WEBSITE";
export function redirectFromWebsite(payload) {
  //console.log("Actions changedTab",tab)
  return {
    type: REDIRECT_FROM_WEBSITE,
    payload: payload,
  };
}

//CHANGE_TAB
export const CHANGE_TAB = "CHANGE_TAB";
export function changeTab(tab) {
  //console.log("Actions changedTab",tab)
  return {
    type: CHANGE_TAB,
    payload: tab,
  };
}

//CHANGE_SELECTED_LOCATION
export const CHANGE_SELECTED_LOCATION = "CHANGE_SELECTED_LOCATION ";
export function changeSelectedLocation(payload) {
  //console.log("Actions changeSelectedLocation",location)
  return {
    type: CHANGE_SELECTED_LOCATION,
    payload: payload,
  };
}

//SEND_SMS
export const SEND_SMS = "SEND_SMS";
function sendSmsAPI(phoneNumber) {
  return api.post(apiPaths.login.sendCode, { phone: "+" + phoneNumber });
}
export function sendSms(phone) {
  return function(dispatch) {
    return sendSmsAPI(phone).then(
      (success) => dispatch(sendSmsSuccess(phone)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function sendSmsSuccess(phone) {
  //console.log("Actions sendSMS",phone)
  return {
    type: SEND_SMS,
    payload: phone,
  };
}

//PAYMENT
export const PAYMENT = "PAYMENT";
function paymentAPI(payload) {
  return api.post(apiPaths.payment, { payload });
}
export function payment(payload) {
  return function(dispatch) {
    return paymentAPI(payload).then(
      (success) => dispatch(paymentSuccess(success.data)),
      (error) => {
        console.log("Charli Error", error);
      }
    );
  };
}
function paymentSuccess(payload) {
  try {
    return {
      type: PAYMENT,
      payload: JSON.parse(payload),
    };
  } catch (e) {
    alert("Error! Please try again!");
  }
}

//CALCULATE_DELIVERY
export const CALCULATE_DELIVERY = "CALCULATE_DELIVERY";
function calculateDeliveryAPI(payload) {
  return api.post(apiPaths.calculateDelivery, { payload });
}
export function calculateDelivery(payload) {
  return function(dispatch) {
    return calculateDeliveryAPI(payload).then(
      (success) => dispatch(calculateDeliverySuccess(success.data)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function calculateDeliverySuccess(payload) {
  //console.log("Actions payment Success",JSON.parse(payload))
  return {
    type: CALCULATE_DELIVERY,
    payload: JSON.parse(payload),
  };
}

//GET_DELIVERY_CHARGES
export const GET_DELIVERY_CHARGES = "GET_DELIVERY_CHARGES";
function getDeliveryAPI(payload) {
  return api.get(apiPaths.explore.delivery, { payload });
}
export function getDelivery(payload) {
  return function(dispatch) {
    return getDeliveryAPI(payload).then(
      (success) => dispatch(getDeliverySuccess(success.data)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function getDeliverySuccess(payload) {
  //console.log("Actions Delivery Charges Success",payload)
  return {
    type: GET_DELIVERY_CHARGES,
    payload: payload,
  };
}

//LOGIN_MODAL_TOGGLE
export const LOGIN_MODAL_TOGGLE = "LOGIN_MODAL_TOGGLE";
export function loginModalToggle(toggle) {
  return {
    type: LOGIN_MODAL_TOGGLE,
    payload: toggle,
  };
}

//PROFILE_MODAL_TOGGLE
export const PROFILE_MODAL_TOGGLE = "PROFILE_MODAL_TOGGLE";
export function profileModalToggle(toggle) {
  return {
    type: PROFILE_MODAL_TOGGLE,
    payload: toggle,
  };
}

//UPDATE_USER_PROFILE
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
function updateUserAPI(userId, username, email) {
  //console.log("Actions updateUserAPI",username,email)
  //alert("Actions updateUserAPI",username,email)
  const userData = {
    username: username.trim(),
    email: email.trim(),
  };
  // console.log("Hello Update",userId,userData)

  return api.put(`${apiPaths.login.createUser}${userId}/`, userData);
}
export function updateUser(userId, username, email) {
  //alert(userId,username,email)
  return function(dispatch) {
    return updateUserAPI(userId, username, email).then(
      (success) =>
        dispatch(updateUserSuccess(success.data, userId, username, email)),
      (error) => alert("Error 2" + error)
    );
  };
}
function updateUserSuccess(data, userId, username, email) {
  //console.log("Actions updateUserSuccess", data,userId,username,email)
  //alert()
  return {
    type: UPDATE_USER_PROFILE,
    payload: data,
  };
}

//LOGIN_COMPLETE
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
function loginPhoneAPI(phoneNumber, code) {
  return api.post(apiPaths.login.verifyNumber, {
    phone: "+" + phoneNumber,
    code: code,
  });
}
export function loginPhone(phone, code) {
  return function(dispatch) {
    return loginPhoneAPI(phone, code).then(
      (success) => dispatch(loginPhoneSuccess(success.data, phone)),
      (error) => {
        console.log("Error 2" + error);
      }
    );
  };
}
function loginPhoneSuccess(data, phone) {
  //console.log("Sahaj 1", data)
  return {
    type: LOGIN_COMPLETE,
    payload: { phone: phone, token: data.token, userId: data.user_id },
  };
}

//LOGOUT
export const LOGOUT = "LOGOUT";
export function logout() {
  //console.log("Actions logout")
  return {
    type: LOGOUT,
    payload: true,
  };
}

//GET_USER_PROFILE
export const GET_USER_PROFILE = "GET_USER_PROFILE";
function getUserProfileAPI(userId) {
  return api.get(apiPaths.profile + userId + "/");
}
export function getUserProfile(userId) {
  return function(dispatch) {
    return getUserProfileAPI(userId).then(
      (success) => dispatch(getUserProfileSuccess(success.data)),
      (error) => {} //console.log("Error 3",error)
    );
  };
}
function getUserProfileSuccess(data) {
  // console.log("Hello Profile", data)
  return {
    type: GET_USER_PROFILE,
    payload: {
      email: data.email,
      username: data.username,
      address: data.address,
      location: data.location,
      image: data.image,
    },
  };
}

//GET_ALL_VENDORS
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
function getAllVendorsAPI() {
  return api.get(apiPaths.explore.trucksList);
}
export function getAllVendors() {
  return function(dispatch) {
    return getAllVendorsAPI().then(
      (success) => dispatch(getAllVendorsSuccess(success)),
      (error) => {
        // console.log("Hello",error)
      } //console.log("Error 4",error)
    );
  };
}
function getAllVendorsSuccess(data) {
  // // console.log("Hello", data)
  return {
    type: GET_ALL_VENDORS,
    payload: data.data,
  };
}

//GET_ALL_LOCATIONS
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
function getAllLocationsAPI() {
  return api.get(apiPaths.explore.location);
}
export function getAllLocations() {
  return function(dispatch) {
    return getAllLocationsAPI().then(
      (success) => dispatch(getAllLocationsSuccess(success)),
      (error) => {} //console.log("Error 4",error)
    );
  };
}
function getAllLocationsSuccess(data) {
  //console.log("Actions getAllLocationsSuccess",data)
  return {
    type: GET_ALL_LOCATIONS,
    payload: data.data,
  };
}

//GET_ALL_FILTERS
export const GET_ALL_FILTERS = "GET_ALL_FILTERS";
function getAllFiltersAPI() {
  return api.get(apiPaths.explore.filterList);
}
export function getAllFilters() {
  return function(dispatch) {
    return getAllFiltersAPI().then(
      (success) => dispatch(getAllFiltersSuccess(success)),
      (error) => {} //console.log("Error 5",error)
    );
  };
}
function getAllFiltersSuccess(data) {
  return {
    type: GET_ALL_FILTERS,
    payload: data.data,
  };
}

//UPDATE_USER_LOCATION
export const UPDATE_USER_LOCATION = "UPDATE_USER_LOCATION";
export function updateUserLocation(lat, lng) {
  //console.log("Actions updateUserLocation",lat,lng)
  return {
    type: UPDATE_USER_LOCATION,
    payload: { latitude: lat, longitude: lng },
  };
}

//GET_SELECTED_VENDOR
export const GET_SELECTED_VENDOR_DATA = "GET_SELECTED_VENDOR_DATA";
function getSelectedVendorAPI(vendorId) {
  return api.get(apiPaths.explore.trucksList + vendorId + "/");
}
export function getSelectedVendor(vendorId) {
  return function(dispatch) {
    return getSelectedVendorAPI(vendorId).then(
      (success) => dispatch(getSelectedVendorSuccess(success)),
      (error) => {} //console.log("Error 6",error)
    );
  };
}
function getSelectedVendorSuccess(data) {
  //console.log("Actions getSelectedVendor",data)
  return {
    type: GET_SELECTED_VENDOR_DATA,
    payload: { vendorData: data.data, selectedVendor: data.data.id },
  };
}

export function setSelectedVendor(data) {
  return {
    type: GET_SELECTED_VENDOR_DATA,
    payload: { vendorData: data, selectedVendor: data.id },
  };
}

//CLEAR_SELECTED_VENDOR
export const CLEAR_SELECTED_VENDOR = "CLEAR_SELECTED_VENDOR";

export function clearSelectedVendor() {
  //console.log("Actions getSelectedVendor clear")
  return {
    type: CLEAR_SELECTED_VENDOR,
    payload: [],
  };
}

export const UPDATE_SELECTED_VENDOR = "UPDATE_SELECTED_VENDOR";
export function updateSelectedVendor(vendorData, selectedVendor) {
  return {
    type: UPDATE_SELECTED_VENDOR,
    payload: { vendorData: vendorData, selectedVendor: selectedVendor },
  };
}

//UPDATE_CART_PRODUCTS
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export function updateCartProducts(products) {
  return {
    type: UPDATE_CART_PRODUCTS,
    payload: { products: products },
  };
}

//UPDATE_CART_TICKET
export const UPDATE_CART_TICKET = "UPDATE_CART_TICKET";
export function updateCartTicket(products) {
  return {
    type: UPDATE_CART_TICKET,
    payload: { products: products },
  };
}

export const UPDATE_CART_TOTAL = "UPDATE_CART_TOTAL";
export function updateCartTotal(productsTotal) {
  return {
    type: UPDATE_CART_TOTAL,
    payload: { productsTotal: productsTotal },
  };
}

export const UPDATE_ORDER_TYPE = "UPDATE_ORDER_TYPE";
export function updateOrderType(orderType) {
  //console.log("Dispatch called ",orderType)
  return {
    type: UPDATE_ORDER_TYPE,
    payload: { orderType: orderType },
  };
}
export const UPDATE_ORDER_LOCATION = "UPDATE_ORDER_LOCATION";
export function updateOrderLocation(orderLocation) {
  return {
    type: UPDATE_ORDER_LOCATION,
    payload: { orderLocation: orderLocation },
  };
}

export const UPDATE_SERVICE_FEE = "UPDATE_SERVICE_FEE";
export function updateServiceFee(serviceFee) {
  return {
    type: UPDATE_SERVICE_FEE,
    payload: { serviceFee: serviceFee },
  };
}

export const UPDATE_EVENT_ID = "UPDATE_EVENT_ID";
export function updateEventId(eventId) {
  //console.log("Dispatch called ",orderLocation)
  return {
    type: UPDATE_EVENT_ID,
    payload: { eventId: eventId },
  };
}

export const UPDATE_ORDER_LATER = "UPDATE_ORDER_LATER";
export function updateOrderLater(orderLater) {
  //console.log("Dispatch called ",orderLater)
  return {
    type: UPDATE_ORDER_LATER,
    payload: { orderLater: orderLater },
  };
}
export const UPDATE_ORDER_DATE = "UPDATE_ORDER_DATE";
export function updateOrderDate(orderDate) {
  //console.log("Dispatch called ",orderDate)
  return {
    type: UPDATE_ORDER_DATE,
    payload: { orderDate: orderDate },
  };
}

export const UPDATE_ORDER_TIME = "UPDATE_ORDER_TIME";
export function updateOrderTime(orderTime) {
  return {
    type: UPDATE_ORDER_TIME,
    payload: { orderTime: orderTime },
  };
}

export const UPDATE_DELIVERY_CHARGES = "UPDATE_DELIVERY_CHARGES";
export function updateDeliveryCharges(deliveryCharges) {
  //console.log("Dispatch called ",deliveryCharges)
  return {
    type: UPDATE_DELIVERY_CHARGES,
    payload: { deliveryCharges: deliveryCharges },
  };
}

//ORDER_NOW
export const ORDER_NOW = "ORDER_NOW";
function orderNowAPI(payload) {
  //console.log("OrderNow API",payload)
  applyToken(getFromLocalStorage("token"));
  console.log("TOKEN_APPLIED");
  return api.post(apiPaths.explore.orders, payload);
}
export function orderNow(payload) {
  console.log(Object.keys(payload), "PAYLOAD");
  return function(dispatch) {
    return orderNowAPI(payload).then(
      (success) => dispatch(orderNowSuccess(success.data)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function orderNowSuccess(payload) {
  //console.log("orderNow Success",payload)
  return {
    type: ORDER_NOW,
    payload: payload,
  };
}

//ORDER_STATUS
export const ORDER_STATUS = "ORDER_STATUS";
function orderStatusAPI(payload) {
  //console.log("OrderStatus API",payload)
  return api.get(apiPaths.explore.orders + payload + "/", payload);
}
export function orderStatus(payload) {
  return function(dispatch) {
    return orderStatusAPI(payload).then(
      (success) => dispatch(orderStatusSuccess(success.data)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function orderStatusSuccess(payload) {
  //console.log("orderStatus Success",payload)
  return {
    type: ORDER_STATUS,
    payload: payload,
  };
}

//GET_ALL_ORDERS
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
function getAllOrdersAPI() {
  //console.log("getAllOrders API")
  return api.get(apiPaths.explore.orders);
}
export function getAllOrders() {
  return function(dispatch) {
    return getAllOrdersAPI().then(
      (success) => dispatch(getAllOrdersSuccess(success.data)),
      (error) => {
        console.log("Error 1" + error);
      }
    );
  };
}
function getAllOrdersSuccess(payload) {
  //console.log("getAllOrders Success",payload)
  return {
    type: GET_ALL_ORDERS,
    payload: payload,
  };
}

//GET_PROMO_CODE
export const GET_PROMO_CODE = "GET_PROMO_CODE";
function getPromoCodeAPI() {
  return api.get(apiPaths.promoCode);
}
export function getPromoCode() {
  return function(dispatch) {
    return getPromoCodeAPI().then(
      (success) => dispatch(getPromoCodeSuccess(success)),
      (error) => console.log("Error 4", error)
    );
  };
}
function getPromoCodeSuccess(data) {
  //console.log("Actions getPromoCodeSuccess",data)
  return {
    type: GET_PROMO_CODE,
    payload: data.data,
  };
}

//APPLIED_PROMO_CODE
export const APPLIED_PROMO_CODE = "APPLIED_PROMO_CODE";
export function applyPromoCode(code) {
  //console.log("Actions APPLIED_PROMO_CODE",compareDesc)
  return {
    type: APPLIED_PROMO_CODE,
    payload: code,
  };
}

//TRACK_DRIVER
export const TRACK_DRIVER = "TRACK_DRIVER";
function trackDriverAPI(orderId) {
  //console.log("getAllOrders API")
  const orderData = {
    orderId: orderId,
    query_type: "get",
  };
  return api.post(apiPaths.trackDriver, orderData);
}
export function trackDriver(orderId) {
  return function(dispatch) {
    return trackDriverAPI(orderId).then(
      (success) => dispatch(trackDriverSuccess(success.data)),
      (error) => {
        console.log("trackDriverError" + error);
      }
    );
  };
}
function trackDriverSuccess(payload) {
  console.log("trackDriverSuccess", payload);
  return {
    type: TRACK_DRIVER,
    payload: payload,
  };
}

export const SET_BOOKING_REQUEST = "SET_BOOKING_REQUEST";
export function setBookingRequest(data) {
  console.log("setBookingRequestsss ", data);
  return {
    type: SET_BOOKING_REQUEST,
    payload: { data },
  };
}
