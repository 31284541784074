import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const CostByHourChart = ({ shiftData }) => {
  if (!shiftData || !shiftData.cost_by_hour) {
    return null; // Optionally, return a placeholder or loading indicator here
  }

  const data = {
    labels: Object.keys(shiftData.cost_by_hour).map((date) =>
      moment(date).format("DD-MM-YYYY")
    ),
    datasets: [
      {
        label: "Average Hourly Cost Per Day",
        data: Object.values(shiftData.cost_by_hour),
        backgroundColor: ["purple", "orange", "pink", "grey"], // Customize these colors as needed
      },
    ],
  };

  return <Bar data={data} />;
};
export default CostByHourChart;
