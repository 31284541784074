import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

import { theme } from '../theme';
import {store} from '../../../store'

 


class CustomButton extends Component {
    state={
        loginModalToggle:false,
    }
   
    render() {
        const { 
            borderRadius, 
            backgroundColor, 
            isProfilePic, 
            buttonText, 
            handleButtonClick,
            buttonWidth,
            textColor,
            margin 
        } = this.props
        const {appReducer} = store.getState()
        const {userImg} = appReducer
       
        const buttonStyle = {
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            width:buttonWidth,
            color:textColor,
            margin:margin
        };
        const buttonProfilePicStyle = {
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            color:textColor,
            margin:margin,
        };
        const searchButtonIconStyle = {
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
            borderRight: 0,
            borderColor: backgroundColor,
            backgroundColor: backgroundColor,
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0
        };
        const picStyle = {
            border: '1px solid',
            borderRadius: borderRadius,
            borderColor: theme.colors.textColorDark,
            backgroundColor: backgroundColor,

        };
       
        
        
        return (
            <>
                <InputGroup>
                    {isProfilePic === true ?
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1" style={searchButtonIconStyle}>

                                <img
                                    src={userImg!="null" && userImg?userImg:require('../../../assets/img/profile_picture_default.png')}
                                    width="40"
                                    height="40"
                                    className="d-inline-block align-top"
                                    alt="Profile Pic"
                                    style={picStyle}
                                />

                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        : null}
                    <Button style={isProfilePic ? buttonProfilePicStyle : buttonStyle} onClick={handleButtonClick}>{buttonText}</Button>

                </InputGroup>
                
            </>

        );
    }
}

export default CustomButton;