import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import api from "../../services/api";

import config from "../../services/apiConfig";
import moment from "moment";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/UI/Button/index";
import {
  sendSms,
  loginPhone,
  getUserProfile,
  updateCartTicket,
  updateCartTotal,
  updateOrderType,
  updateOrderLater,
  updateOrderLocation,
  updateEventId,
  changeTab,
  calculateDelivery,
  getDelivery,
  updateDeliveryCharges,
  orderStatus,
  getPromoCode,
  applyPromoCode,
  updateOrderDate,
  updateOrderTime,
  validatePromoCode,
  updateSelectedVendor,
  clearSelectedVendor,
  logout,
  updateUser,
  updateCartProducts,
} from "../../actions";
import Event from "./event.js";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { applyToken } from "../../services/api";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { hasEventExpired } from "../../helper";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class NewScreen extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isQuantityPickerModalOpen: false,
    quantityPicker: 0,
    eventDetails: {},
    isOpenFoodSuggestionModal: false,
    isOpenLoginModal: false,
    buttonPressed: false,
    code: "",
    phone: "",
    ticketState: {},
    productsTotalState: 0.0,
    isOpenProfileModal: false,
    vendorEventMenu: {},
  };
  componentDidMount() {
    const { appReducer } = store.getState();
    const { eventTicket, productsTotal } = appReducer;
    const { ticketState, productsTotalState } = this.state;
    // this.props.dispatch(clearSelectedVendor())
    this.getEventDetails();
    this.getVendorEventMenu();
    if (this.isEmpty(ticketState)) {
      this.setState({ ticketState: eventTicket }, () => {});
    }
    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal }, () => {});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appReducer } = store.getState();
    const { eventTicket } = appReducer;
    const { dispatch } = this.props;

    if (this.state.ticketState !== eventTicket) {
      dispatch(updateCartTicket(this.state.ticketState));
      // dispatch(updateCartTotal(this.calculateTotalPrice()))
    }
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  getEventDetails = () => {
    const { appReducer } = store.getState();
    const { products } = appReducer;
    var eventId = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    var url = config.BASE_URI + apiPaths.customerEvents + eventId + "/";

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((eventDetailsResponse) => {
        this.setState({ eventDetails: eventDetailsResponse });
        saveToLocalStorage(
          "selectedEvent",
          JSON.stringify(eventDetailsResponse)
        );
        saveToLocalStorage("serviceFee", eventDetailsResponse.service_fee);
        if (this.isEmpty(products)) {
          if (
            eventDetailsResponse &&
            eventDetailsResponse.vendor &&
            eventDetailsResponse.vendor.length > 0
          ) {
            this.props.dispatch(
              updateSelectedVendor({
                vendorData: eventDetailsResponse.vendor[0],
                selectedVendor: eventDetailsResponse.vendor[0].id,
              })
            );
          }
        }
      });
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var url = config.BASE_URI + apiPaths.customerEvents;
    // var eventId = window.location.href.substring(window.location.href.lastIndexOf(":") + 1)
    // var raw = JSON.stringify(
    //     {
    //         "payload":
    //         {
    //             "body":
    //             {
    //                 "query_type": 'specific_event',
    //                 "event_id":eventId,

    //             }
    //         }
    //     });

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };

    // fetch(url, requestOptions)
    //     .then(response => response.json())
    //     .then(response => {
    //         if (response.success == true) {
    //             this.setState({ eventDetails: response.event_details })
    //         }
    //     })
  };

  getVendorEventMenu = () => {
    var eventId = JSON.parse(getFromLocalStorage("eventDetails")).id;
    var vendorId = localStorage.getItem("vendorId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + "/api/v1/customer-events/";
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_event_menu",
          event_id: eventId,
          vendor_id: vendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("hello ", response);
        if (response.success == true) {
          this.setState({
            vendorEventMenu: response.vendor_details,
          });
        } else {
          console.log("error catch");
          alert(response.error);
        }
      });
  };

  render() {
    const { appReducer } = store.getState();
    const { products } = appReducer;
    const { eventDetails, ticketState, vendorEventMenu } = this.state;
    var startDate = null;
    var endDate = null;
    const { loggedIn, username, userId, dispatch } = this.props;
    const iconButtonStyle = {
      backgroundColor: theme.colors.backgroundColor,
      fontSize: 40,
      padding: 8,
      borderRadius: 20,
      color: theme.colors.iconColor,
      marginLeft: 10,
      marginRight: 10,
    };

    try {
      startDate = moment(eventDetails.start_date, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      endDate = moment(eventDetails.end_date, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
    } catch (err) {}
    let vendorId = localStorage.getItem("vendorId");
    return (
      <div>
        <ResponsiveNavBar
          showBack={true}
          backUrl={
            "/event:" + JSON.parse(getFromLocalStorage("eventDetails")).id
          }
          ticketState={ticketState}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        <Event
          ticketState={ticketState}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userId={userId}
          dispatch={dispatch}
          vendorEventMenu={vendorEventMenu}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(NewScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
