import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PlacesAutocomplete from "react-places-autocomplete";
import FileUploader from "../../../screens/VendorApplyEventScreen/Component/FileUploader";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import * as Sentry from "@sentry/react";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store";

const ErrorMessage = ({ formik, eventItem }) => {
  if (formik.touched[eventItem] && formik.errors[eventItem]) {
    return <div style={{ color: "red" }}>{formik.errors[eventItem]}</div>;
  } else {
    return null;
  }
};
const EventInformation = ({ formik }) => {
  const [eventLocation, setEventLocation] = useState(undefined);
  const handleChange = (e, fieldName) => {
    formik.setFieldValue(fieldName, e.target.value);
    saveToLocalStorage(fieldName, e.target.value);
  };
  const handleTouched = (fieldName) => {
    formik.setFieldTouched(fieldName, true);
  };

  const handleLocation = (result) => {
    formik.setFieldValue("siteArea", result);
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{ margin: "10px 10px 10px 10px" }}>
      <Typography variant="h4" fontStyle="italic" style={{ marginBottom: 20 }}>
        Apply EOI for your Event
      </Typography>
      <Divider variant="middle" />
      <Grid container spacing={3}>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="EOI event name"
            value={getFromLocalStorage("eventName")}
            onChange={(e) => handleChange(e, "eventName")}
            onClick={() => handleTouched("eventName")}
          />
          <ErrorMessage formik={formik} eventItem="eventName" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="First Name"
            value={getFromLocalStorage("firstName")}
            onChange={(e) => handleChange(e, "firstName")}
            onClick={() => handleTouched("firstName")}
          />

          <ErrorMessage formik={formik} eventItem="firstName" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="Last Name"
            value={getFromLocalStorage("lastName")}
            onChange={(e) => handleChange(e, "lastName")}
            onClick={() => handleTouched("lastName")}
          />

          <ErrorMessage formik={formik} eventItem="lastName" />
        </Grid>

        <Grid item lg={12}>
          <PlacesAutocomplete
            id="event_location"
            value={eventLocation}
            onChange={(location) => {
              setEventLocation(location);
            }}
            onSelect={(location) => {
              geocodeByAddress(location)
                .then((results) => {
                  setEventLocation(location);
                  handleLocation(results[0]);
                  getLatLng(results[0]);
                })

                .catch((error) => {
                  Sentry.captureException(error);
                  console.error(error);
                });
            }}
            searchOptions={{
              componentRestrictions: { country: "au" },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <TextField
                  label="Site Area"
                  {...getInputProps({
                    placeholder: "Enter your location ",
                    className: "location-search-input",
                  })}
                />
                <ErrorMessage formik={formik} eventItem="siteArea" />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          cursor: "pointer",
                        }
                      : {
                          cursor: "pointer",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="Email"
            value={getFromLocalStorage("emailEOI")}
            onChange={(e) => handleChange(e, "emailEOI")}
            onClick={() => handleTouched("emailEOI")}
          />

          <ErrorMessage formik={formik} eventItem="emailEOI" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="Contact Number"
            value={getFromLocalStorage("contactNumber")}
            onChange={(e) => handleChange(e, "contactNumber")}
            onClick={() => handleTouched("contactNumber")}
          />

          <ErrorMessage formik={formik} eventItem="contactNumber" />
        </Grid>
        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            label="ABN Number"
            value={getFromLocalStorage("abnNumber")}
            onChange={(e) => handleChange(e, "abnNumber")}
            onClick={() => handleTouched("abnNumber")}
          />
          <ErrorMessage formik={formik} eventItem="abnNumber" />
        </Grid>
        <Grid item lg={12}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Service
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => handleChange(e, "service")}
              onClick={() => handleTouched("service")}
            >
              <FormControlLabel value="food" control={<Radio />} label="Food" />
              <FormControlLabel
                value="retail"
                control={<Radio />}
                label="Retail"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>

          <ErrorMessage formik={formik} eventItem="service" />
        </Grid>
        <Grid item lg={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <FormLabel id="demo-controlled-radio-buttons-group">
              Bump In Date Time
            </FormLabel>
            <TextField
              id="start_date_time"
              //label="event Start Time"
              type="datetime-local"
              onChange={(e) => handleChange(e, "bumpInDateTime")}
              onClick={() => handleTouched("bumpInDateTime")}
            />
            <ErrorMessage formik={formik} eventItem="bumpInDateTime" />
            <FormLabel id="demo-controlled-radio-buttons-group">
              Bump Out Date Time
            </FormLabel>
            <TextField
              id="start_date_time"
              //label="event Start Time"
              type="datetime-local"
              onChange={(e) => handleChange(e, "bumpOutDateTime")}
              onClick={() => handleTouched("bumpOutDateTime")}
            />
            <ErrorMessage formik={formik} eventItem="bumpOutDateTime" />
            <FormLabel id="demo-controlled-radio-buttons-group">
              Event Start Date Time
            </FormLabel>
            <TextField
              id="start_date_time"
              //label="event Start Time"
              type="datetime-local"
              onChange={(e) => handleChange(e, "eventStartDateTime")}
              onClick={() => handleTouched("eventStartDateTime")}
            />
            <ErrorMessage formik={formik} eventItem="eventStartDateTime" />
            <FormLabel id="demo-controlled-radio-buttons-group">
              Event End Date Time
            </FormLabel>
            <TextField
              id="start_date_time"
              //label="event Start Time"
              type="datetime-local"
              onChange={(e) => handleChange(e, "eventEndDateTime")}
              onClick={() => handleTouched("eventEndDateTime")}
            />
            <ErrorMessage formik={formik} eventItem="eventEndDateTime" />
          </div>
        </Grid>

        <Typography style={{ marginTop: 20 }}>
          Organisation terms and conditions:
        </Typography>

        <Grid item lg={12}>
          <FileUploader
            allowMultiple={false}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"eventFile"}
            allowFileTypeValidation={true}
            defaultValue={formik.eventFile}
          />
          <ErrorMessage formik={formik} eventItem="eventFile" />
        </Grid>

        <Typography style={{ marginTop: 20 }}>Organisation logo:</Typography>
        <Grid item lg={12} md={12} xs={12}>
          <FileUploader
            allowMultiple={false}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"eventLogo"}
            allowFileTypeValidation={true}
            defaultValue={formik.eventFile}
          />
          <ErrorMessage formik={formik} eventItem="eventLogo" />
        </Grid>
        <Grid item lg={12}>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Water Access
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => handleChange(e, "waterAccess")}
              onClick={() => handleTouched("waterAccess")}
            >
              <FormControlLabel
                value="Self sufficient"
                control={<Radio />}
                label="Self sufficient"
              />
              <FormControlLabel
                value="Bucket/Barrel fill"
                control={<Radio />}
                label="Bucket/Barrel fill"
              />
              <FormControlLabel
                value="Hose connection"
                control={<Radio />}
                label="Hose Connection"
              />
              <FormControlLabel
                value="Not Required"
                control={<Radio />}
                label="Not Required"
              />
            </RadioGroup>
          </FormControl>
          <ErrorMessage formik={formik} eventItem="waterAccess" />
        </Grid>

        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            type="number"
            label="Cost of 10 amp:"
            value={getFromLocalStorage("eventNumberOf10Amp")}
            onChange={(e) => handleChange(e, "eventNumberOf10Amp")}
            onClick={() => handleTouched("eventNumberOf10Amp")}
          />
        </Grid>

        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            type="number"
            color="secondary"
            label="Cost Of 15 Amp"
            value={getFromLocalStorage("eventNumberOf15Amp")}
            onChange={(e) => handleChange(e, "eventNumberOf15Amp")}
            onClick={() => handleTouched("eventNumberOf15Amp")}
          />
        </Grid>

        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            type="number"
            label="Cost Of 20 Amp"
            value={getFromLocalStorage("eventNumberOf20Amp")}
            onChange={(e) => handleChange(e, "eventNumberOf20Amp")}
            onClick={() => handleTouched("eventNumberOf20Amp")}
          />
        </Grid>

        <Grid item lg={12} style={{ flexDirection: "column" }}>
          <TextField
            fullWidth
            color="secondary"
            type="number"
            label="Cost Of 32 Amp"
            value={getFromLocalStorage("eventNumberOf32Amp")}
            onChange={(e) => handleChange(e, "eventNumberOf32Amp")}
            onClick={() => handleTouched("eventNumberOf32Amp")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventInformation;
