import React, { useState, useEffect } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import { generateTruckBio } from '../../helper.js';

import useFecth from "../../hook/useFecth";
import DatePicker from "react-datepicker";
import moment from "moment";
import Reload from "../VendorApplyEventScreen/Component/Reload.js";
import * as Sentry from "@sentry/react";
import BioModal from './BioModal'; // Import the BioModal component








function EditStall(props) {
  console.log(props);
  const stall_details = props.stall_details;
  const [logoFile, setlogofile] = useState([]);
  const [stallId, set_stallId] = useState(stall_details.id);
  const [stallName, set_StallName] = useState(stall_details.title);
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [generatedBio, setGeneratedBio] = useState('');

  const [cuisine_type, set_cuisine_type] = useState(
    stall_details.kitchens?.[0]?.title || ""
  );
  const [outlet_type, set_outlet_type] = useState(
    Number(stall_details.outlet_type)
  );
  const [service_side, set_service_side] = useState(stall_details.service_side);
  const [nameError, set_nameError] = useState("");
  const [contactNumber, set_contactNumber] = useState(stall_details.phone);
  const [contactError, set_contactError] = useState("");
  const [email, set_email] = useState(stall_details.email);
  const [emailError, set_emailError] = useState("");
  const [city, set_city] = useState(stall_details.hometown);
  const [cityError, set_cityError] = useState("");
  const [abnNumber, set_abnNumber] = useState(stall_details.abn_number);
  const [abnError, set_abnError] = useState("");
  const [stallDescription, setStallDescription] = useState(stall_details.bio);
  const [descriptionError, setDescriptionError] = useState("");
  const [eventPhotos, set_eventPhotos] = useState([]);
  const [siteLayoutFile, set_siteLayoutFile] = useState(null);
  const [vansWidth, set_vansWidth] = useState(stall_details.truck_width);
  const [vansHeight, set_vansHeight] = useState(stall_details.truck_height);
  const [vansDepth, set_vansDepth] = useState(stall_details.truck_depth);
  const [exitDoorPos, set_exitDoorPos] = useState(stall_details.truck_exit);
  const [coolRoomWidth, set_coolRoomWidth] = useState(
    stall_details.cooler_width
  );
  const [coolRoomHeight, set_coolRoomHeight] = useState(
    stall_details.cooler_height
  );
  const [coolRoomDepth, set_coolRoomDepth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCoolRoomRequired, setIsCoolRoomRequired] = useState(
    stall_details.is_cooler_required
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voiceTone, setVoiceTone] = useState("");
  const [popularItems, setPopularItems] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [fireSource, set_fireSource] = useState(stall_details.fire_source);
  const [waterAccess, set_waterAccess] = useState(stall_details.water_access);
  const [numberOf10Amp, set_numberOf10Amp] = useState(
    stall_details.number_of_10_amp
  );


  const [isFormVisible, setIsFormVisible] = useState(false);
 


  const [numberOf15Amp, set_numberOf15Amp] = useState(
    stall_details.number_of_15_amp
  );
  const [numberOf20Amp, set_numberOf20Amp] = useState(
    stall_details.number_of_20_amp
  );
  const [numberOf32Amp, set_numberOf32Amp] = useState(
    stall_details.number_of_32_amp
  );
  const [doesDelivery, set_doesDelivery] = useState(stall_details.delivery);
  const [deliveryFee, set_deliveryFee] = useState(stall_details.delivery_fee);
  const [isPricesAlteredAllowed, set_isPricesAlteredAllowed] = useState(
    stall_details.is_prices_altered_allowed
  );
  const [instagram_username, set_instagram_username] = useState(
    stall_details.instagram
  );
  const [facebook_username, set_facebook_username] = useState(
    stall_details.facebook
  );
  const [website, set_website] = useState(stall_details.website);
  const [otherComments, set_otherComments] = useState(
    stall_details.other_comments
  );
  const [liability_certificate, set_liability_certificate] = useState([]);
  const [electrical_certificate, set_electrical_certificate] = useState([]);
  const [gas_certificate, set_gas_certificate] = useState([]);
  const [food_registration_docs, set_food_registration_docs] = useState([]);
  const [other_uploads, set_other_uploads] = useState([]);
  const [
    electrical_certificate_expiry_date,
    set_electrical_certificate_expiry_date,
  ] = useState(new Date());
  const [
    liability_certificate_expiry_date,
    set_liability_certificate_expiry_date,
  ] = useState(new Date());
  const [
    gas_certificate_expiry_date,
    set_gas_certificate_expiry_date,
  ] = useState(new Date());
  const [
    food_registration_certificate_expiry_date,
    set_food_registration_certificate_expiry_date,
  ] = useState(new Date());
  const [other_uploads_expiry_date, set_other_uploads_expiry_date] = useState(
    new Date()
  );

  const postVendors = (values) => {
    values.preventDefault();
    if (numberOf10Amp === "") {
      set_numberOf10Amp("0");
    }
    if (numberOf15Amp === "") {
      set_numberOf15Amp("0");
    }
    if (numberOf20Amp === "") {
      set_numberOf20Amp("0");
    }
    if (numberOf32Amp === "") {
      set_numberOf32Amp("0");
    }
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          vendor_id: stallId,
          title: stallName,
          // logo: logoMetadata,
          email: email,
          hometown: city,
          bio: stallDescription,
          contact: contactNumber,
          // photo: eventPhotos,
          location: "SRID=4326;POINT (144.5929945 -37.8969398)",
          accepting_orders: true,
          abn_number: abnNumber,
          truck_width: parseFloat(vansWidth),
          truck_depth: parseFloat(vansDepth),
          truck_height: parseFloat(vansHeight),
          // site_layout: siteLayoutFile,
          truck_exit: exitDoorPos,
          is_cooler_required: isCoolRoomRequired,
          cooler_width: parseFloat(coolRoomWidth),
          cooler_height: parseFloat(coolRoomHeight),
          cooler_depth: parseFloat(coolRoomDepth),
          fire_source: fireSource,
          water_access: waterAccess,
          number_of_10_amp: numberOf10Amp ? parseInt(numberOf10Amp) : 0,
          number_of_15_amp: numberOf15Amp ? parseInt(numberOf15Amp) : 0,
          number_of_20_amp: numberOf20Amp ? parseInt(numberOf20Amp) : 0,
          number_of_32_amp: numberOf32Amp ? parseInt(numberOf32Amp) : 0,
          delivery_fee: parseFloat(deliveryFee),
          instagram_username: instagram_username,
          facebook_username: facebook_username,
          website_url: website,
          is_prices_altered_allowed: isPricesAlteredAllowed,
          other_comments: otherComments,
          // cuisine_type: cuisine_type,
          service_side: service_side,
          outlet_type: outlet_type,
          // electrical_certificate: electrical_certificate,
          // liability_certificate: liability_certificate,
          // liablity_certificate_expiry_date: moment(
          //   liability_certificate_expiry_date
          // ).format("DD MMM YYYY"),
          // gas_certificate: gas_certificate,
          // food_registration_certificate: food_registration_docs,
          // food_registration_certificate_expiry_date: moment(
          //   food_registration_certificate_expiry_date
          // ).format("DD MMM YYYY"),
          // other_uploads: other_uploads,
          // other_uploads_expiry_date: moment(other_uploads_expiry_date).format(
          //   "DD MMM YYYY"
          // ),
        },
      },
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Vendor Details Updated Successfully!");
          handleReload();
        } else {
          alert("Vendor details could not be edited please try again!");
          // window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Vendor details could not be edited please try again!");
        // window.location.reload();
      });
  };
  const handleReload = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: JSON.parse(getFromLocalStorage("Operator"))[0].email,
          password: JSON.parse(getFromLocalStorage("Operator"))[0].password,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (
          dataResponse.success == true &&
          dataResponse.data[0].is_operator == true
        ) {
          saveToLocalStorage("Operator", JSON.stringify(dataResponse.data));
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      })
      .finally(() => {
        // Finally, reload the page
        window.location.reload();
      });
  };

  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.events;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisines",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          setAvailableCuisines(response.cuisines);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getOutletTypes = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.operatorData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_outlet_types",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.outlet_type);
        if (response.success === true) {
          setOutletTypes(response.outlet_type);
          // console.log(outlet_type);
          // const matchingType = response.outlet_type.find(
          //   (type) => type.id == outlet_type
          // );
          // console.log(matchingType);
          // if (matchingType) {
          //   set_outlet_type(matchingType.outlet_type);
          //   console.log(matchingType.outlet_type);
          // }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };

  const handleOutletTypeChange = (event) => {
    set_outlet_type(Number(event.target.value));
  };

  const handleStallNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_nameError("Please enter only letters and spaces");
    } else {
      set_StallName(inputValue);
      set_nameError("");
    }
  };

  const handleContactNumberChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_contactError("Please enter a valid contact number");
    } else {
      set_contactNumber(inputValue);
      set_contactError("");
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (!regex.test(inputValue)) {
      set_emailError("Please Enter a valid Email");
    } else {
      set_email(inputValue);
      set_emailError("");
    }
  };
  const handleCityChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_cityError("Please enter only letters and spaces");
    } else {
      set_city(inputValue);
      set_cityError("");
    }
  };
  const handleABNNumber = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_abnError("Please enter a ABN number");
    } else {
      set_abnNumber(inputValue);
      set_abnError("");
    }
  };
  const handleDescription = (event) => {
    const inputDescription = event.target.value;
    setStallDescription(inputDescription);
  
    // Split the input text into words using space as the separator and filter out empty words
    const wordCount = inputDescription.trim().split(/\s+/).length;
  
    if (wordCount > 255) {
      console.log(wordCount, " Words");
      setDescriptionError("Please enter the description in 255 words or fewer.");
    } else {
      setDescriptionError("");
    }
  };
  const handleSiteLayoutFileChange = (fileItems3) => {
    set_siteLayoutFile(fileItems3[0].file);
  };

  const handleGenerateBio = async () => {
    const features = `${stall_details.truck_width} two most popular food items, ${stall_details.popularItems} truck is more focused towards, ${stall_details.vendorType}, make it with the voice tone of ${voiceTone} and make it sound more street food style as well as use Australia English `;
    const bio = await generateTruckBio(
      stall_details.title,
      stall_details.kitchens?.[0]?.title || '',
      stall_details.hometown,
      features,
      popularItems,
      vendorType,
      voiceTone
    );
    setStallDescription(bio);
    setGeneratedBio(bio);
  };

  const handlevansWidthChange = (event) => {
    set_vansWidth(event.target.value);
  };
  const handlevansHeightChange = (event) => {
    set_vansHeight(event.target.value);
  };
  const handlevansDepthChange = (event) => {
    set_vansDepth(event.target.value);
  };
  const handleCoolRoomWidthChange = (event) => {
    set_coolRoomWidth(event.target.value);
  };
  const handleCoolRoomHeightChange = (event) => {
    set_coolRoomHeight(event.target.value);
  };
  const handleCoolRoomDepthChange = (event) => {
    set_coolRoomDepth(event.target.value);
  };
  const handleFireSourceChange = (event) => {
    set_fireSource(event.target.value);
  };
  const handleExitDoorPosChange = (event) => {
    set_exitDoorPos(event.target.value);
  };
  const handleServiceSideChange = (event) => {
    set_service_side(event.target.value);
  };
  const handleWaterAccessChange = (event) => {
    set_waterAccess(event.target.value);
  };
  const handleNumberOf10AmpChange = (event) => {
    set_numberOf10Amp(event.target.value);
  };
  const handleNumberOf15AmpChange = (event) => {
    set_numberOf15Amp(event.target.value);
  };
  const handleNumberOf20AmpChange = (event) => {
    set_numberOf20Amp(event.target.value);
  };
  const handleNumberOf32AmpChange = (event) => {
    set_numberOf32Amp(event.target.value);
  };
  const handleDeliveryFeeChange = (event) => {
    set_deliveryFee(event.target.value);
  };
  const handleInstagramUsernameChange = (event) => {
    set_instagram_username(event.target.value);
  };
  const handleFacebookUsernameChange = (event) => {
    set_facebook_username(event.target.value);
  };
  const handleWebsiteChange = (event) => {
    set_website(event.target.value);
  };
  const handleOtherCommentsChange = (event) => {
    set_otherComments(event.target.value);
  };
  const pull_data = (selectedFiles, fieldName) => {
    const updateField = eval(fieldName);
    updateField(selectedFiles[0]);
  };
  useEffect(() => {
    console.log(logoFile.file);
  }, [logoFile]);
  useEffect(() => {
    getAvailableCuisines();
    getOutletTypes();
  }, []);
  const serviceSideList = [
    "Driver Side",
    "Passenger Side",
    "Rear",
    "Not Required",
  ];
  const fireSourceList = ["Gas", "Coal", "Wood Fire", "None"];
  const exitDoorList = ["back", "right", "front", "left"];
  const waterAccessList = [
    "Not Required",
    "Self Sufficient",
    "Bucket / Barrel Fill",
    "Hose Connection",
    "Tap Access",
  ];
  console.log(outlet_type);
  console.log(outletTypes);
  return (
    <div className="form-style-5">
      <form onSubmit={postVendors}>
        <div>
          <legend>
            <span className="number">Vendor DETAILS:</span>
          </legend>
          <label>Outlet Name*</label>
          <input
            placeholder="Vendor Name*:"
            type="text"
            id="stallName"
            name="stallName"
            value={stallName}
            onChange={handleStallNameChange}
          />
          {nameError && <div style={{ color: "red" }}>{nameError}</div>}
          {}
          <label>Outlet Type*</label>
          <select value={outlet_type} onChange={handleOutletTypeChange}>
            <option value="" disabled>
              Select outlet type
            </option>
            {outletTypes &&
              outletTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.outlet_type}
                </option>
              ))}
          </select>
          <label>Contact Number*</label>
          <input
            placeholder="Contact Number*:"
            type="number"
            id="contactNumber"
            name="contactNumber"
            value={contactNumber}
            onChange={handleContactNumberChange}
          />
          {contactError && <div style={{ color: "red" }}>{contactError}</div>}
          <label>Email*</label>
          <input
            placeholder="Email*:"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          <label>City*</label>
          <input
            placeholder="City*:"
            type="text"
            id="city"
            name="city"
            value={city}
            onChange={handleCityChange}
          />
          {cityError && <div style={{ color: "red" }}>{cityError}</div>}
          <label>ABN Number</label>
          <input
            placeholder="ABN Number(Optional):"
            type="number"
            id="abnNumber"
            name="abnNumber"
            value={abnNumber}
            onChange={handleABNNumber}
          />
          {abnError && <div style={{ color: "red" }}>{abnError}</div>}
        </div>
       
        <label>Description</label>
<textarea
  placeholder="Description of what type of food/drink/service you want to sell/provide (less than 300 words)*:"
  type="text"
  rows="4"
  cols="75"
  value={stallDescription}
  onChange={handleDescription}
></textarea>
{descriptionError && (
  <div style={{ color: "red" }}>{descriptionError}</div>
)}
{/* Bio Generation Button */}
<div>
          <button type="button" onClick={() => setIsFormVisible(true)}>
            Ai Assistant  Generate Bio
          </button>
        </div>

        {/* Conditionally render the form fields for bio generation */}
        {isFormVisible && (
          <div className="bio-form">
            <h3>Answer a Few Questions</h3>
            
              <label>Two Most Popular Items on Your Menu</label>
              <input
                type="text"
                placeholder="Enter your two most popular items, separated by a comma"
                value={popularItems}
                onChange={(e) => setPopularItems(e.target.value)}
                required
              />

              <label>What do you want your customers to know you as?</label>
              <select
                value={vendorType}
                onChange={(e) => setVendorType(e.target.value)}
                required
              >
                <option value="" disabled>Select an option</option>
                <option value="Catering function vendor">Catering function vendor</option>
                <option value="Events and festival food truck vendor">Events and festival food truck vendor</option>
                <option value="Both">Both catering function and events/festival food truck vendor</option>
              </select>



            {/* Tone of Voice Dropdown */}
            <label>Select the Tone of Voice for Your Brand</label>
              <select
                value={voiceTone}
                onChange={(e) => setVoiceTone(e.target.value)}
                required
              >
                <option value="" disabled>Select a tone of voice</option>
                <option value="Friendly and Approachable">Friendly and Approachable: Casual language that invites conversation and creates a sense of community.</option>
                <option value="Playful and Fun">Playful and Fun: Light-hearted, whimsical tone that emphasizes joy and creativity in cooking and eating.</option>
                <option value="Authentic and Wholesome">Authentic and Wholesome: Emphasizes natural ingredients, tradition, and care in food preparation.</option>
                <option value="Sophisticated and Elegant">Sophisticated and Elegant: Uses refined language to convey quality and luxury, appealing to a more upscale audience.</option>
                <option value="Health-Conscious and Informative">Health-Conscious and Informative: Focuses on nutritional benefits, wellness, and informed choices, often with a motivational tone.</option>
                <option value="Adventurous and Bold">Adventurous and Bold: Encourages trying new flavors and cuisines, appealing to food explorers and trendsetters.</option>
                <option value="Nostalgic and Heartfelt">Nostalgic and Heartfelt: Evokes memories and emotions tied to food, emphasizing family recipes and comfort.</option>
                <option value="Eco-Conscious and Sustainable">Eco-Conscious and Sustainable: Highlights environmental responsibility and sustainable practices, appealing to environmentally conscious customers.</option>
              </select>



              <button type="button" onClick={() => handleGenerateBio()}>
            Generate Bio
          </button>

              
          </div>
        )}

        {generatedBio && (
          <div className="generated-bio">
            <h3>Generated Bio:</h3>
            <p>{generatedBio}</p>
          </div>
        )}
        <legend>
          <span className="number">STALL REQUIREMENTS:</span>
        </legend>
       
        <label htmlFor="logoFile">
          Clearly indicate width, depth and height*.
        </label>
        <label>Truck Width</label>
        <input
          placeholder="Width (in meters):"
          id="vansWidth"
          name="vansWidth"
          value={vansWidth}
          onChange={handlevansWidthChange}
          type="number"
        />
        <label>Truck Depth</label>
        <input
          placeholder="Depth (in meters):"
          id="vansHeight"
          name="vansHeight"
          value={vansHeight}
          onChange={handlevansHeightChange}
          type="number"
        />
        <label>Truck Height</label>
        <input
          placeholder="Height (in meters):"
          id="vansDepth"
          name="vansDepth"
          value={vansDepth}
          onChange={handlevansDepthChange}
          type="number"
        />
        <label>Door Positions*</label>
        <label className="paddingPlease">
          What is the position of your exit door at your stall?
        </label>
        <select value={exitDoorPos} onChange={handleExitDoorPosChange}>
          <option value="" disabled>
            Please select
          </option>
          {exitDoorList.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label className="paddingPlease">
          What is the service side of your stall?
        </label>
        <select value={service_side} onChange={handleServiceSideChange}>
          <option value="" disabled>
            Please select
          </option>
          {serviceSideList.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={isCoolRoomRequired}
            onChange={(e) => setIsCoolRoomRequired(e.target.checked)}
          />
          <span className="paddingPlease">I need a cool room/freezer room</span>
        </label>
        {isCoolRoomRequired && (
          <div>
            <label>Size of the coolroom*</label>
            <label className="paddingPlease">Height (in meters):</label>
            <input
              placeholder="Height"
              id="coolRoomHeight"
              name="coolRoomHeight"
              value={coolRoomHeight}
              onChange={handleCoolRoomHeightChange}
              type="number"
            />
            <label className="paddingPlease">Width (in meters):</label>
            <input
              placeholder="Width"
              id="coolRoomWidth"
              name="coolRoomWidth"
              value={coolRoomWidth}
              onChange={handleCoolRoomWidthChange}
              type="number"
            />
            <label className="paddingPlease">Depth (in meters):</label>
            <input
              placeholder="Depth"
              id="coolRoomDepth"
              name="coolRoomDepth"
              value={coolRoomDepth}
              onChange={handleCoolRoomDepthChange}
              type="number"
            />
          </div>
        )}
        <label className="paddingPlease">
          What is your ignition source of fire?*
        </label>
        <select value={fireSource} onChange={handleFireSourceChange}>
          <option value="" disabled>
            Please select
          </option>
          {fireSourceList.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <legend>
          <span className="number">Water Requirements:</span>
        </legend>
        <label>Water Access*</label>
        <select value={waterAccess} onChange={handleWaterAccessChange}>
          <option value="" disabled>
            Please select
          </option>
          {waterAccessList.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label>
          <strong>NOTE: </strong>retail cannot apply for Water Access
        </label>
        <div>
          <legend>
            <span className="number">Power Requirements:</span>
          </legend>
          <label>Number of 10 Amp Outlet/s:</label>
          <input
            placeholder="Number of 10 Amp Outlet/s:"
            type="number"
            id="numberOf10Amp"
            name="numberOf10Amp"
            value={numberOf10Amp}
            onChange={handleNumberOf10AmpChange}
          />
          <label>Number of 15 Amp Outlet/s:</label>
          <input
            placeholder="Number of 15 Amp Outlet/s:"
            type="number"
            id="numberOf15Amp"
            name="numberOf15Amp"
            value={numberOf15Amp}
            onChange={handleNumberOf15AmpChange}
          />
          <label>Number of 20 Amp Outlet/s:</label>
          <input
            placeholder="Number of 20 amps 3 phase Outlet/s:"
            type="number"
            id="numberOf20Amp"
            name="numberOf20Amp"
            value={numberOf20Amp}
            onChange={handleNumberOf20AmpChange}
          />
          <label>Number of 32 Amp Outlet/s:</label>
          <input
            placeholder="Number of 32 amps 3 phase Outlet/s:"
            type="number"
            id="numberOf32Amp"
            name="numberOf32Amp"
            value={numberOf32Amp}
            onChange={handleNumberOf32AmpChange}
          />
          <label>
            <strong>NOTE: </strong>Some events prohibits catering operators to
            bring generators on site & must use event power.
          </label>
         
        </div>
        
        <legend>
          <span className="number">Other Services:</span>
        </legend>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={doesDelivery}
            onChange={(e) => set_doesDelivery(e.target.checked)}
          />
          <span className="paddingPlease">
            Does the vendor provide delivery service?
          </span>
        </label>
        {doesDelivery && (
          <div>
            <input
              placeholder="Delivey Fee*:"
              id="deliveryFee"
              name="deliveryFee"
              value={deliveryFee}
              onChange={handleDeliveryFeeChange}
              type="number"
            />
          </div>
        )}
        <label>
          <strong>
            Where The Truck™ reserves the right to set retail prices
          </strong>
        </label>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={isPricesAlteredAllowed}
            onChange={(e) => set_isPricesAlteredAllowed(e.target.checked)}
          />
          <span className="paddingPlease">
            I am willing to have my prices altered.
          </span>
        </label>
        {/* <div>
          <legend>
            <span className="number">Other Uploads:</span>
          </legend>
          <label htmlFor="electrical_certificate">
            Upload your Extra Documents:
          </label>
          <FileUploader fieldName={"set_siteLayoutFile"} func={pull_data} />
        </div> */}
        <legend>
          <span className="number">Social Handles:</span>
        </legend>
        <input
          placeholder="Website URL: "
          type="text"
          id="website"
          name="website"
          value={website}
          onChange={handleWebsiteChange}
        />
        <input
          placeholder="Facebook Username: "
          type="text"
          id="facebook_username"
          name="facebook_username"
          value={facebook_username}
          onChange={handleFacebookUsernameChange}
        />
        <input
          placeholder="Instagram Username: "
          type="text"
          id="instagram_username"
          name="instagram_username"
          value={instagram_username}
          onChange={handleInstagramUsernameChange}
        />
        <legend>
          <span className="number">Additional Comments:</span>
        </legend>
        <textarea
          placeholder="Comments:"
          type="text"
          rows="4"
          cols="75"
          onChange={handleOtherCommentsChange}
          value={otherComments}
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
export default EditStall;
