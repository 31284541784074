import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import * as Sentry from "@sentry/react";
import { apiPaths } from "../../services/apiPath";

import config from "../../services/apiConfig";

import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ResponsiveVendorNavBar from "../../components/UI/ResponsiveVendorNavBar";
import {
  deleteFromLocalStorage,
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../store";
import ImageUploader from "react-images-upload";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class VendorNewRequestScreen extends Component {
  state = {
    suburb: "Reservoir",
    number_of_guests: 50,
    vendor: "",
    event_start_date: "20 Aug 2021",
    event_end_date: "20 Aug 2021",
    event_start_time: "05:30 PM",
    event_end_time: "08:00 PM",
    event_type: "",
    continueClicked: false,
    cateringPacks: {},
    package_name:
      getFromLocalStorage("package_name") != null &&
      getFromLocalStorage("package_name") != ""
        ? getFromLocalStorage("package_name")
        : "",
    package_description:
      getFromLocalStorage("package_description") != null &&
      getFromLocalStorage("package_description") != ""
        ? getFromLocalStorage("package_description")
        : "",
    package_price_min_50: 0,
    package_price_min_100: 0,
    package_price_min_150: 0,
    reject_reason: "",
    package_people: 0,
    selectedCuisine: "",
    openThankYouModal: false,
    packImages: [],
    loader: false,
  };
  componentDidMount() {
    var temp = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    var booking_request_id = temp.split("&")[0];
    var vendor_id = temp.split("vendor_id=")[1];
    if (vendor_id.includes("#")) {
      vendor_id = vendor_id.split("#")[0];
    }
    this.setState({
      bookingRequestId: booking_request_id,
      vendorId: vendor_id,
    });
    this.getBookingRequestDetails(booking_request_id, vendor_id);
    this.getAvailableCuisines();
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    if (page.includes("resubmit")) {
      this.setState({ continueClicked: true });
    }
  }

  getBookingRequestDetails = (booking_request_id, vendor_id) => {
    this.setState({ loader: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-booking-request",
          booking_request_id: booking_request_id,
          vendor_id: vendor_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ loader: false });
        if (response.success == true) {
          this.setState(
            {
              location: response.booking_details.location,
              event_start_date: response.booking_details.start_date,
              event_end_date: response.booking_details.end_date,
              event_start_time: response.booking_details.start_time,
              vendor: response.booking_details.vendor__title,
              event_end_time: response.booking_details.end_time,
              number_of_guests: response.booking_details.number_of_guests,
              event_type: response.booking_details.event_type,
              selectedCuisine: response.booking_details.cuisine,
            },
            () => {
              if (this.state.location.includes(", Australia")) {
                var temp = this.state.location.split(", Australia")[0];
                if (temp.includes(",")) {
                  temp = temp.split(",")[1];
                } else {
                  temp = temp.split(" ");

                  temp = temp[temp.length - 2] + " " + temp[temp.length - 1];
                }
                this.setState({ suburb: temp });
              } else {
                this.setState({ suburb: this.state.location });
              }
            }
          );
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ loader: false });
      });
  };

  updateBookingRequest = (status) => {
    const {
      package_name,
      package_description,
      package_price_min_50,
      package_price_min_100,
      package_price_min_150,
      package_people,
      bookingRequestId,
      packImages,
    } = this.state;
    var price50 = package_price_min_50;
    var price100 = package_price_min_100;
    var price150 = package_price_min_150;

    if (price50 == "") {
      price50 = 0;
    }
    if (price100 == "") {
      price100 = 0;
    }
    if (price150 == "") {
      price150 = 0;
    }

    if (
      package_name == "" ||
      package_description == "" ||
      (price50 == 0 && price100 == 0 && price150 == 0)
    ) {
      alert("Please enter all the required fields");
      return;
    }
    this.setState({ loader: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_catering_pack",
          booking_request_id: bookingRequestId,
          package_name: package_name,
          package_description: package_description,
          package_price_min_50: price50,
          package_price_min_100: price100,
          package_price_min_150: price150,
          package_people: package_people,
          vendor_id: this.state.vendorId,
          cuisine: this.state.selectedCuisine,
          pack_images: packImages,
          catering_pack_id: [],
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          alert("Submitted Successfully!");
          this.setState({ openThankYouModal: true });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ loader: false });
      });
  };

  getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-available-cuisine",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({ availableCuisines: response.availableCuisines });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  render() {
    const {
      suburb,
      event_start_date,
      event_end_date,
      event_start_time,
      event_end_time,
      number_of_guests,
      event_type,
      continueClicked,
      vendor,
    } = this.state;
    return (
      <>
        <ResponsiveVendorNavBar
          showBack={continueClicked}
          backUrl={window.location.href}
        ></ResponsiveVendorNavBar>
        <Container style={{ padding: 10 }}>
          {continueClicked == false ? (
            <>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>
                    We have a {event_type} request to book your truck. If you
                    want to accept this request please enter your catering pack
                    details
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Truck Name</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>{vendor}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Location</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>{suburb}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Date</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>
                    {event_start_date} - {event_end_date}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Time</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>
                    {event_start_time} - {event_end_time}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Estimated Number of Guests</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>{number_of_guests}</p>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  this.setState({ continueClicked: true });
                }}
              >
                Continue
              </Button>
            </>
          ) : (
            this.renderMenuDetails()
          )}
          {this.renderThankYouModal()}
        </Container>
      </>
    );
  }

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState(
      {
        packImages: pictureDataURLs,
      },
      () => {}
    );
  };

  hasValueUpdated = () => {
    const {
      package_name,
      package_description,
      package_price_min_50,
      package_price_min_100,
      package_price_min_150,
      number_of_guests,
      payment_total_amount,
      reject_reason,
      cateringPacks,
    } = this.state;
    if (
      cateringPacks.package_name != package_name ||
      cateringPacks.package_description != package_description ||
      cateringPacks.package_price != package_price_min_50
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderMenuDetails() {
    const {
      package_name,
      package_description,
      package_price_min_50,
      package_price_min_100,
      package_price_min_150,
      number_of_guests,
      availableCuisines,
      package_people,
      packImages,
    } = this.state;
    return (
      <>
        <Row>
          <Col xs={12}>
            <p>
              Please complete the below form to provide your quote to the
              customer.
            </p>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Catering Pack Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg:Small Pack"
                value={package_name}
                onChange={(e) => {
                  this.setState({ package_name: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Enter your catering pack description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="eg:1x Burger,1x small chips 1x drink"
                value={package_description}
                onChange={(e) => {
                  this.setState({ package_description: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>*Per person price ($) for min 50 people </Form.Label>
              <Form.Control
                type="number"
                placeholder="eg: 20"
                value={package_price_min_50}
                onChange={(e) => {
                  this.setState({ package_price_min_50: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>*Per person price ($) for min 100 people </Form.Label>
              <Form.Control
                type="number"
                placeholder="eg: 20"
                value={package_price_min_100}
                onChange={(e) => {
                  this.setState({ package_price_min_100: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>*Per person price ($) for min 150 people </Form.Label>
              <Form.Control
                type="number"
                placeholder="eg: 20"
                value={package_price_min_150}
                onChange={(e) => {
                  this.setState({ package_price_min_150: e.target.value });
                }}
              />
            </Form.Group>

            {/* */}
            <Form.Group>
              <Form.Label>Select a cuisine </Form.Label>
              <Form.Control
                as="select"
                size="sm"
                custom
                onChange={(e) => {
                  this.setState({ selectedCuisine: e.target.value });
                }}
              >
                <option value={0}>Select a cuisine</option>
                {availableCuisines &&
                  availableCuisines.map((cuisine) => {
                    return (
                      <option
                        selected={
                          cuisine.cuisine.toLowerCase() ==
                          this.state.selectedCuisine.toLowerCase()
                            ? "selected"
                            : null
                        }
                        value={cuisine.cuisine.toLowerCase()}
                      >
                        {cuisine.cuisine}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <p>Upload catering pack images</p>
              <ImageUploader
                withIcon={true}
                buttonText="Choose images"
                onChange={(pictureFiles, pictureDataURLs) =>
                  this.onDrop(pictureFiles, pictureDataURLs)
                }
                withPreview={true}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={5242880}
                label={"Upload catering pack images. Max img size 5mb"}
                buttonStyles={{
                  backgroundColor: "#2859a5",
                  color: "white",
                  fontWeight: "unset",
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="button"
          onClick={() => {
            if (!this.state.loader) {
              this.updateBookingRequest("submit");
            }
          }}
        >
          {this.state.loader ? "Loading..." : "Submit"}
        </Button>
        <p>
          <i>
            *Please keep in mind that other vendors will also be quoting this
            job and as you are a non member a 20% fee will be charged on this
            total price of your quote and deducted from any payments. Please
            consider this while providing your quote. If you like to reduce your
            commission fee, you can do so by becoming a member which will reduce
            counter sales commmission to 25% and catering job commmission to
            20%. To request payment terms and conditions please click the link
            here.
          </i>
        </p>
        <p>
          <i>
            Once you submit your quote the customer will receive your quote and
            if accepted we will confirm your booking.
          </i>
        </p>
        <p>
          <i>
            Want to provide more truckin’ quotes? Add more catering options by
            resubmitting this form. This increases your chances of your quote
            being accepted.
          </i>
        </p>
        <p>
          <i>We will save the packs for any future requests as well.</i>
        </p>
      </>
    );
  }

  renderThankYouModal() {
    const { openThankYouModal } = this.state;
    return (
      <Modal
        show={openThankYouModal}
        onHide={() => {
          this.setState({ openThankYouModal: false }, () => {
            deleteFromLocalStorage("package_name", "");
            deleteFromLocalStorage("package_description", "");
            window.location.reload();
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submitted Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            style={{
              height: 500,
              marginTop: 20,
              overflowY: "scroll",
              border: "none",
            }}
          >
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Row>
                <Col xs={12}>
                  <p>
                    Thank you for submitting your catering quote. The customer
                    will receive your quote and if accepted we will confirm your
                    booking.
                  </p>
                  <p>
                    <b>
                      Want to provide more truckin’ quotes? Add more catering
                      options by resubmitting this form. This increases your
                      chances of your quote being accepted.
                    </b>{" "}
                  </p>
                  <Button
                    style={{ backgroundColor: "#ef3f6d", width: "100%" }}
                    className="button-enabled-pink-style"
                    type="button"
                    onClick={() => {
                      deleteFromLocalStorage("package_name", "");
                      deleteFromLocalStorage("package_description", "");
                      window.location.href = window.location.href + "#resubmit";
                      window.location.reload();
                    }}
                  >
                    Add More Quotes
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default Sentry.withErrorBoundary(VendorNewRequestScreen, {
  fallback: <ErrorFallbackComponent />,
});
