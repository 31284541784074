import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { utils, writeFile } from "xlsx";

const SalesReport = ({ data = {} }) => {
  // Ensure data exists and contains the sales_by_vendor_obj property
  if (!data || !data.sales_by_vendor_obj) {
    return <div>No sales data available.</div>;
  }

  // Extracting data from the provided object
  const {
    event,
    total_voucher_value,
    total_orders,
    total_transaction_fees,
    total_sales,
    total_cash_sales,
    total_refunds,
    total_card_sales,
    canceled_sales,
    canceled_sales_card,
    canceled_sales_cash,
    canceled_orders,
    cash_new_count,
    card_new_count,
    sales_by_vendor_obj,
  } = data;
  // Export data to Excel
  const exportToExcel = () => {
    const wb = utils.book_new();

    // Event Information Sheet
    const eventSheetData = [
      // ... Event details ...
    ];
    const eventSheet = utils.aoa_to_sheet(eventSheetData);
    utils.book_append_sheet(wb, eventSheet, "Event Information");

    // Sales Data Sheet
    const salesDataSheetData = [
      ["Vendor", "Sales Value"],
      ...salesData.map(({ name, value }) => [name, value]),
    ];
    const salesDataSheet = utils.aoa_to_sheet(salesDataSheetData);
    utils.book_append_sheet(wb, salesDataSheet, "Sales Data");

    // Total Sales Data Sheet
    const totalSalesDataSheetData = [
      // ... Total sales data ...
    ];
    const totalSalesDataSheet = utils.aoa_to_sheet(totalSalesDataSheetData);
    utils.book_append_sheet(wb, totalSalesDataSheet, "Total Sales Data");

    // Create the Pie Chart Sheet
    const chartSheetData = [
      ["Vendor", "Sales Value"],
      ...salesData.map(({ name, value }) => [name, value]),
    ];
    chartSheetData.unshift(["Vendor", "Sales Value"]);
    const chartSheet = utils.aoa_to_sheet(chartSheetData);
    const chartColWidths = [{ wpx: 150 }, { wpx: 100 }];
    chartSheet["!cols"] = chartColWidths;
    const chartDrawing = {
      type: "pie",
      position: {
        col: 0,
        row: 1,
      },
      size: {
        width: 480,
        height: 300,
      },
      title: "Sales by Vendor",
    };
    chartSheet["!drawing"] = [chartDrawing];
    utils.book_append_sheet(wb, chartSheet, "Sales by Vendor Chart");

    // Generate and download the Excel file
    writeFile(wb, "sales_report.xlsx");
  };
  // Extracting sales data for the pie chart
  const salesData = Object.entries(
    sales_by_vendor_obj
  ).map(([name, value]) => ({ name, value }));
  salesData.sort((a, b) => b.value - a.value); // Sorting by sales value

  // Extracting the top 3 sellers
  const topThreeSellers = salesData.slice(0, 3);

  return (
    <div>
      <div className="center-button-report">
        <ReactHTMLTableToExcel
          id="export-button"
          className="btn"
          table="sales-report-table"
          filename={event.name + "_sales_report"}
          sheet="sheet1"
          buttonText="Export to Excel"
          style={{ backgroundColor: "#ef3e6d", color: "#f1f1f1" }}
        />
      </div>

      <h2>Event Details</h2>
      <table id="sales-report-table">
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>Event Details</h2>
            </td>
          </tr>
          <tr>
            <td>ID:</td>
            <td>{event.id}</td>
          </tr>
          <tr>
            <td>Name:</td>
            <td>{event.name}</td>
          </tr>
          <tr>
            <td>Location:</td>
            <td>{event.location}</td>
          </tr>
          <tr>
            <td>Start Date:</td>
            <td>{event.start_date}</td>
          </tr>
          <tr>
            <td>End Date:</td>
            <td>{event.end_date}</td>
          </tr>
          <tr>
            <td>Start Time:</td>
            <td>{event.start_time}</td>
          </tr>
          <tr>
            <td>End Time:</td>
            <td>{event.end_time}</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>Sales Data</h2>
            </td>
          </tr>
          <tr>
            <th>Vendor</th>
            <th>Sales Value</th>
          </tr>
          {salesData.map((seller, index) => (
            <tr key={index}>
              <td>{seller.name}</td>
              <td>{seller.value}</td>
            </tr>
          ))}
        </tbody>
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>Sales by Vendor</h2>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={salesData}
                    dataKey="value"
                    nameKey="name"
                    outerRadius={80}
                    fill="#8884d8"
                  >
                    {salesData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={`#${Math.floor(Math.random() * 16777215).toString(
                          16
                        )}`}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>Top 3 Sellers</h2>
            </td>
          </tr>
          <tr>
            <th>Vendor</th>
            <th>Sales Value</th>
          </tr>
          {topThreeSellers.map((seller, index) => (
            <tr key={index}>
              <td>{seller.name}</td>
              <td>{seller.value}</td>
            </tr>
          ))}
        </tbody>
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>Total Sales and Other Data</h2>
            </td>
          </tr>
          <tr>
            <td>Total Voucher Value:</td>
            <td>{total_voucher_value}</td>
          </tr>
          <tr>
            <td>Total Orders:</td>
            <td>{total_orders}</td>
          </tr>
          <tr>
            <td>Total Transaction Fees:</td>
            <td>{total_transaction_fees}</td>
          </tr>
          <tr>
            <td>Total Sales:</td>
            <td>{total_sales}</td>
          </tr>
          <tr>
            <td>Total Cash Sales:</td>
            <td>{total_cash_sales}</td>
          </tr>
          <tr>
            <td>Total Refunds:</td>
            <td>{total_refunds}</td>
          </tr>
          <tr>
            <td>Total Card Sales:</td>
            <td>{total_card_sales}</td>
          </tr>
          <tr>
            <td>Canceled Sales:</td>
            <td>{canceled_sales}</td>
          </tr>
          <tr>
            <td>Canceled Sales Card:</td>
            <td>{canceled_sales_card}</td>
          </tr>
          <tr>
            <td>Canceled Sales Cash:</td>
            <td>{canceled_sales_cash}</td>
          </tr>
          <tr>
            <td>Canceled Orders:</td>
            <td>{canceled_orders}</td>
          </tr>
          <tr>
            <td>Cash New Count:</td>
            <td>{cash_new_count}</td>
          </tr>
          <tr>
            <td>Card New Count:</td>
            <td>{card_new_count}</td>
          </tr>
        </tbody>
      </table>

      {/* Export Button */}
      <ReactHTMLTableToExcel
        id="export-button"
        className="btn"
        table="sales-report-table"
        filename={event.name + "_sales_report"}
        sheet="sheet1"
        buttonText="Export to Excel"
      />
    </div>
  );
};

export default SalesReport;
