import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockPortalApprovedOrdersScreenQuotes extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    kitchacoLocations: [],
    kitchacoLocation:
      getFromLocalStorage("kitchacoLocation") != "undefined" &&
      getFromLocalStorage("kitchacoLocation") != "" &&
      getFromLocalStorage("kitchacoLocation") != null
        ? getFromLocalStorage("kitchacoLocation")
        : 1,
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    sub_total: 0,
    total: 0,
    gst: 0,
    admin_adjustment: 0,
    delivery_charge: 0,
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stock_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.vendors });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Oops, Something went wrong! Please try again later");
      });
  }

  fetchApprovedOrderFromServer = () => {
    const {
      email,
      password,
      kitchacoLocation,
      superAdminEmail,
      superAdminPassword,
      startDate,
      endDate,
    } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = "";

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_past_finalised_quote_requests",
          //   kitchaco_location: parseInt(kitchacoLocation),
          //   start_date: moment(startDate).format("DD MMM YYYY"),
          //   end_date: moment(endDate).format("DD MMM YYYY"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.stockData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            pendingOrder: result.data,
            sub_total: result.sub_total,
            gst: result.gst,
            total: result.total,
            admin_adjustment: result.admin_adjustment,
            delivery_charge: result.delivery_charge,
          });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again");
      });
  };

  containsObject = (obj, list) => {
    if (list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].stock_list_id == obj.stock_list_id) {
          return true;
        }
      }
    }
    return false;
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  onChangeKitchacoLocation(e) {
    this.setState({ kitchacoLocation: e.target.value }, () => {
      saveToLocalStorage("kitchacoLocation", this.state.kitchacoLocation);
      window.location.reload();
    });
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn, isStockAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.getKitchacoLocationData();
      this.fetchApprovedOrderFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      pendingOrder,
      kitchacoLocation,
      kitchacoLocations,
      startDate,
      endDate,
      sub_total,
      delivery_charge,
      gst,
      total,
      admin_adjustment,
    } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>WTT Stock Ordering System</h2>
              <h3>Past Quotes</h3>
            </Col>

            {/* <Col xs={12} md={6} lg={6}>
              {kitchacoLocations && kitchacoLocations.length > 0 ? (
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>
                    Vendors {"(" + kitchacoLocations.length + ")"}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    onChange={this.onChangeKitchacoLocation.bind(this)}
                    defaultValue={kitchacoLocation}
                  >
                    <option value={0}>All</option>
                    {kitchacoLocations.map((location, i) => {
                      return (
                        <option
                          key={i}
                          value={kitchacoLocations[i].user_ptr_id}
                        >
                          {kitchacoLocations[i].title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              ) : null}
            </Col> */}
            {/* <Col xs={12} md={6} lg={6}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4} md={4} lg={4}>
                  <p>Approved Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>Approved End Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button
                    onClick={() => {
                      this.fetchApprovedOrderFromServer();
                    }}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col xs={12} md={12} lg={12}>
              <Row>
                {/* <Col xs={12} md={4} lg={4}>
                  <b>
                    <h4>Stock Ordered (ex GST): ${sub_total}</h4>
                  </b>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <b>
                    <h4>GST: ${Number(gst).toFixed(2)}</h4>
                  </b>
                </Col> */}
                {/* <Col xs={12} md={4} lg={4}> */}
                {/* <b>
                    <h4>Stock Ordered (inc GST): ${total}</h4>
                  </b> */}
                {/* <b>
                    <h6>Below Min Order Fee: ${delivery_charge}</h6>
                  </b> */}
                {/* <b>
                    <h6>Admin Adjustment: ${admin_adjustment}</h6>
                  </b> */}
                {/* </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Order Date and Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Delivery Address
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Sub Total
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              GST
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col"></Col>
          </Row>
          {pendingOrder.map((item, i) => {
            return (
              <>
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Order Number:</b>{" "}
                            {item.id}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {moment(item.order_placed_date_time).format(
                            "DD/MM/YYYY"
                          )}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.operator_name__shipping_address}
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Sub Total:</b>$
                            {item.sub_total}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">GST:</b>${item.gst}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Total:</b>${item.total}
                          </span>
                          {Number(item.delivery_charge) > 0 &&
                          Number(item.admin_adjustment) +
                            Number(item.delivery_charge) !=
                            0
                            ? "(inc below min order fee of $" +
                              Number(item.delivery_charge).toFixed(2) +
                              ")"
                            : null}
                          {Number(item.admin_adjustment) > 0
                            ? "(inc admin adjustment of $" +
                              Number(item.admin_adjustment).toFixed(2) +
                              ")"
                            : null}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">Status:</b>
                            {item.quote_status}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={item.id}
                      style={{ padding: 20, backgroundColor: "#eff2f5" }}
                    >
                      <>
                        <Row>
                          <Col xs={12} md={3} lg={3}>
                            <h5>Products Ordered</h5>
                          </Col>
                        </Row>
                        <Row className="table-header-row">
                          <Col
                            className="desktop-only"
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Id
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Product Name
                          </Col>
                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Qty
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Price
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Sub Total
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            GST
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Total
                          </Col>
                        </Row>
                        {item.orderData.map((product, i) => {
                          return (
                            <>
                              <Row
                                key={i}
                                className="table-row"
                                style={
                                  this.containsObject(
                                    product,
                                    item.adjustedData
                                  )
                                    ? { backgroundColor: "yellow" }
                                    : null
                                }
                              >
                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span className="desktop-only">
                                    {product.id}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  {product.product_name}
                                </Col>

                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Qty:</b>{" "}
                                    {product.product_qty}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Price:</b>{" "}
                                    {product.price_per_item}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Sub Total:</b>$
                                    {product.total_price}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">GST:</b>$
                                    {product.gst}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Total:</b>$
                                    {product.total_price}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        {item.adjustedData && item.adjustedData.length > 0 ? (
                          <>
                            <Row>
                              <Col
                                xs={12}
                                md={3}
                                lg={3}
                                style={{ marginTop: 20 }}
                              >
                                <h5>Adjusted Items</h5>
                              </Col>
                            </Row>
                            <Row className="table-header-row">
                              <Col
                                className="desktop-only"
                                xs={12}
                                md={1}
                                lg={1}
                                className="table-header-col"
                              >
                                Id
                              </Col>
                              <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className="table-header-col"
                              >
                                Product Name
                              </Col>
                              <Col
                                xs={12}
                                md={1}
                                lg={1}
                                className="table-header-col"
                              >
                                Qty
                              </Col>
                              <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className="table-header-col"
                              >
                                Price
                              </Col>
                              <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className="table-header-col"
                              >
                                Sub Total
                              </Col>
                              <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className="table-header-col"
                              >
                                GST
                              </Col>
                              <Col
                                xs={12}
                                md={2}
                                lg={2}
                                className="table-header-col"
                              >
                                Total
                              </Col>
                            </Row>
                            {item.adjustedData &&
                              item.adjustedData.map((product, i) => {
                                return (
                                  <Row key={i} className="table-row">
                                    <Col
                                      xs={12}
                                      md={1}
                                      lg={1}
                                      className="table-col"
                                    >
                                      <span className="desktop-only">
                                        {product.stock_list_id}
                                      </span>
                                    </Col>

                                    <Col
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="table-col"
                                    >
                                      {product.name +
                                        " (" +
                                        product.qty_type__qty_type +
                                        ")"}
                                    </Col>

                                    <Col
                                      xs={12}
                                      md={1}
                                      lg={1}
                                      className="table-col"
                                    >
                                      <span>
                                        <b className="mobile-only">Qty:</b>{" "}
                                        {product.qty}
                                      </span>
                                    </Col>

                                    <Col
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="table-col"
                                    >
                                      <span>
                                        <b className="mobile-only">Price:</b>{" "}
                                        {product.price}
                                      </span>
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="table-col"
                                    >
                                      <span>
                                        <b className="mobile-only">
                                          Sub Total:
                                        </b>
                                        ${product.sub_total}
                                      </span>
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="table-col"
                                    >
                                      <span>
                                        <b className="mobile-only">GST:</b>$
                                        {product.gst}
                                      </span>
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="table-col"
                                    >
                                      <span>
                                        <b className="mobile-only">Total:</b>$
                                        {product.sub_total}
                                      </span>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </>
                        ) : null}
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
              </>
            );
          })}
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockPortalApprovedOrdersScreenQuotes, {
    fallback: <ErrorFallbackComponent />,
  })
);
