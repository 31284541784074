import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import * as Sentry from "@sentry/react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NewStockItemModal from "../VendorMenuEditScreen/NewStockItemModal";

class TruckStockInventory extends Component {
  urlParams = new URLSearchParams(window.location.search);

  storeRoomId = this.urlParams.get("id");
  storeRoomName = this.urlParams.get("storeroom");

  state = {
    vendors: [],
    addItemModal: false,
    vendorId: "",
    storeRoomId: this.storeRoomId,
    storeRoomName: this.storeRoomName,
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isTruckManager:
      getFromLocalStorage("isTruckManager") != "undefined" &&
      getFromLocalStorage("isTruckManager") != "" &&
      getFromLocalStorage("isTruckManager") != null
        ? getFromLocalStorage("isTruckManager")
        : "false",
    isOperatorOperations:
      getFromLocalStorage("isOperatorOperations") != "undefined" &&
      getFromLocalStorage("isOperatorOperations") != "" &&
      getFromLocalStorage("isOperatorOperations") != null
        ? getFromLocalStorage("isOperatorOperations")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    stockList: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    startDate: new Date(),
    endDate: new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    stockCountData: {},
  };

  componentDidMount() {
    //     this.getStockData();
    this.fetchVendorIds();
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.fetchVendorIds();
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  changeQuantity = (id, qty) => {
    if (qty < 0) return;

    const tempStock = { ...this.state.stockCountData };

    tempStock[id] = qty;

    this.setState({ stockCountData: { ...tempStock } });
  };

  fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    this.setState({ isLoading: true });
    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.success === true) {
          this.setState({ vendors: result.stalls ?? [] });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error(error);
        Sentry.captureException(error);
      });
  };

  getStockData = (vendorId) => {
    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_truck_stocklist",
          operator_id: operatorData ? operatorData.id : null,
          vendor_id: Number(vendorId),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        console.log(dataResponse, "REs");
        if (dataResponse.success == true) {
          this.setState({
            stockList: dataResponse.data.map((item) => ({
              ...item,
              stock_count: item.stock_count ? item.stock_count : 0,
            })),
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  handleSaveQty = () => {
    const stock_data = Object.keys(this.state.stockCountData)
      ?.filter((key) => {
        return (
          this.state.stockCountData[key] !==
          this.state.stockList.find((item) => item.id === Number(key))
            ?.stock_count
        );
      })
      ?.map((key) => ({
        stock_id: Number(key),
        stock_count: this.state.stockCountData[key],
      }));

    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_stock_count_for_truck",
          operator_id: operatorData ? operatorData.id : null,
          vendor_id: Number(this.state.vendorId),
          stock_data,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          alert("Stock has been updated successfully!");
          this.setState({ stockCountData: {} });
          this.getStockData(this.state.vendorId);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        alert("Something went wrong. Please try again");

        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  setShowModal = (show) => {
    this.setState({ addItemModal: show });
  };

  render() {
    const {
      isOperatorLoggedIn,
      stockUserLoggedIn,
      isTruckManager,
      isOperatorOperations,
    } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {isOperatorLoggedIn === "true" ||
        isTruckManager === "true" ||
        isOperatorOperations === "true"
          ? this.renderHome()
          : (window.location.href = "/operator-login")}
        <Container></Container>
      </div>
    );
  }

  renderNavBar() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      stockUserData,
      isTruckManager,
      isOperatorOperations,
    } = this.state;
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  }

  renderLogin() {
    const { email, password, isEventCoordinatorLoggedIn } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  renderHome() {
    const { stockList, isLoading, pageSelected } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          {!isLoading ? (
            <>
              <NewStockItemModal
                vendorId={Number(this.state.vendorId)}
                showModal={this.state.addItemModal}
                setShowModal={this.setShowModal}
                handleAddItemSuccess={() => {
                  this.getStockData(this.state.vendorId);
                  alert("New Item has been added successfully.");
                }}
              />
              <Container
                style={{
                  borderWidth: 1,
                  borderColor: "grey",
                  borderStyle: "solid",
                  borderRadius: 5,
                  padding: 15,
                }}
              >
                <Row>
                  <Col xs={12} className="header">
                    <h2>Stock Inventory</h2>
                    <h3>
                      Truck Manager{" "}
                      {
                        JSON.parse(getFromLocalStorage("Operator"))[0]
                          .first_name
                      }{" "}
                      {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="transfer-form-item">
                      <label>Select Vendor:</label>
                      <select
                        style={{ flex: 1 }}
                        onChange={(e) => {
                          this.setState({ vendorId: e.target.value });

                          this.getStockData(e.target.value);
                        }}
                        value={this.state.vendorId || ""}
                      >
                        <option value="" disabled>
                          Select Vendor
                        </option>
                        {this.state.vendors.map((vendor, index) => (
                          <option key={index} value={vendor?.user_ptr_id}>
                            {vendor?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!this.state.vendorId ? null : (
                      <Button
                        style={{ marginBottom: 16 }}
                        disabled={!this.state.vendorId}
                        size="large"
                        onClick={() => {
                          this.setState({ addItemModal: true });
                        }}
                      >
                        Add New Stock Item
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="table-header-row">
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Stock ID
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Stock Name
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Quantity Type
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Current Stock Quantity
                  </Col>

                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Price
                  </Col>
                </Row>
                {stockList && stockList.length
                  ? stockList.map((item) => {
                      return (
                        <Accordion key={item.id}>
                          <>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={item.id}
                            >
                              <Row className="table-row">
                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  {item.id}
                                </Col>

                                <Col
                                  xs={12}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                >
                                  {item.name}
                                </Col>
                                <Col
                                  xs={12}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                >
                                  {item.qty_type__qty_type}
                                </Col>

                                <Col xs={6} md={3} lg={3} className="table-col">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      style={{ padding: 4, borderRadius: 0 }}
                                      variant="primary"
                                      aria-label="add"
                                      size="small"
                                      onClick={() => {
                                        this.changeQuantity(
                                          item.id,
                                          (this.state.stockCountData.hasOwnProperty(
                                            item.id
                                          ) &&
                                          this.state.stockCountData[item.id] !==
                                            item.stock_count
                                            ? this.state.stockCountData[item.id]
                                            : item.stock_count) + 1
                                        );
                                      }}
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </Button>

                                    <input
                                      style={{
                                        padding: 4,
                                        textAlign: "center",
                                      }}
                                      value={
                                        this.state.stockCountData.hasOwnProperty(
                                          item.id
                                        ) &&
                                        this.state.stockCountData[item.id] !==
                                          item.stock_count
                                          ? this.state.stockCountData[item.id]
                                          : item.stock_count
                                      }
                                      onChange={(e) => {
                                        this.changeQuantity(
                                          item.id,
                                          Number(e.target.value)
                                        );
                                      }}
                                    />

                                    <Button
                                      style={{ padding: 4, borderRadius: 0 }}
                                      variant="primary"
                                      aria-label="remove"
                                      size="small"
                                      onClick={() => {
                                        this.changeQuantity(
                                          item.id,
                                          (this.state.stockCountData.hasOwnProperty(
                                            item.id
                                          ) &&
                                          this.state.stockCountData[item.id] !==
                                            item.stock_count
                                            ? this.state.stockCountData[item.id]
                                            : item.stock_count) - 1
                                        );
                                      }}
                                    >
                                      <RemoveIcon fontSize="inherit" />
                                    </Button>
                                  </div>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  {item.price}
                                </Col>
                              </Row>
                              {/* <Accordion.Collapse
                          eventKey={item.id}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Bump In Details:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.bump_in_date}: {item.bump_in_start_time} -{" "}
                                {item.bump_in_end_time}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Bump Out Details:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.bump_out_date}: {item.bump_out_start_time}{" "}
                                - {item.bump_out_end_time}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Site Contact
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.site_contact} - {item.site_contact_number}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Power Arrengments:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.power}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Additional Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.other_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Multiple Day Trading Options
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.multiple_day_trading_options}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Multiple Day Trading Options Time
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.multiple_day_trading_time_options}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Beverage Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.beverage_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.first_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.first_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.first_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.second_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.second_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.second_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.third_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.third_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.third_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Voucher Notes
                              </Col>
                              <Col xs={6} md={6   } lg={6}>
                                {item.vouchers_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Truck Manager:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.truck_manager}
                              </Col>
                            </Row>
                            <Row>
                          
                              <Col xs={6} md={6} lg={6}>
                                <Button
                                  variant="primary"
                                  onClick={() => this.emailRunSheet(item)}
                                >
                                  Email Run Sheet
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              {item.vendor__logo != null ? (
                                <Col xs={2} md={2} lg={2}>
                                  <img
                                    style={{ width: "100%" }}
                                    src={AWS_URL + "/" + item.vendor__logo}
                                    alt="Vendor Logo"
                                  />
                                </Col>
                              ) : null}
                            </Row>

                            {item.payment_status == "complete" ? (
                              <>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Source
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_source}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Receipt Number
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_receipt_number}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Date
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_date}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Time
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_time}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Total Amount
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    $
                                    {Number(item.payment_total_amount).toFixed(
                                      2
                                    )}
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                          </>
                        </Accordion.Collapse> */}
                            </Accordion.Toggle>
                          </>
                        </Accordion>
                      );
                    })
                  : null}
                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                  <Col xs={2} md={2} lg={2}>
                    <p>
                      <b>Showing Page:</b>
                    </p>
                  </Col>
                  <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                    <div style={{ width: "98%" }}>
                      <Pagination>
                        <Pagination.Prev
                          onClick={() => {
                            this.setState(
                              { pageSelected: this.state.pageSelected - 1 },
                              () => {
                                this.getStockData(this.state.vendorId);
                              }
                            );
                          }}
                        />
                        <Pagination.Item active>{pageSelected}</Pagination.Item>
                        <Pagination.Next
                          onClick={() => {
                            this.setState(
                              { pageSelected: this.state.pageSelected + 1 },
                              () => {
                                this.getStockData(this.state.vendorId);
                              }
                            );
                          }}
                        />
                      </Pagination>
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    disabled={!stockList || !stockList.length}
                    size="large"
                    variant="success"
                    onClick={this.handleSaveQty}
                  >
                    Save Changes
                  </Button>
                </div>
              </Container>
              <Footer />
            </>
          ) : (
            <div style={{ textAlign: "center" }}>
              <img src={require("../../assets/img/loading.gif")}></img>
            </div>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(TruckStockInventory, {
    fallback: <ErrorFallbackComponent />,
  })
);
