import {
  store,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import { EMAIL_ORDER_NOTIFICATIONS } from "../../assets/Constants";
import * as Sentry from "@sentry/react";
export const sendEmailConfirmation = async (
  dishes,
  ticketState,
  sub_total,
  discount,
  total
) => {
  var selectedEvent = JSON.parse(getFromLocalStorage("selectedEvent"));
  const { appReducer } = store.getState();
  const {
    orderDate,
    orderTime,
    paymentReceiptNumber,
    username,
    email,
  } = appReducer;
  var serviceFee = getFromLocalStorage("serviceFee");

  let message = "<table style='width:100%;'>";

  // EVENT NAME
  message =
    message +
    "<tr style='width:100%;'><td style='width:60%'>Event Name:</td><td style='width:40%'>" +
    selectedEvent.name +
    "</td></tr>";
  // GIVE EMPTY LINE
  message = message + "<tr><td>&nbsp;</td><td>&nbsp;</td></tr>";
  // EVENT TYPE
  message =
    message +
    "<tr><td>Event Type:</td><td>" +
    selectedEvent.event_type +
    "</td></tr>";
  // GIVE EMPTY LINE
  message = message + "<tr><td>&nbsp;</td><td>&nbsp;</td></tr>";

  //DATE AND TIME
  message = message + "<tr><td>Event Date:</td><td>" + orderDate + "</td></tr>";
  message = message + "<tr><td>Event Time:</td><td>" + orderTime + "</td></tr>";
  // GIVE EMPTY LINE
  message = message + "<tr><td>&nbsp;</td><td>&nbsp;</td></tr>";

  // MENU ITEMS
  for (var key in dishes) {
    //MEAL NAME
    message = message + "<tr><td>" + dishes[key].name;

    //SUBITEMS IF ANY
    if (
      dishes[key] &&
      dishes[key].subitem &&
      dishes[key].subitem.length > 0 &&
      dishes[key].subitem[0] != null
    ) {
      message =
        message +
        '\n<span style="font-size:12px">' +
        dishes[key].subitem +
        "</span>";
    }

    // MEAL QUANTITY
    message =
      message +
      "</td><td>x" +
      dishes[key].quantity +
      "</td><td>$" +
      dishes[key].price +
      "</td></tr>";

    // MEAL COMBO IF ANY
    if (dishes[key] && dishes[key].combo)
      dishes[key].combo.split(",").forEach((comboItem) => {
        if (comboItem.length > 0) {
          message =
            message +
            "<tr><td>+  " +
            comboItem +
            "</td><td>x" +
            dishes[key].quantity +
            "</td><td>" +
            "</td></tr>";
        }
      });

    // MEAL SPECIAL REQUEST
    if (dishes[key] && dishes[key].specialrequest) {
      message =
        message +
        "<tr><td>Special Request:</td><td>&nbsp;</td><td>" +
        dishes[key].specialrequest +
        "</td></tr>";
    }
    message =
      message + "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>";
  }

  // TICKETS ORDERED IF ANY
  for (var key in ticketState) {
    message = message + "<tr><td>Event Ticket</td>";
    message =
      message +
      "<td>x" +
      ticketState[key].quantity +
      "</td><td>$" +
      ticketState[key].ticketPrice +
      "</td></tr>";
    // cartEventTicket={"event":selectedEvent.id,"quantity":ticketState[key].quantity,"one_ticket_price":ticketState[key].ticketPrice,"payment_total_amount":payment_total_amount}
  }

  //SUB TOTAL
  message =
    message +
    "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Sub Total:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
    Number(sub_total).toFixed(2) +
    "</b></td></tr>";

  //SERVICE FEE IF ANY
  if (Number(serviceFee) > 0) {
    message =
      message +
      "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Service Fee:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
      Number(serviceFee).toFixed(2) +
      "</b></td></tr>";
  }
  //DISCOUNT IF ANY
  if (Number(discount) > 0) {
    message =
      message +
      "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Discount:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
      Number(discount).toFixed(2) +
      "</b></td></tr>";
  }

  //TOTAL
  message =
    message +
    "<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td style='color:\"black\"'><b>Total:</b></td><td>&nbsp;</td><td style='color:\"black\"'><b>$" +
    Number(total).toFixed(2) +
    "</b></td></tr>";

  // RECEIPT NUMBER
  message =
    message +
    "<tr><td style='color:\"black\"'><b>Receipt Number:</b></td><td style='color:\"black\"'><b>" +
    paymentReceiptNumber +
    "</b></td></tr></table>";

  let data = {
    method: "POST",
    body: JSON.stringify({
      key: "KqogytxmJIhoq_Cd3Wxqmg",
      template_name: "v-2-2020-wtt-app-invoices-do-not-alter-hg",
      template_content: [
        {
          name: "YOUR_MERGETAG",
          content: "<p>Testing</p>",
        },
      ],
      message: {
        html: "<p>Example HTML content</p>",
        text: "Example text content",
        subject: "Where The Truck Invoice",
        from_email: "noreply@wherethetruck.at",
        from_name: "Where The Truck",
        to: [
          {
            email: email ? email : EMAIL_ORDER_NOTIFICATIONS,
            name: username,
            type: "to",
          },
        ],
        headers: {
          "Reply-To": "noreply@wherethetruck.at",
        },
        important: false,
        track_opens: null,
        track_clicks: null,
        auto_text: null,
        auto_html: null,
        inline_css: null,
        url_strip_qs: null,
        preserve_recipients: null,
        view_content_link: null,
        bcc_address: email ? EMAIL_ORDER_NOTIFICATIONS : null,
        tracking_domain: null,
        signing_domain: null,
        return_path_domain: null,
        merge: true,
        merge_language: "mailchimp",
        global_merge_vars: [
          {
            name: "YOUR_MERGETAG",
            content: message,
          },
        ],
      },
      async: false,
      ip_pool: "Main Pool",
      send_at: "2020-03-17 11:00:00",
    }),
  };

  return fetch(
    "https://mandrillapp.com/api/1.0/messages/send-template.json",
    data
  )
    .then((response) => response.json())
    .then((emailResponse) => {})
    .catch((error) => {
      Sentry.captureException(error);
      console.error("Email Response Error ", error);
    });
};
