import React, { useState } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

function UploadButton() {
  const [liability_certificate, set_liability_certificate] = useState(null);
  const [electrical_certificate, set_electrical_certificate] = useState(null);
  const [gas_certificate, set_gas_certificate] = useState(null);
  const [food_registration_docs, set_food_registration_docs] = useState(null);
  const [other_uploads, set_other_uploads] = useState(null);
  const [
    electrical_certificate_expiry_date,
    set_electrical_certificate_expiry_date,
  ] = useState(new Date());
  const [
    liability_certificate_expiry_date,
    set_liability_certificate_expiry_date,
  ] = useState(new Date());
  const [
    gas_certificate_expiry_date,
    set_gas_certificate_expiry_date,
  ] = useState(new Date());
  const [
    food_registration_certificate_expiry_date,
    set_food_registration_certificate_expiry_date,
  ] = useState(new Date());
  const [other_uploads_expiry_date, set_other_uploads_expiry_date] = useState(
    new Date()
  );

  const UploadDocs = async (values) => {
    values.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "upload_docs",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          electrical_certificate: electrical_certificate
            ? electrical_certificate.name
            : electrical_certificate,
          electrical_certificate_expiry_date: moment(
            electrical_certificate_expiry_date
          ).format("DD MMM YYYY"),
          liability_certificate: liability_certificate
            ? liability_certificate.name
            : liability_certificate,
          liablity_certificate_expiry_date: moment(
            liability_certificate_expiry_date
          ).format("DD MMM YYYY"),
          gas_certificate: gas_certificate
            ? gas_certificate.name
            : gas_certificate,
          gas_certificate_expiry_date: moment(
            gas_certificate_expiry_date
          ).format("DD MMM YYYY"),
          food_registration_certificate: food_registration_docs
            ? food_registration_docs.name
            : food_registration_docs,
          food_registration_certificate_expiry_date: moment(
            food_registration_certificate_expiry_date
          ).format("DD MMM YYYY"),
          other_uploads: other_uploads ? other_uploads.name : other_uploads,
          other_uploads_expiry_date: moment(other_uploads_expiry_date).format(
            "DD MMM YYYY"
          ),
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Documents could not be uploaded please try again!");
          window.location.reload();
        } else {
          alert("Documents could not be uploaded please try again!");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Documents was uploaded Successfully!");
        window.location.reload();
      });
  };
  const handleFileChange1 = (event) => {
    set_liability_certificate(event.target.files[0]);
  };

  const handleFileChange2 = (event) => {
    set_electrical_certificate(event.target.files[0]);
  };
  const handleFileChange3 = (event) => {
    set_gas_certificate(event.target.files[0]);
  };
  const handleFileChange5 = (event) => {
    set_other_uploads(event.target.files[0]);
  };
  const handleFileChange4 = (event) => {
    set_food_registration_docs(event.target.files[0]);
  };

  return (
    <div className="form-style-5">
      <form onSubmit={UploadDocs}>
        <div>
          <legend>
            <span className="number">
              Public and Product liability Certificate*:
            </span>
          </legend>
          <label htmlFor="laibility_certificate">
            Upload your Public and Product liability Certificate*:
          </label>
          <input
            type="file"
            id="liability_certificate"
            name="liability_certificate"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileChange1}
          />
        </div>
        <p>Expiry Date</p>
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={liability_certificate_expiry_date}
          onChange={(date) => set_liability_certificate_expiry_date(date)}
        />
        <div>
          <legend>
            <span className="number">Electrical Certificate</span>
          </legend>
          <label htmlFor="electrical_certificate">
            Upload your Electrical Certificate:
          </label>
          <input
            type="file"
            id="electrical_certificate"
            name="electrical_certificate"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileChange2}
          />
          <p>Expiry Date</p>
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={electrical_certificate_expiry_date}
            onChange={(date) => set_electrical_certificate_expiry_date(date)}
          />
        </div>
        <div>
          <legend>
            <span className="number">Gas Certificate</span>
          </legend>
          <label htmlFor="gas_certificate">Upload your Gas Certificate:</label>
          <input
            type="file"
            id="gas_certificate"
            name="gas_certificate"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileChange3}
          />
          <p>Expiry Date</p>
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={gas_certificate_expiry_date}
            onChange={(date) => set_gas_certificate_expiry_date(date)}
          />
        </div>
        <div>
          <legend>
            <span className="number">Food Registration Certificate*</span>
          </legend>
          <label htmlFor="food_registration_docs">
            Upload your Food Registration Certificate*:
          </label>
          <input
            type="file"
            id="food_registration_docs"
            name="food_registration_docs"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileChange4}
          />
          <p>Expiry Date</p>
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={food_registration_certificate_expiry_date}
            onChange={(date) =>
              set_food_registration_certificate_expiry_date(date)
            }
          />
        </div>
        <div>
          <legend>
            <span className="number">Other Uploads</span>
          </legend>
          <label htmlFor="other_uploads">Other Certificates</label>
          <input
            type="file"
            id="other_uploads"
            name="other_uploads"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileChange5}
          />
        </div>
        <p>Expiry Date</p>
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={other_uploads_expiry_date}
          onChange={(date) => set_other_uploads_expiry_date(date)}
        />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}

export default Sentry.withErrorBoundary(UploadButton, {
  fallback: <ErrorFallbackComponent />,
});
