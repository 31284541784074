import React, { useState } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage } from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, Divider, Switch } from "@mui/material";
import { useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { format } from "date-fns";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

const PostEventChecklist = () => {
  const operatorData = JSON.parse(getFromLocalStorage("Operator") ?? [])[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isStockClosed, setIsStockClosed] = useState(false);
  const [staffClockOff, setStaffClockOff] = useState(false);
  const [truckCleaned, setTruckCleaned] = useState(false);
  const [anyIncidents, setAnyIncidents] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [vendors, setVendors] = useState([]);

  const [fetchedEvents, setFetchedEvents] = useState([]);

  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(false);
  const [isTruckManager, setIsTruckManager] = useState(false);
  const [isOperatorOperations, setIsOperatorOperations] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      isStockClosed: false,
      videoOfTheTruck: null,
      staffClockOff: false,
      truckCleaned: false,
      anyIncidents: false,
      operatorId: null,
    },
  });

  const fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchVendorIds();
  }, []);

  const getAllEvents = (vendorId) => {
    setIsLoading(true);
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_past_events_for_operators",
          vendor_id: Number(vendorId),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          setFetchedEvents(dataResponse?.data ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const onSubmit = async (data) => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const formData = new FormData();
    formData.append("operator_id", operatorId);
    formData.append("timestamp", format(new Date(), "dd MMM yyyy"));
    formData.append("vendor_id", vendorId);
    formData.append("event_id", eventId);
    formData.append("is_closing_stock_done", isStockClosed);
    formData.append("did_all_the_staff_clock_off", staffClockOff);
    formData.append("was_the_truck_cleaned", truckCleaned);
    formData.append("any_incidents_happened", anyIncidents);
    if (!data.videoOfTheTruck) {
      alert("Please upload the video of the truck to continue.");
      return;
    }

    function appendFileField(key, fieldName) {
      if (data[fieldName] instanceof FileList && data[fieldName].length > 0) {
        const file = data[fieldName][0];
        const modifiedFile = new File(
          [file],
          `${fieldName}+ ${JSON.parse(getFromLocalStorage("Operator"))[0].id}`,
          {
            type: file.type,
          }
        );
        formData.append(key, modifiedFile);
      }
    }

    appendFileField("post_event_checklist_video", "videoOfTheTruck");

    try {
      const response = await axios.post(
        config.BASE_URI + "/api/v1" + apiPaths.postEventsChecklist,
        formData
      );

      if (response.data.success === true) {
        alert("Post Event Checklist Details have been submitted successfully!");
        window.location.reload();
      } else {
        alert(
          "Post Event Checklist Details could not be added. Please try again."
        );
      }
    } catch (error) {
      console.log(error);
      alert("Vendor Details could not be added. Please try again.");
    }
  };

  const renderNavBar = () => {
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  };

  return (
    <>
      {renderNavBar()}
      {!isLoading ? (
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Typography>Post Event Checklist Form </Typography>
          <div>
            <label>Select Vendor:</label>
            <select
              onChange={(e) => {
                getAllEvents(e.target.value);
                setVendorId(e.target.value);
              }}
              value={vendorId || ""}
            >
              <option value="">Select Vendor</option>
              {vendors.map((vendor, index) => (
                <option key={index} value={vendor?.user_ptr_id}>
                  {vendor?.title}
                </option>
              ))}
            </select>

            {vendorId && (
              <div>
                <label>Select Event:</label>
                <select
                  onChange={(e) => {
                    setEventId(e.target.value);
                  }}
                  value={eventId || ""}
                >
                  <option value="">Select Event</option>
                  {fetchedEvents.map((event, index) => (
                    <option key={index} value={event?.id}>
                      {event?.name || ""}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <Box>
            {vendorId && eventId ? (
              <form
                className="event-form-container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  Note: Please fill all the fields marked as{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Divider />

                <Divider />
                <div className="event-form-item">
                  <label htmlFor="logo" className="event-form-control">
                    Has Closing Stock been done?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={isStockClosed}
                    onChange={(e) => {
                      setIsStockClosed(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="video" className="event-form-control">
                    Upload the video of the truck{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="event-form-control"
                    type="file"
                    accept=".mp4, .mov, .avi"
                    id="video"
                    name="video"
                    {...register("videoOfTheTruck")}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="logo" className="event-form-control">
                    Did Everyone Clock Off{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={staffClockOff}
                    onChange={(e) => {
                      setStaffClockOff(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="logo" className="event-form-control">
                    Was the Truck cleaned?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={truckCleaned}
                    onChange={(e) => {
                      setTruckCleaned(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="logo" className="event-form-control">
                    Did any Incidents happen?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={anyIncidents}
                    onChange={(e) => {
                      setAnyIncidents(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <button className="form-button" type="submit">
                  Submit
                </button>
              </form>
            ) : null}
          </Box>
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={require("../../assets/img/loading.gif")}></img>
        </div>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(PostEventChecklist, {
  fallback: <ErrorFallbackComponent />,
});
