import React from "react";
import "./style1.css";
import "./style.css";
import Footer from "../../components/UI/Footer";
import { useRef, useState, useEffect, useCallback, memo } from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { Button } from "react-bootstrap";
import ServiceItem from "./ServiceItem";
import YoutubeEmbed from "../EOIVideo";
const KioskLanding = memo(() => {
  const [active, setActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setActive(!active);
    setIsActive(!isActive);
  };
  const [showForm, setShowForm] = useState(false);
  const [input, setInput] = useState("");
  const inputHandler = (e) => {
    setInput(e.target.value);
  };
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   if (input) {
  //     console.log(input);
  //     let data = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         key: "KqogytxmJIhoq_Cd3Wxqmg",
  //         template_name: "vendor-intro",
  //         template_content: [
  //           {
  //             name: "YOUR_MERGETAG",
  //             content: "<p>Testing</p>",
  //           },
  //         ],
  //         message: {
  //           subject: "Welcome to Where The Truck",
  //           from_email: "noreply@wherethetruck.at",
  //           from_name: "Where The Truck",
  //           to: [
  //             {
  //               email: input ? input : "aaradhanah@amfvg.com.au",
  //               type: "to",
  //             },
  //           ],
  //           headers: {
  //             "Reply-To": "noreply@wherethetruck.at",
  //           },
  //           important: false,
  //           track_opens: null,
  //           track_clicks: null,
  //           auto_text: null,
  //           auto_html: null,
  //           inline_css: null,
  //           url_strip_qs: null,
  //           preserve_recipients: null,
  //           view_content_link: null,
  //           bcc_address: null,
  //           tracking_domain: null,
  //           signing_domain: null,
  //           return_path_domain: null,
  //           merge: true,
  //           merge_language: "mailchimp",
  //           global_merge_vars: [
  //             {
  //               name: "YOUR_MERGETAG",
  //               content: "Hello, this is the content of the email!",
  //             },
  //           ],
  //         },
  //         async: false,
  //         ip_pool: "Main Pool",
  //       }),
  //     };
  //     return fetch(
  //       "https://mandrillapp.com/api/1.0/messages/send-template.json",
  //       data
  //     )
  //       .then((response) => handleFormClose())
  //       .then((emailResponse) => {
  //         handleFormClose();
  //       })
  //       .catch((error) => {
  //         Sentry.captureException(error);
  //         console.error("Email Response Error ", error);
  //         handleFormClose();
  //       });
  //   }
  // };

  // const handleFormClose = () => {
  //   setShowForm(false);
  // };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowForm(true);
  //   }, 1000); // Show modal after 10 seconds

  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <>
      <div className="whole">
        <div className="whole-html">
          <section id="header">
            <div className="header containerl">
              <div className="nav-bar">
                <div className="brand">
                  <a href="#hero">
                    <h1 style={{ fontSize: "2em" }}>
                      <span>Home</span>
                    </h1>
                  </a>
                </div>
                <div className="nav-list">
                  <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                  </div>
                  <ul className={active ? "active" : ""}>
                    <li>
                      <a
                        href="#services"
                        data-after="Service"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        About
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="#solutions"
                        data-after="Solution"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Solutions
                      </a>
                    </li>
                    <li>
                      <a
                        href="#projects"
                        data-after="Projects"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Subscription
                      </a>
                    </li>
                    <li>
                      <a
                        href="/operator-login"
                        data-after="About"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        href="/register"
                        data-after="Contact"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Register
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* <Modal /> */}
          {/* <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showForm}
            onClose={handleFormClose}
            className="modalnvm"
          >
            <Box className="modalnvm-content">
              <Container>
                <Form onSubmit={submitHandler}>
                  <CloseIcon
                    fontSize="large"
                    style={{ float: "right" }}
                    onClick={handleFormClose}
                  />
                  <H2>Subscribe to our newsletter</H2>
                  <p>
                    Stay in the loop with what's trucking! Get new offers and
                    discounts from WTT in your inbox.
                  </p>
                  <Input type="email" onChange={inputHandler} />
                  <Button style={{ margin: 10 }} onClick={submitHandler}>
                    Submit
                  </Button>
                </Form>
              </Container>
            </Box>
          </Modal> */}
          <section id="hero">
            <div class="hero containerl">
              <div></div>
            </div>
          </section>
          <YoutubeEmbed embedId="_nPCF6PnuWE" />
          <section id="services">
            <div class="services containerl">
              <div class="service-top">
                <h1 class="section-title">
                  <span>Why Queue Skipper?</span>
                </h1>
                <p className="pl">
                  Where the truck consistently stive to improve our industry and
                  support our subscribing vendors and event organizer clients
                  with new efficiencies and opportunities. As a food truck
                  vendor the WhereTheTruck Queue Skipper will offer you the
                  below benefits:
                </p>
              </div>

              <div class="service-bottom">
                <ServiceItem
                  iconSrc={require("../../assets/img/logo-round.png")}
                  text="No More Long Lines: Say goodbye to waiting! Our kiosk streamlines the ordering process, letting you enjoy more of what you love – delicious food."
                />
                <ServiceItem
                  iconSrc={require("../../assets/img/logo-round.png")}
                  text="Effortless Ordering: With an intuitive touch interface,
                  finding and ordering your favorite dishes has never been
                  easier or faster."
                />
                <ServiceItem
                  iconSrc={require("../../assets/img/logo-round.png")}
                  text="Cut Operational Costs: Reduce the need for additional
                  staffing without compromising customer service. QuickQueue
                  is like having an extra pair of hands during those busy
                  rushes!"
                />
                <ServiceItem
                  iconSrc={require("../../assets/img/logo-round.png")}
                  text="Enhanced Customer Experience:With quick, seamless ordering,
                  your customers get to experience the joy of street food
                  without the wait, turning first-time visitors into regulars."
                />
              </div>
            </div>
          </section>

          <Footer />
          {/* <script src="./app.js"></script> */}
        </div>
      </div>
    </>
  );
});
const Div = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #414345, #232526);
  overflow: hidden;
`;
const Container = styled.div`
  position: relative;
`;
const Form = styled.form`
  position: relative;
  padding: 3rem;
  min-width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 30px #333;
  background: rgba(225, 225, 225, 0.2);
  background-clip: padding-box;
  backdrop-filter: blur(10px);
  z-index: 2;
  background-color: #ef3e6d;
`;
const H2 = styled.h2`
  color: #000;
  padding: 1rem;
  padding-left: 20%;
  text-aligh: center;
  font-size: 2rem;
`;
const P = styled.p`
  color: #000;
  padding: 1rem;
  padding-left: 20%;
  text-aligh: center;
  font-size: 1rem;
`;
const Input = styled.input`
  padding: 10px;
  border-radius: 10px 0 0 10px;
  border: none;
  width: 80%;
  outline: none;
  background: #f3f1ef;
`;

export default KioskLanding;
