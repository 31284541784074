import * as React from 'react';
import { Form} from 'react-bootstrap';

const Textfield = (props) => {
    const {label, defaultValue, formStateHandler, formTouchedHandler, fieldName} = props;
    return (
       <Form.Control
            type={"text"}
            label = {label}
            defaultValue = {defaultValue}
            onChange = {(event) => {formStateHandler(fieldName, event.target.value)}}
            onClick={() => formTouchedHandler(fieldName, true)}
        />
       

    )
}

export default Textfield;