import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";

class AdminDailyQuotes extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    email: "",
    password: "",
    emails: [],
    searchVendor: "",
    tempEmailList: [],
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn == "true") {
      this.getEmailsToVendor();
    } else {
      window.location.href = "/admin";
    }
  }

  getEmailsToVendor = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_emails_to_customer",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse.emails_to_customer);
          function filterEmailsSentTwoDaysBack(data) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const twoDaysAgo = new Date(currentDate);
            twoDaysAgo.setDate(currentDate.getDate() - 32);
            const filteredEmails = data.filter((email) => {
              const sentDate = new Date(email.sent_on);
              sentDate.setHours(0, 0, 0, 0);
              return sentDate.getTime() === twoDaysAgo.getTime();
            });
            return filteredEmails;
          }
          const filteredEmails = filterEmailsSentTwoDaysBack(
            dataResponse.emails_to_customer
          );
          console.log(filteredEmails);
          this.setState({
            emails: filteredEmails,
            tempEmailList: filteredEmails,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };
  handleCheckboxChange = (index) => {
    const updatedEmails = this.state.emails.map((email, i) => {
      if (i === index) {
        return { ...email, is_contacted: !email.is_contacted };
      }
      return email;
    });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      emails,
      searchVendor,
      handleCheckboxChange,
    } = this.state;
    var emailList = this.state.emails;

    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>

        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <h4>Emails Sent to Customers</h4>
          <>
            <InputGroup className="mb-3">
              <InputGroup.Text value={searchVendor}>
                <img
                  style={{ width: 20 }}
                  src={require("../../assets/img/search.gif")}
                ></img>
              </InputGroup.Text>
              <FormControl
                aria-label="Search for vendors"
                onChange={(e) => {
                  var searchString = e.target.value;
                  if (searchString == "") {
                    this.setState({ emails: this.state.tempEmailList });
                  } else {
                    var result = emailList.filter((obj) => {
                      if (
                        obj.vendor__title.search(
                          new RegExp(searchString, "i")
                        ) != -1
                      ) {
                        return obj;
                      }
                    });
                    this.setState({ emails: result });
                  }
                }}
              />
            </InputGroup>
            <Row className="table-header-row">
              <Col xs={12} md={1} lg={1} className="table-header-col">
                SR No.
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Customer Name
              </Col>
              <Col xs={12} md={3} lg={3} className="table-header-col">
                Customer Email
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Customer Phone
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Customer Address
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Email Sent On
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Is Paid
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Is Contacted
              </Col>
            </Row>
            {emails.map((item, i) => {
              return (
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Sr No:</b> {item.id}
                            </span>
                          </Col>
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Customer Name:</b>{" "}
                              {item.booking_request__name}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Customer Email:</b>{" "}
                              {item.booking_request__email}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Customer Phone:</b>{" "}
                              {item.booking_request__phone}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only"> Customer Address:</b>{" "}
                              {item.booking_request__location}
                            </span>
                          </Col>
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Email Sent On:</b>{" "}
                              {item.sent_on}
                            </span>
                          </Col>
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Is Paid:</b>{" "}
                              {item.booking_details.payment_status !==
                              "incomplete"
                                ? "Yes"
                                : "No"}
                            </span>
                          </Col>
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Is Contacted:</b>{" "}
                              <input
                                type="checkbox"
                                checked={item.is_contacted}
                                onChange={() => {
                                  if (item.is_contacted === false) {
                                    var url =
                                      config.BASE_URI + apiPaths.adminData;
                                    var myHeaders = new Headers();
                                    myHeaders.append(
                                      "Content-Type",
                                      "application/json"
                                    );

                                    var raw = JSON.stringify({
                                      payload: {
                                        body: {
                                          query_type:
                                            "update_quote_send_to_customer_status",
                                          id: item.id,
                                          is_contacted: true,
                                        },
                                      },
                                    });

                                    var requestOptions = {
                                      method: "POST",
                                      headers: myHeaders,
                                      body: raw,
                                      redirect: "follow",
                                    };

                                    fetch(url, requestOptions)
                                      .then((response) => response.json())
                                      .then((dataResponse) => {
                                        if (dataResponse.success === true) {
                                          console.log(
                                            "Contact status updated successfully"
                                          );
                                          window.location.reload();
                                          // Here, you might want to update the local state to reflect the change on the UI
                                        } else {
                                          console.error(
                                            "Failed to update contact status"
                                          );
                                        }
                                      })
                                      .catch((error) => {
                                        Sentry.captureException(error);
                                        console.error(error);
                                        alert("Something went wrong");
                                      });
                                  }
                                }}
                              />
                            </span>
                          </Col>
                        </Row>
                        <Accordion.Collapse
                          eventKey={item.id}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Booking Request ID
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.id}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Cuisine
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.cuisine}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Type
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.event_type}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Number Of Guests
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.number_of_guests}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Date
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.start_date +
                                  " " +
                                  item.booking_details.end_date}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Time
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.start_time +
                                  " " +
                                  item.booking_details.end_time}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Location
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.location}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Name
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.name}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Phone
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.booking_details.phone}
                              </Col>
                            </Row>
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>
                  </>
                </Accordion>
              );
            })}
          </>
          <div style={{ position: "relative" }}>
            <Button style={{ position: "absolute", top: 0, right: 0 }}>
              Update
            </Button>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminDailyQuotes, {
    fallback: <ErrorFallbackComponent />,
  })
);
