import React, { forwardRef, useEffect, useRef, useState } from "react";

const ImageHighlighter = forwardRef(({ imgSrc }, ref) => {
  const [circles, setCircles] = useState([]);
  const [image, setImage] = useState(new Image());

  // Load the image
  useEffect(() => {
    const img = new Image();
    img.src = imgSrc; // Replace with your image path
    img.onload = () => {
      setImage(img);
      resizeCanvas();
    };
  }, []);

  // Resize the canvas based on its parent container
  const resizeCanvas = () => {
    const canvas = ref.current;
    if (canvas) {
      canvas.width = canvas.parentElement.offsetWidth;
      canvas.height = canvas.parentElement.offsetHeight;
      drawCanvas();
    }
  };

  // Draw the image and circles on the canvas
  const drawCanvas = () => {
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate aspect ratio
    const imgAspectRatio = image.width / image.height;
    const canvasAspectRatio = canvas.width / canvas.height;

    let drawWidth, drawHeight;

    if (canvasAspectRatio > imgAspectRatio) {
      // Canvas is wider than the image
      drawHeight = canvas.height;
      drawWidth = drawHeight * imgAspectRatio;
    } else {
      // Canvas is taller than the image
      drawWidth = canvas.width;
      drawHeight = drawWidth / imgAspectRatio;
    }

    // Draw the image centered
    const xOffset = (canvas.width - drawWidth) / 2;
    const yOffset = (canvas.height - drawHeight) / 2;
    ctx.drawImage(image, xOffset, yOffset, drawWidth, drawHeight);

    // Draw all circles
    circles.forEach((circle) => drawCircle(ctx, circle.x, circle.y));
  };

  // Draw a circle on the canvas
  const drawCircle = (ctx, x, y) => {
    ctx.beginPath();
    ctx.arc(x, y, 20, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(255,255,0,0.6)";
    ctx.fill();
  };

  // Handle canvas click to add a circle
  const handleCanvasClick = (e) => {
    const canvas = ref.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setCircles((prevCircles) => [...prevCircles, { x, y }]);
  };

  // Undo the last circle
  const handleUndo = () => {
    setCircles((prevCircles) => {
      const newCircles = prevCircles.slice(0, -1);
      return newCircles;
    });
  };

  // Resize the canvas when the window is resized
  useEffect(() => {
    const handleResize = () => {
      resizeCanvas();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Redraw the canvas whenever circles change
  useEffect(() => {
    drawCanvas();
  }, [circles, image]);

  return (
    <>
      <canvas
        ref={ref}
        onClick={handleCanvasClick}
        style={{ border: "1px solid black", width: "100%", height: "100%" }}
      />
      <div
        onClick={handleUndo}
        style={{
          marginBottom: "16px",
          padding: 5,
          background: "#115e9c",
          color: "#fff",
          borderRadius: 4,
          display: "inline-block",
        }}
      >
        Undo Highlight
      </div>
    </>
  );
});
export default ImageHighlighter;
