import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Modal,
} from "react-bootstrap";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EOIPage from "../../components/UI/EOI/index";
import { Redirect } from "react-router";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import image from "../../assets/img/wttimg.png";
import mobile_ice from "../../assets/img/MobileIceCreams.png";
import inkredible_calamari from "../../assets/img/inkredible_calamari.png";
import i_love_dumplings from "../../assets/img/iLoveDumplings.png";
import mi_casa from "../../assets/img/Mi_casa.png";
import Benz_On_miller from "../../assets/img/Benz_On_miller.png";
import Kind_of_the_wings from "../../assets/img/Kind_of_the_wings.jpeg";
import Wholly_schnit from "../../assets/img/Wholly_schnit.png";
import DUO_ice_cream from "../../assets/img/DUO_ice_cream.png";
import Mister_Gee_burgers from "../../assets/img/Mister_Gee_burgers.png";
import Flower_Show from "../../assets/img/flowerShow.png";
import Racing_Club from "../../assets/img/racingClub.png";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

class SupplierOverviewScreen extends Component {
  state = {
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    isSuperAdminLoggedIn: "false",
    email: getFromLocalStorage("email"),
    password: getFromLocalStorage("password"),
    isOperatorLoggedIn: "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    total_vouchers_value: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
  };
  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      isOrganiserLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn == "true" ||
      isOperatorLoggedIn == "true" ||
      isOrganiserLoggedIn == "true"
    ) {
      this.getVendorsInEvent();
    }
  }

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };
  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    // if (selectedEventId == 0) {
    //   alert("Please select an event.");
    //   return;
    // }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_orders_new",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
            total_vouchers_value: dataResponse.total_voucher_value,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    // if (selectedEventId == 0) {
    //   alert("Please select an event.");
    //   return;
    // }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_in_event_organiser",
          email: email,
          password: password,
          event: selectedEventId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          let vendorId = "0";
          if (dataResponse.vendors?.length == 1) {
            vendorId = dataResponse.vendors[0].user_ptr_id;
          } else {
            vendorId = "0";
          }

          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);

            this.getOrders();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              isSuperAdminLoggedIn: "" + dataResponse.data.is_super_admin,
              isOperatorLoggedIn: "" + dataResponse.data.is_operator,
              vendorName: dataResponse.data.vendor__title,
            },
            () => {
              saveToLocalStorage(
                "isSuperAdminLoggedIn",
                "" + dataResponse.data.is_super_admin
              );
              saveToLocalStorage(
                "isOperatorLoggedIn",
                "" + dataResponse.data.is_operator
              );
              saveToLocalStorage(
                "vendorName",
                "" + dataResponse.data.vendor__title
              );
              saveToLocalStorage("email", email);
              //saveToLocalStorage("password", password);
              this.getAllEvents();
            }
          );
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };
  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    deleteFromLocalStorage("Operator", "");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/");
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      isOrganiserLoggedIn,
      vendorName,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isOperatorLoggedIn={isOperatorLoggedIn}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></AdminNavBar>
        {isSuperAdminLoggedIn == "false" &&
        isOperatorLoggedIn == "false" &&
        isOrganiserLoggedIn == "false" ? (
          <Redirect to="/organiser" />
        ) : (
          <>
            {this.renderHome()}
            {this.renderOrderStatusChangeModal()}
          </>
        )}
      </>
    );
  }

  renderLogin() {
    const { email, password } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  getEventData() {
    return this.events.filter((e) => e.id === e.event);
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      total_vouchers_value,
    } = this.state;

    if (selectedEvent) {
    }
    let date_range = this.getDateRange(
      selectedEvent.start_date,
      selectedEvent.end_date
    );
    return (
      <div className="all">
        {!isLoading ? (
          <>
            <Container
              className="container-element"
              // style={{
              //   borderWidth: 1,
              //   borderColor: "grey",
              //   borderStyle: "solid",
              //   borderRadius: 5,
              //   padding: 15,
              // }}
            >
              <section class="home" id="home">
                <div class="content">
                  <div class="flex-container">
                    <div class="text-container">
                      <h3 class="mainfont" style={{ color: "#2959a5" }}>
                        Stock Supplier Portal - Streamlining Inventory
                        Management and Collaboration
                      </h3>
                      <h4 class="sidefont" style={{ color: "#2959a5" }}></h4>
                      <p className="paragraph-1" style={{ color: "#2959a5" }}>
                        The Stock Supplier Portal is a powerful and intuitive
                        online platform designed to revolutionize inventory
                        management and foster seamless collaboration between
                        suppliers and businesses.
                      </p>
                      {/* <p className="paragraph-1" style={{ color: "#2959a5" }}>
                        We deliver catering solutions to{" "}
                        <strong>
                          Major public events, sporting events, Music festivals,
                          Carnivals, Corporate events, Weddings, Birthdays,
                          Engagements
                        </strong>
                      </p> */}
                      <Row>
                        <Col>
                          <button
                            className="btn-pink"
                            onClick={() =>
                              window.location.replace(
                                "/stock-catelogue-supplier-list"
                              )
                            }
                          >
                            StockList Catelogue
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="btn-pink righty"
                            onClick={() =>
                              window.location.replace("stock-quotes-pending")
                            }
                          >
                            View Pending Stock Quote Requests
                          </button>
                        </Col>

                        <Col>
                          <button
                            className="btn-pink righty"
                            onClick={() =>
                              window.location.replace(
                                "stock-portal-past-quotes"
                              )
                            }
                          >
                            View Past Stock Orders
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <div class="text-container">
                      <h4 className="header-4" style={{ color: "#2959a5" }}>
                        Benifits of using Stock Supplier Portal
                      </h4>
                      <ul className="ul-details">
                        <li className="li-details">
                          <button className="paragraph">
                            Seamless Order Processing: The portal facilitates
                            seamless order processing, allowing suppliers to
                            receive purchase orders, update order statuses, and
                            generate invoices all within a centralized system.
                            This eliminates the need for manual paperwork and
                            streamlines the entire order fulfillment process,
                            saving time and reducing the potential for errors.
                            Suppliers can also communicate directly with
                            businesses through the portal, ensuring clear and
                            efficient communication channels for order-related
                            inquiries or updates.
                          </button>
                        </li>
                        <li className="li-details">
                          <button className="paragraph">
                            Collaborative Platform for Communication: Effective
                            communication is crucial for successful
                            supplier-business relationships. The Stock Supplier
                            Portal provides a collaborative platform where
                            suppliers and businesses can interact, exchange
                            messages, and share vital information. Suppliers can
                            address inquiries, provide product updates, or
                            discuss pricing and availability, fostering
                            transparent and streamlined communication. This
                            enhanced collaboration helps build stronger
                            partnerships, leading to improved supply chain
                            efficiency and customer satisfaction.
                          </button>
                        </li>
                        <li className="li-details">
                          <button className="paragraph">
                            Experience the Future of Inventory Management: The
                            Stock Supplier Portal redefines inventory management
                            by providing a comprehensive, user-friendly, and
                            collaborative platform for suppliers to efficiently
                            manage their stock and foster strong relationships
                            with businesses. Embrace the future of inventory
                            management with the Stock Supplier Portal and unlock
                            new levels of efficiency, productivity, and growth
                            in your supply chain operations.
                          </button>
                        </li>
                        {/* <li className="li-details">
                          <button className="paragraph">
                            State-of-the-art WTT POS system: Our advanced WTT
                            POS system serves as a comprehensive data hub,
                            providing deep insights into vendor performance,
                            item mix, and sales timeline. Event organizers can
                            access detailed analytics and reports in real-time,
                            empowering them to make data-driven decisions and
                            optimize their offerings for maximum profitability
                            and customer satisfaction. With our POS system,
                            event organizers have a complete overview of event
                            sales, allowing them to identify trends, monitor
                            performance, and make informed decisions to enhance
                            the event experience.
                          </button>
                        </li> */}
                        {/* <li className="li-details">
                          <button className="paragraph">
                            User-friendly website integration: We offer a
                            seamless user experience by fitting all the
                            essential information about our services into a
                            single screen on our website. This concise yet
                            comprehensive presentation allows event organizers
                            to quickly grasp the key features and benefits we
                            offer, reinforcing their confidence in our ability
                            to conduct their events in the best possible way.
                          </button>
                        </li> */}
                      </ul>
                    </div>
                    {/* <div class="text-container">
                      <h4 style={{ color: "#2959a5" }}>Our Proud Partners</h4>{" "}
                      <p style={{ color: "#2959a5" }}>
                        ... and 1000+ vendors all over Australia
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "0 -10px",
                        }}
                      >
                        <Box
                          component="ul"
                          sx={{
                            display: "flex",
                            gap: 2,
                            flexWrap: "wrap",
                            p: 0,
                            m: 0,
                          }}
                        >
                          <a
                            href="http://mobileicecreams.com/?gclid=CjwKCAjwsvujBhAXEiwA_UXnAOkG9hzxUUihlTK44cD7v3S7shisTmOiqvFyyc3L6PynwvGVJ6kUchoC4f4QAvD_BwE"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img src={mobile_ice} loading="lazy" alt="" />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Mobile Ice Cream
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://www.instagram.com/inkrediblecalamari1/?hl=en"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={inkredible_calamari}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Inkredible Calamari
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="http://www.gourmetfoodtrucks.com.au/about-micasa-food-truck.html"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img src={mi_casa} loading="lazy" alt="" />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Mi casa
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://www.benzonmiller.com.au/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={Benz_On_miller}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Benz On miller
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://www.ilovedumplings.net.au/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={i_love_dumplings}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  I Love Dumplings
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://www.facebook.com/kingofthewingsbrisbane/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={Kind_of_the_wings}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Kind of the wings
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://www.mistergee.com.au/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={Mister_Gee_burgers}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Mister Gee burgers
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://whollyschnit.com.au/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={Wholly_schnit}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Wholly schnit
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a href="https://www.duoduo.com.au/" target="_blank">
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src={DUO_ice_cream}
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  DUO ice cream
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                        </Box>
                      </div>
                    </div> */}
                    {/* <div class="text-container">
                      <h4 style={{ color: "#2959a5" }}>Our Clients</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "0 -10px",
                        }}
                      >
                        <Box
                          component="ul"
                          sx={{
                            display: "flex",
                            gap: 2,
                            flexWrap: "wrap",
                            p: 0,
                            m: 0,
                          }}
                        >
                          <a href="https://ausopen.com/" target="_blank">
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Australian_Open_Logo_2017.svg/1200px-Australian_Open_Logo_2017.svg.png"
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Australia Open
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <a
                            href="https://melbflowershow.com.au/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img src={Flower_Show} loading="lazy" alt="" />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Melbourne Flower Show
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={Racing_Club} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Grand Prix
                              </Typography>
                            </CardContent>
                          </Card>
                          <a
                            href="https://www.vrc.com.au/melbourne-cup-carnival/melbourne-cup-carnival-2023/"
                            target="_blank"
                          >
                            <Card sx={{ minHeight: "280px", width: 320 }}>
                              <CardCover>
                                <img
                                  src="https://d1p9wirkq0k00v.cloudfront.net/wp-content/uploads/2019/12/25030748/victoria-racing-club-1-logo-png-transparent-1.png"
                                  loading="lazy"
                                  alt=""
                                />
                              </CardCover>
                              <CardCover
                                sx={{
                                  background:
                                    "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                                }}
                              />
                              <CardContent sx={{ justifyContent: "flex-end" }}>
                                <Typography
                                  level="h2"
                                  fontSize="lg"
                                  textColor="#fff"
                                  mb={1}
                                >
                                  Melbourne Cup
                                </Typography>
                              </CardContent>
                            </Card>
                          </a>
                        </Box>
                      </div>
                    </div> */}
                    {/* <div class="image">
                    <img src={image} alt="" />
                  </div> */}
                  </div>
                </div>
              </section>
            </Container>
            <footer className="footer">
              <div className="container">
                <div className="row text-center">
                  <div className="col-sm-12 divide-xs">
                    <p>
                      <small>
                        Copyright © 2024 Where The Truck All rights reserved.
                      </small>
                    </p>
                    <p>
                      <small>
                        By using the Where The Truck website, you agree to our{" "}
                        <a
                          href="http://amfvg.com.au/terms-and-conditions-of-use/"
                          target="_blank"
                          className="anchor"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="http://amfvg.com.au/privacy-policy/"
                          target="_blank"
                          className="anchor"
                        >
                          Privacy Policy
                        </a>
                      </small>
                    </p>
                  </div>
                  <div className="clearfix"></div>
                  <div className="divide-sm"></div>
                </div>
              </div>
            </footer>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderOrderStatusChangeModal() {
    const {
      isOpenOrderStatusChangeModal,
      selectedOrder,
      selectedOrderNewStatus,
      isLoadingChangeOrderStatus,
    } = this.state;

    return (
      <>
        {selectedOrder != null && selectedOrderNewStatus != "" ? (
          <Modal
            show={isOpenOrderStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenOrderStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Order #{selectedOrder.id} to {selectedOrderNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Order #{selectedOrder.id} to{" "}
                {selectedOrderNewStatus}? This will also send a message to the
                customer.
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeOrderStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeOrderStatusOnServer(
                        selectedOrder.id,
                        selectedOrderNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenOrderStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(SupplierOverviewScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
