import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";
import NumberField from "../VendorApplyEventScreen/Component/NumberField";
import { Label } from "@material-ui/icons";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
class AdminVendorCateringApplications extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    tempBookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],
    avaliableCateringPreference: [
      "Not Selected",
      "Recommended",
      "Value for money",
      "Highly Rated",
    ],
    availableStatus: ["processing", "rejected", "approved", "Finalized Paid"],
    selectedBookingNewStatus: "",
    selectedBooking: null,
    isOpenBookingStatusChangeModal: false,
    isOpenBookingStatusChangeModal: false,
    isLoadingChangeBookingStatus: false,
    isLoadingChangeBookingStatus: false,
    isLoadingSendingVendorRequest: false,
    vendorCateringPacks: [],
    selectedCateringPack: {},
    requestSent: [],
    isOpenCustomerEmailDetails: false,
    requestSentIds: [],
    isLoadingVendor: false,
    searchVendor: "",
    searchNonCuisineVendor: "",
    vendorListSearchDisplay: "none",
    nonCuisineVendorListSearchDisplay: "none",
    selectedCuisine: "",
    adminNotesChanged: [],
    isLoading: false,
    requestState: "all",
    selectedPreference: "",
    selectedQuote: [],
    recommendationReason: "",

    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getDashboardData(this.state.requestState);
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendors = (cuisine, booking_request_id) => {
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisine_specific_vendors",
          cuisine: cuisine,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          dataResponse.vendors.sort(function(a, b) {
            var nameA = a.title,
              nameB = b.title;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.setState({
            vendors: dataResponse.vendors,
            nonCuisineVendors: dataResponse.non_cuisine_vendors,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  saveAdminNotes = (item, notes) => {
    const { email } = this.state;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_admin_notes",
          booking_request_id: item.id,
          admin_notes: notes,
          email: email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          alert("Saved Successfully!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  sendRequestToVendor(booking_request_id, user_ptr_id) {
    this.setState({ isLoadingSendingVendorRequest: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_request_to_vendor",
          user_ptr_id: user_ptr_id,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingSendingVendorRequest: false });
        if (dataResponse.success == true) {
          alert("Sent Successfully!");
          var arr = this.state.requestSentIds;
          arr.push(user_ptr_id);
          this.setState({ requestSentIds: arr });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingSendingVendorRequest: false });
      });
  }

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_get_catering_applications",
          //   start_date: startDate,
          //   end_date: endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({
            bookingRequests: dataResponse.catering_applications,
            tempBookingRequests: dataResponse.catering_applications,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeBookingStatus = (e, booking) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var newStatus = e.target.value;
    if (newStatus == "finalised") {
    }
    this.setState(
      {
        selectedBooking: booking,
        selectedBookingNewStatus: e.target.value,
        isOpenBookingStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeBookingStatus: false });
      }
    );
  };

  onChangeBookingStatusOnServer = (bookingId, bookingStatus) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_catering_pack_prefference",
          event_application_id: bookingId,
          eoi_status: bookingStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())

      .then((dataResponse) => {
        this.setState({ isLoadingChangeBookingStatus: false });

        this.setState({ isOpenBookingStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Status Change Successful.");
        } else {
          alert("Error! Please try again.");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenBookingStatusChangeModal: false,
          isLoadingChangeBookingStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  getVendorCateringPacks = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendorCateringPacks: dataResponse.catering_packs });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  sendEmailToCustomer = (selectedBooking, selectedCateringPack) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_email_to_customer",
          booking_request_id: selectedBooking.id,
          catering_pack_id: selectedCateringPack.id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to the customer.");
          this.setState({
            isOpenCustomerResponseModal: false,
            reviewEmail: false,
          });
        } else {
          alert("Something went wrong! Please try again");

          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  setAdminNotes = (item, notes, index) => {
    let start = new Date().valueOf();
    const { bookingRequests, adminNotesChanged } = this.state;
    let tempNotesChanged = adminNotesChanged;
    let tempBookingRequest = bookingRequests;
    tempBookingRequest[index].admin_notes = notes;
    tempNotesChanged.push(tempBookingRequest[index].id);

    this.setState(
      {
        bookingRequests: tempBookingRequest,
        adminNotesChanged: tempNotesChanged,
      },
      () => {
        let end = new Date().valueOf();
      }
    );
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  emailRunSheet = (item, job_details) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_quotes_for_catering",
          item: item,
          job_details: job_details,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to the Customer.");
        } else {
          alert("Something went wrong! Please contact Tech support");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please contact Tech support");
      });
  };

  handlePrefferenceChange = (e) => {
    this.setState({ selectedPreference: e.target.value });
  };

  renderHome() {
    const {
      bookingRequests,
      tempBookingRequests,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      startDate,
      endDate,
      completed_payments,
      avaliableCateringPreference,
      pageSelected,
      searchVendor,
    } = this.state;
    var emailList = this.state.tempBookingRequests;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <b>Filter</b>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.setState({ requestState: e.target.value });
                        this.getDashboardData(e.target.value);
                      }}
                      defaultValue={requestState}
                    >
                      <option value={"all"}>All</option>
                      <option value={"rejected"}>Rejected</option>
                      <option value={"processing"}>Pending</option>
                      <option value={"approved"}>Accepted</option>
                      <option value={"Finalized Paid"}>Paid</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <b>Requested Events in Date Range:</b>
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={4} md={4} lg={4}>
                      <p>Start Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={startDate}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p>End Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={endDate}
                        onChange={(date) => this.setEndDate(date)}
                      />
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p>&nbsp;</p>
                      <Button
                        onClick={() => {
                          this.setState({ resetFilter: "false" }, () => {
                            saveToLocalStorage(
                              "resetFilter",
                              this.state.resetFilter.toString()
                            );
                            this.getDashboardData(this.state.requestState);
                          });
                        }}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={8} md={4} lg={4}>
                  {/* {this.state.resetFilter == "false" ? (
                    <p>
                      By clicking this button, you can reset all filters and it
                      will show you all the booking request in reverser
                      chronoligical order.
                    </p>
                  ) : (
                    <p>
                      Showing Event Organiser Account requests in the order they
                      were made, filters are not applied. To apply filter you
                      can change the Requested Event Date.
                    </p>
                  )} */}
                  {this.state.resetFilter == "true" ? (
                    <p>
                      <b>
                        Note: When filters are not set, it will only show you
                        details of all upcoming events. It wont show you events
                        whose dates have passed.
                      </b>
                    </p>
                  ) : null}
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button
                    onClick={() => {
                      this.setState({ resetFilter: "true" }, () => {
                        saveToLocalStorage(
                          "resetFilter",
                          this.state.resetFilter.toString()
                        );
                        this.getDashboardData(this.state.requestState);
                      });
                    }}
                  >
                    {this.state.resetFilter == "true"
                      ? "Filters are not Set"
                      : "Reset Filters"}
                  </Button>
                </Col>
              </Row>
              <InputGroup className="mb-3">
                <InputGroup.Text value={searchVendor}>
                  <img
                    style={{ width: 20 }}
                    src={require("../../assets/img/search.gif")}
                  ></img>
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Booking Location"
                  aria-label="Search for stalls"
                  onChange={(e) => {
                    var searchString = e.target.value;
                    console.log(tempBookingRequests);
                    console.log(tempBookingRequests);
                    if (searchString == "") {
                      this.setState({
                        bookingRequests: this.state.tempBookingRequests,
                      });
                    } else {
                      console.log(emailList);
                      var result = emailList.filter((obj) => {
                        if (obj.booking_details__location) {
                          if (
                            obj.booking_details__location.search(
                              new RegExp(searchString, "i")
                            ) != -1
                          ) {
                            return obj;
                          }
                        }
                      });
                      this.setState({ bookingRequests: result });
                    }
                  }}
                />
              </InputGroup>

              <Row className="table-header-row">
                <Col xs={12} md={1} lg={1} className="table-header-col">
                  Application Number
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Stall Name
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Job Location
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Booking Request Ref No
                </Col>
                <Col xs={12} md={1} lg={1} className="table-header-col">
                  Total Price
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Operator Id
                </Col>
              </Row>
              {bookingRequests.map((item, requestIndex) => {
                let cuisine_array = [...new Set(item.cuisine_array)];
                return (
                  <Accordion key={item.id}>
                    <>
                      <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Booking request #:</b>{" "}
                              {item.id}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Stall Namel</b>
                              {/* {item.booking_details__location} */}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Job Location</b>
                              {item.booking_details__location}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Booking Request ID:</b>
                              {item.booking_details__id} --{" "}
                              {item.booking_details__email}
                            </span>
                          </Col>
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Total Price:</b>
                              {item.total_price}
                            </span>
                          </Col>

                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="table-col"
                            style={{ textAlign: "center" }}
                          >
                            <span>
                              <b className="mobile-only">Operator Name</b>
                              {item.operator_id__first_name}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="table-col"
                            style={{ textAlign: "center" }}
                          >
                            <Row style={{ marginBottom: 10 }}></Row>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey={item.id}
                        style={{ padding: 20, backgroundColor: "#eff2f5" }}
                      >
                        <>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Number Of Quotes:
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.stall_name__title.length}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Number Of Guests:
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__number_of_guests}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Cuisine Requested:
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__cuisine}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Truck Bucks/ Discount Offered:
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.bump_out_date}: {item.bump_out_start_time} -{" "}
                              {item.bump_out_end_time}
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Catering Event Type
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__event_type}
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Additional Serves Offered ?
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.site_contact} - {item.site_contact_number}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Customer Name
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__name}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Job Start Date & Time
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__start_date} -{" "}
                              {item.booking_details__start_time}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              Job End Date & Time
                            </Col>
                            <Col xs={6} md={6} lg={6}>
                              {item.booking_details__end_date} -{" "}
                              {item.booking_details__end_time}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={6} lg={6}>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  this.emailRunSheet(
                                    this.state.selectedQuote,
                                    item
                                  );
                                }}
                              >
                                Send Catering Packs
                              </Button>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "10px" }}>
                            <table style={{ borderBottom: "1px" }}>
                              <thead style={{ borderBottom: "1px" }}>
                                <tr>
                                  <th>Stall Name</th>
                                  <th>Pack Name</th>
                                  <th>Package Details</th>
                                  <th>Recommedation Reason</th>
                                  <th>Price Per Person</th>
                                  <th>Sub Total</th>
                                  <th>Truck Bucks Offered</th>
                                  <th>Total</th>
                                  <th>Prefference Number For the pack</th>
                                  <th>Selected Quote</th>
                                  {/* Add more table headers for additional fields */}
                                </tr>
                              </thead>
                              <tbody>
                                {item.stall_name__title.map((data, index) => (
                                  <tr
                                    key={index}
                                    style={{ borderBottom: "1px" }}
                                  >
                                    <td>
                                      {"  " + data.stall_name__title + " "}
                                    </td>
                                    <td>
                                      {"  " + data.catering_pack_name + "  "}
                                    </td>
                                    <td>
                                      {"  " + data.package_details + "  "}
                                    </td>
                                    <td>
                                      <input
                                        type="text-area"
                                        data={data.recommendation_reason}
                                        onChange={(e) => {
                                          data.recommendation_reason =
                                            e.target.value;
                                        }}
                                        defaultValue={
                                          data.recommendation_reason
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      {"  " + data.recommendation_reason + "  "}
                                    </td>
                                    <td>
                                      {"$" +
                                        " " +
                                        data.catering_package_price_per_person}
                                    </td>
                                    <td>{"$" + " " + data.sub_total_price}</td>
                                    <td>{data.truck_bucks_used}</td>
                                    <td>{"$" + " " + data.total_price} </td>
                                    <td>
                                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                        <Form.Control
                                          as="select"
                                          size="sm"
                                          custom
                                          onChange={(e) => {
                                            this.onChangeBookingStatus(e, data);
                                          }}
                                          defaultValue={
                                            data.package_recommendation
                                          }
                                        >
                                          {avaliableCateringPreference.map(
                                            (status, i) => {
                                              return (
                                                <option key={i} value={status}>
                                                  {status}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          let checked = e.target.checked;
                                          let value = e.target.value;
                                          let tempSelectedQuoteObj = data;
                                          let temp_quoteArrIds = this.state
                                            .selectedQuote;
                                          if (checked) {
                                            temp_quoteArrIds.push(
                                              data.catering_pack_id
                                            );
                                          } else {
                                            tempSelectedQuoteObj = tempSelectedQuoteObj.filter(
                                              (data) =>
                                                data.catering_pack_id != value
                                            );
                                          }
                                          this.setState({
                                            selectedQuote: temp_quoteArrIds,
                                          });
                                          console.log(
                                            "Selected qus " +
                                              this.state.selectedQuote
                                          );
                                        }}
                                      />
                                    </td>
                                    {/* Add more table cells for additional fields */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Row>

                          <Row style={{ marginTop: "10px" }}>
                            <Col xs={6} md={6} lg={6}>
                              <PDFDownloadLink
                                document={<RunsheetPdfCreator item={item} />}
                                fileName={
                                  "runsheet" +
                                  item.vendor__title +
                                  "-" +
                                  item.event_name +
                                  ".pdf"
                                }
                              >
                                {({ blob, url, loading, error }) =>
                                  loading
                                    ? "Loading document..."
                                    : "Download PDF!"
                                }
                              </PDFDownloadLink>
                            </Col>
                          </Row>

                          <Row>
                            {item.vendor__logo != null ? (
                              <Col xs={2} md={2} lg={2}>
                                <img
                                  style={{ width: "100%" }}
                                  src={AWS_URL + "/" + item.vendor__logo}
                                  alt="Vendor Logo"
                                />
                              </Col>
                            ) : null}
                          </Row>

                          {item.payment_status == "complete" ? (
                            <>
                              <Row>
                                <Col xs={6} md={6} lg={6}>
                                  Payment Source
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  {item.payment_source}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} lg={6}>
                                  Payment Receipt Number
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  {item.payment_receipt_number}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} lg={6}>
                                  Payment Date
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  {item.payment_date}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} lg={6}>
                                  Payment Time
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  {item.payment_time}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} lg={6}>
                                  Payment Total Amount
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  $
                                  {Number(item.payment_total_amount).toFixed(2)}
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </>
                      </Accordion.Collapse>
                    </>
                  </Accordion>
                );
              })}
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col xs={2} md={2} lg={2}>
                  <p>
                    <b>Showing Page:</b>
                  </p>
                </Col>
                <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                  <div style={{ width: "98%" }}>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected - 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                      <Pagination.Item active>{pageSelected}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected + 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
              {this.renderInviteModal()}
              {this.renderBookingStatusChangeModal()}
              {this.renderCustomerResponseModal()}
              {this.renderCustomerEmailModal()}
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderInviteModal(item) {
    const { inviteVendorModal } = this.state;

    return (
      <>
        {item && item.id ? (
          <Modal
            show={inviteVendorModal}
            onHide={() => {
              this.setState({ inviteVendorModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send Request to Vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            ></Modal.Body>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerEmailModal() {
    const { isOpenCustomerEmailDetails, selectedBooking } = this.state;
    return (
      <>
        {selectedBooking && selectedBooking != null ? (
          <Modal
            show={isOpenCustomerEmailDetails}
            onHide={() => {
              this.setState({ isOpenCustomerEmailDetails: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Customer Email Details</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                            {
                                selectedBooking.map((email) => {
                                    return (
                                        <div style={{ textAlign: 'left', backgroundColor: '#f7f7f7' }}>
                                            <p>Vendor: {email.vendor}</p>
                                            <p>Catering Pack Name: {email.catering_pack_name}</p>
                                            <p>Catering Pack Price per person: ${email.catering_pack_price}</p>

                                        </div>
                                    )
                                })}
                        </Modal.Body> */}
            <Modal.Footer></Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderBookingStatusChangeModal() {
    const {
      isOpenBookingStatusChangeModal,
      selectedBooking,
      selectedBookingNewStatus,
      isLoadingChangeBookingStatus,
    } = this.state;

    return (
      <>
        {selectedBooking != null && selectedBookingNewStatus != "" ? (
          <Modal
            show={isOpenBookingStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenBookingStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Recommedation Type #{selectedBooking.id} to{" "}
                {selectedBookingNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Event Organiser Application
                Status For #{selectedBooking.organisation_name} to{" "}
                {selectedBookingNewStatus}?
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeBookingStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeBookingStatusOnServer(
                        selectedBooking,
                        selectedBookingNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenBookingStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerResponseModal() {
    const {
      isOpenCustomerResponseModal,
      selectedBooking,
      isLoadingCustomerResponse,
      vendors,
      vendorCateringPacks,
      selectedCateringPack,
      reviewEmail,
    } = this.state;
    let min_fee = 0;
    let sub_total = 0;
    let min_call_out_fee = 0;
    if (selectedCateringPack && selectedBooking) {
      min_fee =
        Number(selectedCateringPack.package_price) *
        Number(selectedCateringPack.package_people);
      sub_total =
        Number(selectedCateringPack.package_price) *
        Number(selectedBooking.number_of_guests);
    }
    if (min_fee - sub_total > 0) {
      min_call_out_fee = min_fee - sub_total;
    }

    return (
      <>
        {selectedBooking != null ? (
          <Modal
            show={isOpenCustomerResponseModal}
            onHide={() => {
              this.setState({ isOpenCustomerResponseModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!reviewEmail
                  ? "Sending Message to Customer"
                  : "Review Email and Send"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {!reviewEmail ? (
                <>
                  <p>
                    You are about to send a response to the customer regarding
                    his booking request. Please confirm and fill in the details
                    below to send the response.
                  </p>
                  <div style={{ textAlign: "left" }}>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Name
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Phone
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.phone}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Email
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.email}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Booking Request Id
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.id}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Cuisine
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.cuisine}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Number of guests
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.number_of_guests}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Select the {selectedBooking.cuisine} vendor
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                          <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                              this.getVendorCateringPacks(
                                e.target.value,
                                selectedBooking
                              );
                            }}
                          >
                            <option key={0} value={-1}>
                              {"Select a vendor"}
                            </option>
                            {vendors.map((vendor, index) => {
                              return (
                                <option key={index} value={vendor.user_ptr_id}>
                                  {vendor.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {vendorCateringPacks.length > 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Select the catering pack
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                            <Form.Control
                              as="select"
                              size="sm"
                              custom
                              onChange={(e) => {
                                this.setState({
                                  selectedCateringPack:
                                    vendorCateringPacks[e.target.value],
                                });
                              }}
                            >
                              <option key={0} value={-1}>
                                {"Select a catering pack"}
                              </option>
                              {vendorCateringPacks.map((pack, index) => {
                                return (
                                  <option
                                    style={{ whiteSpace: "pre-line" }}
                                    key={index}
                                    value={index}
                                  >
                                    {pack.package_name +
                                      "\n$" +
                                      pack.package_price +
                                      "/person" +
                                      "(For min " +
                                      pack.package_people +
                                      " people )"}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          The selected vendor hasn't uploaded any catering packs
                        </Col>
                      </Row>
                    )}

                    {!this.isEmpty(selectedCateringPack) ? (
                      <>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Name
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Description
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_description}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Price per person
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {"$" + selectedCateringPack.package_price}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <p>Dear {selectedBooking.name}</p>
                  <p>
                    Thank you for your request. We have contacted some of the
                    best food trucks and based on the your requirements we found
                    the best quote for you. Following are the details -{" "}
                  </p>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Name
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Description
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_description}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Number of Guests
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {Number(selectedBooking.number_of_guests)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Sub Total
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  {Number(min_fee) - Number(sub_total) > 0 ? (
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          This pack is for min{" "}
                          {selectedCateringPack.package_people} people so a min
                          callout fee will be applied to your order
                        </Tooltip>
                      }
                    >
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                           Call Out Fee:
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          {"$" + Number(min_call_out_fee).toFixed(2)}
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  ) : null}
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Total:
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(min_call_out_fee + sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  <p>We are holding this booking for the next 7 days.</p>
                  <p>
                    If you have any questions please do not hesitate to email me
                    back or call me on 0411738771
                  </p>
                  <p>Regards</p>
                  <p>Alana Tarev</p>
                  <p>Customer Support</p>
                  <p>WTT Team</p>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              {isLoadingCustomerResponse ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {!reviewEmail ? (
                    <Button
                      style={{
                        width: "100%",
                        marginBottom: 20,
                        marginTop: 10,
                        backgroundColor: "#ef3f6d",
                      }}
                      className="button-enabled-pink-style"
                      onClick={() => {
                        if (
                          !this.isEmpty(selectedCateringPack) &&
                          vendorCateringPacks.length > 0
                        ) {
                          this.setState({ reviewEmail: true });
                        } else {
                          alert(
                            "Please select a vendor and a catering pack to send the email to the customer!"
                          );
                        }
                      }}
                    >
                      Review and Send
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure? This will send an email to the customer with the above details"
                            )
                          ) {
                            this.sendEmailToCustomer(
                              selectedBooking,
                              selectedCateringPack
                            );
                          } else {
                          }
                        }}
                      >
                        Send
                      </Button>

                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          this.setState({ reviewEmail: false });
                        }}
                      >
                        Back
                      </Button>
                    </>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminVendorCateringApplications, {
    fallback: <ErrorFallbackComponent />,
  })
);
