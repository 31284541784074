import React from 'react';
import {Form} from 'react-bootstrap';

const SelectMenu = (props) => {
    const {values, 
            labels, 
            formStateHandler, 
            formTouchedHandler, 
            fieldName, 
            otherFunction,
            defaultValue} = props;

    return (
        <Form.Control 
            as="select"
            onChange = {(event) => {
                formStateHandler(fieldName, event.target.value);
                if (otherFunction) {
                    otherFunction(event.target.value);
                }
            }}
            onClick={() => formTouchedHandler(fieldName, true)}
            defaultValue={defaultValue}>
            {values.map((value, i) => <option value={value} key={i.toString()}>{labels[i]}</option>)}
        </Form.Control>
    );
}

export default SelectMenu;