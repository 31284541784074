import React from "react";
import "./style.css";
import { useRef, useState, useEffect, useCallback, memo } from "react";
import { Box, Modal } from "@mui/material";
import useFecth from "../../hook/useFecth";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Footer from "../../components/UI/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { PaymentPage } from "./PaymentPage";
import * as Sentry from "@sentry/react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Divider from "@mui/material/Divider";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
const VendorApplyEventScreen = memo(() => {
  const [activeProfileComponent, setActiveProfileComponent] = useState(
    getFromLocalStorage("activeProfileComponent") != "undefined" &&
      getFromLocalStorage("activeProfileComponent") != "" &&
      getFromLocalStorage("activeProfileComponent") != null
      ? getFromLocalStorage("activeProfileComponent")
      : "profile"
  );
  const [first_name, set_first_name] = useState(
    JSON.parse(getFromLocalStorage("Operator"))[0].first_name
  );
  const [account_name, set_account_name] = useState("");
  const [account_number, set_account_number] = useState("");
  const [BSB_number, set_BSB_number] = useState("");
  const NoFlagComponent = () => null;
  const [last_name, set_last_name] = useState(
    JSON.parse(getFromLocalStorage("Operator"))[0].last_name
  );
  const [email, set_email] = useState(
    JSON.parse(getFromLocalStorage("Operator"))[0].email
  );
  const [vendor_id, set_vendor_id] = useState(
    JSON.parse(getFromLocalStorage("Operator"))[0].id
  );
  const [phone, set_phone] = useState("");
  const [error, setError] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [state, set_state] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const handleCode = (phone) => {
    set_phone(phone);
    console.log(phone);
    if (phone && !isValidPhoneNumber(phone)) {
      setError("Invalid phone number");
    } else {
      setError(null);
    }
  };
  const togglePaymentModal = () => {
    const newValue = !isPaymentModalOpen;
    setIsPaymentModalOpen(newValue);

    if (!newValue && getFromLocalStorage("bookingRequestId") === "") {
      window.location.href = "/book-a-truck";
    }
  };
  useEffect(() => {}, [isPaymentModalOpen]);
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const handleFirstNameChange = (event) => {
    set_first_name(event.target.value);
  };
  const handleLastNameChange = (event) => {
    set_last_name(event.target.value);
  };
  const handleAccountNameChange = (event) => {
    set_account_name(event.target.value);
  };
  const handleAccountNumberChange = (event) => {
    set_account_number(event.target.value);
  };
  const handleBSBNumberChange = (event) => {
    set_BSB_number(event.target.value);
  };
  const handleEmailChange = (event) => {
    set_email(event.target.value);
  };
  const { vendors } = useFecth("Operator");
  useEffect(() => {
    console.log(vendors);
    if (!Array.isArray(vendors) || vendors.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [vendors]);

  const handlePhoneChange = (event) => {
    set_phone(event.target.value);
  };
  const handleStateChange = (event) => {
    set_state(event.target.value);
  };
  const postEditVendorProfile = async (values) => {
    values.preventDefault();
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "edit_vendor_profile",
          operator_id: vendor_id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          state: state,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Profile updated successfully!");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Couldn't update the details, please try again later!");
        window.location.reload();
      });
  };
  const getBankingDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.operatorData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_banking_details",
          email: JSON.parse(getFromLocalStorage("Operator"))[0].email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true && response.banking_details !== []) {
          if (response.banking_details) {
            set_account_name(response.banking_details.account_name);
            set_account_number(response.banking_details.account_number);
            set_BSB_number(response.banking_details.BSB_number);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const handleRenewMembership = async (vendorId) => {
    try {
      setLoading(true);
      var url = config.BASE_URI + apiPaths.operatorData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "request_membership",
            vendor_id: vendorId,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
        setLoading(false);
      }
      const dataResponse = await response.json();

      console.log("Response from server:", dataResponse); // Log the entire response to check for the structure and content

      if (dataResponse.success === true) {
        setLoading(false);
        console.log("Membership renewed successfully!");
        if (dataResponse.supscription_link) {
          console.log("Subscription link:", dataResponse.supscription_link);
          // Redirect to the membership page with the subscription link appended
          window.location.href = `https://trucks.wherethetruckat.com/membership/${encodeURIComponent(
            dataResponse.supscription_link
          )}`;
        } else {
          console.log("No subscription link provided.");
        }
      } else {
        setLoading(false);
        console.error("Error renewing membership:", dataResponse.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error renewing membership:", error);
    }
  };

  const handleRequestMembership = (vendorId) => {
    // Make API request to request membership using the vendorId
    const url = config.BASE_URI + apiPaths.requestMembership;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ vendor_id: vendorId }),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Handle response accordingly
        console.log("Membership requested successfully!", data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error requesting membership:", error);
      });
  };
  const postBankingDetails = async (values) => {
    values.preventDefault();
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "post_bank_details",
          operator_id: vendor_id,
          account_name: account_name,
          account_number: account_number,
          bsb: BSB_number,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Bank Details added successfullu!");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Couldn't add the banking details");
        window.location.reload();
      });
  };
  useEffect(() => {
    getBankingDetails();
  }, []);
  return (
    <>
      <VendorNavBar />
      <div className="body">
        <Container>
          {/* <div className="navbar-container1">
            <ul className="ul">
              <li className="li">
                <a
                  onClick={() =>
                    window.location.replace("/vendor-profile/profile")
                  }
                >
                  Profile
                </a>
              </li>
              <li className="li">
                <a
                  onClick={() =>
                    window.location.replace("/vendor-profile/payment")
                  }
                >
                  Payment Methods
                </a>
              </li>
              <li className="li">
                <a
                  onClick={() =>
                    window.location.replace("/vendor-profile/membership")
                  }
                >
                  Membership
                </a>
              </li>
            </ul>
          </div> */}
          {activeProfileComponent === "profile" && (
            <div className="form-style-5 content">
              <legend>
                <span className="number">Operator Details</span>
              </legend>
              <form onSubmit={postEditVendorProfile}>
                <label html="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={first_name}
                  onChange={handleFirstNameChange}
                />
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  onChange={handleLastNameChange}
                />
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <label htmlFor="mobile">Phone</label>
                <div className="phone-input">
                  <PhoneInput
                    international={false}
                    flagComponent={NoFlagComponent}
                    defaultCountry="AU"
                    value={phone}
                    onChange={handleCode}
                  />
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}

                <label htmlFor="state" className="label">
                  State
                </label>
                <select defaultValue="" onChange={handleStateChange}>
                  <option value="" disabled>
                    Please Select
                  </option>
                  <option value="Western Australia">Western Australia</option>
                  <option value="South Australia">South Australia</option>
                  <option value="Northern Territory">Northern Territory</option>
                  <option value="Victoria">Victoria</option>
                  <option value="Queensland">Queensland</option>
                  <option value="Australian Capital Territory">
                    Australian Capital Territory
                  </option>
                  <option value="New South Whales">New South Whales</option>
                  <option value="Tasmania">Tasmania</option>
                </select>
                <button type="submit">Update Details</button>
              </form>
            </div>
          )}
          {activeProfileComponent === "payment" && (
            <div className="form-style-5 content">
              <form onSubmit={postBankingDetails}>
                <div>
                  Note: Please enter the account details into which you want to
                  be paid.
                </div>
                <Divider />
                <label html="account_name">Account Name</label>
                <input
                  type="text"
                  id="account_name"
                  name="account_name"
                  value={account_name}
                  onChange={handleAccountNameChange}
                />
                <label html="account_number">Account Number</label>
                <input
                  type="text"
                  id="account_number"
                  name="account_number"
                  value={account_number}
                  onChange={handleAccountNumberChange}
                />
                <label html="account_name">BSB Number</label>
                <input
                  type="text"
                  id="BSB_number"
                  name="BSB_number"
                  value={BSB_number}
                  onChange={handleBSBNumberChange}
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {activeProfileComponent === "membership" &&
            (!loading ? (
              <div className="content">
                <Row className="table-header-row" style={{ marginTop: 10 }}>
                  <Col xs={12} md={4} lg={4} className="table-header-col">
                    Truck Name
                  </Col>
                  <Col xs={12} md={4} lg={4} className="table-header-col">
                    Email
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Subscription Status
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                  Subscription Expiry Date
                  </Col>
                </Row>
                {vendors.map((item, i) => (
                  <Row key={i} className="table-row">
                    <Col xs={12} md={4} lg={4} className="table-col">
                      <p className="desktop-only">{item.title}</p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="table-col">
                      <p>{item.email}</p>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="table-col">
                      {!item.is_wtt_subscriber ? (
                        <Button
                          onClick={() => handleRenewMembership(item.id)}
                          variant="primary"
                        >
                          Request Subscription
                        </Button>
                      ) : (
                        <h1>Current Subscriber</h1>
                      )}
                    </Col>
                    <Col xs={12} md={2} lg={2} className="table-col">
                      <p>
                        {item.is_wtt_subscriber
                          ? "28th Feb, 2025"
                          : "Not Applicable"}
                      </p>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <img
                  src={require("../../assets/img/loading.gif")}
                  alt="Loading..."
                />
              </div>
            ))}
        </Container>
      </div>
      <Footer />
    </>
  );
});

export default Sentry.withErrorBoundary(VendorApplyEventScreen, {
  fallback: <ErrorFallbackComponent />,
});
