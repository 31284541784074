import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './WeatherTable.css';

const WeatherTable = ({ hourlyForecast }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    // Update current time every minute
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 60000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Find the current weather data
  const currentWeather = hourlyForecast.find((hour) => moment(hour.time) >= currentTime);

  // Filter the forecast data for the next 8 hours from the current time
  const filteredForecast = hourlyForecast.filter(
    (hour) => moment(hour.time) >= currentTime && moment(hour.time) <= currentTime.clone().add(8, 'hours')
  );

  return (
    <div>
      <h2>Weather Forecast</h2>
      <div className="current-time">
        <p>
          Current Time: {currentTime.format('HH:mm')}
          {currentWeather && (
            <>
              <span className="weather-icon">
                <img src={currentWeather.icon_url} alt={currentWeather.condition} />
              </span>
              <span className="temperature">{currentWeather.temperature_celsius}°C</span>
              <span className="condition">{currentWeather.condition}</span>
            </>
          )}
        </p>
      </div>
      <div className="weather-container">
        {filteredForecast.map((hour) => (
          <div key={hour.time} className="weather-item">
            <div className="weather-icon">
              <img src={hour.icon_url} alt={hour.condition} />
            </div>
            <div className="weather-details">
              <div className="temperature">{hour.temperature_celsius}°C</div>
              <div className="time">{moment(hour.time).format('HH:mm')}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeatherTable;
