import React, { useEffect, useState } from "react";

import TableBody from "./TableBody";
import TableHead from "./TableHead";
import { Table } from "react-bootstrap";
import "./index.css";

const TableData = ({ tableData }) => {
  const columns = [
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Quantity Sold", accessor: "count", sortable: true },
    { label: "Total", accessor: "price", sortable: true },
    { label: "% of Total Sales", accessor: "percentage", sortable: true },
  ];

  const [sortedTableData, setTableData] = useState(tableData);

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  useEffect(() => {
    handleSorting("price", "desc");
  }, [tableData]);

  return (
    <Table striped bordered hover>
      <TableHead columns={columns} handleSorting={handleSorting} />
      <TableBody columns={columns} sortedTableData={sortedTableData} />
    </Table>
  );
};

export default TableData;
