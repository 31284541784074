import React, { useState } from "react";

const CategoriesSidebar = ({ meals, onSelectCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const getUniqueCategories = (meals) => {
    const categories = meals.map((meal) => meal.category.title);
    return ["All", ...new Set(categories)];
  };

  const categories =
    meals && meals.length > 0 ? getUniqueCategories(meals) : [];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    onSelectCategory(category);
  };

  return (
    <div
      style={{
        backgroundColor: "#2959a5",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <h3>Categories</h3>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {categories.map((category, index) => (
          <li
            key={index}
            onClick={() => handleCategoryClick(category)}
            style={{
              cursor: "pointer",
              backgroundColor:
                selectedCategory === category ? "#ef3e6d" : "transparent",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoriesSidebar;
