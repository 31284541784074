import React, { useState } from "react";
import FileUploader from "../FileUploader";
import { Form, Row, Col, Accordion, Card } from "react-bootstrap";
import "../../style.css";
import SelectMenus from "../SelectMenu";
import NumberField from "../NumberField";
import CheckButton from "../CheckButton";

const StallRequirements = (props) => {
  const { formik, viewOnly } = props;
  const [isCoolerRequired, setCoolerRequired] = useState(
    formik.values.isCoolerRequired
  );
  const [fireSource, setFireSource] = useState("");
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        style={{
          color: "black",
        }}
      >
        2. STALL REQUIREMENTS
        {((formik.errors.siteLayoutFile ||
        formik.errors.vansWidth ||
        formik.errors.vansDepth ||
        formik.errors.vansHeight ||
        formik.errors.exitDoorPos ||
        (isCoolerRequired &&
          (formik.errors.coolRoomWidth || formik.errors.coolRoomDepth)) ||
        formik.errors.fireSource ||
        (fireSource === "gas" && formik.errors.gasCert)) && !viewOnly) ? (
          <div style={{ color: "red", fontWeight: "bold" }}>
            {"Information Missing"}
          </div>
        ) : null}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
            {!viewOnly ? (
                <div className="title-heading">
                <h6>Site Layout*: </h6>
                {formik.touched.siteLayoutFile && formik.errors.siteLayoutFile ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.siteLayoutFile}
                  </div>
                ) : null}
                <FileUploader
                  allowMultiple={true}
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"siteLayoutFile"}
                  allowFileTypeValidation={false}
                  defaultValue={formik.values.siteLayoutFile}
                />
              </div>
            ):(
              <div className="title-heading">
                <h6>Site Layout*: </h6>
                {formik.values.siteLayoutFile.map(({item}) => (
                <img src={item && item != null && item != "" ? item : require("../../../../assets/img/default_cover.jpg")} alt="Liability Certificate" height="200px" width="200px"
                onError={(e)=>{
                    const reader = new FileReader();
                    reader.onload = function(){
                        e.target.src = reader.result;
                    }
                    reader.readAsDataURL(item);
                }}
                />
                ))}
              </div>
            )}


          {!viewOnly ? (
          <div className="title-heading">
          <h6>Clearly indicate width, depth and height*.</h6>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              <h6>Width (in meters): </h6>
              {formik.touched.vansWidth && formik.errors.vansWidth ? (
                <div style={{ color: "red" }}>{formik.errors.vansWidth}</div>
              ) : null}
            </Form.Label>
            <Col sm={10}>
              <NumberField
                placeholder={"Width"}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"vansWidth"}
                defaultValue={formik.values.vansWidth}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              <h6>Depth (in meters): </h6>
              {formik.touched.vansDepth && formik.errors.vansDepth ? (
                <div style={{ color: "red" }}>{formik.errors.vansDepth}</div>
              ) : null}
            </Form.Label>
            <Col sm={10}>
              <NumberField
                placeholder={"Depth"}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"vansDepth"}
                defaultValue={formik.values.vansDepth}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              <h6>Height (in meters): </h6>
              {formik.touched.vansHeight && formik.errors.vansHeight ? (
                <div style={{ color: "red" }}>{formik.errors.vansHeight}</div>
              ) : null}
            </Form.Label>
            <Col sm={10}>
              <NumberField
                placeholder={"Height"}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"vansHeight"}
                defaultValue={formik.values.vansHeight}
              />
            </Col>
          </Form.Group>
        </div>
  
          ) : (
            <div className="title-heading">
            <h6>Clearly indicate width, depth and height*.</h6>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <h6>Width (in meters): </h6>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder={"Width"}
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"vansWidth"}
                  defaultValue={formik.values.vansWidth}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <h6>Depth (in meters): </h6>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder={"Depth"}
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"vansDepth"}
                  defaultValue={formik.values.vansDepth}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <h6>Height (in meters): </h6>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder={"Height"}
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"vansHeight"}
                  defaultValue={formik.values.vansHeight}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>
          </div>
          )}
          {!viewOnly ? (
          <div key="inline-radio" className="mb-3 title-heading">
          <h6>Exit Door Position*</h6>
          <p>What is the position of your exit door at your stall?</p>
          {formik.touched.exitDoorPos && formik.errors.exitDoorPos ? (
            <div style={{ color: "red" }}>{formik.errors.exitDoorPos}</div>
          ) : null}
          <SelectMenus
            values={["", "front", "back", "right", "left"]}
            labels={["Please Select", "Front", "Back", "Right", "Left"]}
            formStateHandler={formik.setFieldValue}
            formTouchedHandler={formik.setFieldTouched}
            fieldName={"exitDoorPos"}
            defaultValue={formik.values.exitDoorPos}
          />
        </div>
  
          ) : (
            <div key="inline-radio" className="mb-3 title-heading">
            <h6>Exit Door Position*</h6>
            <p>What is the position of your exit door at your stall?</p>
            <Form.Control
              fieldName={"exitDoorPos"}
              defaultValue={formik.values.exitDoorPos}
              readOnly={true}
              style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
            />
          </div>
          )}
          {!viewOnly ? (
            <>
              <div className="title-heading">
              <Row>
                <Col sm={1}>
                  <CheckButton
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"isCoolerRequired"}
                    otherFunction={setCoolerRequired}
                    defaultChecked={formik.values.isCoolerRequired}
                  />
                </Col>
                <Col sm={11}>
                  <h6>I need a cool room/freezer room</h6>
                </Col>
              </Row>
            </div>

            <div
              className="title-heading"
              style={{ display: isCoolerRequired ? "block" : "none" }}
            >
              <h6>Size of the coolroom*</h6>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Width (in meters): </h6>
                  {formik.touched.coolRoomWidth && formik.errors.coolRoomWidth ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.coolRoomWidth}
                    </div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <NumberField
                    placeholder={"Width"}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"coolRoomWidth"}
                    defaultValue={formik.values.coolRoomWidth}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Depth (in meters): </h6>
                  {formik.touched.coolRoomDepth && formik.errors.coolRoomDepth ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.coolRoomDepth}
                    </div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <NumberField
                    placeholder={"Depth"}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"coolRoomDepth"}
                    defaultValue={formik.values.coolRoomDepth}
                  />
                </Col>
              </Form.Group>
            </div>
          </>
  
          ) : (
          <>
          <div key="inline-radio" className="mb-3 title-heading">
          <h6>I need a cool room/freezer room</h6>
            <Form.Control
              defaultValue={formik.values.isCoolerRequired}
              readOnly={true}
              style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
            />
          </div>
          <div
            className="title-heading"
            style={{ display: isCoolerRequired ? "block" : "none" }}
          >
            <h6>Size of the coolroom*</h6>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <h6>Width (in meters): </h6>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  fieldName={"coolRoomWidth"}
                  defaultValue={formik.values.coolRoomWidth}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                <h6>Depth (in meters): </h6>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  fieldName={"coolRoomDepth"}
                  defaultValue={formik.values.coolRoomDepth}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>
          </div>
          </>
          )}
          {!viewOnly ? (
            <div key="inline-radio2" className="mb-3 title-heading">
            <h6>What is your ignition source of fire?* </h6>
            {formik.touched.fireSource && formik.errors.fireSource ? (
              <div style={{ color: "red" }}>{formik.errors.fireSource}</div>
            ) : null}
            <SelectMenus
              values={["", "Gas", "Coal", "Woodfire", "None"]}
              labels={["Please Select", "Gas", "Coal", "Wood Fire", "None"]}
              formStateHandler={formik.setFieldValue}
              formTouchedHandler={formik.setFieldTouched}
              fieldName={"fireSource"}
              otherFunction={setFireSource}
              defaultValue={formik.values.fireSource}
            />
          </div>
  
          ) : (
            <div key="inline-radio" className="mb-3 title-heading">
            <h6>What is your ignition source of fire?* </h6>
            <Form.Control
              fieldName={"fireSource"}
              defaultValue={formik.values.fireSource}
              readOnly={true}
              style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
            />
          </div>
          )}
          <div
            className="title-heading"
            style={{ display: fireSource === "gas" ? "block" : "none" }}
          >
            <h6>Gas Compliance Certificate</h6>
            {formik.touched.gasCert && formik.errors.gasCert ? (
              <div style={{ color: "red" }}>{formik.errors.gasCert}</div>
            ) : null}
            <FileUploader
              allowMultiple={true}
              formStateHandler={formik.setFieldValue}
              formTouchedHandler={formik.setFieldTouched}
              fieldName={"gasCert"}
              allowFileTypeValidation={false}
              defaultValue={formik.values.gasCert}
            />
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default StallRequirements;
