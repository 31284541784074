import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.css";

const MealStats = ({ meal_freq }) => {
  // Sort the meal_freq array by count and price to find the top 5 selling items and the item with the most gross revenue
  const top5SellingItems = meal_freq
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  const itemsWithMostGrossRevenue = meal_freq
    .sort((a, b) => b.price * b.count - a.price * a.count)
    .slice(0, 5);

  return (
    <div>
      <Row className="justify-content-md-center">
        <Col sm={6} md={6}>
          <div className="container-compo">
            <h3 className="h33">Top 5 Selling Items:</h3>
            <ul className="ull">
              {top5SellingItems.map((item, index) => (
                <li key={index} className="lii">
                  {item.name} - Total Sales: {item.count}, Gross Revenue:{" "}
                  {item.price}
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Col sm={6} md={6}>
          <div className="container-compo">
            <h3 className="h33">Item with Most Gross Revenue:</h3>
            <ul className="ull">
              {itemsWithMostGrossRevenue.map((item, index) => (
                <li key={index} className="lii">
                  {item.name} - Total Sales: {item.count}, Gross Revenue:{" "}
                  {item.price}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MealStats;
