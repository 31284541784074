import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Line } from "react-chartjs-2";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  InputGroup,
  FormControl,
  Form,
  Container,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
// import { isEmpty } from "./utils.js";
// import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";

class CumulativeSales extends Component {
  state = {
    salesData: {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      datasets: [
        {
          label: "This Period",
          data: [100, 120, 95, 85, 75, 60, 80],
          borderColor: "#266df1",
          fill: false,
        },
        {
          label: "Last Period",
          data: [90, 100, 85, 75, 65, 55, 70],
          borderColor: "#266df1",
          fill: false,
          borderDash: [10, 5],
        },
      ],
    },
    orderData: {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      datasets: [
        {
          label: "This Period",
          data: [1000, 1200, 950, 850, 750, 600, 800],
          borderColor: "#266df1",
          fill: false,
        },
        {
          label: "Last Period",
          data: [900, 1000, 850, 750, 650, 550, 700],
          borderColor: "#266df1",
          fill: false,
          borderDash: [10, 5],
        },
      ],
    },
    ticketData: {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      datasets: [
        {
          label: "This Period",
          data: [10, 12, 9, 8, 7, 6, 8],
          borderColor: "#266df1",
          fill: false,
        },
        {
          label: "Last Period",
          data: [9, 10, 8, 7, 6, 5, 7],
          borderColor: "#266df1",
          fill: false,
          borderDash: [10, 5],
        },
      ],
    },
    activeLink: "/cumulative-sales/sales-total",
    searchVendor: "",
    Operator:
      getFromLocalStorage("Operator") != "undefined" &&
      getFromLocalStorage("Operator") != "" &&
      getFromLocalStorage("Operator") != null
        ? getFromLocalStorage("Operator")
        : "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    vendors: [],
    tempVendorList: [],
    isLoading: false,
    showPassword: false,
    total_sales_past: 11265,
    total_sales_present: 1022,
    total_orders_past: 13,
    total_orders_present: 12,
    canceled_sales_present: 33,
    canceled_orders_present: 11,
    canceled_sales_present: 56,
    canceled_orders_present: 1,
    salesByDaysData: [],
    selectedVendorIds: [],
    allVendorsSelected: false,
    startDatePresent:
      getFromLocalStorage("startDatePresent") != "undefined" &&
      getFromLocalStorage("startDatePresent") != "" &&
      getFromLocalStorage("startDatePresent") != null &&
      getFromLocalStorage("startDatePresent").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDatePresent"))
        : new Date(),
    startDatePast:
      getFromLocalStorage("startDatePast") != "undefined" &&
      getFromLocalStorage("startDatePast") != "" &&
      getFromLocalStorage("startPast") != null &&
      getFromLocalStorage("startPast").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startPast"))
        : new Date(),
    endDatePast:
      getFromLocalStorage("endDatePresent") != "undefined" &&
      getFromLocalStorage("endDatePresent") != "" &&
      getFromLocalStorage("endDatePresent") != null &&
      getFromLocalStorage("endDatePresent").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDatePresent"))
        : new Date(),
    endDatePresent:
      getFromLocalStorage("endDatePast") != "undefined" &&
      getFromLocalStorage("endDatePast") != "" &&
      getFromLocalStorage("endDatePast") != null &&
      getFromLocalStorage("endDatePast").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDatePast"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDatePresent: date }, () => {
      saveToLocalStorage("startDatePresent", this.state.startDatePresent);
    });
    var enddate = moment(date).add(6, "days");
    var startdatepast = moment(date).subtract(7, "days");
    var enddatepast = moment(date).subtract(1, "days");
    this.setState({ endDatePresent: enddate }, () => {
      saveToLocalStorage("endDatePresent", this.state.endDatePresent);
    });
    this.setState({ endDatePast: enddatepast }, () => {
      saveToLocalStorage("endDatePast", this.state.endDatePast);
    });
    this.setState({ startDatePast: startdatepast }, () => {
      saveToLocalStorage("startDatePast", this.state.startDatePast);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDatePresent: date }, () => {
      saveToLocalStorage("endDatePresent", this.state.endDatePresent);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleLinkClickSales = (event) => {
    event.preventDefault();
    const link = event.target
      .getAttribute("href")
      .replace("/cumulative-sales/", "");
    this.setState({ activeLink: link });
  };
  handleLinkClickOrders = (event) => {
    event.preventDefault();
    const link = event.target
      .getAttribute("href")
      .replace("/cumulative-sales/", "");
    this.setState({ activeLink: link });
  };
  handleLinkClickTickets = (event) => {
    event.preventDefault();
    const link = event.target
      .getAttribute("href")
      .replace("/cumulative-sales/", "");
    this.setState({ activeLink: link });
  };

  getCumulativeOrders = () => {
    const { email, password, selectedVendorIds } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cumulative_analytics",
          email: email,
          password: password,
          vendors: selectedVendorIds,
          start_date_past: this.state.startDatePast,
          end_date_past: this.state.endDatePast,
          start_date_present: this.state.startDatePresent,
          end_date_present: this.state.endDatePresent,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            total_sales_past: dataResponse.total_sales,
            total_orders_past: dataResponse.total_orders,
            canceled_sales_past: dataResponse.canceled_sales,
            canceled_orders_past: dataResponse.canceled_orders,
            salesByDays_past: dataResponse.sorted_daily_sales,
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState(
            {
              vendors: dataResponse.vendors,
              tempVendorList: dataResponse.vendors,
            },
            () => {
              let vendorIds = this.state.vendors.map(
                (vendor) => vendor.user_ptr_id
              );
              this.setState({ selectedVendorIds: vendorIds }, () => {
                saveToLocalStorage("selectedVendorIds", vendorIds);
              });
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      Operator,
      searchVendor,
      isLoading,
      tempVendorList,
      total_orders_past,
      total_orders_present,
      total_sales_past,
      total_sales_present,
      canceled_orders,
      canceled_sales,
      startDatePresent,
      startDatepast,
      endDatePresent,
      endDatePast,
    } = this.state;
    var vendorList = this.state.tempVendorList;
    const { activeLink, salesData, orderData, ticketData } = this.state;
    const activeClass = "nav-link active-link";
    const inactiveClass = "nav-link";
    let first_name = "";
    if (Operator !== "") {
      first_name = JSON.parse(Operator)[0].first_name;
    }

    let chartData;
    let message;
    if (activeLink === "sales-total") {
      chartData = salesData;
      message = (
        <span>
          <span className="sales-values">
            This Period: A$ {this.state.total_sales_present}
          </span>
          <span className="sales-values">
            Last Period: A$ {this.state.total_sales_past}
          </span>
        </span>
      );
    } else if (activeLink === "order-volume") {
      chartData = orderData;
      message = (
        <span>
          <span className="sales-values">
            This Period: A$ {this.state.total_orders_present}
          </span>
          <span className="sales-values">
            Last Period: A$ {this.state.total_orders_past}
          </span>
        </span>
      );
    } else {
      chartData = ticketData;
      message = (
        <span>
          <span className="sales-values">
            This Period: A${" "}
            {(
              this.state.total_sales_present / this.state.total_orders_present
            ).toFixed(2)}
          </span>
          <span className="sales-values">
            Last Period: A${" "}
            {(
              this.state.total_sales_past / this.state.total_orders_past
            ).toFixed(2)}
          </span>
        </span>
      );
    }
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <h2>Good morning, {first_name}</h2>
              <p>
                <strong>Sales analytics</strong>
              </p>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Form.Group>
                    <Form.Label>
                      <strong>Stores</strong>
                    </Form.Label>
                    <div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text value={searchVendor}>
                          <img
                            style={{ width: 20 }}
                            src={require("../../assets/img/search.gif")}
                          ></img>
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search for vendors"
                          aria-label="Search for vendors"
                          onChange={(e) => {
                            var searchString = e.target.value;
                            if (searchString == "") {
                              this.setState({
                                tempVendorList: this.state.vendors,
                              });
                            } else {
                              var result = vendorList.filter((obj) => {
                                if (
                                  obj.title.search(
                                    new RegExp(searchString, "i")
                                  ) != -1
                                ) {
                                  return obj;
                                }
                              });
                              this.setState({ tempVendorList: result });
                            }
                          }}
                        />
                      </InputGroup>
                      <Form.Check
                        type="checkbox"
                        id="select-all"
                        label="Select All"
                        checked={
                          this.state.selectedVendorIds.length ===
                          this.state.vendors.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({
                              selectedVendorIds: this.state.vendors.map(
                                (vendor) => vendor.user_ptr_id
                              ),
                            });
                          } else {
                            this.setState({ selectedVendorIds: [] });
                          }
                        }}
                      />
                      <Form.Check
                        type="checkbox"
                        id="clear"
                        label="Clear"
                        checked={
                          this.state.selectedVendorIds &&
                          this.state.vendors &&
                          this.state.selectedVendorIds.length === 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({ selectedVendorIds: [] });
                          }
                        }}
                      />
                      <div style={{ height: "200px", overflowY: "auto" }}>
                        {this.state.tempVendorList.map((vendor) => (
                          <Form.Check
                            key={vendor.user_ptr_id}
                            type="checkbox"
                            id={`vendor-${vendor.user_ptr_id}`}
                            label={vendor.title}
                            checked={
                              this.state.selectedVendorIds &&
                              this.state.tempVendorList &&
                              this.state.selectedVendorIds.includes(
                                vendor.user_ptr_id
                              )
                            }
                            onChange={(e) => {
                              const vendorId = vendor.user_ptr_id;
                              const selectedVendorIds = this.state.selectedVendorIds.slice();
                              if (e.target.checked) {
                                selectedVendorIds.push(vendorId);
                              } else {
                                const index = selectedVendorIds.indexOf(
                                  vendorId
                                );
                                if (index >= 0) {
                                  selectedVendorIds.splice(index, 1);
                                }
                              }
                              this.setState({ selectedVendorIds });
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <p>Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDatePresent}
                    onChange={(date) => this.setStartDate(date)}
                    filterDate={(date) => {
                      const day = date.getDay();
                      return (
                        day === 0 ||
                        day === 2 ||
                        day === 3 ||
                        day === 4 ||
                        day === 5 ||
                        day === 6 ||
                        day === 1
                      );
                    }}
                  />
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <p>End Date</p>
                  <p
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "black",
                      padding: "2px 1px 2px 5px",
                      backgroundColor: "#bbbcbd",
                      cursor: "not-allowed",
                    }}
                  >
                    {moment(endDatePresent)
                      .format("DD/MM/yyyy")
                      .toString()}
                  </p>
                </Col>
                <Col xs={12} md={12} lg={12} className="text-center">
                  <Button
                    style={{ marginTop: 30, marginBottom: 30, width: "200px" }}
                    onClick={() => {
                      // this.getCumulativeOrdersPresent();
                      this.getCumulativeOrders();
                    }}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
              <div className="navbar-container">
                <ul>
                  <li
                    className={
                      activeLink === "sales-total" ? activeClass : inactiveClass
                    }
                  >
                    <a
                      href="/cumulative-sales/sales-total"
                      onClick={this.handleLinkClickSales}
                    >
                      Sales total
                    </a>
                    <Tooltip title="This is the total value of items sold plus applicable taxes.">
                      <InfoIcon
                        className="question-mark"
                        sx={{ height: "15px" }}
                      />
                    </Tooltip>
                    <div className="underline"></div>
                  </li>
                  <li
                    className={
                      activeLink === "order-volume"
                        ? activeClass
                        : inactiveClass
                    }
                  >
                    <a
                      href="/cumulative-sales/order-volume"
                      onClick={this.handleLinkClickOrders}
                    >
                      Order volume
                    </a>
                    <Tooltip title="This is the total number of orders your food truck has completed.">
                      <InfoIcon
                        className="question-mark"
                        sx={{ height: "15px" }}
                      />
                    </Tooltip>
                    <div className="underline"></div>
                  </li>
                  <li
                    className={
                      activeLink === "ticket-size" ? activeClass : inactiveClass
                    }
                  >
                    <a
                      href="/cumulative-sales/ticket-size"
                      onClick={this.handleLinkClickTickets}
                    >
                      Ticket size
                    </a>
                    <Tooltip title="This is the average value of items sold per order, including applicable sales tax">
                      <InfoIcon
                        className="question-mark"
                        sx={{ height: "15px" }}
                      />
                    </Tooltip>
                    <div className="underline"></div>
                  </li>
                </ul>
              </div>
              {message}
              <Line data={chartData} />
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(CumulativeSales, {
    fallback: <ErrorFallbackComponent />,
  })
);
