import { vendorService } from "../services/VendorService";
import getDropDownData from "./utils";

export const vendorActions = {
    getDiet,
    getKitchen,
} 

export const GET_DIET = 'GET_DIET';
function getDiet() {
    return (dispatch) => {
        vendorService.getDiet().then(
            success => dispatch({
                type: GET_DIET,
                payload: getDropDownData(success.data),
            })
        );
    }
}

export const GET_KITCHEN = 'GET_KITCHEN';
function getKitchen() {
    return (dispatch) => {
        vendorService.getKitchens().then(
            success => dispatch({
                type: GET_KITCHEN,
                payload: getDropDownData(success.data),
            })
        );
    }
}
