import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Media from "react-bootstrap/Media";
import InputGroup from "react-bootstrap/InputGroup";
import EllipsisText from "react-ellipsis-text";
import { theme } from "../theme";
import LazyLoad from "react-lazy-load";
import { SlideDown } from "react-slidedown";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "react-slidedown/lib/slidedown.css";
import "./style.css";
import { store } from "../../../store";
import InstaFeed from "../InstaFeed";

class SideBar extends Component {
  state = {
    openCusinesDropDown: false,
  };
  toggleCusinesDropDown() {
    if (this.state.openCusinesDropDown) {
      this.setState({ openCusinesDropDown: false });
    } else {
      this.setState({ openCusinesDropDown: true });
    }
  }

  render() {
    const {
      vendorData,
      filters,
      selectedCategory,
      getSelectedVendor,
    } = this.props;
    const { openCusinesDropDown } = this.state;
    const listGroupItemStyle = {
      border: 0,
      borderRadius: "inherit",
    };
    const listGroupItemDownDownStyle = {
      border: 0,
      borderRadius: "inherit",
      backgroundColor: theme.colors.primaryColor + "1A",
      borderRadius: 40,
      border: 0,
      padding: 10,
      cursor: "pointer",
    };
    const headingStyle = {
      fontWeight: 900,
      fontSize: "1.5em",
    };
    const listGroupStyle = {
      backgroundColor: theme.colors.textColorLight,

      height: "100vh",
      minHeight: "100vh",
    };
    const ellipsisTextStyle = {
      fontSize: 12,
      lineHeight: 0,
    };

    const selectedCategoryStyle = {
      backgroundColor: theme.colors.primaryColor + "1A",
      borderRadius: 40,
      border: 0,
      cursor: "pointer",
    };
    const picStyle = {
      border: "1px solid",
      borderRadius: 20,
      borderColor: theme.colors.textColorDark,
      backgroundColor: theme.colors.backgroundColor,
    };
    const noPadding = {
      padding: 0,
      backgroundColor: "transparent",
      border: 0,
    };
    const dropdownTitleStyle = {
      display: "block",
      width: "100%",
      height: "calc(1.5em + .75rem + 2px)",
      padding: ".375rem .75rem",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.colors.textColorDark,
      backgroundColor: "transparent",
      backgroundClip: "unset",
      border: "0px solid #ced4da",
      borderRadius: ".25rem",
      transition:
        "border - color .15s ease -in -out, box - shadow .15s ease -in -out",
    };

    const trendingTrucks = vendorData.map((truck, i) => {
      const { appReducer } = store.getState();
      const { selectedLocation } = appReducer;
      if (truck.kitchaco.location == selectedLocation) {
        if (i < 3) {
          return (
            <ListGroup.Item
              style={listGroupItemStyle}
              key={i}
              onClick={() => getSelectedVendor(truck.id)}
            >
              <Media>
                <LazyLoad
                  width={60}
                  height={60}
                  debounce={false}
                  offsetVertical={500}
                >
                  <img
                    width={50}
                    height={50}
                    className="mr-3"
                    src={
                      truck.logo
                        ? truck.logo
                        : require("../../../assets/img/empty_logo.png")
                    }
                    alt="Generic placeholder"
                  />
                </LazyLoad>
                <Media.Body>
                  <h5 style={{ marginBottom: 0 }}>{truck.title}</h5>
                  <div style={{ lineHeight: 1 }}>
                    <EllipsisText
                      text={truck.address}
                      length={"85"}
                      style={ellipsisTextStyle}
                    />
                  </div>
                </Media.Body>
              </Media>
            </ListGroup.Item>
          );
        }
      }
    });

    // const displayCategories = filters && filters.Kitchen.map((category, i) => {
    //     if (selectedCategory === category.title) {
    //         return (
    //             <ListGroup.Item as="li" style={selectedCategoryStyle} hover key={i}>
    //                 <Media>

    //                     <Media.Body>
    //                         <h5 style={{ marginBottom: 0 }}>{category.title}</h5>
    //                     </Media.Body>
    //                 </Media>
    //             </ListGroup.Item>
    //         )
    //     }
    //     else {
    //         return (
    //             <ListGroup.Item as="li" className="hover" key={i} style={{ border: 0 }}>
    //                 <Media>
    //                     <Media.Body>
    //                         <h5 style={{ marginBottom: 0 }}>{category.title}</h5>
    //                     </Media.Body>
    //                 </Media>
    //             </ListGroup.Item>
    //         )
    //     }
    // }

    // )
    return (
      <div style={listGroupStyle}>
        <ListGroup>
          <ListGroup.Item style={listGroupItemStyle}>
            <h3 style={headingStyle}>Restaurants of the Week! </h3>
          </ListGroup.Item>
          <div style={{ cursor: "pointer" }}>{trendingTrucks}</div>
        </ListGroup>
        <ListGroup.Item style={listGroupItemStyle}>
          <h3 style={headingStyle}>Checkout the latest </h3>
        </ListGroup.Item>
        <InstaFeed account="kitchaco" numberOfMediaElements={7} />

        {/* <ListGroup as="ul">
                <ListGroup.Item onClick={this.toggleCusinesDropDown.bind(this)} style={listGroupItemDownDownStyle}>
                    <InputGroup >
                        <InputGroup.Prepend style={{ marginRight: 10 }}>
                            <InputGroup.Text id="basic-addon1" style={noPadding}>
                                <img
                                    src={require('../../../assets/img/profile_picture_default.png')}
                                    width="40"
                                    height="40"
                                    className="d-inline-block align-top"
                                    alt="Profile Pic"
                                    style={picStyle}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <h5 style={dropdownTitleStyle} className="form-control">Kitchen & Cusines </h5>
                        <InputGroup.Append>
                            <InputGroup.Text style={{ border: 0, backgroundColor: 'transparent' }}><KeyboardArrowDownIcon></KeyboardArrowDownIcon></InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>

                </ListGroup.Item>
                <SlideDown className={'my-dropdown-slidedown'}>
                    {openCusinesDropDown && displayCategories}
                </SlideDown>
            </ListGroup> */}
      </div>
    );
  }
}

export default SideBar;
