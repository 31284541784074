import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import * as Sentry from "@sentry/react";
import "date-fns";
import AdminNavBar from "../../components/UI/AdminNavBar/index.js";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
class AdminTxnReportScreen extends Component {
  state = {
    searchVendor: "",
    filteredVendors: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    adminKitchacoLocation:
      getFromLocalStorage("adminKitchacoLocation") != "undefined" &&
      getFromLocalStorage("adminKitchacoLocation") != "" &&
      getFromLocalStorage("adminKitchacoLocation") != null
        ? getFromLocalStorage("adminKitchacoLocation")
        : 0,
    vendors: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    salesReport: [],
    isLoading: false,
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    var endDate = moment(date).add(6, "days");
    this.setState({ endDate: endDate }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  onChangeKitchacoLocation(e) {
    this.setState({ adminKitchacoLocation: e.target.value }, () => {
      saveToLocalStorage(
        "adminKitchacoLocation",
        this.state.adminKitchacoLocation
      );
    });
  }

  getSalesReport() {
    const { startDate, endDate, adminKitchacoLocation } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "get_sales_report",
      kitchaco: parseInt(adminKitchacoLocation),
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          this.setState({ salesReport: dataResponse.data });
        } else {
          var result = window.confirm(
            "Report for this period is not available. Do you want to generate it now? This will generate the report and send transactional summary to the registered email id."
          );
          if (result) {
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  getTxnReport() {
    const { startDate, endDate, vendorId } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "get_transactional_report",
      vendor: vendorId,
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      email_report: true,
    });
    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          alert("An email has been sent to the registered email id");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
            // this.getOrders();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      vendors,
      isLoading,
      isSuperAdminLoggedIn,
      startDate,
      endDate,
      selectedVendorId,
      selectedVendorSearch,
      filteredVendors,
    } = this.state;
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };
    return (
      <>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={3} lg={3}>
                <div className="App">
                  <div className="search-bar-container">
                    <SearchBarVendor
                      vendorList={vendors}
                      placeHolder={"Search Vendors"}
                      setFilteredVendors={setFilteredVendors}
                      selectedVendorId={this.state.selectedVendorId} // Pass the selected event ID
                      selectedVendorSearch={this.state.selectedVendorSearch}
                    />
                    <SearchResultsListVendor
                      filteredVendors={filteredVendors}
                      selectedVendorP={selectedVendorP}
                      selectedVendorQ={selectedVendorQ}
                    />
                  </div>
                </div>
                {console.log(vendors)}
                {/* <Form.Label>Select Vendor</Form.Label>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedVendorId}
                    onChange={(e) => {
                      this.setState(
                        { selectedVendorId: "" + e.target.value },
                        () => {}
                      );
                      saveToLocalStorage(
                        "selectedVendorId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {vendors && vendors.length == 1 ? (
                      <option value={vendors[0].user_ptr_id}>
                        {vendors[0].title}
                      </option>
                    ) : (
                      <>
                        <option value={0}>Select Vendor</option>
                        {vendors &&
                          vendors.map((e) => {
                            return (
                              <option value={"" + e.user_ptr_id}>
                                {e.title}
                              </option>
                            );
                          })}
                      </>
                    )}
                  </Form.Control>
                </Form.Group> */}
              </Col>
              <Col xs={12} md={8} lg={8}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={3} md={3} lg={3}>
                    <p>Start Date</p>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={(date) => this.setStartDate(date)}
                      filterDate={(date) => {
                        const day = date.getDay();
                        return (
                          day !== 0 &&
                          day !== 2 &&
                          day !== 3 &&
                          day !== 4 &&
                          day !== 5 &&
                          day !== 6
                        );
                      }}
                    />
                  </Col>
                  <Col xs={3} md={3} lg={3}>
                    <p>End Date</p>
                    <p
                      style={{
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "black",
                        padding: "2px 1px 2px 5px",
                        backgroundColor: "#bbbcbd",
                        cursor: "not-allowed",
                      }}
                    >
                      {moment(endDate)
                        .format("DD/MM/yyyy")
                        .toString()}
                    </p>
                    {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} /> */}
                  </Col>
                  <Col xs={2} md={2} lg={2}></Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button
                      onClick={() => {
                        var result = window.confirm(
                          "Are you sure? This will generate the txn report and email it to registered email id"
                        );
                        if (result) {
                          this.getTxnReport();
                        }
                      }}
                    >
                      Generate
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminTxnReportScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
