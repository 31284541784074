import React, { Component } from "react";
import Container from "@mui/material/Container";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import TruckAnalysis from "../TruckAnalysis";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class TruckAnalytics extends Component {
  state = {
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
  };
  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: parseInt("-1"),
          selected_end_order_id: parseInt("-1"),
          selected_date: parseInt("-1"),
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  render() {
    const { stallList, operator } = this.props;
    return (
      <div>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <TruckAnalysis />
          <h4>Select the trucks to get Insights!(Coming Soon Stay Tuned)</h4>
          <div style={{ display: "flex", flexWrap: "wrap", margin: "0 -10px" }}>
            {stallList.map((stall, index) => (
              <Card
                sx={{ minHeight: "280px", width: 320, margin: "10px" }}
                key={index}
                className="cards"
              >
                <CardCover>
                  <img
                    src={stall.logo}
                    srcSet={stall.logo}
                    loading="lazy"
                    alt=""
                  />
                </CardCover>
                <CardCover
                  sx={{
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                  }}
                />
                <CardContent sx={{ justifyContent: "flex-end" }}>
                  <Typography level="h2" fontSize="lg" textColor="#fff" mb={1}>
                    {stall.title}
                  </Typography>
                  <Typography
                    startDecorator={<LocationOnRoundedIcon />}
                    textColor="neutral.300"
                  >
                    {stall.hometown}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}
export default Sentry.withErrorBoundary(TruckAnalytics, {
  fallback: <ErrorFallbackComponent />,
});
