import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Paper, Grid, Row, Col, Typography, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AWS_URL } from "../../assets/Constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ReactStars from "react-rating-stars-component";
import * as Sentry from "@sentry/react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    margin: "auto",
    overflow: "auto",
    height: 600,
    flexDirection: "column",
    gap: "0px 20px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: 128,
    maxHeight: 128,
  },
  textBox: {
    border: "2px solid",
    boxShadow: "5px 5px 5px 5px #888888",
    borderRadius: 20,
    marginBottom: 10,
  },
  datePicker: {
    margin: theme.spacing(1),
  },
}));

const AlertBox = ({
  open,
  handleClose,
  vendorDetail,
  eoiID,
  confirmVendor,
  rejectVendor,
}) => {
  console.log(vendorDetail.meals);
  const [menuStatus, setMenuStatus] = useState(false);
  const [vendorRating, setVendorRating] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [statementOfTrade, setStatementOfTrade] = useState([]);

  const handleCheckboxChange = (event, documentName) => {
    if (event.target.checked) {
      setSelectedDocuments((prev) => [...prev, documentName]);
    } else {
      setSelectedDocuments((prev) =>
        prev.filter((doc) => doc !== documentName)
      );
    }
  };

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const classes = useStyles();
  const [siteLocation, setSiteLocation] = useState("");
  const [bumpInDate, setBumpInDate] = useState("");
  const [bumpOutDate, setBumpOutDate] = useState("");
  console.log(vendorDetail);
  const {
    id,
    title,
    address,
    dates,
    location,
    logo,
    logo_map,
    number_of_10_amp,
    is_prices_altered_allowed,
    number_of_15_amp,
    number_of_20_amp,
    number_of_32_amp,
    is_cooler_required,
    food_refistration_docs,
    work_cover_insurance,
    working_with_childrens_check,
    fire_safety_certificate,
    motor_vehicle_insurance,
    food_safety_certificate,
    liablity_certificate,
    meals,
    electricial_certificate,
    gas_certificate,
    site_layout,
    streat_trader_file,
    other_uploads,
    truck_width,
    truck_depth,
    truck_height,
    truck_exit,
    instagram,
  } = vendorDetail;

  const getSOTForEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_sot_for_event",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data[0].statement_of_trade_file);
        setStatementOfTrade(response.data[0].statement_of_trade_file);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };

  const getVendorRatingForEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_rating_for_event",
          vendor_id: vendorDetail.id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.ratings_data.ratings_value__sum == null) {
          setVendorRating(9 / 2);
        } else {
          setVendorRating(response.ratings_data.ratings_value__sum / 2);
        }
        console.log(vendorRating);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };
  const alertVendorDocuments = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "upload_alert_for_vendors",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
          documents: selectedDocuments,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          alert("Alerted Successfully!");
        } else {
          alert("Please try again!");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        alert("Please try again!");
      });
  };
  const firstExample = {
    size: 30,
    isHalf: true,
    value: vendorRating,
    edit: false,
  };

  useEffect(() => {
    // getVendorRatingForEvent();
    getSOTForEvent();
  }, [vendorDetail]);

  useEffect(() => {
    console.log(vendorDetail);
    if (vendorDetail && Array.isArray(vendorDetail.meals)) {
      const isImageMissing = vendorDetail.meals.some(
        (meal) => !meal.image || meal.image.trim() === ""
      );

      if (isImageMissing) {
        console.log(false);
      }
    }
  }, [vendorDetail]);
  const generateContract = () => {
    let bumpin = moment(bumpInDate).format("DD MMM YYYY");
    let bumpout = moment(bumpOutDate).format("DD MMM YYYY");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "generate_eoi_contracts",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
          site_area: siteLocation,
          bump_in_date: bumpin,
          bump_out_date: bumpout,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          alert("Generated Successfully!");
        } else {
          alert("Please try again!");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        alert("Please try again!");
      });
  };

  useEffect(() => {
    console.log(vendorRating);
    console.log(firstExample);
  }, [vendorRating]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ position: "absolute", margin: 100, top: 300 }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.textBox}>
          <Grid item className={classes.image}>
            <img className={classes.img} alt="vendor-logo" src={logo} />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h5">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>{title}</div>
                  </div>
                </Typography>
                <Grid item xs></Grid>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TextField
          label="Site Location"
          variant="outlined"
          fullWidth
          value={siteLocation}
          onChange={(e) => setSiteLocation(e.target.value)}
          className={classes.datePicker}
        />

        {/* Bump-in Date Picker */}
        <div className={classes.datePicker}>
          <Typography variant="body1">Bump-in Date:</Typography>
          <DatePicker
            selected={bumpInDate}
            onChange={(date) => setBumpInDate(date)}
            dateFormat="dd MMM yyyy"
            placeholderText="Select Bump-in Date"
          />
        </div>

        {/* Bump-out Date Picker */}
        <div className={classes.datePicker}>
          <Typography variant="body1">Bump-out Date:</Typography>
          <DatePicker
            selected={bumpOutDate}
            onChange={(date) => setBumpOutDate(date)}
            dateFormat="dd MMM yyyy"
            placeholderText="Select Bump-out Date"
          />
        </div>
        <Tooltip
          title={
            vendorDetail && vendorDetail.meals
              ? vendorDetail.meals.every(
                  (meal) => meal.description && meal.image
                )
                ? ""
                : "The vendor doesn't have complete meal details or images, so you cannot select them. Please alert them to upload!"
              : "Vendor details are not available"
          }
        >
          <span>
            <Button
              onClick={() => generateContract(vendorDetail)}
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              disabled={
                !(
                  vendorDetail &&
                  vendorDetail.meals &&
                  vendorDetail.meals.every(
                    (meal) => meal.description && meal.image
                  )
                )
              }
            >
              Generate Contract
            </Button>
          </span>
        </Tooltip>

        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ margin: 10 }}
        >
          Close
        </Button>
      </Paper>
    </Modal>
  );
};

export default AlertBox;
