import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  FormControl,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Card,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { BUSY_TIMES } from "./utils";
import ImageUploader from "react-images-upload";
import { ShareSocial } from "react-share-social";

class AdminApplyBulkToEvents extends Component {
  state = {
    searchVendor: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    eventName: "",
    selectedVendorId: "0",
    eventOrganiserEmail: "",
    eventOrganiserFullName: "",
    showConfirmationModal: false,
    coverImage: "",
    onlineOrdering: true,
    selectedVendorList: [],
    selectedVendorNames: [],
    vendorList: [],
    stalls: [],
    tempStallsList: [],
    tempVendorList: [],
    eventDescription: "",
    coverImage: [],
    showSuccess: false,
    eventId: -1,
    errorArray: [],
    nonMembersCommission: "0",
    membersCommission: "0",
    siteFees: "0",
    selectAll: false,
    isSaving: false,
  };

  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConfirmationModal: !prevState.showConfirmationModal,
    }));
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState({ eventLocation: results[0].formatted_address }, () => {});
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({ coverImage: pictureDataURLs }, () => {});
  };

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events_to_apply",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            vendorList: response.vendors,
            tempVendorList: response.vendors,
          });
        }
      });
  };

  getStalls = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            stalls: response.vendors,
            tempStallsList: response.vendors,
          });
        }
      });
  };

  saveEvent = () => {
    this.toggleConfirmationModal();
  };

  handleSubmitConfirmed = () => {
    const {
      eventStartDateTime,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      email,
      eventName,
      eventType,
      eventStatus,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      stalls,
      tempStallsList,
      eventOrganiserEmail,
      eventOrganiserFullName,
    } = this.state;

    this.setState({ isSaving: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var stringVendor = selectedVendorList.map((i) => i.toString());
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "apply_bulk_events",
          vendor: this.state.selectedVendorId,
          events: stringVendor,
          adming_user: this.state.email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              showSuccess: true,
              isSaving: false,
            });
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      this.setState({ isSaving: false });
    }
  };

  handleSelectAll = (e) => {
    const { vendorList } = this.state;
    const selectAll = e.target.checked;
    const selectedVendorList = selectAll ? vendorList.map((vendor) => vendor.id) : [];
    const selectedVendorNames = selectAll ? vendorList.map((vendor) => vendor.event_name) : [];
    this.setState({ selectAll, selectedVendorList, selectedVendorNames });
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (isSuperAdminLoggedIn === "true" || isEventCoordinatorLoggedIn === "true") {
      this.getAllVendors();
      this.getStalls();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </>
    );
  }

  renderHome() {
    const {
      isSaving,
      searchVendor,
      eventName,
      selectedVendorName,
      vendorList,
      eventOrganiserFullName,
      eventOrganiserEmail,
      stalls,
      selectedVendorId,
      selectAll,
    } = this.state;
    return (
      <>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Row>
            <Col xs={12} md={12} xs={12}>
              <p>Fill up the below form to apply for the event with event organisers</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Select Stall</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId}
                      onChange={(e) => {
                        this.setState({ selectedVendorId: "" + e.target.value }, () => {});
                        saveToLocalStorage("selectedVendorId", "" + e.target.value);
                      }}
                    >
                      {stalls.length == 1 ? (
                        <option value={stalls[0].user_ptr_id}>{stalls[0].title}</option>
                      ) : (
                        <>
                          <option value={0}>Select The Vendor</option>
                          {stalls &&
                            stalls.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>{e.title}</option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form.Group>

                <Form.Group as={Col} controlId="my_multiselect_field" style={{ paddingLeft: 0 }}>
                  <Form.Label>*Select Events To Apply</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text value={searchVendor}>
                      <img style={{ width: 20 }} src={require("../../assets/img/search.gif")}></img>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search for vendors"
                      aria-label="Search for vendors"
                      onChange={(e) => {
                        var searchString = e.target.value;
                        if (searchString == "") {
                          this.setState({ vendorList: this.state.tempVendorList });
                        } else {
                          var result = vendorList.filter((obj) => {
                            if (obj.event_name.search(new RegExp(searchString, "i")) != -1) {
                              return obj;
                            }
                          });
                          this.setState({ vendorList: result });
                        }
                      }}
                    />
                  </InputGroup>

                  <Form.Check
                    type="checkbox"
                    id="selectAll"
                    label="Select All"
                    checked={selectAll}
                    onChange={this.handleSelectAll}
                  />
                  <div style={{ height: "200px", overflowY: "auto" }}>
                    {this.state.vendorList.map((vendor) => (
                      <Form.Check
                        key={vendor.id}
                        type="checkbox"
                        id={`vendor-${vendor.id}`}
                        label={vendor.event_name + " -- Event Type: " + vendor.event_type__job_type}
                        checked={this.state.selectedVendorList.includes(vendor.id)}
                        onChange={(e) => {
                          const vendorId = vendor.id;
                          const vendorName = vendor.event_name;
                          const selectedVendorList = [...this.state.selectedVendorList];
                          const selectedVendorNames = [...this.state.selectedVendorNames];

                          if (e.target.checked) {
                            selectedVendorList.push(vendorId);
                            selectedVendorNames.push(vendorName);
                          } else {
                            const index = selectedVendorList.indexOf(vendorId);
                            if (index >= 0) {
                              selectedVendorList.splice(index, 1);
                              selectedVendorNames.splice(index, 1);
                            }
                          }

                          this.setState({ selectedVendorList, selectedVendorNames });
                        }}
                      />
                    ))}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1"></Form.Group>

                <Button
                  variant="primary"
                  type="button"
                  onClick={this.saveEvent}
                  disabled={isSaving} // Disable the button while saving
                >
                  {isSaving ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Saving...
                    </>
                  ) : (
                    "Submit"
                  )}{" "}
                  {/* Display loading indicator */}
                </Button>
              </Form>
              <div>
                <h4>Selected Events To Apply:</h4>
                <ul>
                  {this.state.selectedVendorNames.map((vendorName) => (
                    <li key={vendorName}>{vendorName}</li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>

          {this.renderSuccess()}
          {this.renderConfirmationModal()}
        </Container>
        <Footer />
      </>
    );
  }

  renderSuccess() {
    const { showSuccess, eventId, errorArray } = this.state;
    var url = window.location.href.replace("/admin-share-multiple-stalls", "");
    return (
      <>
        <Modal show={showSuccess} onHide={() => { window.location.reload(); }}>
          <Modal.Header closeButton>
            <Modal.Title>Submitted Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }

  renderConfirmationModal() {
    const { showConfirmationModal, eventName, selectedVendorNames, selectedVendorList } = this.state;
    return (
      <Modal show={showConfirmationModal} onHide={this.toggleConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to apply for the event <strong>{eventName}</strong> for the following{" "}
          <strong>{selectedVendorList.length}</strong> events?
          <ul>
            {selectedVendorNames.map((vendorName) => (
              <li key={vendorName}>{vendorName}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSubmitConfirmed}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminApplyBulkToEvents, {
    fallback: <ErrorFallbackComponent />,
  })
);
