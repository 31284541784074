import React from "react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../store";

const SearchResultVendor = ({
  filteredVendor,
  filteredId,
  selectedVendor,
  selectedVendora,
}) => {
  const handleFilter = () => {
    saveToLocalStorage("selectedVendorSearch", "" + filteredVendor);
    selectedVendor(filteredVendor);
    selectedVendora(filteredId);
  };
  return (
    <div className="search-result" onClick={handleFilter}>
      {filteredVendor}
    </div>
  );
};

export default SearchResultVendor;
