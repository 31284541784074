import { GET_DIET, GET_KITCHEN } from "../actions/VendorAction";

const initialState = {
  diet: "",
  kitchen: "",
};

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIET:
      return {
        ...state,
        diet: action.payload,
      };

    case GET_KITCHEN:
      return {
        ...state,
        kitchen: action.payload,
      };

    default:
      return state;
  }
}
