import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Carousel,
  Button,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { AWS_URL } from "../../assets/Constants";
import Footer from "../../components/UI/Footer";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import EOIPage from "../../components/UI/EOI/index";
import Modal from "react-bootstrap/Modal";
import "./style.css";  // Updated CSS file for modern design

const SharedOutlet = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isOpenEOIModal, set_isOpenEOIModal] = useState(false);
  const location = useLocation();

  const handleClose = () => {
    set_isOpenEOIModal(false);
  };

  const handleOpen = () => {
    set_isOpenEOIModal(true);
  };

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const handleDocumentClick = async (docType) => {
    try {
      const hashcode = query.get("hashcode");
      await axios.post(
        config.BASE_URI + apiPaths.adminData,
        {
          payload: {
            body: {
              query_type: "update-document-click",
              document_type: docType,
              hashcode: hashcode,
            },
          },
        }
      );
      console.log(`Document ${docType} click logged successfully.`);
    } catch (err) {
      console.error("Error logging document click:", err.message);
    }
  };

  const query = useQuery();
  const hashcode = query.get("hashcode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          config.BASE_URI + apiPaths.organiserData,
          {
            payload: {
              body: {
                query_type: "fetch_shared_outlet",
                hashcode: hashcode,
              },
            },
          }
        );

        if (response.data.success) {
          setData(response.data.vendors);
        } else {
          setError(response.data.err);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [hashcode]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ResponsiveNavBar
        showBack={false}
        backUrl={"/"}
        ticketState={{}}
        products={{}}
        loggedIn={false}
        username={""}
        userd={""}
        dispatch={() => {}}
      ></ResponsiveNavBar>
      <Container className="modern-container">
        <img
          className="d-block w-100 hero-image"
          src={require("../../assets/img/wtt.png")}
          alt=""
        />
        <Button
          className="modern-button"
          onClick={handleOpen}
        >
          Book {data.vendor__title}
        </Button>

        <Row>
          <Col md={12}>
            <Card className="modern-card">
              <Card.Header className="vendor-header modern-header">
                {data.vendor__title}
              </Card.Header>
              <Card.Body>
                <Image
                  src={AWS_URL + "/" + data.vendor__logo}
                  alt={data.vendor__title}
                  fluid
                  className="vendor-image modern-image"
                />

                <Card.Text className="modern-text">
                  {data.vendor__bio}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card className="modern-card">
              <Card.Header className="vendor-header modern-header">
                <h4>Stall Details</h4>
              </Card.Header>
              <Card.Body>
                <p>
                  <strong>Dimensions:</strong>{" "}
                  {[
                    `L ${data.vendor__truck_width || "N/A"}m x `,
                    `H ${data.vendor__truck_height || "N/A"}m x `,
                    `W ${data.vendor__truck_depth || "N/A"}m`,
                  ]
                    .filter(Boolean)
                    .join(" ")}
                </p>
                <p>
                  <strong>Fire Source:</strong> {data.vendor__fire_source || "N/A"}
                </p>
                <p>
                  <strong>Water Access:</strong> {data.vendor__water_access || "N/A"}
                </p>
                <p>
                  <strong>Service Side:</strong> {data.vendor__service_side || "N/A"}
                </p>
                <p>
                  <strong>Outlet Type:</strong>{" "}
                  {data.vendor__outlet_type__outlet_type || "N/A"}
                </p>
                <div>
                  {data.vendor__number_of_15_amp > 0 && (
                    <p>
                      <strong>Number of 15 Amp:</strong>{" "}
                      {data.vendor__number_of_15_amp}
                    </p>
                  )}

                  {data.vendor__number_of_10_amp > 0 && (
                    <p>
                      <strong>Number of 10 Amp:</strong>{" "}
                      {data.vendor__number_of_10_amp}
                    </p>
                  )}

                  {data.vendor__number_of_20_amp > 0 && (
                    <p>
                      <strong>Number of 20 Amp:</strong>{" "}
                      {data.vendor__number_of_20_amp}
                    </p>
                  )}

                  {data.vendor__number_of_32_amp > 0 && (
                    <p>
                      <strong>Number of 32 Amp:</strong>{" "}
                      {data.vendor__number_of_32_amp}
                    </p>
                  )}

                  {data.vendor__staff_required_at_event > 0 && (
                    <p>
                      <strong>Number of Staff at the event:</strong>{" "}
                      {data.vendor__staff_required_at_event}
                    </p>
                  )}
                  {data.vendor__sister_brands && (
                    <p>
                      <strong>Sister Brands:</strong>{" "}
                      {data.vendor__sister_brands}
                    </p>
                  )}
                  {data.vendor__serves_per_hour > 0 && (
                    <p>
                      <strong>Serves Per Hour:</strong>{" "}
                      {data.vendor__serves_per_hour}
                    </p>
                  )}
                  {data.vendor__company_name && (
                    <p>
                      <strong>Company Name:</strong>{" "}
                      {data.vendor__company_name}
                    </p>
                  )}
                  {data.vendor__trading_name && (
                    <p>
                      <strong>Trading Name:</strong>{" "}
                      {data.vendor__trading_name}
                    </p>
                  )}

                  {data.vendor__major_events_trucks_worked_at && (
                    <p>
                      <strong>Major Events Participated In:</strong>{" "}
                      {data.vendor__major_events_trucks_worked_at}
                    </p>
                  )}

                  {data.vendor__sustanablity_practices && (
                    <p>
                      <strong>Sustanablity Practices:</strong>{" "}
                      {data.vendor__sustanablity_practices}
                    </p>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card className="modern-card">
              <Card.Header className="vendor-header modern-header">
                <h4>Stall Documents</h4>
              </Card.Header>
              <Card.Body>
                {data.vendor__food_refistration_docs && (
                  <p>
                    <strong>Council Registration Certificate:</strong>{" "}
                    <a
                      href={AWS_URL + "/" + data.vendor__food_refistration_docs}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Council Registration Certificate
                    </a>
                  </p>
                )}
                {data.vendor__food_safety_certificate && (
                  <p>
                    <strong>Food Safety Certificate:</strong>{" "}
                    <a
                      href={
                        AWS_URL + "/" + data.vendor__food_safety_certificate
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        handleDocumentClick("Food Safety Certificate")
                      }
                    >
                      Food Safety Certificate
                    </a>
                  </p>
                )}
                {data.vendor__liablity_certificate && (
                  <p>
                    <strong>Public Liability Certificate:</strong>{" "}
                    <a
                      href={AWS_URL + "/" + data.vendor__liablity_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        handleDocumentClick("Public Liability Certificate")
                      }
                    >
                      Public Liability Certificate
                    </a>
                  </p>
                )}
                {data.vendor__work_cover_insurance && (
                  <p>
                    <strong>Work Cover:</strong>{" "}
                    <a
                      href={AWS_URL + "/" + data.vendor__work_cover_insurance}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleDocumentClick("Work Cover")}
                    >
                      Work Cover
                    </a>
                  </p>
                )}
                {data.operator_shared_statement_of_trade_file && (
                  <p>
                    <strong>Statement Of Trade:</strong>{" "}
                    <a
                      href={
                        AWS_URL +
                        "/" +
                        data.operator_shared_statement_of_trade_file
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleDocumentClick("Statement Of Trade")}
                    >
                      Statement Of Trade
                    </a>
                  </p>
                )}
                {data.vendor__site_layout && (
                  <p>
                    <strong>Site Layout:</strong>{" "}
                    <a
                      href={AWS_URL + "/" + data.vendor__site_layout}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleDocumentClick("Site Layout")}
                    >
                      Site Layout
                    </a>
                  </p>
                )}
                {data.vendor__working_with_childrens_check && (
                  <p>
                    <strong>Working With Children's Check:</strong>{" "}
                    <a
                      href={
                        AWS_URL +
                        "/" +
                        data.vendor__working_with_childrens_check
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        handleDocumentClick("Working With Children's Check")
                      }
                    >
                      Working With Children's Check
                    </a>
                  </p>
                )}
                {data.vendor__electricial_certificate && (
                  <p>
                    <strong>Electrical Certificate:</strong>{" "}
                    <a
                      href={
                        AWS_URL + "/" + data.vendor__electricial_certificate
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        handleDocumentClick("Electrical Certificate")
                      }
                    >
                      Electrical Certificate
                    </a>
                  </p>
                )}
                {data.vendor__gas_certificate && (
                  <p>
                    <strong>Gas Plate:</strong>{" "}
                    <a
                      href={AWS_URL + "/" + data.vendor__gas_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleDocumentClick("Gas Plate")}
                    >
                      Gas Plate
                    </a>
                  </p>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card className="modern-card">
              <Card.Header className="vendor-header modern-header">
                <h4>Images</h4>
              </Card.Header>
              <Card.Body>
                <Carousel>
                  {data.truck_images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={AWS_URL + "/" + image.image}
                        alt={`Truck Images ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Carousel>
                  {data.staff_uniform_images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={AWS_URL + "/" + image.image}
                        alt={`Staff Uniform ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="modern-card">
              <Card.Header className="vendor-header modern-header">
                <h4>Menu</h4>
              </Card.Header>
              <Card.Body>
                <div>
                  {data.meals
                    .reduce((acc, meal, index, array) => {
                      if (index % 2 === 0) {
                        acc.push(array.slice(index, index + 2));
                      }
                      return acc;
                    }, [])
                    .map((mealPair, idx) => (
                      <Row key={idx}>
                        {mealPair.map((meal) => (
                          <Col md={6} key={meal.id}>
                            <Card className="modern-card">
                              <Card.Header className="vendor-subheader modern-subheader">
                                {meal.name} -- {meal.category__title}
                              </Card.Header>
                              <Card.Body>
                                <Image
                                  className="meal-image modern-meal-image"
                                  src={AWS_URL + "/" + meal.image}
                                  alt={meal.name}
                                  fluid
                                />
                                <Card.Text>{meal.description}</Card.Text>
                                <Card.Text>
                                  Dietary:{" "}
                                  {meal.diets__title === null
                                    ? "Not Specified"
                                    : meal.diets__title}
                                </Card.Text>
                                <Card.Text>Price: ${meal.price}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={isOpenEOIModal} onHide={handleClose}>
        <EOIPage handleClose={handleClose} />
      </Modal>

      <Footer />
    </>
  );
};

export default SharedOutlet;
