import React from "react";
import { Accordion, Card, Form} from 'react-bootstrap';
import CustTextArea from '../CustTextArea';

const OtherComments = (props) => {
    const {formik, viewOnly} = props;

    return (
        <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
            7. OTHER COMMENTS
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
        <Card.Body>
            {!viewOnly ? (
                <div className="title-heading">
                <h6 >Other Comments</h6>
                <CustTextArea 
                    label={"Other Comments"}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"otherComments"}
                    defaultValue={formik.values.otherComments}
                />
               </div>
            ):(
                <div className="title-heading">
                <h6 >Other Comments</h6>
                <Form.Control as="textarea" rows={10} 
                    label={"Other Comments"}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"otherComments"}
                    defaultValue={formik.values.otherComments}
                    readOnly={true}
                    style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
                </div>
            )
            }
        </Card.Body>
        </Accordion.Collapse>
        </Card>
    );
}

export default OtherComments;