import { connect } from "react-redux";
import { vendorActions } from "../../../actions/VendorAction";
import React, { Component } from "react";

class MenuTable extends Component {
  constructor(props) {
    super(props);
    const rows = props.formik.values.menu;
    const rowsVisited = props.formik.values.electricalAppliances.map(() => {
      return {
        idx: false,
        cuisineType: false,
        productName: false,
        prices: false,
      };
    });
    this.state = {
      rows: rows,
      rowsVisited: rowsVisited,
    };
    this.formik = props.formik;
  }

  componentDidMount() {
    this.props.getDiet();
    this.props.getKitchen();
  }

  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      ...rows[idx],
      [name]: value,
    };
    this.setState({ ...this.state, rows: rows }, () =>
      this.formik.setFieldValue("menu", this.state.rows)
    );
  };

  handleClick = (idx) => (e) => {
    const { name, value } = e.target;
    const rowsVisited = [...this.state.rowsVisited];
    rowsVisited[idx] = {
      ...rowsVisited[idx],
      [name]: true,
    };
    const newState = { ...this.state, rowsVisited: rowsVisited };
    this.setState(newState, () =>
      this.formik.setFieldTouched("menu", this.state.rowsVisited)
    );
  };

  handleAddRow = (e) => {
    e.preventDefault();
    const item = {
      idx: "",
      cuisineType: "",
      productName: "",
      prices: "",
    };

    const visited = {
      idx: false,
      cuisineType: false,
      productName: false,
      prices: false,
    };

    this.setState({
      rows: [...this.state.rows, item],
      rowsVisited: [...this.state.rowsVisited, visited],
    });
  };

  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1),
      rowsVisited: this.state.rowsVisited.slice(0, -1),
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);

    const rowsVisited = [...this.state.rowsVisited];
    rowsVisited.splice(idx, 1);
    this.setState({ rows, rowsVisited }, () => {
      this.formik.setFieldValue("menu", this.state.rows);
      this.formik.setFieldTouched("menu", this.state.rowsVisited);
    });
  };

  render() {
    return (
      <div>
        <div className="container" style={{ overflow: "auto" }}>
          <div className="row clearfix">
            <div className="column">
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
                style={{ minWidth: "1200px" }}
              >
                <thead>
                  <tr>
                    <th className="text-center"> ID </th>
                    <th className="text-center"> Type of Cuisine </th>
                    <th className="text-center"> Product Name </th>
                    <th className="text-center"> Prices </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx}</td>

                      <td>
                        <select
                          name="cuisineType"
                          className="form-select form-control"
                          onChange={this.handleChange(idx)}
                          onClick={this.handleClick(idx)}
                          value={this.state.rows[idx].cuisineType}
                        >
                          {this.props.kitchen
                            ? this.props.kitchen.map((value) => (
                                <option value={value}>
                                  {value === "" ? "Please Select" : value}
                                </option>
                              ))
                            : null}
                        </select>

                        {this.state.rowsVisited[idx]["cuisineType"] &&
                        !this.state.rows[idx]["cuisineType"] ? (
                          <div style={{ color: "red" }}>{"Required"}</div>
                        ) : null}
                      </td>

                      <td>
                        <input
                          type="text"
                          name="productName"
                          value={this.state.rows[idx].productName}
                          onChange={this.handleChange(idx)}
                          onClick={this.handleClick(idx)}
                          className="form-control"
                        />

                        {this.state.rowsVisited[idx]["productName"] &&
                        !this.state.rows[idx]["productName"] ? (
                          <div style={{ color: "red" }}>{"Required"}</div>
                        ) : null}
                      </td>

                      <td>
                        <input
                          type="number"
                          min={0}
                          name="prices"
                          value={this.state.rows[idx].prices}
                          onChange={this.handleChange(idx)}
                          onClick={this.handleClick(idx)}
                          className="form-control"
                        />

                        {this.state.rowsVisited[idx]["prices"] &&
                        !this.state.rows[idx]["prices"] ? (
                          <div style={{ color: "red" }}>{"Required"}</div>
                        ) : null}
                        {this.state.rows[idx]["prices"] &&
                        !/^[1-9]\d*(\.\d+)?$/i.test(
                          this.state.rows[idx]["prices"]
                        ) ? (
                          <div style={{ color: "red" }}>{"Invalid Number"}</div>
                        ) : null}
                      </td>

                      <td>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={this.handleRemoveSpecificRow(idx)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={this.handleAddRow} className="btn btn-primary">
                Add Item
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    diet: state.vendorReducer.diet,
    kitchen: state.vendorReducer.kitchen,
  };
}

const actionCreators = {
  getDiet: vendorActions.getDiet,
  getKitchen: vendorActions.getKitchen,
};

export default connect(mapStateToProps, actionCreators)(MenuTable);
