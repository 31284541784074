import React, { useState } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage } from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { format } from "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

const VendorStoreRoom = () => {
  //   const operatorData = JSON.parse(getFromLocalStorage("Operator") ?? [])[0];
  const [storeRoomName, setStoreRoomName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [eventId, setEventId] = useState(null);
  const [eventName, setEventName] = useState("");

  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [storeRooms, setStoreRooms] = useState([]);

  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(false);
  const [isTruckManager, setIsTruckManager] = useState(false);
  const [isOperatorOperations, setIsOperatorOperations] = useState(false);

  const getStoreRooms = (event_id) => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    const operatorId = operator[0].id;

    setIsLoading(true);
    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_event_storerooms",
          operator_id: operatorId ? operatorId : null,
          event_id: Number(event_id),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          setStoreRooms(dataResponse.storerooms);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    setIsLoading(true);
    //     const operator = JSON.parse(getFromLocalStorage("Operator"));
    //     const operatorId = operator[0].id;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email:
            getFromLocalStorage("email") != "undefined" &&
            getFromLocalStorage("email") != "" &&
            getFromLocalStorage("email") != null
              ? getFromLocalStorage("email")
              : "",
          password:
            getFromLocalStorage("password") != "undefined" &&
            getFromLocalStorage("password") != "" &&
            getFromLocalStorage("password") != null
              ? getFromLocalStorage("password")
              : "",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          console.log(dataResponse);
          setFetchedEvents(dataResponse?.events ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.operatorStockData,
        {
          payload: {
            body: {
              query_type: "create_event_storeroom",
              event_id: eventId,
              store_room_name: storeRoomName,
              operator_id: operator[0].id,
            },
          },
        }
      );
      setIsLoading(false);

      if (response.data.success === true) {
        alert("Store Room has been created successfully!");
        getStoreRooms(eventId);
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      alert("Something went wrong. Please try again.");
    }
  };

  const renderNavBar = () => {
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  };

  return (
    <>
      {renderNavBar()}
      {!isLoading ? (
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <h2>Event Store Room</h2>
          <div>
            <div>
              <label>Select Event:</label>
              <select
                style={{ width: "100%" }}
                onChange={(e) => {
                  setEventId(e.target.value);
                  getStoreRooms(Number(e.target.value));
                }}
                value={eventId || ""}
              >
                <option value="">Select Event</option>
                {fetchedEvents.map((event, index) => (
                  <option key={index} value={event?.id}>
                    {event?.name || ""}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <Box>
            {eventId ? (
              <form className="event-form-container" onSubmit={onSubmit}>
                {/* <div>
                  Note: Please fill all the fields marked as{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Divider /> */}

                <Divider />
                <div className="event-form-item">
                  <label htmlFor="logo" className="event-form-control">
                    Store Room Name
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={storeRoomName}
                    onChange={(e) => {
                      setStoreRoomName(e.target.value);
                    }}
                  />
                </div>

                <button
                  style={{ margin: 0 }}
                  className="form-button"
                  type="submit"
                >
                  Add Store Room
                </button>
              </form>
            ) : null}
          </Box>

          {eventId && storeRooms ? (
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h4>Store Rooms List</h4>
                </Col>
              </Row>

              <Row className="table-header-row">
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Store Room ID
                </Col>
                <Col xs={12} md={10} lg={10} className="table-header-col">
                  Store Room Name
                </Col>
              </Row>
              {storeRooms.map((item) => {
                return (
                  <>
                    <Row
                      className="table-row card-row"
                      onClick={() => {
                        window.location.href =
                          "/operator-stock-inventory?storeroom=" +
                          item.store_room_name +
                          "&id=" +
                          item.id;
                      }}
                    >
                      <Col xs={12} md={2} lg={2} className="table-col">
                        {item.id}
                      </Col>

                      <Col
                        xs={12}
                        md={10}
                        lg={10}
                        className="table-col"
                        style={{ textAlign: "center" }}
                      >
                        {item.store_room_name}
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Container>
          ) : null}
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={require("../../assets/img/loading.gif")}></img>
        </div>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(VendorStoreRoom, {
  fallback: <ErrorFallbackComponent />,
});
