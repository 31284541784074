import React, { Component } from "react";
import {
  CURRENCY,
  TRANSACTION_FEE,
  EVENT_FEE,
  APP_FEE,
  KITCHACO_DELIVERY_FEE,
  SQUARE_ACCESS_TOKEN,
  SQUARE_API_VERSION,
  PAYMENT_URL,
  EMAIL_ORDER_NOTIFICATIONS,
  SQUARE_APP_ID,
  LOCATION_ID,
} from "../../../assets/Constants";
import { payment, orderNow } from "../../../actions";
import moment from "moment";
import {
  store,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store/index";
import { applyToken } from "../../../services/api";
import {
  updateCartTicket,
  updateCartTotal,
  clearSelectedVendor,
  updateCartProducts,
} from "../../../actions";
import { sendEmailConfirmation } from "./sendEmailConfirmation";
import { token } from "morgan";
const styles = {
  name: {
    verticalAlign: "top",
    display: "none",
    margin: 0,
    borderWidth: 1,
    borderColor: "#e8e9ea",
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#000",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
    marginRight: 10,
    width: 290,
  },
  leftCenter: {
    float: "left",
    textAlign: "center",
    color: "black",
  },
  blockRight: {
    display: "block",
    float: "right",
  },
  center: {
    textAlign: "center",
  },
};

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
      paymentStatusState: "",
      paymentSourceState: "",
      paymentReceiptNumberState: "",
      paymentErrorState: "",
      paymentErrorDetailsState: "",
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentTotalCharges: 0.0,
      deliveryCharges: 0.0,
      isPaymentLoading: false,
      card: undefined,
    };
    this.requestCardNonce = this.requestCardNonce.bind(this);
    var selectedVendorData = JSON.parse(
      getFromLocalStorage("selectedVendorData")
    );
  }

  orderNowDishes = () => {
    const { appReducer } = store.getState();
    var selectedVendorData = JSON.parse(
      getFromLocalStorage("selectedVendorData")
    );
    var selectedEvent = JSON.parse(getFromLocalStorage("selectedEvent"));
    const {
      username,
      userId,
      orderLater,
      orderDate,
      orderType,
      orderLocation,
      serviceFee,
      paymentStatus,
      paymentSource,
      paymentReceiptNumber,
      paymentWttCharges,
      paymentTxnCharges,
      paymentTotalCharges,
      eventId,
    } = appReducer;

    const { productsState, orderDateState, ticketState } = this.props;

    var sub_total = 0;

    const newDishes = [];

    for (var key in productsState) {
      newDishes.push({
        quantity: productsState[key].quantity,
        id: productsState[key].id,
        request: productsState[key].properties.SpecialRequest.toString(),
        combo: productsState[key].properties.Combo.toString(),
        subitem: productsState[key].properties.Subitem.toString(),
      });
      sub_total =
        Number(sub_total) +
        Number(productsState[key].quantity) *
          Number(productsState[key].prices.AUD);
    }

    var cartEventTicket = {};
    for (var key in ticketState) {
      var payment_total_amount =
        Number(ticketState[key].quantity) *
        Number(ticketState[key].ticketPrice);
      sub_total = Number(sub_total) + Number(payment_total_amount);
      cartEventTicket = {
        event: selectedEvent.id,
        quantity: ticketState[key].quantity,
        one_ticket_price: ticketState[key].ticketPrice,
        payment_total_amount: payment_total_amount,
      };
    }

    const data = {
      customer_name: username,
      customer: userId,
      vendor: selectedVendorData.id,
      meals: [...newDishes],
      payment_status: paymentStatus,
      payment_source: paymentSource,
      payment_receipt_number: paymentReceiptNumber,
      payment_wtt_charges: paymentWttCharges,
      payment_txn_charges: paymentTxnCharges,
      payment_total_amount: paymentTotalCharges,
      order_date: orderDate,
      order_time: this.props.order_time,
      order_special_request: "",
      vendor_name: selectedVendorData.title,
      order_later:
        typeof orderLater == "string" ? JSON.parse(orderLater) : orderLater,
      order_type: orderType,
      event_location: orderLocation,
      service_fee: serviceFee,
      event_id: eventId,
      discount: Number(getFromLocalStorage("discount")).toFixed(2),
      promo_code: this.props.applyPromoCode,
      delivery_charge: this.props.deliveryCharges,
      event_ticket: cartEventTicket,
      sub_total: sub_total,
    };

    this.props.dispatch(orderNow(data)).then((orderResponse) => {
      if (orderResponse && orderResponse["payload"]) {
        var response = orderResponse["payload"];
        if (response.status === "Received") {
          this.orderPlacedSuccessfully();
        }
      } else {
        alert(
          "There was an error processing your order! If your money was deducted, it would be refunded in full within 2 business days."
        );
        window.location.reload(true);
      }
    });
  };

  orderPlacedSuccessfully = () => {
    alert("Order Placed Succesfully!");
    const { appReducer } = store.getState();
    const { eventTicket } = appReducer;
    const { dispatch } = this.props;
    dispatch(updateCartTicket({}));
    dispatch(updateCartTotal(0.0));
    deleteFromLocalStorage("selectedEvent");
    dispatch(clearSelectedVendor());
    dispatch(updateCartProducts({}));
    dispatch(updateCartTotal(0));

    deleteFromLocalStorage("orderStatus", "");
    deleteFromLocalStorage("orderDate", "");
    deleteFromLocalStorage("paymentStatus", "");
    deleteFromLocalStorage("paymentSource", "");
    deleteFromLocalStorage("paymentReceiptNumber", "");
    deleteFromLocalStorage("paymentWttCharges", 0);
    deleteFromLocalStorage("paymentTxnCharges", 0);
    deleteFromLocalStorage("paymentTotalCharges", 0);
    deleteFromLocalStorage("paymentError", "");
    deleteFromLocalStorage("eventTicket", "{}");
    deleteFromLocalStorage("productsTotal", 0);
    deleteFromLocalStorage("orderType", "");
    deleteFromLocalStorage("orderLocation", "");
    deleteFromLocalStorage("eventId", "");
    deleteFromLocalStorage("orderLater", false);
    deleteFromLocalStorage("deliveryCharges", 0);
    deleteFromLocalStorage("serviceFee", 0);
    deleteFromLocalStorage("applyPromoCode", "");
    deleteFromLocalStorage("discount", 0);
    window.location.href = "/orders";
  };

  requestCardNonce() {
    if (this.state.isPaymentLoading == false) {
      this.setState({ isPaymentLoading: true }, () => {
        this.paymentForm.requestCardNonce();
      });
    }
  }

  makePayment = (
    amount_money,
    source_id,
    idempotency_key,
    currency,
    app_fee_money,
    tickets,
    dishes
  ) => {
    let data = {
      url: PAYMENT_URL,
      token: SQUARE_ACCESS_TOKEN,
      square_api_version: SQUARE_API_VERSION,
      crossDomain: true,
      method: "POST",
      body: JSON.stringify({
        source_id: source_id,
        idempotency_key: idempotency_key,
        amount_money: {
          amount: amount_money,
          currency: currency,
        },
        app_fee_money: {
          amount: app_fee_money,
          currency: currency,
        },
        autocomplete: true,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + SQUARE_ACCESS_TOKEN,
        "Square-Version": SQUARE_API_VERSION,
      },
    };
    this.props.dispatch(payment(data)).then((response) => {
      var paymentResponse = response.payload;
      if (paymentResponse != undefined) {
        if (
          paymentResponse.payment != undefined &&
          paymentResponse.payment.status != "CANCELLED" &&
          paymentResponse.payment.status != "FAILED"
        ) {
          this.setState(
            {
              paymentStatusState: paymentResponse.payment.status,
              paymentSourceState: paymentResponse.payment.source_type,
              paymentReceiptNumberState: paymentResponse.payment.receipt_number,
              paymentWttChargesState:
                paymentResponse.payment.app_fee_money.amount / 100,
              paymentTxnChargesState:
                Math.round(TRANSACTION_FEE * amount_money * 100) / 100 / 100,
              paymentTotalChargesState:
                paymentResponse.payment.total_money.amount / 100,
              paymentError: "",
            },
            () => {
              sendEmailConfirmation(
                dishes,
                this.state.ticketState,
                this.props.sub_total,
                this.props.discount,
                this.props.total
              );

              applyToken(getFromLocalStorage("token"));
              this.orderNowDishes();
            }
          );
        } else {
          var error = "Payment Error! Please try again.";
          if (paymentResponse.errors[0].code === "INVALID_EXPIRATION") {
            error = "Invalid Card Expiry. Please try again!";
          } else if (paymentResponse.errors[0].code === "GENERIC_DECLINE") {
            error = "Card Declined. Please try again!";
          } else if (paymentResponse.errors[0].code === "CVV_FAILURE") {
            error = "Invalid CVV. Please try again!";
          }
          this.setState(
            { paymentError: "FAILED", paymentErrorDetails: error },
            () => {
              window.location.reload(true);
              // this.onCardEntryCancel();
            }
          );
          alert(error);
        }
      }
    });
  };

  // Loading Card UI

  onLoad = async () => {
    if (!this.props.paymentForm) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = this.props.paymentForm;
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    let card;
    try {
      card = await this.initializeCard(payments);
    } catch (e) {
      console.error("initialize card error!", e);
      return;
    }
  };

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    this.setState({
      card: card,
    });
    return card;
  }

  async createPayment(token) {
    const body = JSON.stringify({
      LOCATION_ID,
      sourceId: token,
      crossDomain: true,
    });

    const paymentResponse = await fetch(PAYMENT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
      body,
    });

    if (paymentResponse.ok) {
      return paymentResponse.json();
    }

    const errorBody = await paymentResponse.text();
    throw new Error(errorBody);
  }

  async tokenize(card) {
    const tokenResult = await card.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  placeOrder = async (event, paymentMethod, shouldVerify = false) => {
    if (this.state.isPaymentLoading == false) {
      this.setState({ isPaymentLoading: true });
    }
    const cardButton = document.getElementById("card-button");
    const {
      user,
      sub_total,
      deliveryCharges,
      orderType,
      ticketState,
      productsState,
    } = this.props;
    try {
      //cardButton.disabled = true
      const token = await this.tokenize(paymentMethod);
      let source_id = token;
      let idempotency_key = "" + user + "" + Date.now();
      let currency = CURRENCY;

      let amount_money =
        Number(sub_total) +
        Number(getFromLocalStorage("serviceFee")) -
        Number(this.props.discount);

      //PAYMENT STEP 3 - Convert the amount_money to cents
      amount_money = parseInt(
        (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
      ); // rounded in cents

      // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
      // make sure this is also in cents

      var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

      // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
      app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

      // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery
      if (orderType == "delivery") {
        app_fee_money += KITCHACO_DELIVERY_FEE;
      }

      const paymentResults = await this.makePayment(
        amount_money,
        source_id,
        idempotency_key,
        currency,
        app_fee_money,
        ticketState,
        productsState
      );
    } catch (e) {
      cardButton.disabled = false;
      console.error(e.message);
    }
  };

  componentDidMount() {
    const config = {
      applicationId: SQUARE_APP_ID,
      locationId: LOCATION_ID,
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Helvetica Neue",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#000",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale",
        },
      ],
      applePay: {
        elementId: "sq-apple-pay",
      },
      masterpass: {
        elementId: "sq-masterpass",
      },
      googlePay: {
        elementId: "sq-google-pay",
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "• • • •  • • • •  • • • •  • • • •",
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV",
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY",
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Post Code",
      },
      callbacks: {
        methodsSupported: (methods) => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay,
            });
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay,
            });
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass,
            });
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "AUD",
            countryCode: "AU",
            total: {
              label: "MERCHANT NAME",
              amount: "100",
              pending: false,
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "100",
                pending: false,
              },
            ],
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          const {
            user,
            sub_total,
            deliveryCharges,
            orderType,
            ticketState,
            productsState,
          } = this.props;
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            errors.forEach(function(error) {});

            return;
          }
          // PAYMENT STEP 1 - Initialise
          var source_id = nonce;
          var idempotency_key = "" + user + "" + Date.now();
          var currency = CURRENCY;

          var amount_money =
            Number(sub_total) +
            Number(getFromLocalStorage("serviceFee")) -
            Number(this.props.discount);

          //PAYMENT STEP 3 - Convert the amount_money to cents
          amount_money = parseInt(
            (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
          ); // rounded in cents

          // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
          // make sure this is also in cents

          var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

          // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
          app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

          // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery
          if (orderType == "delivery") {
            app_fee_money += KITCHACO_DELIVERY_FEE;
          }

          this.makePayment(
            amount_money,
            source_id,
            idempotency_key,
            currency,
            app_fee_money,
            ticketState,
            productsState
          );

          this.setState({
            nonce: nonce,
          });
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("error").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("error").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand,
                });
              } else {
                this.setState({
                  cardBrand: "",
                });
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function() {
          document.getElementById("name").style.display = "inline-flex";
        },
      },
    };

    this.onLoad();

    // this.paymentForm = new this.props.paymentForm(config);
    // this.paymentForm.build();
  }

  render() {
    const { appReducer } = store.getState();
    const { isPaymentLoading } = this.state;
    return (
      <div className="checkout-container">
        <div id="form-container">
          <div id="card-container"></div>
          <button
            className="button-credit-card"
            id="card-button"
            onClick={(e) => this.placeOrder(e, this.state.card, false)}
          >
            {isPaymentLoading == true ? "Loading..." : "Pay"}
          </button>
        </div>
        <p style={styles.center} id="error"></p>
      </div>
    );
  }
}
