import React, { useRef, useEffect } from 'react';
import "./style.css";

function QueueSkipperLanding() {
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.fade-in');
      const windowHeight = window.innerHeight;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < windowHeight - 100) {
          section.classList.add('visible');
        } else {
          section.classList.remove('visible');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="qs-container">
      <header className="qs-header">
        <div className="qs-logo">Queue Skipper</div>
        <nav>
          <span className="qs-nav-link" onClick={() => scrollToSection(featuresRef)}>Features</span>
          <span className="qs-nav-link" onClick={() => scrollToSection(pricingRef)}>Pricing</span>
          <span className="qs-nav-link" onClick={() => scrollToSection(contactRef)}>Contact Us</span>
        </nav>
      </header>

      <section ref={heroRef} className="qs-hero fade-in">
        <h1>Transform Your Service with Queue Skipper</h1>
        <p>Empowering fast, seamless transactions for your business with a user-friendly Kiosk & POS system.</p>
        <button className="qs-button">Get Started</button>
      </section>

      <section ref={featuresRef} className="qs-features fade-in">
        <div className="qs-feature">
          <h2>Interactive Kiosk</h2>
          <p>Intuitive design, fast touch response, customizable menus, and more.</p>
        </div>
        <div className="qs-feature">
          <h2>Seamless POS</h2>
          <p>Synchronize orders, manage fees, and deliver receipts instantly.</p>
        </div>
        <div className="qs-feature">
          <h2>Reliable Operation</h2>
          <p>High network reliability, offline mode, and built-in printer support.</p>
        </div>
      </section>

      <section ref={pricingRef} className="qs-pricing fade-in">
        <h2>Choose Your Plan</h2>
        <div className="qs-pricing-plan">
          <h3>Basic</h3>
          <p className="qs-price">$49 / month</p>
          <ul>
            <li>Essential Kiosk features</li>
            <li>Basic reporting</li>
          </ul>
          <button className="qs-button">Select Basic</button>
        </div>
        <div className="qs-pricing-plan">
          <h3>Premium</h3>
          <p className="qs-price">$99 / month</p>
          <ul>
            <li>All Basic features</li>
            <li>Advanced analytics</li>
            <li>Priority support</li>
          </ul>
          <button className="qs-button">Select Premium</button>
        </div>
      </section>

      <section ref={contactRef} className="qs-contact fade-in">
        <h2>Contact Us</h2>
        <form>
          <input type="text" name="name" placeholder="Name" required className="qs-input" />
          <input type="email" name="email" placeholder="Email" required className="qs-input" />
          <textarea name="message" placeholder="Message" required className="qs-input"></textarea>
          <button type="submit" className="qs-button">Send Message</button>
        </form>
      </section>

      <footer className="qs-footer">
        <p>&copy; {new Date().getFullYear()} Queue Skipper. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default QueueSkipperLanding;
