import React, { Component } from "react";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

class FloatingFilterButton extends Component {
  state = {
    isOpen: false,
  };
  render() {
    const floatingMenuStyle = {};
    return (
      <div style={{ position: "fixed", bottom: 50, right: 20, zIndex: 100 }}>
        <FloatingMenu
          slideSpeed={500}
          direction="left"
          spacing={8}
          isOpen={this.state.isOpen}
          style={this.state.isOpen ? floatingMenuStyle : null}
        >
          <MainButton
            iconResting={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-map-filter.png")}
              ></img>
            }
            iconActive={
              <img
                alt="cancel"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-map-filter.png")}
              ></img>
            }
            backgroundColor="black"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            size={56}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-reset.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
            onClick={() => alert("First button clicked")}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-tor.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
            onClick={() => alert("First button clicked")}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-ff.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-ct.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-ft.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
          />
          <ChildButton
            icon={
              <img
                alt="filter"
                style={{ width: 56 }}
                src={require("../../../assets/img/main-filter-it.png")}
              ></img>
            }
            backgroundColor="white"
            size={56}
          />
        </FloatingMenu>
      </div>
    );
  }
}

export default FloatingFilterButton;
