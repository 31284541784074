import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Image,
} from "react-bootstrap";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  SQUARE_SCRIPT_JS,
  SQUARE_APP_ID,
  LOCATION_ID,
} from "../../assets/Constants";
import PaymentForm from "./components/PaymentForm.js";
import "react-phone-input-2/lib/style.css";
var interval = "";
export class PaymentPage extends Component {
  state = {
    isPaymentModalOpen: false,
  };
  togglePaymentModal = () => {
    this.state.isPaymentModalOpen
      ? this.setState({ isPaymentModalOpen: false }, () => {
          if (JSON.parse(getFromLocalStorage("Operator"))[0].email == "") {
            window.location.href = "/operator-landing";
          }
        })
      : this.setState({ isPaymentModalOpen: true });
  };
  componentWillMount() {
    console.log(this);
    const that = this;
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = SQUARE_SCRIPT_JS;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true,
      });
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }
  componentDidMount() {
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
  }
  getPaymentDetails = () => {};

  onLoad = async () => {
    if (!this.props.paymentForm) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = this.props.paymentForm;
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    let card;
    try {
      card = await this.initializeCard(payments);
    } catch (e) {
      console.error("initialize card error!", e);
      return;
    }
  };

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    this.setState({
      card: card,
    });
    return card;
  }

  async tokenize(card) {
    const tokenResult = await card.tokenize();
    if (tokenResult.status === "OK") {
      console.log("tokenize result ", tokenResult);
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }
  render() {
    return (
      <div>
        <Row style={{ marginTop: 10 }}>
          <Col xs={12} md={12} lg={12}>
            <Button
              style={{
                width: "50%",
                margin: "auto",
                marginBottom: 20,
                backgroundColor: "#ef3f6d",
              }}
              className="button-enabled-pink-style"
              onClick={() => {
                this.togglePaymentModal();
              }}
            >
              Pay
            </Button>
          </Col>
        </Row>
        {this.renderPaymentModal()}
      </div>
    );
  }
  renderPaymentModal = () => {
    const { isPaymentModalOpen } = this.state;

    const { dispatch, userId } = this.props;
    var total = 0;
    var sub_total = 0;
    var min_fee = 0;
    var min_call_out_fee = 0;
    if (this.state.selectedPack) {
      sub_total =
        Number(this.state.selectedPack.package_price) *
        Number(getFromLocalStorage("bookingNumberOfGuests"));
      min_fee =
        Number(this.state.selectedPack.package_price) *
        Number(this.state.selectedPack.package_people);

      if (min_fee - sub_total > 0) {
        min_call_out_fee = min_fee - sub_total;
      }
      if (this.state.selectedPack && this.state.selectedPack.package_price) {
        total = Number(Number(sub_total) + Number(min_call_out_fee)).toFixed(2);
      }
    }
    return (
      <Modal
        show={isPaymentModalOpen}
        onHide={this.togglePaymentModal.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Payment Details Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            style={{
              height: 500,
              marginTop: 20,
              overflowY: "scroll",
              border: "none",
            }}
          >
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Card.Title></Card.Title>
              <Row>
                <Col xs={12}>
                  <PaymentForm
                    // paymentForm={window.Square.payments(
                    //   SQUARE_APP_ID,
                    //   LOCATION_ID
                    // )}
                    user={null}
                    total={499}
                    dispatch={dispatch}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };
}
