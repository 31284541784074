import React from "react";
import { Bar } from "react-chartjs-2";

const SalesByDaysChart = ({ salesByDays, selectedDate }) => {
  console.log(salesByDays, selectedDate, "in");
  const labels = [];
  const currentYearData = [];
  const lastYearData = [];
  if (salesByDays && salesByDays.event_1 && salesByDays.event_2) {
    if (selectedDate === "-1") {
      // Initialize empty arrays for event dates if daily_payment doesn't exist
      const event1Dates = salesByDays.event_1?.daily_payment
        ? Object.keys(salesByDays.event_1.daily_payment).sort()
        : [];
      const event2Dates = salesByDays.event_2?.daily_payment
        ? Object.keys(salesByDays.event_2.daily_payment).sort()
        : [];

      // Determine the number of days based on the longest array of dates
      const numDays = Math.max(event1Dates.length, event2Dates.length);

      for (let i = 0; i < numDays; i++) {
        labels.push(`Day ${i + 1}`);

        // Fetch sales data if the date exists, otherwise use 0
        const event1Sales = event1Dates[i]
          ? salesByDays.event_1.daily_payment[event1Dates[i]]
          : 0;
        const event2Sales = event2Dates[i]
          ? salesByDays.event_2.daily_payment[event2Dates[i]]
          : 0;

        currentYearData.push(event2Sales);
        lastYearData.push(event1Sales);
      }
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Last Year",
            backgroundColor: "rgb(60, 179, 113)",
            borderColor: "rgb(60, 179, 113)",
            borderWidth: 1,
            data: lastYearData,
          },
          {
            label: "Current Year",
            backgroundColor: "rgb(255, 0, 0)",
            borderColor: "rgb(255, 0, 0)",
            borderWidth: 1,
            data: currentYearData,
          },
        ],
      };

      const options = {
        scales: {
          x: {
            ticks: {
              color: "black",
            },
          },
          y: {
            ticks: {
              color: "black",
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function(context) {
                return context[0].label;
              },
              label: function(context) {
                const label = context.dataset.label || "";
                const value = context.raw;
                let tooltipMessage = `${label}: $${value}`;
                // Adjusting logic to ensure the tooltip reflects the correct comparison
                // This assumes datasetIndex 0 is "Last Year" and 1 is "Current Year" after your reversal
                if (context.datasetIndex === 1) {
                  // Changed from 0 to 1 to reflect the reversal
                  const lastYearValue = lastYearData[context.dataIndex] || 0;
                  const amountChange = value - lastYearValue;
                  const percentageChange = (
                    (amountChange / lastYearValue) *
                    100
                  ).toFixed(2);
                  const changeText = ` ($${
                    amountChange >= 0 ? "+" : ""
                  }${amountChange.toFixed(2)}, ${percentageChange}%)`;
                  tooltipMessage += amountChange !== 0 ? changeText : "";
                }
                return tooltipMessage;
              },
            },
            displayColors: true,
          },
        },
      };

      const totalCurrentYear = currentYearData.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const totalLastYear = lastYearData.reduce((acc, curr) => acc + curr, 0);
      const totalChangePercentage = (
        ((totalCurrentYear - totalLastYear) / totalLastYear) *
        100
      ).toFixed(2);
      const totalChangeColor =
        totalCurrentYear >= totalLastYear ? "green" : "red";
      const totalChangeSymbol = totalCurrentYear >= totalLastYear ? "▲" : "▼";

      return (
        <div>
          <Bar data={data} options={options} />
          <div className="summary">
            <strong>Summary:</strong> Overall your sales are{" "}
            {totalChangeColor === "green" ? "up" : "down"} by
            <span style={{ color: totalChangeColor }}>
              {totalChangeSymbol} {totalChangePercentage}%
            </span>
            ($ {(totalCurrentYear - totalLastYear).toFixed(2)})
          </div>
        </div>
      );
    } else {
      const selectedDateFormatted = selectedDate; // "DD MMM YYYY" format

      // Ensure we have daily_payment data for both events
      if (
        salesByDays.event_2?.daily_payment &&
        salesByDays.event_1?.daily_payment
      ) {
        const event2Dates = Object.keys(
          salesByDays.event_2.daily_payment
        ).sort();
        const dayNIndex = event2Dates.indexOf(selectedDateFormatted); // Determine "Day N"

        if (dayNIndex !== -1) {
          const event1Dates = Object.keys(
            salesByDays.event_1.daily_payment
          ).sort();
          if (dayNIndex < event1Dates.length) {
            const correspondingDateLastYear = event1Dates[dayNIndex]; // Corresponding "Day N" in the previous year
            console.log(correspondingDateLastYear);
            // Collect hourly data for the selected day and the corresponding day, then sort by time
            const currentYearHourly = Object.entries(
              salesByDays.event_2.hourly_payment || {}
            )
              .filter(([key]) => key.startsWith(selectedDateFormatted))
              .sort(([a], [b]) => a.localeCompare(b));
            console.log(currentYearHourly);

            const lastYearHourly = Object.entries(
              salesByDays.event_1.hourly_payment || {}
            )
              .filter(([key]) => key.startsWith(correspondingDateLastYear))
              .sort(([a], [b]) => a.localeCompare(b));
            console.log(lastYearHourly);

            for (let i = 0; i < 24; i++) {
              labels.push(`${i.toString().padStart(2, "0")}:00`); // Ensures format like "00:00", "01:00", ..., "23:00"
            }

            // Mapping of hourly data for easier access
            const mapCurrentYearHourly = new Map(currentYearHourly);
            const mapLastYearHourly = new Map(lastYearHourly);

            labels.forEach((timePart) => {
              // Extract the hour part to match with the hourly data keys
              const hourPart = timePart.split(":")[0]; // Get the hour part, e.g., "15" from "15:00"

              // Current year data for the hour, or 0 if not found
              const currentYearValue =
                mapCurrentYearHourly.get(
                  `${selectedDateFormatted} ${timePart}`
                ) || 0;
              currentYearData.push(currentYearValue);

              // Last year data for the hour, or 0 if not found. Need to construct the key based on the corresponding date and hour
              const lastYearKey = `${correspondingDateLastYear} ${hourPart}:00`; // Assuming lastYearHourly keys follow the "DD MMM YYYY HH:MM" format
              const lastYearValue = mapLastYearHourly.get(lastYearKey) || 0;
              lastYearData.push(lastYearValue);
            });
          }
        }
      }
      // Continue with constructing the data and options for the Bar chart as before
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Last Year",
            backgroundColor: "rgb(60, 179, 113)",
            borderColor: "rgb(0, 255, 0)",
            borderWidth: 1,
            data: lastYearData,
          },
          {
            label: "Current Year",
            backgroundColor: "rgb(255, 0, 0)",
            borderColor: "rgb(255, 0, 0)",
            borderWidth: 1,
            data: currentYearData,
          },
        ],
      };

      // Define chart options
      const options = {
        scales: {
          x: {
            ticks: {
              color: "black",
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: "black",
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function(context) {
                // This sets the title of the tooltip based on the label of the hovered item
                return `Time: ${context[0].label}`;
              },
              label: function(context) {
                const label = context.dataset.label || "";
                const value = context.raw;
                let tooltipMessage = `${label}: $${value}`;

                // This check assumes the first dataset (index 0) is for the "Last Year"
                // And you want to show comparison data when hovering over the "Current Year" data, which should be index 1
                if (context.datasetIndex === 1) {
                  // Changed from 0 to 1 to reflect "Current Year" data
                  const lastYearValue = lastYearData[context.dataIndex] || 0;
                  const amountChange = value - lastYearValue;
                  const percentageChange = (
                    (amountChange / (lastYearValue || 1)) *
                    100
                  ).toFixed(2); // Added || 1 to avoid division by zero

                  const changeText = ` ($${
                    amountChange >= 0 ? "+" : ""
                  }${amountChange.toFixed(2)}, ${percentageChange}%)`;
                  tooltipMessage += amountChange !== 0 ? changeText : "";
                }

                return tooltipMessage;
              },
            },
            displayColors: true,
          },
        },
      };
      const totalCurrentYearSales = currentYearData.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const totalLastYearSales = lastYearData.reduce(
        (acc, curr) => acc + curr,
        0
      );

      // Calculate the percentage change in total sales from the previous year to the current year
      const totalChange = totalCurrentYearSales - totalLastYearSales;
      const totalChangePercentage = (
        (totalChange / totalLastYearSales) *
        100
      ).toFixed(2);

      // Determine the color and symbol for the change indicator
      const totalChangeColor = totalChange >= 0 ? "green" : "red";
      const totalChangeSymbol = totalChange >= 0 ? "▲" : "▼";

      return (
        <div>
          <Bar data={data} options={options} />
          <div className="summary">
            <strong>Summary:</strong> For the selected day, your total sales are{" "}
            {totalChangeColor === "green" ? "up" : "down"} by
            <span style={{ color: totalChangeColor }}>
              {totalChangeSymbol} {Math.abs(totalChangePercentage)}%
            </span>
            ($ {totalChange.toFixed(2)}), compared to the corresponding day last
            year.
          </div>
        </div>
      );
    }
  } else {
    // Handle if salesByDays or its properties are not defined
    console.log("Sales data is not available.");
    return null;
  }
};

export default SalesByDaysChart;
