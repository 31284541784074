export const organiserAction = {
  GET_ORGANISER_EVENT: "get_event",
  GET_ORGANISER_EVENT_SUCCESS: "get_event_success",
  GET_ORGANISER_EVENT_FAILED: "get_event_failed",
  CREATE_NEW_EVENT: "create_new_event",
  ADD_VENDORS_REQUEST: "add_vendors_request",
  GET_COMFIRM_VENDORS: "get_comfirm_vendors",
  GET_SELECTED_EOI: "get_selected_eoi",
  ADD_EOI: "add_eoi",
};

export function getEvent() {
  return {
    type: organiserAction.GET_ORGANISER_EVENT,
  };
}

export function createNewEvent(payload) {
  return {
    type: organiserAction.CREATE_NEW_EVENT,
    payload,
  };
}
export function getComfirmedVendors(payload) {
  return {
    type: organiserAction.GET_COMFIRM_VENDORS,
    payload,
  };
}
export function getSelectedEoi(payload) {
  return {
    type: organiserAction.GET_SELECTED_EOI,
    payload,
  };
}
export function createEOIEvent(payload) {
  return {
    type: organiserAction.ADD_EOI,
    payload,
  };
}
export function addVendorsRequest(payload) {
  return {
    type: organiserAction.ADD_VENDORS_REQUEST,
    payload,
  };
}
export function getEventSuccess(payload) {
  return {
    type: organiserAction.GET_ORGANISER_EVENT_SUCCESS,
    payload,
  };
}
export function getEventFailed(error) {
  return {
    type: organiserAction.GET_ORGANISER_EVENT_FAILED,
    error,
  };
}
