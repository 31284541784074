import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Divider from "@mui/material/Divider";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import EOIPage from "../../UI/EOI/index";
import SupplierPage from "../../UI/Supplier/index";
import * as Sentry from "@sentry/react";
import { theme } from "../theme";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CustomButton from "../Button/index";
import moment from "moment";

import {
  changeTab,
  loginPhone,
  sendSms,
  loginModalToggle,
  profileModalToggle,
  logout,
  updateUser,
  getUserProfile,
  changeSelectedLocation,
  getAllLocations,
  redirectFromWebsite,
  getSelectedVendor,
} from "../../../actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import "./style.css";
import {
  store,
  getFromLocalStorage,
  deleteFromLocalStorage,
} from "../../../store";
import { GOOGLE_API_KEY } from "../../../assets/Constants";
import { applyToken } from "../../../services/api";
import { NavDropdown } from "react-bootstrap";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class ResponsiveNavBar extends Component {
  state = {
    // isOpenLoginModal: !!this.props.showLogin,
    isOpenProfileModal: false,
    isOpenEOIModal: false,
    isOpenSupplierApplicationModal: false,
    truckBucks: 0,
    usernameState: "",
    emailState: "",
  };
  getTruckBucks = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-truck-bucks",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          this.setState({ truckBucks: response.truckBucks });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  clickedBookATruck = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update-bookatruck-click",
          timestamp: moment(new Date()).format("DD MMM YYYY hh:mm A"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          this.setState({ truckBucks: response.truckBucks });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  componentDidMount() {
    this.getTruckBucks();
  }
  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  toggleProfileModal = (toggle) => {
    // this.props.dispatch(toggleProfileModal(toggle))
    this.setState({ isOpenProfileModal: toggle });
  };

  sendSms = () => {
    this.setState({ buttonPressed: true }, () => {
      this.props.dispatch(sendSms(this.state.phone)).then(() => {
        this.setState({ buttonPressed: false });
      });
    });
  };

  loginPhone = () => {
    this.props
      .dispatch(loginPhone(this.state.phone, this.state.code))
      .then((data) => {
        if (data) {
          this.getUserProfile(data["payload"].userId);
        } else {
          alert("Error! Please try again!");
        }
      });
  };

  getUserProfile = (userId) => {
    this.props.dispatch(getUserProfile(userId)).then((data) => {
      if (data.payload.username == "" || data.payload.email == null) {
        this.toggleProfileModal(true);
      } else {
        window.location.reload();
      }
    });
  };

  updateUserProfile = (userId) => {
    const { usernameState, emailState } = this.state;
    if (usernameState == "" || emailState == "") {
      alert("Please fill in the required fields");
      return;
    }

    applyToken(getFromLocalStorage("token"));
    this.props
      .dispatch(updateUser(userId, usernameState, emailState))
      .then((data) => {
        this.getUserProfile(data["payload"].id);
      });
  };

  logout = () => {
    this.props.dispatch(logout());
    window.location.reload();
  };

  handleClose = () => {
    this.setState({ isOpenEOIModal: false });
  };

  handleCloseSupplierModal = () => {
    this.setState({ isOpenSupplierApplicationModal: false });
  };
  render() {
    const {
      ticketState,
      products,
      loggedIn,
      username,
      userId,
      backUrl,
      showBack,
      toggleSelectedTruckClicked,
      toggleSelectedThisPackClicked,
      selectedTruckClicked,
      selectedThisPackClicked,
    } = this.props;
    console.log(this.props);

    var badgeLength =
      Number(Object.keys(ticketState).length) +
      Number(Object.keys(products).length);
    const iconButtonStyle = {
      backgroundColor: theme.colors.backgroundColor,
      fontSize: 40,
      padding: 8,
      borderRadius: 20,
      color: theme.colors.iconColor,
      marginLeft: 10,
      marginRight: 10,
    };
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    return (
      <>
        <Navbar
          variant="dark"
          expand="lg"
          style={{ backgroundColor: "#2859a5", marginBottom: "20px" }}
        >
          {showBack == false ? (
            <Navbar.Brand href="/">
              <Image
                style={{ height: 40 }}
                src={require("../../../assets/img/welcome-logo.png")}
                fluid
              />
            </Navbar.Brand>
          ) : selectedThisPackClicked == true ||
            selectedTruckClicked == true ? (
            <Navbar.Brand
              onClick={() => {
                selectedThisPackClicked == true
                  ? toggleSelectedThisPackClicked(false)
                  : toggleSelectedTruckClicked(false);
              }}
            >
              <Image
                style={{ height: 40 }}
                src={require("../../../assets/img/back-btn.png")}
                fluid
              />
            </Navbar.Brand>
          ) : (
            <Navbar.Brand href={backUrl}>
              <Image
                style={{ height: 40 }}
                src={require("../../../assets/img/back-btn.png")}
                fluid
              />
            </Navbar.Brand>
          )}

          {((ticketState && !this.isEmpty(ticketState)) ||
            (products && !this.isEmpty(products))) &&
          page != "checkout" ? (
            <IconButton
              className="mobile-only"
              aria-label="cart"
              onClick={() => {
                window.location.href = "/checkout";
              }}
            >
              <StyledBadge badgeContent={badgeLength} color="secondary">
                <ShoppingCartIcon style={iconButtonStyle}></ShoppingCartIcon>
              </StyledBadge>
            </IconButton>
          ) : null}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              {((ticketState && !this.isEmpty(ticketState)) ||
                (products && !this.isEmpty(products))) &&
              page != "checkout" ? (
                <IconButton
                  className="desktop-only"
                  aria-label="cart"
                  onClick={() => {
                    window.location.href = "/checkout";
                  }}
                >
                  <StyledBadge badgeContent={badgeLength} color="secondary">
                    <ShoppingCartIcon
                      style={iconButtonStyle}
                    ></ShoppingCartIcon>
                  </StyledBadge>
                </IconButton>
              ) : null}
              {loggedIn == false || username == "" || userId == "" ? (
                <>
                  {/* <NavDropdown
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                    active={
                      page == "organiser" || page == "organiser" ? true : false
                    }
                    title="Event Organisers"
                    id="basic-nav-dropdown"
                  >
                    /organiser-landing
                    <NavDropdown.Item href="/organiser">
                      Organiser Login
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        this.setState({ isOpenEOIModal: true });
                      }}
                    >
                      Apply For Event Organiser Account
                    </NavDropdown.Item>
                  </NavDropdown> */}
                  <Nav.Link
                    onClick={() => {
                      window.location.replace("/organiser-landing");
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Event Organiser
                  </Nav.Link>

                  {/* <NavDropdown
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                    active={
                      page == "supplier-login" || page == "supplier-login"
                        ? true
                        : false
                    }
                    title="Industries"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/supplier-login">
                      Supplier Login
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        this.setState({ isOpenSupplierApplicationModal: true });
                      }}
                    >
                      Apply For Supplier Account
                    </NavDropdown.Item>
                  </NavDropdown> */}

                  <Nav.Link
                    onClick={() => {
                      window.location.href = "/book-a-truck";
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Book a truck
                  </Nav.Link>

                  <Nav.Link
                    onClick={() => {
                      window.location.replace("/operator-landing");
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Vendor Login
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      // this.setState({ isOpenLoginModal: true });
                      this.props.dispatch(loginModalToggle(true));
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Sign In
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link
                    onClick={() => {
                      this.setState({ isOpenEOIModal: true });
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Apply Your Event
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      window.location.href = "/book-a-truck";
                    }}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    Book a truck
                  </Nav.Link>
                  <NavDropdown
                    title="My Account"
                    id="basic-nav-dropdown"
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 50,
                      marginLeft: 10,
                    }}
                  >
                    <NavDropdown.ItemText>
                      {"Hi, " + username + " you have"}
                    </NavDropdown.ItemText>
                    <NavDropdown.ItemText style={{ color: "#EF3E6D" }}>
                      {this.state.truckBucks}
                    </NavDropdown.ItemText>
                    <NavDropdown.ItemText>Truck Bucks</NavDropdown.ItemText>
                    <Divider
                      primaryColor="red"
                      secondaryColor="grey"
                      heightValue={20}
                    ></Divider>
                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        this.logout();
                      }}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.renderLoginModal()}
        {this.renderProfileModal()}
        {this.renderEOIModal()}
        {this.renderSupplierModal()}
      </>
    );
  }

  renderEOIModal = () => {
    const { isOpenEOIModal } = this.state;
    return (
      <Modal show={isOpenEOIModal} onHide={this.handleClose}>
        <EOIPage handleClose={this.handleClose} />
      </Modal>
    );
  };

  renderSupplierModal = () => {
    const { isOpenSupplierApplicationModal } = this.state;
    return (
      <Modal
        show={isOpenSupplierApplicationModal}
        onHide={this.handleCloseSupplierModal}
      >
        <SupplierPage handleClose={this.handleCloseSupplierModal} />
      </Modal>
    );
  };

  renderLoginModal = () => {
    const { buttonPressed, code } = this.state;
    const { appReducer } = store.getState();
    const { selectedTab, smsSent, loginModalToggle: showLogin } = appReducer;
    return (
      <Modal
        show={showLogin}
        onHide={() => {
          // this.setState({ isOpenLoginModal: false });
          this.props.dispatch(loginModalToggle(false));
        }}
      >
        {smsSent === false ? (
          <>
            <Modal.Header>
              <Modal.Title>Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              Whether you're creating an account or signing back in, lets's
              start with your number
              <PhoneInput
                containerClass={"phoneInputStyle"}
                country={"au"}
                onlyCountries={["au", "gb"]}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone })}
                autoFormat={true}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                backgroundColor={"#646771"}
                textColor={theme.colors.textColorLight}
                borderRadius={20}
                isProfilePic={false}
                buttonText={buttonPressed ? "Loading..." : "Continue"}
                handleButtonClick={buttonPressed ? null : this.sendSms}
                buttonWidth={"90%"}
                margin={"auto"}
              ></CustomButton>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>Verify Your Number</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                textAlign: "center",
                width: "90%",
                margin: "auto",
                color: "#adb3bf",
              }}
            >
              Enter the confirmation code we have sent to your number
              <OtpInput
                onChange={(code) =>
                  this.setState({ code: code }, () => {
                    if (code.length === 4) {
                      this.loginPhone();
                    }
                  })
                }
                value={code}
                numInputs={4}
                separator={<span>&nbsp;</span>}
                containerStyle={"otpInputStyle"}
              />
            </Modal.Body>
            <Modal.Footer>
              <p>Resend Confirmation Code</p>
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  };
  renderProfileModal = () => {
    const { isOpenProfileModal, usernameState, emailState } = this.state;
    const { appReducer } = store.getState();
    const { userId } = appReducer;

    return (
      <Modal
        show={isOpenProfileModal}
        onHide={() => {
          this.toggleProfileModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Profile Info</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            width: "90%",
            margin: "auto",
            color: "#adb3bf",
          }}
        >
          Enter your profile details below
          <Form.Group style={{ textAlign: "left" }}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              defaultValue={usernameState}
              onChange={(event) => {
                this.setState({ usernameState: event.target.value });
              }}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail" style={{ textAlign: "left" }}>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              defaultValue={emailState}
              onChange={(event) => {
                this.setState({ emailState: event.target.value });
              }}
            />
          </Form.Group>
          <CustomButton
            backgroundColor={
              usernameState != "" && emailState != ""
                ? theme.colors.primaryColor
                : theme.colors.backgroundColor
            }
            textColor={
              usernameState != "" && emailState != ""
                ? theme.colors.textColorLight
                : theme.colors.textColorDark
            }
            borderRadius={20}
            isProfilePic={false}
            buttonText={"Continue"}
            margin={"auto"}
            buttonWidth={"90%"}
            handleButtonClick={
              usernameState != "" && emailState != ""
                ? () => {
                    this.updateUserProfile(userId);
                  }
                : null
            }
          ></CustomButton>
        </Modal.Body>
      </Modal>
    );
  };
}

export default ResponsiveNavBar;
