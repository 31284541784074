import React, { useState } from "react";
import { AWS_URL } from "../../assets/Constants";
import Button from "react-bootstrap/Button";

const Slideshow = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (!images || images.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <img
        src={
          images[currentImageIndex].image
            ? AWS_URL + "/" + images[currentImageIndex].image
            : require("../../assets/img/empty_logo.png")
        }
        alt={`Slideshow Image ${currentImageIndex + 1}`}
        style={{ width: "150px", height: "150px" }}
      />
      <div style={{ paddingTop: "15px" }}>
        <Button onClick={handlePrevious}>{"<"}</Button>
        <span
          style={{ paddingLeft: "5px", paddingRight: "5px" }}
        >{`${currentImageIndex + 1} / ${images.length}`}</span>
        <Button onClick={handleNext}>{">"}</Button>
      </div>
    </div>
  );
};
export default Slideshow;
