import React from "react";
import { Form, Button } from "react-bootstrap";
import Container from "@mui/material/Container";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-sm-12 divide-xs">
              <p>
                <small>
                  Copyright © 2024 Where The Truck All rights reserved.
                </small>
              </p>
              <p>
                <small>
                  By using the Where The Truck website, you agree to our{" "}
                  <a
                    href="http://amfvg.com.au/terms-and-conditions-of-use/"
                    target="_blank"
                    className="anchor"
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="http://amfvg.com.au/privacy-policy/"
                    target="_blank"
                    className="anchor"
                  >
                    Privacy Policy
                  </a>
                </small>
              </p>
            </div>
            <div className="clearfix"></div>
            <div className="divide-sm"></div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
