import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table, Alert, Modal, Accordion, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Footer from "../../components/UI/Footer";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import EOIPage from "../../components/UI/EOI/index";
import "./style.css";
import { AWS_URL } from "../../assets/Constants";

const DepartmentAccessByEventScreen = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [eoiName, setEoiName] = useState(null);
  const [isOpenEOIModal, set_isOpenEOIModal] = useState(false);
  const location = useLocation();

  const handleClose = () => {
    set_isOpenEOIModal(false);
  };

  const handleOpen = () => {
    set_isOpenEOIModal(true);
  };

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const hashcode = query.get("hashcode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          config.BASE_URI + apiPaths.organiserData,
          {
            payload: {
              body: {
                query_type: "fetch_departmental_access",
                hashcode: hashcode,
              },
            },
          }
        );

        if (response.data.success) {
          setData(response.data.vendors[0]);
          setEoiName(response.data.eoi_name);
        } else {
          setError(response.data.err);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [hashcode]);

  const calculateWattage = (amps10, amps15, amps20, amps32) => {
    return amps10 * 2300 + amps15 * 3600 + amps20 * 10800 + amps32 * 21600;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ResponsiveNavBar
        showBack={false}
        backUrl={"/"}
        ticketState={{}}
        products={{}}
        loggedIn={false}
        username={""}
        userd={""}
        dispatch={() => {}}
      ></ResponsiveNavBar>
      <Container>
        <Alert variant="info">
          <h4>Electrical Requirements Information</h4>
          <p>
            This page provides details on the electrical requirements for the
            event "{eoiName}". It lists the number of electrical outlets
            required by each vendor and the corresponding wattage. The table
            below indicates if the total wattage requested by any vendor
            exceeds the calculated wattage based on the number of amps. If a
            vendor's total wattage is overpowered, an error message will be
            displayed in red.
          </p>
        </Alert>
        <h1>Electrical Requirements For {eoiName}</h1>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Number of 10 Amp</th>
              <th>Number of 15 Amp</th>
              <th>Number of 20 Amp</th>
              <th>Number of 32 Amp</th>
              <th>Total Wattage</th>
              <th>Electrical Certificate</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            {data.map((vendor, index) => {
              const calculatedWattage = calculateWattage(
                vendor.number_of_10_amp,
                vendor.number_of_15_amp,
                vendor.number_of_20_amp,
                vendor.number_of_32_amp
              );
              const isWattageValid = vendor.total_wattage <= calculatedWattage;

              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{vendor.title}</td>
                    <td>{vendor.number_of_10_amp}</td>
                    <td>{vendor.number_of_15_amp}</td>
                    <td>{vendor.number_of_20_amp}</td>
                    <td>{vendor.number_of_32_amp}</td>
                    <td style={{ color: isWattageValid ? "black" : "red" }}>
                      {vendor.total_wattage}
                    </td>
                    <td>
                      {vendor.electrical_certificate !== "" ? (
                        <a
                          href={`${AWS_URL}/${vendor.electrical_certificate}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Electrical Certificate
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {!isWattageValid && (
                        <span style={{ color: "red" }}>Overdrawn</span>
                      )}
                    </td>
                  </tr>
                  {vendor.equipment_list && vendor.equipment_list.length > 0 && (
                    <tr>
                      <td colSpan="8">
                        <Accordion>
                          <Accordion.Toggle as={Card.Header} eventKey="0">
                            View Equipment List
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Vendor Title</th>
                                    <th>Name</th>
                                    <th>Wattage</th>
                                    <th>Test and Tag Expiry Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendor.equipment_list.map((equipment, index) => (
                                    <tr key={index}>
                                      <td>{equipment.vendor__title}</td>
                                      <td>{equipment.name}</td>
                                      <td>{equipment.wattage}</td>
                                      <td>{equipment.test_and_tag_expiry_date}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <Modal show={isOpenEOIModal} onHide={handleClose}>
        <EOIPage handleClose={handleClose} />
      </Modal>
      <Footer />
    </>
  );
};

export default DepartmentAccessByEventScreen;
