import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

const WeatherForecastModal = ({ forecastData }) => {
  console.log(forecastData);
  const [showModal, setShowModal] = useState(false);
  const [showHourlyModal, setShowHourlyModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setShowHourlyModal(false);
    setSelectedDay(null);
  };

  const handleShow = () => setShowModal(true);

  const handleHourlyShow = (dayIndex) => {
    setSelectedDay(dayIndex);
    setShowHourlyModal(true);
  };

  const handleBackToDaily = () => {
    setShowHourlyModal(false);
    setSelectedDay(null);
    setShowModal(true);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("Do MMM YYYY hh:mm A");
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "#2959a5",
              cursor: "pointer",
            }}
            onClick={handleShow}
          >
            See Details
          </p>
        </Col>
        <Col></Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Event Weather Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {forecastData &&
          forecastData.forecast &&
          forecastData.forecast.forecastday ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Max Temp (C)</th>
                  <th>Min Temp (C)</th>
                  <th>Condition</th>
                  <th>Hourly Report</th>
                </tr>
              </thead>
              <tbody>
                {forecastData.forecast.forecastday.map((day, index) => (
                  <tr key={index}>
                    <td>{formatDateTime(day.date)}</td>
                    <td>{day.day.maxtemp_c}</td>
                    <td>{day.day.mintemp_c}</td>
                    <td>
                      {day.day.condition ? (
                        <>
                          {day.day.condition.text}
                          <img
                            src={day.day.condition.icon}
                            alt={day.day.condition.text}
                          />
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <Button
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#2959a5",
                          borderColor: "#2959a5",
                          borderRadius: "5px",
                          color: "#ffffff",
                          fontSize: "14px", // Reduced font size uniformly for all buttons
                          padding: "10px 20px",
                          height: "50px", // Uniform height
                          width: "250px", // Uniform width
                        }}
                        onClick={() => handleHourlyShow(index)}
                      >
                        View Hourly
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No daily forecast data available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHourlyModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Hourly Weather Forecast</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={handleBackToDaily}>
            Back to Daily Forecast
          </Button>
          {selectedDay !== null &&
          forecastData.forecast.forecastday[selectedDay] ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Temperature (C)</th>
                  <th>Condition</th>
                </tr>
              </thead>
              <tbody>
                {forecastData.forecast.forecastday[selectedDay].hour.map(
                  (hour, i) => (
                    <tr key={i}>
                      <td>{formatDateTime(hour.time)}</td>
                      <td>{hour.temp_c}</td>
                      <td>
                        {hour.condition ? (
                          <>
                            {hour.condition.text}
                            <img
                              src={hour.condition.icon}
                              alt={hour.condition.text}
                            />
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <p>No hourly report data available for the selected day</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WeatherForecastModal;
