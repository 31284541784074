import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import Footer from "../../components/UI/Footer";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import ReadMoreReact from "read-more-react";
import * as Sentry from "@sentry/react";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { responsiveFontSizes, withStyles } from "@material-ui/core/styles";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { hasEventExpired } from "../../helper";
import { AWS_URL } from "../../assets/Constants";
import { sizeHeight } from "@mui/system";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class AllEventsScreen extends Component {
  state = {
    events: new Array(),
    isLoading: false,
  };

  getAllEvents = () => {
    this.setState({ isLoading: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "all_events_without_menu",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.events, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.success == true) {
          const sortedEvents = result.events.sort((b, a) =>
            moment(
              moment(a.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
            ).diff(
              moment(moment(b.start_date, "DD MMM YYYY").format("YYYY-MM-DD")),
              "days"
            )
          );

          this.setState({ events: sortedEvents });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var url = config.BASE_URI + apiPaths.customerEvents;
    // var raw = JSON.stringify(
    //     {
    //         "payload":
    //         {
    //             "body":
    //             {
    //                 "query_type": 'all_events',

    //             }
    //         }
    //     });

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };

    // fetch(url, requestOptions)
    //     .then(response => response.json())
    //     .then(response => {
    //         if (response.success == true) {
    //             const sortedEvents = response.events.sort((b, a) => moment(moment(a.start_date, 'DD MMM YYYY').format('YYYY-MM-DD')).diff(moment(moment(b.start_date, 'DD MMM YYYY').format('YYYY-MM-DD')), 'days'))
    //             this.setState({events:sortedEvents})
    //         }
    //     })
  };
  componentDidMount() {
    this.getAllEvents();
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { events, isLoading } = this.state;
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    return (
      <div>
        <div className="minHeight">
          <ResponsiveNavBar
            showBack={false}
            backUrl={""}
            ticketState={eventTicket}
            products={products}
            loggedIn={loggedIn}
            username={username}
            userd={userId}
            dispatch={dispatch}
          ></ResponsiveNavBar>
          <Container>
            <h1 className="body-content-1">404</h1>
            <h2 className="body-content-2">Page Not Found</h2>
            <h4 className="body-content-2">
              We're sorry, we couldn't find the page you requested.
            </h4>
            <h4>
              <a href="/events" className="body-content-2">
                Go Home
              </a>
            </h4>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AllEventsScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
