import React, { Component } from "react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import LazyLoad from "react-lazy-load";
import Img from "react-image";
import { store, getFromLocalStorage } from "../../store";
import VendorSideBar from "../../components/UI/VendorSideBar";
import { theme } from "../../components/UI/theme";
import CustomButton from "../../components/UI/Button";
import { EMAIL_BOOK_CATERING } from "../../assets/Constants";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import {
  updateCartProducts,
  updateCartTotal,
  changeTab,
  getSelectedVendor,
  sendSms,
  loginPhone,
  getUserProfile,
  logout,
  updateUser,
} from "../../actions";
import TrackVisibility from "react-on-screen";
import * as Sentry from "@sentry/react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
  Toast,
} from "react-bootstrap";

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import "./style.css";

import {
  CartComponent,
  ProductComponent,
  CheckoutButtonComponent,
  cartLocalization,
} from "react-shopping-cart";
import CheckBox from "../../components/UI/CheckBox";
import { isRestaurantOpen } from "../../assets/Utils";

import { connect } from "react-redux";
import ScrollMenu from "react-horizontal-scrolling-menu";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const { getDefaultLocalization } = cartLocalization;
// You may take localization object from wherever you want, that's just an example
// For more information, see localization section
const iPadCaseLocalization = {
  color: "Color",
  iPadCase: "iPad case",
  red: "Red",
  green: "Green",
  yellow: "Yellow",
  AUD: "$",
};

const iPadPropertiesWithAdditionalCostLocalization = {
  yellow: "Yellow",
};

const localization = {
  AUD: "$",
};

class MenuScreen extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    mealList: [],
    selectedMealCategory: "",
    mealCategories: [],
    isOpenCartModal: false,
    isFindOutMoreClicked: false,
    bookingUserName: "",
    bookingUserEmail: "",
    bookingUserPhone: "",
    bookingUserDate: "",
    bookingUserTime: "",
    bookingUserLocation: "",
    bookingUserGuest: "",
    selectedMeal: {},
    combos: {},
    productsState: {},
    productsTotalState: 0,
    mealTotalState: 0,
    quantityPicker: 1,
    showAddToCartToast: false,
    selectedSubItem: {},
    selectedCombo: new Array(),
    additionalCostState: new Array(),
    specialRequest: "",
    divFocus: "category0",
    product: {
      name: "iPadCase",
      id: "ipad-case",
      path: "/shop/ipad-case/",
      properties: {
        color: [
          "red",
          "green",
          {
            additionalCost: {
              GBP: 1,
              EUR: 2,
              USD: 3.5,
            },
            value: "yellow",
          },
        ],
      },
      propertiesToShowInCart: ["color"],
      prices: { AUD: 70 },
      currency: "AUD",
      imageSrc: "1-483x321.jpeg",
    },
    getProductLocalization: getDefaultLocalization("product", "en", {
      ...iPadCaseLocalization,
      ...iPadPropertiesWithAdditionalCostLocalization,
    }),
    getCheckoutButtonLocalization: getDefaultLocalization(
      "checkoutButton",
      "en",
      iPadCaseLocalization
    ),

    getCartLocalization: getDefaultLocalization("cart", "en"),
    carouselVisible: true,
    isOpenCartModal: false,
    isOpenLoginModal: false,
  };

  setDivFocus = (categoryid, category) => {
    if (categoryid == "category-1") {
      categoryid = "category0";
    }

    this.setState({ divFocus: categoryid, selectedMealCategory: category });
    document.getElementById(categoryid).scrollIntoView();
  };

  componentWillMount() {
    const { appReducer } = store.getState();
    const { products, selectedVendorData } = appReducer;
    if (!this.isEmpty(products)) {
      var vendorId = window.location.href.substring(
        window.location.href.lastIndexOf(":") + 1
      );
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { appReducer } = store.getState();

    const {
      products,
      productsTotal,
      selectedVendorData,
      selectedVendor,
      location,
      selectedLocationId,
    } = appReducer;
    const { productsState, productsTotalState } = this.state;

    var vendorId = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    dispatch(getSelectedVendor(vendorId)).then(() => {
      const { appReducer } = store.getState();
      var selectedVendorData = appReducer.selectedVendorData;
      if (
        this.state.selectedMealCategory == "" &&
        Object.keys(selectedVendorData).length != 0
      ) {
        var meal_categories = [];
        this.setSelectedMealCategory(meal_categories, selectedVendorData, "");
      }
    });

    if (this.isEmpty(productsState)) {
      this.setState({ productsState: products });
    }
    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appReducer } = store.getState();
    const { products } = appReducer;
    const { dispatch } = this.props;

    if (this.state.productsState !== products) {
      dispatch(updateCartProducts(this.state.productsState));
      dispatch(
        updateCartTotal(this.calculateCartTotal(this.state.productsState))
      );
    }
  }

  getMealsByCategory(meals, mealCategories = this.state.mealCategories) {
    var exploreMealListing = [];
    meals.map((meal, i) => {
      try {
        if (mealCategories.some((item) => item === meal.category.title)) {
          var obj = {};
          if (exploreMealListing.some((item) => meal.category.title in item)) {
            exploreMealListing.map((t, i) => {
              if (meal.category.title in t) {
                var arr = t[meal.category.title];
                arr.push(meal);
                var new_obj = {};
                new_obj[meal.category.title] = arr;
                exploreMealListing.remove(i);
                exploreMealListing.push(obj);
              }
            });
          } else {
            var arr = [];
            arr.push(meal);
            obj[meal.category.title] = arr;
            exploreMealListing.push(obj);
          }
        }
      } catch (err) {}
    });

    return exploreMealListing;
  }

  getComboArray = (combos) => {
    let comboArray = [];
    combos.forEach((combo) => {
      comboArray.push([
        {
          id: combo.id,
          value: combo.name,
          isChecked: false,
          price: combo.price,
        },
      ]);
    });

    return comboArray;
  };
  handleCheckComboElement = (event) => {
    let combos = this.state.combos;
    let selectedCombo = [];

    combos.forEach((combo) => {
      combo = combo[0];
      if (combo.value == event.target.value) {
        combo.isChecked = event.target.checked;
        if (combo.isChecked) {
          var cost =
            Number(this.state.mealTotalState) +
            Number(combo.price) * Number(this.state.quantityPicker);
          var k = combo.value;
          var v = Number(combo.price) * Number(this.state.quantityPicker);

          var obj = {};
          obj[k] = v;
          this.setState(
            {
              additionalCostState: [...this.state.additionalCostState, obj],
              mealTotalState: cost,
            },
            () => {}
          );

          //this.setState({ additionalCostState: Number(this.state.additionalCostState) + (Number(combo.price) * Number(this.state.quantityPicker)), mealTotalState: cost })
        } else {
          var cost =
            Number(this.state.mealTotalState) -
            Number(combo.price) * Number(this.state.quantityPicker);
          var k = combo.value;
          var v = Number(combo.price) * Number(this.state.quantityPicker);
          var temp = new Array();
          this.state.additionalCostState.map((item) => {
            if (Object.keys(item) == k && Object.values(item) == v) {
              // dont push it into new array
            } else {
              var key = Object.keys(item);
              var val = Object.values(item);

              var obj = {};
              obj[key] = val[0];
              temp.push(obj);
            }
          });
          this.setState(
            { additionalCostState: temp, mealTotalState: cost },
            () => {}
          );
        }
      }

      if (combo.isChecked) {
        selectedCombo.push(combo.value);
      }
    });
    this.setState({ combos: combos, selectedCombo: selectedCombo });
  };

  calculateCartTotal = (products) => {
    var price = 0;
    for (var key in products) {
      price +=
        Number(products[key].prices.AUD) * Number(products[key].quantity);

      products[key].additionalCost &&
        products[key].additionalCost.map((item) => {
          var additionalPrice =
            Object.values(item)[0] * Number(products[key].quantity);
          if (!isNaN(Number(additionalPrice))) {
            price += Number(additionalPrice);
          }
        });
    }
    //price = Number(price) + Number(this.state.additionalCostState)
    return price;
  };

  setSelectedMealCategory = (
    meal_categories,
    truckData,
    selectedMealCategory
  ) => {
    if (Object.keys(truckData).length != 0) {
      this.setState(
        {
          mealList: [],
        },
        () => {
          truckData.meals.sort(function(a, b) {
            var nameA = a.category.priority,
              nameB = b.category.priority;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });

          var temp_category = truckData.meals.map((q) => q.category.title);

          meal_categories = temp_category.filter(
            (q, idx) => temp_category.indexOf(q) === idx
          );
          meal_categories.unshift("All");
          if (selectedMealCategory == "") {
            selectedMealCategory = "All";
          }

          this.setState(
            {
              selectedMealCategory: selectedMealCategory,
              mealCategories: meal_categories,
            },
            () => {
              var tempMealList = [];

              for (var i = 0; i < truckData.meals.length; i++) {
                if (
                  truckData.meals[i].category.title ==
                  this.state.selectedMealCategory
                ) {
                  tempMealList.push(truckData.meals[i]);
                }
              }

              this.setState(
                {
                  mealList: [...this.state.mealList, ...tempMealList],
                },
                () => {}
              );
            }
          );
        }
      );
    }
  };

  toggleisOpenCartModal() {
    this.state.isOpenCartModal
      ? this.setState({ isOpenCartModal: false })
      : this.setState({ isOpenCartModal: true });
  }

  addProduct = (key, product, currency) => {
    var values = Object.values(this.state.productsState);
    if (values.length == 0) {
      void this.setState(
        ({
          productsState: {
            [key]: cartProduct = { quantity: 0 },
            ...restOfProducts
          },
        }) => ({
          productsState: {
            ...restOfProducts,
            [key]: {
              ...product,
              quantity: product.quantity + cartProduct.quantity,
            },
          },
        }),
        () => {}
      );
    } else {
      for (var i = 0; i < values.length; i++) {
        var value = values[i];
        var match = false;
        var matchKey = -1;
        if (
          value.id == product.id &&
          value.properties.SpecialRequest.toString() ==
            product.properties.SpecialRequest.toString() &&
          value.properties.Combo.toString() ==
            product.properties.Combo.toString() &&
          value.properties.Subitem.toString() ==
            product.properties.Subitem.toString()
        ) {
          match = true;
          matchKey = Object.keys(this.state.productsState)[i];
          break;
        }
      }
      if (match) {
        // same product. Increase quanity
        void this.setState(
          ({
            productsState: {
              [matchKey]: cartProduct = { quantity: 0 },
              ...restOfProducts
            },
          }) => ({
            productsState: {
              ...restOfProducts,
              [matchKey]: {
                ...product,
                quantity: product.quantity + cartProduct.quantity,
              },
            },
          }),
          () => {}
        );
      } else {
        // same product with different extras. Add as new product
        var key = product.id + "_" + Date.now();
        this.setState(
          { productsState: { ...this.state.productsState, [key]: product } },
          () => {}
        );
      }
    }

    //dispatch(updateCartProducts(products))
  };

  generateProductKey = (id, properties) =>
    `${id}/${Object.entries(properties).join("_")}`;

  updateProduct = (key, updatedProduct) => {
    return void ":)";
  };

  removeProduct = (key) => {
    return void ":C";
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  addDefaultSrc = (e) => {
    e.target.src = require("../../assets/img/empty_logo.png");
  };

  render() {
    const { appReducer } = store.getState();
    const {
      selectedVendorData,
      products,
      productsTotal,
      eventTicket,
    } = appReducer;
    const {
      mealCategories,
      selectedMealCategory,
      isOpenCartModal,
      isFindOutMoreClicked,
      selectedMeal,
      combos,
      selectedSubItem,
      selectedCombo,
      getProductLocalization,
      getCheckoutButtonLocalization,
      getCartLocalization,
      productsState,
      product,
      productsTotalState,
      showAddToCartToast,
      specialRequest,
    } = this.state;

    var mealsByCategory = [];
    if (mealCategories.length != 0) {
      mealsByCategory = this.getMealsByCategory(selectedVendorData.meals);
    }
    const {
      addProduct,
      generateProductKey,
      updateProduct,
      removeProduct,
      isEmpty,
      state,
    } = this;

    const { loggedIn, username, userId, dispatch } = this.props;
    const iconButtonStyle = {
      backgroundColor: theme.colors.backgroundColor,
      fontSize: 40,
      padding: 8,
      borderRadius: 20,
      color: theme.colors.iconColor,
      marginLeft: 10,
      marginRight: 10,
    };

    const checkoutButtonElement = (
      <CheckoutButtonComponent
        grandTotal={productsTotalState}
        hidden={isEmpty(productsState) ? true : false}
        checkoutURL="/checkout"
        currency="AUD"
        getLocalization={getCheckoutButtonLocalization}
      />
    );
    var badgeLength =
      Number(Object.keys(eventTicket).length) +
      Number(Object.keys(productsState).length);
    var selectedEvent = "";
    if (getFromLocalStorage("selectedEvent") != "undefined") {
      selectedEvent = JSON.parse(getFromLocalStorage("selectedEvent"));
    }
    return (
      <div>
        <ResponsiveNavBar
          showBack={true}
          backUrl={"/event:" + selectedEvent.id}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>

        <Container>
          <Row>
            <Col
              lg={3}
              className="desktop-only"
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
              }}
            >
              {mealCategories.length != 0 ? (
                <VendorSideBar
                  mealCategories={mealCategories}
                  selectedMealCategory={selectedMealCategory}
                  toggleFoodTruckStreetModal={this.toggleisOpenCartModal.bind(
                    this
                  )}
                  setDivFocus={this.setDivFocus.bind(this)}
                  divFocus={this.state.divFocus}
                ></VendorSideBar>
              ) : null}
            </Col>
            <Col lg={9}>
              <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                  position: "absolute",
                  minHeight: "100px",
                }}
              >
                <Toast
                  style={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    zIndex: 102,
                  }}
                  onClose={() => this.setState({ showAddToCartToast: false })}
                  show={showAddToCartToast}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded mr-2"
                      alt=""
                    />
                    <strong className="mr-auto">Added to Cart</strong>
                    <small>just now</small>
                  </Toast.Header>
                  <Toast.Body>
                    {selectedMeal.name} is successfully added to your cart.{" "}
                  </Toast.Body>
                </Toast>
              </div>
              <TrackVisibility partialVisibility>
                {({ isVisible }) => {
                  if (this.state.carouselVisible != isVisible) {
                    this.setState({ carouselVisible: isVisible });
                  }
                  return (
                    <Row style={{ marginTop: 10 }}>
                      <Col lg={12}>
                        <Carousel
                          controls={false}
                          style={{ maxHeight: 313, overflow: "hidden" }}
                        >
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={
                                selectedVendorData.image &&
                                selectedVendorData.image != null &&
                                selectedVendorData.image != ""
                                  ? selectedVendorData.image
                                  : require("../../assets/img/default_cover.jpg")
                              }
                              alt={selectedVendorData.title}
                            />

                            <Carousel.Caption className="mobile-only">
                              <h3>{selectedVendorData.title}</h3>
                            </Carousel.Caption>
                            <div
                              style={{
                                height: 313,
                                backgroundColor: "black",
                                opacity: 0.5,
                                position: "absolute",
                                width: "100%",
                                top: 0,
                              }}
                              className="mobile-only"
                            ></div>
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                    </Row>
                  );
                }}
              </TrackVisibility>

              <Row>
                {this.renderMobileCategories()}
                {this.renderAddToCartModal()}

                {mealsByCategory.map((meal, i) => {
                  var mealsArray = meal[Object.keys(meal)];
                  return (
                    <div
                      key={i}
                      id={"category" + i}
                      style={{
                        paddingBottom: 40,
                        paddingTop: 40,
                        borderBottom: "1px solid #C7CACE",
                        paddingLeft: 15,
                        paddingRight: 15,
                        width: "100%",
                      }}
                    >
                      <Row>
                        <Col lg={12}>
                          <h4 style={{ textAlign: "left" }}>
                            {Object.keys(meal)}
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} lg={12}>
                          {mealsArray.map((meal, i) => {
                            return (
                              <>
                                {i % 4 == 0 ? (
                                  <Row>
                                    <Col xs={12} md={6} lg={3} key={i}>
                                      <Card
                                        style={{ height: 370, marginTop: 20 }}
                                      >
                                        <div
                                          style={{
                                            minHeight: 160,
                                            maxHeight: 160,
                                            overflow: "hidden",
                                          }}
                                        >
                                          <Card.Img
                                            onError={this.addDefaultSrc}
                                            className="align-middle"
                                            src={
                                              mealsArray[i].image
                                                ? mealsArray[i].image
                                                : require("../../assets/img/empty_logo.png")
                                            }
                                          />
                                        </div>
                                        <Card.Body variant="bottom">
                                          <Card.Title>
                                            {mealsArray[i].name}
                                          </Card.Title>
                                          <Card.Text>
                                            ${mealsArray[i].price}
                                          </Card.Text>
                                          <Button
                                            onClick={() => {
                                              const product = {
                                                name: mealsArray[i].name,
                                                id: "" + mealsArray[i].id,
                                                path: mealsArray[i].image
                                                  ? mealsArray[i].image
                                                  : require("../../assets/img/empty_logo.png"),
                                                prices: {
                                                  AUD: Number(
                                                    mealsArray[i].price
                                                  ),
                                                },
                                                currency: "AUD",
                                                imageSrc: mealsArray[i].image
                                                  ? mealsArray[i].image
                                                  : require("../../assets/img/empty_logo.png"),
                                                properties: {},
                                                propertiesToShowInCart: [],
                                                quantity: 1,
                                                additionalCost: this.state
                                                  .additionalCostState,
                                              };
                                              this.setState(
                                                {
                                                  combos: this.getComboArray(
                                                    mealsArray[i].combo
                                                  ),
                                                  mealTotalState:
                                                    Number(
                                                      this.state.quantityPicker
                                                    ) *
                                                    Number(mealsArray[i].price),
                                                },
                                                () => {
                                                  this.setState({
                                                    selectedMeal: mealsArray[i],
                                                    isOpenCartModal: true,
                                                  });
                                                }
                                              );

                                              //addProduct(mealsArray[i].id,product,"AUD")
                                            }}
                                            style={{
                                              backgroundColor:
                                                theme.colors.primaryColor,
                                              borderColor:
                                                theme.colors.primaryColor,
                                              color: "white",
                                            }}
                                          >
                                            Add To Cart
                                          </Button>
                                        </Card.Body>
                                      </Card>
                                    </Col>

                                    <Col xs={12} md={6} lg={3} key={i}>
                                      {mealsArray[i + 1] != undefined ? (
                                        <Card
                                          style={{ height: 370, marginTop: 20 }}
                                        >
                                          <div
                                            style={{
                                              minHeight: 160,
                                              maxHeight: 160,
                                              overflow: "hidden",
                                            }}
                                          >
                                            <Card.Img
                                              onError={this.addDefaultSrc}
                                              className="align-middle"
                                              src={
                                                mealsArray[i + 1].image
                                                  ? mealsArray[i + 1].image
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                            />
                                          </div>
                                          <Card.Body variant="bottom">
                                            <Card.Title>
                                              {mealsArray[i + 1].name}
                                            </Card.Title>
                                            <Card.Text>
                                              ${mealsArray[i + 1].price}
                                            </Card.Text>
                                            <Button
                                              onClick={() => {
                                                const product = {
                                                  name: mealsArray[i + 1].name,
                                                  id: "" + mealsArray[i + 1].id,
                                                  path: mealsArray[i + 1].image
                                                    ? mealsArray[i + 1].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  prices: {
                                                    AUD: Number(
                                                      mealsArray[i + 1].price
                                                    ),
                                                  },
                                                  currency: "AUD",
                                                  imageSrc: mealsArray[i + 1]
                                                    .image
                                                    ? mealsArray[i + 1].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  properties: {},
                                                  propertiesToShowInCart: [],
                                                  quantity: 1,
                                                  additionalCost: this.state
                                                    .additionalCostState,
                                                };
                                                this.setState(
                                                  {
                                                    combos: this.getComboArray(
                                                      mealsArray[i + 1].combo
                                                    ),
                                                    mealTotalState:
                                                      Number(
                                                        this.state
                                                          .quantityPicker
                                                      ) *
                                                      Number(
                                                        mealsArray[i + 1].price
                                                      ),
                                                  },
                                                  () => {
                                                    this.setState({
                                                      selectedMeal:
                                                        mealsArray[i + 1],
                                                      isOpenCartModal: true,
                                                    });
                                                  }
                                                );

                                                //addProduct(mealsArray[i+1].id,product,"AUD")
                                              }}
                                              style={{
                                                backgroundColor:
                                                  theme.colors.primaryColor,
                                                borderColor:
                                                  theme.colors.primaryColor,
                                                color: "white",
                                              }}
                                            >
                                              Add To Cart
                                            </Button>
                                          </Card.Body>
                                        </Card>
                                      ) : null}
                                    </Col>

                                    <Col xs={12} md={6} lg={3} key={i}>
                                      {mealsArray[i + 2] != undefined ? (
                                        <Card
                                          style={{ height: 370, marginTop: 20 }}
                                        >
                                          <div
                                            style={{
                                              minHeight: 160,
                                              maxHeight: 160,
                                              overflow: "hidden",
                                            }}
                                          >
                                            <Card.Img
                                              onError={this.addDefaultSrc}
                                              className="align-middle"
                                              src={
                                                mealsArray[i + 2].image
                                                  ? mealsArray[i + 2].image
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                            />
                                          </div>
                                          <Card.Body variant="bottom">
                                            <Card.Title>
                                              {mealsArray[i + 2].name}
                                            </Card.Title>
                                            <Card.Text>
                                              ${mealsArray[i + 2].price}
                                            </Card.Text>
                                            <Button
                                              onClick={() => {
                                                const product = {
                                                  name: mealsArray[i + 2].name,
                                                  id: "" + mealsArray[i + 2].id,
                                                  path: mealsArray[i + 2].image
                                                    ? mealsArray[i + 2].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  prices: {
                                                    AUD: Number(
                                                      mealsArray[i + 2].price
                                                    ),
                                                  },
                                                  currency: "AUD",
                                                  imageSrc: mealsArray[i + 2]
                                                    .image
                                                    ? mealsArray[i + 2].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  properties: {},
                                                  propertiesToShowInCart: [],
                                                  quantity: 1,
                                                  additionalCost: this.state
                                                    .additionalCostState,
                                                };
                                                this.setState(
                                                  {
                                                    combos: this.getComboArray(
                                                      mealsArray[i + 2].combo
                                                    ),
                                                    mealTotalState:
                                                      Number(
                                                        this.state
                                                          .quantityPicker
                                                      ) *
                                                      Number(
                                                        mealsArray[i + 2].price
                                                      ),
                                                  },
                                                  () => {
                                                    this.setState({
                                                      selectedMeal:
                                                        mealsArray[i + 2],
                                                      isOpenCartModal: true,
                                                    });
                                                  }
                                                );

                                                //addProduct(mealsArray[i+2].id,product,"AUD")
                                              }}
                                              style={{
                                                backgroundColor:
                                                  theme.colors.primaryColor,
                                                borderColor:
                                                  theme.colors.primaryColor,
                                                color: "white",
                                              }}
                                            >
                                              Add To Cart
                                            </Button>
                                          </Card.Body>
                                        </Card>
                                      ) : null}
                                    </Col>

                                    <Col xs={12} md={6} lg={3} key={i}>
                                      {mealsArray[i + 3] != undefined ? (
                                        <Card
                                          style={{ height: 370, marginTop: 20 }}
                                        >
                                          <div
                                            style={{
                                              minHeight: 160,
                                              maxHeight: 160,
                                              overflow: "hidden",
                                            }}
                                          >
                                            <Card.Img
                                              onError={this.addDefaultSrc}
                                              className="align-middle"
                                              src={
                                                mealsArray[i + 3].image
                                                  ? mealsArray[i + 3].image
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                            />
                                          </div>
                                          <Card.Body variant="bottom">
                                            <Card.Title>
                                              {mealsArray[i + 3].name}
                                            </Card.Title>
                                            <Card.Text>
                                              ${mealsArray[i + 3].price}
                                            </Card.Text>
                                            <Button
                                              onClick={() => {
                                                const product = {
                                                  name: mealsArray[i + 3].name,
                                                  id: "" + mealsArray[i + 3].id,
                                                  path: mealsArray[i + 3].image
                                                    ? mealsArray[i + 3].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  prices: {
                                                    AUD: Number(
                                                      mealsArray[i + 3].price
                                                    ),
                                                  },
                                                  currency: "AUD",
                                                  imageSrc: mealsArray[i + 3]
                                                    .image
                                                    ? mealsArray[i + 3].image
                                                    : require("../../assets/img/empty_logo.png"),
                                                  properties: {},
                                                  propertiesToShowInCart: [],
                                                  quantity: 1,
                                                  additionalCost: this.state
                                                    .additionalCostState,
                                                };
                                                this.setState(
                                                  {
                                                    combos: this.getComboArray(
                                                      mealsArray[i + 3].combo
                                                    ),
                                                    mealTotalState:
                                                      Number(
                                                        this.state
                                                          .quantityPicker
                                                      ) *
                                                      Number(
                                                        mealsArray[i + 3].price
                                                      ),
                                                  },
                                                  () => {
                                                    this.setState({
                                                      selectedMeal:
                                                        mealsArray[i + 3],
                                                      isOpenCartModal: true,
                                                    });
                                                  }
                                                );

                                                //addProduct(mealsArray[i+3].id,product,"AUD")
                                              }}
                                              style={{
                                                backgroundColor:
                                                  theme.colors.primaryColor,
                                                borderColor:
                                                  theme.colors.primaryColor,
                                                color: "white",
                                              }}
                                            >
                                              Add To Cart
                                            </Button>
                                          </Card.Body>
                                        </Card>
                                      ) : null}
                                    </Col>
                                  </Row>
                                ) : null}
                              </>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
        {(productsState && !this.isEmpty(productsState)) ||
        (eventTicket && !this.isEmpty(eventTicket)) ? (
          <Row
            className="mobile-only"
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              padding: 10,
              margin: "0 auto",
              backgroundColor: "white",
              paddingBottom: 30,
            }}
          >
            <Button
              onClick={() => {
                window.location.href = "/checkout";
              }}
              block
              className="button-enabled-pink-style"
              style={{ marginTop: 20 }}
            >
              Checkout Now
            </Button>
          </Row>
        ) : null}
      </div>
    );
  }

  renderMobileCategories = () => {
    const {
      mealCategories,
      selectedMealCategory,
      carouselVisible,
    } = this.state;

    return (
      <Row
        style={
          carouselVisible == false
            ? {
                position: "fixed",
                top: 0,
                zIndex: 100,
                backgroundColor: "white",
                paddingTop: 10,
              }
            : { marginTop: 10 }
        }
      >
        <Col lg={12} style={{ overflow: "scroll", width: "100vw" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
              marginLeft: 15,
              marginRight: 15,
            }}
            className="mobile-only"
          >
            {mealCategories.map((category, k) => {
              var selected = selectedMealCategory == category;
              return (
                <p
                  onClick={() => {
                    this.setDivFocus("category" + (k - 1), category);
                  }}
                  style={{
                    padding: 10,
                    backgroundColor: selected ? "#2859a5" : "#ffffff",
                    color: selected ? "white" : "black",
                    marginRight: 10,
                    borderRadius: 40,
                    borderWidth: 1,
                    borderColor: "#2859a5",
                    borderStyle: "solid",
                    minWidth: 100,
                    textAlign: "center",
                  }}
                >
                  {category}
                </p>
              );
            })}
          </div>
        </Col>
      </Row>
    );
  };

  renderAddToCartModal = () => {
    const {
      isOpenCartModal,
      selectedMeal,
      combos,
      selectedSubItem,
      selectedCombo,
      specialRequest,
    } = this.state;
    return (
      <Modal
        show={isOpenCartModal}
        onHide={this.toggleisOpenCartModal.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", width: "90%", margin: "auto" }}
        >
          <Card style={{ height: 500, marginTop: 20, overflowY: "scroll" }}>
            <div style={{ minHeight: 250, maxHeight: 250, overflow: "hidden" }}>
              <Card.Img
                onError={this.addDefaultSrc}
                className="align-middle"
                src={
                  selectedMeal.image
                    ? selectedMeal.image
                    : require("../../assets/img/empty_logo.png")
                }
              />
            </div>
            <Card.Body variant="bottom">
              <Card.Title>{selectedMeal.name}</Card.Title>
              <Card.Text>{selectedMeal.description}</Card.Text>
              <Row>
                <Col
                  className="text-center"
                  xs={3}
                  onClick={() => {
                    this.setState((prevState, props) => ({
                      quantityPicker: prevState.quantityPicker - 1,
                    }));
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: theme.colors.borderColor,
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    -
                  </div>
                </Col>
                <Col xs={6}>
                  {" "}
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    {this.state.quantityPicker}
                  </div>{" "}
                </Col>
                <Col
                  className="text-center"
                  xs={3}
                  onClick={() => {
                    this.setState((prevState, props) => ({
                      quantityPicker: prevState.quantityPicker + 1,
                    }));
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: theme.colors.borderColor,
                      cursor: "pointer",
                      width: 50,
                      height: 50,
                      display: "inline-block",
                      lineHeight: "50px",
                    }}
                  >
                    +
                  </div>
                </Col>
              </Row>
              {selectedMeal.subitem && selectedMeal.subitem.length > 0 ? (
                <Row style={{ marginTop: 20 }}>
                  <Card.Title
                    style={{
                      textAlign: "left",
                      backgroundColor: "#c7cace",
                      padding: 10,
                      width: "100%",
                    }}
                  >
                    Pick One
                  </Card.Title>
                  <Col xs={12}>
                    {selectedMeal.subitem.map((subitem) => {
                      return (
                        <li style={{ listStyle: "none", textAlign: "left" }}>
                          <input
                            key={subitem.id}
                            onClick={() => {
                              this.setState({ selectedSubItem: subitem });
                            }}
                            type="radio"
                            checked={
                              this.state.selectedSubItem === subitem
                                ? true
                                : false
                            }
                            value={subitem.name}
                          />{" "}
                          {subitem.name}
                        </li>
                      );
                    })}
                  </Col>
                </Row>
              ) : null}
              {selectedMeal.combo && selectedMeal.combo.length > 0 ? (
                <Row style={{ marginTop: 20 }}>
                  <Card.Title
                    style={{
                      textAlign: "left",
                      backgroundColor: "#c7cace",
                      padding: 10,
                      width: "100%",
                    }}
                  >
                    Add Extras
                  </Card.Title>
                  <Col xs={12}>
                    {combos &&
                      combos.length > 0 &&
                      combos.map((combo) => {
                        return (
                          <CheckBox
                            handleCheckComboElement={
                              this.handleCheckComboElement
                            }
                            {...combo}
                          />
                        );
                      })}
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginTop: 20 }}>
                <Card.Title
                  style={{
                    textAlign: "left",
                    backgroundColor: "#c7cace",
                    padding: 10,
                    width: "100%",
                  }}
                >
                  Special Request
                </Card.Title>
                <Col xs={12}>
                  <Form.Group controlId="formBasicSpecialRequest">
                    <Form.Control
                      type="text"
                      placeholder="Eg: No Onions"
                      value={specialRequest}
                      onChange={(e) => {
                        this.setState({ specialRequest: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton
            backgroundColor={theme.colors.primaryColor}
            textColor={theme.colors.textColorLight}
            borderRadius={20}
            isProfilePic={false}
            buttonText={
              "Add to Cart $" + Number(this.state.mealTotalState).toFixed(2)
            }
            handleButtonClick={() => {
              const product = {
                name: selectedMeal.name,
                id: "" + selectedMeal.id,
                path: selectedMeal.image
                  ? selectedMeal.image
                  : require("../../assets/img/empty_logo.png"),
                prices: { AUD: Number(selectedMeal.price) },
                currency: "AUD",
                imageSrc: selectedMeal.image
                  ? selectedMeal.image
                  : require("../../assets/img/empty_logo.png"),
                properties: {
                  Combo: [selectedCombo],
                  Subitem: [selectedSubItem.name],
                  SpecialRequest: [specialRequest],
                },
                propertiesToShowInCart: ["Combo", "Subitem", "SpecialRequest"],
                quantity: this.state.quantityPicker,
                additionalCost: this.state.additionalCostState,
              };
              this.setState({ isOpenCartModal: false }, () => {
                this.setState(
                  {
                    showAddToCartToast: true,
                    additionalCostState: new Array(),
                    selectedSubItem: {},
                    selectedCombo: new Array(),
                    specialRequest: "",
                  },
                  () => {
                    this.addProduct(selectedMeal.id, product, "AUD");
                  }
                );
              });
            }}
            buttonWidth={"90%"}
            margin={"auto"}
          ></CustomButton>
        </Modal.Footer>
      </Modal>
    );
  };
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(MenuScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
