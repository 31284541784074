import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  FormControl,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Card,
} from "react-bootstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import ImageUploader from "react-images-upload";
import { ShareSocial } from "react-share-social";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SignaturePad from "../ReactSignatureCanvas/index.js";
import { AWS_URL } from "../../assets/Constants";

class AdminMembershipContracts extends Component {
  state = {
    isAgreed: false,
    showModal: false,
    signatureData: null,
    operatorSign: "",
    isSubmitting: false,
    searchVendor: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    eventName: "",
    eventType: "public",
    eventStatus: "active",
    bookingRequests: [],
    siteArea: 0,
    eventStartDateTime: "",
    eventEndDateTime: "",
    busyStartTime: "02:00 AM",
    busyEndTime: "02:00 AM",
    eventLocation: "",
    coverImage: "",
    onlineOrdering: true,
    selectedVendorList: [],
    selectedVendorName: [],
    vendorList: [],
    tempVendorList: [],
    eventDescription: "",
    coverImage: [],
    showSuccess: false,
    eventId: -1,
    errorArray: [],
    nonMembersCommisiion: "0",
    membersCommission: "0",
    siteFees: "0",
  };

  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_admin_pending_subscription",
          admin_email: getFromLocalStorage("email"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.pending_aggrements });
          console.log(dataResponse);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleAgreementChange = (event) => {
    const isAgreed = event.target.checked;
    this.setState({ isAgreed, showModal: isAgreed });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  sendSignatureToServer = async (id, signatureData) => {
    try {
      // const base64Response1 = await fetch(this.state.operatorSign); // Use the signatureData parameter
      // const blob1 = await base64Response1.blob();
      // const file1 = new File([blob1], "operator_signature.jpg", {
      //   type: "image/jpeg",
      // });
      let currentDate = moment().format("DD MMM YYYY");
      const base64Response = await fetch(signatureData); // Use the signatureData parameter
      const blob = await base64Response.blob();
      const filename = `wtt_membership_sign_${moment(currentDate).format(
        "DD MMM YYYY"
      )}.jpg`;
      const file = new File([blob], filename, {
        type: "image/jpeg",
      });

      // Prepare FormData with the signature file and additional data
      const formData = new FormData();
      formData.append("wtt_membership_sign", file);
      formData.append("contract_id", id);
      formData.append("operator_name", this.state.operatorName);
      formData.append("timestamp", moment(currentDate).format("DD MMM YYYY"));

      // Send the signature file and additional data to the server
      const response = await axios.post(
        config.BASE_URI + apiPaths.signSubscription,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success === true) {
        alert("You have successfully signed the contract.");
        this.setState({
          selectedVendorId: "",
          operatorName: "",
          isAgreed: false,
          showModal: false,
          operatorSign: "",
        });
        //window.location.reload();
      } else {
        alert("Error sending signature to server:");
        //window.location.reload();
      }
    } catch (error) {
      alert("There was an error processing your signature. Please try again.");
      //window.location.reload();
    }
  };

  saveSignature = (signatureData, id) => {
    if (this.state.operatorName === "") {
      alert("Please enter your name");
    } else {
      this.setState({ signatureData }, () => {
        this.sendSignatureToServer(id, signatureData); // Assuming you modify this function to accept id and event
        this.handleCloseModal();
        this.setState({ isAgreed: false });
      });
      // this.setState(
      //   {
      //     operatorSign: signatureData,
      //   },
      //   () => {
      //     this.handleCloseModal();
      //   }
      // );
    }
  };
  componentDidMount() {
    this.getDashboardData();
  }

  render() {
    const {
      isSuperAdminLoggedIn,
      eventStartDateTime,
      searchVendor,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      siteArea,
      eventName,
      eventType,
      eventStatus,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      nonMembersCommisiion,
      membersCommission,
      siteFees,
      bookingRequests,
      isAgreed,
      showModal,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Row>
            <Col xs={12} md={12} xs={12}>
              <h2>WTT Confirm Membership</h2>
            </Col>
          </Row>
          {bookingRequests.map((item) => (
            <Accordion key={item.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.vendor__title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <>
                    {item.draft_agreement ? (
                      <iframe
                        src={AWS_URL + "/" + item.draft_agreement}
                        style={{
                          width: "100%",
                          height: "700px",
                          border: "none",
                        }}
                        title={`Document - ${item.vendor__title}`}
                      ></iframe>
                    ) : (
                      <Typography>No contract available</Typography>
                    )}
                    <div>
                      <label
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isAgreed}
                          onChange={this.handleAgreementChange}
                          style={{ marginRight: "4px" }} // Added a small right margin to the checkbox
                        />
                        I agree to the above terms
                      </label>

                      <Modal show={showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Signature Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="name-field">
                            <label htmlFor="operatorName">Your Name:</label>
                            <input
                              type="text"
                              id="operatorName"
                              onChange={(e) =>
                                this.setState({
                                  operatorName: e.target.value,
                                })
                              }
                              placeholder="Full Name"
                            />
                          </div>
                          <SignaturePad
                            onSave={(signatureData) =>
                              this.saveSignature(signatureData, item.id)
                            }
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <button onClick={this.handleCloseModal}>Close</button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminMembershipContracts, {
    fallback: <ErrorFallbackComponent />,
  })
);
