import React from "react";
import ChatBot from "react-simple-chatbot";
import Container from "@mui/material/Container";
import { Segment } from "semantic-ui-react";
import { ThemeProvider } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import assistant from "./Fotor_AI.png";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

function ChatBotApp() {
  const theme = {
    background: "#E9EEF5",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#2959A5",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#2959A5",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };
  const style1 = {
    zIndex: 2,
  };
  let firstName = JSON.parse(getFromLocalStorage("Operator"))[0].first_name;
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const steps = [
    {
      id: "0",
      message: `Good Day ${firstName}!`,
      trigger: "1",
    },
    {
      id: "1",
      message: "Please Select How can I help you today?",
      trigger: "4",
    },
    {
      id: "4",
      options: [
        {
          value: "Adding my food outlet/truck",
          label: "Adding my food outlet/truck",
          trigger: "5",
        },
        {
          value: "Uploading my documents in WTT",
          label: "Uploading my documents in WTT",
          trigger: "13",
        },
        {
          value: "Applying an event EOI",
          label: "Applying an EOI",
          trigger: "10",
        },
        {
          value: "Catering EOI",
          label: "Catering EOI",
          trigger: "19",
        },
        {
          value: "Upload Event Menu",
          label: "Upload Event Menu",
          trigger: "22",
        },
        {
          value: "Upload Catering Menu",
          label: "Upload Catering Menu",
          trigger: "23",
        },
        {
          value: "Ordering stocks and utilities",
          label: "Ordering stocks and utilities",
          trigger: "16",
        },
      ],
    },
    {
      id: "5",
      message:
        "To add an outlet, click the 'Add new Outlet' button in the menu and fill the details of the outlet. Please make sure all the field marked as important are filled and press 'submit button.",
      trigger: "6",
    },
    {
      id: "6",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "7",
    },
    {
      id: "7",
      component: (
        <a href="https://www.youtube.com/watch?v=V7kMG2Mo7Hg" target="_blank">
          Add new Outlet
        </a>
      ),
      trigger: "8",
    },
    {
      id: "8",
      message: "Is there anything else I can help you?",
      trigger: "9",
    },
    {
      id: "9",
      options: [
        {
          value: "Yes",
          label: "Yes",
          trigger: "4",
        },
        { value: "No", label: "No", trigger: "end" },
      ],
    },
    {
      id: "end",
      message:
        "Thank you for chatting with me. I hope I was able to assist you with your queries. Have a wonderful day ahead!",
      end: true,
    },
    {
      id: "10",
      message:
        "Applying for an event is easy! Simply browse the events listed and select the date(s) you are interested in by hovering over the dates to see the name of the event. Then, drag and drop your food truck outlet into the selected event(s) to express your interest in participating. Our event coordinators will review your application and confirm your participation. If you have any questions or encounter any issues during the application process, please feel free to reach out to us for assistance.",
      trigger: "11",
    },
    {
      id: "19",
      message:
        "Participating in a catering event is a straightforward process. Begin by exploring the available catering events by selecting the date range. Once you have identified the catering event(s) you're interested in, simply drag and drop your stall into the selected event(s) to indicate your desire to participate also select the catering pack you want to apply for that particular event. Our dedicated team of coordinators will carefully review your application and provide confirmation for your involvement. If you have any inquiries or encounter any challenges throughout the application procedure, please do not hesitate to contact us for guidance and support.",
      trigger: "20",
    },
    {
      id: "22",
      message:
        "Firstly, click the Events Menu in the menu bar and select your stall. You'll see an Add button in the top right corner; click it, and you'll be directed to a form. Fill in the item details, then click Submit. You can also edit the details by pressing the Edit button.",
      trigger: "24",
    },
    {
      id: "23",
      message:
        "Firstly, click the Cateriing Menu in the menu bar and select your stall. You'll see an Add button in the top right corner; click it, and you'll be directed to a form. Fill in the item details, then click Submit. You can also edit the details by pressing the Edit button.",
      trigger: "25",
    },
    {
      id: "20",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "21",
    },
    {
      id: "24",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "26",
    },
    {
      id: "25",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "27",
    },
    {
      id: "21",
      component: (
        <a href="https://www.youtube.com/watch?v=qdf_LcKRX6E" target="_blank">
          Apply Catering Event
        </a>
      ),
      trigger: "8",
    },
    {
      id: "26",
      component: (
        <a href="https://www.youtube.com/watch?v=jQm28kPp_DY" target="_blank">
          Upload Event Menu
        </a>
      ),
      trigger: "8",
    },
    {
      id: "27",
      component: (
        <a href="https://www.youtube.com/watch?v=mSi0Jl0FYHc" target="_blank">
          Upload Catering Menu
        </a>
      ),
      trigger: "8",
    },
    {
      id: "11",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "12",
    },
    {
      id: "12",
      component: (
        <a href="https://www.youtube.com/watch?v=kw1RNB4HRGk" target="_blank">
          Apply an EOI
        </a>
      ),
      trigger: "8",
    },
    {
      id: "13",
      message:
        "To add the documents, click the 'Documents' button in the menu and upload your documents. Please make sure all the field marked as important are filled and press 'submit' button.",
      trigger: "14",
    },
    {
      id: "14",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "15",
    },
    {
      id: "15",
      component: (
        <a href="https://youtu.be/upgHaOkNL0Y" target="_blank">
          Upload Documents
        </a>
      ),
      trigger: "8",
    },
    {
      id: "16",
      message:
        "To place an order for stock, click on 'event purchases' in the menu bar and then select 'event stock purchase' from the dropdown menu. On the stock ordering page, select the outlet for which you want to order the stocks for the event you're participating in. Then select the required quantities of the drinks you wish to purchase. Please note that all drinks come in packs of different quantities. Finally, click the 'place order' button to submit your order. You will receive a notification confirming whether your order has been successful or not.",
      trigger: "17",
    },
    {
      id: "17",
      message: `For the detailed tutorial please watch the below video \n`,
      trigger: "18",
    },
    {
      id: "18",
      component: (
        <a href="https://www.youtube.com/watch?v=hpn-J-xXeUM&t" target="_blank">
          Ordering Stocks/Utilities
        </a>
      ),
      trigger: "8",
    },
  ];
  return (
    <>
      <Segment zIndex="999">
        <ThemeProvider theme={theme}>
          <ChatBot
            steps={steps}
            headerTitle="Tyler from WTT"
            botAvatar={assistant}
          />
        </ThemeProvider>
      </Segment>
    </>
  );
}

export default Sentry.withErrorBoundary(ChatBotApp, {
  fallback: <ErrorFallbackComponent />,
});
