import React, { Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import { TextField } from "@material-ui/core";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockPortalPendingStockQuote extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    pricePerItem: 0,
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    disapprovedReason: "",
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  fetchPendingOrderFromServer = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_pending_quote_requests",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.stockData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ pendingOrder: result.data });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        alert("Error! Please try again");
      });
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  handlePricePerItem = (event) => {
    const value = event.target.value;
    this.setState({ pricePerItem: value });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isStockAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.fetchPendingOrderFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  disapprovOrder = (orderId) => {
    const {
      disapprovedReason,
      email,
      password,
      superAdminEmail,
      superAdminPassword,
    } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var data = {};
    if (email != "" && password != "") {
      data = {
        email: email,
        password: password,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    } else if (superAdminEmail != "" && superAdminPassword != "") {
      data = {
        email: superAdminEmail,
        password: superAdminPassword,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    }
    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Disapproved Succesfully");
          window.location.href = "/stock-admin";
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        alert("Error! Please try again!");
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const { pendingOrder } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>WTT Stock Ordering Portal</h2>
              <h3>Pending Quotes</h3>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Quote #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Order Date and Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Additional Notes
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Has Requested Best Quality
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Has Requested Best Price
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Delivery Address
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col"></Col>
          </Row>
          {pendingOrder.map((item, i) => {
            return (
              <>
                <Accordion key={"main" + item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Order Number:</b>{" "}
                            {item.id}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {moment(item.order_placed_date_time).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          -{" "}
                          {moment(item.order_placed_date_time).format(
                            "HH:mm:ss"
                          )}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.additional_notes}
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Sub Total:</b>
                            {item.has_requested_best_quality ? "Yes" : "No"}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">GST:</b>
                            {item.has_requested_best_price ? "Yes" : "No"}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Total:</b>
                            {item.quote_status}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">Status:</b>
                            {item.operator_name__shipping_address}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={item.id}
                      style={{ padding: 20, backgroundColor: "#eff2f5" }}
                    >
                      <>
                        <Row className="table-header-row">
                          <Col
                            className="desktop-only"
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Id
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Product Name
                          </Col>
                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Qty
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Price
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Sub Total
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            SKU Code
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Total
                          </Col>
                        </Row>
                        {item.orderData.map((product, i) => {
                          return (
                            <>
                              <Row key={"orderData" + i} className="table-row">
                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span className="desktop-only">
                                    {product.id}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  {product.product_name}
                                </Col>

                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Qty:</b>{" "}
                                    {product.product_qty}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Price:</b>{" "}
                                    {product.price_per_item == 0 ? (
                                      <input
                                        type="number"
                                        value={this.state.pricePerItem}
                                        onChange={this.handlePricePerItem.bind(
                                          this
                                        )}
                                      />
                                    ) : (
                                      product.price_per_item
                                    )}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Sub Total:</b>{" "}
                                    {Number(product.sub_total) == 0
                                      ? this.state.pricePerItem *
                                        product.product_qty
                                      : this.state.pricePerItem *
                                        product.product_qty}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">GST:</b>{" "}
                                    {product.stock_quote_catelogue_id__sku_code}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Total:</b>$
                                    {this.state.pricePerItem *
                                      product.product_qty}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <Row className="button-row">
                          <Col xs={12}>
                            <Button
                              onClick={() => {
                                window.location.href =
                                  "/stock-admin-order-edit:" + item.id;
                              }}
                            >
                              Send Quote to Vendors
                            </Button>
                          </Col>
                        </Row>
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
              </>
            );
          })}
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockPortalPendingStockQuote, {
    fallback: <ErrorFallbackComponent />,
  })
);
