import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import Media from 'react-bootstrap/Media'
import EllipsisText from "react-ellipsis-text";
import { theme } from '../theme'
import InstaFeed from '../InstaFeed';


class FloatingPanel extends Component {

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    state = {
        width: 0, height: 0
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const listGroupItemStyle={
            border:0,
            borderRadius:'inherit'
        }
        const listGroupStyle = {
            backgroundColor:theme.colors.textColorLight,
            border:'1px solid ' + theme.colors.borderColor,
            borderRadius:20,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            transition: "0.3s",
        }
        const ellipsisTextStyle = 
        { 
            fontSize: 12, lineHeight: 0 
        }
        const text = 'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.Donec lacinia congue felis in faucibus.'
        if (this.state.width >= 1350) {
            return (
                <div style={{ position: 'absolute', top: 100, left: 20, zIndex: 100, width: 300 }}>

                    <ListGroup style={listGroupStyle}>
                        <ListGroup.Item style={listGroupItemStyle}><h5>What's Latest</h5></ListGroup.Item>

                        <InstaFeed account="kitchaco" numberOfMediaElements={1} />

                    </ListGroup>
                </div>
            );
        }
        else{
            return (
                <div>
                </div>
            );
        }
    }
}

export default FloatingPanel;
