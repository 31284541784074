import moment from "moment";
import axios from 'axios';




const OPENAI_API_KEY = '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';

export const generateTruckBio = async (truckName, cuisineType, city, features, popularItems, vendorType) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that generates creative bios for food trucks.'
          },
          {
            role: 'user',
            content: `Write a creative and engaging 255-word bio for a food truck named "${truckName}". It specializes in ${cuisineType} and is located in ${city}. It has the following features: ${features}. The two most popular items on the menu are: ${popularItems}. The vendor focuses on: ${vendorType}. make it 255 caracters only for validation purpouse`
          }
        ],
        max_tokens: 500,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    // Correctly extract the content from the response object
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating food truck bio:', error);
    return 'Failed to generate bio';
  }
};

export const generateCateringTruckBio = async (truckName, cuisineType, city, features, popularItems, vendorType) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that generates creative bios for food trucks.'
          },
          {
            role: 'user',
            content: `Write a creative and engaging 255-word bio for a food truck named "${truckName}". It specializes in ${cuisineType} and is located in ${city}. It has the following features: ${features}. The two most popular items on the menu are: ${popularItems}. The vendor focuses on: ${vendorType}. make it 255 caracters `
          }
        ],
        max_tokens: 500,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    // Correctly extract the content from the response object
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating food truck bio:', error);
    return 'Failed to generate bio';
  }
};

export const generateMealDescription = async (truckName, cuisineType, city, features, popularItems, vendorType) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that generates creative bios for food trucks.'
          },
          {
            role: 'user',
            content: `Write a creative and engaging 255-word bio for a food truck named "${truckName}". It specializes in ${cuisineType} and is located in ${city}. It has the following features: ${features}. The two most popular items on the menu are: ${popularItems}. The vendor focuses on: ${vendorType}. make it 255 caracters `
          }
        ],
        max_tokens: 500,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    // Correctly extract the content from the response object
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating food truck bio:', error);
    return 'Failed to generate bio';
  }
};


export const hasEventExpired = (eventDetails, type) => {
  // Ensure the current timestamp is in the correct format
  var currentTimestamp = moment().format("DD MMM YYYY hh:mm A");

  // Construct event start and end timestamps
  var eventStartTimestamp = moment(eventDetails.start_date + " " + eventDetails.start_time, "DD MMM YYYY hh:mm A");
  var eventEndTimestamp = moment(eventDetails.end_date + " " + eventDetails.end_time, "DD MMM YYYY hh:mm A");

  // Calculate differences with the current timestamp
  var diffWithEnd = eventEndTimestamp.diff(moment(currentTimestamp, "DD MMM YYYY hh:mm A"), "minutes");
  var diffWithStart = eventStartTimestamp.diff(moment(currentTimestamp, "DD MMM YYYY hh:mm A"), "minutes");

  // Adjust the end time difference for specific event types
  if (type === "food") {
    if (
      eventDetails.event_type === "catering" ||
      eventDetails.event_type === "ftims" ||
      eventDetails.event_type === "private"
    ) {
      diffWithEnd += 60;
    }
  }

  // Determine if the event has expired
  if (diffWithEnd < 0) {
    return true;
  } else {
    return false;
  }
};
