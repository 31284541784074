import React from "react";
import "./SearchResultsList.css";
import SearchResultVendor from "./SearchResultVendor";

const SearchResultsListVendor = ({
  filteredVendors,
  selectedVendorP,
  selectedVendorQ,
}) => {
  const selectedVendor = (data) => {
    selectedVendorP(data);
  };
  const selectedVendora = (data) => {
    selectedVendorQ(data);
  };
  return (
    <div className="results-list">
      {filteredVendors &&
        filteredVendors.map((vendor, id) => {
          return (
            <SearchResultVendor
              filteredVendor={vendor.title}
              filteredId={vendor.user_ptr_id}
              key={id}
              selectedVendor={selectedVendor}
              selectedVendora={selectedVendora}
            />
          );
        })}
    </div>
  );
};
export default SearchResultsListVendor;
