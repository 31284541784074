export default function getDropDownData(data) {
    let result = [""];

    for (let diet of data) {
        result.push(diet.title);
    }
    return result;
}



