import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

class VendorAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftData: this.props.shiftData,
      sales_by_vendor_obj: this.props.sales_by_vendor_obj,
    };
  }

  prepareChartData(costByHour) {
    return {
      labels: Object.keys(costByHour),
      datasets: [
        {
          label: "Cost by Day",
          data: Object.values(costByHour),
          fill: false,
          backgroundColor: "#016815",
          borderColor: "#02ba26",
        },
      ],
    };
  }

  renderChart(data, title) {
    if (!data || !title) {
      return (
        <Typography variant="h6" style={{ textAlign: "center" }}>
          No data available
        </Typography>
      );
    }

    const chartData = this.prepareChartData(data.cost_by_hour);
    const sales = this.state.sales_by_vendor_obj[title];
    return (
      <>
        {typeof sales === "number" ? (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Total Sales: ${sales.toLocaleString()}
          </Typography>
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Sales data unavailable
          </Typography>
        )}
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Total Wage Cost: $
          {data.total_cost ? data.total_cost.toFixed(2) : "0.00"}
          {sales > 0
            ? ` (${((data.total_cost / sales) * 100).toFixed(
                2
              )}% of total sales)`
            : ""}
        </Typography>
        <Line data={chartData} />
      </>
    );
  }

  renderAccordion() {
    const { shiftData } = this.state;

    return Array.isArray(shiftData) && shiftData.length > 0 ? (
      shiftData.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1a-content`}
            id={`panel1a-header-${index}`}
          >
            <Typography>{data.vendor_title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data.shift_data && typeof data.shift_data === "object" ? (
              this.renderChart(data.shift_data, data.vendor_title)
            ) : (
              <Typography>No data available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))
    ) : (
      <Typography style={{ textAlign: "center" }}>
        No shift data available
      </Typography>
    );
  }

  render() {
    return <div>{this.renderAccordion()}</div>;
  }
}

export default VendorAccordion;
