import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const CustomerOrders = () => {
  return (
    <div>
      <p>Hello</p>
    </div>
  );
};

export default CustomerOrders;
