import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../../components/UI/AdminNavBar";
import Footer from "../../../components/UI/Footer";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../../store";
import * as Sentry from "@sentry/react";
import { Container, Button, Modal } from "react-bootstrap";
import AspectRatio from "@mui/joy/AspectRatio";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Avatar from "@mui/joy/Avatar";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "../utils.js";
import "./style1.css";
// import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EOIPage from "../../../components/UI/EOI/index";
import mobile_ice from "../../../assets/img/MobileIceCreams.png";
import inkredible_calamari from "../../../assets/img/inkredible_calamari.png";
import i_love_dumplings from "../../../assets/img/iLoveDumplings.png";
import mi_casa from "../../../assets/img/Mi_casa.png";
import Benz_On_miller from "../../../assets/img/Benz_On_miller.png";
import Kind_of_the_wings from "../../../assets/img/Kind_of_the_wings.jpeg";
import Wholly_schnit from "../../../assets/img/Wholly_schnit.png";
import DUO_ice_cream from "../../../assets/img/DUO_ice_cream.png";
import Mister_Gee_burgers from "../../../assets/img/Mister_Gee_burgers.png";
import Flower_Show from "../../../assets/img/flowerShow.png";
import Racing_Club from "../../../assets/img/racingClub.png";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

class LandingScreen extends Component {
  state = {
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    canCreateEOI:
      getFromLocalStorage("canCreateEOI") != "undefined" &&
      getFromLocalStorage("canCreateEOI") != "" &&
      getFromLocalStorage("canCreateEOI") != null
        ? getFromLocalStorage("canCreateEOI")
        : "false",
    isSuperAdminLoggedIn: "false",
    email: getFromLocalStorage("email"),
    password: getFromLocalStorage("password"),
    isOperatorLoggedIn: "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    active: false,
    isLoading: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    total_vouchers_value: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    deleteFromLocalStorage("Operator", "");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/");
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      isOrganiserLoggedIn,
      vendorName,
    } = this.state;
    return (
      <>
        {/* <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isOperatorLoggedIn={isOperatorLoggedIn}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></AdminNavBar> */}
        {isSuperAdminLoggedIn == "false" &&
        isOperatorLoggedIn == "false" &&
        isOrganiserLoggedIn == "false" ? (
          window.location.replace("/organiser")
        ) : (
          <>{this.renderHome()}</>
        )}
      </>
    );
  }

  getEventData() {
    return this.events.filter((e) => e.id === e.event);
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      total_vouchers_value,
      active,
      toggleMenu,
      canCreateEOI,
    } = this.state;
    console.log(canCreateEOI);
    if (selectedEvent) {
    }

    return (
      <div className="all">
        {!isLoading ? (
          <>
            <section id="header">
              <div className="header containerl">
                <div className="nav-bar">
                  <div className="brand">
                    <a href="#hero">
                      <h1 style={{ fontSize: "2em" }}>
                        <span>Home</span>
                      </h1>
                    </a>
                  </div>
                  <div className="nav-list">
                    <div className="hamburger" onClick={this.toggleMenu}>
                      <div className="bar"></div>
                    </div>
                    <ul className={this.state.active ? "active" : ""}>
                      {canCreateEOI === "true" ? (
                        <li>
                          <a
                            href="#services"
                            data-after="Service"
                            style={{ fontSize: "1.5em" }}
                            onClick={() =>
                              window.location.replace("create-events")
                            }
                          >
                            Create EOI
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}

                      <li>
                        <a
                          href="#solutions"
                          data-after="Solution"
                          style={{ fontSize: "1.5em" }}
                          onClick={() =>
                            window.location.replace("view-pending-vendors")
                          }
                        >
                          View Current EOI's
                        </a>
                      </li>
                      <li>
                        <a
                          href="#projects"
                          data-after="Projects"
                          style={{ fontSize: "1.5em" }}
                          onClick={() =>
                            window.location.replace("view-past-events")
                          }
                        >
                          View Past Events
                        </a>
                      </li>
                      <li>
                        <a
                          href="/organiser"
                          data-after="About"
                          style={{ fontSize: "1.5em" }}
                          onClick={this.logout}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section id="heroo">
              <div class="heroo containerll">
                <div></div>
              </div>
            </section>
            <section id="services">
              <div class="services containerll">
                <div class="service-top">
                  <Card
                    data-resizable
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#ef3e6d",
                      color: "#fff",
                      overflow: "auto",
                      resize: "horizontal",
                      "--icon-size": "100px",
                    }}
                  >
                    <CardOverflow variant="solid" color="#EF3E6D">
                      <AspectRatio
                        variant="outlined"
                        color="warning"
                        ratio="1"
                        sx={{
                          m: "auto",
                          transform: "translateY(50%)",
                          borderRadius: "50%",
                          width: "var(--icon-size)",
                          boxShadow: "sm",
                          bgcolor: "background.surface",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          sx={{ "--Avatar-size": "4rem" }}
                          src="./img/img-1.png"
                        />
                      </AspectRatio>
                    </CardOverflow>
                    <Typography
                      level="title-lg"
                      sx={{ mt: "calc(var(--icon-size) / 2)", color: "#fff" }}
                    >
                      What Event Organisers Say
                    </Typography>
                    <CardContent sx={{ maxWidth: "40ch" }}>
                      Where the Truck has been a game-changer for me when it
                      comes to running events. The platform provides a seamless
                      experience, allowing me to effortlessly manage all the
                      necessary documents and logistics. With Where the Truck
                      taking care of the paperwork, I can now focus my attention
                      on creating unforgettable experiences for event attendees.
                      It has truly simplified the event management process and
                      saved me valuable time and effort. Thank you, Where the
                      Truck!
                    </CardContent>
                    <h4>-Lina, Evant Coordinator</h4>
                    <CardActions
                      orientation="vertical"
                      buttonFlex={1}
                      sx={{
                        "--Button-radius": "40px",
                        width: "clamp(min(100%, 160px), 50%, min(100%, 200px))",
                      }}
                    ></CardActions>
                  </Card>
                </div>
                <Container
                  className="container-element"
                  style={{
                    borderWidth: 1,
                    // borderColor: "grey",
                    // borderStyle: "solid",
                    borderRadius: 5,
                    padding: 15,
                  }}
                ></Container>
                <section id="services">
                  <div class="services containerl">
                    <div style={{ fontSize: "3em" }}>
                      <h5 style={{ color: "#2959a5", fontSize: "30px" }}>
                        Why Choose Us?
                      </h5>
                    </div>

                    <div class="service-bottom">
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          50+ years of experience in Retail & Corporate
                          Catering.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Supported Australia Open, Melbourne Cup & more.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Expertise in event organizers' unique needs and
                          requirements.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Streamlined processes from storage to vendor
                          management.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Secure, user-friendly compliance document storage.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Centralized vendor management for efficient selection.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Advanced WTT POS system for real-time insights.
                        </p>
                      </div>
                      <div class="service-item">
                        <div class="icon">
                          <img
                            className="imgl"
                            src={require("../../../assets/img/logo-round.png")}
                          />
                        </div>
                        <p className="pl">
                          Concise website info for quick service understanding.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <Container
                  className="container-element"
                  style={{
                    borderWidth: 1,
                    // borderColor: "grey",
                    // borderStyle: "solid",
                    borderRadius: 5,
                    padding: 15,
                  }}
                >
                  <div class="text-container">
                    <h5 style={{ color: "#2959a5", fontSize: "30px" }}>
                      Our Proud Partners
                    </h5>{" "}
                    <p style={{ color: "#2959a5", fontSize: "25px" }}>
                      ... and 1000+ vendors all over Australia
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 -10px",
                      }}
                    >
                      <Box
                        component="ul"
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexWrap: "wrap",
                          p: 0,
                          m: 0,
                        }}
                      >
                        <a
                          href="http://mobileicecreams.com/?gclid=CjwKCAjwsvujBhAXEiwA_UXnAOkG9hzxUUihlTK44cD7v3S7shisTmOiqvFyyc3L6PynwvGVJ6kUchoC4f4QAvD_BwE"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={mobile_ice} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Mobile Ice Cream
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="https://www.instagram.com/inkrediblecalamari1/?hl=en"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src={inkredible_calamari}
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Inkredible Calamari
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="http://www.gourmetfoodtrucks.com.au/about-micasa-food-truck.html"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={mi_casa} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Mi casa
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="https://www.benzonmiller.com.au/"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={Benz_On_miller} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Benz On miller
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="https://www.ilovedumplings.net.au/"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src={i_love_dumplings}
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                I Love Dumplings
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="https://www.facebook.com/kingofthewingsbrisbane/"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src={Kind_of_the_wings}
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Kind of the wings
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a href="https://www.mistergee.com.au/" target="_blank">
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src={Mister_Gee_burgers}
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Mister Gee burgers
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a href="https://whollyschnit.com.au/" target="_blank">
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={Wholly_schnit} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Wholly schnit
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a href="https://www.duoduo.com.au/" target="_blank">
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={DUO_ice_cream} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                DUO ice cream
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                      </Box>
                    </div>
                  </div>
                </Container>
                <Container
                  className="container-element"
                  style={{
                    borderWidth: 1,
                    // borderColor: "grey",
                    // borderStyle: "solid",
                    borderRadius: 5,
                    padding: 15,
                  }}
                >
                  <div class="text-container">
                    <h5 style={{ color: "#2959a5", fontSize: "30px" }}>
                      Our Clients
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 -10px",
                      }}
                    >
                      <Box
                        component="ul"
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexWrap: "wrap",
                          p: 0,
                          m: 0,
                        }}
                      >
                        <a href="https://ausopen.com/" target="_blank">
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Australian_Open_Logo_2017.svg/1200px-Australian_Open_Logo_2017.svg.png"
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Australia Open
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <a
                          href="https://melbflowershow.com.au/"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img src={Flower_Show} loading="lazy" alt="" />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Melbourne Flower Show
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                        <Card sx={{ minHeight: "280px", width: 320 }}>
                          <CardCover>
                            <img src={Racing_Club} loading="lazy" alt="" />
                          </CardCover>
                          <CardCover
                            sx={{
                              background:
                                "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                            }}
                          />
                          <CardContent sx={{ justifyContent: "flex-end" }}>
                            <Typography
                              level="h2"
                              fontSize="lg"
                              textColor="#fff"
                              mb={1}
                            >
                              Grand Prix
                            </Typography>
                          </CardContent>
                        </Card>
                        <a
                          href="https://www.vrc.com.au/melbourne-cup-carnival/melbourne-cup-carnival-2023/"
                          target="_blank"
                        >
                          <Card sx={{ minHeight: "280px", width: 320 }}>
                            <CardCover>
                              <img
                                src="https://flemingtontraders.com.au/wp-content/uploads/2023/02/the-2019-melbourne-cup-won-by-vow-and-declare-1585714096_1352x900.jpg"
                                loading="lazy"
                                alt=""
                              />
                            </CardCover>
                            <CardCover
                              sx={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                              }}
                            />
                            <CardContent sx={{ justifyContent: "flex-end" }}>
                              <Typography
                                level="h2"
                                fontSize="lg"
                                textColor="#fff"
                                mb={1}
                              >
                                Melbourne Cup
                              </Typography>
                            </CardContent>
                          </Card>
                        </a>
                      </Box>
                    </div>
                  </div>
                </Container>
              </div>
            </section>

            <footer className="footer">
              <div className="container">
                <div className="row text-center">
                  <div className="col-sm-12 divide-xs">
                    <p>
                      <small>
                        Copyright © 2024 Where The Truck All rights reserved.
                      </small>
                    </p>
                    <p>
                      <small>
                        By using the Where The Truck website, you agree to our{" "}
                        <a
                          href="http://amfvg.com.au/terms-and-conditions-of-use/"
                          target="_blank"
                          className="anchor"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="http://amfvg.com.au/privacy-policy/"
                          target="_blank"
                          className="anchor"
                        >
                          Privacy Policy
                        </a>
                      </small>
                    </p>
                  </div>
                  <div className="clearfix"></div>
                  <div className="divide-sm"></div>
                </div>
              </div>
            </footer>
          </>
        ) : (
          <Container
            className="container-element"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              flexDirection: "column",
            }}
          >
            <section class="home" id="home">
              <div class="content">
                <div class="flex-container">
                  <div>
                    <div class="typing-indicator">
                      <div class="typing-circle"></div>
                      <div class="typing-circle"></div>
                      <div class="typing-circle"></div>
                      <div class="typing-shadow"></div>
                      <div class="typing-shadow"></div>
                      <div class="typing-shadow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(LandingScreen);
