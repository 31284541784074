import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
// import { hasEventExpired } from "../../helper";
import { AWS_URL } from "../../assets/Constants";
// import TextField from "@material-ui/core/TextField";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// import PlacesAutocomplete from "react-places-autocomplete";
// import { BUSY_TIMES } from "./utils";
// import ImageUploader from "react-images-upload";
import {
  // Dropdown,
  // DropdownButton,
  FormControl,
  InputGroup,
  Form,
  Container,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
// import Spinner from "react-bootstrap/Spinner";
// import { isEmpty } from "./utils.js";
// import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import TableData from "../../components/UI/ItemsSoldTable";
// import { Bar, Pie } from "react-chartjs-2";
// import DatePicker from "react-datepicker";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from "@sentry/react";

class AdminEditEventScreen extends Component {
  state = {
    searchEvent: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") !== "undefined" &&
      getFromLocalStorage("email") !== "" &&
      getFromLocalStorage("email") !== null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") !== "undefined" &&
      getFromLocalStorage("password") !== "" &&
      getFromLocalStorage("password") !== null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") !== "" &&
      getFromLocalStorage("isOperatorLoggedIn") !== null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") !== "undefined" &&
      getFromLocalStorage("vendorName") !== "" &&
      getFromLocalStorage("vendorName") !== null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    tempEvents: [],
    vendorEvents: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") !== "undefined" &&
      getFromLocalStorage("selectedEventId") !== "" &&
      getFromLocalStorage("selectedEventId") !== null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") !== "undefined" &&
      getFromLocalStorage("selectedVendorId") !== "" &&
      getFromLocalStorage("selectedVendorId") !== null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    showSuccess: false,
    eventName: "",
    vendorList: [],
    tempVendorList: [],
    selectedVendorName: [],
    selectedVendorList: [],
    eventType: "public",
    eventStatus: "active",
    eventStartDateTime: "",
    eventEndDateTime: "",
    busyStartTime: "02:00 AM",
    busyEndTime: "02:00 AM",
    eventLocation: "",
    eventDescription: "",
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    startDate:
      getFromLocalStorage("startDate") !== "undefined" &&
      getFromLocalStorage("startDate") !== "" &&
      getFromLocalStorage("startDate") !== null &&
      getFromLocalStorage("startDate").toString() !== "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") !== "undefined" &&
      getFromLocalStorage("endDate") !== "" &&
      getFromLocalStorage("endDate") !== null &&
      getFromLocalStorage("endDate").toString() !== "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    this.getEvents();
    this.getAllVendors();
    const { isSuperAdminLoggedIn, isEventCoordinatorLoggedIn } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  handleChange = (eventLocation) => {
    // const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  handleSelect = (eventLocation) => {
    // const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getEvents = () => {
    this.setState({ isLoading: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "all_events",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.events, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          const sortedEvents = result.events.sort((b, a) =>
            moment(
              moment(a.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
            ).diff(
              moment(moment(b.start_date, "DD MMM YYYY").format("YYYY-MM-DD")),
              "days"
            )
          );
          this.setState({ events: sortedEvents });
          this.setState({ tempEvents: sortedEvents });
          console.log(sortedEvents);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          this.setState({
            vendorList: response.vendors,
            tempVendorList: response.vendors,
          });
        }
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          const vendor_ids = dataResponse.event.vendor.map(
            (vendors) => vendors.user_ptr_id
          );
          this.setState({
            selectedVendorList: vendor_ids,
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };
  getVendorsInEvent = () => {
    const { isOperatorLoggedIn } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn === true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          Sentry.captureException(error);
          console.error(error);
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  convertDateTime = (dateString, timeString) => {
    const date = new Date(dateString);
    const time = new Date(`01/01/2000 ${timeString}`);
    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    );
    const isoString = dateTime.toISOString().slice(0, -8);
    return isoString;
  };

  saveEvent = () => {
    const {
      selectedEvent,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
    } = this.state;

    if (selectedVendorList.length <= 0 || selectedEvent === "") {
      alert("Please select the vendors");
      return;
    }

    const start_date_time = this.convertDateTime(
      selectedEvent.start_date,
      selectedEvent.start_time
    );
    const end_date_time = this.convertDateTime(
      selectedEvent.end_date,
      selectedEvent.end_time
    );

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var ids = this.state.selectedEvent.vendor.map(
      (vendors) => vendors.user_ptr_id
    );
    var all_ids = ids.concat(selectedVendorList);
    var stringVendor = all_ids.map((i) => i.toString());
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "save_event",
          busy_end_time: "02:00 AM",
          busy_start_time: "02:00 AM",
          event_name: selectedEvent.name,
          event_type: selectedEvent.event_type,
          event_status: selectedEvent.event_status,
          start_date_time: start_date_time,
          end_date_time: end_date_time,
          event_location: selectedEvent.event_location,
          description: selectedEvent.description,
          cover_image: selectedEvent.cover,
          vendors: stringVendor,
          selected_event_from_list_id: selectedEvent.id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              showSuccess: true,
              eventId: response.eventId,
              errorArray: response.menu_error,
            });
            alert("Event updated successfully");
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn ||
        !isEventCoordinatorLoggedIn ||
        !isAccountantLoggedIn ||
        !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </>
    );
  }

  renderHome() {
    const {
      // eventDescription,
      // eventName,
      // eventLocation,
      searchVendor,
      eventStatus,
      eventType,
      selectedVendorName,
      // eventStartDateTime,
      // eventEndDateTime,
      // busyStartTime,
      // busyEndTime,
      vendorList,
      selectedVendorList,
      searchEvent,
      isLoading,
      tempEvents,
      // selectedEventId,
      // orders,
      // tempOrders,
      selectedEvent,
      // vendors,
      // selectedVendorId,
      // availableStatus,
      // isLoadingChangeOrderStatus,
      // selectedStartOrderId,
      // selectedEndOrderId,
      // selectedDate,
      // startDate,
      // endDate,
    } = this.state;
    let vendor_ids = [];
    if (selectedEvent && selectedEvent.vendor) {
      vendor_ids = selectedEvent.vendor.map((vendors) => vendors.user_ptr_id);
    }

    // let date_range = this.getDateRange(
    //   start_date,
    //   end_date
    // );
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
                minHeight: "71vh",
                position: "relative",
              }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Text value={searchEvent}>
                  <img
                    alt="search"
                    style={{ width: 20 }}
                    src={require("../../assets/img/search.gif")}
                  ></img>
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Event"
                  aria-label="Search for events"
                  value={
                    this.state.selectedEvent
                      ? this.state.selectedEvent.name
                      : ""
                  }
                  onFocus={() => {
                    if (this.state.tempEvents.length > 0) {
                      this.setState({ showDropdown: true });
                    }
                  }}
                  onBlur={() => {
                    setTimeout(
                      () => this.setState({ showDropdown: false }),
                      200
                    );
                  }}
                  onChange={(e) => {
                    var searchString = e.target.value;
                    if (searchString === "") {
                      this.setState({ tempEvents: this.state.events });
                    } else {
                      var result = tempEvents.find((obj) => {
                        if (
                          obj.name.search(new RegExp(searchString, "i")) !== -1
                        ) {
                          return obj;
                        }
                      });
                      this.setState({ tempEvents: result });
                    }
                  }}
                />
                {this.state.showDropdown && this.state.tempEvents.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      zIndex: 1,
                      border: "2px solid black",
                    }}
                  >
                    <ul
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {this.state.tempEvents.map((event) => (
                        <li
                          key={event.id}
                          onClick={() =>
                            this.setState({
                              selectedEvent: event,
                              showDropdown: false,
                            })
                          }
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                        >
                          {event.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </InputGroup>
              <Row>
                <Col xs={12} md={12} xs={12}>
                  <Form style={{ marginTop: "200px" }}>
                    <img
                      alt={selectedEvent.title}
                      height={200}
                      width={"100%"}
                      className="mr-3"
                      src={
                        selectedEvent.cover &&
                        selectedEvent.cover !== null &&
                        selectedEvent.cover !== ""
                          ? AWS_URL + "/" + selectedEvent.cover
                          : require("../../assets/img/empty_logo.png")
                      }
                      style={{ overflow: "hidden" }}
                    />
                    <Form.Group className="mb-3" controlId="formEventName">
                      <Form.Label>
                        Event Name: {selectedEvent ? selectedEvent.name : ""}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEventType">
                      <Form.Label>
                        Event Type: {eventType ? selectedEvent.event_type : ""}
                      </Form.Label>
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm"></Form.Group>
                      <Form.Group className="mb-3" controlId="formEventStatus">
                        <Form.Label>
                          Event Status:{" "}
                          {eventStatus ? selectedEvent.event_status : ""}
                        </Form.Label>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm"></Form.Group>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formStartTime">
                        <Form.Label>
                          Start Date and Time: {selectedEvent.start_date},{" "}
                          {selectedEvent.start_time}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formStartTime">
                        <Form.Label>
                          End Date and Time: {selectedEvent.end_date},{" "}
                          {selectedEvent.end_time}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formEventName">
                        <Form.Label>
                          Location: {selectedEvent.event_location}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="my_multiselect_field"
                        style={{ paddingLeft: 0 }}
                      >
                        {selectedEvent.vendor &&
                          selectedEvent.vendor.length > 0 && (
                            <Form.Label>
                              Vendors:{" "}
                              {selectedEvent.vendor
                                .map((vendor) => vendor.title)
                                .join(", ")}
                            </Form.Label>
                          )}
                        <InputGroup className="mb-3">
                          <InputGroup.Text value={searchVendor}>
                            <img
                              alt="search"
                              style={{ width: 20 }}
                              src={require("../../assets/img/search.gif")}
                            ></img>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Search for vendors"
                            aria-label="Search for vendors"
                            onChange={(e) => {
                              var searchString = e.target.value;
                              if (searchString == "") {
                                this.setState({
                                  vendorList: this.state.tempVendorList,
                                });
                              } else {
                                var result = vendorList.filter((obj) => {
                                  if (
                                    obj.title.search(
                                      new RegExp(searchString, "i")
                                    ) != -1
                                  ) {
                                    return obj;
                                  }
                                });
                                this.setState({ vendorList: result });
                              }
                            }}
                          />
                        </InputGroup>
                        <Form.Check
                          type="checkbox"
                          id="clear"
                          label="Clear"
                          checked={
                            this.state.selectedVendorList &&
                            this.state.tempVendorList &&
                            this.state.selectedVendorList.length === 0
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({ selectedVendorList: vendor_ids });
                            }
                          }}
                        />
                        <div style={{ height: "200px", overflowY: "auto" }}>
                          {this.state.vendorList.map((vendor) => (
                            <Form.Check
                              key={vendor.user_ptr_id}
                              type="checkbox"
                              id={`vendor-${vendor.user_ptr_id}`}
                              label={vendor.title}
                              checked={
                                this.state.selectedVendorList &&
                                this.state.vendorList &&
                                this.state.selectedVendorList.includes(
                                  vendor.user_ptr_id
                                )
                              }
                              onChange={(e) => {
                                const vendorId = vendor.user_ptr_id;
                                const vendorName = vendor.title;
                                const selectedVendorList = this.state.selectedVendorList.slice();
                                if (e.target.checked) {
                                  selectedVendorList.push(vendorId);
                                  selectedVendorName.push(vendorName);
                                } else {
                                  const index = selectedVendorList.indexOf(
                                    vendorId
                                  );
                                  if (index >= 0) {
                                    selectedVendorList.splice(index, 1);
                                  }
                                }
                                this.setState({ selectedVendorList });
                              }}
                            />
                          ))}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          Description: {selectedEvent.description}
                        </Form.Label>
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {
                          this.saveEvent();
                        }}
                      >
                        Submit
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              alt="loading"
              src={require("../../assets/img/loading.gif")}
            ></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminEditEventScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
