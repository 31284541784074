import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../store";
import "./SearchBar.css";

const SearchBarVendor = ({
  vendorList,
  placeHolder,
  setFilteredVendors,
  selectedVendorId,
  selectedVendorSearch,
}) => {
  const [inputValue, setInputValue] = useState(selectedVendorSearch);
  const handleChange = (value) => {
    setInputValue(value);
    searchData(value);
  };
  useEffect(() => {
    setInputValue(selectedVendorSearch);
  }, [selectedVendorSearch]);

  const searchData = (value) => {
    const result = vendorList.filter((user) => {
      return (
        value &&
        user &&
        user.title &&
        user.title.toLowerCase().includes(value.toLowerCase())
      );
    });
    if (result.length === 0) {
      setFilteredVendors(vendorList);
    } else {
      setFilteredVendors(result);
    }
  };

  return (
    <div className="input-wrapper">
      <FaSearch id="search-icon" />
      <input
        placeholder={placeHolder}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBarVendor;
