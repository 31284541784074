import React, { useState } from "react";

const ServiceItem = ({ iconSrc, text }) => {
  const maxLength = 100; // Set maximum length of text before showing "Read More"
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Define the styles for the "Read More" button
  const readMoreButtonStyles = {
    backgroundColor: "#2959a5", // Background color of the button
    color: "#ef3e6d", // Text color
    border: "none", // Remove default border
    cursor: "pointer", // Change cursor to pointer on hover
    textDecoration: "underline", // Optionally add underline to indicate it's clickable
  };

  // Split the text at the first colon to separate the title
  const [title, ...rest] = text.split(":");
  const content = rest.join(":"); // Rejoin the remaining parts in case there are more colons

  return (
    <div className="service-item">
      <div className="icon">
        <img className="imgl" src={iconSrc} />
      </div>
      <p className="pl">
        <strong>{title}:</strong>
        {isExpanded || content.length + title.length + 1 <= maxLength
          ? content
          : ` ${content.substring(0, maxLength - title.length - 1)}... `}
        {content.length + title.length + 1 > maxLength && (
          <button onClick={toggleExpanded} style={readMoreButtonStyles}>
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </p>
    </div>
  );
};

export default ServiceItem;
