import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { AWS_URL } from "../../assets/Constants";
import * as Sentry from "@sentry/react";

// // Import the font files
// import robotoRegular from "../../assets/fonts/Open Sans-Regular.ttf";
// import robotoBold from "../../assets/fonts/Open Sans-Bold.ttf";
// Register the fonts
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     {
//       src:
//         "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
//     },
//     {
//       src:
//         "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
//       fontWeight: 600,
//     },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    paddingHorizontal: 30,
    flexGrow: 1,
  },
  heading2: {
    paddingTop: 10,
    fontSize: 18,
    color: "#3959a5",

    fontWeight: 600,
  },
});

const RunsheetPdfCreator = ({ item }) => {
  console.log({ item }, "PROPS");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/* <Image
            src={AWS_URL + item.vendor__logo}
            style={{ width: 100, height: 100, marginLeft: 20 }}
          /> */}
          <Text
            style={{
              fontSize: 18,
              fontWeight: "strong",
              textAlign: "center",
              color: "#3959a5",
              marginBottom: 30,
            }}
          >
            Run Sheet {item.event_name}
          </Text>
          <View
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginTop: 20,
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                color: "#fff",
                backgroundColor: "#3959a5",
                fontSize: 17,
              }}
            >
              Event Additional Information:{" "}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Job Location:</Text>{" "}
              {item.location}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Site Contact:</Text>{" "}
              {item.site_contact}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Site Contact Number:</Text>{" "}
              {item.site_contact_number}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Truck Name:</Text>{" "}
              {item.vendor__title}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Event Start Date:</Text>{" "}
              {item.event_date}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Event Start Time:</Text>{" "}
              {item.start_time}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Event End Time:</Text>{" "}
              {item.end_time}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text
                style={{
                  fontWeight: 600,
                  paddingRight: 50,
                }}
              >
                Power Arrangements:
              </Text>{" "}
              {item.power}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Extended Duration in hrs:</Text>{" "}
              {item.extend_cut_off_by}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Bump In Date/Time:</Text>{" "}
              {item.bump_in_date} {" " + item.bump_in_start_time}{" "}
              {" -  " + item.bump_in_end_time}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Bump Out Date/Time:</Text>{" "}
              {item.bump_out_date} {" " + item.bump_out_start_time}{" "}
              {" -  " + item.bump_out_end_time}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Beverage Notes:</Text>{" "}
              {item.beverage_notes}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Additional Notes:</Text>{" "}
              {item.other_notes}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                textDecoration: "underline",
                color: "#fff",
                backgroundColor: "#3959a5",
                fontSize: 17,
              }}
            >
              Event Action Items:{" "}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              <Text style={{ fontWeight: 600 }}>Social Media:</Text>
            </Text>
          </View>
          <View
            style={{
              diapaly: "flex",
              gap: 10,
            }}
          >
            <Text style={{ fontSize: 15 }}>
              1. Don't forget to let your followers know you will be attending!
            </Text>
            <Text style={{ fontSize: 15 }}>
              2. Tag @wherethetruckat and #wherethetruckat
            </Text>
            <Text style={{ fontSize: 15 }}>
              SOT: Please make sure to upload your statement of trade on you
              Vendor Portal.
            </Text>
          </View>
          <View
            style={{
              marginTop: 30,
              diapaly: "flex",
              gap: 10,
            }}
          >
            <Text
              style={{
                fontSize: 13,
                fontStyle: "italic",
                color: "#3959a5",
                textAlign: "center",
              }}
            >
              We look forward to having you join us for {item.event_name}!
            </Text>
            <Text
              style={{
                fontSize: 13,
                fontStyle: "italic",
                color: "#3959a5",
                textAlign: "center",
              }}
            >
              Should you have any questions on the event, please contact the
              team member who booked your outlet: Monday - Friday - business
              hours.
            </Text>
            <Text
              style={{
                fontSize: 13,
                fontStyle: "italic",
                color: "#3959a5",
                textAlign: "center",
              }}
            >
              WTT and AMFVG wish you every success in your trade!
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default RunsheetPdfCreator;
