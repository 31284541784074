import React, { Component } from "react";
import { connect } from "react-redux";
import { createNewEvent } from "../../actions/EventOrganiser/action";
import EventForm from "./EventForm";
import {
  Typography,
  Container,
  TextField,
  Paper,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Select,
} from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import * as Yup from "yup";
import { deleteFromLocalStorage, getFromLocalStorage } from "../../store";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import EOIPage from "../../components/UI/EOI/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
const eventSchema = Yup.object().shape({
  event_type: Yup.string().required("The Event type is required"),
  description: Yup.string().required("Please add the description"),
});

class CreateEvents extends Component {
  state = {
    isSuperAdminLoggedIn: "false",
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    canCreateEOI:
      getFromLocalStorage("canCreateEOI") != "undefined" &&
      getFromLocalStorage("canCreateEOI") != "" &&
      getFromLocalStorage("canCreateEOI") != null
        ? getFromLocalStorage("canCreateEOI")
        : "false",
    eventLocation: "",
    eventCompanyName: "",
    eventCompanyABN: "",
    eventCompanyNumber: "",
    eventCompanyEmail: "",
    eventCompanyAddress: "",
    createEventName: "",
    createEventStartDateTime: "",
    createEventEndDateTime: "",
    currentSteps: 0,
    query_type: "save_event",
    name: "",
    event_type: "",
    event_status: "",
    start_date_time: "",
    end_date_time: "",
    busy_start_time: "",
    busy_end_time: "",
    event_location: "",
    description: "",
    cover_image: "",
    vendors: [],
    stepContent: ["Company Information", "Event Information"],
    loadingStatus: false,
    validated: false,
  };

  handleNextStep = () => {
    switch (this.state.currentSteps) {
      case 0:
        let preStep = this.state.currentSteps;

        this.setState({ currentSteps: preStep + 1 });

        break;
      case 1:
        let step = this.state.currentSteps;
        this.setState({ currentSteps: step + 1 });

        break;

      default:
        return;
    }
  };
  handlePreviousStep = () => {
    const preStep = this.state.currentSteps;
    this.setState({ currentSteps: preStep - 1 });
  };

  handleReset = () => {
    this.setState({ currentSteps: 0 });
  };

  handleClose = () => {};
  componentDidMount() {
    const { canCreateEOI } = this.state;
    if (canCreateEOI === "false") {
      window.location.href = "/organiser";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  render() {
    const {
      currentSteps,
      stepContent,
      eventCompanyEmail,
      eventCompanyName,
      eventCompanyNumber,
      isSuperAdminLoggedIn,
      isOrganiserLoggedIn,
      vendorName,
      validated,
    } = this.state;

    return (
      <div>
        {isOrganiserLoggedIn ? (
          <div>
            <AdminNavBar
              style={{ margin: "auto" }}
              isSuperAdminLoggedIn={isSuperAdminLoggedIn}
              isOrganiserLoggedIn={isOrganiserLoggedIn}
              logout={this.logout}
              vendorName={vendorName}
            ></AdminNavBar>
            <EventForm />
            <Footer />
          </div>
        ) : (
          <div> Loading..... </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDetail: state.organiser.newEventDetail,
    EOIDetail: state.organiser.newEOIDetail,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(CreateEvents, {
    fallback: <ErrorFallbackComponent />,
  })
);
