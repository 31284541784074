import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import * as Sentry from "@sentry/react";
import { apiPaths } from "../../services/apiPath";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import api from "../../services/api";
import config from "../../services/apiConfig";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/UI/Button/index";
import {
  sendSms,
  loginPhone,
  getUserProfile,
  updateCartTicket,
  updateCartTotal,
  updateOrderType,
  updateOrderLater,
  updateOrderLocation,
  changeTab,
  calculateDelivery,
  getDelivery,
  updateDeliveryCharges,
  orderStatus,
  getPromoCode,
  applyPromoCode,
  updateOrderDate,
  updateOrderTime,
  validatePromoCode,
  updateSelectedVendor,
  clearSelectedVendor,
  logout,
  updateUser,
} from "../../actions";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { applyToken } from "../../services/api";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@material-ui/core/TextField";
import { Bar, Pie } from "react-chartjs-2";
import ResponsiveVendorNavBar from "../../components/UI/ResponsiveVendorNavBar";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class VendorResponseScreen extends Component {
  state = {
    suburb: "Reservoir",
    number_of_guests: 50,
    event_start_date: "20 Aug 2021",
    event_end_date: "20 Aug 2021",
    event_start_time: "05:30 PM",
    event_end_time: "08:00 PM",
    event_type: "",
    continueClicked: false,
    cateringPacks: {},
    pack_name: "",
    pack_description: "",
    package_price: 0,
    reject_reason: "",
  };
  componentDidMount() {
    var booking_request_id = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    this.getBookingRequestDetails(booking_request_id);
  }

  getBookingRequestDetails = (booking_request_id) => {
    this.setState({ loader: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-event-details",
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState(
            {
              location: response.booking_details.location,
              event_start_date: response.booking_details.start_date,
              event_end_date: response.booking_details.end_date,
              event_start_time: response.booking_details.start_time,
              event_end_time: response.booking_details.end_time,
              number_of_guests: response.booking_details.number_of_guests,
              event_type: response.booking_details.event_type,
              cateringPacks: response.booking_details.catering_packs,
              package_name:
                response.booking_details.catering_packs.package_name,
              package_description:
                response.booking_details.catering_packs.package_description,
              package_price:
                response.booking_details.catering_packs.package_price,
              payment_total_amount:
                response.booking_details.payment_total_amount,
            },
            () => {
              if (this.state.location.includes(", Australia")) {
                var temp = this.state.location.split(", Australia")[0];
                if (temp.includes(",")) {
                  temp = temp.split(",")[1];
                } else {
                  temp = temp.split(" ");

                  temp = temp[temp.length - 2];
                }
                this.setState({ suburb: temp });
              } else {
                this.setState({ suburb: this.state.location });
              }
            }
          );
        } else {
          alert("This request has been closed now! ");
          window.location.href = "/";
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ loader: false });
      });
  };

  updateBookingRequest = (status) => {
    var booking_request_id = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    const {
      package_name,
      package_description,
      package_price,
      number_of_guests,
      payment_total_amount,
      reject_reason,
    } = this.state;
    this.setState({ loader: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update-booking-request",
          booking_request_id: booking_request_id,
          package_name: package_name,
          package_description: package_description,
          package_price: package_price,
          reject_reason: reject_reason,
          status: status,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          alert("Submitted Successfully!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ loader: false });
      });
  };
  render() {
    const {
      suburb,
      event_start_date,
      event_end_date,
      event_start_time,
      event_end_time,
      number_of_guests,
      event_type,
      continueClicked,
    } = this.state;
    return (
      <>
        <ResponsiveVendorNavBar
          showBack={continueClicked}
          backUrl={window.location.href}
        ></ResponsiveVendorNavBar>
        <Container>
          {continueClicked == false ? (
            <>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>
                    We have a {event_type} request to book your truck. Accept
                    this job or provide a revised quote back to the customer
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Location</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>{suburb}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Date</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>
                    {event_start_date} - {event_end_date}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Time</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>
                    {event_start_time} - {event_end_time}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <p>Estimated Number of Guests</p>
                </Col>
                <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                  <p>{number_of_guests}</p>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  this.setState({ continueClicked: true });
                }}
              >
                Continue
              </Button>
            </>
          ) : (
            this.renderMenuDetails()
          )}
        </Container>
      </>
    );
  }

  hasValueUpdated = () => {
    const {
      package_name,
      package_description,
      package_price,
      number_of_guests,
      payment_total_amount,
      reject_reason,
      cateringPacks,
    } = this.state;
    if (
      cateringPacks.package_name != package_name ||
      cateringPacks.package_description != package_description ||
      cateringPacks.package_price != package_price
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderMenuDetails() {
    const {
      package_name,
      package_description,
      package_price,
      number_of_guests,
      payment_total_amount,
      reject_reason,
    } = this.state;
    return (
      <>
        <Row>
          <Col xs={12}>
            <p>
              You can either accept this job or provide a revised quote back to
              the customer{" "}
            </p>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Label>Pack Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg:Small Pack"
                value={package_name}
                onChange={(e) => {
                  this.setState({ package_name: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Label>Enter your catering pack description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="eg:1x Burger,1x small chips 1x drink"
                value={package_description}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Label>Per person price ($) </Form.Label>
              <Form.Control
                type="number"
                placeholder="eg: 20"
                value={package_price}
                onChange={(e) => {
                  this.setState({ package_price: e.target.value });
                }}
              />
            </Form.Group>
            <Row>
              <Col xs={6} md={6} lg={6}>
                <p>Total Number of Guests</p>
              </Col>
              <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                <p>{number_of_guests}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6} lg={6}>
                <p>Total Paid By Customer</p>
              </Col>
              <Col style={{ textAlign: "left" }} xs={6} md={6} lg={6}>
                <p>${Number(payment_total_amount).toFixed(2)}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="button"
          onClick={() => {
            if (this.hasValueUpdated()) {
              this.updateBookingRequest("update_and_approve");
            } else {
              this.updateBookingRequest("approve");
            }
          }}
        >
          {this.hasValueUpdated() ? "Update and Approve" : "Approve"}
        </Button>
        <h4>OR</h4>
        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
          <Form.Label>Reject Reason</Form.Label>
          <Form.Control
            type="text"
            value={reject_reason}
            onChange={(e) => {
              this.setState({ reject_reason: e.target.value });
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            this.updateBookingRequest("reject");
          }}
        >
          Reject
        </Button>
        <p>
          <i>We will send you a confirmation with all the details.</i>
        </p>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default Sentry.withErrorBoundary(VendorResponseScreen, {
  fallback: <ErrorFallbackComponent />,
});
