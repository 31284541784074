export const firebaseConfig = {
  apiKey: "AIzaSyDwgDtMj_rgnX888YvCHEr4WfQZd12f8wU",
  authDomain: "where-the-truck-app.firebaseapp.com",
  databaseURL: "https://where-the-truck-app.firebaseio.com",
  projectId: "where-the-truck-app",
  storageBucket: "where-the-truck-app.appspot.com",
  messagingSenderId: "331022996386",
  appId: "1:331022996386:web:ad92bc8589546d7ae218fb",
  measurementId: "G-8F6N4EKP4L",
};
