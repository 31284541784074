import React from "react";
import "./style.css";
import "./Body";
import "./Component/style1.css";
import Footer from "../../components/UI/Footer";
import { useRef, useState, useEffect, useCallback, memo } from "react";
import StallGallery from "./Component/StallGallery";
import OperatorMenuScreen from "../../screens/OperatorMenuScreen";
import ChatBotApp from "../../screens/ChatBotApp";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import RegisterStall from "../RegisterStall";
import EventSection from "./Component/EventSection";
import VendorNavBar from "./Component/VendorNavBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TouchBackend } from "react-dnd-touch-backend";
import contact from "./contact.png";

import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import update from "immutability-helper";
import useFecth from "../../hook/useFecth";
import { ItemTypes } from "./constant/Constant";
const Body = memo(() => {
  const [eventsList, setEventsList] = useState([]);
  const { vendors, events } = useFecth("Operator");
  const [operatorId, setOperatorId] = useState("");
  const [activeComponent, setActiveComponent] = useState("home");
  const [showForm, setShowForm] = useState(false);
  const [showBot, setShowBot] = useState(false);
  const chatBotRef = useRef(null);
  const handleFormClose = () => {
    setShowForm(false);
  };
  const handleBotClose = () => {
    setShowBot(false);
  };
  const handleLogoClick = () => {
    // Function to handle logo click event
    setShowBot(true); // Update state to show ChatBotApp
  };

  return (
    <>
      <VendorNavBar />
      <div className="body">
        <Container maxWidth="lg">
          <Row>
            <Col>
              <div>
                {/* Render your logo here with an onClick handler */}

                <div className="image-container">
                  <img src={contact} alt="Logo" onClick={handleLogoClick} />
                </div>
                {/* Render the ChatBotApp component with a ref */}
                <div className="image-container">
                  {showBot && <ChatBotApp ref={chatBotRef} />}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
});

export default Body;
