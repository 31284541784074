import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  Alert,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Sentry from "@sentry/react";
const SupplierScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.data[0].is_super_admin) {
          saveToLocalStorage(
            "isSupplierLoggedIn",
            "" + dataResponse.data[0].is_super_admin
          );
          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          saveToLocalStorage("Organiser", JSON.stringify(dataResponse.data));
          saveToLocalStorage("selectedEventId", dataResponse.data[0].event);
          setLoggedIn(true);
          window.location.replace("supplier-overview");
        } else {
          setInvalid(true);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };

  return (
    <>
      <div className="organiser">
        <Alert variant="danger" show={invalid}>
          Invalid email/password. Please try again.
        </Alert>

        <div className="login-organiser">
          {getFromLocalStorage("isOrganiserLoggedIn") == loggedIn && (
            <Redirect to="/organiser-overview" />
          )}

          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-6" size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Label>Password</Form.Label>
            <OutlinedInput
              placeholder="Password"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              style={{ width: "100%", height: "50%" }}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <ReCAPTCHA
              style={{ marginTop: 20 }}
              sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
            />
            <Button
              block
              size="lg"
              type="submit"
              disabled={!validateForm()}
              style={{ marginTop: 20 }}
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SupplierScreen;
