import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import * as Sentry from "@sentry/react";

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { getFromLocalStorage } from "../../store";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";

const ServiceProvidersModal = ({
  service_providers,
  setProviders,
  event_id,
}) => {
  console.log(service_providers);
  const [showModal, setShowModal] = useState(false);

  const [edit, setEdit] = useState(-1);
  const [editName, setEditName] = useState("");
  const [editSummary, setEditSummary] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  //   useEffect(() => {
  //     setEditorState(() => {
  //       console.log("EFFECT");
  //       const contentBlock = convertFromHTML(service_providers);
  //       const contentState = ContentState.createFromBlockArray(
  //         contentBlock.contentBlocks
  //       );
  //       return EditorState.createWithContent(contentState);
  //     });
  //   }, [service_providers]);

  //   const onEditorStateChange = (newState) => {
  //     setEditorState(newState);
  //   };

  const handleSubmit = () => {
    //     const contentState = editorState.getCurrentContent();
    //     const rawState = convertToRaw(contentState);
    //     const html = draftToHtml(rawState);
    //     console.log(html);
    const organiserData =
      JSON.parse(getFromLocalStorage("Organiser")) &&
      JSON.parse(getFromLocalStorage("Organiser")).length
        ? JSON.parse(getFromLocalStorage("Organiser"))[0]
        : {};
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "post_service_providers",
          service_provider_details: service_providers,
          time_stamp: new Date(),
          event_id: event_id,
          selected_admin_user: organiserData.id,
          date_written: format(new Date(), "dd MMM yyyy"),
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    handleClear();
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "post_pr_for_event");
        if (dataResponse.success) {
          alert("Service Providers added Successfully!");
          setShowModal(false);
        } else alert("Something went wrong. Please try again");
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong. Please try again");
      });
  };

  const handleDelete = (index) => {
    const tempProviders = service_providers.filter((service, ind) => {
      return ind !== index;
    });
    console.log(tempProviders);
    setProviders(tempProviders);
  };

  const handleClear = () => {
    setEdit(-1);
    setEditName("");
    setEditSummary("");
  };
  const handleSave = async (index, id) => {
    console.log("EDIT", { edit, editName, editSummary });

    const organiserData =
      JSON.parse(getFromLocalStorage("Organiser")) &&
      JSON.parse(getFromLocalStorage("Organiser")).length
        ? JSON.parse(getFromLocalStorage("Organiser"))[0]
        : {};
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "edit_service_provider",
          service_provider_id: id,
          service_provider_name: editName,
          service_provider_summary: editSummary,
          selected_admin_user: organiserData.id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "edit_service_provider");
        if (dataResponse.success) {
          const tempProviders = [...service_providers];
          tempProviders[index] = {
            service_provider_name: editName,
            summary_of_services: editSummary,
          };
          setProviders([...tempProviders]);
          handleClear();
          alert("Service Providers updated Successfully!");
          setShowModal(false);
        } else alert("Something went wrong. Please try again");
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong. Please try again");
      });
  };
  //   const handleEdit=(index)=>{
  //       const tempProviders=service_providers.filter((service,ind)=>{return ind!==index})
  //       console.log(tempProviders)
  //   }

  return (
    <>
      <Row>
        <Col></Col>
        <Col
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "#2959a5",
              cursor: "pointer",
            }}
            onClick={handleShow}
          >
            See Details
          </p>
        </Col>
        <Col></Col>
      </Row>
      <Modal fullscreen={true} show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Service Providers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="primary"
            onClick={() => {
              setProviders([
                { service_provider_name: "", summary_of_services: "" },
                ...service_providers,
              ]);
              setEdit(0);
              setEditName("");
              setEditSummary("");
            }}
          >
            Add Service Provider
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>service_provider_name</th>
                <th>summary_of_services</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {service_providers
                ? service_providers.map((service, index) => (
                    <tr key={index}>
                      <td>
                        {edit === index ? (
                          <input
                            onChange={(e) => {
                              setEditName(e.target.value);
                            }}
                            defaultValue={service.service_provider_name}
                            value={editName}
                          />
                        ) : (
                          service.service_provider_name
                        )}
                      </td>
                      <td>
                        {edit === index ? (
                          <textarea
                            rows={3}
                            onChange={(e) => {
                              setEditSummary(e.target.value);
                            }}
                            defaultValue={service.summary_of_services}
                            value={editSummary}
                          />
                        ) : (
                          service.summary_of_services
                        )}
                      </td>
                      <td style={{ display: "flex", gap: "10px" }}>
                        {edit === index ? (
                          <>
                            <Button
                              variant="primary"
                              onClick={() => {
                                handleSave(index, service.id);
                              }}
                            >
                              Save
                            </Button>
                            <Button variant="secondary" onClick={handleClear}>
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => {
                                  setEdit(index);
                                  setEditName(service.service_provider_name);
                                  setEditSummary(service.summary_of_services);
                                }}
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                            {/* <Tooltip
                              title="Delete"
                              onClick={() => {
                                handleDelete(index);
                              }}
                            >
                              <IconButton aria-label="delete" size="small">
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip> */}
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit Changes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServiceProvidersModal;
