import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import PaymentForm from "./components/PaymentForm.js";
import { SQUARE_SCRIPT_URL } from "../../assets/Constants";
import NavBar from "../../components/UI/NavBar/index";
import {
  store,
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store/index";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import "react-phone-input-2/lib/style.css";
import CustomButton from "../../components/UI/Button/index";
import { theme } from "../../components/UI/theme";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import {
  updateCartProducts,
  updateCartTotal,
  updateCartTicket,
  sendSms,
  loginPhone,
  getUserProfile,
  logout,
  updateUser,
} from "../../actions";

import { applyToken } from "../../services/api";
import {
  CartComponent,
  ProductComponent,
  CheckoutButtonComponent,
  cartLocalization,
} from "react-shopping-cart";

import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
const { getDefaultLocalization } = cartLocalization;

const iPadCaseLocalization = {
  color: "Color",
  iPadCase: "iPad case",
  red: "Red",
  green: "Green",
  yellow: "Yellow",
  AUD: "$",
};

const iPadPropertiesWithAdditionalCostLocalization = {
  yellow: "Yellow",
};

const localization = {
  AUD: "$",
};

class CheckoutScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      orderStatusState: "",
      orderTypeState: "",
      orderLocationState: "",
      serviceFeeState: "",
      orderLaterState: false,
      orderDateState: "",
      productsState: {},
      ticketState: {},
      productsTotalState: 0,
      ticketTotalState: 0,
      deliveryChargesState: 0,
      serviceFeeState: 0,
      paymentStatusState: "",
      paymentSourceState: "",
      paymentReceiptNumberState: "",
      paymentErrorState: "",
      paymentErrorDetailsState: "",
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentProcessStarted: false,
      serverDeliveryChargesArray: new Array(),
      orderPromoCodeValue: "",
      orderPromoCodeState: new Array(),
      promoCodeError: "",
      applyPromoCodeState: "",
      discountState: 0.0,
      getCheckoutButtonLocalization: getDefaultLocalization(
        "checkoutButton",
        "en",
        iPadCaseLocalization
      ),

      getCartLocalization: getDefaultLocalization("cart", "en"),
      selectedEvent: null,
      isPaymentModalOpen: false,
      isOpenLoginModal: false,
      usernameState: "",
      emailState: "",
      pickupTime: "",
      pickupTimes: [],
      isOpenTimeModal: false,
      selectedVendorData: -1,
      promoCode: "",
    };
  }

  componentWillMount() {
    const that = this;
    let sqPaymentScript = document.createElement("script");
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = SQUARE_SCRIPT_URL;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true,
      });
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  componentDidMount() {
    const { appReducer } = store.getState();
    const { loggedIn, username, userId } = this.props;
    const { eventTicket, productsTotal, products } = appReducer;

    const {
      productsState,
      productsTotalState,
      ticketState,
      ticketTotalState,
    } = this.state;
    if (this.isEmpty(ticketState)) {
      this.setState({ ticketState: eventTicket }, () => {});
    }
    if (ticketTotalState == 0) {
      var ticketTotalStateTemp = 0;
      for (var key in eventTicket) {
        ticketTotalStateTemp =
          Number(eventTicket[key].quantity) *
          Number(eventTicket[key].ticketPrice);
      }
      this.setState({ ticketTotalState: ticketTotalStateTemp });
    }

    if (this.isEmpty(productsState)) {
      this.setState({ productsState: products }, () => {});
    }

    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal });
    }
    if (getFromLocalStorage("selectedEvent") != "undefined") {
      this.setState(
        { selectedEvent: JSON.parse(getFromLocalStorage("selectedEvent")) },
        () => {
          if (getFromLocalStorage("selectedVendorData") != "undefined") {
            let selectedVendorData = JSON.parse(
              getFromLocalStorage("selectedVendorData")
            );
            this.setState({ selectedVendorData: selectedVendorData }, () => {
              this.getPickupTime();
            });
          }
        }
      );
    }

    if (loggedIn != false && username != "" && userId != "") {
      this.setState({ isOpenTimeModal: true });
    }
  }

  emptyStorage = () => {
    deleteFromLocalStorage("orderStatus", "");
    deleteFromLocalStorage("orderDate", "");
    deleteFromLocalStorage("paymentStatus", "");
    deleteFromLocalStorage("paymentSource", "");
    deleteFromLocalStorage("paymentReceiptNumber", "");
    deleteFromLocalStorage("paymentWttCharges", 0);
    deleteFromLocalStorage("paymentTxnCharges", 0);
    deleteFromLocalStorage("paymentTotalCharges", 0);
    deleteFromLocalStorage("paymentError", "");
    deleteFromLocalStorage("eventTicket", "{}");
    deleteFromLocalStorage("productsTotal", 0);
    deleteFromLocalStorage("orderType", "");
    deleteFromLocalStorage("orderLocation", "");
    deleteFromLocalStorage("eventId", "");
    deleteFromLocalStorage("orderLater", false);
    deleteFromLocalStorage("deliveryCharges", 0);
    deleteFromLocalStorage("serviceFee", 0);
    deleteFromLocalStorage("applyPromoCode", "");
    deleteFromLocalStorage("discount", 0);
  };
  getPickupTime = () => {
    const { selectedEvent, selectedVendorData } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var url = config.BASE_URI + "/api/v1/customer-events/";

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_pickup_time",
          event_id: selectedEvent.id,
          vendor_id: selectedVendorData.id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({ pickupTimes: response.pickup_times });
        }
      });
  };

  validatePromoCode = () => {
    const { promoCode, selectedEvent, selectedVendorData } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var url = config.BASE_URI + "/api/v1/customer-events/";

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "validate_promo",
          event_id: selectedEvent.id,
          code: promoCode,
          vendor_id: selectedVendorData.id,
          order_total: this.calculateTotal(),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState(
            { discountState: response.data.discounted_amount },
            () => {
              saveToLocalStorage("discount", this.state.discountState);
            }
          );
        } else {
          alert(response.error);
        }
      });
  };

  sendSms = () => {
    this.setState({ buttonPressed: true }, () => {
      this.props.dispatch(sendSms(this.state.phone)).then(() => {
        this.setState({ buttonPressed: false });
      });
    });
  };
  loginPhone = () => {
    this.props
      .dispatch(loginPhone(this.state.phone, this.state.code))
      .then((data) => {
        if (data) {
          this.getUserProfile(data["payload"].userId);
        } else {
          alert("Error! Please try again!");
        }
      });
  };

  getUserProfile = (userId) => {
    this.props.dispatch(getUserProfile(userId)).then((data) => {
      if (data.payload.username == "" || data.payload.email == null) {
        this.toggleProfileModal(true);
      } else {
        window.location.reload();
      }
    });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  calculateSubTotal = () => {
    const { productsTotalState, ticketTotalState, productsState } = this.state;
    var subTotal = Number(productsTotalState) + Number(ticketTotalState);
    if (Number(subTotal) > 0) {
      return subTotal;
    } else {
      window.location.href = "/";
    }
  };

  calculateTotal = () => {
    const { productsTotalState, ticketTotalState, discountState } = this.state;
    return (
      Number(productsTotalState) +
      Number(ticketTotalState) +
      Number(getFromLocalStorage("serviceFee")) -
      Number(discountState)
    );
  };

  togglePaymentModal = () => {
    this.state.isPaymentModalOpen
      ? this.setState({ isPaymentModalOpen: false })
      : this.setState({ isPaymentModalOpen: true });
  };

  toggleProfileModal = (toggle) => {
    // this.props.dispatch(toggleProfileModal(toggle))
    this.setState({ isOpenProfileModal: toggle });
  };

  logout = () => {
    this.props.dispatch(logout());
    window.location.reload();
  };

  updateUserProfile = (userId) => {
    const { usernameState, emailState } = this.state;
    if (usernameState == "" || emailState == "") {
      alert("Please fill in the required fields");
      return;
    }
    applyToken(getFromLocalStorage("token"));
    this.props
      .dispatch(updateUser(userId, usernameState, emailState))
      .then((data) => {
        this.getUserProfile(data["payload"].id);
      });
  };

  removeProduct = (key) => {
    const { productsState, ticketState } = this.state;

    const { appReducer } = store.getState();
    const { products } = appReducer;
    const { dispatch } = this.props;
    if (key in productsState) {
      var jsonObj = productsState;
      delete jsonObj[key];

      this.setState({ productsState: jsonObj }, () => {
        var price = 0;
        for (var key in this.state.productsState) {
          price +=
            Number(this.state.productsState[key].prices.AUD) *
            Number(this.state.productsState[key].quantity);
          this.state.productsState[key].additionalCost &&
            this.state.productsState[key].additionalCost.map((item) => {
              var additionalPrice =
                Object.values(item)[0] *
                Number(this.state.productsState[key].quantity);
              if (!isNaN(Number(additionalPrice))) {
                price += Number(additionalPrice);
              }
            });
        }
        this.setState({ productsTotalState: price }, () => {
          dispatch(updateCartProducts(this.state.productsState));
          dispatch(updateCartTotal(this.calculateTotal()));
        });
      });
    } else if (key in ticketState) {
      var jsonObj = ticketState;
      delete jsonObj[key];
      this.setState({ ticketState: jsonObj }, () => {
        this.setState({ ticketTotalState: 0 }, () => {
          dispatch(updateCartTicket(this.state.ticketState));
          dispatch(updateCartTotal(this.calculateTotal()));
        });
      });
    }
  };

  render() {
    const { loggedIn, username, userId, dispatch } = this.props;
    const {
      productsState,
      ticketState,
      selectedEvent,
      loaded,
      pickupTime,
      promoCode,
    } = this.state;
    let mergedProducts = { ...ticketState, ...productsState };
    var selectedVendorData = "";
    if (getFromLocalStorage("selectedVendorData") != "undefined") {
      selectedVendorData = JSON.parse(
        getFromLocalStorage("selectedVendorData")
      );
    }

    return (
      loaded && (
        <div>
          <ResponsiveNavBar
            showBack={true}
            backUrl={"/"}
            ticketState={ticketState}
            products={{}}
            loggedIn={loggedIn}
            username={username}
            userd={userId}
            dispatch={dispatch}
          ></ResponsiveNavBar>
          <Container>
            <Row>
              <Col lg={8} className="checkout-screen-cart">
                <CartComponent
                  hideHeader={true}
                  products={mergedProducts}
                  getLocalization={getDefaultLocalization(
                    "cart",
                    "en",
                    localization
                  )}
                  currency="AUD"
                  isCartEmpty={false}
                  onRemoveProduct={(key) => this.removeProduct(key)}
                />
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Sub Total:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      ${Number(this.calculateSubTotal()).toFixed(2)}
                    </h5>
                  </Col>
                </Row>
                {Number(getFromLocalStorage("serviceFee") > 0) ? (
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={6}>
                      <h5 style={{ textAlign: "left" }}>Service Fee:</h5>
                    </Col>
                    <Col xs={6}>
                      <h5 style={{ textAlign: "left" }}>
                        ${Number(getFromLocalStorage("serviceFee")).toFixed(2)}
                      </h5>
                    </Col>
                  </Row>
                ) : null}

                {Number(this.state.discountState) > 0 ? (
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={6}>
                      <h5 style={{ textAlign: "left" }}>Discount</h5>
                    </Col>
                    <Col xs={6}>
                      <h5 style={{ textAlign: "left" }}>
                        ${Number(this.state.discountState).toFixed(2)}
                      </h5>
                    </Col>
                  </Row>
                ) : null}

                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Total:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      ${Number(this.calculateTotal()).toFixed(2)}
                    </h5>
                  </Col>
                </Row>
                <Row className="desktop-only">
                  <Button
                    onClick={() => {
                      if (loggedIn == false || username == "" || userId == "") {
                        this.setState({ isOpenLoginModal: true });
                      } else {
                        this.togglePaymentModal();
                      }
                    }}
                    block
                    className="button-enabled-pink-style"
                    style={{ marginTop: 20, backgroundColor: "#ef3f6d" }}
                  >
                    {loggedIn == false || username == "" || userId == ""
                      ? "Sign In to complete your order"
                      : "Pay Now"}
                  </Button>
                </Row>
              </Col>
              <Col lg={4}>
                <div className="desktop-only">
                  <Card.Img
                    src={require("../../assets/img/default_cover.jpg")}
                  />
                </div>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Order Type:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Event</h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Event Name:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      {selectedEvent != null ? selectedEvent.name : ""}
                    </h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Event Date:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      {selectedEvent != null ? selectedEvent.start_date : ""}
                    </h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Event Time:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      {selectedEvent != null
                        ? selectedEvent.start_time +
                          " - " +
                          selectedEvent.end_time
                        : ""}
                    </h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Order Pickup Time:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      {pickupTime}{" "}
                      <span
                        style={{
                          color: "blue",
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({ isOpenTimeModal: true });
                        }}
                      >
                        Edit
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Event Location:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>
                      {selectedEvent != null ? selectedEvent.location : ""}
                    </h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10, marginBottom: 80 }}>
                  <Col xs={6}>
                    <h5 style={{ textAlign: "left" }}>Promo Code:</h5>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="Enter Promo Code"
                        value={promoCode}
                        onChange={(e) => {
                          this.setState({ promoCode: e.target.value });
                        }}
                      />
                      <Button
                        style={{ marginTop: 10 }}
                        onClick={() => {
                          this.validatePromoCode();
                        }}
                      >
                        Apply
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>

                <Row
                  className="mobile-only"
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "90%",
                    margin: "0 auto",
                    backgroundColor: "white",
                    paddingBottom: 30,
                  }}
                >
                  <Button
                    onClick={() => {
                      if (loggedIn == false || username == "" || userId == "") {
                        this.setState({ isOpenLoginModal: true });
                      } else {
                        this.togglePaymentModal();
                      }
                    }}
                    block
                    className="button-enabled-pink-style"
                    style={{ marginTop: 20 }}
                  >
                    {loggedIn == false || username == "" || userId == ""
                      ? "Sign In to complete your order"
                      : "Pay Now"}
                  </Button>
                </Row>
              </Col>
            </Row>
            {this.renderPaymentModal()}
            {this.renderLoginModal()}
            {this.renderProfileModal()}
            {this.renderPickUpTime()}
          </Container>
        </div>
      )
    );
  }

  renderPaymentModal = () => {
    const {
      isPaymentModalOpen,
      productsTotalState,

      deliveryChargesState,
      serviceFeeState,
      orderTypeState,
      productsState,
      ticketState,
      orderDateState,
      orderLaterState,
      discountState,
      applyPromoCodeState,
      pickupTime,
      promoCode,
    } = this.state;

    const { dispatch, userId } = this.props;
    return (
      <Modal
        show={isPaymentModalOpen}
        onHide={this.togglePaymentModal.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Payment Details Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card
            style={{
              height: 500,
              marginTop: 20,
              overflowY: "scroll",
              border: "none",
            }}
          >
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Card.Title></Card.Title>
              <Row>
                <Col xs={12}>
                  <PaymentForm
                    paymentForm={window.SqPaymentForm}
                    user={userId}
                    deliveryCharges={deliveryChargesState}
                    serviceFeeState={serviceFeeState}
                    orderType={orderTypeState}
                    dispatch={dispatch}
                    productsState={productsState}
                    ticketState={ticketState}
                    orderDateState={orderDateState}
                    orderLaterState={orderLaterState}
                    discount={discountState}
                    applyPromoCode={promoCode}
                    sub_total={this.calculateSubTotal()}
                    total={this.calculateTotal()}
                    order_time={pickupTime}
                    sub_total={this.calculateSubTotal()}
                    total={this.calculateTotal()}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  renderLoginModal = () => {
    const { isOpenLoginModal, buttonPressed, code } = this.state;
    const { appReducer } = store.getState();
    const { selectedTab, smsSent } = appReducer;
    return (
      <Modal
        show={isOpenLoginModal}
        onHide={() => {
          this.setState({ isOpenLoginModal: false });
        }}
      >
        {smsSent === false ? (
          <>
            <Modal.Header>
              <Modal.Title>Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              Whether you're creating an account or signing back in, lets's
              start with your number
              <PhoneInput
                containerClass={"phoneInputStyle"}
                country={"au"}
                onlyCountries={["au", "gb"]}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone })}
                autoFormat={true}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                backgroundColor={"#646771"}
                textColor={theme.colors.textColorLight}
                borderRadius={20}
                isProfilePic={false}
                buttonText={buttonPressed ? "Loading..." : "Continue"}
                handleButtonClick={buttonPressed ? null : this.sendSms}
                buttonWidth={"90%"}
                margin={"auto"}
              ></CustomButton>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>Verify Your Number</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                textAlign: "center",
                width: "90%",
                margin: "auto",
                color: "#adb3bf",
              }}
            >
              Enter the confirmation code we have sent to your number
              <OtpInput
                onChange={(code) =>
                  this.setState({ code: code }, () => {
                    if (code.length === 4) {
                      this.loginPhone();
                    }
                  })
                }
                value={code}
                numInputs={4}
                separator={<span>&nbsp;</span>}
                containerStyle={"otpInputStyle"}
              />
            </Modal.Body>
            <Modal.Footer>
              <p>Resend Confirmation Code</p>
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  };
  renderProfileModal = () => {
    const { isOpenProfileModal, usernameState, emailState } = this.state;

    const { appReducer } = store.getState();
    const { userId } = appReducer;

    return (
      <Modal
        show={isOpenProfileModal}
        onHide={() => {
          this.toggleProfileModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Profile Info</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            width: "90%",
            margin: "auto",
            color: "#adb3bf",
          }}
        >
          Enter your profile details below
          <Form.Group style={{ textAlign: "left" }}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              defaultValue={usernameState}
              onChange={(event) => {
                this.setState({ usernameState: event.target.value });
              }}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail" style={{ textAlign: "left" }}>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              defaultValue={emailState}
              onChange={(event) => {
                this.setState({ emailState: event.target.value });
              }}
            />
          </Form.Group>
          <CustomButton
            backgroundColor={
              usernameState != "" && emailState != ""
                ? theme.colors.primaryColor
                : theme.colors.backgroundColor
            }
            textColor={
              usernameState != "" && emailState != ""
                ? theme.colors.textColorLight
                : theme.colors.textColorDark
            }
            borderRadius={20}
            isProfilePic={false}
            buttonText={"Continue"}
            margin={"auto"}
            buttonWidth={"90%"}
            handleButtonClick={
              usernameState != "" && emailState != ""
                ? () => {
                    this.updateUserProfile(userId);
                  }
                : null
            }
          ></CustomButton>
        </Modal.Body>
      </Modal>
    );
  };

  renderPickUpTime() {
    const { isOpenTimeModal, pickupTime, pickupTimes } = this.state;
    return (
      <Modal
        show={isOpenTimeModal}
        onHide={() => {
          this.toggleProfileModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Select Pickup Time</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            width: "90%",
            margin: "auto",
            color: "#adb3bf",
          }}
        >
          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
            <Form.Control
              as="select"
              size="sm"
              custom
              value={pickupTime}
              onChange={(e) => {
                this.setState({
                  pickupTime: e.target.value,
                  isOpenTimeModal: false,
                });
                saveToLocalStorage("pickupTime", e.target.value);
              }}
            >
              <option value={0}>Select pickup time</option>
              {pickupTimes.map((item) => {
                return (
                  <option disabled={!item.available} value={item.time}>
                    {item.time}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(CheckoutScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
