import React from "react";
import "./SearchResultsList.css";
import SearchResult from "./SearchResult";

const SearchResultsList = ({
  filteredEvents,
  selectedEventP,
  selectedEventQ,
}) => {
  const selectedEvent = (data) => {
    console.log(data);
    selectedEventP(data);
  };
  const selectedEventa = (data) => {
    console.log(data);
    selectedEventQ(data);
  };
  return (
    <div className="results-list">
      {filteredEvents &&
        filteredEvents.map((event, id) => {
          return (
            <SearchResult
              filteredEvent={event.name}
              filteredId={event.id}
              key={id}
              selectedEvent={selectedEvent}
              selectedEventa={selectedEventa}
            />
          );
        })}
    </div>
  );
};
export default SearchResultsList;
