import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import {
  SQUARE_APP_ID,
  LOCATION_ID,
  PAYMENT_URL,
  SQUARE_ACCESS_TOKEN,
  SQUARE_API_VERSION,
  CURRENCY,
  TRANSACTION_FEE,
  APP_FEE,
  KITCHACO_DELIVERY_FEE,
} from "../../assets/Constants";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  store,
} from "../../store";
import { useDispatch } from "react-redux";
import {
  orderNow,
  payment,
  updateCartProducts,
  updateCartTicket,
  updateCartTotal,
} from "../../actions";
import { sendEmailConfirmation } from "./sendEmailConfirmation";
import { format } from "date-fns";

const PaymentForm = ({
  total,
  user,
  onPaymentSuccess,
  eventId,
  eventLocation,
  productsState,
  ticketState,
  orderDateState,
  deliveryCharges,
  deliveryLocation,
  order_time,
  discount,
  sub_total,
  orderType,
  applyPromoCode,
}) => {
  console.log(
    total,
    onPaymentSuccess,
    productsState,
    ticketState,
    orderDateState,
    deliveryCharges,
    deliveryLocation,
    order_time,
    orderType,
    applyPromoCode
  );
  const [card, setCard] = useState(null);
  const [isSquareLoaded, setIsSquareLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentData, setPaymentData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const loadSquareScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://sandbox.web.squarecdn.com/v1/square.js";
        script.async = true;
        script.onload = () => resolve(true);
        document.head.appendChild(script);
      });
    };

    const initializeSquare = async () => {
      await loadSquareScript();
      setIsSquareLoaded(true);

      if (window.Square) {
        const payments = window.Square.payments(SQUARE_APP_ID, LOCATION_ID);
        const card = await payments.card();
        await card.attach("#card-container");
        setCard(card);
      }
    };

    initializeSquare().catch((err) => {
      console.error("Failed to initialize Square payment form:", err);
      setError("Failed to initialize payment form. Please try again.");
    });
  }, []);

  const orderPlacedSuccessfully = () => {
    alert("Order Placed Succesfully!");
    const { appReducer } = store.getState();
    const { eventTicket } = appReducer;
    dispatch(updateCartTicket({}));
    dispatch(updateCartTotal(0.0));
    deleteFromLocalStorage("selectedEvent");
    dispatch(updateCartProducts({}));
    dispatch(updateCartTotal(0));

    deleteFromLocalStorage("orderStatus", "");
    deleteFromLocalStorage("customerCart", "{}");
    deleteFromLocalStorage("ORDER_TYPE", "Pickup");
    deleteFromLocalStorage("orderDate", "");
    deleteFromLocalStorage("paymentStatus", "");
    deleteFromLocalStorage("paymentSource", "");
    deleteFromLocalStorage("paymentReceiptNumber", "");
    deleteFromLocalStorage("paymentWttCharges", 0);
    deleteFromLocalStorage("paymentTxnCharges", 0);
    deleteFromLocalStorage("paymentTotalCharges", 0);
    deleteFromLocalStorage("paymentError", "");
    deleteFromLocalStorage("eventTicket", "{}");
    deleteFromLocalStorage("productsTotal", 0);
    deleteFromLocalStorage("orderType", "");
    deleteFromLocalStorage("orderLocation", "");
    deleteFromLocalStorage("eventId", "");
    deleteFromLocalStorage("orderLater", false);
    deleteFromLocalStorage("deliveryCharges", 0);
    deleteFromLocalStorage("serviceFee", 0);
    deleteFromLocalStorage("applyPromoCode", "");
    deleteFromLocalStorage("discount", 0);
    window.location.href = "/orders";
  };

  const orderNowDishes = () => {
    const { appReducer } = store.getState();
    var selectedVendorData = JSON.parse(
      getFromLocalStorage("selectedVendorData")
    );
    var selectedEvent = JSON.parse(
      getFromLocalStorage("selectedEvent")
        ? getFromLocalStorage("selectedEvent")
        : {}
    );
    const {
      username,
      userId,
      orderLater,
      // orderDate,
      // orderType,
      // orderLocation,
      serviceFee,
      paymentStatus,
      paymentSource,
      paymentReceiptNumber,
      paymentWttCharges,
      paymentTxnCharges,
      paymentTotalCharges,
      // eventId,
    } = appReducer;

    var sub_total = 0;

    const newDishes = [];

    for (var key in productsState) {
      newDishes.push({
        quantity: productsState[key].quantity,
        id: productsState[key].meal_id,
        name: productsState[key].name,
        price: productsState[key].price,
        combo:
          productsState[key].combos && productsState[key].combos.length
            ? productsState[key].combos.map((item) => item.name).join(",")
            : "",
        subitem:
          productsState[key].subitems && productsState[key].subitems.length
            ? productsState[key].subitems.map((item) => item.name).join(",")
            : "",
        request: productsState[key].request ? productsState[key].request : "",
        // properties: {
        //   SpecialRequest: productsState[key]?.specialRequest ?? "",
        //   Combo: productsState[key]?.combo ?? [],
        //   Subitem: productsState[key]?.subitem ?? [],
        // },
      });
      sub_total =
        Number(sub_total) +
        Number(productsState[key].quantity) * Number(productsState[key].price);
    }

    var cartEventTicket = {};
    for (var key in ticketState) {
      var payment_total_amount =
        Number(ticketState[key].quantity) *
        Number(ticketState[key].ticketPrice);
      sub_total = Number(sub_total) + Number(payment_total_amount);
      cartEventTicket = {
        event: selectedEvent.id,
        quantity: ticketState[key].quantity,
        one_ticket_price: ticketState[key].ticketPrice,
        payment_total_amount: payment_total_amount,
      };
    }

    const data = {
      customer_name: username,
      customer: userId,
      vendor: selectedVendorData.id,
      meals: [...newDishes],
      payment_status: paymentStatus,
      payment_source: paymentSource,
      payment_receipt_number: paymentReceiptNumber,
      payment_wtt_charges: paymentWttCharges,
      payment_txn_charges: paymentTxnCharges ?? 0.0,
      payment_total_amount: paymentTotalCharges,
      order_date: format(orderDateState, "dd MMM yyyy"),
      order_time: order_time,
      order_special_request: "",
      vendor_name: selectedVendorData.title,
      order_later:
        typeof orderLater == "string" ? JSON.parse(orderLater) : orderLater,
      order_type: orderType?.toLowerCase(),
      event_location: eventLocation,
      service_fee: serviceFee,
      event_id: eventId,
      discount: Number(getFromLocalStorage("discount")).toFixed(2),
      promo_code: applyPromoCode,
      delivery_charge: deliveryCharges,
      event_ticket: cartEventTicket,
      sub_total: sub_total,
    };

    dispatch(orderNow(data)).then((orderResponse) => {
      if (orderResponse && orderResponse["payload"]) {
        var response = orderResponse["payload"];
        if (response.status === "Received") {
          orderPlacedSuccessfully();
        }
      } else {
        alert(
          "There was an error processing your order! If your money was deducted, it would be refunded in full within 2 business days."
        );
        // window.location.reload(true);
      }
    });
  };

  const tokenizeCard = async () => {
    if (!card) {
      console.error("Card not initialized");
      return;
    }

    setLoading(true);
    setError(null);

    const result = await card.tokenize();
    if (result.status === "OK") {
      return result.token;
      // onPaymentSuccess();
      // orderNowDishes();
    } else {
      console.error("Tokenization failed:", result.errors);
      setError("Payment failed. Please check your card details and try again.");
    }

    setLoading(false);
  };

  const makePayment = (
    amount_money,
    source_id,
    idempotency_key,
    currency,
    app_fee_money,
    tickets,
    dishes
  ) => {
    let data = {
      url: PAYMENT_URL,
      token: SQUARE_ACCESS_TOKEN,
      square_api_version: SQUARE_API_VERSION,
      crossDomain: true,
      method: "POST",
      body: JSON.stringify({
        source_id: source_id,
        idempotency_key: idempotency_key,
        amount_money: {
          amount: amount_money,
          currency: currency,
        },
        app_fee_money: {
          amount: app_fee_money,
          currency: currency,
        },
        autocomplete: true,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + SQUARE_ACCESS_TOKEN,
        "Square-Version": SQUARE_API_VERSION,
      },
    };
    dispatch(payment(data)).then((response) => {
      var paymentResponse = response.payload;
      // console.log(paymentResponse, "PAYMENT");
      // return paymentResponse;
      if (paymentResponse != undefined) {
        if (
          paymentResponse.payment != undefined &&
          paymentResponse.payment.status != "CANCELLED" &&
          paymentResponse.payment.status != "FAILED"
        ) {
          setPaymentData(() => ({
            paymentStatus: paymentResponse.payment.status,
            paymentSource: paymentResponse.payment.source_type,
            paymentReceiptNumber: paymentResponse.payment.receipt_number,
            paymentWttCharges:
              paymentResponse.payment.app_fee_money.amount / 100,
            paymentTxnCharges:
              Math.round(TRANSACTION_FEE * amount_money * 100) / 100 / 100,
            paymentTotalCharges:
              paymentResponse.payment.total_money.amount / 100,
            paymentError: "",
          }));

          sendEmailConfirmation(
            dishes,
            ticketState,
            sub_total,
            discount,
            total + deliveryCharges
          );

          orderNowDishes();
        }
      } else {
        var error = "Payment Error! Please try again.";
        if (paymentResponse.errors[0].code === "INVALID_EXPIRATION") {
          error = "Invalid Card Expiry. Please try again!";
        } else if (paymentResponse.errors[0].code === "GENERIC_DECLINE") {
          error = "Card Declined. Please try again!";
        } else if (paymentResponse.errors[0].code === "CVV_FAILURE") {
          error = "Invalid CVV. Please try again!";
        }
        setPaymentData(() => ({
          paymentError: "FAILED",
          paymentErrorDetails: error,
        }));

        window.location.reload(true);
        // this.onCardEntryCancel();

        alert(error);
      }
    });
  };

  const placeOrder = async () => {
    // if (isPaymentLoading == false) {
    //   this.setState({ isPaymentLoading: true });
    // }
    const cardButton = document.getElementById("card-button");

    try {
      //cardButton.disabled = true
      const token = await tokenizeCard();
      let source_id = token;
      let idempotency_key = "" + user + "" + Date.now();
      let currency = CURRENCY;

      let amount_money =
        Number(sub_total) +
        Number(getFromLocalStorage("serviceFee")) -
        Number(discount);

      //PAYMENT STEP 3 - Convert the amount_money to cents
      amount_money = parseInt(
        (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
      ); // rounded in cents

      // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
      // make sure this is also in cents

      var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

      // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
      app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

      // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery
      if (orderType == "delivery") {
        app_fee_money += KITCHACO_DELIVERY_FEE;
      }

      const paymentResults = await makePayment(
        amount_money,
        source_id,
        idempotency_key,
        currency,
        app_fee_money,
        ticketState,
        productsState
      );
    } catch (e) {
      if (cardButton) cardButton.disabled = false;
      console.error(e.message);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "#fff",
        maxWidth: 400,
        margin: "auto",
        mt: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>
      <Typography variant="body1" gutterBottom>
        Total: ${(total + deliveryCharges).toFixed(2)}
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {isSquareLoaded ? (
        <div>
          <div id="card-container" style={{ marginBottom: 16 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={placeOrder}
            disabled={!card || loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Pay"}
          </Button>
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default PaymentForm;
