import React, { useRef, useState, useEffect } from "react";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PaymentForm from "./PaymentForm";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CategoriesSidebar from "./CategoriesSidebar";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import Badge from "@mui/material/Badge";
import { AWS_URL, DELIVERY_FEE } from "../../assets/Constants";
import config from "../../services/apiConfig";
import "./style.css";
import { loginModalToggle } from "../../actions";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
  store,
} from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  promoMessage: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.light,
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
  },
  card: {
    maxWidth: 345,
    width: 345,
    height: 400,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  media: {
    height: 140,
    backgroundColor: "#2959a5",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
  },
  description: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "90vh",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
    padding: "32px",
    borderRadius: "15px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "16px",
    },
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: 1000,
    width: "100%",
    margin: "auto",
    display: "block",
    paddingBottom: "10px",
  },
  cartDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  cartTotal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  cartItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  cartImage: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  cartActions: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  cartQuantity: {
    margin: "0 10px",
  },
  cartDelete: {
    marginLeft: "10px",
    color: "red",
    cursor: "pointer",
  },
}));

const Event = ({
  ticketState,
  products,
  loggedIn,
  username,
  userId,
  dispatch,
  vendorEventMenu,
}) => {
  console.log(
    ticketState,
    products,
    loggedIn,
    username,
    userId,
    dispatch,
    vendorEventMenu
  );
  const { appReducer } = store.getState();
  const { loginModalToggle: showLogin } = appReducer;

  const savedCart = JSON.parse(getFromLocalStorage("customerCart"));
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let eventDetails;
  let vendorId = localStorage.getItem("vendorId")
    ? localStorage.getItem("vendorId")
    : null;
  const [customerCart, setCustomerCart] = useState(() => {
    if (savedCart && savedCart.cart) {
      return { ...savedCart };
    } else
      return {
        total: 0,
        cart: [],
        subtotal: 0,
      };
  });
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedMeals, setSelectedMeals] = useState({});
  const [mealModalOpen, setMealModalOpen] = useState(false);
  const [selectedCombos, setSelectedCombos] = useState([]);
  const [selectedSubItems, setSelectedSubItems] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [pickupDates, setPickupDates] = useState([]);
  const [pickupTimes, setPickupTimes] = useState([]);
  const [selectedPickupTime, setSelectedPickupTime] = useState("");
  const [selectedPickupDate, setSelectedPickupDate] = useState("");
  const [orderType, setOrderType] = useState(() =>
    getFromLocalStorage("ORDER_TYPE")
      ? getFromLocalStorage("ORDER_TYPE")
      : "Pickup"
  );
  const [promoCode, setPromoCode] = useState("");
  const [deliveryLocation, set_deliveryLocation] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(() =>
    getFromLocalStorage("ORDER_TYPE") === "Delivery" ? DELIVERY_FEE : 0
  );
  const [promoMessage, setPromoMessage] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [serviceFeeState, set_serviceFeeState] = useState(0);
  const [orderLaterState, set_orderLaterState] = useState(0);
  const [filteredMeals, setFilteredMeals] = useState([]);
  const refs = useRef({});

  const saveCart = (cart) => {
    saveToLocalStorage("customerCart", JSON.stringify(cart));
  };

  useEffect(() => {
    // Initialize filteredMeals with all meals if vendorEventMenu is valid and has meals
    if (vendorEventMenu && vendorEventMenu.meals) {
      setFilteredMeals(vendorEventMenu.meals);
    }
  }, [vendorEventMenu]);

  const handleShowLogin = () => {
    dispatch(loginModalToggle(true));
  };

  const handleOrderTypeChange = (event) => {
    const selectedType = event.target.value;
    setOrderType(selectedType);

    setDeliveryFee(selectedType === "Delivery" ? DELIVERY_FEE : 0);

    saveToLocalStorage("ORDER_TYPE", selectedType);
  };

  const handlePickupTimeChange = (event) => {
    setSelectedPickupTime(event.target.value);
  };

  const handlePickupDateChange = (event) => {
    setSelectedPickupDate(event.target.value);
  };

  const handleSelectCategory = (category) => {
    if (!vendorEventMenu || !vendorEventMenu.meals) {
      return;
    }

    if (category === "All") {
      setFilteredMeals(vendorEventMenu.meals);
    } else {
      const categoryMeals = vendorEventMenu.meals.filter(
        (meal) => meal.category.title === category
      );
      setFilteredMeals(categoryMeals);
    }
  };

  const handlePaymentSuccess = () => {
    setIsPaymentModalOpen(false); // Close the payment modal
    setCustomerCart({ total: 0, cart: [], subtotal: 0 }); // Reset the cart
    saveCart({ total: 0, cart: [], subtotal: 0 });
    // Here, you might want to navigate the user to a success page or show a success message
  };

  // Function to transition from cart review to payment
  const proceedToPayment = () => {
    setIsCartOpen(false);

    setIsPaymentModalOpen(true);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  useEffect(() => {
    // Load the Square payment script
    fetchEventDetails();
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      // Script loaded successfully
      console.log("Square payment script loaded");
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);

    return () => {
      // Cleanup script when the component unmounts
      document.getElementsByTagName("head")[0].removeChild(sqPaymentScript);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    let currentDate = start;
    while (currentDate <= end) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const fetchEventDetails = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const url = config.BASE_URI + "/api/v1/customer-events/";

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "specific_event_details",
          event_id: eventDetails.id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      console.log("hello ", result);

      if (result.success === true) {
        console.log(result);
        // this.setState({ selectedEvent: result.event_details }, () => {});
        // dispatch(updateOrderLocation(result.event_details.location));
        // dispatch(updateEventId(result.event_details.id));
        // let date_range = this.getDateRange(
        //   result.event_details.start_date,
        //   result.event_details.end_date
        // );
        // this.setState({ dateRange: date_range });
        eventDetails = result.event_details;
        const dates = getDateRange(
          result.event_details.start_date,
          result.event_details.end_date
        );
        setPickupDates(dates);
        if (dates.length > 0) {
          setSelectedPickupDate(dates[0]);
        }
        getPickupTime();
      } else {
        alert(result.error);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  const handleComboChange = (combo, comboPrice) => {
    setSelectedCombos((prevSelectedCombos) => {
      if (prevSelectedCombos.map((item) => item.id).includes(combo.id)) {
        // Remove the combo if already selected
        return prevSelectedCombos.filter((item) => item.id !== combo.id);
      } else {
        // Add the combo if not selected
        return [...prevSelectedCombos, combo];
      }
    });

    setCustomerCart((prevCart) => {
      const isComboSelected = selectedCombos
        .map((item) => item.id)
        .includes(combo.id);
      const newTotal =
        prevCart.total + (isComboSelected ? -comboPrice : comboPrice);
      const newState = { ...prevCart, total: newTotal, subtotal: newTotal };

      saveCart(newState);
      return newState;
    });
  };

  const handleSubItemChange = (subitem, subitemPrice) => {
    setSelectedSubItems((prevSelectedSubItems) => {
      if (prevSelectedSubItems.map((item) => item.id).includes(subitem.id)) {
        // Remove the combo if already selected
        return prevSelectedSubItems.filter((item) => item.id !== subitem.id);
      } else {
        // Add the combo if not selected
        return [...prevSelectedSubItems, subitem];
      }
    });

    setCustomerCart((prevCart) => {
      const isSubItemSelected = selectedSubItems
        .map((item) => item.id)
        .includes(subitem.id);
      const newTotal =
        prevCart.total + (isSubItemSelected ? -subitemPrice : subitemPrice);
      const newState = { ...prevCart, total: newTotal, subtotal: newTotal };

      saveCart(newState);
      return newState;
    });
  };

  const getPickupTime = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const url = config.BASE_URI + "/api/v1/customer-events/";

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_pickup_time",
          event_id: eventDetails.id,
          vendor_id: vendor.id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      console.log("hello ", result);

      if (result.success === true) {
        console.log(result.pickup_times);
        setPickupTimes(result.pickup_times);
        if (result.pickup_times.length > 0) {
          setSelectedPickupTime(result.pickup_times[0].time);
        }
      } else {
        console.error("Failed to fetch pickup times:", result.error);
      }
    } catch (error) {
      console.error("Error fetching pickup times:", error);
    }
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };
  const handleDeliveryLocationChange = (event) => {
    set_deliveryLocation(event.target.value);
  };

  const validatePromoCode = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + "/api/v1/customer-events/";
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "validate_promo",
          event_id: eventDetails.id,
          code: promoCode,
          vendor_id: vendor.id,
          order_total: customerCart.total,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("hello ", response);
        if (response.success == false) {
          // const discount = response.data.discounted_amount;
          const discount = 2;
          setDiscountedAmount(discount);
          const newTotal = Math.max(customerCart.total - discount, 0);
          setCustomerCart((prevCart) => {
            const newState = {
              ...prevCart,
              total: newTotal,
            };

            saveCart(newState);
            return newState;
          });
          setPromoMessage(
            `Applied $${discount} discount! New total is $${newTotal.toFixed(
              2
            )}`
          );
        } else {
          alert(response.error);
        }
      });
  };

  const storedEventDetails = localStorage.getItem("eventDetails");
  if (storedEventDetails) {
    try {
      eventDetails = JSON.parse(storedEventDetails);
    } catch (error) {
      console.error("Error parsing event details from localStorage:", error);
      eventDetails = null;
    }
  }

  vendorId = vendorId ? Number(vendorId) : null;

  const vendor =
    eventDetails && vendorId
      ? eventDetails.vendor.find((v) => v.id === vendorId)
      : null;

  // const handleMealClick = (meal) => {
  //   const mealPrice = parseFloat(meal.price); // Convert price to a float
  //   const newSelectedMeals = { ...selectedMeals };
  //   if (newSelectedMeals[meal.id]) {
  //     delete newSelectedMeals[meal.id]; // Deselect meal
  //   } else {
  //     newSelectedMeals[meal.id] = meal; // Select meal
  //   }
  //   setSelectedMeals(newSelectedMeals);
  //   const existingItem = customerCart.cart.find(
  //     (item) => item.meal_id === meal.id
  //   );
  //   if (existingItem) {
  //     setCustomerCart((prevState) => ({
  //       ...prevState,
  //       cart: prevState.cart.map((item) =>
  //         item.meal_id === meal.id
  //           ? { ...item, quantity: item.quantity + 1 }
  //           : item
  //       ),
  //       total: prevState.total + mealPrice, // Add numerical value
  //     }));
  //   } else {
  //     setCustomerCart((prevState) => ({
  //       total: prevState.total + mealPrice, // Add numerical value
  //       cart: [
  //         ...prevState.cart,
  //         { meal_id: meal.id, price: meal.price, quantity: 1 },
  //       ],
  //     }));
  //   }
  // };
  const addToCart = () => {
    const mealPrice = parseFloat(selectedMeal.price);
    const existingItemIndex = customerCart.cart.findIndex((item) => {
      const id = selectedMeal.id ? selectedMeal.id : selectedMeal.meal_id;
      return item.meal_id === id;
    });

    // Calculate total combo price
    const comboPrice = selectedCombos.reduce((acc, combo) => {
      // const combo = selectedMeal.combo.find((c) => c.id === comboId);
      return acc + parseFloat(combo.price);
    }, 0);
    // Calculate total subitem price
    const subitemPrice = selectedSubItems.reduce((acc, subitem) => {
      // const subitem = selectedMeal.subitem.find((c) => c.id === subitemId);
      return acc + parseFloat(subitem.price);
    }, 0);

    const totalPrice = mealPrice + comboPrice + subitemPrice;
    setCustomerCart((prevState) => {
      let newCart = [...prevState.cart];
      if (existingItemIndex >= 0) {
        newCart[existingItemIndex] = {
          ...newCart[existingItemIndex],
          quantity: quantity,
        };
      } else {
        newCart.push({
          meal_id: selectedMeal.id,
          price: totalPrice,
          quantity: quantity,
          name: selectedMeal.name,
          image: selectedMeal.image,
          combos: selectedCombos,
          request: selectedMeal.specialRequest,
          subitems: selectedSubItems,
        });
      }

      const newTotal = newCart.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );

      const newState = {
        cart: newCart,
        total: newTotal,
        subtotal: newTotal,
      };
      saveCart(newState);
      return newState;
    });

    setMealModalOpen(false);
    setQuantity(1);
    setSelectedCombos([]);
    setPromoMessage("");
    setPromoCode("");
    setDiscountedAmount(0);
  };

  const handleMealClick = (meal) => {
    setQuantity(meal && meal.quantity ? meal.quantity : 1);
    setSelectedMeal(meal);
    setMealModalOpen(true);
  };

  const removeMealFromCart = (mealId) => {
    setCustomerCart((prevState) => {
      const newState = {
        ...prevState,
        cart: prevState.cart.filter((item) => item.meal_id !== mealId),
        total: prevState.cart.reduce((acc, item) => {
          if (item.meal_id !== mealId) {
            return acc + parseFloat(item.price) * item.quantity;
          }
          return acc;
        }, 0),
        subtotal: prevState.cart.reduce((acc, item) => {
          if (item.meal_id !== mealId) {
            return acc + parseFloat(item.price) * item.quantity;
          }
          return acc;
        }, 0),
      };
      saveCart(newState);
      return newState;
    });
    setPromoMessage("");
    setPromoCode("");
    setDiscountedAmount(0);
    const newSelectedMeals = { ...selectedMeals };
    delete newSelectedMeals[mealId];
    setSelectedMeals(newSelectedMeals);
  };

  const viewCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const totalItemsInCart = customerCart.cart.reduce(
    (total, item) => Number(total) + Number(item.quantity),
    0
  );

  return (
    <div>
      <Container
        className="responsive-container"
        style={{
          borderWidth: 1,
          borderColor: "grey",
          borderStyle: "solid",
          borderRadius: 5,
          padding: 15,
        }}
      >
        {vendorEventMenu ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
                position: "relative",
                paddingBottom: "30px",
                paddingTop: "30px",
              }}
            >
              <h2
                className="responsive-header"
                style={{
                  textAlign: "center",
                  fontWeight: 1000,
                  width: "100%",
                  margin: "auto",
                  display: "block",
                  paddingBottom: "10px",
                }}
              >
                {vendorEventMenu.title}
              </h2>
              <div style={{ width: "24px" }}></div>
              <IconButton
                onClick={viewCart}
                style={{ position: "absolute", right: 0 }}
              >
                <Badge
                  badgeContent={totalItemsInCart}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#ef3e6d",
                      color: "white",
                    },
                  }}
                >
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <CategoriesSidebar
                  meals={vendorEventMenu.meals}
                  onSelectCategory={handleSelectCategory}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Grid container spacing={2}>
                  {filteredMeals.length > 0 ? (
                    filteredMeals.map((meal) => {
                      // Determine the grid size based on the number of meals
                      const gridSize =
                        filteredMeals.length === 1
                          ? 12
                          : filteredMeals.length === 2
                          ? 6
                          : 4;
                      return (
                        <Grid item key={meal.id} xs={12} sm={gridSize}>
                          <Card
                            raised={selectedMeals && selectedMeals[meal.id]}
                            className="responsive-card"
                            style={{
                              maxWidth: 345,
                              width: "100%",
                              borderRadius: "8px",
                              boxShadow: 3,
                            }}
                          >
                            <ButtonBase
                              onClick={() => handleMealClick(meal)}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <CardActionArea>
                                <img
                                  className="d-block w-100"
                                  src={
                                    meal.image &&
                                    meal.image != null &&
                                    meal.image !== ""
                                      ? AWS_URL + "/" + meal.image
                                      : require("../../assets/img/default_cover.jpg")
                                  }
                                  alt={meal.name}
                                  style={{
                                    height: "250px",
                                    width: "100%",
                                    objectFit: "contain",
                                    margin: "auto",
                                  }}
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: 500,
                                      width: "100%",
                                      margin: "auto",
                                      display: "block",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    {meal.name}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: 500,
                                      width: "100%",
                                      margin: "auto",
                                      display: "block",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    ${meal.price}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </ButtonBase>
                          </Card>
                        </Grid>
                      );
                    })
                  ) : (
                    <Typography>
                      No meals available in this category.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>
            Vendor not found or event details are unavailable.
          </Typography>
        )}
      </Container>
      <Modal
        open={isCartOpen && !showLogin}
        onClose={viewCart}
        aria-labelledby="shopping-cart-modal-title"
        aria-describedby="shopping-cart-items"
      >
        <Box className={classes.modalStyle}>
          <Typography
            id="shopping-cart-modal-title"
            variant="h6"
            component="h2"
            className={classes.modalTitle}
          >
            Shopping Cart
          </Typography>
          <div id="shopping-cart-items">
            {customerCart.cart.map((item, index) => (
              <div key={index} className={classes.cartItem}>
                <img
                  src={AWS_URL + "/" + item.image}
                  alt={item.name}
                  className={classes.cartImage}
                />
                <Typography>{item.name}</Typography>
                <div className={classes.cartActions}>
                  <IconButton onClick={() => handleMealClick(item)}>
                    <RemoveIcon />
                  </IconButton>
                  <Typography className={classes.cartQuantity}>
                    {item.quantity}
                  </Typography>
                  <IconButton onClick={() => handleMealClick(item)}>
                    <AddIcon />
                  </IconButton>
                  <Typography>
                    ${(item.price * item.quantity).toFixed(2)}
                  </Typography>
                  <IconButton onClick={() => removeMealFromCart(item.meal_id)}>
                    <DeleteIcon className={classes.cartDelete} />
                  </IconButton>
                </div>
                {item.subitems && (
                  <div>
                    <Typography variant="subtitle2">Subitems:</Typography>
                    <ul>
                      {item.subitems.map((subitem) => {
                        return <li key={subitem.id}>{subitem.name}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <div className={classes.cartTotal}>
              <Typography>Sub Total:</Typography>
              <Typography>${customerCart.subtotal.toFixed(2)}</Typography>
            </div>
            {promoMessage && (
              <div className={classes.promoMessage}>
                <Typography color="primary" variant="body1">
                  {promoMessage} <LocalOfferIcon />
                </Typography>
              </div>
            )}
            {orderType === "Delivery" && (
              <div className={classes.cartTotal}>
                <Typography>Delivery Charges:</Typography>
                <Typography>${deliveryFee.toFixed(2)}</Typography>
              </div>
            )}
            <div className={classes.cartTotal}>
              <Typography>Total:</Typography>
              <Typography>
                ${Number(customerCart.total.toFixed(2)) + Number(deliveryFee)}
              </Typography>
            </div>
            <div className={classes.cartDetails}>
              <Typography>Order Type:</Typography>
              <TextField
                select
                SelectProps={{ native: true }}
                value={orderType}
                onChange={handleOrderTypeChange}
                fullWidth
              >
                <option value="Pickup">Pickup</option>
                <option value="Delivery">Delivery</option>
              </TextField>
            </div>
            {orderType === "Pickup" ? (
              <>
                <div className={classes.cartDetails}>
                  <Typography>Order Pickup Date:</Typography>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                    value={selectedPickupDate}
                    onChange={handlePickupDateChange}
                  >
                    {pickupDates.map((date, index) => (
                      <option key={index} value={date}>
                        {new Date(date).toDateString()}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className={classes.cartDetails}>
                  <Typography>Order Pickup Time:</Typography>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                    value={selectedPickupTime}
                    onChange={handlePickupTimeChange}
                  >
                    {pickupTimes.map((time) => (
                      <option key={time.id} value={time.time}>
                        {time.time}
                      </option>
                    ))}
                  </TextField>
                </div>
              </>
            ) : (
              <>
                <div className={classes.cartDetails}>
                  <Typography>Delivery Date:</Typography>
                  <TextField select SelectProps={{ native: true }} fullWidth>
                    {pickupDates.map((date, index) => (
                      <option
                        key={index}
                        value={date.toISOString().split("T")[0]}
                      >
                        {date.toDateString()}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className={classes.cartDetails}>
                  <Typography>Delivery Time:</Typography>
                  <TextField select SelectProps={{ native: true }} fullWidth>
                    {pickupTimes.map((time) => (
                      <option key={time.id} value={time.time}>
                        {time.time}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className={classes.cartDetails}>
                  <Typography>Delivery Location:</Typography>
                  <TextField
                    placeholder="Enter Delivery Location"
                    value={deliveryLocation}
                    onChange={handleDeliveryLocationChange}
                    fullWidth
                  />
                </div>
              </>
            )}
            <div className={classes.cartDetails}>
              <Typography>Event Name:</Typography>
              <Typography>{eventDetails?.name}</Typography>
            </div>
            <div className={classes.cartDetails}>
              <Typography>Event Date:</Typography>
              <Typography>
                {eventDetails?.start_date} - {eventDetails?.end_date}
              </Typography>
            </div>
            <div className={classes.cartDetails}>
              <Typography>Event Time:</Typography>
              <Typography>
                {eventDetails?.start_time} - {eventDetails?.end_time}
              </Typography>
            </div>
            <div className={classes.cartDetails}>
              <Typography>Event Location:</Typography>
              <Typography>{eventDetails?.location}</Typography>
            </div>
            <div className={classes.cartDetails}>
              <Typography>Promo Code:</Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    placeholder="Enter Promo Code"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={validatePromoCode}
                    variant="contained"
                    sx={{ mt: 1, width: "100%" }}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </div>
            {loggedIn ? (
              <Button
                variant="contained"
                onClick={proceedToPayment}
                sx={{
                  mt: 2,
                  mx: "auto",
                  display: "block",
                  width: "80%",
                  backgroundColor: "#ef3e6d",
                  "&:hover": {
                    backgroundColor: "#d43a5b", // Darker shade for hover effect
                  },
                }}
              >
                Pay
              </Button>
            ) : (
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                Please{" "}
                <Button color="primary" onClick={handleShowLogin}>
                  Sign In
                </Button>{" "}
                to proceed with the payment.
              </Typography>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
      >
        <Box className={classes.modalStyle}>
          <Typography variant="h6" className={classes.modalTitle}>
            Payment
          </Typography>
          {loggedIn && (
            <PaymentForm
              //paymentForm={window.Square.payments(SQUARE_APP_ID, LOCATION_ID)}
              eventId={eventDetails?.id}
              eventLocation={eventDetails?.location}
              user={userId}
              deliveryCharges={deliveryFee}
              onPaymentSuccess={handlePaymentSuccess}
              serviceFeeState={serviceFeeState}
              orderType={orderType}
              productsState={customerCart.cart}
              ticketState={ticketState}
              orderDateState={selectedPickupDate}
              orderLaterState={orderLaterState}
              discount={discountedAmount}
              applyPromoCode={promoCode}
              order_time={selectedPickupTime}
              deliveryLocation={deliveryLocation}
              sub_total={customerCart.subtotal}
              total={customerCart.total}
            />
          )}
        </Box>
      </Modal>
      {selectedMeal && (
        <Modal
          open={mealModalOpen}
          onClose={() => setMealModalOpen(false)}
          aria-labelledby="meal-modal-title"
          aria-describedby="meal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Typography
              id="meal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: "center",
                fontWeight: 1000,
                width: "100%",
                margin: "auto",
                display: "block",
                paddingBottom: "10px",
              }}
              className={classes.modalTitle}
            >
              {selectedMeal.name}
            </Typography>
            <Typography
              id="meal-modal-description"
              style={{ mt: 2, textAlign: "center", paddingBottom: "10px" }}
            >
              {selectedMeal.description}
            </Typography>
            <img
              src={
                selectedMeal.image
                  ? AWS_URL + "/" + selectedMeal.image
                  : require("../../assets/img/default_cover.jpg")
              }
              alt={selectedMeal.name}
              style={{
                height: "250px",
                width: "250px",
                objectFit: "contain",
                margin: "auto",
              }}
            />
            <TextField
              margin="normal"
              id="quantity"
              label="Quantity"
              type="number"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={quantity}
              onChange={handleQuantityChange}
              inputProps={{ min: 1, style: { textAlign: "center" } }}
              fullWidth
            />
            {/* Combo Section */}
            {selectedMeal.combo && (
              <>
                <Typography variant="subtitle1">Combo:</Typography>
                {selectedMeal.combo.map((combo) => (
                  <div key={combo.id}>
                    <Checkbox
                      checked={selectedCombos
                        .map((item) => item.id)
                        .includes(combo.id)}
                      onChange={() => handleComboChange(combo, combo.price)}
                    />
                    {combo.name} (+${combo.price})
                  </div>
                ))}
              </>
            )}
            {/* Combo Section */}
            {selectedMeal.subitem && (
              <>
                <Typography variant="subtitle1">Sub Item:</Typography>
                {selectedMeal.subitem.map((subitem) => (
                  <div key={subitem.id}>
                    <Checkbox
                      checked={selectedSubItems
                        .map((item) => item.id)
                        .includes(subitem.id)}
                      onChange={() =>
                        handleSubItemChange(subitem, subitem.price)
                      }
                    />
                    {subitem.name} (+${subitem.price})
                  </div>
                ))}
              </>
            )}
            <TextField
              onChange={(e) => {
                setSelectedMeal((st) => ({
                  ...st,
                  specialRequest: e.target.value,
                }));
              }}
              margin="normal"
              id="special-request"
              label="Special Request"
              variant="outlined"
              fullWidth
            />
            <Box
              style={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => setMealModalOpen(false)}
                variant="outlined"
                style={{ color: "red", borderColor: "red", marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button onClick={addToCart} color="primary" variant="contained">
                Add to Cart
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Event;
