import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react/";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { tagList } from "./tagList";
import ProfileImage from "@daym3l/react-profile-image";
import Media from "react-bootstrap/Media";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { UNITS } from "../../assets/Constants";
import NewStockItemModal from "../VendorMenuEditScreen/NewStockItemModal";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function VendorMenuAddScreen(props) {
  console.log(props);
  const getImages = (base64Image, fileImage) => {
    set_meal_image(base64Image);

    // Convert the base64 image to a suitable format for Clarifai API (if needed)
    // Assuming base64Image is a Data URI format: 'data:image/jpeg;base64,/9j/4AAQSkZJR...'
    const base64 = base64Image.replace(/^data:image\/(.*);base64,/, "");

    // Your Clarifai API details
    const PAT = "2fb343a0690745f39797cf60daf1e5d4";
    const USER_ID = "clarifai";
    const APP_ID = "main";
    const MODEL_ID = "general-image-recognition";
    const MODEL_VERSION_ID = "aa7f35c01e0642fda5cf400f543e7c40";

    const raw = JSON.stringify({
      user_app_id: {
        user_id: USER_ID,
        app_id: APP_ID,
      },
      inputs: [
        {
          data: {
            image: {
              base64: base64,
            },
          },
        },
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Key " + PAT,
      },
      body: raw,
    };

    fetch(
      "https://api.clarifai.com/v2/models/" +
        MODEL_ID +
        "/versions/" +
        MODEL_VERSION_ID +
        "/outputs",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.outputs[0].data.concepts);
        setTags(result.outputs[0].data.concepts);
        checkTags(result.outputs[0].data.concepts, tagList.foodTags);
      })
      .catch((error) => {
        console.error("Clarifai API error", error);
      });
  };
  const checkTags = (tags, foodTags) => {
    console.log("Checking tags:", tags); // Log to see if tags is defined
    console.log("Food tags:", foodTags); // Log to see if foodTags is defined

    const isValid = tags.some((tag) => {
      console.log("Current tag name:", tag.name); // Log the current tag name
      return foodTags && tag.name && foodTags.includes(tag.name.toLowerCase());
    });

    console.log(isValid ? "true" : "false");
    setImageStatus(isValid ? true : false);
  };

  const [showAddItemModal, setShowAddItemModal] = useState(false);

  const [meal_name, set_meal_name] = useState("");
  const [meal_name_error, set_meal_name_error] = useState("");
  const [meal_price, set_meal_price] = useState("");
  const [meal_image, set_meal_image] = useState("");
  const [description, set_description] = useState("");
  const [combo, set_combo] = useState([]);
  const [cuisine_type, set_cuisine_type] = useState("");
  const [meal_status, set_status] = useState("");
  const [imageStatus, setImageStatus] = useState(true);
  const [tags, setTags] = useState([]);
  const [combos, setCombos] = useState([]);
  const [showAddCombo, setShowAddCombo] = useState(false);
  const [subitem, setsubitem] = useState([]);
  const [build_chart, setBuild_chart] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [showAddSubitem, setShowAddSubitem] = useState(false);
  const [showAddBuildChart, setShowAddBuildChart] = useState(false);

  const addCombo = () => {
    setCombos([
      ...combos,
      {
        name: "",
        status: "Enabled", // Default status
        price: "",
      },
    ]);
    setShowAddCombo(false); // Hide the add combo section after adding a new combo
  };

  const addSubitem = () => {
    setsubitem([
      ...subitem,
      {
        name: "",
        status: "Enabled", // Default status
        price: 0,
      },
    ]);
    setShowAddSubitem(false); // Hide the add combo section after adding a new combo
  };
  const addBuildChart = () => {
    setBuild_chart((st) => [
      ...st,
      {
        stock_id: null,
        quantity_per_meal: 0,
        unit: "",
      },
    ]);
    setShowAddBuildChart(false); // Hide the add combo section after adding a new combo
  };

  const handleComboChange = (index, field, value) => {
    const updatedCombos = combos.map((combo, comboIndex) => {
      if (index === comboIndex) {
        return { ...combo, [field]: value };
      }
      return combo;
    });
    console.log(updatedCombos);
    setCombos(updatedCombos);
  };
  const handleSubitemChange = (index, field, value) => {
    const updatedCombos = subitem.map((combo, comboIndex) => {
      if (index === comboIndex) {
        return { ...combo, [field]: value };
      }
      return combo;
    });
    console.log(updatedCombos);
    setsubitem(updatedCombos);
  };
  const handleBuildChartChange = (index, field, value) => {
    console.log("Item");
    const updatedBuildChart = build_chart.map((chart, idx) => {
      if (index === idx) {
        return { ...chart, [field]: value };
      }
      return chart;
    });
    console.log(updatedBuildChart);
    setBuild_chart(updatedBuildChart);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_meal_categories",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(response.meal_categories);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const fetchClarifaiResults = (imageUrl) => {
    const PAT = "b3d52504a2b841b68e6cba920113c250"; // Your Personal Access Token
    const USER_ID = "clarifai"; // The user ID
    const APP_ID = "main"; // The app ID
    const MODEL_ID = "general-image-recognition"; // The model ID
    const MODEL_VERSION_ID = "aa7f35c01e0642fda5cf400f543e7c40"; // The model version ID

    const raw = JSON.stringify({
      user_app_id: {
        user_id: USER_ID,
        app_id: APP_ID,
      },
      inputs: [
        {
          data: {
            image: {
              url: imageUrl,
            },
          },
        },
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Key " + PAT,
      },
      body: raw,
    };

    fetch(
      "https://api.clarifai.com/v2/models/" +
        MODEL_ID +
        "/versions/" +
        MODEL_VERSION_ID +
        "/outputs",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        // Here you could set the result to state or perform other actions with it
      })
      .catch((error) => console.log("error", error));
  };

  const postAddMeal = async (values) => {
    values.preventDefault();
    if (
      meal_name === "" ||
      meal_price === "" ||
      meal_status === "" ||
      cuisine_type === "" ||
      description === ""
    ) {
      alert("Please Enter all the Fields");
    } else {
      const validCombos = combos.filter((combo) => combo.name && combo.price);
      const validSubItem = subitem.filter((combo) => combo.name);
      const url = config.BASE_URI + apiPaths.adminData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      console.log(build_chart);

      const operatorData =
        JSON.parse(getFromLocalStorage("Operator")) &&
        JSON.parse(getFromLocalStorage("Operator")).length
          ? JSON.parse(getFromLocalStorage("Operator"))[0]
          : {};

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "add_menu",
            meal_name: meal_name,
            description: description,
            image: meal_image,
            meal_price: parseFloat(meal_price),
            category: parseInt(cuisine_type),
            meal_status: meal_status,
            vendor_id: props.stallId,
            operator_id: operatorData ? operatorData.id : null,
            // build_chart: [
            //   {
            //     stock_id: 1,
            //     quantity_per_meal: 20,
            //     unit: "Grams",
            //   },
            // ],
            build_chart: build_chart,
            image_status: imageStatus ? "Enabled" : "Waiting for review",
            meal_combo: validCombos,
            sub_item: validSubItem,
          },
        },
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success == true) {
            alert("Cuisine added successfully!");
            window.location.reload();
            // window.location.href = "/apply-events/events-menu";
          } else {
            alert("Adding menu is unsuccessful, Please try again");
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert("Adding menu is unsuccessful, Please try after some time");
        });
    }
  };
  const handleMealNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_meal_name_error("Please enter only letters and spaces");
    } else {
      set_meal_name(inputValue);
      set_meal_name_error("");
    }
  };
  const handleMealPriceChange = (event) => {
    const inputValue = event.target.value;
    set_meal_price(inputValue);
  };
  const handleMealImageChange = (event) => {
    set_meal_image(event.target.files[0]);
  };
  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value;
    set_description(inputValue);
  };
  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };
  const handleStatusChange = (event) => {
    set_status(event.target.value);
  };

  const getStockData = () => {
    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_stocklist",
          operator_id: operatorData ? operatorData.id : null,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "REs");
        if (dataResponse.success == true) {
          setStockList(dataResponse.stocklist);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  useEffect(() => {
    getAvailableCuisines();
    getStockData();
  }, []);

  return (
    <Container>
      <div className="form-style-5">
        <form onSubmit={postAddMeal}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Name:"
                type="text"
                id="meal_name"
                name="meal_name"
                value={meal_name}
                onChange={handleMealNameChange}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Price:"
                type="number"
                id="meal_price"
                name="meal_price"
                value={meal_price}
                onChange={handleMealPriceChange}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleCuisineTypeChange}>
                <option value="" disabled>
                  Select cuisine type
                </option>
                {availableCuisines.map((cuisine) => (
                  <option key={cuisine.id} value={cuisine.id}>
                    {cuisine.title}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleStatusChange}>
                <option value="" disabled>
                  Status
                </option>
                <option value={"Enabled"}>Enabled</option>
                <option value={"Disabled"}>Disabled</option>
              </select>
            </Col>
          </Row>
          <label htmlFor="logoFile">Upload the Meal Image:</label>
          <ProfileImage
            camera={false}
            returnImage={(base64Image, fileImage) => {
              getImages(base64Image, fileImage);
            }}
            uploadBtnProps={{ variant: "contained", label: "Upload" }}
            defaultImage={require("../../assets/img/empty_logo.png")}
            style={{ width: 100, height: 100 }}
          />
          {/* <div className="image-tags">
            Image Tags:
            {tags.slice(0, 3).map((tag, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}"{tag.name}"
              </React.Fragment>
            ))}
          </div> */}
          {!imageStatus && (
            <div>
              <span style={{ color: "red" }}>
                Your image is currently under review. Please ensure that the
                photograph accurately depicts the food item you are offering.
                Non-compliance may result in the removal of this item from
                trading, and in some cases, it could lead to disqualification by
                the event coordinators.*
              </span>
            </div>
          )}
          <Row
            style={{
              paddingBottom: 5,
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Description:"
                type="text"
                rows="4"
                cols="75"
                onChange={handleDescriptionChange}
              ></textarea>
            </Col>
          </Row>
          <Media
            key={1}
            style={{
              borderBottomWidth: 1,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          ></Media>
          <Row style={{ paddingBottom: 10, paddingTop: 5 }}>
            <Col lg={12} md={12} sm={12}>
              {combos.map((combo, index) => (
                <Row key={index} style={{ paddingBottom: 10 }}>
                  <Col lg={4} md={4} sm={12}>
                    <input
                      placeholder="Combo Name"
                      type="text"
                      value={combo.name}
                      onChange={(e) =>
                        handleComboChange(index, "name", e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <input
                      placeholder="Price"
                      type="number"
                      value={combo.price}
                      onChange={(e) =>
                        handleComboChange(
                          index,
                          "price",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <select
                      value={combo.status}
                      onChange={(e) =>
                        handleComboChange(index, "status", e.target.value)
                      }
                    >
                      <option value="Enabled">Enabled</option>
                      <option value="Disabled">Disabled</option>
                    </select>
                  </Col>
                </Row>
              ))}
              <Button onClick={addCombo}>Add Combo</Button>
            </Col>
          </Row>
          <Media
            key={1}
            style={{
              borderBottomWidth: 1,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          ></Media>
          <Row style={{ paddingBottom: 10, paddingTop: 5 }}>
            <Col lg={12} md={12} sm={12}>
              {subitem.map((item, index) => (
                <Row key={index} style={{ paddingBottom: 10 }}>
                  <Col lg={4} md={4} sm={12}>
                    <input
                      placeholder="Item Name"
                      type="text"
                      value={item.name}
                      onChange={(e) =>
                        handleSubitemChange(index, "name", e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <input
                      placeholder="Price"
                      type="number"
                      onChange={(e) =>
                        handleSubitemChange(index, "price", e.target.value)
                      }
                      value={item.price}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <select
                      value={item.status}
                      onChange={(e) =>
                        handleSubitemChange(index, "status", e.target.value)
                      }
                    >
                      <option value="Enabled">Enabled</option>
                      <option value="Disabled">Disabled</option>
                    </select>
                  </Col>
                </Row>
              ))}
              <Button onClick={addSubitem}>Add Sub Item</Button>
            </Col>
          </Row>
          <Media
            key={1}
            style={{
              borderBottomWidth: 1,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          ></Media>
          <NewStockItemModal
            handleAddItemSuccess={() => {
              getStockData();
              alert("New Item has been added successfully.");
            }}
            vendorId={Number(props.stallId)}
            showModal={showAddItemModal}
            setShowModal={setShowAddItemModal}
          />
          <Row style={{ paddingBottom: 10, paddingTop: 5 }}>
            <Col lg={12} md={12} sm={12}>
              {build_chart.map((item, index) => (
                <Row key={index} style={{ paddingBottom: 10 }}>
                  <Col lg={4} md={4} sm={12}>
                    <select
                      value={item.stock_id}
                      onChange={(e) => {
                        if (e.target.value === "new") {
                          setShowAddItemModal(true);
                          return;
                        }
                        handleBuildChartChange(
                          index,
                          "stock_id",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select Stock Item</option>
                      <option
                        style={{
                          color: "#1876D1",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        value="new"
                      >
                        Add a New Item
                      </option>

                      {stockList && stockList.length
                        ? stockList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <input
                      placeholder="Quantity per meal"
                      type="number"
                      onChange={(e) =>
                        handleBuildChartChange(
                          index,
                          "quantity_per_meal",
                          e.target.value
                        )
                      }
                      value={item.quantity_per_meal}
                    />
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <select
                      placeholder="Unit"
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) =>
                        handleBuildChartChange(index, "unit", e.target.value)
                      }
                      value={item.unit}
                    >
                      {UNITS.map((unitItem) => (
                        <option
                          style={{ textTransform: "capitalize" }}
                          key={unitItem}
                          value={unitItem}
                        >
                          {unitItem}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              ))}
              <Button onClick={addBuildChart}>Add Build Chart</Button>
            </Col>
          </Row>

          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}
export default Sentry.withErrorBoundary(VendorMenuAddScreen, {
  fallback: <ErrorFallbackComponent />,
});
