import React, { useState, useEffect } from "react";
import config from "../../../services/apiConfig";
import { Button } from "@material-ui/core";
import { apiPaths } from "../../../services/apiPath";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "react-bootstrap/Navbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../../store";
import "./style.css";
import moment from "moment";
import useFecth from "../../../hook/useFecth";
import ProfileImage from "@daym3l/react-profile-image";
const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function AlertBox(props) {
  const selectedEvent = props.selectedEvent;
  const selectedState = "";
  const melbourne = [
    "Greater Melbourne",
    "Northern Melbourne Suburbs",
    "Southern Melbourne Suburbs",
    "Western Melbourne Suburbs",
    "Eastern Melbourne Suburbs",
    "Barwon South West",
    "Gippsland",
    "Grampians",
    "Hume",
    "Loddon Mallee",
  ];
  const victoria = [
    "Greater Melbourne",
    "Northern Melbourne Suburbs",
    "Southern Melbourne Suburbs",
    "Western Melbourne Suburbs",
    "Eastern Melbourne Suburbs",
    "Barwon South West",
    "Gippsland",
    "Grampians",
    "Hume",
    "Loddon Mallee",
  ];
  const newSouthWales = [
    "Greater Sydney",
    "Newcastle and Lake Macquarie",
    "Illawarra",
    "Hunter Valley excluding Newcastle",
    "Richmond Tweed",
    "Capital region",
    "Mid North Coast",
    "Central West",
    "New England and North West",
    "Riverina",
    "Southern Highlands and Shoalhaven",
    "Coffs Harbour-Grafton",
    "Far West and Orana",
    "Murray",
  ];
  const queensland = [
    "Brisbane",
    "Logan City",
    "Gold Coast",
    "Sunshine Coast",
    "Ipswich",
    "Lockyer Valley",
    "South East Queensland",
    "Darling Downs",
    "Wide Bay–Burnett",
    "Central Queensland",
    "Mackay, Isaac & Whitsunday",
    "North Queensland",
    "Far North Queensland",
  ];
  const southAustralia = [
    "Adelaide",
    "Outer Adelaide",
    "Yorke and Lower North",
    "Murray Lands",
    "South East",
    "Eyre",
    "Northern",
    "Offshore & Migratory",
  ];
  const northernTerritory = [
    "Darwin metropolitan",
    "Daly–Tiwi–West Arnhem",
    "East Arnhem",
    "Katherine",
    "Barkly",
    "Alice Springs",
  ];
  const westernAustralia = [
    "Gascoyne",
    "Goldfields–Esperance",
    "Great Southern",
    "Kimberley",
    "Mid West",
    "Peel",
    "Pilbara",
    "South West",
    "Wheatbelt",
  ];
  const australianCapitalTerritory = ["Bean", "Canberra", "Fenner"];
  const tasmania = ["Hobart", "Bass", "Braddon", "Clark", "Franklin", "Lyons"];
  const [isAllDivisionsSelected, setIsAllDivisionsSelected] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedVictoria, setSelectedVictoria] = useState(victoria);
  const isAllSelectedVictoria = selectedVictoria.length === victoria.length;
  const [openAlert, setOpenAlert] = useState(false);
  const handleAlertClick = () => {
    setOpenAlert(true);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleConfirm = () => {
    const value = [];
    if (selectedAustralianCapitalTerritory.length >= 4) {
      value.push(4);
    }
    if (selectedVictoria.length >= 1) {
      value.push(1);
    }
    if (selectedTasmania.length >= 1) {
      value.push(3);
    }
    if (selectedWesternAustralia.length >= 1) {
      value.push(8);
    }
    if (selectedNorthernTerritory.length >= 1) {
      value.push(5);
    }
    if (selectedNewSouthWales.length >= 1) {
      value.push(2);
    }
    if (selectedSouthAustralia.length >= 1) {
      value.push(7);
    }
    if (selectedQueensland.length >= 1) {
      value.push(6);
    }
    console.log(value);

    if (value) {
      const url = config.BASE_URI + apiPaths.organiserData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "alert_vendor_for_eoi",
            eoi_id: selectedEvent.id,
            vendor_id: 0,
            state_id: value,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success === true) {
            alert(
              `Alerted the selected regional vendors for ${selectedEvent.eoi_event_name}`
            );
          } else {
            alert(dataResponse.message);
          }
        })
        .catch((err) => {
          alert("Please Try again!");
        });
    }
    setOpenAlert(false);
  };
  const [selectedNewSouthWales, setSelectedNewSouthWales] = useState(
    newSouthWales
  );
  const isAllSelectedNewSouthWales =
    selectedNewSouthWales.length === newSouthWales.length;

  const [selectedQueensland, setSelectedQueensland] = useState(queensland);
  const isAllSelectedQueensland =
    selectedQueensland.length === queensland.length;

  const [selectedSouthAustralia, setSelectedSouthAustralia] = useState(
    southAustralia
  );
  const isAllSelectedSouthAustralia =
    selectedSouthAustralia.length === southAustralia.length;

  const [selectedWesternAustralia, setSelectedWesternAustralia] = useState(
    westernAustralia
  );
  const isAllSelectedWesternAustralia =
    selectedWesternAustralia.length === westernAustralia.length;

  const [selectedTasmania, setSelectedTasmania] = useState(tasmania);
  const isAllSelectedTasmania = selectedTasmania.length === tasmania.length;

  const [selectedNorthernTerritory, setSelectedNorthernTerritory] = useState(
    northernTerritory
  );
  const isAllSelectedNorthernTerritory =
    selectedNorthernTerritory.length === northernTerritory.length;

  const [
    selectedAustralianCapitalTerritory,
    setSelectedAustralianCapitalTerritory,
  ] = useState(australianCapitalTerritory);
  const isAllSelectedAustralianCapitalTerritory =
    selectedAustralianCapitalTerritory.length ===
    australianCapitalTerritory.length;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleFormClose = () => {
    setShowForm(false);
  };

  const handleToggleAllDivisions = () => {
    if (isAllDivisionsSelected) {
      // Unselect all divisions of all states
      setSelectedNewSouthWales([]);
      setSelectedVictoria([]);
      setSelectedQueensland([]);
      setSelectedSouthAustralia([]);
      setSelectedWesternAustralia([]);
      setSelectedTasmania([]);
      setSelectedAustralianCapitalTerritory([]);
      setSelectedNorthernTerritory([]);
    } else {
      // Select all divisions of all states
      setSelectedNewSouthWales(newSouthWales);
      setSelectedVictoria(victoria);
      setSelectedQueensland(queensland);
      setSelectedSouthAustralia(southAustralia);
      setSelectedWesternAustralia(westernAustralia);
      setSelectedTasmania(tasmania);
      setSelectedAustralianCapitalTerritory(australianCapitalTerritory);
      setSelectedNorthernTerritory(northernTerritory);
    }

    setIsAllDivisionsSelected(!isAllDivisionsSelected);
  };

  // Victoria
  const handleToggleAllVictoria = () => {
    if (selectedVictoria.length === victoria.length) {
      setSelectedVictoria([]);
    } else {
      setSelectedVictoria(victoria);
    }
  };

  const handleToggleVictoria = (victoriaItem) => {
    if (selectedVictoria.includes(victoriaItem)) {
      setSelectedVictoria((prevSelectedVictoria) =>
        prevSelectedVictoria.filter((item) => item !== victoriaItem)
      );
    } else {
      setSelectedVictoria((prevSelectedVictoria) => [
        ...prevSelectedVictoria,
        victoriaItem,
      ]);
    }
  };

  // New South Wales
  const handleToggleAllNewSouthWales = () => {
    if (selectedNewSouthWales.length === newSouthWales.length) {
      setSelectedNewSouthWales([]);
    } else {
      setSelectedNewSouthWales(newSouthWales);
    }
  };

  const handleToggleNewSouthWales = (newSouthWalesItem) => {
    if (selectedNewSouthWales.includes(newSouthWalesItem)) {
      setSelectedNewSouthWales((prevSelectedNewSouthWales) =>
        prevSelectedNewSouthWales.filter((item) => item !== newSouthWalesItem)
      );
    } else {
      setSelectedNewSouthWales((prevSelectedNewSouthWales) => [
        ...prevSelectedNewSouthWales,
        newSouthWalesItem,
      ]);
    }
  };

  // Queensland
  const handleToggleAllQueensland = () => {
    if (selectedQueensland.length === queensland.length) {
      setSelectedQueensland([]);
    } else {
      setSelectedQueensland(queensland);
    }
  };

  const handleToggleQueensland = (queenslandItem) => {
    if (selectedQueensland.includes(queenslandItem)) {
      setSelectedQueensland((prevSelectedQueensland) =>
        prevSelectedQueensland.filter((item) => item !== queenslandItem)
      );
    } else {
      setSelectedQueensland((prevSelectedQueensland) => [
        ...prevSelectedQueensland,
        queenslandItem,
      ]);
    }
  };

  // Western Australia
  const handleToggleAllWesternAustralia = () => {
    if (selectedWesternAustralia.length === westernAustralia.length) {
      setSelectedWesternAustralia([]);
    } else {
      setSelectedWesternAustralia(westernAustralia);
    }
  };

  const handleToggleWesternAustralia = (westernAustraliaItem) => {
    if (selectedWesternAustralia.includes(westernAustraliaItem)) {
      setSelectedWesternAustralia((prevSelectedWesternAustralia) =>
        prevSelectedWesternAustralia.filter(
          (item) => item !== westernAustraliaItem
        )
      );
    } else {
      setSelectedWesternAustralia((prevSelectedWesternAustralia) => [
        ...prevSelectedWesternAustralia,
        westernAustraliaItem,
      ]);
    }
  };

  // South Australia
  const handleToggleAllSouthAustralia = () => {
    if (selectedSouthAustralia.length === southAustralia.length) {
      setSelectedSouthAustralia([]);
    } else {
      setSelectedSouthAustralia(southAustralia);
    }
  };

  const handleToggleSouthAustralia = (southAustraliaItem) => {
    if (selectedSouthAustralia.includes(southAustraliaItem)) {
      setSelectedSouthAustralia((prevSelectedSouthAustralia) =>
        prevSelectedSouthAustralia.filter((item) => item !== southAustraliaItem)
      );
    } else {
      setSelectedSouthAustralia((prevSelectedSouthAustralia) => [
        ...prevSelectedSouthAustralia,
        southAustraliaItem,
      ]);
    }
  };

  // Tasmania
  const handleToggleAllTasmania = () => {
    if (selectedTasmania.length === tasmania.length) {
      setSelectedTasmania([]);
    } else {
      setSelectedTasmania(tasmania);
    }
  };

  const handleToggleTasmania = (tasmaniaItem) => {
    if (selectedTasmania.includes(tasmaniaItem)) {
      setSelectedTasmania((prevSelectedTasmania) =>
        prevSelectedTasmania.filter((item) => item !== tasmaniaItem)
      );
    } else {
      setSelectedTasmania((prevSelectedTasmania) => [
        ...prevSelectedTasmania,
        tasmaniaItem,
      ]);
    }
  };

  // Northern Territory
  const handleToggleAllNorthernTerritory = () => {
    if (selectedNorthernTerritory.length === northernTerritory.length) {
      setSelectedNorthernTerritory([]);
    } else {
      setSelectedNorthernTerritory(northernTerritory);
    }
  };

  const handleToggleNorthernTerritory = (northernTerritoryItem) => {
    if (selectedNorthernTerritory.includes(northernTerritoryItem)) {
      setSelectedNorthernTerritory((prevSelectedNorthernTerritory) =>
        prevSelectedNorthernTerritory.filter(
          (item) => item !== northernTerritoryItem
        )
      );
    } else {
      setSelectedNorthernTerritory((prevSelectedNorthernTerritory) => [
        ...prevSelectedNorthernTerritory,
        northernTerritoryItem,
      ]);
    }
  };

  // Australian Capital Territory
  const handleToggleAllAustralianCapitalTerritory = () => {
    if (
      selectedAustralianCapitalTerritory.length ===
      australianCapitalTerritory.length
    ) {
      setSelectedAustralianCapitalTerritory([]);
    } else {
      setSelectedAustralianCapitalTerritory(australianCapitalTerritory);
    }
  };

  const handleToggleAustralianCapitalTerritory = (
    australianCapitalTerritoryItem
  ) => {
    if (
      selectedAustralianCapitalTerritory.includes(
        australianCapitalTerritoryItem
      )
    ) {
      setSelectedAustralianCapitalTerritory(
        (prevSelectedAustralianCapitalTerritory) =>
          prevSelectedAustralianCapitalTerritory.filter(
            (item) => item !== australianCapitalTerritoryItem
          )
      );
    } else {
      setSelectedAustralianCapitalTerritory(
        (prevSelectedAustralianCapitalTerritory) => [
          ...prevSelectedAustralianCapitalTerritory,
          australianCapitalTerritoryItem,
        ]
      );
    }
  };

  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        className="button-report"
        style={{
          background: "#ef3e6d",
          backgroundColor: "#ef3e6d",
          borderRadius: "6px",
          border: "1px solid #ef3e6d",
          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Reach vendors by Location
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showForm}
        onClose={handleFormClose}
        className="modalnvm"
      >
        <Box className="modalnvm-content">
          <CloseIcon
            className="plzHover"
            fontSize="large"
            style={{ margin: 10, float: "right" }}
            onClick={handleFormClose}
          />
          <div className="form-style-5">
            <legend>
              <span className="number">Select Vendor Locations</span>
            </legend>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllDivisionsSelected}
              onChange={handleToggleAllDivisions}
            />

            <h4>Victoria</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedVictoria}
              onChange={handleToggleAllVictoria}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {victoria.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`victoria-${item}`}
                  label={item}
                  checked={selectedVictoria.includes(item)}
                  onChange={() => handleToggleVictoria(item)}
                />
              ))}
            </div>
            <h4>New South Wales</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedNewSouthWales}
              onChange={handleToggleAllNewSouthWales}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {newSouthWales.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`newSouthWales-${item}`}
                  label={item}
                  checked={selectedNewSouthWales.includes(item)}
                  onChange={() => handleToggleNewSouthWales(item)}
                />
              ))}
            </div>
            <h4>Queensland</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedQueensland}
              onChange={handleToggleAllQueensland}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {queensland.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`queensland-${item}`}
                  label={item}
                  checked={selectedQueensland.includes(item)}
                  onChange={() => handleToggleQueensland(item)}
                />
              ))}
            </div>
            <h4>Western Australia</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedWesternAustralia}
              onChange={handleToggleAllWesternAustralia}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {westernAustralia.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`westernAustralia-${item}`}
                  label={item}
                  checked={selectedWesternAustralia.includes(item)}
                  onChange={() => handleToggleWesternAustralia(item)}
                />
              ))}
            </div>
            <h4>South Australia</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedSouthAustralia}
              onChange={handleToggleAllSouthAustralia}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {southAustralia.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`southAustralia-${item}`}
                  label={item}
                  checked={selectedSouthAustralia.includes(item)}
                  onChange={() => handleToggleSouthAustralia(item)}
                />
              ))}
            </div>
            <h4>Tasmania</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedTasmania}
              onChange={handleToggleAllTasmania}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {tasmania.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`tasmania-${item}`}
                  label={item}
                  checked={selectedTasmania.includes(item)}
                  onChange={() => handleToggleTasmania(item)}
                />
              ))}
            </div>
            <h4>Australian Capital Territory</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedAustralianCapitalTerritory}
              onChange={handleToggleAllAustralianCapitalTerritory}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {australianCapitalTerritory.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`australianCapitalTerritory-${item}`}
                  label={item}
                  checked={selectedAustralianCapitalTerritory.includes(item)}
                  onChange={() => handleToggleAustralianCapitalTerritory(item)}
                />
              ))}
            </div>
            <h4>Northern Territory</h4>
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelectedNorthernTerritory}
              onChange={handleToggleAllNorthernTerritory}
            />
            <div style={{ height: "200px", overflowY: "auto" }}>
              {northernTerritory.map((item) => (
                <Form.Check
                  key={item}
                  type="checkbox"
                  id={`northernTerritory-${item}`}
                  label={item}
                  checked={selectedNorthernTerritory.includes(item)}
                  onChange={() => handleToggleNorthernTerritory(item)}
                />
              ))}
            </div>
            <Button
              onClick={handleAlertClick}
              className="button-report"
              style={{
                background: "#ef3e6d",
                backgroundColor: "#ef3e6d",
                borderRadius: "6px",
                border: "1px solid #ef3e6d",
                display: "inline-block",
                cursor: "pointer",
                color: "#ffffff",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "6px 24px",
                textDecoration: "none",
              }}
            >
              Send Alert
            </Button>
            {/* <AlertDialog
              open={openAlert}
              handleClose={() => setOpenAlert(false)} // Close the dialog on Cancel
              handleConfirm={handleConfirm} // Handle Confirm action and API call
            /> */}
            <Dialog
              open={openAlert}
              onClose={() => setOpenAlert(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirmation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to alert all the vendors in the selected regions
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default AlertBox;
