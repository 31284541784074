import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export function EventOrganiserBarGraph({ vendorName, vendorData }) {
  console.log(vendorName, vendorData);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const itemsLabel = [];
  const itemsData = [];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Sales by Vendors",
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        label: function(tooltips, data) {
          return "$" + tooltips.yLabel;
        },
      },
    },
  };

  const data = {
    labels: vendorName,
    datasets: [
      {
        data: vendorData,
        backgroundColor: [
          "#488f31",
          "#fde987",
          "#f9b25f",
          "#ec7a4f",
          "#de425b",
          "#63ab70",
          "#adcc76",
          "#8f2d56",
          "#d95763",
          "#f2a154",
          "#f9d9a6",
          "#a2d6c4",
          "#4f86c6",
          "#f06eaa",
          "#5e5d5c",
        ],
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
