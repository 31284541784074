import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";

import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";
import EditableForm from "./EditableForm";

class AdminEditRunsheets extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    vendors: [],
    nonCuisineVendors: [],

    availableStatus: ["processing", "rejected", "approved", "Finalized Paid"],
    selectedBooking: null,
    selectedBookingId: null,
    isLoadingVendor: false,
    searchVendor: "",
    selectedCuisine: "",
    isLoading: false,
    requestState: "all",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn, isEventCoordinatorLoggedIn } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getDashboardData(this.state.requestState);
    } else {
      window.location.href = "/admin";
    }
  }

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_vendor_calender_data",
          start_date: startDate,
          end_date: endDate,
          vendor_id: "0",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          // Sort the bookingRequests array based on start_date and end_date
          const sortedBookingRequests = dataResponse.events.sort((a, b) => {
            const startDateA = moment(a.event_date);
            const startDateB = moment(b.event_date);
            const endDateA = moment(a.bump_out_date);
            const endDateB = moment(b.bump_out_date);

            if (startDateA.isBefore(startDateB)) return -1;
            if (startDateA.isAfter(startDateB)) return 1;

            // If start dates are equal, compare end dates
            if (endDateA.isBefore(endDateB)) return -1;
            if (endDateA.isAfter(endDateB)) return 1;

            return 0;
          });

          console.log(sortedBookingRequests);
          sortedBookingRequests.reverse();
          console.log(sortedBookingRequests);
          this.setState({ bookingRequests: sortedBookingRequests });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  handleBookingRequestChange = (event) => {
    const selectedId = event.target.value;
    const selectedBooking = this.state.bookingRequests.find(
      (request) => request.id.toString() === selectedId
    );

    this.setState(
      {
        selectedBookingId: selectedId,
        selectedBooking: selectedBooking,
      },
      () => {
        console.log(this.state.selectedBooking);
      }
    );
  };
  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}

      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      availableStatus,
      isLoading,
      requestState,
      startDate,
      endDate,
      pageSelected,
      selectedBooking,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <b>Filter</b>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.setState({ requestState: e.target.value });
                        this.getDashboardData(e.target.value);
                      }}
                      defaultValue={requestState}
                    >
                      <option value={"all"}>All</option>
                      <option value={"rejected"}>Rejected</option>
                      <option value={"processing"}>Pending</option>
                      <option value={"approved"}>Accepted</option>
                      <option value={"Finalized Paid"}>Paid</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <b>Requested Runsheets in Date Range:</b>
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={12} md={4}>
                      <p>Start Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={startDate}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <p>End Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={endDate}
                        onChange={(date) => this.setEndDate(date)}
                      />
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p>&nbsp;</p>
                      <Button
                        onClick={() => {
                          this.setState({ resetFilter: "false" }, () => {
                            saveToLocalStorage(
                              "resetFilter",
                              this.state.resetFilter.toString()
                            );
                            this.getDashboardData(this.state.requestState);
                          });
                        }}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={8} md={4} lg={4}>
                  {this.state.resetFilter == "false" ? (
                    <p>
                      By clicking this button, you can reset all filters and it
                      will show you all the booking request in reverser
                      chronoligical order.
                    </p>
                  ) : (
                    <p>
                      Showing Event Organiser Account requests in the order they
                      were made, filters are not applied. To apply filter you
                      can change the Requested Event Date.
                    </p>
                  )}
                  {this.state.resetFilter == "true" ? (
                    <p>
                      <b>
                        Note: When filters are not set, it will only show you
                        details of all upcoming events. It wont show you events
                        whose dates have passed.
                      </b>
                    </p>
                  ) : null}
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button
                    onClick={() => {
                      this.setState({ resetFilter: "true" }, () => {
                        saveToLocalStorage(
                          "resetFilter",
                          this.state.resetFilter.toString()
                        );
                        this.getDashboardData(this.state.requestState);
                      });
                    }}
                  >
                    {this.state.resetFilter == "true"
                      ? "Filters are not Set"
                      : "Reset Filters"}
                  </Button>
                </Col>
              </Row>
              <Form.Group controlId="bookingRequestSelect">
                <Form.Label>Select Booking Request</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.selectedBookingId}
                  onChange={this.handleBookingRequestChange}
                >
                  <option value="">Select a booking...</option>
                  {bookingRequests.map((request) => (
                    <option key={request.id} value={request.id}>
                      {request.id} - {request.event_name} -{" "}
                      {request.vendor__title}{" "}
                      {moment(request.event_date).format("DD MMM YYYY")}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedBooking && (
                <EditableForm selectedBooking={selectedBooking} />
              )}
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(AdminEditRunsheets);
