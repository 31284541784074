import React, { Component } from "react";
import { Table, Container, Spinner, Button, Form } from "react-bootstrap";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import "./style.css";

class AdminPendingMenuScreen extends Component {
  state = {
    isLoading: true,
    menuItems: [],
    isStatusUpdating: false,
    selectedMenuItemId: null,
  };

  componentDidMount() {
    this.fetchMenuData();
  }

  fetchMenuData = () => {
    const url = `${config.BASE_URI}${apiPaths.organiserData}`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_pending_menu_updates",
          event_id: "1291",
          vendor_id: "827",
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ menuItems: dataResponse.menu, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error fetching menu data:", error);
        this.setState({ isLoading: false });
      });
  };

  handleStatusChange = (menuItemId, status) => {
    this.setState({ isStatusUpdating: true, selectedMenuItemId: menuItemId });

    const url = `${config.BASE_URI}${apiPaths.organiserData}`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "approve_menu_request",
          menu_item_id: menuItemId,
          status: status,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isStatusUpdating: false, selectedMenuItemId: null });
        if (dataResponse.success) {
          alert(`Status updated to ${status} successfully!`);
          this.fetchMenuData();
        } else {
          alert("Error updating status. Please try again.");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error updating menu status:", error);
        this.setState({ isStatusUpdating: false, selectedMenuItemId: null });
      });
  };

  renderMenuTable() {
    const { menuItems, isStatusUpdating, selectedMenuItemId } = this.state;

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Menu Item</th>
            <th>Price</th>
            <th>Vendor</th>
            <th>Event</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {menuItems.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>${item.price}</td>
              <td>{item.vendor__title}</td>
              <td>{item.event__name}</td>
              <td>
                <Form.Control
                  as="select"
                  value={item.status || "Waiting in Review"}
                  onChange={(e) => this.handleStatusChange(item.id, e.target.value)}
                  disabled={isStatusUpdating && selectedMenuItemId === item.id}
                >
                  <option value="Waiting in Review">Waiting in Review</option>
                  <option value="Enabled">Enable</option>
                  <option value="Rejected">Reject</option>
                </Form.Control>
              </td>
              <td>
                <Button
                  variant="success"
                  onClick={() => this.handleStatusChange(item.id, "Enabled")}
                  disabled={isStatusUpdating && selectedMenuItemId === item.id}
                >
                  Accept
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() => this.handleStatusChange(item.id, "Rejected")}
                  disabled={isStatusUpdating && selectedMenuItemId === item.id}
                >
                  Reject
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <AdminNavBar />
        <Container>
          <h2 className="mt-4 mb-4">Pending Menu Items</h2>
          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            this.renderMenuTable()
          )}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Sentry.withErrorBoundary(AdminPendingMenuScreen, {
  fallback: <ErrorFallbackComponent />,
});
