import React, { Component, useCallback } from "react";
import SimpleMap from "../../components/GoogleMapComponent/index";
import Footer from "../../components/UI/Footer";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
import "./style.css";
import {
  Grid,
  Typography,
  Container,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Button,
  Box,
} from "@material-ui/core";
import Sel from "react-select";

import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { InputGroup, Form } from "react-bootstrap";
import { FormControl as FC } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { apiPaths } from "../../services/apiPath";
import AdminNavBar from "../../components/UI/AdminNavBar";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import config from "../../services/apiConfig";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import {
  getComfirmedVendors,
  getSelectedEoi,
} from "../../actions/EventOrganiser/action";
import { AWS_URL } from "../../assets/Constants";
class OrganiserSubaccount extends Component {
  state = {
    isDialogVisible: false,
    isSuperAdminLoggedIn: "false",
    selectedVendorIds: [],
    isLoading: false,
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    site_area: [],
    events_for_organiser: [],
    site_area_list: [],
    events_list: [],
    acceptedVendorData: [],
    eventList: [],
    isOpen: false,
    selectedVendor: {},
    confirmVendors: [],
    noticeOpen: false,
    contractNoticeOpen: false,
    missingDoc: [],
    selectEvent: {},
    defaultLink: "../..assets/img/welcome-logo.png",
  };
  handleSiteAreaChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ site_area: selectedIds });
  };
  handleEventsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ events_for_organiser: selectedIds });
  };
  toggleDialog = () => {
    this.setState((prevState) => ({
      isDialogVisible: !prevState.isDialogVisible,
    }));
  };

  acceptVendors = async () => {
    const url = config.devUrl + apiPaths.adminData;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "accepted_vendor_outlets",
          event_id: this.state.selectEvent.id,
          accepted_vendor_ids: this.state.selectedVendorIds,
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      alert("Applying please wait!");

      if (result.success === true) {
        alert("Apply succeeded!");
        this.setState({ selectedVendorIds: [] });
        this.getTargetedEvents(this.state.selectEvent.id);
        this.getTargetedAcceptedEvents(this.state.selectEvent.id);
      } else {
        alert("The application failed. Please try later");
        this.getTargetedEvents(this.state.selectEvent.id);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      alert("The application failed. Please try later");
      this.setState({ selectedVendorIds: [] });
    }
  };

  getAcceptedVendors = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ acceptedVendorData: vendorList });
      return;
    }
    let applyVendor = [];

    vendorList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.accepted_outlets;

      fetch(vendorUrl, {
        method: "GET",
        redirect: "follow",
      }).then((response) =>
        response
          .json()
          .then((responseData) => {
            console.log(responseData);
            let isIdPresent = false;
            if (applyVendor) {
              for (let i = 0; i < applyVendor.length; i++) {
                if (applyVendor[i].id === responseData.id) {
                  isIdPresent = true;
                  break;
                }
              }
              if (!isIdPresent) {
                applyVendor.push(responseData);
              }
            }
          })
          .then((response) => {
            console.log(applyVendor);
            this.setState({ acceptedVendorData: applyVendor });
          })
      );
    });
  };

  getTargetedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_applied_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const vendorList = response.event_application;
        console.log(vendorList);
        this.getAllVendors(vendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedAcceptedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_accepted_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const acceptedVendorList = response.accepted_applications;
        this.getAcceptedVendors(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedRejectedEvents = (id) => {
    this.setState({ isLoading: true });
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_rejected_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ isLoading: false });
        const acceptedVendorList = response.rejected_applications;
        this.getRejected(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };
  handleAddStakeholderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId, email } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "create_organiser_subaccount",
          email: this.state.email,
          stakeholder_first_name: this.state.stakeholder_first_name,
          stakeholder_last_name: this.state.stakeholder_last_name,
          stakeholder_email: this.state.stakeholder_email,
          events_for_organiser: this.state.events_for_organiser,
          site_area: this.state.site_area,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse);
          //this.setState({ showModal: false });
          alert("Added Organiser Subaccount");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getSiteAreas = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_site_areas",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ site_area_list: dataResponse.vendors }, () => {});
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  getDropEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events_list: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {}
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_current_eoi",
          email_id: emailID,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ eventList: data.eoi });
      });
  };
  componentDidMount = () => {
    this.getEvents();
    this.getSiteAreas();
    this.getDropEvents();
  };

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  onClose = () => {
    this.setState({ isOpen: false });
  };

  onComfirm = (vendor) => {
    const documents = [
      { doc: vendor.food_refistration_docs, name: "food Registration Docs" },
      { doc: vendor.liablity_certificate, name: "Liablity Certificate" },
      { doc: vendor.meals, name: "Meals" },
      { doc: vendor.electricial_certificate, name: "Electrical Certificeate" },
      { doc: vendor.gas_certificate, name: "Gas certificate" },
      { doc: vendor.site_layout, name: "Site LayOut" },
    ];
    const missingDocument = [];
    documents.map((item) => {
      if (!item.doc) {
        missingDocument.push(item);
      }
    });
    if (missingDocument.length != 0) {
      this.setState({ missingDoc: missingDocument });
      this.setState({ noticeOpen: true });
    } else {
      if (this.state.confirmVendors.includes(vendor)) {
        alert("this Vendor aleady added!");
        this.onClose();
      } else {
        this.setState({
          confirmVendors: [...this.state.confirmVendors, vendor],
        });
        this.onClose();
      }
    }
  };

  removeVendor = (vendor) => {
    this.setState({
      confirmVendors: this.state.confirmVendors.filter(
        (item) => item !== vendor
      ),
    });
  };
  openNotice = () => {
    this.setState({ noticeOpen: true });
  };

  ContractNoticeComponent = ({ openContractNotice, selectEvent }) => {
    return (
      <Dialog
        open={openContractNotice}
        onClose={() => this.setState({ contractNoticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Contract"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to use your own contract or do you want to use our
            tempalete to generate final contract?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
            }}
          >
            Use Your contract
          </Button>

          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
              window.open("/contract-generator");
            }}
          >
            Use Our Contract Template
          </Button>

          <Button onClick={() => this.setState({ contractNoticeOpen: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  NoticeComponent = ({ document, selectedVendor, noticeOpens }) => {
    return (
      <Dialog
        open={noticeOpens}
        onClose={() => this.setState({ noticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing documentation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Documents is not uploaded by vendor owners, do you still want to
            accept this vendor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ noticeOpen: false })}>
            Disagree
          </Button>
          <Button
            onClick={() => {
              if (this.state.confirmVendors.includes(selectedVendor)) {
                alert("this Vendor aleady added!");
                this.setState({ noticeOpen: false });
              } else {
                this.setState({
                  confirmVendors: [
                    ...this.state.confirmVendors,
                    selectedVendor,
                  ],
                });
              }
              this.setState({ noticeOpen: false });
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  render() {
    const {
      isDialogVisible,
      isSuperAdminLoggedIn,
      isByState,
      isOrganiserLoggedIn,
      vendorName,
      acceptedVendorData,
      acceptVendors,
      isLoading,
    } = this.state;
    console.log(this.state.site_area_list, this.state.events_list);
    const siteAreaOptions = this.state.site_area_list.map((site) => ({
      value: site.id,
      label: site.site_name,
    }));
    console.log(siteAreaOptions);
    const eventsOptions = this.state.events_list.map((site) => ({
      value: site.id,
      label: site.name,
    }));

    const csvAcceptedDataDownload = acceptedVendorData.map((vendor) => {
      const allMealsHaveDescription =
        vendor.meals?.every((meal) => meal.description?.trim() !== "") ?? false; // Default to false if 'meals' is undefined or empty

      // Check if meals array exists and then check if all meals have an image
      const allMealsHaveImage =
        vendor.meals?.every((meal) => meal.image) ?? false; // Default to false if 'meals' is undefined or empty

      return {
        "Vendor Outlet Name": vendor.title,
        "Vendor Location": vendor.address,
        "Vendor Email": vendor.email,
        "Vendor Contact Number": vendor.phone, // Assuming 'phone' is the correct field for contact number
        "Truck Dimensions": `${vendor.truck_height}x${vendor.truck_width}x${vendor.truck_depth}`,
        "Member Status": vendor.is_wtt_subscriber ? "Member" : "Not Member",
        "Number of 10 Amps": vendor.number_of_10_amp,
        "Number of 15 Amps": vendor.number_of_15_amp,
        "Number of 20 Amps": vendor.number_of_20_amp,
        "Number of 32 Amps": vendor.number_of_32_amp,
        "Water Access": vendor.water_access,
        "Electrical Certificate": vendor.electricial_certificate ? "Yes" : "No",
        "Fire Safety Certificate": vendor.fire_safety_certificate
          ? "Yes"
          : "No",
        "Food Registration Docs": vendor.food_refistration_docs ? "Yes" : "No",
        "Food Safety Certificate": vendor.food_safety_certificate
          ? "Yes"
          : "No",
        "Gas Certificate": vendor.gas_certificate ? "Yes" : "No",
        "Liability Certificate": vendor.liablity_certificate ? "Yes" : "No",
        "Working With Children Check": vendor.working_with_childrens_check
          ? "Yes"
          : "No",
        "Work Cover Insurance": vendor.work_cover_insurance ? "Yes" : "No",
        "Street Trader File": vendor.streat_trader_file ? "Yes" : "No",
        "Site Layout": vendor.site_layout ? "Yes" : "No",
        Image: vendor.image ? "Yes" : "No",
        Logo: vendor.logo ? "Yes" : "No",
        "Meal Description": allMealsHaveDescription ? "Yes" : "No",
        "Meal Images": allMealsHaveImage ? "Yes" : "No",
      };
    });
    const jsonToXLSX = (data, filename) => {
      console.log("clicked", data);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };
    return (
      <>
        <div>
          <AdminNavBar
            style={{ margin: "auto" }}
            isSuperAdminLoggedIn={isSuperAdminLoggedIn}
            isOrganiserLoggedIn={isOrganiserLoggedIn}
            logout={this.logout}
            vendorName={vendorName}
          />
          <this.NoticeComponent
            document={this.state.missingDoc}
            noticeOpens={this.state.noticeOpen}
            selectedVendor={this.state.selectedVendor}
          />
          <Container
            style={{
              minHeight: "600px",
              padding: "20px",
              maxWidth: "600px",
              marginTop: "20px",
            }}
          >
            <h2>Add Subaccount</h2>
            <Form onSubmit={this.handleAddStakeholderFormSubmit}>
              <Form.Group controlId="formAddStakeholderFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  value={this.state.stakeholder_first_name}
                  onChange={(e) =>
                    this.setState({ stakeholder_first_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAddStakeholderLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  value={this.state.stakeholder_last_name}
                  onChange={(e) =>
                    this.setState({ stakeholder_last_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAddStakeholderEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={this.state.stakeholder_email}
                  onChange={(e) =>
                    this.setState({ stakeholder_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formSiteArea">
                <Form.Label>Site Area</Form.Label>
                <Sel
                  isMulti
                  value={siteAreaOptions.filter((option) =>
                    this.state.site_area.includes(option.value)
                  )}
                  onChange={this.handleSiteAreaChange}
                  options={siteAreaOptions}
                  placeholder="Select Site Area"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEvents">
                <Form.Label>Events</Form.Label>
                <Sel
                  isMulti
                  value={eventsOptions.filter((option) =>
                    this.state.events_for_organiser.includes(option.value)
                  )}
                  onChange={this.handleEventsChange}
                  options={eventsOptions}
                  placeholder="Select Events"
                  required
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "#2959a5",
                  borderColor: "#2959a5",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "20px",
                  color: "#fff",
                }}
              >
                Create Account
              </Button>
            </Form>
          </Container>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDetail: state.organiser.newEventDetail,
    EOI: state.organiser,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OrganiserSubaccount, {
    fallback: <ErrorFallbackComponent />,
  })
);
