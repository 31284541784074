import React from "react";
import axios from "axios";
import ErrorFallbackComponent from "../screens/ErrorFallBackScreen";
import config from "./apiConfig";
import * as Sentry from "@sentry/react";

const instance = axios.create({
  baseURL: `${config.BASE_URI}`,
});

export const applyToken = (token) => {
  instance.defaults.headers.common.Authorization = `Token ${token}`;
};

export const clearToken = () => {
  instance.defaults.headers.common.Authorization = null;
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response && error.message === "Network Error") {
      alert(
        "Connection error!Please check your internet connection and try again."
      );
    }
    return Promise.reject(error);
  }
);

export default Sentry.withErrorBoundary(instance, {
  fallback: <ErrorFallbackComponent />,
});
