import * as React from 'react';
import { Form} from 'react-bootstrap';

const CustTextArea = (props) => {
    const { label,formStateHandler,formTouchedHandler, fieldName, defaultValue} = props;
    return (
        <Form.Control
            onClick={() => formTouchedHandler(fieldName, true)}
            label = {label}
            as={"textarea"}
            rows={4}
            onChange = {(event) => {
                formStateHandler(fieldName, event.target.value)
            }}
            defaultValue={defaultValue}
        />
    )
}

export default CustTextArea;