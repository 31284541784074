import React, { useState, useEffect } from "react";
import Footer from "../../components/UI/Footer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Divider from "@mui/material/Divider";

function PrivacyPolicy() {
  return (
    <div>
      <Navbar
        variant="dark"
        expand="lg"
        style={{ backgroundColor: "#2859a5", marginBottom: "20px" }}
      >
        <Navbar.Brand href="/">
          <Image
            style={{ height: 40 }}
            src={require("../../assets/img/welcome-logo.png")}
            fluid
          />
        </Navbar.Brand>
      </Navbar>
      <h1>PRIVACY POLICY FOR WTT POS</h1>
      <Container maxWidth="md" style={{ padding: 20 }}>
        <h4 maxWidth="md" style={{ fontSize: 20 }}>
          Effective Date: <span style={{ color: "#707987" }}>17/10/2023</span>
        </h4>
      </Container>
      <Container
        maxWidth="md"
        style={{ backgroundColor: "#F3F1EF", padding: 20 }}
      >
        {/* <Box sx={{ bgcolor: "#cfe8fc", height: "100vh" }} /> */}
        <h4 style={{ color: "#ef3e6d" }}>1. Introduction</h4>
        Where The Truck POS ("we" or "our") is a mobile application available on
        the Google Play Store, tailored for users in Australia. We are committed
        to safeguarding your privacy and complying with the Australian Privacy
        Principles (APPs) under the Privacy Act 1988 (Cth). This policy outlines
        how we collect, use, share, and protect your personal information. By
        using Where the Truck POS, you consent to the practices outlined in this
        Privacy Policy.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>2. Information We Collect</h4>
        <h5 style={{ color: "#2959a5" }}>a.Location Tracking:</h5> To provide
        you with location-based services, such as real-time tracking of your
        food truck's location for customers, we may collect and process your
        device's geographic location.
        <h5 style={{ color: "#2959a5" }}>c. Integrating Hardware:</h5> If you
        opt to integrate hardware devices such as printers and square terminals,
        we may collect device information to ensure proper functionality and
        support.
        <h5 style={{ color: "#2959a5" }}>
          d. Applying for Catering Jobs and Expressions of Interest (EOIs):
        </h5>
        When you submit applications for catering jobs or EOIs, we collect
        personal and business information provided in the application, which may
        include your contact details, food truck information, and other relevant
        data, and we may also send your information to relevant Customers &
        event organisers.
        <h5 style={{ color: "#2959a5" }}>e. Online Orders:</h5>
        To process and fulfilled your online orders, we collect information
        related to these orders, including order details, delivery addresses,
        and payment information.
        <h5 style={{ color: "#2959a5" }}>f. E-Receipts:</h5>
        We collect transaction details, including the date, time, items
        purchased, and payment information, to provide you with electronic
        receipts.
        <h5 style={{ color: "#2959a5" }}>g. Real Time Sales Tracking:</h5>
        We may collect sales and transaction data to offer real-time tracking
        and analytics for your business, helping you make data-driven decisions.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>
          3. How We Use Your Information We use the collected information for
          the following purposes:{" "}
        </h4>
        <ul>
          <li>
            {" "}
            To provide and improve our services, enhancing your user experience.
          </li>
          <li>
            {" "}
            To enable and support hardware integrations for seamless operations.
          </li>
          <li> To review and process catering job applications and EOIs.</li>
          <li> To process and fulfil online orders efficiently.</li>
          <li>To provide electronic receipts for transactions.</li>
          <li>
            {" "}
            To offer real-time sales tracking and analytics to help you manage
            your business effectively.
          </li>
        </ul>
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>
          4. Data Sharing We may share your information with third parties in
          the following situations:
        </h4>
        <ul>
          <li>To process payments securely. </li>
          <li>
            {" "}
            With third-party vendors and service providers to support the
            functionality of our application.{" "}
          </li>
          <li>
            When required by Australian law, legal processes, or government
            authorities.{" "}
          </li>
          <li>
            In the event of a merger, acquisition, or other corporate
            transaction.{" "}
          </li>
          <li>
            To protect our rights, privacy, safety, and property, as well as
            those of our users.{" "}
          </li>
        </ul>
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>5. Security: </h4>
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, alteration, and destruction. We employ
        industry-standard security protocols and use encryption to protect data
        during transmission. However, no data transmission over the internet can
        be guaranteed to be 100% secure.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>
          6. Access to and Correction of Your Personal Information
        </h4>
        You have the right to access and correct the personal information we
        hold about you. To exercise this right, please contact us at the address
        provided below.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>9. Updates to this Privacy Policy</h4>
        We may update this Privacy Policy to reflect changes in our practices,
        operational requirements, or legal and regulatory developments. We
        encourage you to review this policy periodically to stay informed about
        how we collect, use, and protect your information.
        <Divider variant="middle" style={{ padding: 10 }} />
        <h4 style={{ color: "#ef3e6d" }}>10. Contact Us</h4>
        If you have questions or concerns about this Privacy Policy or our data
        practices, please contact us at: [Your Contact Information] This Privacy
        Policy is subject to the terms and conditions of the Google Play Store.
      </Container>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
