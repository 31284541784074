import React from "react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../store";

const SearchResult = ({
  filteredEvent,
  filteredId,
  selectedEvent,
  selectedEventa,
}) => {
  const handleFilter = () => {
    saveToLocalStorage("selectedEventSearch", "" + filteredEvent);
    selectedEvent(filteredEvent);
    selectedEventa(filteredId);
    console.log(filteredId);
  };
  return (
    <div className="search-result" onClick={handleFilter}>
      {filteredEvent}
    </div>
  );
};

export default SearchResult;
