import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Modal,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from "@sentry/react";
import MealStats from "./MealStats";

class AdminDashboardScreen extends Component {
  state = {
    searchVendor: "",
    filteredVendors: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    tempVendors: [],
    filteredIds: [],
    searchVendors: "",
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    selectedVendorIds: [],
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: {},
    salesModal: false,
    ordersModal: false,
    averageModal: false,
    previousModal: false,
    refundsModal: false,
    refundsOrderModal: false,
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  handleCloseAverageModal = () => {
    this.setState({ averageModal: false });
  };
  handleShowAverageModal = () => {
    this.setState({ averageModal: true });
  };
  handleCloseRefundsModal = () => {
    this.setState({ refundsModal: false });
  };
  handleShowRefundsModal = () => {
    this.setState({ refundsModal: true });
  };
  handleCloseRefundsOrderModal = () => {
    this.setState({ refundsOrderModal: false });
  };
  handleShowRefundsOrderModal = () => {
    this.setState({ refundsOrderModal: true });
  };
  handleClosePreviousModal = () => {
    this.setState({ previousModal: false });
  };
  handleShowPreviousModal = () => {
    console.log("clicked");
    this.setState({ previousModal: true });
  };

  handleShowSalesModal = () => {
    this.setState({ salesModal: true });
  };

  handleCloseSalesModal = () => {
    this.setState({ salesModal: false });
  };
  handleShowOrdersModal = () => {
    console.log("clicked");
    this.setState({ ordersModal: true });
  };

  handleCloseOrdersModal = () => {
    this.setState({ ordersModal: false });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorIds,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics_v2",
          email: email,
          password: password,
          vendor: selectedVendorIds,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
            salesByHoursData: dataResponse.hour_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };
  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
          this.setState({ tempVendors: dataResponse.vendors });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          Sentry.captureException(error);
          console.error(error);
        });
    }
  };
  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              isSuperAdminLoggedIn: "" + dataResponse.data.is_super_admin,
              isOperatorLoggedIn: "" + dataResponse.data.is_operator,
              vendorName: dataResponse.data.vendor__title,
            },
            () => {
              saveToLocalStorage(
                "isSuperAdminLoggedIn",
                "" + dataResponse.data.is_super_admin
              );
              saveToLocalStorage(
                "isOperatorLoggedIn",
                "" + dataResponse.data.is_operator
              );
              saveToLocalStorage(
                "vendorName",
                "" + dataResponse.data.vendor__title
              );
              saveToLocalStorage("email", email);
              saveToLocalStorage("password", password);
              if (dataResponse.data.is_super_admin === true) {
                this.getVendorsInEvent();
              } else {
                alert("Your not super admin");
                this.getVendorsInEvent();
              }
            }
          );
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };
  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };
  handleSearch = (e) => {
    const searchString = e.target.value;
    if (searchString === "") {
      this.setState({ vendors: this.state.tempVendors });
    } else {
      const filteredVendors = this.state.tempVendors.filter(
        (obj) => obj.title.search(new RegExp(searchString, "i")) !== -1
      );
      this.setState({ vendors: filteredVendors });
    }
  };

  handleCheckboxChange = (vendorId, isChecked) => {
    if (isChecked) {
      this.setState((prevState) => ({
        selectedVendorIds: [...prevState.selectedVendorIds, vendorId],
      }));
    } else {
      this.setState((prevState) => ({
        selectedVendorIds: prevState.selectedVendorIds.filter(
          (id) => id !== vendorId
        ),
      }));
    }
  };

  clearSelection = () => {
    this.setState({ selectedVendorIds: [] });
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      startDate,
      endDate,
      selectedVendorSearch,
      filteredVendors,
    } = this.state;
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };
    if (selectedEvent) {
    }
    const data = {
      labels: Object.keys(this.state.salesByHoursData),
      datasets: [
        {
          label: "Sales by Hours ($)",
          data: Object.values(this.state.salesByHoursData),
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              {/* <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <div className="App">
                    <div className="search-bar-container">
                      <SearchBarVendor
                        vendorList={vendors}
                        placeHolder={"Search Vendors"}
                        setFilteredVendors={setFilteredVendors}
                        selectedVendorId={this.state.selectedVendorId} // Pass the selected event ID
                        selectedVendorSearch={this.state.selectedVendorSearch}
                      />
                      <SearchResultsListVendor
                        filteredVendors={filteredVendors}
                        selectedVendorP={selectedVendorP}
                        selectedVendorQ={selectedVendorQ}
                      />
                    </div>
                  </div>
                  {console.log(vendors)}
                </Col> */}
              <Row>
                <Col xs={12} md={12} lg={12}>
                  {this.state.vendors && this.state.vendors.length > 0 ? (
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>*Participating Vendors</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>
                          <img
                            style={{ width: 20 }}
                            src={require("../../assets/img/search.gif")}
                            alt="Search"
                          />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search for vendors"
                          aria-label="Search for vendors"
                          onChange={this.handleSearch}
                        />
                      </InputGroup>

                      <Form.Check
                        type="checkbox"
                        id="clear"
                        label="Clear"
                        onChange={this.clearSelection}
                        checked={this.state.selectedVendorIds.length === 0}
                      />

                      <div style={{ height: "200px", overflowY: "auto" }}>
                        {this.state.vendors.map((vendor) => (
                          <Form.Check
                            key={vendor.user_ptr_id}
                            type="checkbox"
                            id={`vendor-${vendor.user_ptr_id}`}
                            label={vendor.title}
                            checked={this.state.selectedVendorIds.includes(
                              vendor.user_ptr_id
                            )}
                            onChange={(e) =>
                              this.handleCheckboxChange(
                                vendor.user_ptr_id,
                                e.target.checked
                              )
                            }
                          />
                        ))}
                      </div>
                    </Form.Group>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4}>
                  <p>Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <p>End Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <Button
                    style={{ marginTop: 30 }}
                    onClick={() => this.getOrders()}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>

              {selectedVendorId != "0" ? (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              ) : null}

              <Row>
                <Col xs={12} md={4} lg={4}>
                  <h5>Total Sales: ${Number(total_sales).toFixed(2)}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowSalesModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>Total Orders: {total_orders}</h5>
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowOrdersModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Total Avg Order: $
                    {(
                      Number(total_sales) /
                      (Number(total_orders) + Number(total_orders))
                    ).toFixed(2)}
                  </h5>
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowAverageModal}
                  >
                    See Details
                  </p>
                </Col>
              </Row>

              <Modal
                show={this.state.salesModal}
                onHide={this.handleCloseSalesModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Cash Sales: ${Number(totalCash).toFixed(2)}</p>
                  <p>Card Sales: ${Number(totalCard).toFixed(2)}</p>

                  <p>GST: ${((Number(total_sales) * 10) / 100).toFixed(2)}</p>

                  <p>
                    Total Sales(Surcharge+Transaction Fee): $
                    {Number(total_sales).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.ordersModal}
                onHide={this.handleCloseOrdersModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Cash Orders: {totalCashOrders}
                    {/* (
                      {(
                        (totalCashOrders /
                          (totalCashOrders + totalCardOrders)) *
                        100
                      ).toFixed(2)}
                      %) */}
                  </p>
                  <p>
                    Card Orders: {totalCardOrders}
                    {/* ({(
                        (totalCardOrders /
                          (totalCashOrders + totalCardOrders)) *
                        100
                      ).toFixed(2)}
                      %) */}
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.averageModal}
                onHide={this.handleCloseAverageModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Cash Avg: $
                    {Number(totalCashOrders) !== 0
                      ? (Number(totalCash) / Number(totalCashOrders)).toFixed(2)
                      : 0}
                  </p>
                  <p>
                    Card Avg: $
                    {Number(totalCardOrders) !== 0
                      ? (Number(totalCard) / Number(totalCardOrders)).toFixed(2)
                      : 0}
                  </p>
                </Modal.Body>
              </Modal>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <h5>Refunds: ${Number(canceled_sales).toFixed(2)}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowRefundsModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>Refunded Orders: {canceled_orders}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowRefundsOrderModal}
                  >
                    See Details
                  </p>
                </Col>
              </Row>
              <Modal
                show={this.state.refundsModal}
                onHide={this.handleCloseRefundsModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Refunds: ${Number(canceled_sales).toFixed(2)}</p>
                  <p>
                    Total Sales Less Refund: $
                    {Number(
                      Number(total_sales) - Number(canceled_sales)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Cash Less Refund: $
                    {Number(
                      Number(totalCash) - Number(canceled_sales_cash)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Card Less Refund: $
                    {Number(
                      Number(totalCard) - Number(canceled_sales_card)
                    ).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.refundsOrderModal}
                onHide={this.handleCloseRefundsOrderModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Refunded Orders: ${canceled_orders}</p>
                  <p>
                    Total Orders Less Refunded Orders: $
                    {total_orders - canceled_orders}
                  </p>
                </Modal.Body>
              </Modal>

              {/* <Row>
                {" "}
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Sales:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    ${Number(total_sales).toFixed(2) + "/" + total_orders}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Cash</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCash).toFixed(2) + "/" + totalCashOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Card</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCard).toFixed(2) + "/" + totalCardOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{total_orders}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunds:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>${Number(canceled_sales).toFixed(2)}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunded Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{canceled_orders}</h5>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Sales Less Refund:</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>
                      $
                      {Number(
                        Number(total_sales) - Number(canceled_sales)
                      ).toFixed(2)}
                    </b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Cash Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCash) - Number(canceled_sales_cash)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Card Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCard) - Number(canceled_sales_card)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Orders Less Refunded Orders</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>{total_orders - canceled_orders}</b>
                  </h5>
                </Col>
              </Row> */}
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Bar data={data} options={options} />
                </Col>
              </Row>
              <Row>
                <MealStats meal_freq={this.state.meal_freq}></MealStats>
              </Row>
              <hr />

              {this.state.selectedVendorId != undefined &&
              this.state.selectedVendorId != 0 ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserVendorsPieChart
                      vendorName={this.state.vendorName}
                      vendorData={this.state.meal_freq}
                    ></EventOrganiserVendorsPieChart>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserVendorsPieChart
                      vendorName={this.state.salesByVendorName}
                      vendorData={this.state.salesByVendorTotal}
                      isBool={true}
                    ></EventOrganiserVendorsPieChart>
                  </Col>
                </Row>
              )}
              <div className="my-3">
                <h3>Items Sold:</h3>
                {this.state.newMealFrequency[0] && (
                  <TableData
                    tableData={this.state.newMealFrequency}
                  ></TableData>
                )}
              </div>
              <div
                style={{
                  height: window.innerHeight / 3,
                  overflow: "scroll",
                  borderWidth: 1,
                  borderColor: "black",
                  borderStyle: "solid",
                }}
              ></div>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminDashboardScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
