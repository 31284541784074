import React, { Component } from "react";
import { connect } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import Container from "react-bootstrap/Container";
import * as Sentry from "@sentry/react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import { WbIncandescentOutlined } from "@material-ui/icons";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockAdminOrderEditScreen extends Component {
  state = {
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    pendingOrder: new Array(),
    quantitiesArray: [],
    expiryAdjustmentArray: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    qtyTouched: false,
    expiryAdjustmentTouched: false,
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    belowMinCheckbox: false,
    isLoading: false,
    adminAdjustment: 0,
    belowMinChanged: false,
  };

  logout = () => {
    deleteFromLocalStorage("stockAdminLoggedIn", "false");
    deleteFromLocalStorage("stockAdminEmail", "");
    deleteFromLocalStorage("stockAdminPassword", "");
    this.setState({ loggedIn: "false" });
    window.location.href = "/stock-admin";
  };

  approveOrder = () => {
    const {
      quantitiesArray,
      pendingOrder,
      expiryAdjustmentArray,
      email,
      password,
      superAdminEmail,
      superAdminPassword,
      adminAdjustment,
    } = this.state;

    var orderData = pendingOrder[0].orderData;

    var stockData = [];
    var sub_total = 0;
    var total_gst = 0;
    var total = 0;

    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0;
      stockitem_sub_total = Number(
        Number(orderData[key].price) * Number(quantitiesArray[key])
      ).toFixed(2);
      var stockitem_total_gst = 0;
      if (Number(orderData[key].gst) > 0) {
        stockitem_total_gst = Number(
          Number(0.1 * orderData[key].price) * Number(quantitiesArray[key])
        ).toFixed(3);
      }
      var stockitem_total = Number(
        Number(stockitem_sub_total) + Number(stockitem_total_gst)
      ).toFixed(3);

      sub_total = Number(sub_total) + Number(stockitem_sub_total);
      total_gst = Number(total_gst) + Number(stockitem_total_gst);
      total =
        Number(total) +
        Number(stockitem_sub_total) +
        Number(stockitem_total_gst);

      var obj = {
        id: orderData[key].id,
        stock_list_id: orderData[key].stock_list_id,
        name: orderData[key].name,
        qty: quantitiesArray[key],
        qty_type: orderData[key].qty_type,
        price: orderData[key].price,
        sub_total: stockitem_sub_total,
        gst: stockitem_total_gst,
        total: stockitem_total,
        expiry_adjustment: expiryAdjustmentArray[key],
      };
      stockData.push(obj);
    }

    if (Number(adminAdjustment) > 0) {
      //Below Min Order Fee Added
      total = Number(total) + Number(adminAdjustment);
    } else {
      //Below Min Order Fee Removed
      total =
        Number(total) +
        Number(pendingOrder[0]["delivery_charge"]) +
        Number(adminAdjustment);
    }

    var orderData = {
      kitchaco: pendingOrder[0].kitchaco,
      order_date: moment(new Date()).format("DD MMM YYYY"),
      order_time: moment(new Date()).format("hh:mm A"),
      sub_total: Number(sub_total).toFixed(2),
      gst: Number(total_gst).toFixed(2),
      admin_adjustment: Number(adminAdjustment),
      total: Number(total).toFixed(2),
      stockData: stockData,
      orderId: pendingOrder[0].id,
    };

    if (stockData.length > 0 && Number(total) > 0) {
      var data = {};
      if (email != "" && password != "") {
        data = {
          email: email,
          password: password,
          orderData: orderData,
          query_type: "admin_approve_order",
        };
      } else if (superAdminEmail != "" && superAdminPassword != "") {
        data = {
          email: superAdminEmail,
          password: superAdminPassword,
          orderData: orderData,
          query_type: "admin_approve_order",
        };
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "admin_approve_order",
            orderData: orderData,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            alert("Approved Succesfully");
            window.location.href = "/stock-admin";
          } else {
            alert(result.error);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
        });
    } else {
      alert("Please select altleast 1 item");
    }
  };

  fetchOrderFromServer = () => {
    const { email, password, superAdminEmail, superAdminPassword } = this.state;
    this.setState({ isLoading: true });
    var orderId = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_admin_specific_pending",
          orderId: orderId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ pendingOrder: result.data }, () => {
            var orderData = this.state.pendingOrder[0].orderData;
            var tempQtyArray = [];
            var tempEAArray = [];
            for (var i = 0; i < orderData.length; i++) {
              tempQtyArray[i] = orderData[i].qty;
              tempEAArray[i] = 0;
            }
            this.setState(
              {
                quantitiesArray: tempQtyArray,
                expiryAdjustmentArray: tempEAArray,
              },
              () => {}
            );

            if (Number(this.state.pendingOrder[0].delivery_charge) > 0) {
              this.setState({ belowMinCheckbox: true }, () => {
                this.setState({ isLoading: false });
              });
            } else {
              this.setState({ isLoading: false });
            }
          });
        } else {
          this.setState({ isLoading: false });
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  handleChangeQty(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
      qtyTouched: true,
    });
  }

  handleChangeEA(i, e) {
    this.setState({
      expiryAdjustmentArray: {
        ...this.state.expiryAdjustmentArray,
        [i]: e.target.value,
      },
      expiryAdjustmentTouched: true,
    });
  }

  componentDidMount() {
    const { loggedIn } = this.state;

    this.fetchOrderFromServer();
  }

  render() {
    const { loggedIn, isLoading } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {!isLoading ? this.renderOrderPage() : null}
        <Container></Container>
      </div>
    );
  }

  renderNavBar() {
    const {
      stockAdminLoggedIn,
      isSuperAdminLoggedIn,
      stockAdminUserData,
    } = this.state;
    return <AdminNavBar isSuperAdminLoggedIn={true}></AdminNavBar>;
  }

  renderOrderPage() {
    const {
      pendingOrder,
      qtyTouched,
      belowMinCheckbox,
      belowMinChanged,
    } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>
                Stall Name:{" "}
                {pendingOrder[0] && pendingOrder[0].vendor__location}
              </h3>
              <h4>Order Number {pendingOrder[0] && pendingOrder[0].id}</h4>
              <h4>
                Order Date {pendingOrder[0] && pendingOrder[0].order_date}
              </h4>
              <h4>
                Order Time {pendingOrder[0] && pendingOrder[0].order_time}
              </h4>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Id
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col">
              Product Name
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Expiry Adjustment
            </Col>
          </Row>
          {pendingOrder[0] &&
            pendingOrder[0].orderData.map((item, i) => {
              var subtotal = 0;
              var gst = 0;

              if (qtyTouched == false) {
                subtotal = item.sub_total;
                gst = item.gst;
              } else {
                if (document.getElementById("qty_" + i)) {
                  subtotal = Number(
                    Number(document.getElementById("qty_" + i).value) *
                      Number(item.price)
                  ).toFixed(2);
                  gst = Number(
                    Number(document.getElementById("qty_" + i).value) *
                      (0.1 * Number(item.price))
                  ).toFixed(3);
                } else {
                  subtotal = 0.0;
                  gst = 0.0;
                }
              }
              return (
                <Row key={i} className="table-row">
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <h4 className="desktop-only">{item.id}</h4>
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-col">
                    <h4>{item.name + " (" + item.qty_type__qty_type + ")"}</h4>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">Par:</b> {item.par}
                    </span>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        placeholder="Quantity Needed"
                        id={"qty_" + i}
                        value={this.state.quantitiesArray[i]}
                        name={this.state.quantitiesArray[i]}
                        onChange={this.handleChangeQty.bind(this, i)}
                      />
                      <Form.Text className="text-muted">
                        {item.qty_type__qty_type}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    {Number(item.gst) > 0 ? (
                      <span>
                        <b className="mobile-only">Price:</b> $
                        {item.price + " + GST"}
                      </span>
                    ) : (
                      <span>
                        <b className="mobile-only">Price:</b> ${item.price}
                      </span>
                    )}
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    {Number(item.gst) > 0 ? (
                      <span>
                        <h4>
                          <b className="mobile-only">Sub Total:</b>{" "}
                          {subtotal + " +  " + gst}
                        </h4>
                      </span>
                    ) : (
                      <span>
                        <h4>
                          <b className="mobile-only">Sub Total:</b> {subtotal}
                        </h4>
                      </span>
                    )}
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <Form.Group>
                      <Form.Control
                        type="number"
                        placeholder="Expiry Adjustment"
                        id={"ea_" + i}
                        value={this.state.expiryAdjustmentArray[i]}
                        name={this.state.expiryAdjustmentArray[i]}
                        onChange={this.handleChangeEA.bind(this, i)}
                      />
                      <Form.Text className="text-muted">
                        default = 7 days
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
          {pendingOrder[0] ? (
            <Row className="button-row">
              <Col xs={12}>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label={
                      belowMinCheckbox
                        ? "Below Min Order Fee Applied"
                        : "Apply Below Min Order"
                    }
                    checked={belowMinCheckbox}
                    onChange={(e) => {
                      var message =
                        "Are you sure you want to remove the 'Below Min Order Fee' from this order?";
                      var adjustment = -Number(pendingOrder[0].delivery_charge);
                      var changeTo = false;
                      if (e.target.checked) {
                        message =
                          "Are you sure you want to add the 'Below Min Order Fee' tp this order?";
                        adjustment = Number(
                          pendingOrder[0].min_stock_delivery_charge
                        );
                        changeTo = true;
                      }

                      if (window.confirm(message)) {
                        if (belowMinChanged == false) {
                          this.setState(
                            {
                              adminAdjustment: adjustment,
                              belowMinCheckbox: changeTo,
                              belowMinChanged: true,
                            },
                            () => {}
                          );
                        } else {
                          window.location.reload();
                        }
                      } else {
                        this.setState(
                          { belowMinCheckbox: !changeTo },
                          () => {}
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : null}
          <Row className="button-row">
            <Col xs={12}>
              <Button
                onClick={() => {
                  this.approveOrder();
                }}
              >
                Approve Order
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminOrderEditScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
