import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import moment from "moment";

const WeatherForecastModal = ({ forecastData }) => {
  console.log(forecastData);
  const [showModal, setShowModal] = useState(false);
  const [showHourlyModal, setShowHourlyModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [canSeeSales, setCanSeeSales] = useState(
    getFromLocalStorage("canSeeSales") != "undefined" &&
      getFromLocalStorage("canSeeSales") != "" &&
      getFromLocalStorage("canSeeSales") != null
      ? getFromLocalStorage("canSeeSales")
      : "false"
  );

  const handleClose = () => {
    setShowModal(false);
    setShowHourlyModal(false);
    setSelectedDay(null);
  };

  const handleShow = () => setShowModal(true);

  const handleHourlyShow = (dayIndex) => {
    setSelectedDay(dayIndex);
    setShowHourlyModal(true);
  };

  const handleBackToDaily = () => {
    setShowHourlyModal(false);
    setSelectedDay(null);
    setShowModal(true);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("Do MMM YYYY hh:mm A");
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              if (canSeeSales === "true") {
                handleShow();
              }
            }}
            style={{
              marginBottom: "10px",
              background: canSeeSales === "false" ? "#DAA520" : "#2959a5",
              backgroundColor: canSeeSales === "false" ? "#DAA520" : "#2959a5",
              borderRadius: "6px",
              border:
                "1px solid " +
                (canSeeSales === "false" ? "#DAA520" : "#2959a5"),
              color: "#ffffff",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              marginTop: "10px",
              height: "50px", // Uniform height
              width: "280px", // Uniform width
            }}
          >
            View Event Weather <WorkspacePremiumIcon />
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Event Weather Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {forecastData &&
          forecastData.forecast &&
          forecastData.forecast.forecastday ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Max Temp (C)</th>
                  <th>Min Temp (C)</th>
                  <th>Condition</th>
                  <th>Hourly Report</th>
                </tr>
              </thead>
              <tbody>
                {forecastData.forecast.forecastday.map((day, index) => (
                  <tr key={index}>
                    <td>{formatDateTime(day.date)}</td>
                    <td>{day.day.maxtemp_c}</td>
                    <td>{day.day.mintemp_c}</td>
                    <td>
                      {day.day.condition ? (
                        <>
                          {day.day.condition.text}
                          <img
                            src={day.day.condition.icon}
                            alt={day.day.condition.text}
                          />
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <Button onClick={() => handleHourlyShow(index)}>
                        View Hourly
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No daily forecast data available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHourlyModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Hourly Weather Forecast</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={handleBackToDaily}>
            Back to Daily Forecast
          </Button>
          {selectedDay !== null &&
          forecastData.forecast.forecastday[selectedDay] ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Temperature (C)</th>
                  <th>Condition</th>
                </tr>
              </thead>
              <tbody>
                {forecastData.forecast.forecastday[selectedDay].hour.map(
                  (hour, i) => (
                    <tr key={i}>
                      <td>{formatDateTime(hour.time)}</td>
                      <td>{hour.temp_c}</td>
                      <td>
                        {hour.condition ? (
                          <>
                            {hour.condition.text}
                            <img
                              src={hour.condition.icon}
                              alt={hour.condition.text}
                            />
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <p>No hourly report data available for the selected day</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WeatherForecastModal;
