import React, { useState } from 'react';
import './SalesTable.css';
const SalesTable = ({ sales_by_vendor_obj }) => {
  const [sortedData, setSortedData] = useState(null);

  const sortData = () => {
    const sortedEntries = Object.entries(sales_by_vendor_obj).sort((a, b) => b[1] - a[1]);
    setSortedData(sortedEntries);
  };

  const renderTableRows = () => {
    const dataToRender = sortedData || Object.entries(sales_by_vendor_obj);

    return dataToRender.map(([vendor, sales]) => (
      <tr key={vendor}>
        <td>{vendor}</td>
        <td>${sales.toFixed(2)}</td>
      </tr>
    ));
  };

  return (
    <div className="sales-table-container">
      <button className="sort-button" onClick={sortData}>Sort by Sales</button>
      <table className="sales-table">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Sales</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>
    </div>
  );
};

export default SalesTable;
