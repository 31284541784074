import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

function VendorCostAccordion({ shiftData, sales_by_vendor_obj }) {
  console.log(sales_by_vendor_obj);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderChart = (sortedHourlyCost, totalCost, title) => {
    const hours = Object.keys(sortedHourlyCost).sort();
    const costs = hours.map((hour) => sortedHourlyCost[hour]);

    const chartData = {
      labels: hours,
      datasets: [
        {
          label: "Total Cost by Hour",
          data: costs,
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    const sales = sales_by_vendor_obj[title];
    return (
      <>
        {typeof sales === "number" ? (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Total Sales: ${sales.toLocaleString()}
          </Typography>
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Sales data unavailable
          </Typography>
        )}
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Total Wage Cost: ${totalCost ? totalCost.toFixed(2) : "0.00"}
          {sales > 0
            ? ` (${((totalCost / sales) * 100).toFixed(2)}% of total sales)`
            : ""}
        </Typography>
        <Bar data={chartData} options={options} />
      </>
    );
  };

  return (
    <div>
      {Array.isArray(shiftData) && shiftData.length > 0 ? (
        shiftData.map((vendor, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {vendor.vendor_title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {vendor.shift_data && vendor.shift_data.sorted_hourly_cost ? (
                renderChart(
                  vendor.shift_data.sorted_hourly_cost,
                  vendor.shift_data.total_cost,
                  vendor.vendot_title
                )
              ) : (
                <Typography>No data available.</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography style={{ textAlign: "center" }}>
          No shift data available
        </Typography>
      )}
    </div>
  );
}

export default VendorCostAccordion;
