import React, { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFContract from "../../components/PdfComponent/PdfCreator";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import { useDispatch, useSelector } from "react-redux";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import {
  Grid,
  Typography,
  Container,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  ListSubheader,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActionArea,
  Button,
  CircularProgress,
  FormControl,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import ReactPDF from "@react-pdf/renderer";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store/index";
const Contract = () => {
  const vendors = JSON.parse(getFromLocalStorage("comfirmedVendors"));
  const EOI = JSON.parse(getFromLocalStorage("selectedEOI"));
  const Organiser = JSON.parse(getFromLocalStorage("Organiser"))[0];
  const logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  return (
    <div>
      <AdminNavBar
        style={{ margin: "auto" }}
        isSuperAdminLoggedIn="false"
        isOrganiserLoggedIn={getFromLocalStorage("isOrganiserLoggedIn")}
        logout={logout}
        vendorName=""
      ></AdminNavBar>

      <div
        style={{
          width: 800,
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            fontSize: 30,
            fontStyle: "oblique",
          }}
        >
          Contract Details
        </Typography>

        {/* <Button
          variant="contained"
          onClick={() => {
            const url = config.devUrl + apiPaths.adminData;
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let vendorId = [];

            this.state.confirmVendors.map((item) => {
              vendorId.push(item.id);
            });

            const raw = JSON.stringify({
              payload: {
                body: {
                  query_type: "accepted_vendor_outlets",
                  event_id: this.state.selectEvent.id,
                  accepted_vendor_ids: vendorId,
                },
              },
            });

            let requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };
            try {
              fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                  if (response.success == true) {
                    alert("Apply successed!");
                    this.setState({ confirmVendors: [] });
                    this.getTargetedEvents(this.state.selectEvent.id);
                  } else {
                    alert("The application is failed. Please try latter");
                    this.setState({ confirmVendors: [] });
                    this.getTargetedEvents(this.state.selectEvent.id);
                  }
                });
            } catch (error) {
              alert("The application is failed. Please try latter");
              this.setState({ confirmVendors: [] });
            }
            vendorId.length = 0;
          }}
        >
          Accept
        </Button> */}

        {/* <Button
        onClick={() =>
          ReactPDF.render(<PDFContract />, `${__dirname}/example.pdf`)
        }
      >
        save
      </Button> */}

        <PDFViewer style={{ width: 800, height: 800, overflow: "auto" }}>
          <PDFContract vendor={vendors} EOI={EOI} organiser={Organiser} />
        </PDFViewer>
      </div>
    </div>
  );
};

export default Sentry.withErrorBoundary(Contract, {
  fallback: <ErrorFallbackComponent />,
});
