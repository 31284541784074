import React from 'react';
import FileUploader from '../FileUploader';
import {Accordion, Card} from 'react-bootstrap';

const OtherUploads = (props) => {
    const {formik, viewOnly} = props;

    return (
        <Card>
            {
                !viewOnly ? (
                    <>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    8. OTHER UPLOADS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                <div className="title-heading">
                    <h6 >Other documents that you think are necessary</h6>
                    <FileUploader 
                        allowMultiple={true}
                        formStateHandler={formik.setFieldValue}
                        formTouchedHandler={formik.setFieldTouched}
                        fieldName={"otherUploads"}
                        defaultValues={formik.values.otherUploads}
                    />
                </div>
                </Card.Body>
                </Accordion.Collapse>
                </>
                ):(
                    <>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    8. OTHER UPLOADS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                <div className="title-heading">
                    <h6 >Other documents: </h6>
                    {formik.values.otherUploads.map(({item}) => (
                        <img
                        className="d-block w-100"
                        src={item && item != null && item != "" ? item : require("../../../../assets/img/default_cover.jpg")}
                        alt="Event Photos"
                        height="200px"
                        width="200px"
                        />
                    ))}
                </div>
                </Card.Body>
                </Accordion.Collapse>
                </>
                )}
        </Card>
    );
}

export default OtherUploads;