export const isRestaurantOpen = (
  moment,
  kitchacoLocations,
  minDistance,
  type,
  selectedDate,
  selectedTime
) => {
  var tempDat = new Date();
  if (type == "schedule_order") {
    tempDat =
      moment(selectedDate).format("DD MMM YYYY") +
      " " +
      moment(selectedTime).format("HH:mm");
  }

  var currentDateTime = moment(tempDat).format("YYYY-MM-DD HH:mm");
  var currentDate = moment(tempDat).format("YYYY-MM-DD");
  var day = moment(currentDateTime).day();
  var timings = "0";
  switch (day) {
    case 0:
      timings = kitchacoLocations[minDistance].sunday_time;
      break;
    case 1:
      timings = kitchacoLocations[minDistance].monday_time;
      break;
    case 2:
      timings = kitchacoLocations[minDistance].tuesday_time;
      break;
    case 3:
      timings = kitchacoLocations[minDistance].wednesday_time;
      break;
    case 4:
      timings = kitchacoLocations[minDistance].thursday_time;
      break;
    case 5:
      timings = kitchacoLocations[minDistance].friday_time;
      break;
    case 6:
      timings = kitchacoLocations[minDistance].saturday_time;
      break;
  }

  if (timings == "0") {
    return false;
  }

  var startTime = new Array();
  var endTime = new Array();
  var windows = timings.split(",");
  windows.map((item) => {
    var temp = item.split("-");
    startTime.push(temp[0]);
    endTime.push(temp[1]);
  });

  for (var i = 0; i < startTime.length; i++) {
    var diffWithEnd = moment(currentDateTime).diff(
      moment(currentDate + " " + endTime[i]),
      "minutes"
    );

    var diffWithStart = moment(currentDateTime).diff(
      moment(currentDate + " " + startTime[i]),
      "minutes"
    );

    if (diffWithStart > 0 && diffWithEnd < 0) {
      return true;
    }
  }
  return false;
};
