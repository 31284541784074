import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  info: {
    marginBottom: "20px",
  },
  salesTable: {
    marginTop: "20px",
  },
  tableHeader: {
    backgroundColor: "#e9ecef",
    padding: "5px",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #ccc",
    padding: "5px",
  },
  tableCell: {
    flex: 1,
    textAlign: "center",
  },
});

const EventReport = ({ salesFigure }) => {
  const { event, sales_by_vendor_obj } = salesFigure;

  // Check if salesFigure is empty
  if (Object.keys(salesFigure).length === 0) {
    return <Text>No sales data available.</Text>;
  }

  return (
    <Document>
      <Page size="A4" style={styles.container}>
        <Text style={styles.title}>{event.name}</Text>
        <View style={styles.info}>
          <Text>Location: {event.location}</Text>
          <Text>
            Date: {event.start_date} - {event.end_date}
          </Text>
          <Text>
            Time: {event.start_time} - {event.end_time}
          </Text>
        </View>
        <View style={styles.salesTable}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Vendor</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Sales</Text>
          </View>
          {Object.entries(sales_by_vendor_obj).map(([vendor, sales]) => (
            <View style={styles.tableRow} key={vendor}>
              <Text style={styles.tableCell}>{vendor}</Text>
              <Text style={styles.tableCell}>{sales}</Text>
            </View>
          ))}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableHeader]}>
              Total Sales
            </Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>
              {salesFigure.total_sales}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EventReport;
