import React from "react";

const ErrorFallbackComponent = ({ error }) => {
  // Customize the error rendering as needed
  return (
    <div>
      <h1>Something went wrong!</h1>
      <p>{error.message}</p>
      {/* You can add more error details or styling here */}
    </div>
  );
};

export default ErrorFallbackComponent;
