import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react/";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ProfileImage from "@daym3l/react-profile-image";
import Media from "react-bootstrap/Media";
import * as Sentry from "@sentry/react";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function AdminMenuAddScreen(props) {
  console.log(props);
  const getImages = (base64Image, fileImage) => {
    set_meal_image(base64Image);
  };
  const [meal_name, set_meal_name] = useState("");
  const [meal_name_error, set_meal_name_error] = useState("");
  const [meal_price, set_meal_price] = useState("");
  const [meal_image, set_meal_image] = useState("");
  const [description, set_description] = useState("");
  const [cuisine_type, set_cuisine_type] = useState("");
  const [meal_status, set_status] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_meal_categories",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(response.meal_categories);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const postAddMeal = async (values) => {
    values.preventDefault();
    if (
      1 === "1"
      // meal_name === "" ||
      // meal_price === "" ||
      // meal_status === "" ||
      // cuisine_type === "" ||
      // description === ""
    ) {
      alert("Please Enter all the Fields");
    } else {
      const url = config.BASE_URI + apiPaths.adminData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "admin_event_menu_add",
            organiser_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
            meal_name: meal_name,
            event_id: props.eventId,
            description: description,
            image: meal_image,
            meal_price: parseFloat(meal_price),
            category: parseInt(cuisine_type),
            meal_status: meal_status,
            vendor_id: props.stallId,
          },
        },
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success == true) {
            alert("Cuisine added successfully!");
            window.location.reload();
          } else {
            alert("Please enter all the fields");
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert("Adding menu is unsuccessful, Please try after some time");
        });
    }
  };
  const handleMealNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_meal_name_error("Please enter only letters and spaces");
    } else {
      set_meal_name(inputValue);
      set_meal_name_error("");
    }
  };
  const handleMealPriceChange = (event) => {
    const inputValue = event.target.value;
    set_meal_price(inputValue);
  };
  const handleMealImageChange = (event) => {
    set_meal_image(event.target.files[0]);
  };
  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value;
    set_description(inputValue);
  };
  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };
  const handleStatusChange = (event) => {
    set_status(event.target.value);
  };
  useEffect(() => {
    getAvailableCuisines();
  }, []);

  return (
    <Container>
      <div className="form-style-5">
        <form onSubmit={postAddMeal}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Name:"
                type="text"
                id="meal_name"
                name="meal_name"
                value={meal_name}
                onChange={handleMealNameChange}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Price:"
                type="number"
                id="meal_price"
                name="meal_price"
                value={meal_price}
                onChange={handleMealPriceChange}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleCuisineTypeChange}>
                <option value="" disabled>
                  Select cuisine type
                </option>
                {availableCuisines.map((cuisine) => (
                  <option key={cuisine.id} value={cuisine.id}>
                    {cuisine.title}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleStatusChange}>
                <option value="" disabled>
                  Status
                </option>
                <option value={"Enabled"}>Enabled</option>
                <option value={"Disabled"}>Disabled</option>
              </select>
            </Col>
          </Row>
          <label htmlFor="logoFile">Upload the Meal Image:</label>
          <ProfileImage
            camera={false}
            returnImage={(base64Image, fileImage) => {
              getImages(base64Image, fileImage);
            }}
            uploadBtnProps={{ variant: "contained", label: "Upload" }}
            defaultImage={require("../../assets/img/empty_logo.png")}
            style={{ width: 100, height: 100 }}
          />
          {/* <input
            type="file"
            id="meal_image"
            name="meal_image"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleMealImageChange}
          /> */}
          <Row
            style={{
              paddingBottom: 10,
              marginTop: 10,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Comments:"
                type="text"
                rows="4"
                cols="75"
                onChange={handleDescriptionChange}
              ></textarea>
            </Col>
          </Row>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}
export default Sentry.withErrorBoundary(AdminMenuAddScreen, {
  fallback: <ErrorFallbackComponent />,
});
