import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventSalesBarGraph } from "../EventOrganiserOverviewScreen/EventSalesBarGraph";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import SalesTable from "../AdminExternalOperatorsDashboardScreen/Components/SalesTabel";
import SalesByEventTabel from "../AdminExternalOperatorsDashboardScreen/Components/SalesByEventTabel";

class OrganiserSiteAreaScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",

    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    total_voucher_value: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorObj: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    salesByEventName: [],
    salesByEventTotal: [],
    salesByEventObj: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentDidMount() {
    const { isSuperAdminLoggedIn, isOrganiserLoggedIn } = this.state;
    if (isSuperAdminLoggedIn == "true" || isOrganiserLoggedIn == "true") {
      // this.getAllEvents();
    }
    this.getVendorsInEvent();
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures_for_site_areas",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: dataResponse.card_new_count,
            totalCashOrders: dataResponse.cash_new_count,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor_obj,
            salesByEvent: dataResponse.sales_by_events_obj,
            salesByHours: dataResponse.sorted_hours_sales,
            salesByVendorObj: dataResponse.sales_by_vendor_obj,
            salesByEventObj: dataResponse.sales_by_events_obj,
            total_voucher_value: dataResponse.total_voucher_value,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        var salesBYEventName = [];
        var salesBYEventTotal = [];
        // alert("salesByVendor" + dataResponse.sales_by_vendor_obj)
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor_obj
        )) {
          console.log(`${key}: ${salesBYVendorName.push(key)}`);
          console.log(`${value}: ${salesBYVendorTotal.push(value)}`);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_events_obj
        )) {
          console.log(`${key}: ${salesBYEventName.push(key)}`);
          console.log(`${value}: ${salesBYEventTotal.push(value)}`);
        }

        this.setState({ salesByEventName: salesBYEventName });
        this.setState({ salesByEventTotal: salesBYEventTotal });

        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,

      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const isOperatorLoggedIn = false;
    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_organiser_details_for_site_areas_ids",
            operator_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.site_areas }, () => {
            this.setState({ selectedVendorId: selectedVendorId }, () => {
              saveToLocalStorage("selectedVendorId", selectedVendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };
  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              isSuperAdminLoggedIn: "" + dataResponse.data.is_super_admin,
              isOperatorLoggedIn: "" + dataResponse.data.is_operator,
              vendorName: dataResponse.data.vendor__title,
            },
            () => {
              saveToLocalStorage(
                "isSuperAdminLoggedIn",
                "" + dataResponse.data.is_super_admin
              );
              saveToLocalStorage(
                "isOperatorLoggedIn",
                "" + dataResponse.data.is_operator
              );
              saveToLocalStorage(
                "vendorName",
                "" + dataResponse.data.vendor__title
              );
              saveToLocalStorage("email", email);
              saveToLocalStorage("password", password);
              if (dataResponse.data.is_super_admin === true) {
                this.getVendorsInEvent();
              } else {
                alert("Your not super admin");
                this.getVendorsInEvent();
              }
            }
          );
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };
  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.reload();
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isOrganiserLoggedIn,
    } = this.state;
    return (
      <>
        {isOrganiserLoggedIn === "true" ? (
          <>
            <AdminNavBar
              style={{ margin: "auto" }}
              isSuperAdminLoggedIn={false}
              isOrganiserLoggedIn={isOrganiserLoggedIn}
              logout={this.logout}
              vendorName={vendorName}
            />
            {this.renderHome()}
          </>
        ) : (
          // Render the login component when neither isSuperAdminLoggedIn nor isAccountantLoggedIn is "true"
          window.location.replace("/organiser")
        )}
      </>
    );
  }

  renderLogin() {
    const { email, password, isOrganiserLoggedIn, vendorName } = this.state;
    return (
      <div>
        <AdminNavBar
          style={{ margin: "auto" }}
          isSuperAdminLoggedIn={false}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></AdminNavBar>{" "}
        <Container>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Enter email"
              style={{ height: 50, marginTop: 40 }}
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group>
            <OutlinedInput
              id="outlined-adornment-password"
              placeholder="Password"
              type={this.state.showPassword ? "text" : "password"}
              onChange={(e) => this.setState({ password: e.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Form.Group>
          <ReCAPTCHA
            style={{ marginTop: 20 }}
            sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
          />

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }
  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      total_voucher_value,
      selectedDate,
      startDate,
      endDate,
      salesByVendorObj,
      salesByEventObj,
    } = this.state;
    if (selectedEvent) {
    }

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={6} lg={6}>
                  {vendors && vendors.length > 0 ? (
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>
                        Site Areas {"(" + vendors.length + ")"}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedVendorId}
                        onChange={(e) => {
                          this.setState(
                            { selectedVendorId: "" + e.target.value },
                            () => {
                              // this.getOrders();
                            }
                          );
                          saveToLocalStorage(
                            "selectedVendorId",
                            "" + e.target.value
                          );
                        }}
                      >
                        {vendors && vendors.length == 1 ? (
                          <option value={vendors[0].id}>
                            {vendors[0].site_name}
                          </option>
                        ) : (
                          <>
                            <option value={0}>Please Select Your Venue</option>
                            {vendors &&
                              vendors.map((e) => {
                                return (
                                  <option value={e.id}>{e.site_name}</option>
                                );
                              })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  ) : null}
                </Col>

                <Col xs={12} md={4}>
                  <p>Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <p>End Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Button
                    style={{ marginTop: 30 }}
                    onClick={() => this.getOrders()}
                  >
                    Apply
                  </Button>
                </Col>

                {selectedVendorId != "-1" ? (
                  <Col xs={12} md={3} lg={3}>
                    <Form.Label>Select Time</Form.Label>
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={this.state.selectedDate}
                        onChange={(e) => {
                          this.setState(
                            { selectedDate: e.target.value },
                            () => {
                              this.getOrders();
                            }
                          );
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Sales:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    ${Number(total_sales).toFixed(2) + "/" + total_orders}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Cash</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCash).toFixed(2) + "/" + totalCashOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>Total Card</h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    {Number(totalCard).toFixed(2) + "/" + totalCardOrders}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Total Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{total_orders}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunds:</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>${Number(canceled_sales).toFixed(2)}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>Refunded Orders</h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>{canceled_orders}</h5>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Sales Less Refund:</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>
                      $
                      {Number(
                        Number(total_sales) - Number(canceled_sales)
                      ).toFixed(2)}
                    </b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Cash Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCash) - Number(canceled_sales_cash)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Card Less Refund:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(totalCard) - Number(canceled_sales_card)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>Total Voucher Values:</b>
                  </h6>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6>
                    <b>
                      $
                      {Number(
                        Number(total_voucher_value) -
                          Number(canceled_sales_card)
                      ).toFixed(2)}
                    </b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>Total Orders Less Refunded Orders</b>
                  </h5>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h5>
                    <b>{total_orders - canceled_orders}</b>
                  </h5>
                </Col>
              </Row>
              {/* <Row>
              <Col xs={12} md={12} lg={12}>
                <Bar
                  data={{
                    labels: this.state.salesByHoursLabels,
                    datasets: [
                      {
                        label: "Sales by hours ($)",
                        data: this.state.salesByHoursData,
                        backgroundColor: this.getBackgroundColor(
                          this.state.salesByHoursLabels.length
                        ),

                        borderWidth: 1,
                      },
                    ],
                  }}
                  height={400}
                  width={600}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}xw
                />
              </Col>
            </Row> */}

              <hr />

              {this.state.selectedVendorId != undefined ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserBarGraph
                      vendorName={this.state.salesByVendorName}
                      vendorData={this.state.salesByVendorTotal}
                      isBool={true}
                    ></EventOrganiserBarGraph>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <EventOrganiserBarGraph
                    vendorName={this.state.salesByVendorName}
                    vendorData={this.state.salesByVendorTotal}
                    isBool={true}
                  ></EventOrganiserBarGraph>
                </Row>
              )}

              {this.state.selectedVendorId != undefined ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventSalesBarGraph
                      vendorName={this.state.salesByEventName}
                      vendorData={this.state.salesByEventTotal}
                      isBool={true}
                    ></EventSalesBarGraph>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <EventSalesBarGraph
                    vendorName={this.state.salesByEventName}
                    vendorData={this.state.salesByEventTotal}
                    isBool={true}
                  ></EventSalesBarGraph>
                </Row>
              )}

              <SalesTable sales_by_vendor_obj={salesByVendorObj}></SalesTable>
              <SalesByEventTabel
                sales_by_vendor_obj={salesByEventObj}
              ></SalesByEventTabel>

              {/* <div className="my-3">
              <h3>Items Sold:</h3>
              {this.state.newMealFrequency[0] && (
                <TableData tableData={this.state.newMealFrequency}></TableData>
              )}
            </div>
            <div
              style={{
                height: window.innerHeight / 3,
                overflow: "scroll",
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >

            </div> */}
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OrganiserSiteAreaScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
