import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./SignaturePad.css"; // Import your CSS file for styling

const SignaturePad = ({ onSave }) => {
  const signatureRef = useRef();

  const handleClear = () => {
    signatureRef.current.clear();
  };

  const handleSave = () => {
    const signatureData = signatureRef.current.toDataURL(); // Convert to JPEG
    onSave(signatureData); // Pass the signature data back to the parent component
    console.log("Signature Data:", signatureData);
  };

  return (
    <div className="signature-container">
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        ref={signatureRef}
      />
      <div className="button-container">
        <button className="clear-button" onClick={handleClear}>
          Clear
        </button>
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;
