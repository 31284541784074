const orders = [
    {
        "id": 12697,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:44:04.398171Z",
        "status": "Finished",
        "payment_receipt_number": "nY69nPr7PqaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33211,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12696,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:40:09.994554Z",
        "status": "Finished",
        "payment_receipt_number": "VACraeNhWuhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:39 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33210,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12695,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:39:20.220467Z",
        "status": "Finished",
        "payment_receipt_number": "4NHZfIgOJIpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33209,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12694,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.648646Z",
        "status": "Finished",
        "payment_receipt_number": "lv8NDnk3KzgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:37 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33208,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12693,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.618096Z",
        "status": "Finished",
        "payment_receipt_number": "OOuVpttXczjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:37 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33207,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12692,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.587379Z",
        "status": "Finished",
        "payment_receipt_number": "dUwqsxNZZIeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33206,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12691,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.550252Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-36-27",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33205,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12690,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.494323Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-36-01",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33204,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12689,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.460973Z",
        "status": "Finished",
        "payment_receipt_number": "StombxjEgzbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.9,
        "order_date": "27 Mar 2022",
        "order_time": "10:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33203,
                "meal": null,
                "meal__name": "The Shroomin'",
                "event_meal__name": "The Shroomin'",
                "meal__price": 13.9,
                "price": 13.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12688,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:38:27.416730Z",
        "status": "Finished",
        "payment_receipt_number": "292pmIL9erVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33202,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12687,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:35:09.270206Z",
        "status": "Finished",
        "payment_receipt_number": "Uc1bQBTLl3pqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:34 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33201,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12686,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:35:09.239096Z",
        "status": "Finished",
        "payment_receipt_number": "SeNsE9zm8feqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:33 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33200,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12685,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:35:09.212388Z",
        "status": "Finished",
        "payment_receipt_number": "Mb3GZb7HomfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 15.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 15.9,
        "order_date": "27 Mar 2022",
        "order_time": "10:33 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33199,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12684,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:35:09.174110Z",
        "status": "Finished",
        "payment_receipt_number": "qrnvgcpLlNqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:32 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33198,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12683,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:32:16.052195Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-31-32",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:31 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33197,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12682,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:31:28.480907Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-30-49",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33196,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12681,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:31:28.445236Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-30-15",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33195,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12680,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:31:28.410665Z",
        "status": "Finished",
        "payment_receipt_number": "fBfV4gIRcfeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33194,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12679,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:31:28.373998Z",
        "status": "Finished",
        "payment_receipt_number": "xmqxhZxz8XiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33193,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12678,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:31:06.556823Z",
        "status": "Finished",
        "payment_receipt_number": "9jt063iuaEWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12,
        "order_date": "27 Mar 2022",
        "order_time": "10:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33191,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33192,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12677,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:31:06.526014Z",
        "status": "Finished",
        "payment_receipt_number": "J78e28VNXYlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33190,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12676,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:29:10.195729Z",
        "status": "Finished",
        "payment_receipt_number": "D1aNe69v0bXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33189,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12675,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:29:10.154254Z",
        "status": "Finished",
        "payment_receipt_number": "bXFsc6ss5IjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12,
        "order_date": "27 Mar 2022",
        "order_time": "10:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33187,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33188,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12674,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:29:10.106825Z",
        "status": "Finished",
        "payment_receipt_number": "fjQkIBX7OnaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "10:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33185,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33186,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12673,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:28:26.092174Z",
        "status": "Finished",
        "payment_receipt_number": "9CmObMYLuviqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33184,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12672,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:28:26.044419Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-27-17",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33183,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12671,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:56.169983Z",
        "status": "Finished",
        "payment_receipt_number": "JNqUDk7DDobqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33181,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33182,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12670,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:56.139991Z",
        "status": "Finished",
        "payment_receipt_number": "CROTJUWRJBWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33180,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12669,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:56.103201Z",
        "status": "Finished",
        "payment_receipt_number": "f1sXrrd1YNrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33178,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33179,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12668,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:56.063144Z",
        "status": "Finished",
        "payment_receipt_number": "ywkfbVx1N0qqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33176,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33177,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12667,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:56.020842Z",
        "status": "Finished",
        "payment_receipt_number": "x3C4BqYdkxlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12,
        "order_date": "27 Mar 2022",
        "order_time": "10:23 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33174,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33175,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12666,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:55.980536Z",
        "status": "Finished",
        "payment_receipt_number": "zlYso0AaQCpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:23 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33172,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33173,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12665,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:26:55.945583Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-22-55",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:22 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33171,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12664,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:25:59.487122Z",
        "status": "Finished",
        "payment_receipt_number": "TgjGM3rd7rVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33170,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12663,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:25:16.897665Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-24-32",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33169,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12662,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:25:16.840506Z",
        "status": "Finished",
        "payment_receipt_number": "3N1Lnx55JmqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 21,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 21,
        "order_date": "27 Mar 2022",
        "order_time": "10:23 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33165,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33166,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33167,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33168,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12661,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:23:20.042150Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-21-59",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "10:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33163,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33164,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12660,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:23:19.980159Z",
        "status": "Finished",
        "payment_receipt_number": "KLe8yE2jvUlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 8,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 8,
        "order_date": "27 Mar 2022",
        "order_time": "10:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33161,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33162,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12659,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:23:19.938861Z",
        "status": "Finished",
        "payment_receipt_number": "U0lBhsmpiuiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33160,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12658,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:23:19.900208Z",
        "status": "Finished",
        "payment_receipt_number": "xtd0DFcEsWhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33159,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12657,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.565563Z",
        "status": "Finished",
        "payment_receipt_number": "FoGku7JejwlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33158,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12656,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.524579Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-21-14",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33156,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33157,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12655,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.486100Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-20-25",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33155,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12654,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.433144Z",
        "status": "Finished",
        "payment_receipt_number": "5WFSMFKIH3oqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:19 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33152,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33153,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33154,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12653,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.400662Z",
        "status": "Finished",
        "payment_receipt_number": "iawyGumgZ9bqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:18 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33151,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12652,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.362202Z",
        "status": "Finished",
        "payment_receipt_number": "g3vgKtSJ70qqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:18 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33150,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12651,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:22:16.321152Z",
        "status": "Finished",
        "payment_receipt_number": "V5AFT4Oi5bVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33149,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12650,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.581938Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-19-38",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "10:19 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33147,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33148,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12649,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.544349Z",
        "status": "Finished",
        "payment_receipt_number": "W0EynyVoDBrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:18 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33146,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12648,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.498889Z",
        "status": "Finished",
        "payment_receipt_number": "RzmNiCD4xmWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 8,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 8,
        "order_date": "27 Mar 2022",
        "order_time": "10:18 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33144,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33145,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12647,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.453181Z",
        "status": "Finished",
        "payment_receipt_number": "gr1MNel17TiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 15.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 15.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33142,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33143,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12646,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.415776Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-17-14",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33141,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12645,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.379474Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-16-49",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33140,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12644,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:20:23.330648Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-16-17",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "10:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33138,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33139,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12643,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:17:42.750939Z",
        "status": "Finished",
        "payment_receipt_number": "OFICMlaf32iqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33136,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33137,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12642,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:17:42.692516Z",
        "status": "Finished",
        "payment_receipt_number": "fSxveqJPKpVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33135,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12641,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.931016Z",
        "status": "Finished",
        "payment_receipt_number": "3ydTvykdsahqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33133,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33134,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12640,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.899114Z",
        "status": "Finished",
        "payment_receipt_number": "DwQZ1BTYnMeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33132,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12639,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.867479Z",
        "status": "Finished",
        "payment_receipt_number": "oUr0cthdnfVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33131,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12638,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.828113Z",
        "status": "Finished",
        "payment_receipt_number": "6vW4itPIcWiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 8,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 8,
        "order_date": "27 Mar 2022",
        "order_time": "10:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33129,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33130,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12637,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.793679Z",
        "status": "Finished",
        "payment_receipt_number": "IOlGl85VYyhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33128,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12636,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.753206Z",
        "status": "Finished",
        "payment_receipt_number": "13TEfWG0voiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33127,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12635,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:16:17.720391Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-11-58",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33126,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12634,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.293010Z",
        "status": "Finished",
        "payment_receipt_number": "jRRsFUE00WiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 31.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 31.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:15 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33122,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33123,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33124,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33125,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12633,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.253500Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-14-54",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33120,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33121,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12632,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.221300Z",
        "status": "Finished",
        "payment_receipt_number": "JDWw5hB6njVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33119,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12631,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.184034Z",
        "status": "Finished",
        "payment_receipt_number": "WfWTD4HgjmqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33118,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12630,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.142933Z",
        "status": "Finished",
        "payment_receipt_number": "tMXrprC7HuaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.9,
        "order_date": "27 Mar 2022",
        "order_time": "10:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33116,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33117,
                "meal": null,
                "meal__name": "The Shroomin'",
                "event_meal__name": "The Shroomin'",
                "meal__price": 13.9,
                "price": 13.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12629,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.109507Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-12-46",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33115,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12628,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.077168Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-11-59",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33114,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12627,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.045295Z",
        "status": "Finished",
        "payment_receipt_number": "nAefQFOs5koqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "10:10 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33113,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12626,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:15:49.001183Z",
        "status": "Finished",
        "payment_receipt_number": "EIX8qmMyx3WqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:10 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33112,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12625,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:08:28.414144Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T22-07-44",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "10:07 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33111,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12624,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:07:56.495256Z",
        "status": "Finished",
        "payment_receipt_number": "wCwErcIInoYqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:07 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33110,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12623,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T11:07:13.311442Z",
        "status": "Finished",
        "payment_receipt_number": "EkaNFVo90YdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:06 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33109,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12622,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:06:30.259399Z",
        "status": "Finished",
        "payment_receipt_number": "594BCITnWypqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "10:05 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33107,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33108,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12621,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:06:30.216351Z",
        "status": "Finished",
        "payment_receipt_number": "VJ059oKT5IrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:05 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33106,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12620,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T11:02:56.205045Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T22-02-10",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "10:02 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33105,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12619,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T10:59:49.622318Z",
        "status": "Finished",
        "payment_receipt_number": "5vDOsT0Nl2WqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "09:59 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33104,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12618,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T10:59:21.785911Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T21-58-38",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "09:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33103,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12617,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T10:58:22.443357Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T21-57-32",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "09:57 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33102,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12616,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T10:53:14.642378Z",
        "status": "Finished",
        "payment_receipt_number": "yLx5rULtPmfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "09:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33101,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12615,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T10:48:33.687043Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T21-47-53",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 34,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 34,
        "order_date": "27 Mar 2022",
        "order_time": "09:47 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33098,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33099,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33100,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12614,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T10:43:52.223689Z",
        "status": "Finished",
        "payment_receipt_number": "RTxpXeunu6mqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "09:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33097,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12613,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T10:43:42.878388Z",
        "status": "Finished",
        "payment_receipt_number": "Mnwc3vcYMNXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "09:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33096,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12612,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T09:53:43.570483Z",
        "status": "Finished",
        "payment_receipt_number": "fldyHtZYbyaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "08:52 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33095,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12611,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T09:45:06.809529Z",
        "status": "Finished",
        "payment_receipt_number": "19tGmHjx0zcqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 15.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 15.9,
        "order_date": "27 Mar 2022",
        "order_time": "08:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33094,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12610,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T09:32:48.989957Z",
        "status": "Finished",
        "payment_receipt_number": "A5Bo5ab89IpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "08:32 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33093,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12609,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T09:30:23.141839Z",
        "status": "Finished",
        "payment_receipt_number": "HxwbPnfHlqgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "08:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33090,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33091,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33092,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12608,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T09:15:24.681452Z",
        "status": "Finished",
        "payment_receipt_number": "pFQOMhbZAckqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "08:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33089,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12607,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T09:15:15.831915Z",
        "status": "Finished",
        "payment_receipt_number": "MULWaULZ8YeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "08:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33088,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12606,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T09:15:15.798901Z",
        "status": "Finished",
        "payment_receipt_number": "ogyjbMlyujqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "08:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33087,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12605,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T09:04:42.687643Z",
        "status": "Finished",
        "payment_receipt_number": "zsetCG2nYBrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "08:04 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33086,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12604,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T09:03:41.079358Z",
        "status": "Finished",
        "payment_receipt_number": "GgozwcQR72WqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "08:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33085,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12603,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:59:04.277759Z",
        "status": "Finished",
        "payment_receipt_number": "jZIcjvu08tWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33083,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33084,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12602,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:58:56.616283Z",
        "status": "Finished",
        "payment_receipt_number": "xVjfqEFhEAoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 24.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 24.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33081,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33082,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12601,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:54:19.021966Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T19-53-31",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "07:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33080,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12600,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:51:37.962167Z",
        "status": "Finished",
        "payment_receipt_number": "E3B1DXkWFdaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:50 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33079,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12599,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:51:19.427802Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T19-50-40",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:50 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33078,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12598,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:49:57.490790Z",
        "status": "Finished",
        "payment_receipt_number": "hs8HBPUnaBgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 26,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 26,
        "order_date": "27 Mar 2022",
        "order_time": "07:49 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33076,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33077,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12597,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:48:44.795736Z",
        "status": "Finished",
        "payment_receipt_number": "1eTk79m5KOaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "07:48 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33075,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12596,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:48:40.111628Z",
        "status": "Finished",
        "payment_receipt_number": "aN3gaP1mNjoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "07:47 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33073,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33074,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12595,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:46:36.089526Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-45-52",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "07:45 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33072,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12594,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:45:55.375556Z",
        "status": "Finished",
        "payment_receipt_number": "BNKB8WNRzIfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:45 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33071,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12593,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:45:55.340730Z",
        "status": "Finished",
        "payment_receipt_number": "8YT1QRD8T3qqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33070,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12592,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:45:36.170967Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-44-52",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33069,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12591,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:44:44.828278Z",
        "status": "Finished",
        "payment_receipt_number": "LxEdr6k70HiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 29,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 29,
        "order_date": "27 Mar 2022",
        "order_time": "07:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33067,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33068,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12590,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:37:00.054940Z",
        "status": "Finished",
        "payment_receipt_number": "bQfJH7qVA1eqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33066,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12589,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:36:09.077327Z",
        "status": "Finished",
        "payment_receipt_number": "QvxNuDgXL9eqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33065,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12588,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:36:09.043692Z",
        "status": "Finished",
        "payment_receipt_number": "kb1rYoSe6MlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33064,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12587,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:36:08.998403Z",
        "status": "Finished",
        "payment_receipt_number": "NX83f1aL2mdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:34 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33062,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33063,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12586,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:34:58.691251Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-34-15",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "07:34 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33061,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12585,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:34:08.484499Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-33-24",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "07:33 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33059,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33060,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12584,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:31:04.427524Z",
        "status": "Finished",
        "payment_receipt_number": "WYaJDMienCeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33056,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33057,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33058,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12583,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:30:29.723824Z",
        "status": "Finished",
        "payment_receipt_number": "Vo9nekfC12jqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 21,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 21,
        "order_date": "27 Mar 2022",
        "order_time": "07:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33052,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33053,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33054,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33055,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12582,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:29:03.735128Z",
        "status": "Finished",
        "payment_receipt_number": "gg1DZAw8zLmqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33051,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12581,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:28:11.871491Z",
        "status": "Finished",
        "payment_receipt_number": "WV55hiXQT8hqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33050,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12580,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:27:10.652838Z",
        "status": "Finished",
        "payment_receipt_number": "iWwhj3PlEqjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 45,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 45,
        "order_date": "27 Mar 2022",
        "order_time": "07:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33046,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33047,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33048,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33049,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12579,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:27:10.610724Z",
        "status": "Finished",
        "payment_receipt_number": "9LQ4O840MynqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "07:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33044,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33045,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12578,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:26:23.427634Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T19-25-50",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 28.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 28.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33040,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33041,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33042,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33043,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12577,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:25:28.412334Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-24-43",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33039,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12576,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:25:28.379066Z",
        "status": "Finished",
        "payment_receipt_number": "npo1MFbPHQbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33038,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12575,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:23:41.526420Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T19-22-57",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:22 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33036,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33037,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12574,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:23:07.391883Z",
        "status": "Finished",
        "payment_receipt_number": "jHsk4PqqariqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 34,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 34,
        "order_date": "27 Mar 2022",
        "order_time": "07:22 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33033,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33034,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33035,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12573,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:21:33.592262Z",
        "status": "Finished",
        "payment_receipt_number": "vASX0c34NLjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.4,
        "order_date": "27 Mar 2022",
        "order_time": "07:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33031,
                "meal": null,
                "meal__name": "The Shroomin'",
                "event_meal__name": "The Shroomin'",
                "meal__price": 13.9,
                "price": 13.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33032,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12572,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:21:27.853979Z",
        "status": "Finished",
        "payment_receipt_number": "9YvJ4gVskbWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33030,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12571,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:20:09.663560Z",
        "status": "Finished",
        "payment_receipt_number": "WEH5ZdwpmjbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:19 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33028,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33029,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12570,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:18:30.661441Z",
        "status": "Finished",
        "payment_receipt_number": "0QzhGVRDXDqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33026,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33027,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12569,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:18:30.620309Z",
        "status": "Finished",
        "payment_receipt_number": "bwUqhl468keqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33024,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33025,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12568,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:16:06.412382Z",
        "status": "Finished",
        "payment_receipt_number": "BEEGNpigucYqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:15 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33023,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12567,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:15:21.596755Z",
        "status": "Finished",
        "payment_receipt_number": "cipPmNdWLlgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33022,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12566,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:15:21.557200Z",
        "status": "Finished",
        "payment_receipt_number": "Nsx1k6UTlUpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33020,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33021,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12565,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:15:21.525304Z",
        "status": "Finished",
        "payment_receipt_number": "tMBrMOiB9MbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33019,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12564,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:15:21.485238Z",
        "status": "Finished",
        "payment_receipt_number": "hR6ADpP9fieqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33018,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12563,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:15:21.436454Z",
        "status": "Finished",
        "payment_receipt_number": "Y0K9mwX5lRoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 49.3,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 49.3,
        "order_date": "27 Mar 2022",
        "order_time": "07:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33015,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33016,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33017,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12562,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:12:44.256438Z",
        "status": "Finished",
        "payment_receipt_number": "dsLROMggnJpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33013,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33014,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12561,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:10:36.100572Z",
        "status": "Finished",
        "payment_receipt_number": "3mUhYbDav9hqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "07:09 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33012,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12560,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:10:26.295302Z",
        "status": "Finished",
        "payment_receipt_number": "7Q2iwuwJ0nZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "07:09 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33009,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33010,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33011,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12559,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:09:46.083130Z",
        "status": "Finished",
        "payment_receipt_number": "YMkKDaA87dmqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "07:09 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33008,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12558,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:08:16.271468Z",
        "status": "Finished",
        "payment_receipt_number": "1oWI7RRiVjgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:07 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33007,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12557,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:07:25.320314Z",
        "status": "Finished",
        "payment_receipt_number": "bZrTuz3fcFgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:07 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33006,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12556,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:07:05.664780Z",
        "status": "Finished",
        "payment_receipt_number": "uUoNbzD0XbhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:06 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33005,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12555,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:05:50.247664Z",
        "status": "Finished",
        "payment_receipt_number": "aAoNuELgBYeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 35,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 35,
        "order_date": "27 Mar 2022",
        "order_time": "07:05 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33003,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33004,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12554,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:04:33.724357Z",
        "status": "Finished",
        "payment_receipt_number": "0Ah15R29oBrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 27,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 27,
        "order_date": "27 Mar 2022",
        "order_time": "07:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 33001,
                "meal": null,
                "meal__name": "Chicken Mary's Platter",
                "event_meal__name": "Chicken Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33002,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12553,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:04:23.673581Z",
        "status": "Finished",
        "payment_receipt_number": "KcSHCyH0JyhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 40.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 40.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32996,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32997,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32998,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32999,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 33000,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12552,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:04:23.628509Z",
        "status": "Finished",
        "payment_receipt_number": "cqMXK4JIr3bqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 27,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 27,
        "order_date": "27 Mar 2022",
        "order_time": "07:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32993,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32994,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32995,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12551,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:04:23.589470Z",
        "status": "Finished",
        "payment_receipt_number": "51aw5m6raZjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:02 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32991,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32992,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12550,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:04:23.556216Z",
        "status": "Finished",
        "payment_receipt_number": "E7Gycgf3sAlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:01 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32990,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12549,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:03:19.001586Z",
        "status": "Finished",
        "payment_receipt_number": "9mZpZ76BRBdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:02 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32989,
                "meal": null,
                "meal__name": "Chicken Mary's Platter",
                "event_meal__name": "Chicken Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12548,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:02:16.581074Z",
        "status": "Finished",
        "payment_receipt_number": "5wRehsM8mBiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "07:01 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32988,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12547,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T08:01:34.607811Z",
        "status": "Finished",
        "payment_receipt_number": "O0bqXbPF25hqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 30.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 30.9,
        "order_date": "27 Mar 2022",
        "order_time": "07:00 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32984,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32985,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32986,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32987,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12546,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:01:28.761407Z",
        "status": "Finished",
        "payment_receipt_number": "vkLIQjyEgApqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 43.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 43.5,
        "order_date": "27 Mar 2022",
        "order_time": "07:00 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32980,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32981,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32982,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32983,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12545,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:00:10.541538Z",
        "status": "Finished",
        "payment_receipt_number": "jNqPczIpcfWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14,
        "order_date": "27 Mar 2022",
        "order_time": "06:59 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32977,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32978,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32979,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12544,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T08:00:10.509762Z",
        "status": "Finished",
        "payment_receipt_number": "dQB6zF61JnpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "06:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32976,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12543,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:59:28.464568Z",
        "status": "Finished",
        "payment_receipt_number": "R9BowpVmpwfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 15,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 15,
        "order_date": "27 Mar 2022",
        "order_time": "06:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32973,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32974,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32975,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12542,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:59:28.405159Z",
        "status": "Finished",
        "payment_receipt_number": "c2laj9len6mqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "06:58 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32969,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32970,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32971,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32972,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12541,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:59:28.345269Z",
        "status": "Finished",
        "payment_receipt_number": "XsxC8EuAXcdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 35.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 35.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:57 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32965,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32966,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32967,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32968,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12540,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:57:51.510223Z",
        "status": "Finished",
        "payment_receipt_number": "hGq5nx68FPeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 23,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 23,
        "order_date": "27 Mar 2022",
        "order_time": "06:57 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32963,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32964,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12539,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:57:51.466162Z",
        "status": "Finished",
        "payment_receipt_number": "U6E66Wp1nlnqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:56 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32961,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32962,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12538,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:56:01.659002Z",
        "status": "Finished",
        "payment_receipt_number": "YA3mg3kXibkqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 63,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 63,
        "order_date": "27 Mar 2022",
        "order_time": "06:55 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32955,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32956,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32957,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32958,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32959,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32960,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12537,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:55:12.916965Z",
        "status": "Finished",
        "payment_receipt_number": "yE86HlV72yWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:54 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32953,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32954,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12536,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:55:12.888030Z",
        "status": "Finished",
        "payment_receipt_number": "2HLn1g9JJapqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "06:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32952,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12535,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:55:12.856723Z",
        "status": "Finished",
        "payment_receipt_number": "dLyJsnX605bqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32951,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12534,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:54:33.376000Z",
        "status": "Finished",
        "payment_receipt_number": "fsV3FHpFEedqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32950,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12533,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:53:27.640422Z",
        "status": "Finished",
        "payment_receipt_number": "SR3Y3tz5AJhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:52 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32949,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12532,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:53:05.774371Z",
        "status": "Finished",
        "payment_receipt_number": "4bUuQmr0jZeqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:52 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32948,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12531,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:53:05.736587Z",
        "status": "Finished",
        "payment_receipt_number": "nRuNZHcUk3kqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32946,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32947,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12530,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:53:05.695825Z",
        "status": "Finished",
        "payment_receipt_number": "r0mukLy3HlXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32944,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32945,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12529,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:52:03.564641Z",
        "status": "Finished",
        "payment_receipt_number": "D1dEzPUAOHXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 46.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 46.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32941,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32942,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32943,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12528,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:52:03.512537Z",
        "status": "Finished",
        "payment_receipt_number": "w5o89S0YyiiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 41,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 41,
        "order_date": "27 Mar 2022",
        "order_time": "06:50 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32938,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32939,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32940,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12527,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:50:39.510291Z",
        "status": "Finished",
        "payment_receipt_number": "C3aX29AujjXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 20.4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 20.4,
        "order_date": "27 Mar 2022",
        "order_time": "06:50 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32936,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32937,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12526,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:48:52.656221Z",
        "status": "Finished",
        "payment_receipt_number": "QzObyP270kbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:48 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32935,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12525,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:47:54.728403Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-47-15",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 44,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 44,
        "order_date": "27 Mar 2022",
        "order_time": "06:47 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32931,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32932,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32933,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32934,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12524,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:46:40.237529Z",
        "status": "Finished",
        "payment_receipt_number": "HuQQv7AHg2VqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:45 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32930,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12523,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:46:40.198143Z",
        "status": "Finished",
        "payment_receipt_number": "aDS8s3BS5UZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:45 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32929,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12522,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:44:20.765096Z",
        "status": "Finished",
        "payment_receipt_number": "F2VOk0k2eZWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32927,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32928,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12521,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:43:52.206442Z",
        "status": "Finished",
        "payment_receipt_number": "PdAfgK5a4oXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32926,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12520,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:42:37.030128Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-41-55",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "06:41 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32925,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12519,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:42:36.997199Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-41-18",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:41 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32924,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12518,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:42:36.963704Z",
        "status": "Finished",
        "payment_receipt_number": "GVN1L5YAu3lqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:40 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32923,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12517,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:42:36.924113Z",
        "status": "Finished",
        "payment_receipt_number": "faC3O5VKBJoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17,
        "order_date": "27 Mar 2022",
        "order_time": "06:40 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32921,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32922,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12516,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:41:15.550133Z",
        "status": "Finished",
        "payment_receipt_number": "sha5mdlCmqgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:40 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32920,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12515,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:41:15.504592Z",
        "status": "Finished",
        "payment_receipt_number": "pXiEbFHVE0nqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13,
        "order_date": "27 Mar 2022",
        "order_time": "06:39 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32918,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32919,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12514,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:39:44.040271Z",
        "status": "Finished",
        "payment_receipt_number": "le3VW9OOs7kqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32916,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32917,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12513,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:39:44.004892Z",
        "status": "Finished",
        "payment_receipt_number": "Mn42NI6VPWaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32915,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12512,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:39:17.016362Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-38-33",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32914,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12511,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:39:16.983482Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-38-22",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32913,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12510,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:39:16.943399Z",
        "status": "Finished",
        "payment_receipt_number": "sfiFyhn2s1YqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:37 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32912,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12509,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:36:59.850655Z",
        "status": "Finished",
        "payment_receipt_number": "MMnU61tuf6fqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "06:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32910,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32911,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12508,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:34:46.571051Z",
        "status": "Finished",
        "payment_receipt_number": "IbQhKOkXUPbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:34 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32908,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32909,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12507,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:30:54.827787Z",
        "status": "Finished",
        "payment_receipt_number": "GhXmQiUymecqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "06:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32906,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32907,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12506,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:30:54.791800Z",
        "status": "Finished",
        "payment_receipt_number": "164W0w0DFXdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 20.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 20.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32904,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32905,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12505,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:30:50.113827Z",
        "status": "Finished",
        "payment_receipt_number": "wavn1VJEXMnqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32902,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32903,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12504,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:29:40.332815Z",
        "status": "Finished",
        "payment_receipt_number": "guWtVpdpUibqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 29,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 29,
        "order_date": "27 Mar 2022",
        "order_time": "06:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32899,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32900,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32901,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12503,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:29:40.298992Z",
        "status": "Finished",
        "payment_receipt_number": "HkiOwYvmsxhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32898,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12502,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:29:40.259919Z",
        "status": "Finished",
        "payment_receipt_number": "hYevekUfi0VqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:27 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32896,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32897,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12501,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:28:48.744511Z",
        "status": "Finished",
        "payment_receipt_number": "cVlrhreahDWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32895,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12500,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:27:25.951543Z",
        "status": "Finished",
        "payment_receipt_number": "LIq2Qz74YAfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32894,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12499,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:27:25.918581Z",
        "status": "Finished",
        "payment_receipt_number": "yfX2BiEfqVbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32893,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12498,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:25:12.238799Z",
        "status": "Finished",
        "payment_receipt_number": "TtMm68igMtZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32892,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12497,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:25:12.189810Z",
        "status": "Finished",
        "payment_receipt_number": "XwLx73aWUIfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 14,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 14,
        "order_date": "27 Mar 2022",
        "order_time": "06:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32890,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32891,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12496,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:25:02.205083Z",
        "status": "Finished",
        "payment_receipt_number": "tMO4J6LfTMbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 19.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 19.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32888,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32889,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12495,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:25:02.179330Z",
        "status": "Finished",
        "payment_receipt_number": "zBeji10THIpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 12.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 12.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:23 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32887,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12494,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:23:47.802783Z",
        "status": "Finished",
        "payment_receipt_number": "LZf3lDnEWKdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 35.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 35.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:23 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32885,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32886,
                "meal": null,
                "meal__name": "Trio Of Dips",
                "event_meal__name": "Trio Of Dips",
                "meal__price": 13,
                "price": 13,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12493,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:22:16.241337Z",
        "status": "Finished",
        "payment_receipt_number": "USVDT4Q9kgnqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 35.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 35.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32882,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32883,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32884,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12492,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:20:37.602477Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T18-19-53",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 45,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 45,
        "order_date": "27 Mar 2022",
        "order_time": "06:19 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32878,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32879,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32880,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32881,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12491,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:19:09.496621Z",
        "status": "Finished",
        "payment_receipt_number": "PYuV59dLqWoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 52,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 52,
        "order_date": "27 Mar 2022",
        "order_time": "06:18 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32873,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32874,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32875,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32876,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32877,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12490,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:18:12.282580Z",
        "status": "Finished",
        "payment_receipt_number": "jWpY4X9TbTiqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 21.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 21.9,
        "order_date": "27 Mar 2022",
        "order_time": "06:17 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32871,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32872,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12489,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:17:07.778784Z",
        "status": "Finished",
        "payment_receipt_number": "A4m1KproD7YqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32869,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32870,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12488,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:16:50.826747Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-16-09",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 35.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 35.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32866,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32867,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32868,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12487,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:15:58.640657Z",
        "status": "Finished",
        "payment_receipt_number": "p0gaRJv2yebqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 26.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 26.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:15 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32864,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32865,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12486,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:15:17.352289Z",
        "status": "Finished",
        "payment_receipt_number": "ChQ7ra0wj7ZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 46,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 46,
        "order_date": "27 Mar 2022",
        "order_time": "06:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32859,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32860,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32861,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32862,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32863,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12485,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:14:47.074289Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T18-14-02",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32858,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12484,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:14:47.045037Z",
        "status": "Finished",
        "payment_receipt_number": "nYBzzGl9GBhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32857,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12483,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:13:57.290130Z",
        "status": "Finished",
        "payment_receipt_number": "p793bGdIUihqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 24,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 24,
        "order_date": "27 Mar 2022",
        "order_time": "06:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32854,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32855,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32856,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12482,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:13:57.255988Z",
        "status": "Finished",
        "payment_receipt_number": "SnDuhwClDMrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32852,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32853,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12481,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:13:23.638875Z",
        "status": "Finished",
        "payment_receipt_number": "UM58tfRu7dgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 45,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 45,
        "order_date": "27 Mar 2022",
        "order_time": "06:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32848,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32849,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32850,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32851,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12480,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:13:23.602846Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T18-12-24",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32846,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32847,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12479,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:12:25.133332Z",
        "status": "Finished",
        "payment_receipt_number": "pCncRKRLg8qqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 45,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 45,
        "order_date": "27 Mar 2022",
        "order_time": "06:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32841,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32842,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32843,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32844,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32845,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12478,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:12:03.258434Z",
        "status": "Finished",
        "payment_receipt_number": "XIrhfiD27DqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32839,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32840,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12477,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:11:36.997459Z",
        "status": "Finished",
        "payment_receipt_number": "nSkEoCij91mqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:10 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32838,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12476,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:11:05.374343Z",
        "status": "Finished",
        "payment_receipt_number": "Q4cokcIwjuaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 45,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 45,
        "order_date": "27 Mar 2022",
        "order_time": "06:10 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32833,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32834,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32835,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32836,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32837,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12475,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:11:05.320089Z",
        "status": "Finished",
        "payment_receipt_number": "xULLAAaah8pqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 31,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 31,
        "order_date": "27 Mar 2022",
        "order_time": "06:09 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32830,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32831,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32832,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12474,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:09:27.501254Z",
        "status": "Finished",
        "payment_receipt_number": "2wPeecBToFbqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 44,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 44,
        "order_date": "27 Mar 2022",
        "order_time": "06:08 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32826,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32827,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32828,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32829,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12473,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:09:20.211537Z",
        "status": "Finished",
        "payment_receipt_number": "4KR7KhiPWLXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 38,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 38,
        "order_date": "27 Mar 2022",
        "order_time": "06:08 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32823,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32824,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32825,
                "meal": null,
                "meal__name": "Vege Patch",
                "event_meal__name": "Vege Patch",
                "meal__price": 14.5,
                "price": 14.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12472,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:08:22.832025Z",
        "status": "Finished",
        "payment_receipt_number": "lR5xlNoJ0geqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "06:07 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32821,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32822,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12471,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:06:46.892384Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T18-06-03",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:06 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32820,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12470,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:05:22.721162Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-04-43",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:04 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32819,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12469,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:04:25.007480Z",
        "status": "Finished",
        "payment_receipt_number": "LpfEmY1gBNZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 37,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 37,
        "order_date": "27 Mar 2022",
        "order_time": "06:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32817,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32818,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12468,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:04:24.589239Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-04-16",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:04 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32816,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12467,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:04:24.550432Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T18-03-55",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:03 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32815,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12466,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:03:06.798199Z",
        "status": "Finished",
        "payment_receipt_number": "D2fnSTSGjvZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 23,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 23,
        "order_date": "27 Mar 2022",
        "order_time": "06:02 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32813,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32814,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12465,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:01:50.469476Z",
        "status": "Finished",
        "payment_receipt_number": "cf567KF1crXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "06:01 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32812,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12464,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:01:30.575316Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T18-00-45",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "06:00 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32811,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12463,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T07:01:30.534104Z",
        "status": "Finished",
        "payment_receipt_number": "lJwzmkvs0yVqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4,
        "order_date": "27 Mar 2022",
        "order_time": "05:59 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32810,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12462,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T07:00:13.083306Z",
        "status": "Finished",
        "payment_receipt_number": "TSvRIB3ss9XqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:59 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32806,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32807,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32808,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32809,
                "meal": null,
                "meal__name": "OG Cheesy Pour",
                "event_meal__name": "OG Cheesy Pour",
                "meal__price": 2.5,
                "price": 2.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12461,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:58:01.751357Z",
        "status": "Finished",
        "payment_receipt_number": "xuty5PBqUxpqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:57 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32805,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12460,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.607674Z",
        "status": "Finished",
        "payment_receipt_number": "lA7FhsNwUApqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:56 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32804,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12459,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.579014Z",
        "status": "Finished",
        "payment_receipt_number": "nXcLyRhB0AqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:56 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32803,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12458,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.543865Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T17-55-35",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:55 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32802,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12457,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.514468Z",
        "status": "Finished",
        "payment_receipt_number": "xyJChJbBjHrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:54 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32801,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12456,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.466245Z",
        "status": "Finished",
        "payment_receipt_number": "tHIzJvn7KKrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 43.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 43.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:54 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32797,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32798,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32799,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32800,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12455,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:57:08.429628Z",
        "status": "Finished",
        "payment_receipt_number": "ga0PQCq5O4kqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32795,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32796,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12454,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:56:49.342820Z",
        "status": "Finished",
        "payment_receipt_number": "DSKPQBo3KqZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:56 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32794,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12453,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:55:35.185413Z",
        "status": "Finished",
        "payment_receipt_number": "J6eNjqpMRPnqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 24,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 24,
        "order_date": "27 Mar 2022",
        "order_time": "05:54 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32791,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32792,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32793,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12452,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:53:40.420049Z",
        "status": "Finished",
        "payment_receipt_number": "IuMfwu9U8UjqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "05:53 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32789,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32790,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12451,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:52:46.239345Z",
        "status": "Finished",
        "payment_receipt_number": "nhGSLVQd7UlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:52 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32788,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12450,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:52:05.470258Z",
        "status": "Finished",
        "payment_receipt_number": "bRmvirTxhSoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32787,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12449,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:52:05.427292Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T17-51-06",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32786,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12448,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:51:50.558904Z",
        "status": "Finished",
        "payment_receipt_number": "MXuDRQdHc6eqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:51 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32785,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12447,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:45:33.378424Z",
        "status": "Finished",
        "payment_receipt_number": "fkSpzQtgKWWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32784,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12446,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:45:33.344605Z",
        "status": "Finished",
        "payment_receipt_number": "Fhfy4UoyxZgqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32781,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32782,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32783,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12445,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:45:33.304988Z",
        "status": "Finished",
        "payment_receipt_number": "Fd5bWksqsepqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32779,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32780,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12444,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:45:33.272147Z",
        "status": "Finished",
        "payment_receipt_number": "RVtkSkGET2ZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:43 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32777,
                "meal": null,
                "meal__name": "Regular Fries",
                "event_meal__name": "Regular Fries",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32778,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12443,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:45:26.986114Z",
        "status": "Finished",
        "payment_receipt_number": "TY6wbVlzCPlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10,
        "order_date": "27 Mar 2022",
        "order_time": "05:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32775,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32776,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12442,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:45:26.949223Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T17-44-02",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 10.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 10.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:44 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32773,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32774,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12441,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:43:37.712853Z",
        "status": "Finished",
        "payment_receipt_number": "zJzE8d2CdsYqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 26.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 26.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:42 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32770,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32771,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32772,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12440,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:43:37.683628Z",
        "status": "Finished",
        "payment_receipt_number": "TvdCwnRHQmaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:42 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32769,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12439,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:42:34.629701Z",
        "status": "Finished",
        "payment_receipt_number": "5PptpB3IYObqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:41 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32768,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12438,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:40:44.816468Z",
        "status": "Finished",
        "payment_receipt_number": "s4Bh78mUfufqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:40 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32767,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12437,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:39:05.551285Z",
        "status": "Finished",
        "payment_receipt_number": "BrThYe4u94ZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 22.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 22.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:38 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32765,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32766,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12436,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:39:05.503333Z",
        "status": "Finished",
        "payment_receipt_number": "n9tlI3F6EZqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 26.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 26.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:37 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32762,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32763,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32764,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12435,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:39:05.462218Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T17-37-12",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:37 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32761,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12434,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:37:25.149475Z",
        "status": "Finished",
        "payment_receipt_number": "IRQAldnwaqWqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:36 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32760,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12433,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:36:47.046594Z",
        "status": "Finished",
        "payment_receipt_number": "l9yJzn1163kqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 8.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 8.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32758,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32759,
                "meal": null,
                "meal__name": "Sunkist 375ml",
                "event_meal__name": "Sunkist 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12432,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:36:47.013004Z",
        "status": "Finished",
        "payment_receipt_number": "IDoLbjJzt6dqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:35 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32757,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12431,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:34:57.573346Z",
        "status": "Finished",
        "payment_receipt_number": "WMQcuLbAqTXqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "05:34 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32755,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32756,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12430,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:34:57.531719Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T17-33-41",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "05:33 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32753,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32754,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12429,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:31:46.441432Z",
        "status": "Finished",
        "payment_receipt_number": "z6RJUP89K0YqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:31 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32752,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12428,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:31:46.400852Z",
        "status": "Finished",
        "payment_receipt_number": "X28DJtcwjvoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17,
        "order_date": "27 Mar 2022",
        "order_time": "05:30 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32750,
                "meal": null,
                "meal__name": "The Cheesy OG",
                "event_meal__name": "The Cheesy OG",
                "meal__price": 12.5,
                "price": 12.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32751,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12427,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:30:36.257474Z",
        "status": "Finished",
        "payment_receipt_number": "Y5Xht2070RkqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 26.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 26.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:29 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32748,
                "meal": null,
                "meal__name": "Lamb Mary's Platter",
                "event_meal__name": "Lamb Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32749,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12426,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:29:39.343268Z",
        "status": "Finished",
        "payment_receipt_number": "TNTDK0ZnVyZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "05:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32746,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32747,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12425,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:29:21.620649Z",
        "status": "Finished",
        "payment_receipt_number": "pTrybaguFjaqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:28 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32744,
                "meal": null,
                "meal__name": "Large Chips",
                "event_meal__name": "Large Chips",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32745,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12424,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:27:05.867860Z",
        "status": "Finished",
        "payment_receipt_number": "6j3fZHkgXHdqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 64.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 64.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32739,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32740,
                "meal": null,
                "meal__name": "Chicken Mary's Platter",
                "event_meal__name": "Chicken Mary's Platter",
                "meal__price": 22.5,
                "price": 22.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32741,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32742,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32743,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12423,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:27:02.782515Z",
        "status": "Finished",
        "payment_receipt_number": "ZApeeVKSNMcqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 27,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 27,
        "order_date": "27 Mar 2022",
        "order_time": "05:26 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32736,
                "meal": null,
                "meal__name": "Large Fries",
                "event_meal__name": "Large Fries",
                "meal__price": 9,
                "price": 9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32737,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32738,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12422,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:26:04.536373Z",
        "status": "Finished",
        "payment_receipt_number": "AznqzqlViiqqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 66,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 66,
        "order_date": "27 Mar 2022",
        "order_time": "05:25 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32730,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32731,
                "meal": null,
                "meal__name": "Real OG",
                "event_meal__name": "Real OG",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32732,
                "meal": null,
                "meal__name": "The Crispy Bird",
                "event_meal__name": "The Crispy Bird",
                "meal__price": 17.5,
                "price": 17.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32733,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32734,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32735,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12421,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:22:21.074367Z",
        "status": "Finished",
        "payment_receipt_number": "4Nfz3dGxoNcqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "05:21 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32728,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32729,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12420,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:20:48.274543Z",
        "status": "Finished",
        "payment_receipt_number": "Tg4L65DlDRlqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 20.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 20.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:20 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32726,
                "meal": null,
                "meal__name": "Little Chick",
                "event_meal__name": "Little Chick",
                "meal__price": 16.5,
                "price": 16.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32727,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12419,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:20:48.246251Z",
        "status": "Finished",
        "payment_receipt_number": "Rm9Nx3gaJCoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:19 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32725,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12418,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:17:08.398385Z",
        "status": "Finished",
        "payment_receipt_number": "W5eFgGeTjgoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:16 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32723,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32724,
                "meal": null,
                "meal__name": "Trio Of Dips",
                "event_meal__name": "Trio Of Dips",
                "meal__price": 13,
                "price": 13,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12417,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:16:37.046895Z",
        "status": "Finished",
        "payment_receipt_number": "AMWHEHxtoufqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 15.9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 15.9,
        "order_date": "27 Mar 2022",
        "order_time": "05:15 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32722,
                "meal": null,
                "meal__name": "The Texan",
                "event_meal__name": "The Texan",
                "meal__price": 15.9,
                "price": 15.9,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12416,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:14:52.340515Z",
        "status": "Finished",
        "payment_receipt_number": "E3LtDhaY66mqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 6,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 6,
        "order_date": "27 Mar 2022",
        "order_time": "05:14 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32721,
                "meal": null,
                "meal__name": "Regular Chips",
                "event_meal__name": "Regular Chips",
                "meal__price": 6,
                "price": 6,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12415,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:14:33.062095Z",
        "status": "Finished",
        "payment_receipt_number": "smqi8b171djqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:13 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32720,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12414,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:13:06.618573Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T17-12-26",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18,
        "order_date": "27 Mar 2022",
        "order_time": "05:12 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32718,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32719,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12413,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:12:15.955529Z",
        "status": "Finished",
        "payment_receipt_number": "MGWDwuL1khoqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32717,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12412,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:12:15.918455Z",
        "status": "Finished",
        "payment_receipt_number": "3_2022-03-27T17-11-03",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:11 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32716,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12411,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T06:03:11.866272Z",
        "status": "Finished",
        "payment_receipt_number": "Ot28MBlK1YnqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 18.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 18.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:02 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32715,
                "meal": null,
                "meal__name": "Ms Mary's Lamb",
                "event_meal__name": "Ms Mary's Lamb",
                "meal__price": 18.5,
                "price": 18.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12410,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T06:02:00.533537Z",
        "status": "Finished",
        "payment_receipt_number": "BLrrXSzXjHZqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "05:01 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32714,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12409,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T05:50:47.516840Z",
        "status": "Finished",
        "payment_receipt_number": "e02IuaMy3CrqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 9,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 9,
        "order_date": "27 Mar 2022",
        "order_time": "04:50 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32712,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32713,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12408,
        "customer": null,
        "customer_name": "MaryPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-27T05:48:40.747969Z",
        "status": "Finished",
        "payment_receipt_number": "1_2022-03-27T16-47-57",
        "payment_status": "COMPLETED",
        "payment_source": "CASH",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 17.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 17.5,
        "order_date": "27 Mar 2022",
        "order_time": "04:47 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32709,
                "meal": null,
                "meal__name": "Chips & Fetta",
                "event_meal__name": "Chips & Fetta",
                "meal__price": 9.5,
                "price": 9.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32710,
                "meal": null,
                "meal__name": "Solo 375ml",
                "event_meal__name": "Solo 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32711,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12407,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T05:47:20.808786Z",
        "status": "Finished",
        "payment_receipt_number": "vtDGsSKDPkfqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "27 Mar 2022",
        "order_time": "04:46 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32708,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12406,
        "customer": null,
        "customer_name": "RealOGPOS1",
        "customer__phone": null,
        "customer__email": null,
        "vendor": 3197,
        "event_name": "",
        "created_at": "2022-03-27T05:44:15.103090Z",
        "status": "Finished",
        "payment_receipt_number": "bjTLwNccozhqO",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 13.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 13.5,
        "order_date": "27 Mar 2022",
        "order_time": "04:24 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32707,
                "meal": null,
                "meal__name": "The Traditional OG",
                "event_meal__name": "The Traditional OG",
                "meal__price": 13.5,
                "price": 13.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12051,
        "customer": 6752,
        "customer_name": "Sahaj",
        "customer__phone": "+61452641992",
        "customer__email": "sahaj@kitchaco.com",
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-25T04:53:34.640558Z",
        "status": "Received",
        "payment_receipt_number": "",
        "payment_status": "",
        "payment_source": "",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 0,
        "order_date": "27 Mar 2022",
        "order_time": "06:15 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "Rod Laver Arena/Melbourne Park, Melbourne VIC 3004, Australia",
        "event_order_pickup_time": "",
        "meal": [
            {
                "id": 32116,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12050,
        "customer": 6752,
        "customer_name": "Sahaj",
        "customer__phone": "+61452641992",
        "customer__email": "sahaj@kitchaco.com",
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-25T04:49:15.706441Z",
        "status": "Received",
        "payment_receipt_number": "HVVm",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0.95,
        "payment_txn_charges": 0.2,
        "sub_total": 16.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 16.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:00 PM",
        "order_special_request": "",
        "order_later": true,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "Rod Laver Arena/Melbourne Park, Melbourne VIC 3004, Australia",
        "event_order_pickup_time": "",
        "meal": [
            {
                "id": 32113,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32114,
                "meal": null,
                "meal__name": "Pepsi Max 375ml",
                "event_meal__name": "Pepsi Max 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 2,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32115,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12049,
        "customer": 6752,
        "customer_name": "Sahaj",
        "customer__phone": "+61452641992",
        "customer__email": "sahaj@kitchaco.com",
        "vendor": 16,
        "event_name": "",
        "created_at": "2022-03-25T04:37:58.997325Z",
        "status": "Received",
        "payment_receipt_number": "TzZk",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0.49,
        "payment_txn_charges": 0.1,
        "sub_total": 8.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 8.5,
        "order_date": "27 Mar 2022",
        "order_time": "06:00 PM",
        "order_special_request": "",
        "order_later": false,
        "promo_code": "",
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "Rod Laver Arena/Melbourne Park, Melbourne VIC 3004, Australia",
        "event_order_pickup_time": "",
        "meal": [
            {
                "id": 32111,
                "meal": null,
                "meal__name": "Water bottle",
                "event_meal__name": "Water bottle",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            },
            {
                "id": 32112,
                "meal": null,
                "meal__name": "Pepsi 375ml",
                "event_meal__name": "Pepsi 375ml",
                "meal__price": 4,
                "price": 4,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    },
    {
        "id": 12048,
        "customer": 6752,
        "customer_name": "Sahaj",
        "customer__phone": "+61452641992",
        "customer__email": "sahaj@kitchaco.com",
        "vendor": 3197,
        "event_name": null,
        "created_at": "2022-03-25T04:13:30.935451Z",
        "status": "Received",
        "payment_receipt_number": "1Ka3",
        "payment_status": "COMPLETED",
        "payment_source": "CARD",
        "payment_wtt_charges": 0,
        "payment_txn_charges": 0,
        "sub_total": 4.5,
        "discount": 0,
        "service_fee": 0,
        "delivery_charge": 0,
        "payment_total_amount": 4.5,
        "order_date": "26 Mar 2022",
        "order_time": "06:15 PM",
        "order_special_request": "",
        "order_later": false,
        "promo_code": null,
        "order_type": "pickup",
        "delivery_location": "",
        "event_location": "Rod Laver Arena/Melbourne Park, Melbourne VIC 3004, Australia",
        "event_order_pickup_time": null,
        "meal": [
            {
                "id": 32110,
                "meal": null,
                "meal__name": "Water",
                "event_meal__name": "Water",
                "meal__price": 4.5,
                "price": 4.5,
                "combo": [],
                "subitem": [],
                "quantity": 1,
                "special_request": null,
                "request": ""
            }
        ]
    }
]
export default orders