import React from "react";
import { Container } from "react-bootstrap";

const NotFound = ({ title, description }) => {
  return (
    <Container>
      <h2 className="body-content-2" style={{ color: "#222" }}>
        {title}
      </h2>
      <h4
        style={{ color: "#333", textAlign: "center" }}
        className="body-content-2"
      >
        {description}
      </h4>
      <h4>
        <a href="/events" className="body-content-2">
          Go Home
        </a>
      </h4>
    </Container>
  );
};
export default NotFound;
