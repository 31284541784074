import {
  CHANGE_TAB,
  SEND_SMS,
  LOGIN_MODAL_TOGGLE,
  PROFILE_MODAL_TOGGLE,
  LOGIN_COMPLETE,
  LOGOUT,
  GET_USER_PROFILE,
  GET_ALL_VENDORS,
  GET_ALL_LOCATIONS,
  GET_ALL_FILTERS,
  UPDATE_USER_LOCATION,
  GET_SELECTED_VENDOR_DATA,
  CLEAR_SELECTED_VENDOR,
  UPDATE_CART_PRODUCTS,
  UPDATE_CART_TICKET,
  UPDATE_CART_TOTAL,
  UPDATE_ORDER_TYPE,
  UPDATE_ORDER_LOCATION,
  UPDATE_EVENT_ID,
  UPDATE_ORDER_LATER,
  UPDATE_ORDER_DATE,
  UPDATE_ORDER_TIME,
  UPDATE_DELIVERY_CHARGES,
  PAYMENT,
  CHANGE_SELECTED_LOCATION,
  UPDATE_USER_PROFILE,
  CALCULATE_DELIVERY,
  GET_DELIVERY_CHARGES,
  ORDER_NOW,
  ORDER_STATUS,
  GET_PROMO_CODE,
  APPLIED_PROMO_CODE,
  GET_ALL_ORDERS,
  REDIRECT_FROM_WEBSITE,
  TRACK_DRIVER,
  UPDATE_SELECTED_VENDOR,
  SET_BOOKING_REQUEST,
  UPDATE_SERVICE_FEE,
} from "../actions/index";

import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../store/index";
import { applyToken, clearToken } from "../services/api";
import { TRANSACTION_FEE } from "../assets/Constants";

const isEmpty = (obj) => {
  try {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  } catch (error) {
    return true;
  }
};

const initialState = {
  selectedTab:
    getFromLocalStorage("selectedTab") != "" &&
    getFromLocalStorage("selectedTab") != null
      ? getFromLocalStorage("selectedTab")
      : "map",
  loggedIn: getFromLocalStorage("loggedIn") === "true" ? true : false,
  smsSent: false,
  loginModalToggle: false,
  profileModalToggle: false,
  phone:
    getFromLocalStorage("phone") !== "" ? getFromLocalStorage("phone") : "",
  userId:
    getFromLocalStorage("userId") !== "" ? getFromLocalStorage("userId") : "",
  token:
    getFromLocalStorage("token") !== "" ? getFromLocalStorage("token") : "",
  email:
    getFromLocalStorage("email") !== "" ? getFromLocalStorage("email") : "",
  username:
    getFromLocalStorage("username") !== ""
      ? getFromLocalStorage("username")
      : "",
  userAddress:
    getFromLocalStorage("userAddress") !== ""
      ? getFromLocalStorage("userAddress")
      : "",
  userLocation:
    getFromLocalStorage("userLocation") !== ""
      ? getFromLocalStorage("userLocation")
      : "",
  userImg:
    getFromLocalStorage("userImg") !== "" ? getFromLocalStorage("userImg") : "",
  vendorData:
    getFromLocalStorage("vendorData") &&
    JSON.parse(getFromLocalStorage("vendorData")) &&
    JSON.parse(getFromLocalStorage("vendorData")).length > 0
      ? JSON.parse(getFromLocalStorage("vendorData"))
      : new Array(),
  location:
    getFromLocalStorage("location") &&
    JSON.parse(getFromLocalStorage("location")) &&
    JSON.parse(getFromLocalStorage("location")).length > 0
      ? JSON.parse(getFromLocalStorage("location"))
      : new Array(),
  selectedLocation:
    getFromLocalStorage("selectedLocation") != ""
      ? getFromLocalStorage("selectedLocation")
      : "31, Malua Street, Reservoir",
  selectedLocationId:
    getFromLocalStorage("selectedLocationId") != ""
      ? getFromLocalStorage("selectedLocationId")
      : "1",
  //location:new Array(),
  filters: {},
  selectedCategory: "American",
  currentLatitude:
    Number(getFromLocalStorage("currentLatitude")) != 0
      ? Number(getFromLocalStorage("currentLatitude"))
      : 0,
  currentLongitude:
    Number(getFromLocalStorage("currentLongitude")) != 0
      ? Number(getFromLocalStorage("currentLongitude"))
      : 0,
  selectedVendorData: isEmpty(
    JSON.parse(getFromLocalStorage("selectedVendorData"))
  )
    ? new Object()
    : getFromLocalStorage("selectedVendorData"),
  // selectedVendorData:new Object(),
  selectedVendor:
    Number(getFromLocalStorage("selectedVendor")) !== -1
      ? Number(getFromLocalStorage("selectedVendor"))
      : -1,
  products: isEmpty(JSON.parse(getFromLocalStorage("products")))
    ? new Object()
    : JSON.parse(getFromLocalStorage("products")),
  productsTotal:
    Number(getFromLocalStorage("productsTotal")) !== 0
      ? Number(getFromLocalStorage("productsTotal"))
      : 0,
  deliveryCharges:
    Number(getFromLocalStorage("deliveryCharges")) !== 0
      ? Number(getFromLocalStorage("deliveryCharges"))
      : 0,
  orderLocation:
    getFromLocalStorage("orderLocation") !== ""
      ? getFromLocalStorage("orderLocation")
      : "",
  serviceFee:
    Number(getFromLocalStorage("serviceFee")) !== 0
      ? Number(getFromLocalStorage("serviceFee"))
      : 0,
  eventId:
    getFromLocalStorage("eventId") !== "" ? getFromLocalStorage("eventId") : "",
  orderDate:
    getFromLocalStorage("orderDate") !== ""
      ? getFromLocalStorage("orderDate")
      : "",
  orderTime:
    getFromLocalStorage("orderTime") !== ""
      ? getFromLocalStorage("orderTime")
      : "",
  orderLater: getFromLocalStorage("orderLater") === "true" ? true : false,
  orderType:
    getFromLocalStorage("orderType") !== ""
      ? getFromLocalStorage("orderType")
      : "",
  paymentStatus:
    getFromLocalStorage("paymentStatus") !== ""
      ? getFromLocalStorage("paymentStatus")
      : "",
  paymentSource:
    getFromLocalStorage("paymentSource") !== ""
      ? getFromLocalStorage("paymentSource")
      : "",
  paymentReceiptNumber:
    getFromLocalStorage("paymentReceiptNumber") !== ""
      ? getFromLocalStorage("paymentReceiptNumber")
      : "",
  paymentWttCharges:
    Number(getFromLocalStorage("paymentWttCharges")) !== 0
      ? Number(getFromLocalStorage("paymentWttCharges"))
      : 0,
  paymentTotalCharges:
    Number(getFromLocalStorage("paymentTotalCharges")) !== 0
      ? Number(getFromLocalStorage("paymentTotalCharges"))
      : 0,
  paymentError:
    getFromLocalStorage("paymentError") !== ""
      ? getFromLocalStorage("paymentError")
      : "",
  delivery: null,
  deli: 0.0,
  orderPromoCode: new Array(),
  applyPromoCode:
    getFromLocalStorage("applyPromoCode") !== ""
      ? getFromLocalStorage("applyPromoCode")
      : "",
  discount:
    Number(getFromLocalStorage("discount")) !== 0
      ? Number(getFromLocalStorage("discount"))
      : 0,
  orders:
    getFromLocalStorage("orders") &&
    JSON.parse(getFromLocalStorage("orders")) &&
    JSON.parse(getFromLocalStorage("orders")).length > 0
      ? JSON.parse(getFromLocalStorage("orders"))
      : new Array(),
  redirectFromWesbite:
    getFromLocalStorage("redirectFromWesbite") === "true" ? true : false,
  eventTicket: isEmpty(JSON.parse(getFromLocalStorage("eventTicket")))
    ? new Object()
    : JSON.parse(getFromLocalStorage("eventTicket")),
  bookingName:
    getFromLocalStorage("bookingName") !== ""
      ? getFromLocalStorage("bookingName")
      : "",
  bookingEmail:
    getFromLocalStorage("bookingEmail") !== ""
      ? getFromLocalStorage("bookingEmail")
      : "",
  bookingPhone:
    getFromLocalStorage("bookingPhone") !== ""
      ? getFromLocalStorage("bookingPhone")
      : "",
  bookingLocation:
    getFromLocalStorage("bookingLocation") !== ""
      ? getFromLocalStorage("bookingLocation")
      : "",
  bookingEventType:
    getFromLocalStorage("bookingEventType") !== ""
      ? getFromLocalStorage("bookingEventType")
      : "",
  bookingNumberOfGuests:
    getFromLocalStorage("bookingNumberOfGuests") !== "0"
      ? getFromLocalStorage("bookingNumberOfGuests")
      : "0",
  bookingStartDateTime:
    getFromLocalStorage("bookingStartDateTime") !== ""
      ? getFromLocalStorage("bookingStartDateTime")
      : "",
  bookingEndDateTime:
    getFromLocalStorage("bookingEndDateTime") !== ""
      ? getFromLocalStorage("bookingEndDateTime")
      : "",
  customerEventType:
    getFromLocalStorage("customerEventType") !== ""
      ? getFromLocalStorage("customerEventType")
      : "",
  customerOtherEventType:
    getFromLocalStorage("customerOtherEventType") !== ""
      ? getFromLocalStorage("customerOtherEventType")
      : "",
};

export default function reducer(state = initialState, action) {
  var newState = { ...state };
  switch (action.type) {
    case REDIRECT_FROM_WEBSITE:
      newState.redirectFromWesbite = action.payload;
      saveToLocalStorage("redirectFromWebsite", action.payload);
      return newState;
    case CHANGE_TAB:
      newState.selectedTab = action.payload;
      saveToLocalStorage("selectedTab", action.payload);
      return newState;
    case CHANGE_SELECTED_LOCATION:
      newState.selectedLocation = action.payload.location;
      saveToLocalStorage("selectedLocation", action.payload.location);
      newState.selectedLocationId = action.payload.id;
      saveToLocalStorage("selectedLocationId", action.payload.id);
      return newState;
    case GET_DELIVERY_CHARGES:
      newState.deli = action.payload;
      return newState;
    case SEND_SMS:
      newState.smsSent = true;
      return newState;
    case LOGIN_MODAL_TOGGLE:
      newState.loginModalToggle = action.payload;
      return newState;
    case UPDATE_USER_PROFILE:
      newState.username = action.payload.username;
      newState.email = action.payload.email;
      saveToLocalStorage("email", action.payload.email);
      saveToLocalStorage("username", action.payload.username);
      return newState;
    case PROFILE_MODAL_TOGGLE:
      newState.profileModalToggle = action.payload;
      return newState;
    case LOGIN_COMPLETE:
      newState.loggedIn = true;
      newState.loginModalToggle = false;
      newState.phone = action.payload.phone;
      newState.userId = action.payload.userId;
      newState.token = action.payload.token;
      applyToken(action.payload.token);
      saveToLocalStorage("loggedIn", true);
      saveToLocalStorage("userId", action.payload.userId);
      saveToLocalStorage("phone", action.payload.phone);
      saveToLocalStorage("token", action.payload.token);
      return newState;
    case LOGOUT:
      newState.loggedIn = false;
      newState.loginModalToggle = false;
      newState.phone = "";
      newState.userId = "";
      newState.token = "";
      clearToken();
      saveToLocalStorage("loggedIn", false);
      deleteFromLocalStorage("userId", "");
      deleteFromLocalStorage("phone", "");
      deleteFromLocalStorage("token", "");
      deleteFromLocalStorage("username", "");
      deleteFromLocalStorage("email", "");
      deleteFromLocalStorage("userImg", "");
      deleteFromLocalStorage("userAddress", "");
      deleteFromLocalStorage("userLocation", "");
      deleteFromLocalStorage("products", "{}");
      deleteFromLocalStorage("productsTotal", 0);
      deleteFromLocalStorage("orderType", "");
      deleteFromLocalStorage("orderLocation", "");
      deleteFromLocalStorage("eventId", "");
      deleteFromLocalStorage("orderLater", false);
      deleteFromLocalStorage("deliveryCharges", 0);
      deleteFromLocalStorage("serviceFee", 0);
      deleteFromLocalStorage("paymentStatus", "");
      deleteFromLocalStorage("paymentSource", "");
      deleteFromLocalStorage("paymentReceiptNumber", "");
      deleteFromLocalStorage("paymentWttCharges", 0);
      deleteFromLocalStorage("paymentTxnCharges", 0);
      deleteFromLocalStorage("paymentTotalCharges", 0);
      deleteFromLocalStorage("paymentError", "");
      deleteFromLocalStorage("applyPromoCode", "");
      deleteFromLocalStorage("discount", 0);
      deleteFromLocalStorage("orders", "[]");
      deleteFromLocalStorage("orderId", "");
      return newState;
    case GET_USER_PROFILE:
      newState.email = action.payload.email;
      newState.username = action.payload.username;
      newState.userImg = action.payload.image;
      newState.address = action.payload.address;
      newState.userLocation = action.payload.location;
      saveToLocalStorage("email", action.payload.email);
      saveToLocalStorage("username", action.payload.username);
      saveToLocalStorage("userImg", action.payload.image);
      saveToLocalStorage("userAddress", action.payload.address);
      saveToLocalStorage("userLocation", action.payload.userLocation);
      return newState;
    case GET_ALL_VENDORS:
      newState.vendorData = action.payload;
      saveToLocalStorage("vendorData", JSON.stringify(action.payload));
      return newState;
    case GET_ALL_LOCATIONS:
      newState.location = action.payload;
      saveToLocalStorage("location", JSON.stringify(action.payload));
      return newState;
    case GET_ALL_FILTERS:
      newState.filters = action.payload;
      return newState;
    case UPDATE_USER_LOCATION:
      newState.currentLatitude = action.payload.latitude;
      newState.currentLongitude = action.payload.longitude;
      saveToLocalStorage("currentLatitude", action.payload.latitude);
      saveToLocalStorage("currentLongitude", action.payload.longitude);
      return newState;

    case CLEAR_SELECTED_VENDOR:
      newState.selectedVendorData = new Object();
      newState.selectedVendor = "";
      deleteFromLocalStorage("selectedVendorData", "{}");
      deleteFromLocalStorage("selectedVendor", "-1");
      return newState;
    case UPDATE_CART_PRODUCTS:
      newState.products = action.payload.products;
      saveToLocalStorage("products", JSON.stringify(action.payload.products));
      return newState;
    case UPDATE_CART_TICKET:
      newState.eventTicket = action.payload.products;
      saveToLocalStorage(
        "eventTicket",
        JSON.stringify(action.payload.products)
      );
      return newState;
    case GET_SELECTED_VENDOR_DATA:
      newState.selectedVendorData = action.payload.vendorData;
      newState.selectedVendor = action.payload.selectedVendor;
      saveToLocalStorage(
        "selectedVendorData",
        JSON.stringify(action.payload.vendorData)
      );
      saveToLocalStorage("selectedVendor", action.payload.selectedVendor);
      return newState;

    case UPDATE_SELECTED_VENDOR:
      newState.selectedVendorData = action.payload.vendorData;
      newState.selectedVendor = action.payload.selectedVendor;
      saveToLocalStorage(
        "selectedVendorData",
        JSON.stringify(action.payload.vendorData.vendorData)
      );
      saveToLocalStorage(
        "selectedVendor",
        action.payload.vendorData.selectedVendor
      );
      return newState;
    case UPDATE_CART_TOTAL:
      newState.productsTotal = action.payload.productsTotal;
      saveToLocalStorage("productsTotal", action.payload.productsTotal);
      return newState;
    case UPDATE_ORDER_TYPE:
      newState.orderType = action.payload.orderType;
      saveToLocalStorage("orderType", action.payload.orderType);
      return newState;
    case UPDATE_ORDER_LOCATION:
      newState.orderLocation = action.payload.orderLocation;
      saveToLocalStorage("orderLocation", action.payload.orderLocation);
      return newState;
    case UPDATE_SERVICE_FEE:
      newState.serviceFee = action.payload.serviceFee;
      saveToLocalStorage("serviceFee", action.payload.serviceFee);
      return newState;
    case UPDATE_EVENT_ID:
      newState.eventId = action.payload.eventId;
      saveToLocalStorage("eventId", action.payload.eventId);
      return newState;
    case UPDATE_ORDER_LATER:
      newState.orderLater = action.payload.orderLater;
      saveToLocalStorage("orderLater", action.payload.orderLater);
      return newState;
    case UPDATE_ORDER_DATE:
      newState.orderDate = action.payload.orderDate;
      saveToLocalStorage("orderDate", action.payload.orderDate);
      return newState;
    case UPDATE_ORDER_TIME:
      newState.orderTime = action.payload.orderTime;
      saveToLocalStorage("orderTime", action.payload.orderTime);
      return newState;
    case UPDATE_DELIVERY_CHARGES:
      newState.deliveryCharges = action.payload.deliveryCharges;
      saveToLocalStorage("deliveryCharges", action.payload.deliveryCharges);
      return newState;
    case PAYMENT:
      newState.paymentStatus = action.payload["payment"].status;
      newState.paymentSource = action.payload["payment"].source_type;
      newState.paymentReceiptNumber = action.payload["payment"].receipt_number;
      newState.paymentWttCharges =
        action.payload["payment"].app_fee_money.amount / 100;
      newState.paymentTxnCharges =
        Math.round(
          TRANSACTION_FEE * action.payload["payment"].total_money.amount * 100
        ) /
        100 /
        100;
      newState.paymentTotalCharges =
        action.payload["payment"].total_money.amount / 100;
      newState.paymentError = "";
      saveToLocalStorage("paymentStatus", action.payload["payment"].status);
      saveToLocalStorage(
        "paymentSource",
        action.payload["payment"].source_type
      );
      saveToLocalStorage(
        "paymentReceiptNumber",
        action.payload["payment"].receipt_number
      );
      saveToLocalStorage(
        "paymentWttCharges",
        action.payload["payment"].app_fee_money.amount / 100
      );
      saveToLocalStorage(
        "paymentTxnCharges",
        Math.round(
          TRANSACTION_FEE * action.payload["payment"].total_money.amount * 100
        ) /
          100 /
          100
      );
      saveToLocalStorage(
        "paymentTotalCharges",
        action.payload["payment"].total_money.amount / 100
      );
      saveToLocalStorage("paymentError", "");
      return newState;
    case CALCULATE_DELIVERY:
      newState.delivery = action.payload;
      return newState;
    case ORDER_NOW:
      saveToLocalStorage("orderId", action.payload.id);
      saveToLocalStorage("orderStatus", action.payload.status);
      return newState;
    case ORDER_STATUS:
      saveToLocalStorage("orderId", action.payload.id);
      saveToLocalStorage("orderStatus", action.payload.status);
      return newState;
    case GET_PROMO_CODE:
      newState.orderPromoCode = action.payload.promoCode;
      return newState;
    case APPLIED_PROMO_CODE:
      newState.applyPromoCode = action.payload.code;
      newState.discount = action.payload.discount;
      saveToLocalStorage("applyPromoCode", action.payload.code);
      saveToLocalStorage("discount", action.payload.discount);
      return newState;
    case GET_ALL_ORDERS:
      newState.orders = action.payload.orders;
      saveToLocalStorage("orders", JSON.stringify(action.payload));
      return newState;
    case TRACK_DRIVER:
      return newState;
    case SET_BOOKING_REQUEST:
      newState.bookingName = action.payload.data.name;
      newState.bookingEmail = action.payload.data.email;
      newState.bookingPhone = action.payload.data.phone;
      newState.bookingLocation = action.payload.data.location;
      newState.bookingNumberOfGuests = action.payload.data.number_of_guests;
      newState.bookingEventType = action.payload.data.event_type;
      newState.bookingStartDateTime = action.payload.data.start_date_time;
      newState.bookingEndDateTime = action.payload.data.end_date_time;
      saveToLocalStorage("bookingName", action.payload.data.name);
      saveToLocalStorage("bookingEmail", action.payload.data.email);
      saveToLocalStorage("bookingPhone", action.payload.data.phone);
      saveToLocalStorage("bookingLocation", action.payload.data.location);
      saveToLocalStorage(
        "bookingNumberOfGuests",
        action.payload.data.number_of_guests
      );
      saveToLocalStorage("bookingEventType", action.payload.data.event_type);
      console.log(action.payload.data.start_date_time);
      console.log(action.payload.data.end_date_time);
      saveToLocalStorage(
        "bookingStartDateTime",
        action.payload.data.start_date_time
      );
      saveToLocalStorage(
        "bookingEndDateTime",
        action.payload.data.end_date_time
      );
      saveToLocalStorage(
        "customerEventType",
        action.payload.data.customer_event_type
      );
      saveToLocalStorage(
        "customerOtherEventType",
        action.payload.data.customer_other_event_type
      );

      return newState;

    default:
      return state;
  }
}
