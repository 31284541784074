import { organiserAction } from "../../actions/EventOrganiser/action";

const initialState = {
  fetchingOrganiser: false,
  comfirmedVendors: [],
  selectedEoi: {},
  newEventDetail: {
    description: "",
    event_type: "",
  },
  newEOIDetail: {
    abnNumber: "",
    contactNumber: "",
    email: "",
    eventContract: [],
    eventFile: [],
    eventLocation: "",
    eventLogo: [],
    eventName: "",
    numberOfEvents: "",
    numberOfVendors: "",
    siteArea: "",
  },
  vendorsRequest: [],
};

export default function EventOrganiserReducer(
  state = initialState,
  { type, ...action }
) {
  switch (type) {
    case organiserAction.CREATE_NEW_EVENT:
      return {
        ...state,
        newEventDetail: action.payload,
      };
    case organiserAction.ADD_EOI:
      return {
        ...state,
        newEOIDetail: action.payload,
      };
    case organiserAction.GET_COMFIRM_VENDORS:
      return {
        ...state,
        comfirmedVendors: action.payload,
      };
    case organiserAction.GET_SELECTED_EOI:
      return {
        ...state,
        selectedEoi: action.payload,
      };
    case organiserAction.ADD_VENDORS_REQUEST:
      return {
        ...state,
        vendorsRequest: action.payload,
      };
    default:
      return state;
  }
}
