import React from "react";
import { Form } from "react-bootstrap";

const NumberField = (props) => {
  const {
    placeholder,
    formStateHandler,
    formTouchedHandler,
    fieldName,
    defaultValue,
  } = props;

  return (
    <Form.Control
      type="decimal"
      onChange={(event) => {
        formStateHandler(fieldName, event.target.value);
      }}
      onClick={() => formTouchedHandler(fieldName, true)}
      min={0}
      defaultValue={defaultValue}
    />
  );
};

export default NumberField;
