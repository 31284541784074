import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { AWS_URL } from "../../assets/Constants";

class AdminPostEventChecklistListScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email: "",
    password: "",
    emails: [],
    searchVendor: "",
    tempEmailList: [],
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getEmailsToVendor();
    } else {
      window.location.href = "/admin";
    }
  }

  getEmailsToVendor = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_post_event_checklist",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            emails: dataResponse.post_event_list,
            tempEmailList: dataResponse.post_event_list,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const { emails, searchVendor } = this.state;
    var emailList = this.state.emails;

    return (
      <div>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <h4>Pre Event Checklist List</h4>
          <>
            <InputGroup className="mb-3">
              <InputGroup.Text value={searchVendor}>
                <img
                  style={{ width: 20 }}
                  src={require("../../assets/img/search.gif")}
                ></img>
              </InputGroup.Text>
              <FormControl
                aria-label="Search for vendors"
                onChange={(e) => {
                  var searchString = e.target.value;
                  if (searchString == "") {
                    this.setState({ emails: this.state.tempEmailList });
                  } else {
                    var result = emailList.filter((obj) => {
                      if (
                        obj.vendor__title.search(
                          new RegExp(searchString, "i")
                        ) != -1
                      ) {
                        console.log(obj);
                        return obj;
                      }
                    });
                    this.setState({ emails: result });
                  }
                }}
              />
            </InputGroup>
            <Row className="table-header-row">
              <Col xs={12} md={1} lg={1} className="table-header-col">
                SR No.
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Vendor
              </Col>
              <Col xs={12} md={3} lg={3} className="table-header-col">
                Event Name
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Video Of The Truck
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Reported By 
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Date Created
              </Col>
            </Row>
            {emails.map((item, i) => {
              return (
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Sr No:</b> {item.id}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Vendor:</b>{" "}
                              {item.vendor__title}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Event Name:</b>{" "}
                              {item.event__name}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Video Of The Truck:</b>{" "}
                              <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.post_event_checklist_video
                                  }
                                  target="_blank"
                                >
                                  {}{"Video Link "}
                                </a>
                              
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only"> Reported By :</b>{" "}
                              {item.operator__first_name + " " + item.operator__last_name }
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Date Written:</b>{" "}
                              {item.date_written}
                            </span>
                          </Col>
                        </Row>
                        <Accordion.Collapse
                          eventKey={item.id}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Has Closing Stock Done
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.is_closing_stock_done == false? "No" : "yes"}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Did All the stock Clock In?
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.did_all_the_staff_clock_off == false? "No" : "yes"}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Was Truck Cleaned Up ?
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.was_the_truck_cleaned == false? "No" : "yes"}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                               Any Incedents Happen During Event ?
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.any_incidents_happened == false? "No" : "yes"}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                               Other Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.other_notes}
                              </Col>
                            </Row>
                            
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>
                  </>
                </Accordion>
              );
            })}
          </>
        </Container>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminPostEventChecklistListScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
