import React from "react";
import { Bar } from "react-chartjs-2";

const CostByTotalHourChart = ({ shiftData }) => {
  if (!shiftData || !shiftData.sorted_hourly_cost) {
    return null; // Optionally, return a placeholder or loading state here
  }

  const calculatePercentageDifference = (current, previous) => {
    if (previous === 0) return "N/A"; // Avoid division by zero
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  const calculatePercentageOfTotal = (cost, total) => {
    return ((cost / total) * 100).toFixed(2);
  };

  const hours = Object.keys(shiftData.sorted_hourly_cost).sort();
  const costs = hours.map((hour) => shiftData.sorted_hourly_cost[hour]);
  const previousCosts = [0, ...costs.slice(0, -1)];
  const percentageDiffs = costs.map((cost, index) =>
    calculatePercentageDifference(cost, previousCosts[index])
  );
  const percentagesOfTotal = costs.map((cost) =>
    calculatePercentageOfTotal(cost, shiftData.total_cost)
  );

  const chartData = {
    labels: hours,
    datasets: [
      {
        label: "Total Labour Cost per Hour",
        data: costs,
        backgroundColor: ["purple", "orange", "pink", "grey"], // Customize as needed
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function(context) {
            const index = context.dataIndex;
            const percentageDiff = percentageDiffs[index];
            const percentageOfTotal = percentagesOfTotal[index];
            return [
              `${percentageDiff}% ${
                percentageDiff >= 0 ? "Increase" : "Decrease"
              } from Last Hour`,
              `${percentageOfTotal}% of Total Labour Cost`,
            ];
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
export default CostByTotalHourChart;
