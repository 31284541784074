import React, { Component } from "react";
import { connect } from "react-redux";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import Axios from "axios";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage, saveToLocalStorage } from "../../store";

class ElectricalEquipmentScreen extends Component {
  state = {
    name: "",
    wattage: "",
    test_and_tag_expiry_date: "",
    vendor_id: getFromLocalStorage("vendor_id") || "",
    vendors: [],
    equipments: [],
    isLoading: false,
    showModal: false,
  };

  componentDidMount() {
    this.fetchVendorIds();
  }

  fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"))[0];
    const url = config.BASE_URI + apiPaths.adminData;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payload: {
          body: {
            query_type: "get_operator_details_for_stall_ids",
            operator_id: operator.id,
          },
        },
      }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ vendors: result.stalls });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  fetchVendorEquipments = (vendor_id) => {
    const url = config.BASE_URI + apiPaths.adminData;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payload: {
          body: {
            query_type: "get_vendor_appliances",
            vendor_id: vendor_id,
          },
        },
      }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ equipments: result.equipments });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleVendorChange = (e) => {
    const vendor_id = e.target.value;
    this.setState({ vendor_id }, () => {
      if (vendor_id) {
        this.fetchVendorEquipments(vendor_id);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, wattage, test_and_tag_expiry_date, vendor_id } = this.state;
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("name", name);
    formData.append("wattage", wattage);
    formData.append("test_and_tag_expiry_date", test_and_tag_expiry_date);
    formData.append("vendor_id", vendor_id);

    Axios.post(config.BASE_URI + apiPaths.electricalEquipment, formData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.success) {
          this.setState({ showModal: true });
          this.fetchVendorEquipments(vendor_id);
        } else {
          alert("Unable to submit the form. Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.setState({ isLoading: false });
        alert("Unable to submit the form. Please try again!");
      });
  };

  handleEditSubmit = (equipment_id) => (e) => {
    e.preventDefault();
    const { vendor_id } = this.state;
    const name = this.state[`name_${equipment_id}`];
    const wattage = this.state[`wattage_${equipment_id}`];
    const test_and_tag_expiry_date = this.state[`test_and_tag_expiry_date_${equipment_id}`];

    const formData = new FormData();
    formData.append("name", name);
    formData.append("wattage", wattage);
    formData.append("test_and_tag_expiry_date", test_and_tag_expiry_date);
    formData.append("vendor_id", vendor_id);
    formData.append("equipment_id", equipment_id);

    Axios.post(config.BASE_URI + apiPaths.updateElectricalEquipment, formData)
      .then((res) => {
        if (res.data.success) {
          this.fetchVendorEquipments(vendor_id);
        } else {
          alert("Unable to update the form. Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        alert("Unable to update the form. Please try again!");
      });
  };

  render() {
    const { name, wattage, test_and_tag_expiry_date, vendor_id, vendors, equipments, isLoading, showModal } = this.state;

    return (
      <div>
        <VendorNavBar />
        <Container>
          <div>
          Please list all electrical appliances you wish to use throughout the event.
All electrical equipment must have a current Electrical Tag (Test and Tag) attached.
Any equipment not listed on this form will not be used on the day.
An electrician will inspect your stall and check your equipment against what is included on this form.
Please fill in the following information for each piece of equipment you will be using.
Every piece of equipment MUST be listed and the following information is mandatory
Description: Please tell us what it is, eg. Urn, cash register, cold room, stove,
hot plate, etc.
Watts/Amps: On every piece of equipment a compliance plate will be visible,
on this plate information about the wattage or amperage will be found eg,
50Hz 700w see here. 2400 watts is 10 amp and 3600 watts is 15 amp
Test and Tag: All equipment must also have a current electrical tag. The
information must be up date and include the following Tag Number, Test
Date, and Test Due Date, see here
All this must be included on the form below
          </div>
          <h2>Electrical Equipment Form</h2>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Select Vendor</Form.Label>
              <Form.Control
                as="select"
                name="vendor_id"
                value={vendor_id}
                onChange={this.handleVendorChange}
                required
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor.user_ptr_id} value={vendor.user_ptr_id}>
                    {vendor.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Electrical equipment Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Electrical equipment Wattage</Form.Label>
              <Form.Control
                type="number"
                name="wattage"
                value={wattage}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label> Electrical equipment Test and Tag Expiry Date</Form.Label>
              <Form.Control
                type="text"
                name="test_and_tag_expiry_date"
                value={test_and_tag_expiry_date}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Form>

          <h3 className="mt-5">Existing Equipments</h3>
          {equipments.map((equipment) => (
            <Form key={equipment.id} onSubmit={this.handleEditSubmit(equipment.id)}>
              <Form.Group>
                <Form.Label>Equipment Name</Form.Label>
                <Form.Control
                  type="text"
                  name={`name_${equipment.id}`}
                  value={this.state[`name_${equipment.id}`] || equipment.name}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label> Equipment Wattage</Form.Label>
                <Form.Control
                  type="number"
                  name={`wattage_${equipment.id}`}
                  value={this.state[`wattage_${equipment.id}`] || equipment.wattage}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Test and Tag Expiry Date</Form.Label>
                <Form.Control
                  type="text"
                  name={`test_and_tag_expiry_date_${equipment.id}`}
                  value={this.state[`test_and_tag_expiry_date_${equipment.id}`] || equipment.test_and_tag_expiry_date}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          ))}
        </Container>

        <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Form submitted successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(ElectricalEquipmentScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
