// SharedOutlet.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import './style.css';

const TruckManagerEventFeedbackScreen = () => {
    const [feedbackData, setFeedbackData] = useState({
        question1: "",
        question2: "",
        question3: "",
        question4: "",
    });
    const [error, setError] = useState(null);
    const location = useLocation();
    
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const hashcode = query.get("hashcode");
    const questions = [
        "Please provide detailed comments on how efficient the bump in to site was and communication of the event organiser pro's &Con's:",
        "What are the challenges faced in operation during the event?",
        "Please provide detailed comments on your site position and how it affected your trade for the better or worse. Which cuisine was busiest on site & what could do well if available next year. Refer to the site map and refer to the best site number you think did the best.:",
        "What could have been done better to improve the sales:",
    ];
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const feedbackPayload = {
                query_type: "post_truck_manager_event_feedback",
                hashcode: hashcode,
                feedbackData: feedbackData,
            };
    
            // Add individual question-answer pairs to feedbackPayload
            questions.forEach((question, index) => {
                const questionKey = `question${index + 1}`;
                feedbackPayload[questionKey] = {
                    question: question,
                    answer: feedbackData[questionKey],
                };
            });
    
            const response = await axios.post(config.BASE_URI + apiPaths.operatorData, {
                payload: {
                    body: feedbackPayload,
                },
            });
    
            if (response.data.success) {
                // Show a success message using an alert box
                window.alert("Feedback submitted successfully!");
                window.location.href = "/events";
                // Optionally, you can clear the form or perform other actions
            } else {
                setError(response.data.err);
            }
        } catch (err) {
            setError(err.message);
        }
    };
    
    
    const questionsAndAnswers = [
        {
            question: "question1: Please provide detailed comments on how efficient the bump in to site was and communication of the event organiser pro's &Con's:",
            answer: feedbackData.question1,
        },
        {
            question: "question2: What are the challenges faced in operation during the event?",
            answer: feedbackData.question2,
        },
        {
            question: "question3: Please provide detailed comments on your site position and how it affected your trade for the better or worse. Which cuisine was busiest on site & what could do well if available next year. Refer to the site map and refer to the best site number you think did the best.:",
            answer: feedbackData.question3,
        },
        {
            question: "question4: What could have been done better to improve the sales:",
            answer: feedbackData.question4,
        },
    ];

    const handleFeedbackChange = (e, index) => {
        const { value } = e.target;
        const updatedFeedbackData = { ...feedbackData };
        updatedFeedbackData[`question${index + 1}`] = value;
        setFeedbackData(updatedFeedbackData);
    };
    
    return (
        <div className="page-container">
            <Container className="full-height">
                <Row>
                    <Col md={12} className="feedback-form">
                        <Card>
                            <Card.Header>
                                <h4>WTT Truck Manager Feedback Form</h4>
                            </Card.Header>
                            <Card.Body>
                                <form onSubmit={handleSubmit}>
                                    {questionsAndAnswers.map((item, index) => (
                                        <div className="form-group" key={index}>
                                            <label htmlFor={`question${index + 1}`}>{item.question}</label>
                                            <textarea
                                                className="form-control textarea"
                                                id={`question${index + 1}`}
                                                name={`question${index + 1}`}
                                                value={item.answer}
                                                onChange={(e) => handleFeedbackChange(e, index)}
                                            />
                                        </div>
                                    ))}
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TruckManagerEventFeedbackScreen;
