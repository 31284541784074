import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import DatePicker from "react-datepicker";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

class CompareVendors extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId1:
      getFromLocalStorage("selectedVendorId1") != "undefined" &&
      getFromLocalStorage("selectedVendorId1") != "" &&
      getFromLocalStorage("selectedVendorId1") != null
        ? getFromLocalStorage("selectedVendorId1")
        : "0",
    selectedVendorId2:
      getFromLocalStorage("selectedVendorId2") != "undefined" &&
      getFromLocalStorage("selectedVendorId2") != "" &&
      getFromLocalStorage("selectedVendorId2") != null
        ? getFromLocalStorage("selectedVendorId2")
        : "0",
    selectedVendorId3:
      getFromLocalStorage("selectedVendorId3") != "undefined" &&
      getFromLocalStorage("selectedVendorId3") != "" &&
      getFromLocalStorage("selectedVendorId3") != null
        ? getFromLocalStorage("selectedVendorId3")
        : "0",
    selectedVendorId4:
      getFromLocalStorage("selectedVendorId4") != "undefined" &&
      getFromLocalStorage("selectedVendorId4") != "" &&
      getFromLocalStorage("selectedVendorId4") != null
        ? getFromLocalStorage("selectedVendorId4")
        : "0",
    vendorName1:
      getFromLocalStorage("vendorName1") != "undefined" &&
      getFromLocalStorage("vendorName1") != "" &&
      getFromLocalStorage("vendorName1") != null
        ? getFromLocalStorage("vendorName1")
        : "0",
    vendorName2:
      getFromLocalStorage("vendorName2") != "undefined" &&
      getFromLocalStorage("vendorName2") != "" &&
      getFromLocalStorage("vendorName2") != null
        ? getFromLocalStorage("vendorName2")
        : "0",
    vendorName3:
      getFromLocalStorage("vendorName3") != "undefined" &&
      getFromLocalStorage("vendorName3") != "" &&
      getFromLocalStorage("vendorName3") != null
        ? getFromLocalStorage("vendorName3")
        : "0",
    vendorName4:
      getFromLocalStorage("vendorName4") != "undefined" &&
      getFromLocalStorage("vendorName4") != "" &&
      getFromLocalStorage("vendorName4") != null
        ? getFromLocalStorage("vendorName4")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales1: 0,
    total_orders1: 0,
    canceled_sales1: 0,
    canceled_orders1: 0,
    total_sales2: 0,
    total_orders2: 0,
    canceled_sales2: 0,
    canceled_orders2: 0,
    total_sales3: 0,
    total_orders3: 0,
    canceled_sales3: 0,
    canceled_orders3: 0,
    total_sales4: 0,
    total_orders4: 0,
    canceled_sales4: 0,
    canceled_orders4: 0,
    totalCash1: 0,
    totalCard1: 0,
    totalCash2: 0,
    totalCard2: 0,
    totalCash3: 0,
    totalCard3: 0,
    totalCash4: 0,
    totalCard4: 0,
    totalCashOrders1: 0,
    totalCardOrders1: 0,
    totalCashOrders2: 0,
    totalCardOrders2: 0,
    totalCashOrders3: 0,
    totalCardOrders3: 0,
    totalCashOrders4: 0,
    totalCardOrders4: 0,
    canceled_sales_cash1: 0,
    canceled_sales_card1: 0,
    canceled_sales_cash2: 0,
    canceled_sales_card2: 0,
    canceled_sales_cash3: 0,
    canceled_sales_card3: 0,
    canceled_sales_cash4: 0,
    canceled_sales_card4: 0,
    selectedDate: "-1",
    meal_freq1: [],
    meal_freq2: [],
    meal_freq3: [],
    meal_freq4: [],
    salesByHoursLabels1: [],
    salesByHoursData1: [],
    salesByHoursLabels2: [],
    salesByHoursData2: [],
    salesByHoursLabels3: [],
    salesByHoursData3: [],
    salesByHoursLabels4: [],
    salesByHoursData4: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // setItemPercentage = () => {
  //   const newMealFrequency = this.state.meal_freq.map((item) => {
  //     const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
  //       2
  //     );
  //     return {
  //       ...item,
  //       percentage,
  //     };
  //   });
  //   this.setState({ newMealFrequency: newMealFrequency });
  //   //return true
  // };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // debugger;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // debugger;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders1 = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId1,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId1,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // debugger;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            total_sales1: dataResponse.total_sales,
            total_orders1: dataResponse.total_orders,
            canceled_sales1: dataResponse.canceled_sales,
            canceled_orders1: dataResponse.canceled_orders,
            totalCash1: dataResponse.total_cash_sales,
            totalCard1: dataResponse.total_card_sales,
            totalCardOrders1: 0,
            totalCashOrders1: 0,
            canceled_sales_cash1: dataResponse.canceled_sales_cash,
            canceled_sales_card1: dataResponse.canceled_sales_card,
            meal_freq1: dataResponse.meal_freq,
            salesByHours1: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels1: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData1: salesByHoursDataTemp });
        // this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders2 = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId2,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId2,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // debugger;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            total_sales2: dataResponse.total_sales,
            total_orders2: dataResponse.total_orders,
            canceled_sales2: dataResponse.canceled_sales,
            canceled_orders2: dataResponse.canceled_orders,
            totalCash2: dataResponse.total_cash_sales,
            totalCard2: dataResponse.total_card_sales,
            totalCardOrders2: 0,
            totalCashOrders2: 0,
            canceled_sales_cash2: dataResponse.canceled_sales_cash,
            canceled_sales_card2: dataResponse.canceled_sales_card,
            meal_freq2: dataResponse.meal_freq,
            salesByHours2: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels2: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData2: salesByHoursDataTemp });
        // this.setItemPercentage();
        // const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders3 = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId3,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId3,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // debugger;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            total_sales3: dataResponse.total_sales,
            total_orders3: dataResponse.total_orders,
            canceled_sales3: dataResponse.canceled_sales,
            canceled_orders3: dataResponse.canceled_orders,
            totalCash3: dataResponse.total_cash_sales,
            totalCard3: dataResponse.total_card_sales,
            totalCardOrders3: 0,
            totalCashOrders3: 0,
            canceled_sales_cash3: dataResponse.canceled_sales_cash,
            canceled_sales_card3: dataResponse.canceled_sales_card,
            meal_freq3: dataResponse.meal_freq,
            salesByHours3: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels3: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData3: salesByHoursDataTemp });
        // this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders4 = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId4,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_analytics",
          email: email,
          password: password,
          vendor: selectedVendorId4,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // debugger;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            total_sales4: dataResponse.total_sales,
            total_orders4: dataResponse.total_orders,
            canceled_sales4: dataResponse.canceled_sales,
            canceled_orders4: dataResponse.canceled_orders,
            totalCash4: dataResponse.total_cash_sales,
            totalCard4: dataResponse.total_card_sales,
            totalCardOrders4: 0,
            totalCashOrders4: 0,
            canceled_sales_cash4: dataResponse.canceled_sales_cash,
            canceled_sales_card4: dataResponse.canceled_sales_card,
            meal_freq4: dataResponse.meal_freq,
            salesByHours4: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels4: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData4: salesByHoursDataTemp });
        // this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId1: vendorId }, () => {
            saveToLocalStorage("selectedVendorId1", vendorId);
            // this.getOrders();
            saveToLocalStorage(
              "vendorName1",
              dataResponse.vendors[vendorId].title
            );
          });
          this.setState({ selectedVendorId2: vendorId }, () => {
            saveToLocalStorage("selectedVendorId2", vendorId);
            saveToLocalStorage(
              "vendorName2",
              dataResponse.vendors[vendorId].title
            );
            // this.getOrders();
          });
          this.setState({ selectedVendorId3: vendorId }, () => {
            saveToLocalStorage("selectedVendorId3", vendorId);
            saveToLocalStorage(
              "vendorName3",
              dataResponse.vendors[vendorId].title
            );
            // this.getOrders();
          });
          this.setState({ selectedVendorId4: vendorId }, () => {
            saveToLocalStorage("selectedVendorId4", vendorId);
            saveToLocalStorage(
              "vendorName4",
              dataResponse.vendors[vendorId].title
            );
            // this.getOrders();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      vendors,
      selectedVendorId1,
      selectedVendorId2,
      selectedVendorId3,
      selectedVendorId4,
      vendorName1,
      vendorname2,
      vendorName3,
      vendorName4,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders1,
      total_sales1,
      canceled_orders1,
      canceled_sales1,
      total_orders2,
      total_sales2,
      canceled_orders2,
      canceled_sales2,
      total_orders3,
      total_sales3,
      canceled_orders3,
      canceled_sales3,
      total_orders4,
      total_sales4,
      canceled_orders4,
      canceled_sales4,
      canceled_sales_cash1,
      canceled_sales_card1,
      canceled_sales_cash2,
      canceled_sales_card2,
      canceled_sales_cash3,
      canceled_sales_card3,
      canceled_sales_cash4,
      canceled_sales_card4,
      totalCash1,
      totalCard1,
      totalCash2,
      totalCard2,
      totalCash3,
      totalCard3,
      totalCash4,
      totalCard4,
      totalCashOrders1,
      totalCardOrders1,
      totalCashOrders2,
      totalCardOrders2,
      totalCashOrders3,
      totalCardOrders3,
      totalCashOrders4,
      totalCardOrders4,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      startDate,
      endDate,
    } = this.state;
    // if (selectedEvent) {
    // }
    // let date_range = this.getDateRange(
    //   start_date,
    //   end_date
    // );
    return (
      <div>
        {!isLoading ? (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Container>
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId1}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId1: "" + e.target.value },
                          () => {}
                        );
                        saveToLocalStorage(
                          "selectedVendorId1",
                          "" + e.target.value
                        );
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId2}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId2: "" + e.target.value },
                          () => {}
                        );
                        saveToLocalStorage(
                          "selectedVendorId2",
                          "" + e.target.value
                        );
                        saveToLocalStorage("vendorName1", e.target.value);
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId3}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId3: "" + e.target.value },
                          () => {}
                        );
                        saveToLocalStorage(
                          "selectedVendorId3",
                          "" + e.target.value
                        );
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId4}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId4: "" + e.target.value },
                          () => {}
                        );
                        saveToLocalStorage(
                          "selectedVendorId4",
                          "" + e.target.value
                        );
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Button
                    style={{ marginTop: 30 }}
                    onClick={() => {
                      this.getOrders1();
                      this.getOrders2();
                      this.getOrders3();
                      this.getOrders4();
                    }}
                  >
                    Apply
                  </Button>
                </Col>

                {selectedVendorId1 != "0" ? (
                  <Col xs={12} md={3} lg={3}>
                    <Form.Label>Select Time</Form.Label>
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={this.state.selectedDate}
                        onChange={(e) => {
                          this.setState(
                            { selectedDate: e.target.value },
                            () => {
                              this.getOrders1();
                              this.getOrders2();
                              this.getOrders3();
                              this.getOrders4();
                            }
                          );
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
            </Container>
            <Container>
              <h4 className="mx-auto">Comparison Of Vendors</h4>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Line
                    data={{
                      labels: this.state.salesByHoursLabels1,
                      datasets: [
                        {
                          label: "Vendor 1",
                          data: this.state.salesByHoursData1,
                          borderColor: "#2ECFDB",
                          backgroundColor: "#28B5BF",
                          borderWidth: 3,
                        },
                        {
                          label: "Vendor 2",
                          data: this.state.salesByHoursData2,
                          borderColor: "#9C619C",
                          backgroundColor: "#8A568A",
                          borderWidth: 3,
                        },
                        {
                          label: "Vendor 3",
                          data: this.state.salesByHoursData3,
                          borderColor: "#A6DE87",
                          backgroundColor: "#8DBD73",
                          borderWidth: 3,
                        },
                        {
                          label: "Vendor 4",
                          data: this.state.salesByHoursData4,
                          borderColor: "#FEFEAA",
                          backgroundColor: "#E3E398",
                          borderWidth: 3,
                        },
                      ],
                    }}
                    height={400}
                    width={600}
                    options={{
                      maintainAspectRatio: false,
                      // scales: {
                      //   yAxes: [
                      //     {
                      //       ticks: {
                      //         beginAtZero: true,
                      //       },
                      //     },
                      //   ],
                      // },
                      title: {
                        display: true,
                        text: "Comparison Of Vendors",
                      },
                    }}
                  />
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <h4 className="mx-auto">Sales By Vendors</h4>
              <Row>
                <Col>
                  <Pie
                    data={{
                      labels: ["Vendor 1", "Vendor 2", "Vendor 3", "Vendor 4"],
                      datasets: [
                        {
                          data: [
                            this.state.total_sales1,
                            this.state.total_sales2,
                            this.state.total_sales3,
                            this.state.total_sales4,
                          ],
                          backgroundColor: [
                            "#28B5BF",
                            "#8A568A",
                            "#8DBD73",
                            "#E3E398",
                          ],
                          hoverBackgroundColor: [
                            "#2ECFDB",
                            "#9C619C",
                            "#A6DE87",
                            "#FEFEAA",
                          ],
                          borderColor: [
                            "#2ECFDB",
                            "#9C619C",
                            "#A6DE87",
                            "#FEFEAA",
                          ],
                        },
                      ],
                    }}
                    height={400}
                    width={600}
                    options={{
                      title: {
                        display: true,
                        text: "My Pie Chart",
                        fontSize: 18,
                        fontColor: "#000",
                        fontFamily: "Arial",
                      },
                      maintainAspectRatio: false,
                      // scales: {
                      //   yAxes: [
                      //     {
                      //       ticks: {
                      //         beginAtZero: true,
                      //       },
                      //     },
                      //   ],
                      // },
                    }}
                  />
                </Col>
              </Row>
            </Container>

            {/* 
            {this.state.selectedVendorId1 != undefined &&
              this.state.selectedVendorId1 != 0 ? (
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <EventOrganiserVendorsPieChart
                    vendorName={this.state.vendorName}
                    vendorData={this.state.meal_freq1}
                  ></EventOrganiserVendorsPieChart>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <EventOrganiserVendorsPieChart
                    vendorName={this.state.salesByVendorName}
                    vendorData={this.state.salesByVendorTotal}
                    isBool={true}
                  ></EventOrganiserVendorsPieChart>
                </Col>
              </Row> */}

            {/* <div className="my-3">
              <h3>Items Sold:</h3>
              {this.state.newMealFrequency[0] && (
                <TableData tableData={this.state.newMealFrequency}></TableData>
              )}
            </div>
            <div
              style={{
                height: window.innerHeight / 3,
                overflow: "scroll",
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >
            </div> */}
          </Container>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(CompareVendors, {
    fallback: <ErrorFallbackComponent />,
  })
);
