import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import "date-fns";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
class AdminVendorOrdersPage extends Component {
  state = {
    adminKitchacoLocation:
      getFromLocalStorage("adminKitchacoLocation") != "undefined" &&
      getFromLocalStorage("adminKitchacoLocation") != "" &&
      getFromLocalStorage("adminKitchacoLocation") != null
        ? getFromLocalStorage("adminKitchacoLocation")
        : 0,
    vendors: [],
    searchvendor: "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    ordersByLocation: [],
    tempOrdersByLocation: [],
    isLoading: false,
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Delivered",
      "Finished",
      "Canceled",
    ],
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : 0,
    vendorId: "0",
  };

  componentDidMount() {
    this.getOrdersData();
    // this.getVendorsInEvent();
    // const { isSuperAdminLoggedIn } = this.state;
    // if (isSuperAdminLoggedIn != "true") {
    //   window.location.href = "/admin";
    // }
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  onChangeKitchacoLocation(e) {
    this.setState({ adminKitchacoLocation: e.target.value }, () => {
      saveToLocalStorage(
        "adminKitchacoLocation",
        this.state.adminKitchacoLocation
      );
      this.getOrdersData();
    });
  }

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        payload: {
            body: {
      query_type: "update_order_status",
      order_id: orderId,
      status: orderStatus,
            }

        }
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

//   getVendorsInEvent = () => {
//     const {
//       email,
//       password,
//       selectedEventId,
//       isOperatorLoggedIn,
//       isSuperAdminLoggedIn,
//     } = this.state;
//     this.setState({ isLoading: false });
//     var url = config.BASE_URI + apiPaths.adminData;
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     if (isOperatorLoggedIn == true) {
//     } else {
//       var raw = JSON.stringify({
//         payload: {
//           body: {
//             query_type: "get_all_vendors",
//           },
//         },
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(url, requestOptions)
//         .then((response) => response.json())
//         .then((dataResponse) => {
//           this.setState({ vendors: dataResponse.vendors }, () => {
//             let vendorId = "0";
//             this.setState({ selectedVendorId: vendorId }, () => {
//               saveToLocalStorage("selectedVendorId", vendorId);
//               // this.getOrders();
//             });
//           });
//         })
//         .catch((error) => {
//           Sentry.captureException(error);
//           console.error(error);
//           this.setState({ isLoading: false });
//         });
//     }
//   };

  getOrdersData() {
    const { startDate, endDate, selectedVendorId = 0 } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "orders_data_event",
          kitchaco_location: "808",
          start_date: moment(startDate).format("DD MMM YYYY"),
          end_date: moment(endDate).format("DD MMM YYYY"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          this.setState({ ordersByLocation: dataResponse.orders_by_location });
          this.setState({
            tempOrdersByLocation: dataResponse.orders_by_location,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <>
        {this.renderMainComponent()}
        {this.renderOrderStatusChangeModal()}
      </>
    );
  }

  renderLoginComponent() {
    return <div>Login Component</div>;
  }

  renderMainComponent() {
    const {
      vendors,
      adminKitchacoLocation,
      startDate,
      endDate,
      ordersByLocation,
      availableStatus,
      isLoading,
      isLoadingChangeOrderStatus,
      isSuperAdminLoggedIn,
      stockAdminLoggedIn,
      selectedVendorId,
      searchVendor,
    } = this.state;
    var emailList = this.state.ordersByLocation;

    return (
      <div>
        {/* <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn}></AdminNavBar> */}
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              {/* <Col xs={12} md={6} lg={6}>
                {vendors && vendors.length > 0 ? (
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>
                      Vendors {"(" + vendors.length + ")"}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId}
                      onChange={(e) => {
                        this.setState(
                          { selectedVendorId: "" + e.target.value },
                          () => {
                            // this.getOrders();
                          }
                        );
                        saveToLocalStorage(
                          "selectedVendorId",
                          "" + e.target.value
                        );
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>All Vendors</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                ) : null}
              </Col> */}
              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={4} md={4} lg={4}>
                    <p>Start Date</p>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={(date) => this.setStartDate(date)}
                    />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>End Date</p>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={endDate}
                      onChange={(date) => this.setEndDate(date)}
                    />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button
                      onClick={() => {
                        this.getOrdersData();
                      }}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <InputGroup className="mb-3">
              <InputGroup.Text value={searchVendor}>
                <img
                  style={{ width: 20 }}
                  src={require("../../assets/img/search.gif")}
                ></img>
              </InputGroup.Text>
              <FormControl
                aria-label="Search for stalls"
                onChange={(e) => {
                  var searchString = e.target.value;
                  if (searchString == "") {
                    this.setState({
                      ordersByLocation: this.state.tempOrdersByLocation,
                    });
                  } else {
                    console.log(emailList);
                    var result = emailList.filter((obj) => {
                      if (
                        obj.vendor__title.search(
                          new RegExp(searchString, "i")
                        ) != -1
                      ) {
                        return obj;
                      }
                    });
                    this.setState({ ordersByLocation: result });
                  }
                }}
              />
            </InputGroup>
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={12} lg={12}>
                <h4>Orders By Location</h4>
              </Col>
            </Row>
            <Row className="table-header-row">
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Order #
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Order Date
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Order Time
              </Col>
              <Col xs={12} md={3} lg={3} className="table-header-col">
                Stall Name
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Customer Name
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Payment Receipt Number
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Status
              </Col>
            </Row>
            {ordersByLocation.map((item, i) => {
              return (
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Order #:</b> {item.id}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">Order Date:</b>
                            {item.order_date}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Order Time:</b>
                            {item.order_time}
                          </span>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="table-col">
                          <span>
                            <b className="mobile-only">Order Location:</b>
                            {item.vendor__title}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">Customer Name:</b>
                            {item.customer_name}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">
                              Payment Receipt Number:
                            </b>
                            {item.payment_receipt_number}
                          </span>
                        </Col>

                        <Col xs={12} md={2} lg={2} className="mobile-only">
                          <span>
                            <b className="mobile-only">Order Status:</b>
                          </span>
                        </Col>
                        <Col
                          xs={12}
                          md={1}
                          lg={1}
                          className="table-col"
                          style={{ paddingLeft: 2, paddingRight: 2 }}
                        >
                          {availableStatus && availableStatus.length > 0 ? (
                            <>
                              {isLoadingChangeOrderStatus ? (
                                <div style={{ textAlign: "center" }}>
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                </div>
                              ) : (
                                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                  <Form.Control
                                    as="select"
                                    size="sm"
                                    custom
                                    onChange={(e) => {
                                      this.onChangeOrderStatus(e, item);
                                    }}
                                    defaultValue={item.status}
                                  >
                                    {availableStatus.map((status, i) => {
                                      return (
                                        <option key={i} value={status}>
                                          {status}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              )}
                            </>
                          ) : null}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="mobile-only">
                          <div
                            style={{
                              padding: 10,
                              backgroundColor: "#1876d1",
                              borderRadius: 40,
                              marginBottom: 10,
                              color: "white",
                            }}
                          >
                            <b className="mobile-only">
                              Click here to see more details
                            </b>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={item.id}
                      style={{ padding: 20, backgroundColor: "#eff2f5" }}
                    >
                      <>
                        {item.meal &&
                          item.meal.map((meal, j) => {
                            return (
                              <Row>
                                <Col
                                  xs={6}
                                  md={6}
                                  lg={6}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  <Row>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.event_meal__name}
                                    </Col>
                                  </Row>
                                  {meal.subitem != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        {meal.subitem}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {meal.special_request != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        {meal.special_request}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {meal.combo_array &&
                                    meal.combo_array.map((combo) => {
                                      return (
                                        <Row>
                                          <Col xs={12} md={12} lg={12}>
                                            {combo.name}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>
                                <Col
                                  xs={3}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  <Row>
                                    <Col xs={12} md={12} lg={12}>
                                      x{meal.quantity}
                                    </Col>
                                  </Row>
                                  {meal.subitem != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        <p></p>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {meal.special_request != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        <p></p>
                                      </Col>
                                    </Row>
                                  ) : null}

                                  {meal.combo_array &&
                                    meal.combo_array.map((combo) => {
                                      return (
                                        <Row>
                                          <Col xs={12} md={12} lg={12}>
                                            x{meal.quantity}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>
                                <Col
                                  xs={3}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  <Row>
                                    <Col xs={12} md={12} lg={12}>
                                      $
                                      {Number(meal.event_meal__price).toFixed(
                                        2
                                      )}
                                    </Col>
                                  </Row>
                                  {meal.subitem != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        <p></p>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {meal.special_request != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        <p></p>
                                      </Col>
                                    </Row>
                                  ) : null}

                                  {meal.combo_array &&
                                    meal.combo_array.map((combo) => {
                                      return (
                                        <Row>
                                          <Col xs={12} md={12} lg={12}>
                                            ${Number(combo.price).toFixed(2)}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>
                              </Row>
                            );
                          })}

                        {/*UBER EATS*/}
                        {item.order_source == "Uber Eats" &&
                          item.meal &&
                          item.meal.map((meal, j) => {
                            var uber_combo = [];
                            if (meal.combo.indexOf(",") !== 1) {
                              uber_combo = meal.combo.split(",");
                            } else {
                              uber_combo.push(meal.combo);
                            }

                            // Check if more than 1 combo items then check price of main meal
                            var uber_meal_price = meal.price;
                            uber_combo.length > 0 &&
                              uber_combo.map((combo) => {
                                var price = 0;
                                if (combo.indexOf("=") != -1) {
                                  var temp = combo.split("=");

                                  price = temp[1];
                                  uber_meal_price -= price;
                                }
                              });

                            return (
                              <Row>
                                <Col
                                  xs={6}
                                  md={6}
                                  lg={6}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  <Row>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.event_meal__name}
                                    </Col>
                                  </Row>
                                  {meal.subitem != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        {meal.subitem}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {uber_combo.map((combo) => {
                                    var name = "";
                                    var price = 0;
                                    var qty = 0;
                                    if (combo.indexOf("=") != -1) {
                                      var temp = combo.split("=");
                                      name = temp[0].split("~")[0];
                                      qty = temp[0].split("~")[1];
                                      price = temp[1];
                                    } else {
                                      name = combo.split("~")[0];
                                    }
                                    return (
                                      <Row>
                                        <Col xs={12} md={12} lg={12}>
                                          {name}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                  {meal.special_request != "" ? (
                                    <Row style={{ padding: 20 }}>
                                      <Col xs={12} md={12} lg={12}>
                                        {meal.special_request}
                                      </Col>
                                    </Row>
                                  ) : null}
                                </Col>
                                <Col
                                  xs={3}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  x{meal.quantity}
                                  {uber_combo.length > 0 &&
                                    uber_combo.map((combo) => {
                                      var qty = 0;
                                      if (combo.indexOf("=") != -1) {
                                        var temp = combo.split("=");
                                        qty = temp[0].split("~")[1];
                                      } else {
                                        qty = combo.split("~")[1];
                                      }
                                      return (
                                        <>
                                          {Number(qty) > 0 ? (
                                            <Row>
                                              <Col xs={12} md={12} lg={12}>
                                                x{Number(qty)}
                                              </Col>
                                            </Row>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                </Col>
                                <Col
                                  xs={3}
                                  md={3}
                                  lg={3}
                                  className="table-col"
                                  style={{ border: "none" }}
                                >
                                  ${Number(uber_meal_price).toFixed(2)}
                                  {uber_combo.length > 0 &&
                                    uber_combo.map((combo) => {
                                      var price = 0;
                                      if (combo.indexOf("=") != -1) {
                                        var temp = combo.split("=");

                                        price = temp[1];
                                      }
                                      return (
                                        <>
                                          {Number(price) > 0 ? (
                                            <Row>
                                              <Col xs={12} md={12} lg={12}>
                                                ${Number(price).toFixed(2)}
                                              </Col>
                                            </Row>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                </Col>
                              </Row>
                            );
                          })}
                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Sub Total
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={3}
                            md={3}
                            lg={3}
                            className="table-header-col"
                            style={{ border: "none" }}
                          ></Col>

                          <Col
                            xs={3}
                            md={3}
                            lg={3}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.order_source == "Kitchaco"
                              ? "$" + Number(item.sub_total).toFixed(2)
                              : "$" +
                                Number(item.payment_total_amount).toFixed(2)}
                          </Col>
                        </Row>
                        {item.delivery_charge &&
                        Number(item.delivery_charge) > 0 ? (
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Delivery Charges
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            ></Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              ${Number(item.delivery_charge).toFixed(2)}
                            </Col>
                          </Row>
                        ) : null}
                        {item.discount && Number(item.discount) > 0 ? (
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Discount
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            ></Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              ${Number(item.discount).toFixed(2)}
                            </Col>
                          </Row>
                        ) : null}
                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Total
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={3}
                            md={3}
                            lg={3}
                            className="table-header-col"
                            style={{ border: "none" }}
                          ></Col>
                          <Col
                            xs={3}
                            md={3}
                            lg={3}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            ${Number(item.payment_total_amount).toFixed(2)}
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Order Source
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.order_source}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Name
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.customer_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Phone
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.customer__phone}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            lg={12}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Email
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={12}
                            md={12}
                            lg={12}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.customer__email}
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Order Type
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            md={6}
                            lg={6}
                            className="table-header-col"
                            style={{ border: "none" }}
                          >
                            {item.order_type}
                          </Col>
                        </Row>
                        {item.order_type == "delivery" ? (
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Delivery Location
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              {item.delivery_location}
                            </Col>
                          </Row>
                        ) : null}
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
              );
            })}
          </Container>
        )}
      </div>
    );
  }

  renderOrderStatusChangeModal() {
    const {
      isOpenOrderStatusChangeModal,
      selectedOrder,
      selectedOrderNewStatus,
      isLoadingChangeOrderStatus,
    } = this.state;

    return (
      <>
        {selectedOrder != null && selectedOrderNewStatus != "" ? (
          <Modal
            show={isOpenOrderStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenOrderStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Order #{selectedOrder.id} to {selectedOrderNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Order #{selectedOrder.id} to{" "}
                {selectedOrderNewStatus}? This will also send a notification to
                the customer.
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeOrderStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeOrderStatusOnServer(
                        selectedOrder.id,
                        selectedOrderNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenOrderStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminVendorOrdersPage, {
    fallback: <ErrorFallbackComponent />,
  })
);
