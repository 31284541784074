import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

const EventComponent = ({ event }) => {
  return (
    <div className="event-container">
      <p>
        Event Name:
        <br /> {event.title}
      </p>
      <p>
        Venue:
        <br /> {event.desc}
      </p>
      <p>
        EOI Status:
        <br />
        {event.status}
      </p>
    </div>
  );
};

const CalendarView = ({ eventsList }) => {
  const bg = {
    pending: "#F29339",
    accepted: "#5CB85C",
    rejected: "#C94D3B",
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = bg[event.status] || "#FFAB91";
    return {
      style: {
        backgroundColor,
      },
    };
  };

  const formattedEvents = eventsList.map((item) => {
    return {
      id: item.id,
      title: item.eoi_event_name,
      start: new Date(item.event_start_date_time),
      end: new Date(item.event_end_date_time),
      allDay: false, // Adjust this according to your data
      resource: { bgColor: item.vendor__brand_colour || "#FFAB91" }, // Provide backgroundColor
      desc: item.event_organiser_address,
      status: item.eoi_stat,
      customClass: "custom-class",
    };
  });
  return (
    <Calendar
      localizer={momentLocalizer(moment)}
      events={formattedEvents}
      startAccessor="start"
      endAccessor="end"
      style={{ minHeight: 500 }}
      titleAccessor="title"
      eventPropGetter={eventStyleGetter}
      components={{
        event: ({ event }) => {
          return <EventComponent event={event} />;
        },
      }}
    />
  );
};
export default CalendarView;
