import React from "react";
import { Pie } from "react-chartjs-2";

const AverageCostPerUserChart = ({ shiftData }) => {
  if (!shiftData || !shiftData.average_cost_per_user) {
    return null; // Optionally, return a placeholder or loading indicator here
  }

  const chartColors = [
    "rgba(255, 99, 132, 0.8)", // red
    "rgba(54, 162, 235, 0.8)", // blue
    "rgba(255, 206, 86, 0.8)", // yellow
    "rgba(75, 192, 192, 0.8)", // green
    "rgba(153, 102, 255, 0.8)", // purple
    "rgba(255, 159, 64, 0.8)", // orange
    // Add more colors if needed
  ];

  const data = {
    labels: Object.keys(shiftData.average_cost_per_user),
    datasets: [
      {
        data: Object.values(shiftData.average_cost_per_user),
        backgroundColor: chartColors.slice(
          0,
          Object.keys(shiftData.average_cost_per_user).length
        ),
      },
    ],
  };

  return <Pie data={data} />;
};
export default AverageCostPerUserChart;
