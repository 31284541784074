import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  FormControl,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Card,
} from "react-bootstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { BUSY_TIMES } from "./utils";
import ImageUploader from "react-images-upload";
import { ShareSocial } from "react-share-social";
class AdminShareMultipleStalls extends Component {
  state = {
    searchVendor: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    eventName: "",
    eventOrganiserEmail: "",
    eventOrganiserFullName: "",
    showConfirmationModal: false,
    coverImage: "",
    onlineOrdering: true,
    selectedVendorList: [],
    selectedVendorName: [],
    vendorList: [],
    tempVendorList: [],
    selectedVendorList: [], // Array to store selected vendor IDs
    selectedVendorNames: [],
    eventDescription: "",
    coverImage: [],
    showSuccess: false,
    eventId: -1,
    errorArray: [],
    nonMembersCommisiion: "0",
    membersCommission: "0",
    siteFees: "0",
  };

  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConfirmationModal: !prevState.showConfirmationModal,
    }));
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState(
      {
        coverImage: pictureDataURLs,
      },
      () => {}
    );
  };

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            vendorList: response.vendors,
            tempVendorList: response.vendors,
          });
        }
      });
  };

  saveEvent = () => {
    const {
      eventStartDateTime,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      email,
      eventName,
      eventType,
      eventStatus,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      membersCommission,
      nonMembersCommisiion,
      siteFees,
      eventOrganiserEmail,
      eventOrganiserFullName,
    } = this.state;

    if (
      eventType == 0 ||
      eventName == "" ||
      eventLocation == "" ||
      eventStartDateTime == "" ||
      eventEndDateTime == "" ||
      eventStatus == 0 ||
      selectedVendorList.length <= 0
    ) {
      alert("Please enter all the required fields");
      return;
    }
    const formattedEventStartDateTime = moment(eventStartDateTime).format("DD MMM YYYY")
    const formattedEOIStartDateTime = moment(eventStartDateTime).format("DD MMM YYYY")
    this.toggleConfirmationModal();
    this.setState({ isSaving: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var stringVendor = selectedVendorList.map((i) => i.toString());
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_wtt_sharable_stalls",
          event_name: eventName,
          eventOrganiserEmail: eventOrganiserEmail,
          eventOrganiserFullName: eventOrganiserFullName,
          vendors: stringVendor,
          email: email,
          eventLocation: eventLocation,
          eventStartDateTime: formattedEventStartDateTime,
          eventEndDateTime: formattedEOIStartDateTime

        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              showSuccess: true,
              isSaving: false,
            });
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getAllVendors();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </>
    );
  }
  renderHome() {
    const {
      isSaving,
      searchVendor,
      eventName,
      selectedVendorName,
      vendorList,
      eventOrganiserFullName,
      eventOrganiserEmail,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime

    } = this.state;
    return (
      <>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Row>
            <Col xs={12} md={12} xs={12}>
              <p>
                Fill up the below form to share stalls with event organisers for
                vendors
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Event Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Event Name"
                    value={eventName}
                    onChange={(e) => {
                      this.setState({ eventName: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Event Organiser Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Event Organiser Full Name"
                    value={eventOrganiserFullName}
                    onChange={(e) => {
                      this.setState({ eventOrganiserFullName: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Event Organiser Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Event Organiser Email"
                    value={eventOrganiserEmail}
                    onChange={(e) => {
                      this.setState({ eventOrganiserEmail: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Location</Form.Label>
                  <PlacesAutocomplete
                    value={eventLocation}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={{
                      componentRestrictions: { country: "au" },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter event location ",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formStartTime">
                  <Form.Label>*Event Start Date Time</Form.Label>
                  <form noValidate>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      value={
                        eventStartDateTime &&
                        eventStartDateTime != null &&
                        eventStartDateTime.toString()
                      }
                      onChange={(event) => {
                        this.setState(
                          { eventStartDateTime: event.target.value },
                          () => {
                            var end = moment(
                              this.state.eventStartDateTime,
                              "YYYY-MM-DDTHH:mm"
                            ).add(1, "hours");
                            end = moment(end).format("YYYY-MM-DDTHH:mm");
                          }
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formStartTime">
                  <Form.Label>*EOI Start Date</Form.Label>
                  <form noValidate>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      value={
                        eventEndDateTime &&
                        eventEndDateTime != null &&
                        eventEndDateTime.toString()
                      }
                      onChange={(event) => {
                        this.setState(
                          { eventEndDateTime: event.target.value },
                          () => {}
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Form.Group>

                <Form.Group
                  as={Col}
                  controlId="my_multiselect_field"
                  style={{ paddingLeft: 0 }}
                >
                  <Form.Label>*Applications For Vendors</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text value={searchVendor}>
                      <img
                        style={{ width: 20 }}
                        src={require("../../assets/img/search.gif")}
                      ></img>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search for vendors"
                      aria-label="Search for vendors"
                      onChange={(e) => {
                        var searchString = e.target.value;
                        if (searchString == "") {
                          this.setState({
                            vendorList: this.state.tempVendorList,
                          });
                        } else {
                          var result = vendorList.filter((obj) => {
                            if (
                              obj.title.search(new RegExp(searchString, "i")) !=
                              -1
                            ) {
                              return obj;
                            }
                          });
                          this.setState({ vendorList: result });
                        }
                      }}
                    />
                  </InputGroup>

                  <Form.Check
                    type="checkbox"
                    id="clear"
                    label="Clear"
                    checked={
                      this.state.selectedVendorList &&
                      this.state.tempVendorList &&
                      this.state.selectedVendorList.length === 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ selectedVendorList: [] });
                      }
                    }}
                  />
                  <div style={{ height: "200px", overflowY: "auto" }}>
                    {this.state.vendorList.map((vendor) => (
                      <Form.Check
                        key={vendor.user_ptr_id}
                        type="checkbox"
                        id={`vendor-${vendor.user_ptr_id}`}
                        label={vendor.title}
                        checked={this.state.selectedVendorList.includes(
                          vendor.user_ptr_id
                        )}
                        onChange={(e) => {
                          const vendorId = vendor.user_ptr_id;
                          const vendorName = vendor.title;
                          const selectedVendorList = [
                            ...this.state.selectedVendorList,
                          ];
                          const selectedVendorNames = [
                            ...this.state.selectedVendorNames,
                          ];

                          if (e.target.checked) {
                            selectedVendorList.push(vendorId);
                            selectedVendorNames.push(vendorName);
                          } else {
                            const index = selectedVendorList.indexOf(vendorId);
                            if (index >= 0) {
                              selectedVendorList.splice(index, 1);
                              selectedVendorNames.splice(index, 1);
                            }
                          }

                          this.setState({
                            selectedVendorList,
                            selectedVendorNames,
                          });
                        }}
                      />
                    ))}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                ></Form.Group>

                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.saveEvent();
                  }}
                  disabled={isSaving} // Disable the button while saving
                >
                  {isSaving ? "Saving..." : "Submit"}{" "}
                  {/* Display loading indicator */}
                </Button>
              </Form>
              <div>
                <h4>Selected Vendors:</h4>
                <ul>
                  {this.state.selectedVendorNames.map((vendorName) => (
                    <li key={vendorName}>{vendorName}</li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>

          {this.renderSuccess()}
          {this.renderConfirmationModal()}
        </Container>
        <Footer />
      </>
    );
  }
  renderSuccess() {
    const { showSuccess, eventId, errorArray } = this.state;
    var url = window.location.href.replace("/admin-share-multiple-stalls", "");
    return (
      <>
        <Modal
          show={showSuccess}
          onHide={() => {
            window.location.reload();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Submitted Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
  renderConfirmationModal() {
    const {
      showConfirmationModal,
      eventName,
      selectedVendorNames,
    } = this.state;
    return (
      <Modal show={showConfirmationModal} onHide={this.toggleConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to apply for the event{" "}
          <strong>{eventName}</strong> for the following vendors?
          <ul>
            {selectedVendorNames.map((vendorName) => (
              <li key={vendorName}>{vendorName}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSubmitConfirmed}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminShareMultipleStalls, {
    fallback: <ErrorFallbackComponent />,
  })
);
