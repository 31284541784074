import React, { useState } from "react";
import FileUploader from "../FileUploader";
import { Row, Form, Col, Accordion, Card } from "react-bootstrap";
import "../../style.css";
import Textfield from "../Textfield";
import CustTextArea from "../CustTextArea";
import CardMedia from "@mui/material/CardMedia";

const StallDetails = (props) => {
  const { formik, viewOnly } = props;
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        style={{
          color: "black",
        }}
      >
        1. Vendor DETAILS
        {(formik.errors.logoFile ||
          formik.errors.stallName ||
          formik.errors.eventPhotos ||
          formik.errors.stallDescription ||
          formik.errors.liabilityCert) &&
        !viewOnly ? (
          <div style={{ color: "red", fontWeight: "bold" }}>
            {"Information Missing"}
          </div>
        ) : null}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Outlet Name*: </h6>
                  {formik.touched.stallName && formik.errors.stallName ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.stallName}
                    </div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Stall Name"}
                    defaultValue={formik.values.stallName}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"stallName"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Vendor Name*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    defaultValue={formik.values.stallName}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Vendor Contact Number*: </h6>
                  {formik.touched.contactNumber &&
                  formik.errors.contactNumber ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.contactNumber}
                    </div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Vendor Contact Number"}
                    defaultValue={formik.values.contactNumber}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"contactNumber"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Vendor Contact Number*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    defaultValue={formik.values.contactNumber}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Email Address*: </h6>
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "red" }}>{formik.errors.email}</div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Vendor Email"}
                    defaultValue={formik.values.email}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"email"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Email Address*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    defaultValue={formik.values.email}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>City*: </h6>
                  {formik.touched.city && formik.errors.city ? (
                    <div style={{ color: "red" }}>{formik.errors.city}</div>
                  ) : null}
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Stall Name"}
                    defaultValue={formik.values.city}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"city"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>City*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    defaultValue={formik.values.city}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>ABN Number(Optional): </h6>
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Stall Name"}
                    defaultValue={formik.values.abnNumber}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"abnNumber"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>ABN Number(Optional): </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={formik.values.abnNumber}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <h6>Logo or Heading Photo*: </h6>
              {formik.touched.logoFile && formik.errors.logoFile ? (
                <div style={{ color: "red" }}>{formik.errors.logoFile}</div>
              ) : null}
              <FileUploader
                allowMultiple={false}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"logoFile"}
                allowFileTypeValidation={true}
                acceptedFileTypes={["image/*"]}
                defaultValue={formik.values.logoFile}
              />
            </div>
          ) : (
            <div className="title-heading">
              <h6>Logo or Heading Photo*: </h6>
              <img
                className="d-block w-100"
                src={
                  formik.values.logoFile &&
                  formik.values.logoFile != null &&
                  formik.values.logoFile != ""
                    ? formik.values.logoFile
                    : require("../../../../assets/img/default_cover.jpg")
                }
                alt="Vendor Logo"
                height="200px"
                width="400px"
              />
            </div>
          )}

          {!viewOnly ? (
            <div className="title-heading">
              <h6>Photos of this catering at a similar event*: </h6>
              {formik.touched.eventPhotos && formik.errors.eventPhotos ? (
                <div style={{ color: "red" }}>{formik.errors.eventPhotos}</div>
              ) : null}
              <FileUploader
                allowMultiple={true}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"eventPhotos"}
                allowFileTypeValidation={true}
                acceptedFileTypes={["image/*"]}
                defaultValue={formik.values.eventPhotos}
              />
            </div>
          ) : (
            <div className="title-heading">
              <h6>Photos of this catering at a similar event*: </h6>
              {formik.values.eventPhotos.map(({ item }) => (
                <img
                  className="d-block w-100"
                  src={
                    item && item != null && item != ""
                      ? item
                      : require("../../../../assets/img/default_cover.jpg")
                  }
                  alt="Event Photos"
                  height="200px"
                  width="200px"
                />
              ))}
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <h6>
                Description of what type of food/drink/service you want to
                sell/provide*:
              </h6>
              {formik.touched.stallDescription &&
              formik.errors.stallDescription ? (
                <div style={{ color: "red" }}>
                  {formik.errors.stallDescription}
                </div>
              ) : null}
              <CustTextArea
                label={"Description"}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"stallDescription"}
                defaultValue={formik.values.stallDescription}
              />
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>
                    Description of what type of food/drink/service you want to
                    sell/provide*:
                  </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    defaultValue={formik.values.stallDescription}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}

          {!viewOnly ? (
            <div className="title-heading">
              <h6>Public and Product liability Certificate*: </h6>
              {formik.touched.liabilityCert && formik.errors.liabilityCert ? (
                <div style={{ color: "red" }}>
                  {formik.errors.liabilityCert}
                </div>
              ) : null}
              <FileUploader
                allowMultiple={true}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"liabilityCert"}
                defaultValue={formik.values.liabilityCert}
              />
            </div>
          ) : (
            <div className="title-heading">
              <h6>Public and Product liability Certificate*: </h6>
              {formik.values.liabilityCert.map(({ item }) => (
                <img
                  src={
                    item && item != null && item != ""
                      ? item
                      : require("../../../../assets/img/default_cover.jpg")
                  }
                  alt="Liability Certificate"
                  height="200px"
                  width="200px"
                  onError={(e) => {
                    const reader = new FileReader();
                    reader.onload = function() {
                      e.target.src = reader.result;
                    };
                    reader.readAsDataURL(item);
                  }}
                />
              ))}
            </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default StallDetails;
