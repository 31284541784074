import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import DatePicker from "react-datepicker";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import moment from "moment";
import useFecth from "../../hook/useFecth";

function EventForm() {
  const { register, handleSubmit } = useForm({});
  const [EOIName, set_EOIName] = useState("");
  const [nameError, set_EOIError] = useState("");
  const [logoFile, set_logoFile] = useState(null);
  const [contactNumber, set_contactNumber] = useState("");
  const [contactError, set_contactError] = useState("");
  const [eventBumpoutDateTime, setEventBumpoutDateTime] = useState(new Date());
  const [eventBumpinDateTime, setEventBumpinDateTime] = useState(new Date());
  const [eventStartDateTime, setEventStartDateTime] = useState(new Date());
  const [eventEndDateTime, setEventEndDateTime] = useState(new Date());
  const [email, set_email] = useState("");
  const [emailError, set_emailError] = useState("");
  const [city, set_city] = useState("");
  const [status, set_status] = useState("");
  const [abnNumber, set_abnNumber] = useState("");
  const [abnError, set_abnError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organiserAddress, setOrganiserAddress] = useState("");
  const [tandcFile, set_tandcFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [services, set_services] = useState("");
  const [waterAccess, set_waterAccess] = useState("");
  const [numberOf10Amp, set_numberOf10Amp] = useState("");
  const [numberOf15Amp, set_numberOf15Amp] = useState("");
  const [numberOf20Amp, set_numberOf20Amp] = useState("");
  const [numberOf32Amp, set_numberOf32Amp] = useState("");
  const [membersCommission, setMembersCommission] = useState("");
  const [nonMembersCommission, setNonMembersCommission] = useState("");
  const [siteFees, setSiteFees] = useState("");
  const [bondAmount, setBondAmount] = useState("");
  const [eoiExpiryDateTime, setEoiExpiryDateTime] = useState(new Date());
  const [eoiDescription, setEoiDescription] = useState("");
  const [isSingleDayEvent, setIsSingleDayEvent] = useState(false);
  const [datesArray, setDatesArray] = useState([]);
  const [raw_bumpin, setRaw_bumpin] = useState(null);

  const postVendors = async (data) => {
    console.log("bumpin", eventBumpinDateTime);
    console.log("start", eventStartDateTime);
    if (eventBumpinDateTime > eventStartDateTime) {
      // Show an alert or handle the validation error as needed
      alert("Bumpin date must be earlier than Start date");
    } else if (eventBumpinDateTime > eventBumpoutDateTime) {
      alert("Bumpout date must be after than Bumpin Date");
    } else if (eventStartDateTime > eventEndDateTime) {
      alert("EOI start date must be before EOI end date");
    } else {
      // Format the dates for API submission
      data["eventBumpoutDateTime"] = moment(eventBumpoutDateTime).format(
        "YYYY-MM-DDTHH:mm:00[Z]"
      );
      data["eventBumpinDateTime"] = moment(eventBumpinDateTime).format(
        "YYYY-MM-DDTHH:mm:00[Z]"
      );
      data["eventStartDateTime"] = moment(eventStartDateTime).format(
        "YYYY-MM-DDTHH:mm:00[Z]"
      );
      data["eventEndDateTime"] = moment(eventEndDateTime).format(
        "YYYY-MM-DDTHH:mm:00[Z]"
      );
      data["eoiExpiryDateTime"] = moment(eoiExpiryDateTime).format(
        "YYYY-MM-DDTHH:mm:00[Z]"
      );
      const formData = new FormData();
      // for (const key in data) {
      //   formData.append(key, data[key]);
      // }
      formData.append(
        "organiser_id",
        JSON.parse(getFromLocalStorage("Organiser"))[0].id
      );
      if (data.EOIName) formData.append("eoi_name", data.EOIName);
      if (data.city) formData.append("site_area", data.city);
      if (data.services) formData.append("services", data.services);
      if (data.eventBumpinDateTime)
        formData.append("bump_in_date_time", data.eventBumpinDateTime);
      if (data.eventBumpoutDateTime)
        formData.append("bump_out_date_time", data.eventBumpoutDateTime);
      if (data.eventStartDateTime)
        formData.append("event_start_date_time", data.eventStartDateTime);
      if (data.eventEndDateTime)
        formData.append("event_end_date_time", data.eventEndDateTime);
      if (data.email) formData.append("eoi_email", data.email);
      if (data.status === "active") formData.append("eoi_status", "approved");
      if (data.status === "draft") formData.append("eoi_status", "processing");
      if (data.contactNumber) formData.append("eoi_mobile", data.contactNumber);
      if (data.abnNumber)
        formData.append("event_organiser_abn", data.abnNumber);
      if (data.firstName)
        formData.append("event_organiser_first_name", data.lastName);
      if (data.lastName)
        formData.append("event_organiser_last_name", data.firstName);
      if (data.organiserAddress)
        formData.append("event_organiser_address", data.organiserAddress);
      if (data.tandcFile)
        formData.append("event_terms_and_conditions", data.tandcFile[0]);
      if (data.logo) formData.append("logo", data.logo[0]);
      if (data.waterAccess) formData.append("water_access", data.waterAccess);
      if (data.numberOf10Amp) {
        formData.append("cost_of_10_amp", data.numberOf10Amp);
      } else {
        formData.append("cost_of_10_amp", 0);
      }
      if (data.numberOf15Amp) {
        formData.append("cost_of_15_amp", data.numberOf15Amp);
      } else {
        formData.append("cost_of_15_amp", 0);
      }
      if (data.numberOf20Amp) {
        formData.append("cost_of_20_amp", data.numberOf20Amp);
      } else {
        formData.append("cost_of_20_amp", 0);
      }
      if (data.numberOf32Amp) {
        formData.append("cost_of_32_amp", data.numberOf32Amp);
      } else {
        formData.append("cost_of_32_amp", 0);
      }
      if (data.logoFile) formData.append("eoi_cover", data.logoFile[0]);
      if (data.membersCommission)
        formData.append("members_commission", data.membersCommission);
      if (data.nonMembersCommission)
        formData.append("non_members_commission", data.nonMembersCommission);
      if (data.siteFees) formData.append("site_fees", data.siteFees);
      if (data.bondAmount) formData.append("bond_amount", data.bondAmount);
      if (data.eoiExpiryDateTime)
        formData.append("eoi_expiry_date_time", data.eoiExpiryDateTime);
      if (data.eoiDescription)
        formData.append("eoi_description", data.eoiDescription);
      if (datesArray && datesArray.length > 0) {
        const filteredDatesArray = datesArray
          .filter((date) => date.date !== null)
          .map((dateObj) => {
            const bumpInDate = moment(dateObj.date)
              .hour(dateObj.bumpIn.getHours())
              .minute(dateObj.bumpIn.getMinutes())
              .second(0);
            const bumpOutDate = moment(dateObj.date)
              .hour(dateObj.bumpOut.getHours())
              .minute(dateObj.bumpOut.getMinutes())
              .second(0);
            return {
              ...dateObj,
              date: moment(dateObj.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              endDate: moment(dateObj.endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              bumpIn: bumpInDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
              bumpOut: bumpOutDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
            };
          });

        formData.append("dates_array", JSON.stringify(filteredDatesArray));
      }

      if (!isSingleDayEvent) {
        formData.append("is_single_day_event", true);
      } else {
        formData.append("is_single_day_event", false);
      }
      try {
        const response = await axios.post(
          config.BASE_URI + apiPaths.eoiApplicationForm,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success === true) {
          alert("EOI created successfully!");
          // window.location.reload();
        } else {
          alert("Please try again");
          // window.location.reload();
        }
      } catch (error) {
        alert("Please try again");
        // window.location.reload();
      }
    }
  };
  // Function to handle date changes for eventStartDateTime
  const handleStartDateTimeChange = (newDateTime) => {
    setEventStartDateTime(newDateTime);
  };

  // Function to handle date changes for eventEndDateTime
  const handleEndDateTimeChange = (newDateTime) => {
    setEventEndDateTime(newDateTime);
  };

  // Function to handle date changes for eventBumpinDateTime
  const handleBumpinDateTimeChange = (newDateTime) => {
    setEventBumpinDateTime(newDateTime);
  };
  const handleEOIoiExpiryDateTimeChange = (newDateTime) => {
    setEoiExpiryDateTime(newDateTime);
  };

  // Function to handle date changes for eventBumpoutDateTime
  const handleBumpoutDateTimeChange = (newDateTime) => {
    setEventBumpoutDateTime(newDateTime);
  };

  const handleDateChange = (property, value, index) => {
    const updatedDatesArray = [...datesArray];
    updatedDatesArray[index][property] = value;
    setDatesArray(updatedDatesArray);
    console.log(datesArray);
  };
  const handleAddDate = () => {
    setDatesArray([
      ...datesArray,
      {
        date: null,
        expected_attendance: 0,
        event_name: "",
        endDate: null,
        bumpIn: new Date(),
        bumpOut: new Date(),
      },
    ]);
    console.log(datesArray);
  };
  const handleRemoveDate = (index) => {
    const updatedDatesArray = [...datesArray];
    updatedDatesArray.splice(index, 1);
    setDatesArray(updatedDatesArray);
    console.log(datesArray);
  };

  return (
    <div className="form-style-5">
      <form onSubmit={handleSubmit(postVendors)}>
        <div>
          <legend>
            <span className="number">EOI Details:</span>
          </legend>
          <input
            placeholder="EOI Name*:"
            type="text"
            id="EOIName"
            name="EOIName"
            {...register("EOIName")}
            required
          />
          {/* {nameError && <div style={{ color: "red" }}>{nameError}</div>} */}
          <input
            placeholder="Site area*:"
            type="text"
            id="city"
            name="city"
            {...register("city")}
            required
          />
          <label>Services*</label>
          <select {...register("services")} defaultValue="">
            <option value="" disabled>
              Please select
            </option>
            <option value="food">food</option>
            <option value="retail">retail</option>
            <option value="other">other</option>
          </select>
          <label>EOI Start and End Date:</label>
          <Row style={{ paddingBottom: "10px" }}>
            <Col xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeField
                  defaultValue={eventStartDateTime}
                  format="dd/MM/yyyy HH:mm"
                  onChange={handleStartDateTimeChange}
                />
              </LocalizationProvider>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeField
                  defaultValue={eventEndDateTime}
                  format="dd/MM/yyyy HH:mm"
                  onChange={handleEndDateTimeChange}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <label>Bump In and Bump Out date:</label>
          <Row style={{ paddingBottom: "15px" }}>
            <Col xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeField
                  defaultValue={eventBumpinDateTime}
                  format="dd/MM/yyyy HH:mm"
                  onChange={handleBumpinDateTimeChange}
                />
              </LocalizationProvider>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeField
                  defaultValue={eventBumpoutDateTime}
                  format="dd/MM/yyyy HH:mm"
                  onChange={handleBumpoutDateTimeChange}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <input
            placeholder="EOI Email*:"
            type="text"
            id="email"
            name="email"
            {...register("email")}
            required
          />
          <label>EOI Status*</label>
          <select defaultValue="" {...register("status")}>
            <option value="" disabled>
              Please select
            </option>
            <option value="active">active</option>
            <option value="draft">draft</option>
          </select>
          <input
            placeholder="EOI Mobile*:"
            type="number"
            id="contactNumber"
            name="contactNumber"
            {...register("contactNumber")}
            required
          />
          <input
            placeholder="Event Organiser ABN Number:"
            type="number"
            id="abnNumber"
            name="abnNumber"
            {...register("abnNumber")}
          />
        </div>
        <input
          placeholder="Event organiser first name:"
          type="text"
          id="lastName"
          name="lastName"
          {...register("lastName")}
        />
        <input
          placeholder="Event organiser last name:"
          type="text"
          id="firstName"
          name="firstName"
          {...register("firstName")}
        />
        <input
          placeholder="Event organiser address:"
          type="text"
          id="organiserAddress"
          name="organiserAddress"
          {...register("organiserAddress")}
        />
        <div>
          <label htmlFor="logoFile">
            Upload the Event terms and conditions:
          </label>
          <input
            type="file"
            id="tandcFile"
            name="tandcFile"
            accept=".jpg,.jpeg,.png,.pdf"
            {...register("tandcFile")}
          />
        </div>
        <label>Event water access:</label>
        <select defaultValue="" {...register("waterAccess")}>
          <option value="" disabled>
            Please select
          </option>
          <option value="Not Required">Not Required</option>
          <option value="Self Sufficient">Self Sufficient</option>
          <option value="Bucket / Barrel Fill">Bucket / Barrel Fill</option>
          <option value="Hose Connection">Hose Connection</option>
        </select>
        <label>Event power details:</label>
        <input
          placeholder="Cost of 10 Amp Outlet/s:"
          type="number"
          id="numberOf10Amp"
          name="numberOf10Amp"
          {...register("numberOf10Amp")}
          required
        />
        <input
          placeholder="Cost of 15 Amp Outlet/s:"
          type="number"
          id="numberOf15Amp"
          name="numberOf15Amp"
          {...register("numberOf15Amp")}
          required
        />
        <input
          placeholder="Cost of 20 amps 3 phase Outlet/s:"
          type="number"
          id="numberOf20Amp"
          name="numberOf20Amp"
          {...register("numberOf20Amp")}
          required
        />
        <input
          placeholder="Cost of 32 amps 3 phase Outlet/s:"
          type="number"
          id="numberOf32Amp"
          name="numberOf32Amp"
          {...register("numberOf32Amp")}
          required
        />
        <div>
          <label htmlFor="logoFile">Upload Eoi cover:</label>
          <input
            type="file"
            id="logoFile"
            name="logoFile"
            accept=".jpg,.jpeg,.png,.pdf"
            {...register("logoFile")}
          />
        </div>
        <input
          placeholder="Members Commission"
          id="membersCommission"
          name="membersCommission"
          {...register("membersCommission")}
          type="number"
        />
        <input
          placeholder="Non members commission"
          id="nonMembersCommission"
          name="nonMembersCommission"
          {...register("nonMembersCommission")}
          type="number"
        />
        <input
          placeholder="Site fees:"
          id="siteFees"
          name="siteFees"
          {...register("siteFees")}
          type="number"
        />
        <input
          placeholder="Bond amount:"
          id="bondAmount"
          name="bondAmount"
          {...register("bondAmount")}
          type="number"
        />
        <label>Eoi expiry date time:</label>
        <Row style={{ paddingBottom: "15px" }}>
          <Col xs={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimeField
                defaultValue={eoiExpiryDateTime}
                format="dd/MM/yyyy HH:mm"
                onChange={handleEOIoiExpiryDateTimeChange}
              />
            </LocalizationProvider>
          </Col>
        </Row>
        <textarea
          placeholder="EOI Description"
          type="text"
          rows="4"
          cols="75"
          {...register("eoiDescription")}
        ></textarea>
        <label>
          <span>Is it a multiple day event?</span>
          {/* isSingleDayEvent represents isMultipleDayEvent */}
          <input
            className="paddingPlease"
            type="checkbox"
            checked={isSingleDayEvent}
            onChange={(e) => setIsSingleDayEvent(e.target.checked)}
          />
        </label>
        {isSingleDayEvent === false ?
          <div>
            <label>Select Dates</label>
            {datesArray.map((item, index) => (
              <div key={index}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimeField
                    value={item.date}
                    format="dd/MM/yyyy HH:mm"
                    onChange={(selectedDate) => {
                      handleDateChange("date", selectedDate, index);
                    }}
                  />
                </LocalizationProvider>
                <input
                  type="number"
                  placeholder="Expected Attendance: "
                  value={item.expected_attendance || ""}
                  onChange={(e) =>
                    handleDateChange(
                      "expected_attendance",
                      Number(e.target.value),
                      index
                    )
                  }
                />
                <input
                  type="text"
                  placeholder="Event Name"
                  value={item.event_name || ""}
                  onChange={(e) =>
                    handleDateChange("event_name", e.target.value, index)
                  }
                />
                <label>BumpIn and BumpOut Time for the day:</label>
                <Row style={{ paddingBottom: "15px" }}>
                  <Col xs={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        value={item.bumpIn}
                        onChange={(selectedDate) => {
                          handleDateChange("bumpIn", selectedDate, index);
                        }}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        value={item.bumpOut}
                        onChange={(selectedDate) => {
                          handleDateChange("bumpOut", selectedDate, index);
                        }}
                      />
                    </LocalizationProvider>
                  </Col>
                </Row>
                <label>Event End Date†:</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimeField
                    value={item.endDate}
                    format="dd/MM/yyyy HH:mm"
                    onChange={(selectedDate) => {
                      handleDateChange("endDate", selectedDate, index);
                    }}
                  />
                </LocalizationProvider>
                <p>[† - This is the event end date not the EOI End date]</p>
                <button
                  type="button"
                  className="date"
                  onClick={() => handleRemoveDate(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button type="button" className="date" onClick={handleAddDate}>
              Add Date
            </button>
          </div>
        :  <div>
            
           
            <button type="button" className="date" onClick={handleAddDate}>
              Add Date
            </button>
          </div>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
export default EventForm;
