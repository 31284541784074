import React, { useEffect, useState } from "react";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../../store";
import * as Sentry from "@sentry/react";
import { Redirect } from "react-router-dom";

class Reload extends React.Component {
  componentDidMount() {
    window.onbeforeunload = this.handleReload;
  }

  handleReload() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: JSON.parse(getFromLocalStorage("Operator"))[0].email,
          password: JSON.parse(getFromLocalStorage("Operator"))[0].password,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (
          dataResponse.success == true &&
          dataResponse.data[0].is_operator == true
        ) {
          saveToLocalStorage("Operator", JSON.stringify(dataResponse.data));
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      })
      .finally(() => {
        // Finally, reload the page
        if (getFromLocalStorage("isOperatorLoggedIn") === "false") {
          // Redirect to "/operator-login" if the condition is met
          window.location.href = "/operator-login";
        } else {
          // Redirect to "/apply-events/home" if the condition is not met
          window.location.href = "/apply-events/home";
        }
        window.location.href = "/apply-events/home";
      });
  }

  render() {
    return <div></div>;
  }
}
export default Reload;
