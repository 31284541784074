import React, { useState, useEffect } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";

function Data() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    const username = getFromLocalStorage("username");
    const email = getFromLocalStorage("email");
    const phone = getFromLocalStorage("phone");

    if (username) {
      const words = username.split(" ");
      setFirstName(words[0]);
      const lastNameIndex = words.length - 1;
      const last =
        lastNameIndex === 0
          ? words[0]
          : words[lastNameIndex - 1] + " " + words[lastNameIndex];
      setLastName(last);
    }
    setEmail(email);
    setPhone(phone);
  }, []);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const postEditCustomerProfile = async (event) => {
    event.preventDefault();
    const url = config.BASE_URI + apiPaths.customerData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "edit_vendor_profile",
          vendor_id: vendorId,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          state: state,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          alert("Profile updated successfully!");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <div className="form-style-5">
      <form onSubmit={postEditCustomerProfile}>
        <label htmlFor="fname">First Name</label>
        <input
          type="text"
          id="fname"
          name="fname"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        <label htmlFor="lname">Last Name</label>
        <input
          type="text"
          id="lname"
          name="lname"
          value={lastName}
          onChange={handleLastNameChange}
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
        />
        <label htmlFor="state" className="label">
          State
        </label>
        <select value={state} onChange={handleStateChange}>
          <option value="" disabled>
            Please Select
          </option>
          <option value="wa">Western Australia</option>
          <option value="sa">South Australia</option>
          <option value="nt">Northern Territory</option>
          <option value="vic">Victoria</option>
          <option value="qld">Queensland</option>
          <option value="act">Australian Capital Territory</option>
          <option value="nsw">New South Wales</option>
          <option value="tas">Tasmania</option>
        </select>
        <button type="submit">Update Details</button>
      </form>
    </div>
  );
}
export default Data;
