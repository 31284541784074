import React, { Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import ExcelFileUploader from "./ExcelFileUploader.js";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockAdminScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    disapprovedReason: "",
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  fetchPendingOrderFromServer = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_admin_pending",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          console.log(result.data);
          this.setState({ pendingOrder: result.data });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again");
      });
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (isSuperAdminLoggedIn || isAccountantLoggedIn || isStockAdminLoggedIn) {
      this.fetchPendingOrderFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  disapprovOrder = (orderId) => {
    const {
      disapprovedReason,
      email,
      password,
      superAdminEmail,
      superAdminPassword,
    } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var data = {};
    if (email != "" && password != "") {
      data = {
        email: email,
        password: password,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    } else if (superAdminEmail != "" && superAdminPassword != "") {
      data = {
        email: superAdminEmail,
        password: superAdminPassword,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    }
    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Disapproved Succesfully");
          window.location.href = "/stock-admin";
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again!");
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const { pendingOrder } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Upload</h2>
            </Col>
          </Row>
          <>
            <ExcelFileUploader />
          </>
        </Container>
        <Footer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
