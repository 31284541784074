import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import PersonalInformation from "./personalInformation";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import * as Sentry from "@sentry/react";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Footer from "../Footer";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validate = (values) => {
  const errors = {};
  if (!values.firstName || values.firstName.length === 0) {
    errors.firstName = "First Name is required";
  }
  if (!values.lastName || values.lastName.length === 0) {
    errors.lastName = "Last Name is required";
  }
  if (!values.email || values.email.length === 0) {
    errors.email = "Email is required";
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Please enter a valid email address";
  }
  if (
    !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i.test(
      values.password
    )
  ) {
    errors.password =
      "Password must contain at least one digit (0-9), one lowercase character (a-z), one uppercase character (A-Z), and one special character";
  }
  if (!values.password || values.password.length === 0) {
    errors.password = "Password is required";
  }
  return errors;
};

const Register = () => {
  const [open, setOpen] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      if (!recaptchaValue) {
        alert("Please complete the ReCAPTCHA.");
        return;
      }

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const url = config.BASE_URI + apiPaths.adminData;
      const raw = JSON.stringify({
        payload: {
          body: {
            query_type: "admin_create_operator_account",
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            email: values.email,
          },
        },
      });
      const request = {
        method: "POST",
        headers: headers,
        body: raw,
      };
      fetch(url, request)
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setOpen(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert("Something went wrong");
        });
    },
  });

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        style={{ backgroundColor: "#2959a5", marginBottom: "20px" }}
      >
        <Navbar.Brand href="/operator-landing">
          <Image
            style={{ height: 40 }}
            src={require("../../../assets/img/welcome-logo.png")}
            fluid
          />
        </Navbar.Brand>
      </Navbar>
      <Container
        maxWidth="sm"
        style={{
          marginTop: "10%",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginBottom: 20,
            color: "#2959a5",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Register Form
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <PersonalInformation formik={formik} />
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <ReCAPTCHA
              style={{ marginTop: 20 }}
              sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
              onChange={handleRecaptchaChange}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                marginTop: 20,
                backgroundColor: "#ef3e6d",
                color: "#fff",
              }}
              disabled={!recaptchaValue}
            >
              Submit
            </Button>
          </div>
        </form>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Registration Succeeded!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Congratulations! Your registration is completed!
              <br />
              Please go to the login page by clicking the button below.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                window.location.replace("/operator-login");
              }}
              style={{ backgroundColor: "#2959a5", color: "#fff" }}
            >
              Go to Login
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Footer styleName="stay-footer" />
    </>
  );
};

export default Register;
