import React, { useState, useEffect } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";

import "./style.css";
import * as Sentry from "@sentry/react";
import { getFromLocalStorage } from "../../store";
import UploadIcon from "../../components/UI/UploadIcon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Axios from "axios";
import { format } from "date-fns";

function Data() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [state, setState] = useState("");
  const [bannerImg, setBannerImg] = useState(
    require("../../assets/img/empty_logo.png")
  );
  const [bannerImgPreview, setBannerImgPreview] = useState(
    require("../../assets/img/empty_logo.png")
  );
  const [officeAddress, setOfficeAddress] = useState("");
  const [abn, setAbn] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    const username = getFromLocalStorage("username");
    const email = getFromLocalStorage("email");
    const phone = getFromLocalStorage("phone");

    if (username) {
      const words = username.split(" ");
      setFirstName(words[0]);
      const lastNameIndex = words.length - 1;
      const last =
        lastNameIndex === 0
          ? words[0]
          : words[lastNameIndex - 1] + " " + words[lastNameIndex];
      setLastName(last);
    }
    setEmail(email);
    setPhone(phone);
  }, []);

  const handleOfficeAddressChange = (event) => {
    setOfficeAddress(event.target.value);
  };

  const handleABNChange = (event) => {
    setAbn(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    setPhone(inputValue);
    if (!regex.test(inputValue)) {
      setPhoneError("Please enter a valid contact number");
    } else {
      setPhoneError("");
    }
  };
  const handleStartTime = (val) => {
    setStartTime(val);
  };
  const handleEndTime = (val) => {
    setEndTime(val);
  };

  const handleUploadImage = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      setBannerImg(files[0]);
      var reader = new FileReader();

      reader.onload = (event) => {
        setBannerImgPreview(event.target.result);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmitOrganiserProfile = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (bannerImg) formData.append("banner_image", bannerImg);
    if (officeAddress) formData.append("office_address", officeAddress);
    if (phone) formData.append("office_phone_number", phone);
    if (abn) formData.append("abn", abn);
    if (startTime)
      formData.append("office_start_time", format(startTime, "HH mm"));
    if (endTime) formData.append("office_end_time", format(endTime, "HH mm"));

    Axios.post(config.BASE_URI + apiPaths.organiserProfile, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          alert("Profile updated successfully!");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Unable to update Organiser's Profile. Please try again.");
      });
  };

  return (
    <div className="form-style-5">
      <form onSubmit={handleSubmitOrganiserProfile}>
        <label className="label" htmlFor="banner-img">
        Banner Image (Hero shot of your top selling food items for website ordering)
        </label>

        <div className="upload-container">
          <div className={"upload-input-container"}>
            <div className="upload-icon-container">
              <UploadIcon fill="#F5F6FD" />
            </div>
            <input
              name="banner-img"
              accept="image/*"
              type="file"
              onChange={(e) => {
                handleUploadImage(e);
                // handleUploadImage(e?.target?.files ? e.target.files : {});
              }}
            />
          </div>
          <img
            className="object-cover w-full h-full"
            alt="avatar"
            width={145}
            height={145}
            src={bannerImgPreview}
          />
        </div>
        <label className="label" htmlFor="fname">
          Office Address
        </label>
        <textarea
          className="txtarea"
          placeholder="Office Address"
          type="text"
          rows="4"
          cols="75"
          value={officeAddress}
          onChange={handleOfficeAddressChange}
        ></textarea>
        <label className="label" htmlFor="phone">
          Office Phone Number{" "}
        </label>
        <input
          className="inpt"
          type="text"
          id="phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
        />
        {phoneError && <div style={{ color: "red" }}>{phoneError}</div>}

        <label className="label" htmlFor="lname">
          ABN Number
        </label>
        <input
          className="inpt"
          type="number"
          id="abn"
          name="abn"
          value={abn}
          onChange={handleABNChange}
        />

        <label className="label" htmlFor="lname">
          Office Start Time
        </label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            className="time"
            value={startTime}
            onChange={(selectedDate) => {
              handleStartTime(selectedDate);
            }}
          />
        </LocalizationProvider>
        <label className="label" htmlFor="lname">
          Office End Time
        </label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            className="time"
            value={endTime}
            onChange={(selectedDate) => {
              handleEndTime(selectedDate);
            }}
          />
        </LocalizationProvider>
        <button type="submit">Update Details</button>
      </form>
    </div>
  );
}
export default Data;
