import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col, Modal } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { FormControl, InputGroup } from "react-bootstrap";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventPOSChart } from "../EventOrganiserOverviewScreen/EventPOSBarGraph";

import { EventSalesBarGraph } from "../EventOrganiserOverviewScreen/EventSalesBarGraph";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import EditableForm from "./EditableForm";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";

class AdminCreateRunsheets extends Component {
  state = {
    searchVendor: "",
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedVendors: [],
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    total_voucher_value: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [""],
    newMealFrequency: [],
    salesByVendorObj: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    salesByEventName: [],
    salesByEventTotal: [],
    salesByEventObj: [],
    salesByPOSName: [],
    salesByPOSTotal: [],
    percentageChangeLastYear: 0,
    percentageChangeLastMonth: 0,
    percentageChangeLastWeek: 0,
    lastYearDatesInfo: {},
    lastMonthDatesInfo: {},
    lastWeekDatesInfo: {},
    salesModal: false,
    ordersModal: false,
    averageModal: false,
    refundsModal: false,
    refundsOrderModal: false,
    selectedVendorList: [],
    selectedVendorName: [],
    vendorList: [],
    tempVendorList: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isEventCoordinatorLoggedIn } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getAllEvents();
      this.getVendorsInEvent();
      this.getAllVendors();
    } else {
      window.location.href = "/admin";
    }
  }

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            vendorList: response.vendors,
            tempVendorList: response.vendors,
          });
        }
      });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_external_operators",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn ? (
          (window.location.href = "/admin")
        ) : (
          <>{this.renderHome()}</>
        )}
      </>
    );
  }

  renderHome() {
    const percentageChangeMessage = (newValue, oldValue) => {
      const percentageChange = ((newValue - oldValue) / oldValue) * 100;
      return `${Math.abs(percentageChange).toFixed(2)}%`;
    };

    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      total_voucher_value,
      selectedDate,
      startDate,
      endDate,
      salesByVendorObj,
      salesByEventObj,
      percentageChangeLastYear,
      percentageChangeLastMonth,
      percentageChangeLastWeek,
      newMealFrequency,
      meal_freq,
      searchVendor,
      vendorList,
      tempVendorList,
      selectedVendorName,
      selectedVendorList,
    } = this.state;

    if (selectedEvent) {
    }

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Form.Label>Select Event</Form.Label>
                  <Form.Group
                    controlId="exampleForm.SelectCustomSizeSm"
                    className="search-dropdown"
                  >
                    <div className="search-input">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedEventId}
                        onChange={(e) => {
                          this.setState(
                            { selectedEventId: "" + e.target.value },
                            () => {
                              this.getVendorsInEvent();
                            }
                          );
                          this.setState({ selectedDate: "-1" });
                          saveToLocalStorage(
                            "selectedEventId",
                            "" + e.target.value
                          );
                          saveToLocalStorage("selectedDate", "-1");
                          console.log(selectedEventId);
                        }}
                      >
                        <option value={0}>Select Event</option>
                        {events.map((e) => (
                          <option value={"" + e.id}>
                            {e.name +
                              " - " +
                              e.start_date +
                              " (" +
                              e.event_status +
                              ")"}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  controlId="my_multiselect_field"
                  style={{ paddingLeft: 0 }}
                >
                  <Form.Label>*Participating Vendors</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text value={searchVendor}>
                      <img
                        style={{ width: 20 }}
                        src={require("../../assets/img/search.gif")}
                      ></img>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search for vendors"
                      aria-label="Search for vendors"
                      onChange={(e) => {
                        var searchString = e.target.value;
                        if (searchString == "") {
                          this.setState({
                            vendorList: this.state.tempVendorList,
                          });
                        } else {
                          var result = vendorList.filter((obj) => {
                            if (
                              obj.title.search(new RegExp(searchString, "i")) !=
                              -1
                            ) {
                              return obj;
                            }
                          });
                          this.setState({ vendorList: result });
                        }
                      }}
                    />
                  </InputGroup>
                  {/* {selectedVendorName.length > 0 ? (
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                      {selectedVendorName.map((vendorName) => {
                        return (
                          <Col style={{ marginTop: 10 }} xs={4} md={4} lg={4}>
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "black",
                              }}
                            >
                              {vendorName}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null}                      */}
                  <Form.Check
                    type="checkbox"
                    id="clear"
                    label="Clear"
                    checked={
                      this.state.selectedVendorList &&
                      this.state.tempVendorList &&
                      this.state.selectedVendorList.length === 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ selectedVendorList: [] });
                      }
                    }}
                  />
                  <div style={{ height: "200px", overflowY: "auto" }}>
                    {this.state.vendorList.map((vendor) => (
                      <Form.Check
                        key={vendor.user_ptr_id}
                        type="checkbox"
                        id={`vendor-${vendor.user_ptr_id}`}
                        label={vendor.title}
                        checked={
                          this.state.selectedVendorList &&
                          this.state.vendorList &&
                          this.state.selectedVendorList.includes(
                            vendor.user_ptr_id
                          )
                        }
                        onChange={(e) => {
                          const vendorId = vendor.user_ptr_id;
                          const vendorName = vendor.title;
                          const selectedVendorList = this.state.selectedVendorList.slice();
                          if (e.target.checked) {
                            selectedVendorList.push(vendorId);
                            selectedVendorName.push(vendorName);
                          } else {
                            const index = selectedVendorList.indexOf(vendorId);
                            if (index >= 0) {
                              selectedVendorList.splice(index, 1);
                            }
                          }
                          console.log(selectedVendorList);
                          this.setState({ selectedVendorList });
                        }}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <EditableForm
                  selectedVendorList={this.state.selectedVendorList}
                  selectedEventId={this.state.selectedEventId}
                />
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminCreateRunsheets, {
    fallback: <ErrorFallbackComponent />,
  })
);
