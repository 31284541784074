import React from "react";
import { useEffect, useState } from "react";
import {
  DollarOutlined,
  CarOutlined,
  RiseOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

function TruckAnalysis() {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: {
      label: "Dataset of Months",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40, 74, 49, 65, 53, 71],
    },
  };
  return (
    <>
      <Space size={20} direction="vertical">
        <Typography.Title level={4}>Dashboard</Typography.Title>
        <Space direction="horizontal">
          <Card style={{ minHeight: "250px", minWidth: "250px" }}>
            <Space direction="vertical">
              <DollarOutlined
                style={{
                  color: "green",
                  backgroundColor: "rgba(0,255,0,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
              <Statistic title="Annual Revenue" value="$10.5M" />
            </Space>
          </Card>
          <Card style={{ minHeight: "250px", minWidth: "250px" }}>
            <Space direction="vertical">
              <CarOutlined
                style={{
                  color: "#9A913A",
                  backgroundColor: "#fcef72",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
              <Statistic title="Total Events" value="57" />
            </Space>
          </Card>
          <Card style={{ minHeight: "250px", minWidth: "250px" }}>
            <Space direction="vertical">
              <RiseOutlined
                align="middle"
                style={{
                  verticalAlign: "middle",
                  color: "red",
                  backgroundColor: "rgba(255,0,0,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
              <Statistic title="Top Events" value="Australia Open" />
              <h5>Melbourne Flower Show</h5>
              <h6>Finders Keepers</h6>
            </Space>
          </Card>
          <Card style={{ minHeight: "250px", minWidth: "250px" }}>
            <Space direction="vertical">
              <ShoppingCartOutlined
                style={{
                  color: "blue",
                  backgroundColor: "rgba(0,0,255,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
              <Statistic title="Top Items" value="Souvlaki" />
              <h5>Chips</h5>
              <h6>Parma</h6>
            </Space>
          </Card>
        </Space>
        <Space direction="horizontal">
          <Card style={{ minHeight: "500px", minWidth: "500px" }}>
            <Space direction="vertical">
              <ShoppingCartOutlined
                style={{
                  color: "blue",
                  backgroundColor: "rgba(0,0,255,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
              <Statistic title="Top Items" value="Souvlaki" />
              <h5>Chips</h5>
              <h6>Parma</h6>
            </Space>
          </Card>
          <Card style={{ minHeight: "500px", minWidth: "500px" }}>
            <Space direction="vertical">
              <ShoppingCartOutlined
                style={{
                  color: "blue",
                  backgroundColor: "rgba(0,0,255,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8,
                }}
              />
            </Space>
          </Card>
        </Space>
      </Space>
    </>
  );
}
export default Sentry.withErrorBoundary(TruckAnalysis, {
  fallback: <ErrorFallbackComponent />,
});
