import React, { Component, useCallback } from "react";
import SimpleMap from "../../components/GoogleMapComponent/index";
import Footer from "../../components/UI/Footer";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
import "./style.css";
import {
  Grid,
  Typography,
  Container,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Button,
  Box,
} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import AlertBox from "./AlertBox.js";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { InputGroup } from "react-bootstrap";
import { FormControl as FC } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { apiPaths } from "../../services/apiPath";
import AdminNavBar from "../../components/UI/AdminNavBar";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import config from "../../services/apiConfig";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import {
  getComfirmedVendors,
  getSelectedEoi,
} from "../../actions/EventOrganiser/action";
import { AWS_URL } from "../../assets/Constants";
class OrganiserEventContract extends Component {
  state = {
    isDialogVisible: false,
    isSuperAdminLoggedIn: "false",
    selectedVendorIds: [],
    isLoading: false,
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    acceptedVendorData: [],
    eventList: [],
    isOpen: false,
    selectedVendor: {},
    confirmVendors: [],
    noticeOpen: false,
    contractNoticeOpen: false,
    missingDoc: [],
    selectEvent: {},
    defaultLink: "../..assets/img/welcome-logo.png",
  };
  toggleDialog = () => {
    this.setState((prevState) => ({
      isDialogVisible: !prevState.isDialogVisible,
    }));
  };

  acceptVendors = async () => {
    const url = config.devUrl + apiPaths.adminData;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "accepted_vendor_outlets",
          event_id: this.state.selectEvent.id,
          accepted_vendor_ids: this.state.selectedVendorIds,
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      alert("Applying please wait!");

      if (result.success === true) {
        alert("Apply succeeded!");
        this.setState({ selectedVendorIds: [] });
        this.getTargetedEvents(this.state.selectEvent.id);
        this.getTargetedAcceptedEvents(this.state.selectEvent.id);
      } else {
        alert("The application failed. Please try later");
        this.getTargetedEvents(this.state.selectEvent.id);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      alert("The application failed. Please try later");
      this.setState({ selectedVendorIds: [] });
    }
  };

  getAcceptedVendors = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ acceptedVendorData: vendorList });
      return;
    }
    let applyVendor = [];

    vendorList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.accepted_outlets;

      fetch(vendorUrl, {
        method: "GET",
        redirect: "follow",
      }).then((response) =>
        response
          .json()
          .then((responseData) => {
            console.log(responseData);
            let isIdPresent = false;
            if (applyVendor) {
              for (let i = 0; i < applyVendor.length; i++) {
                if (applyVendor[i].id === responseData.id) {
                  isIdPresent = true;
                  break;
                }
              }
              if (!isIdPresent) {
                applyVendor.push(responseData);
              }
            }
          })
          .then((response) => {
            console.log(applyVendor);
            this.setState({ acceptedVendorData: applyVendor });
          })
      );
    });
  };

  getTargetedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_applied_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const vendorList = response.event_application;
        console.log(vendorList);
        this.getAllVendors(vendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedAcceptedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_accepted_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const acceptedVendorList = response.accepted_applications;
        this.getAcceptedVendors(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedRejectedEvents = (id) => {
    this.setState({ isLoading: true });
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_rejected_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ isLoading: false });
        const acceptedVendorList = response.rejected_applications;
        this.getRejected(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_current_eoi",
          email_id: emailID,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ eventList: data.eoi });
      });
  };
  componentDidMount = () => {
    this.getEvents();
  };

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  onClose = () => {
    this.setState({ isOpen: false });
  };

  onComfirm = (vendor) => {
    const documents = [
      { doc: vendor.food_refistration_docs, name: "food Registration Docs" },
      { doc: vendor.liablity_certificate, name: "Liablity Certificate" },
      { doc: vendor.meals, name: "Meals" },
      { doc: vendor.electricial_certificate, name: "Electrical Certificeate" },
      { doc: vendor.gas_certificate, name: "Gas certificate" },
      { doc: vendor.site_layout, name: "Site LayOut" },
    ];
    const missingDocument = [];
    documents.map((item) => {
      if (!item.doc) {
        missingDocument.push(item);
      }
    });
    if (missingDocument.length != 0) {
      this.setState({ missingDoc: missingDocument });
      this.setState({ noticeOpen: true });
    } else {
      if (this.state.confirmVendors.includes(vendor)) {
        alert("this Vendor aleady added!");
        this.onClose();
      } else {
        this.setState({
          confirmVendors: [...this.state.confirmVendors, vendor],
        });
        this.onClose();
      }
    }
  };

  removeVendor = (vendor) => {
    this.setState({
      confirmVendors: this.state.confirmVendors.filter(
        (item) => item !== vendor
      ),
    });
  };
  openNotice = () => {
    this.setState({ noticeOpen: true });
  };

  ContractNoticeComponent = ({ openContractNotice, selectEvent }) => {
    return (
      <Dialog
        open={openContractNotice}
        onClose={() => this.setState({ contractNoticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Contract"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to use your own contract or do you want to use our
            tempalete to generate final contract?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
            }}
          >
            Use Your contract
          </Button>

          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
              window.open("/contract-generator");
            }}
          >
            Use Our Contract Template
          </Button>

          <Button onClick={() => this.setState({ contractNoticeOpen: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  NoticeComponent = ({ document, selectedVendor, noticeOpens }) => {
    return (
      <Dialog
        open={noticeOpens}
        onClose={() => this.setState({ noticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing documentation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Documents is not uploaded by vendor owners, do you still want to
            accept this vendor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ noticeOpen: false })}>
            Disagree
          </Button>
          <Button
            onClick={() => {
              if (this.state.confirmVendors.includes(selectedVendor)) {
                alert("this Vendor aleady added!");
                this.setState({ noticeOpen: false });
              } else {
                this.setState({
                  confirmVendors: [
                    ...this.state.confirmVendors,
                    selectedVendor,
                  ],
                });
              }
              this.setState({ noticeOpen: false });
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  render() {
    const {
      isDialogVisible,
      isSuperAdminLoggedIn,
      isByState,
      isOrganiserLoggedIn,
      vendorName,
      acceptedVendorData,
      acceptVendors,
      isLoading,
    } = this.state;

    const csvAcceptedDataDownload = acceptedVendorData.map((vendor) => {
      const allMealsHaveDescription =
        vendor.meals?.every((meal) => meal.description?.trim() !== "") ?? false; // Default to false if 'meals' is undefined or empty

      // Check if meals array exists and then check if all meals have an image
      const allMealsHaveImage =
        vendor.meals?.every((meal) => meal.image) ?? false; // Default to false if 'meals' is undefined or empty

      return {
        "Vendor Outlet Name": vendor.title,
        "Vendor Location": vendor.address,
        "Vendor Email": vendor.email,
        "Vendor Contact Number": vendor.phone, // Assuming 'phone' is the correct field for contact number
        "Truck Dimensions": `${vendor.truck_height}x${vendor.truck_width}x${vendor.truck_depth}`,
        "Member Status": vendor.is_wtt_subscriber ? "Member" : "Not Member",
        "Number of 10 Amps": vendor.number_of_10_amp,
        "Number of 15 Amps": vendor.number_of_15_amp,
        "Number of 20 Amps": vendor.number_of_20_amp,
        "Number of 32 Amps": vendor.number_of_32_amp,
        "Water Access": vendor.water_access,
        "Electrical Certificate": vendor.electricial_certificate ? "Yes" : "No",
        "Fire Safety Certificate": vendor.fire_safety_certificate
          ? "Yes"
          : "No",
        "Food Registration Docs": vendor.food_refistration_docs ? "Yes" : "No",
        "Food Safety Certificate": vendor.food_safety_certificate
          ? "Yes"
          : "No",
        "Gas Certificate": vendor.gas_certificate ? "Yes" : "No",
        "Liability Certificate": vendor.liablity_certificate ? "Yes" : "No",
        "Working With Children Check": vendor.working_with_childrens_check
          ? "Yes"
          : "No",
        "Work Cover Insurance": vendor.work_cover_insurance ? "Yes" : "No",
        "Street Trader File": vendor.streat_trader_file ? "Yes" : "No",
        "Site Layout": vendor.site_layout ? "Yes" : "No",
        Image: vendor.image ? "Yes" : "No",
        Logo: vendor.logo ? "Yes" : "No",
        "Meal Description": allMealsHaveDescription ? "Yes" : "No",
        "Meal Images": allMealsHaveImage ? "Yes" : "No",
      };
    });
    const jsonToXLSX = (data, filename) => {
      console.log("clicked", data);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };
    return (
      <>
        <div>
          <AdminNavBar
            style={{ margin: "auto" }}
            isSuperAdminLoggedIn={isSuperAdminLoggedIn}
            isOrganiserLoggedIn={isOrganiserLoggedIn}
            logout={this.logout}
            vendorName={vendorName}
          ></AdminNavBar>
          <AlertBox
            open={this.state.isOpen}
            handleClose={this.onClose}
            vendorDetail={this.state.selectedVendor}
            eoiID={this.state.selectEvent.id}
            confirmVendor={this.onComfirm}
            rejectVendor={this.onReject}
          />
          <this.NoticeComponent
            document={this.state.missingDoc}
            noticeOpens={this.state.noticeOpen}
            selectedVendor={this.state.selectedVendor}
          />
          <Container style={{ minHeight: "600px" }}>
            <Paper
              elevation={3}
              square
              style={{
                margin: "auto",
                paddingTop: 20,
                width: "80%",
              }}
            >
              <Col
                xs={12}
                md={12}
                lg={12}
                className="create-events-basicInfo"
                style={{ margin: "auto", padding: 10 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Please Select your eoi
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Please select your event"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.selectEvent.eoi_event_name}
                    style={{ height: 60 }}
                    onChange={(e) => {
                      this.getTargetedEvents(e.target.value);
                      this.getTargetedAcceptedEvents(e.target.value);
                      this.getTargetedRejectedEvents(e.target.value);
                    }}
                  >
                    {this.state.eventList?.map((event, key) => (
                      <MenuItem value={event.id} index={key}>
                        {event.eoi_event_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Grid container spacing={3}>
                  <Grid item lg={12}>
                    <Paper elevation={3} style={{ height: "auto" }}>
                      {this.state.selectEvent.eoi_event_name ? (
                        <Row>
                          <Box
                            style={{
                              position: "relative",
                              left: "10%",
                              borderRadius: 30,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              style={{
                                marginTop: 10,
                                marginTop: 10,
                                height: 250,
                                width: 100,
                              }}
                              className="d-block w-100"
                              src={
                                AWS_URL + "/" + this.state.selectEvent.eoi_cover
                              }
                            ></img>

                            <Col lg={12}>
                              <Typography style={{ paddingTop: 20 }}>
                                EOI Name:
                                {this.state.selectEvent.eoi_event_name}
                              </Typography>

                              {/* <Typography style={{ paddingTop: 20 }}>
                                <a
                                  href={this.state.selectEvent.event_terms_and_conditions}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Terms and Conditions
                                </a>

                              </Typography> */}
                              <Typography style={{ paddingTop: 20 }}>
                                Event Location:{" "}
                                {this.state.selectEvent.event_organiser_address}
                              </Typography>
                              <Typography style={{ paddingTop: 20 }}>
                                Event Start Date/Time:
                                {" " +
                                  moment(
                                    this.state.selectEvent.event_start_date_time
                                  ).format("MMMM Do YYYY, h:mm a")}
                              </Typography>
                              <Typography style={{ paddingTop: 20 }}>
                                Event End Date/Time :
                                {" " +
                                  moment(
                                    this.state.selectEvent.event_end_date_time
                                  ).format("MMMM Do YYYY, h:mm a")}
                              </Typography>
                              <Typography style={{ paddingTop: 20 }}>
                                Bump in Date/Time:
                                {" " +
                                  moment(
                                    this.state.selectEvent.bump_in_date_time
                                  ).format("MMMM Do YYYY, h:mm a")}
                              </Typography>
                              <Typography style={{ paddingTop: 20 }}>
                                Bump out Date/Time:
                                {" " +
                                  moment(
                                    this.state.selectEvent.bump_out_date_time
                                  ).format("MMMM Do YYYY, h:mm a")}
                              </Typography>
                              <Typography style={{ paddingTop: 20 }}>
                                Please select the vendors below to generate
                                contract
                              </Typography>
                            </Col>
                          </Box>
                          <Col lg={12}>
                            {this.state.confirmVendors?.length != 0 ? (
                              <>
                                <Container style={{ display: "flex" }}>
                                  {this.state.confirmVendors.length != 0 &&
                                    this.state.confirmVendors.map((item) => (
                                      <>
                                        <Card style={{ width: 200 }}>
                                          <CardActionArea>
                                            <CardHeader>
                                              <Typography>
                                                {item.title}
                                              </Typography>
                                            </CardHeader>
                                            <CardMedia
                                              style={{
                                                width: 100,
                                                height: 100,
                                              }}
                                              image={item.logo}
                                              title="vendor_logo"
                                            />
                                          </CardActionArea>
                                          <Button
                                            style={{ marginTop: 10 }}
                                            color="secondary"
                                            onClick={() =>
                                              this.removeVendor(item)
                                            }
                                          >
                                            Cancel
                                          </Button>
                                        </Card>
                                      </>
                                    ))}
                                </Container>

                                <Button
                                  color="primary"
                                  style={{ margin: 10 }}
                                  onClick={() => {
                                    // this.props.dispatch(
                                    //   getComfirmedVendors(
                                    //     this.state.confirmVendors
                                    //   )
                                    // );
                                    saveToLocalStorage(
                                      "comfirmedVendors",
                                      JSON.stringify(this.state.confirmVendors)
                                    );
                                    // this.props.dispatch(
                                    //   getSelectedEoi(this.state.selectEvent)
                                    // );
                                    saveToLocalStorage(
                                      "selectedEOI",
                                      JSON.stringify(this.state.selectEvent)
                                    );
                                    this.setState({ contractNoticeOpen: true });
                                  }}
                                >
                                  View Contract
                                </Button>
                                <this.ContractNoticeComponent
                                  openContractNotice={
                                    this.state.contractNoticeOpen
                                  }
                                  selectEvent={this.state.selectEvent}
                                />
                                <Button
                                  onClick={() => {
                                    const url =
                                      config.devUrl + apiPaths.adminData;
                                    let myHeaders = new Headers();
                                    myHeaders.append(
                                      "Content-Type",
                                      "application/json"
                                    );
                                    let vendorId = [];

                                    this.state.confirmVendors.map((item) => {
                                      vendorId.push(item.id);
                                    });

                                    const raw = JSON.stringify({
                                      payload: {
                                        body: {
                                          query_type: "accepted_vendor_outlets",
                                          event_id: this.state.selectEvent.id,
                                          accepted_vendor_ids: vendorId,
                                        },
                                      },
                                    });

                                    let requestOptions = {
                                      method: "POST",
                                      headers: myHeaders,
                                      body: raw,
                                      redirect: "follow",
                                    };
                                    try {
                                      fetch(url, requestOptions)
                                        .then((response) => response.json())
                                        .then((response) => {
                                          if (response.success == true) {
                                            alert("Apply successed!");
                                            this.setState({
                                              confirmVendors: [],
                                            });
                                            this.getTargetedEvents(
                                              this.state.selectEvent.id
                                            );
                                            this.getTargetedAcceptedEvents(
                                              this.state.selectEvent.id
                                            );
                                          } else {
                                            alert(
                                              "The application is failed. Please try later"
                                            );
                                            this.setState({
                                              confirmVendors: [],
                                            });
                                            this.getTargetedEvents(
                                              this.state.selectEvent.id
                                            );
                                          }
                                        });
                                    } catch (error) {
                                      Sentry.captureException(error);
                                      console.error(error);
                                      alert(
                                        "The application is failed. Please try later"
                                      );
                                      this.setState({ confirmVendors: [] });
                                    }
                                    vendorId.length = 0;
                                  }}
                                >
                                  Accept
                                </Button>
                              </>
                            ) : null}
                          </Col>

                          <Col lg={12} style={{ marginTop: 20 }}>
                            <>
                              <div className="container-accordion">
                                <Typography>
                                  {acceptedVendorData.length != 0 ? (
                                    <List
                                      style={{
                                        width: "100%",
                                        overflow: "auto",
                                        position: "relative",
                                      }}
                                    >
                                      {acceptedVendorData.map((value) => {
                                        const labelId = `checkbox-list-label-${value.id}`;

                                        return (
                                          <ListItem
                                            key={value.id}
                                            role={undefined}
                                            button
                                            style={{
                                              flex: 1,
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-around",
                                            }}
                                            onClick={() =>
                                              this.setState({
                                                isOpen: true,
                                                selectedVendor: value,
                                              })
                                            }
                                          >
                                            <ListItemIcon
                                              style={{ marginRight: 40 }}
                                            >
                                              <img
                                                src={value.logo}
                                                alt="vendor_logo"
                                                style={{
                                                  height: 64,
                                                  width: 64,
                                                }}
                                              />
                                            </ListItemIcon>
                                            <ListItemText id={labelId}>
                                              {value.title}
                                            </ListItemText>
                                          </ListItem>
                                        );
                                      })}
                                      <div className="center-button-report">
                                        {!isLoading ? (
                                          <CsvDownloadButton
                                            data={csvAcceptedDataDownload}
                                            onClick={() =>
                                              jsonToXLSX(
                                                csvAcceptedDataDownload,
                                                "accepted_vendors"
                                              )
                                            }
                                            filename="applied_vendors.csv"
                                            className="button-report"
                                            style={{
                                              background: "#ef3e6d",
                                              backgroundColor: "#ef3e6d",
                                              borderRadius: "6px",
                                              border: "1px solid #ef3e6d",
                                              display: "inline-block",
                                              cursor: "pointer",
                                              color: "#ffffff",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              padding: "6px 24px",
                                              textDecoration: "none",
                                            }}
                                          >
                                            Download Accepted Vendors
                                          </CsvDownloadButton>
                                        ) : (
                                          <button
                                            disabled
                                            style={{
                                              background: "#ccc", // Use a suitable color for disabled state
                                              borderRadius: "6px",
                                              border: "1px solid #ccc",
                                              display: "inline-block",
                                              cursor: "not-allowed",
                                              color: "#ffffff",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              padding: "6px 24px",
                                              textDecoration: "none",
                                            }}
                                          >
                                            Download Accepted Vendors
                                          </button>
                                        )}
                                      </div>
                                    </List>
                                  ) : (
                                    <Col
                                      lg={12}
                                      style={{
                                        display: "-ms-inline-flexbox",
                                      }}
                                    >
                                      There are no vendors applied yet
                                    </Col>
                                  )}
                                </Typography>
                              </div>
                            </>
                          </Col>
                        </Row>
                      ) : null}
                    </Paper>
                  </Grid>
                </Grid>
              </Col>
            </Paper>
          </Container>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDetail: state.organiser.newEventDetail,
    EOI: state.organiser,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OrganiserEventContract, {
    fallback: <ErrorFallbackComponent />,
  })
);
