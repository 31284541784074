import React, { Component, useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Button from "react-bootstrap/Button";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import { Modal } from 'antd'; // Import Modal from antd for the popup modal

import { getFromLocalStorage } from "../../store";
import { Alert, InputNumber, Select, Switch, Table } from "antd";

// import CalendarView from "./CalendarView";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import ErrorFallbackComponent from "../ErrorFallBackScreen";
import Column from "antd/es/table/Column";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SalesPredictionPdf from "./components/SalesPredictionPdf";
import AdminNavBar from "../../components/UI/AdminNavBar";
import { Input } from "antd"; // Importing the Input component for the search bar

const AdminSalesPrediction = () => {
  const [events, setEvents] = useState([]);
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");

  const [vendors, setVendors] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [targetAmount, setTargetAmount] = useState("");
  const [predictionsLoading, setPredictionsLoading] = useState(false);
  const [itemSalesDist, setItemSalesDist] = useState([]);
  const [stockReqs, setStockReqs] = useState([]);
  const [isStockOrderModalVisible, setIsStockOrderModalVisible] = useState(false); // State to control modal visibility
  const [stockOrder, setStockOrder] = useState([]);
  const [isBufferAdded, setIsBufferAdded] = useState(true);
  const [isSuperAdminLoggedIn, setIsSuperAdminLoggedIn] = useState(
    getFromLocalStorage("isSuperAdminLoggedIn") === "true"
  );
  const [isAccountantLoggedIn, setIsAccountantLoggedIn] = useState(
    getFromLocalStorage("isAccountantLoggedIn") === "true"
  );
  const [isStockAdminLoggedIn, setIsStockAdminLoggedIn] = useState(
    getFromLocalStorage("isStockAdminLoggedIn") === "true"
  );
  const [isEventCoordinatorLoggedIn, setIsEventCoordinatorLoggedIn] = useState(
    getFromLocalStorage("isEventCoordinatorLoggedIn") === "true"
  );


  // Function to handle quantity change in the editable input field
  const handleQtyChange = (val, key) => {
    const updatedStockOrder = stockOrder.map(item => {
      if (item.key === key) {
        return {
          ...item,
          quantity: val,
        };
      }
      return item;
    });
    setStockOrder(updatedStockOrder);
  };

  const handleConvertToStockOrder = () => {
    // Set the converted stockReqs as the stock order data, applying the same logic for quantity
    const convertedStockOrder = stockReqs.map((item) => {
      let convertedQty = item.quantity;
  
      // Apply the buffer if needed and convert to the proper unit (only numeric values)
      if (item.measurement_type === "Kilograms") {
        convertedQty = isBufferAdded
          ? (item.quantity / 1000)?.toFixed(2)
          : ((item.quantity * 0.9) / 1000)?.toFixed(2);
      } else if (item.measurement_type === "kilo") {
        convertedQty = isBufferAdded
          ? item.quantity?.toFixed(2)
          : (item.quantity * 0.9)?.toFixed(2);
      } else {
        convertedQty = isBufferAdded
          ? item.quantity
          : (item.quantity * 0.9)?.toFixed(2); // For items with no unit conversion
      }
  
      return {
        ...item,
        converted_quantity: parseFloat(convertedQty), // Store numeric value only
      };
    });
  
    setStockOrder(convertedStockOrder); // Set the numeric values as stock order
    setIsStockOrderModalVisible(true); // Show modal
  };
  
  

  const handleSubmitStockOrder = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const stockOrderPayload = stockOrder.map(item => ({
      item_name: item.item_name,
      // Send the converted quantity to the API
      quantity: item.converted_quantity, // Use the converted quantity instead of the raw quantity
      item_id: item.id,
    }));
  
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "submit_stock_order",
          event_id: selectedEventId, // Include event_id in the payload
          stock_order: stockOrderPayload, // Use the updated stock order with converted quantities
        },
      },
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Stock order submitted successfully!");
          setIsStockOrderModalVisible(false); // Close modal on success
        } else {
          alert("Failed to submit stock order.");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred while submitting the stock order.");
      });
  };
  

  const filteredVendors = useMemo(() => {
    return vendors.filter((vendor) =>
      vendor?.title.toLowerCase().includes(vendorSearchTerm.toLowerCase())
    );
  }, [vendors, vendorSearchTerm]);

  const selectedEvent = useMemo(
    () => events.find((event) => event.id == selectedEventId),
    [events, selectedEventId]
  );

  const fetchVendorIds = () => {
    


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
          
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setVendors(result.vendors ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    const superAdminStatus = getFromLocalStorage("isSuperAdminLoggedIn") === "true";
    setIsSuperAdminLoggedIn(superAdminStatus);
    fetchVendorIds();
    
    
  }, []);

  const getAllEvents = (selectedVendorId) => {
    setIsLoading(true);
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_upcoming_events_for_operators",
          vendor_id: Number(selectedVendorId),
        },
      },
    }); 

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          setEvents(dataResponse?.data ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const handleGeneratePredictions = () => {
    if (isNaN(targetAmount) || targetAmount <= 0) {
      alert("Please enter valid Target Amount.");
      return;
    }
    setPredictionsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_sales_predictions",
          event_id: selectedEventId,
          vendor_id: selectedVendorId,
          target_amount: targetAmount,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPredictionsLoading(false);

        if (result && result.event_id && result.vendor_id) {
          console.log(result);
          if (result && result.item_sales_distribution) {
            const res = result.item_sales_distribution;
            setItemSalesDist(() =>
              Object.keys(res).map((key) => {
                return {
                  item_name: key,
                  key: key,
                  ...res[key],
                };
              })
            );
          }
          if (result && result.stock_requirements) {
            const res = result.stock_requirements;
            setStockReqs(() =>
              Object.keys(res).map((key) => {
                return {
                  item_name: key,
                  key: key,
                  ...res[key],
                };
              })
            );
          }
        } else {
          alert(
            "Something went wrong while generating sales prediction.\nPlease try again later."
          );
        }
      })
      .catch((error) => {
        setPredictionsLoading(false);
        console.error(error);
        alert(
          "Something went wrong while generating sales prediction.\nPlease try again later."
        );
        Sentry.captureException(error);
      });
  };

  return (
    <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
         
        ></AdminNavBar>
      <Container
        //     maxWidth={!isCalendarView ? 1110 : 2000}
        style={{
          borderWidth: 1,
          borderColor: "grey",
          borderStyle: "solid",
          borderRadius: 5,
          padding: 15,
        }}
      >
        <h1 style={{ fontSize: "30px", textAlign: "left", fontWeight: 600 }}>
          Event Sales Prediction{" "}
        </h1>

        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../assets/img/loading.gif")}></img>
            <p>Loading...</p>
          </div>
        ) : predictionsLoading ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../assets/img/loading.gif")}></img>
            <p>Generating Sales Predictions...</p>
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
  <label>Search Vendor:</label>
  <Input
    placeholder="Search Vendors"
    value={vendorSearchTerm}
    onChange={(e) => setVendorSearchTerm(e.target.value)}
    style={{ marginBottom: "10px" }} // Optional styling for spacing
  />

  <label>Select Vendor:</label>
  <Select
    value={selectedVendorId}
    size="large"
    defaultValue={""}
    onChange={(id) => {
      getAllEvents(id);
      setSelectedVendorId(id);
    }}
  >
    <Select.Option value="">Select Vendor</Select.Option>
    {filteredVendors.map((vendor, index) => (
      <Select.Option key={index} value={vendor?.user_ptr_id}>
        {vendor?.title}
      </Select.Option>
    ))}
  </Select>
</div>

              {selectedVendorId && (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <label>Select Event:</label>
                  <Select
                    value={selectedEventId}
                    size="large"
                    defaultValue={""}
                    onChange={(id) => {
                      setSelectedEventId(id);
                    }}
                  >
                    <Select.Option value="">Select Event</Select.Option>
                    {events.map((event, index) => (
                      <Select.Option key={index} value={event?.id}>
                        {event?.name || ""}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {selectedVendorId && selectedEventId ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      width: "100%",
                    }}
                  >
                    <label>Enter Target Amount:</label>
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      value={targetAmount}
                      onChange={(amt) => {
                        setTargetAmount(amt);
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 24,
              }}
            >
              {selectedVendorId && selectedEventId ? (
                <Button
                  style={{ backgroundColor: "rgb(40, 89, 165)" }}
                  variant="primary"
                  onClick={handleGeneratePredictions}
                >
                  Get Sales Predictions
                </Button>
              ) : null}

              {events &&
              vendors &&
              itemSalesDist &&
              itemSalesDist.length &&
              stockReqs &&
              stockReqs.length ? (
                <Button
                  style={{ backgroundColor: "rgb(40, 89, 165)" }}
                  variant="primary"
                >
                  <PDFDownloadLink
                    style={{ color: "white", textDecoration: "none" }}
                    document={
                      <SalesPredictionPdf
                        event={selectedEvent}
                        vendor={vendors.find(
                          (vendor) => vendor.user_ptr_id == selectedVendorId
                        )}
                        targetAmount={targetAmount}
                        itemSalesDist={itemSalesDist}
                        isBufferAdded={isBufferAdded}
                        stockReqs={stockReqs}
                      />
                    }
                    fileName={
                      "event-sales-prediction" +
                      (selectedEvent ? "-for-" + selectedEvent?.name : "") +
                      ".pdf"
                    }
                  >
                    {({ blob, url, loading, error }) =>
                      loading
                        ? "Generating document..."
                        : "Download Sales Prediction PDF"
                    }
                  </PDFDownloadLink>
                </Button>
              ) : null}
            </div>
            {itemSalesDist && itemSalesDist.length ? (
              <div style={{ marginTop: 40 }}>
                <h3
                  style={{
                    fontSize: "20px",
                    marginBottom: 32,
                    textAlign: "left",
                    fontWeight: 600,
                  }}
                >
                  Projected Sales To Reach Forcasted Revenue{" "}
                </h3>
                <Table dataSource={itemSalesDist} bordered>
                  <Column
                    align="left"
                    title="Item Name"
                    dataIndex="item_name"
                    key="item_name"
                  />
                  <Column
                    align="left"
                    title="Item Price"
                    dataIndex="item_price"
                    key="item_price"
                    sorter={(a, b) => a.item_price - b.item_price}
                  />
                  <Column
                    align="left"
                    title="Percentage of Total (%)"
                    sorter={(a, b) =>
                      a.percentage_of_total - b.percentage_of_total
                    }
                    dataIndex="percentage_of_total"
                    key="percentage_of_total"
                  />
                  <Column
                    align="left"
                    title="Required Qty"
                    dataIndex="required_quantity"
                    key="required_quantity"
                    sorter={(a, b) => a.required_quantity - b.required_quantity}
                  />
                  <Column
                    align="left"
                    title="Avg Qty. per Event"
                    dataIndex="average_quantity_per_event"
                    key="average_quantity_per_event"
                    sorter={(a, b) =>
                      a.average_quantity_per_event -
                      b.average_quantity_per_event
                    }
                  />
                  <Column
                    align="left"
                    title="Revene Contribution"
                    dataIndex="revenue_contribution"
                    key="revenue_contribution"
                    sorter={(a, b) =>
                      a.revenue_contribution - b.revenue_contribution
                    }
                    render={(rev) => rev.toFixed(2)}
                  />
                </Table>
              </div>
            ) : null}
            {stockReqs && stockReqs.length ? (
              <div style={{ marginTop: 40 }}>
                <div
                  style={{
                    marginBottom: 12,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 12,
                  }}
                >
                  <h3
                    style={{
                      fontSize: "20px",
                      marginBottom: 8,
                      textAlign: "left",
                      fontWeight: 600,
                    }}
                  >
                    Stock Required To Reach Forcasted Revenue{""}
                  </h3>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <label>Add 10% Stock Buffer</label>
                    <Switch
                      defaultChecked
                      checked={isBufferAdded}
                      onChange={(checked) => {
                        setIsBufferAdded(checked);
                      }}
                    />
                  </div>
                </div>
                {isBufferAdded ? (
                  <Alert
                    showIcon
                    message="NOTE: Please bare in mind there is a 10% buffer added to the stock"
                    type="info"
                  />
                ) : null}


                <br />
                <Table dataSource={stockReqs} bordered>
                  <Column
                    align="left"
                    title="Item Name"
                    dataIndex="item_name"
                    key="item_name"
                  />
                 <Column
  align="left"
  title="Qty."
  sorter={(a, b) => a.quantity - b.quantity}
  dataIndex="quantity"
  key="quantity"
  render={(val, record) => {
    if (record.measurement_type === "Kilograms") {
      return isBufferAdded
        ? (val / 1000)?.toFixed(2) + " kg"
        : ((val * 0.9) / 1000)?.toFixed(2) + " kg";
    } else if (record.measurement_type === "kilo") {
      return isBufferAdded
        ? val?.toFixed(2) + " kg"
        : (val * 0.9)?.toFixed(2) + " kg";
    }
    return val; // If no conversion is needed
  }}
/>
                  <Column
                    align="left"
                    title="Percentage of Total (%)"
                    sorter={(a, b) =>
                      a.percentage_of_total - b.percentage_of_total
                    }
                    dataIndex="percentage_of_total"
                    key="percentage_of_total"
                  />
                  <Column
                    align="left"
                    title="Unit"
                    dataIndex="unit"
                    key="unit"
                  />
                  <Column
                    align="left"
                    title="Measurement Type"
                    dataIndex="measurement_type"
                    key="measurement_type"
                  />
                </Table>
              </div>
            ) : null}
          </>
        )}

        
      </Container>

      <Button
            style={{ backgroundColor: "rgb(40, 89, 165)", marginTop: '20px' }}
            variant="primary"
            onClick={handleConvertToStockOrder}
          >
            Convert to Stock Order
          </Button>

          {/* Modal to display stock order details */}
          <Modal
  title="Stock Order Details"
  visible={isStockOrderModalVisible}
  onCancel={() => setIsStockOrderModalVisible(false)} // Close modal on cancel
  onOk={handleSubmitStockOrder} // Submit stock order on OK button press
>
  <Table dataSource={stockOrder} bordered>
    <Column
      align="left"
      title="Item Name"
      dataIndex="item_name"
      key="item_name"
    />
    <Column
      align="left"
      title="Quantity"
      dataIndex="converted_quantity"
      key="converted_quantity"
      render={(val, record) => (
        <InputNumber
          min={0}
          value={val}
          onChange={(value) => handleQtyChange(value, record.key)}
        />
      )}
    />
    <Column
      align="left"
      title="Measurement Type"
      dataIndex="measurement_type"
      key="measurement_type"
    />
    <Column
      align="left"
      title="Unit"
      dataIndex="unit"
      key="unit"
    />
  </Table>
</Modal>
        
    </div>
    
  );
};

export default Sentry.withErrorBoundary(AdminSalesPrediction, {
  fallback: <ErrorFallbackComponent />,
});
