import axios from "axios";
import config from "./apiConfig";

export const vendorService = {
  getDiet,
  getKitchens,
  vendorLogin,
};
const baseURL = config.BASE_URI;

function getDiet() {
  let url = baseURL + "/api/v1/diet/";

  return axios.get(url);
}

function getKitchens() {
  let url = baseURL + "/api/v1/kitchens/";

  return axios.get(url);
}

function vendorLogin(email, password) {
  let url = baseURL + "/api/v1/admin-data/";
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify({
    payload: {
      body: {
        query_type: "admin_login",
        email: email,
        password: password,
      },
    },
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return axios.post(url, requestOptions);
}
