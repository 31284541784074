import React from "react";
import FileUploader from "../FileUploader";
import { Row, Col, Accordion, Card, Form } from "react-bootstrap";
import MenuTable from "../MenuTable";
import CheckButton from "../CheckButton";
import "../../style.css";
import Textfield from "../Textfield";

const MenuItems = (props) => {
  const { formik, viewOnly } = props;
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        style={{
          color: "black",
        }}
      >
        6. PRODUCTS/MENU ITEMS
        {(formik.errors.noMenu ||
          formik.errors.foodReg ||
          formik.errors.menu) &&
        !viewOnly ? (
          <div style={{ color: "red", fontWeight: "bold" }}>
            {"Information Missing"}
          </div>
        ) : null}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          {!viewOnly ? (
            <div className="title-heading">
              <h6>Enter your menu:</h6>
              {formik.errors.noMenu ? (
                <div style={{ color: "red" }}>{formik.errors.noMenu}</div>
              ) : null}
              <MenuTable formik={formik} />
              {formik.errors.menu ? (
                <div style={{ color: "red" }}>{"Please finish your menu"}</div>
              ) : null}
            </div>
          ) : (
            <div className="title-heading">
              <h6>Your menu:</h6>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th
                      scope="col"
                      rowSpan="2"
                      style={{ verticalAlign: "top" }}
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      rowSpan="2"
                      style={{ verticalAlign: "top" }}
                    >
                      Type of Cuisine
                    </th>
                    <th
                      scope="col"
                      rowSpan="2"
                      style={{ verticalAlign: "top" }}
                    >
                      Product Name
                    </th>
                    <th
                      scope="col"
                      rowSpan="2"
                      style={{ verticalAlign: "top" }}
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.menu.map((item) => (
                    <tr key={item.idx}>
                      <td>{item.idx}</td>
                      <td>{item.cuisineType}</td>
                      <td>{item.productName}</td>
                      <td>{item.prices}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <br></br>
          {!viewOnly ? (
            <div className="title-heading">
              <h6>Upload your Food Registration Certificate* </h6>
              {formik.touched.foodReg && formik.errors.foodReg ? (
                <div style={{ color: "red" }}>{formik.errors.foodReg}</div>
              ) : null}
              <FileUploader
                allowMultiple={false}
                formStateHandler={formik.setFieldValue}
                formTouchedHandler={formik.setFieldTouched}
                fieldName={"foodReg"}
                allowFileTypeValidation={false}
                defaultValue={formik.values.foodReg}
              />
            </div>
          ) : (
            <div className="title-heading">
              <h6>Food Registration Certificate*: </h6>
              {formik.values.foodReg.map(({ item }) => (
                <img
                  className="d-block w-100"
                  src={
                    item && item != null && item != ""
                      ? item
                      : require("../../../../assets/img/default_cover.jpg")
                  }
                  alt="Event Photos"
                  height="200px"
                  width="200px"
                />
              ))}
            </div>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Food truck minimun Spend*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Textfield
                    label={"Stall Name"}
                    defaultValue={formik.values.minimumSpend}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"minimumSpend"}
                  />
                </Col>
              </Form.Group>
            </div>
          ) : (
            <div className="title-heading">
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  <h6>Food truck minimun Spend*: </h6>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    label={"Stall Name"}
                    defaultValue={formik.values.minimumSpend}
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"minimumSpend"}
                    readOnly={true}
                    style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          {!viewOnly ? (
            <>
              <div>
                <Row>
                  <Col sm={1}>
                    <CheckButton
                      isDelivery={formik.values.delivery}
                      formStateHandler={formik.setFieldValue}
                      formTouchedHandler={formik.setFieldTouched}
                      fieldName={"delivery"}
                      defaultChecked={formik.values.delivery}
                    />
                  </Col>
                  <Col sm={11}>
                    <h6>Does the vendor provide delivery service?</h6>
                  </Col>
                </Row>
              </div>
              {formik.values.delivery && (
                <div className="title-heading">
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                      <h6>Delivey Fee*: </h6>
                    </Form.Label>
                    <Col sm={10}>
                      <Textfield
                        label={"Stall Name"}
                        defaultValue={formik.values.delivery_fee}
                        formStateHandler={formik.setFieldValue}
                        formTouchedHandler={formik.setFieldTouched}
                        fieldName={"delivery_fee"}
                      />
                    </Col>
                  </Form.Group>
                </div>
              )}
            </>
          ) : (
            <>
              <div key="inline-radio" className="mb-3 title-heading">
                <h6>Does the vendor provide delivery service?</h6>
                <Form.Control
                  defaultValue={formik.values.delivery}
                  readOnly={true}
                  style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                />
              </div>
              {formik.values.delivery && (
                <div className="title-heading">
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                      <h6>Delivey Fee*: </h6>
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        label={"Stall Name"}
                        defaultValue={formik.values.delivery_fee}
                        formStateHandler={formik.setFieldValue}
                        formTouchedHandler={formik.setFieldTouched}
                        fieldName={"delivery_fee"}
                        readOnly={true}
                        style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                      />
                    </Col>
                  </Form.Group>
                </div>
              )}
            </>
          )}
          {!viewOnly ? (
            <div className="title-heading">
              <h5>Where The Truck™ reserves the right to set retail prices</h5>
              <Row>
                <Col sm={1}>
                  <CheckButton
                    formStateHandler={formik.setFieldValue}
                    formTouchedHandler={formik.setFieldTouched}
                    fieldName={"isPricesAlteredAllowed"}
                    defaultChecked={formik.values.isPricesAlteredAllowed}
                  />
                </Col>
                <Col sm={11}>
                  <h6>I am willing to have my prices altered.</h6>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="title-heading">
              <h5>Where The Truck™ reserves the right to set retail prices</h5>
              <div key="inline-radio" className="mb-3 title-heading">
                <h6>I am willing to have my prices altered.</h6>
                <Form.Control
                  defaultValue={formik.values.isPricesAlteredAllowed}
                  readOnly={true}
                  style={{ color: "gray", backgroundColor: "#f5f5f5" }}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default MenuItems;
