import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap"; // Import necessary Bootstrap components
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

function VendorDetails() {
  const { id } = useParams();
  const [vendor, setVendor] = useState({});

  useEffect(() => {
    const url = `${config.BASE_URI}${apiPaths.explore.trucksList}${id}/`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setVendor(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error fetching vendor data:", error);
      });
  }, [id]);

  return (
    <Container className="vendor-details">
      <h1>Stall Details</h1>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Text>Title: {vendor.title}</Card.Text>
              <Card.Text>Hometown: {vendor.hometown}</Card.Text>
              <Card.Text>Status: {vendor.status}</Card.Text>
            </Card.Body>
          </Card>
          {/* Display other vendor properties as needed */}
        </Col>
        <Col md={6}>
          <img
            src={vendor.logo}
            alt="Vendor Logo"
            className="vendor-image"
            style={{ maxWidth: "100%", height: "200px" }} // Add this style
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="vendor-description">
            <h2>About the Vendor</h2>
            <p>{vendor.bio}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="vendor-contact">
            <h2>Contact Information</h2>
            <p>
              <strong>Email:</strong> {vendor.email}
            </p>
            <p>
              <strong>Website:</strong> {vendor.website || "Not provided"}
            </p>
            <p>
              <strong>Phone:</strong> {vendor.phone || "Not provided"}
            </p>
            <p>
              <strong>Instagram:</strong> {vendor.instagram || "Not provided"}
            </p>
            <p>
              <strong>Facebook:</strong> {vendor.facebook || "Not provided"}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="vendor-meals">
            <h2>Meals</h2>
            <ul>
              {vendor.meals && vendor.meals.length > 0 ? (
                vendor.meals.map((meal) => (
                  <li key={meal.id}>
                    <strong>{meal.name}</strong>
                    <p>Price: ${meal.price}</p>
                    <p>{meal.description || "No description available"}</p>
                    <img src={meal.image} alt={meal.name} />
                  </li>
                ))
              ) : (
                <p>No meals available</p>
              )}
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="vendor-documents">
            <h2>Documents</h2>
            <p>
              <strong>Liability Certificate:</strong>{" "}
              <a
                href={vendor.liablity_certificate}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Certificate
              </a>
            </p>
            <p>
              <strong>Other Uploads:</strong>{" "}
              <a
                href={vendor.other_uploads}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Uploads
              </a>
            </p>
            <p>
              <strong>Food Registration Docs:</strong>{" "}
              <a
                href={vendor.food_refistration_docs}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Docs
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Sentry.withErrorBoundary(VendorDetails, {
  fallback: <ErrorFallbackComponent />,
});
