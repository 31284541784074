import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";

function VendorCostPerUserAccordion({ shiftData, sales_by_vendor_obj }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderChart = (averageCostPerUser, totalCost, title) => {
    const chartColors = [
      "rgba(255, 99, 132, 0.8)", // red
      "rgba(54, 162, 235, 0.8)", // blue
      "rgba(255, 206, 86, 0.8)", // yellow
      "rgba(75, 192, 192, 0.8)", // green
      "rgba(153, 102, 255, 0.8)", // purple
      "rgba(255, 159, 64, 0.8)", // orange
    ];

    const data = {
      labels: Object.keys(averageCostPerUser),
      datasets: [
        {
          data: Object.values(averageCostPerUser),
          backgroundColor: chartColors.slice(
            0,
            Object.keys(averageCostPerUser).length
          ),
          hoverOffset: 4,
        },
      ],
    };
    const sales = sales_by_vendor_obj[title];
    return (
      <>
        {typeof sales === "number" ? (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Total Sales: ${sales.toLocaleString()}
          </Typography>
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Sales data unavailable
          </Typography>
        )}
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Total Wage Cost: ${totalCost ? totalCost.toFixed(2) : "0.00"}
          {sales > 0
            ? ` (${((totalCost / sales) * 100).toFixed(2)}% of total sales)`
            : ""}
        </Typography>
        <Pie data={data} />
      </>
    );
  };

  return (
    <div>
      {Array.isArray(shiftData) && shiftData.length > 0 ? (
        shiftData.map((vendor, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {vendor.vendor_title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {vendor.shift_data && vendor.shift_data.average_cost_per_user ? (
                renderChart(
                  vendor.shift_data.average_cost_per_user,
                  vendor.shift_data.total_cost,
                  vendor.vendor_title
                )
              ) : (
                <Typography>No data available.</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography style={{ textAlign: "center" }}>
          No shift data available
        </Typography>
      )}
    </div>
  );
}

export default VendorCostPerUserAccordion;
