import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import moment from "moment";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import "react-phone-input-2/lib/style.css";
import { store, getFromLocalStorage } from "../../store";
import {
  AWS_URL,
  SQUARE_SCRIPT_JS,
  LOCATION_ID,
  SQUARE_APP_ID,
} from "../../assets/Constants";
import PaymentForms from "./components/PaymentForm.js";
import LiveChat from "react-livechat";
import setRandomInterval from "set-random-interval";
import Toast from "react-bootstrap/Toast";
import { Facebook } from "react-spinners-css";
import ResponsiveVendorNavBar from "../../components/UI/ResponsiveVendorNavBar";

var interval = "";

class PaymentScreen extends Component {
  state = {
    total: 0,
    userId: -1,
    packId: -1,
    selectedCuisine: "",
    isPaymentModalOpen: false,
  };

  componentWillMount() {
    console.log(this);
    const that = this;
    let sqPaymentScript = document.createElement("script");
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = SQUARE_SCRIPT_JS;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true,
      });
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }
  componentDidMount() {
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
  }

  getPaymentDetails = () => {};

  render() {
    const { total, userId, packId, selectedCuisine } = this.state;
    const { dispatch } = this.props;

    return (
      <div>
        <ResponsiveVendorNavBar
          showBack={false}
          backUrl={""}
        ></ResponsiveVendorNavBar>
        {window.Square ? (
          <Container>
            <LiveChat license={15498117} />
            <PaymentForms
              paymentForm={window.Square.payments(SQUARE_APP_ID, LOCATION_ID)}
              user={userId}
              total={total}
              dispatch={dispatch}
              packId={packId}
              selectedCuisine={selectedCuisine}
            />
          </Container>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(PaymentScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
