import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import * as Sentry from "@sentry/react";
import { Redirect } from "react-router-dom";

import axios from "axios";

import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import AdminNavBar from "../../components/UI/AdminNavBar";

class AdminPOSKioskCodesScreen extends Component {
  state = {
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    selectedBookingNewStatus: "",
    selectedBooking: null,
    isOpenBookingStatusChangeModal: false,
    isOpenBookingStatusChangeModal: false,
    isLoadingChangeBookingStatus: false,
    isLoadingChangeBookingStatus: false,
    isLoadingSendingVendorRequest: false,
    vendorCateringPacks: [],
    selectedCateringPack: {},
    requestSent: [],
    isOpenCustomerEmailDetails: false,
    requestSentIds: [],
    isLoadingVendor: false,
    searchVendor: "",
    searchNonCuisineVendor: "",
    vendorListSearchDisplay: "none",
    nonCuisineVendorListSearchDisplay: "none",
    selectedCuisine: "",
    adminNotesChanged: [],
    isLoading: false,
    requestState: "all",
    searchQuery: "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn, isEventCoordinatorLoggedIn } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getAllVendors();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  getDashboardData = (vendor_ids) => {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_pos_kiosk_codes",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.codes });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeBookingStatus = (e, booking) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var newStatus = e.target.value;
    if (newStatus == "finalised") {
      if (booking.payment_status != "complete") {
        alert(
          "Error! Booking cannot be finalised until payment is completed by the customer."
        );
        return;
      }
    }
    this.setState(
      {
        selectedBooking: booking,
        selectedBookingNewStatus: e.target.value,
        isOpenBookingStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeBookingStatus: false });
      }
    );
  };

  getVendorCateringPacks = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendorCateringPacks: dataResponse.catering_packs });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  setAdminNotes = (item, notes, index) => {
    let start = new Date().valueOf();
    const { bookingRequests, adminNotesChanged } = this.state;
    let tempNotesChanged = adminNotesChanged;
    let tempBookingRequest = bookingRequests;
    tempBookingRequest[index].admin_notes = notes;
    tempNotesChanged.push(tempBookingRequest[index].id);

    this.setState(
      {
        bookingRequests: tempBookingRequest,
        adminNotesChanged: tempNotesChanged,
      },
      () => {
        let end = new Date().valueOf();
      }
    );
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  //   handleFileChange = (event, booking) => {
  //     const file = event.target.files[0];
  //     if (!file) {
  //       return;
  //     }

  //     // Call the uploadSot function with the selected file and booking information
  //     this.uploadSot(file, booking);
  //   };

  //   uploadSot = async (file, bookingId) => {
  //     console.log("uploading sot", file, bookingId.id);
  //     // Create a FormData object to send the file
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("bookingId", bookingId.id);

  //     // Make a POST request to your Django API endpoint
  //     try {
  //       const response = await axios.post(
  //         config.BASE_URI + apiPaths.UploadStatementOfTrades,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       if (response.data.success === true) {
  //         alert("Statement Of Trade Uploaded!");
  //         window.location.reload();
  //       } else {
  //         alert("Vendor Details could not be added. Please try again.");
  //         window.location.reload();
  //       }
  //     } catch (error) {
  //       alert("Vendor Details could not be added. Please try again.");
  //       window.location.reload();
  //     }
  //   };
  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({ vendor_ids: response.vendors });
          this.getDashboardData(response.vendors);
        } else {
          alert("error");
        }
      });
  };

  renderHome() {
    const {
      bookingRequests,
      isLoading,
      pageSelected,
      vendor_ids,
      selectedVendorId,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h2>WTT POS & Kiosk Codes List</h2>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Search by Stall Name or Event Name"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={this.state.searchQuery}
                      onChange={(e) =>
                        this.setState({ searchQuery: e.target.value })
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="table-header-row">
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Stall Name
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                 POS Name
                </Col>

                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Login Code
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Manager Code
                </Col>
              </Row>

              {bookingRequests
                .filter((item) => {
                  if (!this.state.searchQuery) return true; // return all items if searchQuery is empty
                  // Check if the searchQuery is included in the stall name or event name
                  return (
                    item.vendor__title
                      .toLowerCase()
                      .includes(this.state.searchQuery.toLowerCase()) ||
                    item.pos_name
                      .toLowerCase()
                      .includes(this.state.searchQuery.toLowerCase())
                  );
                })
                .map((item, requestIndex) => {
                  // Existing map function

                  let cuisine_array = [...new Set(item.cuisine_array)];
                  return (
                    <Accordion key={item.id}>
                      <>
                        <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                          <Row className="table-row">
                            <Col xs={12} md={2} lg={2} className="table-col">
                              <span>
                                <b className="mobile-only">Stall Name</b>
                                {item.vendor__title}
                              </span>
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-col">
                              <span>
                                <b className="mobile-only">Pos Name</b>
                                {item.pos_name}
                              </span>
                            </Col>

                            <Col xs={12} md={2} lg={2} className="table-col">
                              <span>
                                <b className="mobile-only">Code</b>
                                {item.code }
                              </span>
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-col">
                              {item.vendor__pos_code }
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                      </>
                    </Accordion>
                  );
                })}
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col xs={2} md={2} lg={2}>
                  <p>
                    <b>Showing Page:</b>
                  </p>
                </Col>
                <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                  <div style={{ width: "98%" }}>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected - 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                      <Pagination.Item active>{pageSelected}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected + 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

export default AdminPOSKioskCodesScreen;
