import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  ListGroup,
  Table,
  Modal,
} from "react-bootstrap";
import { Redirect } from "react-router";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { MdAccountCircle } from "react-icons/md";
import config from "../../services/apiConfig";
import { isEmpty } from "./utils.js";
import WTTImage from "../../assets/img/EVENT_REPORT.png";
import { Bar, Pie } from "react-chartjs-2";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip as MuiTooltip } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CssBaseline from "@mui/material/CssBaseline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { green } from "@mui/material/colors";
import InventoryIcon from "@mui/icons-material/Inventory";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import InsightsIcon from "@mui/icons-material/Insights";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import PaymentsIcon from "@mui/icons-material/Payments";
import FunctionsIcon from "@mui/icons-material/Functions";
import ContactlessIcon from "@mui/icons-material/Contactless";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FeedbackIcon from "@mui/icons-material/Feedback";
import InstagramIcon from "@mui/icons-material/Instagram";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Container from "@mui/material/Container";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import TableData from "../../components/UI/ItemsSoldTable";
import WeatherForecastModal from "./WeatherForcastModal";
import Chart from "chart.js/auto";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import VendorAccordion from "./VendorAccordion.js";
import VendorCostAccordion from "./VendorCostAccordion.js";
import VendorCostPerUserAccordion from "./VendorCostPerUserAccordion.js";
import MenuAccordion from "./MenuAccordion.js";
import SocialMediaModal from "./SocialMediaModal";
import MarketingConclusionModal from "./MarketingConclusionModal";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import AdminNavBar from "../../components/UI/AdminNavBar/index.js";
import { AWS_URL } from "../../assets/Constants.js";
import SelectionCriteriaModal from "./SelectionCriteriaModal.js";
import PRModal from "./PRModal.js";
import ServiceProvidersModal from "./ServiceProvidersModal.js";

// Register the components Chart.js needs
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// Create styles
const styles = StyleSheet.create({
  head: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `2px solid #3959a5`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  heading: {
    fontSize: 24,
    color: "#3959a5",
    fontWeight: "bold",
  },
  heading2: {
    paddingTop: 10,
    fontSize: 18,
    color: "#3959a5",
    fontWeight: "bold",
  },
  heading3: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    color: "#3959a5",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    margin: 10,
    padding: 30,
  },
  section: {
    // margin: 10,
    // padding: 30,
  },
  header: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold",
  },
  content: {
    fontSize: 12,
    marginBottom: 5,
  },
  contentQuestion: {
    fontSize: 13,
    marginBottom: 5,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  table: {
    marginTop: 10,
    flexDirection: "column",
    marginHorizontal: 20,
    marginBottom: 30,
    // borderColor: "black",
    // borderWidth: 1,
    // borderTopWidth: 0,
    // borderRightWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  post: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    flexDirection: "column",
    gap: 5,
  },
  postSummary: {
    fontStyle: "italic",
    fontSize: 10,
    paddingBottom: 5,
  },
  postInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  postUser: {
    fontWeight: "semibold",
    fontSize: 10,
    color: "#3959a5",
    flex: 1,
  },
  postDate: {
    fontSize: 10,
    color: "#333",
  },
  tableCell: {
    flex: 1,
    textAlign: "center",
    padding: 5,
    borderColor: "black",
    borderWidth: 1,
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    fontSize: 10,
  },
  headerCell: {
    fontWeight: "bold",
    fontSize: 12,
  },
});
const styles1 = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  detailsSection: {
    marginBottom: 30,
  },
  detail: {
    fontSize: 16,
    marginBottom: 5,
  },
  detailValue: {
    fontWeight: "bold",
  },
  tocSection: {
    marginTop: 20,
  },
  tocTitle: {
    fontSize: 20,
    marginBottom: 10,
  },
  tocItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  tocText: {
    fontSize: 16,
  },
  tocPage: {
    fontSize: 16,
  },
  contentSection: {
    alignItems: "center",
    padding: 20, // Add some padding around the content
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `2px solid #3959a5`,
    paddingBottom: 10,
  },
  salesReport: {
    fontSize: 24,
    color: "#3959a5",
    fontWeight: "bold",
  },
  contentSection: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
  iconAndText: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    display: "flex",
    justifyContent: "flex-start",
  },
  contentText: {
    fontSize: 14,
    marginLeft: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 24,
    bottom: 25,
    right: 50,
    color: "#3959a5",
  },
  footer: {
    borderTop: `2px solid #3959a5`,
    position: "absolute",
    bottom: 65,
    left: 40,
    right: 40,
  },
});

const EventReport = ({
  data,
  feedbackData,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
}) => {
  console.log(start_date, end_date);
  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text>No Ratings Available</Text>;
    }
    return ratings.map((rating, index) => (
      <View key={index} style={styles.section}>
        <Text style={styles.content}>
          {"Rating: " + (Number(rating.ratings_value) / 2).toFixed(1) + "/5"}
        </Text>
        <Text style={styles.content}>{"Comment: " + rating.comments}</Text>
      </View>
    ));
  };
  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text>No Feedback Data Available</Text>;
    }

    return feedbackData.map((feedback, index) => (
      <View key={index} style={styles.section}>
        <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
          Feedback from {feedback.time_stamp}
        </Text>
        {feedback.feedback_lists.map((item, idx) => (
          <View key={idx} style={styles.table}>
            <Text style={styles.contentQuestion}>{item.feedback_question}</Text>
            <Text style={styles.content}>{item.feedback_answer}</Text>
          </View>
        ))}
      </View>
    ));
  };
  const renderCostByDayTable = () => {
    if (!data.cost_by_day || Object.keys(data.cost_by_day).length === 0) {
      return <Text>No Shift Data Available</Text>;
    }

    return Object.entries(data.cost_by_day).map(([date, details]) => (
      <View key={date} wrap={false}>
        {/* Heading for each day */}
        <Text
          style={[
            styles.subHeader,
            { textAlign: "center", paddingTop: "10px" },
          ]}
        >
          {moment(date).format("DD-MM-YYYY")}
        </Text>

        <View
          style={[styles.table, { paddingTop: "10px", paddingBottom: "20px" }]}
        >
          {/* Table headers */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>User</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Total Cost
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Avg Cost/Hr
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Hours Worked
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Cost</Text>
          </View>

          {/* Table rows for each user */}
          {Object.keys(details.user_costs).map((user) => (
            <View key={user} style={styles.tableRow}>
              <Text style={styles.tableCell}>{user}</Text>
              <Text style={styles.tableCell}>
                {details.total_cost.toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.avg_cost_per_hour[user].toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.hours_worked[user].toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.user_costs[user].toFixed(2)}
              </Text>
            </View>
          ))}
        </View>
      </View>
    ));
  };

  const renderCostByHourTable = () => {
    if (
      !data.sorted_hourly_cost ||
      Object.keys(data.sorted_hourly_cost).length === 0
    ) {
      return <Text>No Hourly Cost Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Cost per Hour
          </Text>
        </View>

        {/* Table rows for each date */}
        {Object.entries(data.sorted_hourly_cost).map(([date, cost]) => (
          <View key={date} style={styles.tableRow}>
            <Text style={styles.tableCell}>{date}</Text>
            <Text style={styles.tableCell}>{cost.toFixed(2)}</Text>
          </View>
        ))}
      </View>
    );
  };
  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text>No Weather Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Max Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Min Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Condition</Text>
        </View>

        {/* Table rows for each day */}
        {forecastData.forecast.forecastday.map((day, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{day.date}</Text>
            <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
            <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
            <Text style={styles.tableCell}>
              {day.day.condition ? day.day.condition.text : "N/A"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderCostByUserTable = () => {
    if (
      !data.average_cost_per_user ||
      Object.keys(data.average_cost_per_user).length === 0
    ) {
      return <Text>No Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>User</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Average Cost
          </Text>
        </View>

        {/* Table rows for each date */}
        {Object.entries(data.average_cost_per_user).map(([date, cost]) => (
          <View key={date} style={styles.tableRow}>
            <Text style={styles.tableCell}>{date}</Text>
            <Text style={styles.tableCell}>{cost.toFixed(2)}</Text>
          </View>
        ))}
      </View>
    );
  };
  const InfoRow = ({ icon, text }) => (
    <View style={styles1.iconAndText}>
      {icon}
      <Text style={styles1.contentText}>{text}</Text>
    </View>
  );
  const renderPayoutDetails = () => {
    if (!payoutDetails || payoutDetails.length === 0) {
      return <Text>No Payout Details Available</Text>;
    }

    return payoutDetails.map((payout, index) => (
      <View key={index} style={styles.section}>
        <Text
          style={[
            styles.subHeader,
            { textAlign: "center", paddingBottom: "10px" },
          ]}
        >
          Payout Details for {payout.vendor_name}
        </Text>
        <Text style={styles.content}>Approved By: {payout.approved_by}</Text>
        <Text style={styles.content}>
          Total WTT Commission: {payout.total_wtt_commission}
        </Text>
        <Text style={styles.content}>
          Cash Sales: {payout.wtt_cash_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Card Sales: {payout.wtt_card_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Voucher Sales: {payout.wtt_voucher_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total GST in payout: {payout.total_gst.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total Payout: {payout.total_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Payout: {payout.total_wtt_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Card Commission:{" "}
          {payout.total_wtt_card_commission.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Cash Commission:{" "}
          {payout.total_wtt_cash_commission.toFixed(2)}
        </Text>
        <Text style={[styles.content, { paddingBottom: "10px" }]}>
          Total WTT Voucher Commission:{" "}
          {payout.total_wtt_voucher_commission.toFixed(2)}
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Charge Label
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Gross</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>GST</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Net</Text>
          </View>

          {[1, 2, 3, 4, 5].map((num) => {
            const label = payout[`label_misc_charges${num}`];
            const gross = payout[`gross_misc_charges${num}`];
            const gst = payout[`gst_misc_charges${num}`];
            const net = payout[`net_misc_charges${num}`];

            if (label && label.trim() !== "") {
              return (
                <View key={num} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{label}</Text>
                  <Text style={styles.tableCell}>{gross.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{gst.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{net.toFixed(2)}</Text>
                </View>
              );
            }
            return null;
          })}
        </View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles1.page}>
        <View style={styles1.header}>
          <Text style={styles1.salesReport}>EVENT SALES REPORT</Text>
        </View>
        <View style={styles1.section}>
          <View style={styles1.detailsSection}>
            <Text style={styles1.detail}>
              Event: <Text style={styles.detailValue}>{selectedEventName}</Text>
            </Text>
            <Text style={styles1.detail}>
              Dates:{" "}
              <Text style={styles.detailValue}>{`${moment(start_date).format(
                "DD-MM-YYYY"
              )} - ${moment(end_date).format("DD-MM-YYYY")}`}</Text>
            </Text>
          </View>
          <View style={styles1.tocSection}>
            <Text style={styles1.tocTitle}>Table of Contents</Text>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Sales Insights</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Labour Cost</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Feedback</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Ratings</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Payout Details</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Weather Report</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
          </View>
        </View>
        <View style={styles1.footer}></View>
        <Text style={styles1.pageNumber}>2</Text>
      </Page>
      <Page size="A4" style={styles1.page}>
        <View style={styles1.header}>
          <Text style={styles1.salesReport}>EVENT SALES REPORT</Text>
        </View>
        <View style={styles1.contentSection}>
          <InfoRow
            icon={<AccessTimeIcon style={{ color: "#3959a5" }} />}
            text={`Peak Food Time: ${peak_food_time_hour || "N/A"}`}
          />
          <InfoRow
            icon={<AttachMoneyIcon style={{ color: "#3959a5" }} />}
            text={`Total Sales for Peak Hour: $${Number(
              total_sales_for_peak_hour
            ).toFixed(2) || "N/A"}`}
          />
          <InfoRow
            icon={<ShowChartIcon style={{ color: "#3959a5" }} />}
            text={`Total Event Sales: $${Number(total_event_sales).toFixed(2) ||
              "N/A"}`}
          />
          <InfoRow
            icon={<Inventory2Icon style={{ color: "#3959a5" }} />}
            text={`Total Stock Purchased: $${Number(
              total_stock_purchased
            ).toFixed(2) || "N/A"}`}
          />
          <InfoRow
            icon={<AttachMoneyIcon style={{ color: "#3959a5" }} />}
            text={`Average Spent: $${Number(average_spend).toFixed(2) ||
              "N/A"}`}
          />
        </View>

        <View style={styles1.footer}></View>
        <Text style={styles1.pageNumber}>3</Text>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Labour cost per day
          </Text>
          {renderCostByDayTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Average Labour cost per Hour
          </Text>
          {renderCostByHourTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Avg cost per staff per event
          </Text>
          {renderCostByUserTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Feedback
          </Text>
          {renderFeedbackData()}
          <Text style={styles.subHeader}>Ratings</Text>
          {renderRatings()}
          <Text style={styles.subHeader}>Payout Details</Text>
          {renderPayoutDetails()}
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          >
            Weather Report
          </Text>
          {renderWeatherTable()}
        </View>
      </Page>
    </Document>
  );
};

const EventReportWithoutCost = ({
  data,
  feedbackData,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
  meal_freq,
}) => {
  console.log(start_date, end_date);
  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text>No Ratings Available</Text>;
    }
    return ratings.map((rating, index) => (
      <View key={index} style={styles.section}>
        <Text style={styles.content}>
          {"Rating: " + (Number(rating.ratings_value) / 2).toFixed(1) + "/5"}
        </Text>
        <Text style={styles.content}>{"Comment: " + rating.comments}</Text>
      </View>
    ));
  };
  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text>No Feedback Data Available</Text>;
    }

    return feedbackData.map((feedback, index) => (
      <View key={index} style={styles.section}>
        <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
          Feedback from {feedback.time_stamp}
        </Text>
        {feedback.feedback_lists.map((item, idx) => (
          <View key={idx} style={styles.table}>
            <Text style={styles.contentQuestion}>{item.feedback_question}</Text>
            <Text style={styles.content}>{item.feedback_answer}</Text>
          </View>
        ))}
      </View>
    ));
  };

  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text>No Weather Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Max Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Min Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Condition</Text>
        </View>

        {/* Table rows for each day */}
        {forecastData.forecast.forecastday.map((day, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{day.date}</Text>
            <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
            <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
            <Text style={styles.tableCell}>
              {day.day.condition ? day.day.condition.text : "N/A"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderMealFreq = () => {
    console.log(meal_freq);
    if (!meal_freq) {
      return <Text>No Meal Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Name</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Price ($)</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Count</Text>
        </View>

        {meal_freq.map((meal, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{meal.name}</Text>
            <Text style={styles.tableCell}>{meal.price}</Text>
            <Text style={styles.tableCell}>{meal.count}</Text>
          </View>
        ))}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles1.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Peak Food Time: {peak_food_time_hour || "N/A"}
          </Text>
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Feedback
          </Text>
          {renderFeedbackData()}
          <Text style={styles.subHeader}>Ratings</Text>
          {renderRatings()}
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          >
            Weather Report
          </Text>
          {renderWeatherTable()}
        </View>
      </Page>
    </Document>
  );
};

const EventReportSales = ({
  data,
  eoi_details,
  feedbackData,
  selection_criteria,
  service_providers,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
  meal_freq,
  stock_freq,
  sales_by_vendor_obj,
  socialMedia,
  socialMediaImages,
  marketing,
  acceptedVendors,
  pendingVendors,
  rejectedVendors,
  prSummary,
}) => {
  console.log(
    eoi_details,
    data,
    feedbackData,
    ratings,
    peak_food_time_hour,
    average_spend,
    total_event_sales,
    total_sales_for_peak_hour,
    total_stock_purchased,
    payoutDetails,
    forecastData,
    selectedVendorName,
    selectedEventName,
    start_date,
    end_date,
    meal_freq,
    stock_freq,
    sales_by_vendor_obj,
    socialMedia,
    socialMediaImages,
    marketing,
    acceptedVendors,
    pendingVendors,
    rejectedVendors
  );

  const convertHtmlToJsx = (htmlString) => {
    // Replace <p> tags with new lines
    let plainText = htmlString.replace(/<p>/g, "").replace(/<\/p>/g, "");

    // Replace <ul> and <li> tags
    plainText = plainText.replace(/<ul>/g, "").replace(/<\/ul>/g, "");
    plainText = plainText.replace(/<li>/g, "•  ").replace(/<\/li>/g, "");

    // Remove any remaining HTML tags
    plainText = plainText.replace(/<\/?[^>]+(>|$)/g, "");

    // Trim whitespace and return the result
    return plainText.trim();
  };

  const htmlString = `
<p>As part of the PR strategy, AMFVG utilised our databases of food bloggers, journalists and event companies that have supported our events previously through press releases, photo opportunities and event invites.</p>
<p>AMFVG and The Food Truck Park attracted radio, press, print and social media attention to this event. The Festival event was featured in;</p>
<ul>
  <li>Herald Sun,</li>
  <li>What’s on Melbourne,</li>
  <li>Frankly Frankton and Mornington Peninsula Magazine.</li>
</ul>
<p>The event also featured listed on numerous event listing websites including Eventfinda and Eventbrite.</p>
<p>AMFVG had influencer’s come through from all over Melbourne to take photos of their good times at the park; @missspuishyface, @twotravellingplanners and @kromekids to name a few.</p>
<p>Alongside the print and online coverage, our Event Producer was featured on 3AW plugging the event as well as Fox FM giving us a shout out too.</p>
<p>AMFVG reached out to print media year and had a full-page featured ad in Beat Magazine, that was printed into 30,000 copies, featured on their website which resulted in 427-page views on their site and their social media post reached 2,573 accounts on Facebook.</p>
`;

  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text></Text>;
    }
    return (
      Array.isArray(ratings) &&
      ratings.map((rating, index) => (
        <>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.head}>
                <Text style={styles.heading}>Ratings</Text>
              </View>

              <View key={index} style={styles.section}>
                <Text style={styles.content}>
                  {"Rating: " +
                    (Number(rating.ratings_value) / 2).toFixed(1) +
                    "/5"}
                </Text>
                <Text style={styles.content}>
                  {"Comment: " + rating.comments}
                </Text>
              </View>
            </View>
          </Page>
        </>
      ))
    );
  };

  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text></Text>;
    }

    return (
      Array.isArray(feedbackData) &&
      feedbackData.map((feedback, index) => (
        <>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.head}>
                <Text style={styles.heading}>Feedback Data</Text>
              </View>
              <View key={index} style={styles.section}>
                <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
                  Feedback from {feedback.time_stamp}
                </Text>
                {Array.isArray(feedback.feedback_lists) &&
                  feedback.feedback_lists.map((item, idx) => (
                    <View key={idx} wrap={false} style={styles.table}>
                      <Text style={styles.contentQuestion}>
                        {item.feedback_question}
                      </Text>
                      <Text style={styles.content}>{item.feedback_answer}</Text>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        </>
      ))
    );
  };

  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text></Text>;
    }

    return (
      <>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.head}>
              <Text style={styles.heading}>Weather Data</Text>
            </View>
            <View style={styles.table}>
              {/* Table header */}
              <View style={styles.tableRow} fixed>
                <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Max Temp (C)
                </Text>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Min Temp (C)
                </Text>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Condition
                </Text>
              </View>

              {/* Table rows for each day */}
              {forecastData.forecast.forecastday.map((day, index) => (
                <View wrap={false} key={index} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{day.date}</Text>
                  <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
                  <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
                  <Text style={styles.tableCell}>
                    {day.day.condition ? day.day.condition.text : "N/A"}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </>
    );
  };

  const renderCostByUserTable = () => {
    // Check if data is an array and not empty
    if (!Array.isArray(data) || data.length === 0) {
      return <Text style={styles.noDataText}></Text>;
    }

    return (
      <>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.head}>
              <Text style={styles.heading}>Wage Data</Text>
            </View>
            <View>
              {data.map((vendor, index) => (
                <View key={index} style={styles.vendorSection}>
                  <Text style={styles.vendorTitle}>{vendor.vendor_title}</Text>
                  {vendor.shift_data &&
                  typeof vendor.shift_data === "object" &&
                  vendor.shift_data.average_cost_per_user ? (
                    <View style={styles.table}>
                      <View wrap={false} style={styles.tableRow} fixed>
                        <Text style={[styles.tableCell, styles.headerCell]}>
                          User
                        </Text>
                        <Text style={[styles.tableCell, styles.headerCell]}>
                          Average Cost
                        </Text>
                      </View>
                      {Object.entries(
                        vendor.shift_data.average_cost_per_user
                      ).map(([user, cost]) => (
                        <View key={user} style={styles.tableRow}>
                          <Text style={styles.tableCell}>{user}</Text>
                          <Text style={styles.tableCell}>
                            ${cost.toFixed(2)}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ) : (
                    <Text style={styles.errorText}>
                      Error in Data or No Cost Data Available
                    </Text>
                  )}
                </View>
              ))}
            </View>
          </View>
        </Page>
      </>
    );
  };

  const renderPayoutDetails = () => {
    if (!payoutDetails || payoutDetails.length === 0) {
      return <Text>No Payout Details Available</Text>;
    }

    return (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {payoutDetails.map((payout, index) => (
            <View key={index} style={styles.section}>
              <Text
                style={[
                  styles.subHeader,
                  { textAlign: "center", paddingBottom: "10px" },
                ]}
              >
                Payout Details for {payout.vendor_name}
              </Text>
              <Text style={styles.content}>
                Approved By: {payout.approved_by}
              </Text>
              <Text style={styles.content}>
                Total WTT Commission: {payout.total_wtt_commission}
              </Text>
              <Text style={styles.content}>
                Cash Sales: {payout.wtt_cash_sales.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Card Sales: {payout.wtt_card_sales.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Voucher Sales: {payout.wtt_voucher_sales.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Total GST in payout: {payout.total_gst.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Total Payout: {payout.total_payout.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Total WTT Payout: {payout.total_wtt_payout.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Total WTT Card Commission:{" "}
                {payout.total_wtt_card_commission.toFixed(2)}
              </Text>
              <Text style={styles.content}>
                Total WTT Cash Commission:{" "}
                {payout.total_wtt_cash_commission.toFixed(2)}
              </Text>
              <Text style={[styles.content, { paddingBottom: "10px" }]}>
                Total WTT Voucher Commission:{" "}
                {payout.total_wtt_voucher_commission.toFixed(2)}
              </Text>

              <View style={styles.table}>
                <View wrap={false} style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.headerCell]}>
                    Charge Label
                  </Text>
                  <Text style={[styles.tableCell, styles.headerCell]}>
                    Gross
                  </Text>
                  <Text style={[styles.tableCell, styles.headerCell]}>GST</Text>
                  <Text style={[styles.tableCell, styles.headerCell]}>Net</Text>
                </View>

                {[1, 2, 3, 4, 5].map((num) => {
                  const label = payout[`label_misc_charges${num}`];
                  const gross = payout[`gross_misc_charges${num}`];
                  const gst = payout[`gst_misc_charges${num}`];
                  const net = payout[`net_misc_charges${num}`];

                  if (label && label.trim() !== "") {
                    return (
                      <View key={num} style={styles.tableRow}>
                        <Text style={styles.tableCell}>{label}</Text>
                        <Text style={styles.tableCell}>{gross.toFixed(2)}</Text>
                        <Text style={styles.tableCell}>{gst.toFixed(2)}</Text>
                        <Text style={styles.tableCell}>{net.toFixed(2)}</Text>
                      </View>
                    );
                  }
                  return null;
                })}
              </View>
            </View>
          ))}
          ;
        </View>
      </Page>
    );
  };
  const renderMealFreq = () => {
    console.log(meal_freq);
    if (!meal_freq) {
      return <Text></Text>;
    }

    return (
      <>
        <Text style={styles.heading}>Meal Data</Text>
        <Text></Text>
        <View style={styles.table}>
          {/* Table header */}
          <View wrap={false} style={styles.tableRow} fixed>
            <Text style={[styles.tableCell, styles.headerCell]}>Name</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Price ($)</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Count</Text>
          </View>

          {/* Table rows for each day */}
          {Array.isArray(meal_freq) &&
            meal_freq.map((meal, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{meal.name}</Text>
                <Text style={styles.tableCell}>{meal.price}</Text>
                <Text style={styles.tableCell}>{meal.count}</Text>
              </View>
            ))}
        </View>
      </>
    );
  };
  const renderStockFreq = () => {
    if (!stock_freq) {
      return <Text></Text>;
    }

    return (
      <>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.head}>
              <Text style={styles.heading}>Stock Data</Text>
            </View>
            <View style={styles.table}>
              {/* Table header */}
              <View wrap={false} style={styles.tableRow} fixed>
                <Text style={[styles.tableCell, styles.headerCell]}>Name</Text>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Price ($)
                </Text>
                <Text style={[styles.tableCell, styles.headerCell]}>Count</Text>
              </View>

              {/* Table rows for each day */}
              {Array.isArray(stock_freq) &&
                stock_freq.map((meal, index) => (
                  <View key={index} wrap={false} style={styles.tableRow}>
                    <Text style={styles.tableCell}>{meal.name}</Text>
                    <Text style={styles.tableCell}>{meal.price}</Text>
                    <Text style={styles.tableCell}>{meal.count}</Text>
                  </View>
                ))}
            </View>
          </View>
        </Page>
      </>
    );
  };
  const renderSalesByVendor = () => {
    if (!sales_by_vendor_obj) {
      return <Text></Text>;
    }

    return (
      <>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.head}>
              <Text style={styles.heading}>Sales By Vendors</Text>
            </View>
            <View style={styles.table}>
              {/* Table header */}
              <View wrap={false} style={styles.tableRow} fixed>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Vendor Name
                </Text>
                <Text style={[styles.tableCell, styles.headerCell]}>
                  Total Sales
                </Text>
              </View>

              {/* Table rows for each vendor */}
              {Object.entries(sales_by_vendor_obj)
                .sort((a, b) => b[1] - a[1]) // Sort the entries by sales in descending order
                .map(([vendor, sales], index) => (
                  <View key={index} style={styles.tableRow}>
                    <Text style={styles.tableCell}>{vendor}</Text>
                    <Text style={styles.tableCell}>
                      {sales.toLocaleString()}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
        </Page>
      </>
    );
  };
  const renderAcceptedVendors = () => {
    if (!acceptedVendors) {
      return <Text></Text>;
    }

    return acceptedVendors && acceptedVendors.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Accepted Vendors</Text>
          </View>
          {JSON.stringify(Object.keys(acceptedVendors[0]))}
          <View style={styles.table}>
            {/* Table header */}
            <View wrap={false} style={styles.tableRow} fixed>
              <Text style={[styles.tableCell, styles.headerCell]}>ID</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>Title</Text>
            </View>

            {/* Table rows for each vendor */}
            {acceptedVendors.map((vendor, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{vendor.accepted_outlets}</Text>
                <Text style={styles.tableCell}>
                  {vendor.accepted_outlets__title}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderPendingVendors = () => {
    if (!pendingVendors) {
      return <Text></Text>;
    }

    return pendingVendors && pendingVendors.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Pending Vendors</Text>
          </View>
          <View style={styles.table}>
            {/* Table header */}
            <View wrap={false} style={styles.tableRow} fixed>
              <Text style={[styles.tableCell, styles.headerCell]}>ID</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>Title</Text>
            </View>

            {/* Table rows for each vendor */}
            {pendingVendors.map((vendor, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{vendor.vendor_outlets}</Text>
                <Text style={styles.tableCell}>
                  {vendor.vendor_outlets__title}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderRejectedVendors = () => {
    if (!rejectedVendors) {
      return <Text></Text>;
    }

    return rejectedVendors && rejectedVendors.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Rejected Vendors</Text>
          </View>
          <View style={styles.table}>
            {/* Table header */}
            <View wrap={false} style={styles.tableRow} fixed>
              <Text style={[styles.tableCell, styles.headerCell]}>ID</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>Title</Text>
            </View>

            {/* Table rows for each vendor */}
            {rejectedVendors.map((vendor, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{vendor.rejected_outlets}</Text>
                <Text style={styles.tableCell}>
                  {vendor.rejected_outlets__title}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderSocialMediaSummary = () => {
    if (!socialMedia) {
      return <Text></Text>;
    }

    return socialMedia && socialMedia.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Social Media Summary</Text>
          </View>
          <View>
            {socialMedia.map((item, index) => (
              <View key={index} wrap={false} style={styles.post}>
                <Text style={styles.postSummary}>
                  "{item.summary_of_social_media}"
                </Text>
                <View style={styles.postInfo}>
                  <Text style={styles.postUser}>
                    {item.written_by__first_name} {item.written_by__last_name}
                  </Text>
                  <Text style={styles.postDate}>{item.date_written}</Text>
                </View>
              </View>
            ))}
          </View>
          <View>
            <Text style={styles.heading2}>Social Media Gallery</Text>
            {socialMediaImages.map((item) => (
              <View key={item.id} wrap={false} style={styles.post}>
                {/* <Image src={} cache={false} /> */}
                <Image
                  src={{
                    uri: AWS_URL + "/" + item.imagefield,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderMarketingSummary = () => {
    if (!marketing) {
      return <Text></Text>;
    }

    return marketing && marketing.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Marketing Summary</Text>
          </View>
          <View>
            {marketing.map((item, index) => (
              <View key={index} wrap={false} style={styles.post}>
                <Text style={styles.postSummary}>
                  "{item.summary_of_marketing}"
                </Text>
                <View style={styles.postInfo}>
                  <Text style={styles.postUser}>
                    {item.written_by__first_name} {item.written_by__last_name}
                  </Text>
                  <Text style={styles.postDate}>{item.date_written}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    ) : (
      <Text></Text>
    );
  };
  const renderEventInformation = () => {
    // if (!eventDetails) {
    //   return <Text></Text>;
    // }

    return (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.head}>
            <Text style={styles.heading}>Event Information</Text>
          </View>
          {eoi_details && eoi_details.eoi_event_name ? (
            <View>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Name of the Event: {eoi_details.eoi_event_name}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Event Location: {eoi_details.site_area}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Event Start Date/ Time:{" "}
                {moment(eoi_details.event_start_date_time).format(
                  "DD MMMM YYYY [at] h:mm A"
                )}
              </Text>

              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Event End Date/ Time:{" "}
                {moment(eoi_details.event_end_date_time).format(
                  "DD MMMM YYYY [at] h:mm A"
                )}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Provider: WTT
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                ABN: {eoi_details.event_organiser_abn_number ?? "N/A"}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Business: {eoi_details.event_organiser_buisness_name ?? "N/A"}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Organiser:{" "}
                {(eoi_details.event_organiser_first_name ?? "") +
                  " " +
                  (eoi_details.event_organiser_last_name ?? "")}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Water Access: {eoi_details.event_water_access ?? "N/A"}
              </Text>
              <Text style={[styles.content, { paddingTop: "10px" }]}>
                Services: {eoi_details.services ?? "N/A"}
              </Text>
            </View>
          ) : (
            <View></View>
          )}
          {prSummary ? (
            <View wrap={false}>
              <Text style={[styles.heading3, { textAlign: "center" }]}>
                PR Summary
              </Text>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <Text style={styles.content}>
                  {convertHtmlToJsx(prSummary)}
                </Text>
              </View>
            </View>
          ) : (
            <View></View>
          )}
          {prSummary ? (
            <Text style={[styles.heading3, { textAlign: "center" }]}>---</Text>
          ) : (
            <Text></Text>
          )}
          {service_providers && service_providers.length ? (
            <View wrap={false}>
              <Text style={[styles.heading3, { textAlign: "center" }]}>
                Service Providers
              </Text>
              <View
                style={{
                  paddingTop: "30px",
                }}
              >
                <Text
                  style={[
                    styles.content,
                    { paddingTop: "10px", paddingBottom: "10px" },
                  ]}
                >
                  WTT engaged a number of contractors or service providers to
                  assist in the event
                </Text>
                {service_providers.map((service) => (
                  <View
                    style={[
                      styles.content,
                      { flexDirection: "row", marginBottom: 4 },
                    ]}
                  >
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>
                      {service.service_provider_name} -{" "}
                      {service.summary_of_services}
                    </Text>
                  </View>
                ))}

                <Text style={[styles.content, { paddingTop: "10px" }]}>
                  No traffic management was engaged or in use.
                </Text>
                <Text style={[styles.content, { paddingTop: "10px" }]}>
                  WTT engaged staff to monitor and clean the area.
                </Text>
              </View>
            </View>
          ) : (
            <View></View>
          )}
          {service_providers && service_providers.length ? (
            <Text style={[styles.heading3, { textAlign: "center" }]}>---</Text>
          ) : (
            <Text></Text>
          )}
          {selection_criteria ? (
            <View wrap={false}>
              <Text style={[styles.heading3, { textAlign: "center" }]}>
                Selection Criteria
              </Text>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <Text style={styles.content}>
                  {convertHtmlToJsx(selection_criteria)}
                </Text>
              </View>
            </View>
          ) : (
            <View></View>
          )}
          {selection_criteria ? (
            <Text style={[styles.heading3, { textAlign: "center" }]}>---</Text>
          ) : (
            <Text></Text>
          )}
        </View>
      </Page>
    );
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>
        </View>
      </Page>
      {/* <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Vendor Name: {selectedVendorName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>
        </View>
      </Page> */}
      {renderEventInformation()}
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Peak Food Time: {peak_food_time_hour || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Total Sales for Peak Hour:{" "}
            {Number(total_sales_for_peak_hour).toFixed(2) || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Total Event Sales: {Number(total_event_sales).toFixed(2) || "N/A"}
          </Text>
          <Text style={styles.content}></Text>
          {renderMealFreq()}
        </View>
      </Page>
      {/* <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          ></Text> */}
      {renderSalesByVendor()}
      {renderAcceptedVendors()}
      {renderPendingVendors()}
      {renderRejectedVendors()}
      {/* <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          ></Text> */}
      {renderStockFreq()}
      {/* <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          ></Text> */}
      {/* <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Payout Details
          </Text>
          {renderPayoutDetails()} */}
      {/* <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          ></Text> */}
      {renderCostByUserTable()}
      {/* <Text style={[styles.subHeader, { paddingBottom: "10px" }]}></Text> */}
      {renderFeedbackData()}
      {/* <Text style={styles.subHeader}></Text> */}
      {renderRatings()}

      {renderSocialMediaSummary()}
      {renderMarketingSummary()}
      {/* <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          ></Text> */}
      {renderWeatherTable()}
    </Document>
  );
};

class EventOrganiserPostEventReport extends Component {
  state = {
    selectionCriteria: "",
    serviceProviders: [],
    prSummary: "",
    accepted_vendors_list: [],
    showDetailsModal: false,
    showSelectionCriteria: false,
    showPR: false,
    eoi_details: {},
    pending_list: [],
    rejected_vendors_list: [],
    social_media: [],
    social_media_images: [],
    marketing_summary: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    event_organiser_email: "",
    event_organiser_phone: "",
    truck_manager_email: "",
    truck_manager_phone: "",
    event_organiser_name: "",
    truck_manager_name: "",
    showEventModal: false,
    showTruckModal: false,
    totalWages: 0,
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    phoneNumberValidEventOrganiser: true,
    showWeatherModal: false,
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    showAcceptedVendors: false,
    showPendingVendors: false,
    showRejectedVendors: false,
    salesModal: false,
    billModal: false,
    eventExpensesModal: false,
    eventSummaryModal: false,
    salesByVendors: false,
    compareModal: false,
    mealFreq: false,
    stockFreq: false,
    salesBreakdown: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    modalOpen: false,
    selectedOptions: {
      ServiceProviders: true,
      SelectionCriteria: true,
      PrSummary: true,
      WageDetails: true,
      Feedback: true,
      Ratings: true,
      TotalStockPurchased: true,
      ForecastData: true,
      Meals: true,
      Stock: true,
      SalesByVendors: true,
      VendorsSummary: true,
      Marketing: true,
      SocialMedia: true,
    },
    readyToDownload: false,
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    forecastData: {},
    payoutDetails: {},
    payoutDetailsArray: [],
    shiftData: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    total_event_sales: 0,
    total_stock_purchased: 0,
    average_spend: 0,
    feedback_data: [],
    ratings_from_organiser: [],
    event_expenses: [],
    total_orders: 0,
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    showMarketingSummary: false,
    showSocialMediaPostsModal: false,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    peak_food_time_hour: "",
    total_sales_for_peak_hour: 0,
    width:
      window.innerWidth <= 400 ? 280 : window.innerWidth <= 960 ? 400 : 800,
    selectedDate: "-1",
    meal_freq: [],
    sales_by_vendor_obj: [],
    stock_freq: [],
    payoutArray: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    showMarketingConclusionModal: false,
    total_event_transaction_fees_sales: 0,
    total_event_surge_charge_sales: 0,
    total_event_card_sales: 0,
    total_event_cash_sales: 0,
    sales_by_hour: {},
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };
  handleResize = () => {
    this.setState({ width: this.getWidth() });
  };

  getWidth = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      return 280;
    } else if (width <= 960) {
      return 400;
    } else {
      return 800;
    }
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };
  handleShowSalesModal = () => {
    this.setState({ salesModal: true });
  };
  handleShowAcceptedVendors = () => {
    this.setState({ showAcceptedVendors: true });
  };
  handleShowPendingVendors = () => {
    this.setState({ showPendingVendors: true });
  };
  handleShowRejectedVendors = () => {
    this.setState({ showRejectedVendors: true });
  };
  handleCloseAcceptedVendors = () => {
    this.setState({ showAcceptedVendors: false });
  };
  handleClosePendingVendors = () => {
    this.setState({ showPendingVendors: false });
  };
  handleCloseRejectedVendors = () => {
    this.setState({ showRejectedVendors: false });
  };
  handleCloseSalesModal = () => {
    this.setState({ salesModal: false });
  };
  handleShowBillModal = () => {
    this.setState({ billModal: true });
  };
  handleCloseBillModal = () => {
    this.setState({ billModal: false });
  };
  handleShowEventExpensesModal = () => {
    this.setState({ eventExpensesModal: true });
  };
  handleCloseEventExpenses = () => {
    this.setState({ eventExpensesModal: false });
  };
  handleShowEventSummaryModal = () => {
    this.setState({ eventSummaryModal: true });
  };
  handleCloseEventSummaryModal = () => {
    this.setState({ eventSummaryModal: false });
  };
  handleShowSalesByVendors = () => {
    this.setState({ salesByVendors: true });
  };
  handleCloseSalesByVendors = () => {
    this.setState({ salesByVendors: false });
  };
  handleShowCompareModal = () => {
    this.setState({ compareModal: true });
  };
  handleShowSocialMediaPosts = () => {
    this.setState({ showSocialMediaPostsModal: true });
  };
  handleCloseSocialMediaPosts = () => {
    this.setState({ showSocialMediaPostsModal: false });
  };
  handleShowMarketingSummary = () => {
    this.setState({ showMarketingSummary: true });
  };
  handleCloseMarketingSummary = () => {
    this.setState({ showMarketingSummary: false });
  };
  handleSocialMediaFeedback = () => {
    this.setState({ showSocialMediaFeedbackModal: true });
  };
  handleSocialMediaFeedbackModalClose = () => {
    this.setState({ showSocialMediaFeedbackModal: false });
  };
  handleSocialMediaFeedback = () => {
    this.setState({ showSocialMediaFeedbackModal: true });
  };
  handleMarketingConclusion = () => {
    this.setState({ showMarketingConclusionModal: true });
  };
  handleMarketingConclusionModal = () => {
    console.log("clicked");
    this.setState({ showMarketingConclusionModal: true });
  };
  handleMarketingConclusionModalClose = () => {
    console.log("clicked");
    this.setState({ showMarketingConclusionModal: false });
  };
  handleCloseCompareModal = () => {
    this.setState({ compareModal: false });
  };
  handleShowMealFreq = () => {
    this.setState({ mealFreq: true });
  };
  handleCloseMealFreq = () => {
    this.setState({ mealFreq: false });
  };
  handleShowStockFreq = () => {
    this.setState({ stockFreq: true });
  };
  handleCloseStockFreq = () => {
    this.setState({ stockFreq: false });
  };
  handleShowSalesBreakdown = () => {
    this.setState({ salesBreakdown: true });
  };
  handleCloseSalesBreakdown = () => {
    this.setState({ salesBreakdown: false });
  };
  handleShowDetailsModal = () => {
    this.setState({ showDetailsModal: true });
  };
  handleCloseDetailsModal = () => {
    this.setState({ showDetailsModal: false });
  };
  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  getTooltipContent(tooltipModel) {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.cost_by_day) {
      return;
    }

    const date = tooltipModel.title[0];
    const dailyData = shiftData.cost_by_day[date];
    let tooltipContent = [`Total Cost: ${dailyData.total_cost}`];

    Object.keys(dailyData.user_costs).forEach((userName) => {
      tooltipContent.push(
        `${userName}: $${dailyData.user_costs[userName]}, Hours: ${dailyData.hours_worked[userName]}`
      );
    });

    return tooltipContent;
  }

  renderCostByTotalHourChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.sorted_hourly_cost) {
      return null; // or some placeholder content
    }

    // Function to calculate the percentage difference
    const calculatePercentageDifference = (current, previous) => {
      if (previous === 0) return "N/A"; // Avoid division by zero
      return (((current - previous) / previous) * 100).toFixed(2);
    };
    const calculatePercentageOfTotal = (cost, total) => {
      return ((cost / total) * 100).toFixed(2);
    };

    // Preparing the data for the chart
    const hours = Object.keys(shiftData.sorted_hourly_cost).sort();
    const costs = hours.map((hour) => shiftData.sorted_hourly_cost[hour]);
    const previousCosts = [0, ...costs.slice(0, -1)]; // Shift the costs by one to align with previous hour
    const percentageDiffs = costs.map((cost, index) =>
      calculatePercentageDifference(cost, previousCosts[index])
    );
    const percentagesOfTotal = costs.map((cost) =>
      calculatePercentageOfTotal(cost, shiftData.total_cost)
    );

    const chartData = {
      labels: hours,
      datasets: [
        {
          label: "Total Labour Cost per Hour",
          data: costs,
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            afterLabel: function(context) {
              const index = context.dataIndex;
              const percentageDiff = percentageDiffs[index];
              const percentageOfTotal = percentagesOfTotal[index];
              return [
                `${percentageDiff}% ${
                  percentageDiff >= 0 ? "Increase" : "Decrease"
                } from Last Hour`,
                `${percentageOfTotal}% Percentage of Total Labour Cost `,
              ];
            },
          },
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  }

  renderCostByHourChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.cost_by_hour) {
      return null; // or some placeholder content
    }

    const data = {
      labels: Object.keys(shiftData.cost_by_hour).map((date) =>
        moment(date).format("DD-MM-YYYY")
      ),
      datasets: [
        {
          label: "Average Labour Hourly Cost Per Day Based On Duration",
          data: Object.values(shiftData.cost_by_hour),
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
        },
      ],
    };

    return <Bar data={data} />;
  }

  renderAverageCostPerUserChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.average_cost_per_user) {
      return null; // or some placeholder content
    }
    const chartColors = [
      "rgba(255, 99, 132, 0.8)", // red
      "rgba(54, 162, 235, 0.8)", // blue
      "rgba(255, 206, 86, 0.8)", // yellow
      "rgba(75, 192, 192, 0.8)", // green
      "rgba(153, 102, 255, 0.8)", // purple
      "rgba(255, 159, 64, 0.8)", // orange
      // ... add more colors if needed
    ];
    const data = {
      labels: Object.keys(shiftData.average_cost_per_user),
      datasets: [
        {
          data: Object.values(shiftData.average_cost_per_user),
          backgroundColor: chartColors.slice(
            0,
            Object.keys(shiftData.average_cost_per_user).length
          ),
        },
      ],
    };

    return <Pie data={data} />;
  }

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_events_for_stock",
            operator_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          console.log(dataResponse, "get_all_events_for_stocks");
          this.setState({ events: dataResponse.events }, () => {
            console.log(dataResponse.stalls);
            let vendorId = "0";
            this.setState({ selectedEventId: vendorId }, () => {
              saveToLocalStorage("selectedEventId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      isOrganiserLoggedIn,
    } = this.state;
    if (isOrganiserLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/organiser";
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleButtonClick = () => {
    this.setState({ showEventModal: true });
  };

  handleModalClose = () => {
    this.setState({ showEventModal: false });
  };
  handleTruckModalClose = () => {
    this.setState({ showTruckModal: false });
  };
  handleTruckModalShow = () => {
    this.setState({ showTruckModal: true });
  };

  handleFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    // if (!isPhoneNumberValid) {
    //   this.setState({ phoneNumberValid: false });
    //   return;
    // }

    // If the phone number is valid, you can proceed with other actions
    // console.log("Email:", this.state.email);
    // console.log("Phone:",  this.state.phone);

    // API Call

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_reminder_to_truck_manager",
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          truck_manager_name: this.state.truck_manager_name,
          email: this.state.truck_manager_email,
          phone: this.state.truck_manager_phone,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Truck Manager");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // Close the modal
  };

  handleEventOrganiserReminderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_reminder_to_event_organisers_for_feedback",
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          organiser_name: this.state.event_organiser_name,
          email: this.state.event_organiser_email,
          phone: this.state.event_organiser_phone,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Truck Manager");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // Close the modal
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = (selectedVendorId) => {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState(
            {
              events: dataResponse.data.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getOrders();
              }
              this.getOrders();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getOrdersPartTwo = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_post_event_organiser_report_two",
          email: email,
          password: password,
          event_id: selectedEventId,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "fetch_post_event_organiser_report_two");
        if (dataResponse.success == true) {
          this.setState({
            social_media: dataResponse.social_media,
            social_media_images: dataResponse.social_media_images,
            marketing_summary: dataResponse.marketing,
            accepted_vendors_list: dataResponse.accepted_vendors_list,
            pending_list: dataResponse.pending_list,
            rejected_vendors_list: dataResponse.rejected_vendors_list,
            eoi_details:
              dataResponse.eoi_details && dataResponse.eoi_details.length
                ? dataResponse.eoi_details[0]
                : {},
            prSummary:
              dataResponse.pr_for_event && dataResponse.pr_for_event.length
                ? dataResponse.pr_for_event[
                    dataResponse.pr_for_event.length - 1
                  ].summary_of_pr
                : "",
            selectionCriteria:
              dataResponse.selection_criteria &&
              dataResponse.selection_criteria.length
                ? dataResponse.selection_criteria[
                    dataResponse.selection_criteria.length - 1
                  ].selection_requirements
                : `<p>${
                    dataResponse.eoi_details && dataResponse.eoi_details.length
                      ? dataResponse.eoi_details[0].eoi_event_name
                      : ""
                  } provided an EOI to the ${
                    dataResponse.eoi_details && dataResponse.eoi_details.length
                      ? dataResponse.eoi_details[0].eoi_event_name
                      : ""
                  } membership. This process and selection criteria involved;</p>
<p>Selection Criteria:</p>
<ul>
<li>Quality and Value for money;</li>
<li>Food type and menu mix;</li>
<li>The food truck's experience in meeting the high demand of the event;</li>
<li>The popularity of the Food Truck Brand;</li>
<li>The 'fit' to the event and overall event style and type;</li>
<li>The ability to meet the overarching event requirements and KPI's; and,</li>
<li>Community expectations across the event five days.</li>
</ul>
<p>${
                    dataResponse.eoi_details && dataResponse.eoi_details.length
                      ? dataResponse.eoi_details[0].eoi_event_name
                      : ""
                  } members responded to the EOI very well, AMVFG adopted an approach that provide the vendors with greater coverage and consistency which additionally provided visiting patrons with knowledge and the opportunity to return due to Food Trucks remaining across the days, a full listing of each days Food Trucks was provided to the patrons.</p>
<p>This change in rotations was well received by the Food Trucks and the patrons alike.</p>`,
            serviceProviders:
              dataResponse.service_providers &&
              dataResponse.service_providers.length
                ? dataResponse.service_providers
                : [],
          });
          this.setState({
            payoutDetailsArray: dataResponse.payout_details,
            shiftData: dataResponse.shifts_data,
            feedback_data: dataResponse.feedback_data,
          });

          function calculateTotalCost(shiftsData) {
            let totalCost = 0;
            shiftsData.forEach((shift) => {
              if (
                shift.shift_data &&
                typeof shift.shift_data.total_cost === "number"
              ) {
                totalCost += shift.shift_data.total_cost;
              }
            });
            return totalCost;
          }
          const totalWages = calculateTotalCost(dataResponse.shifts_data);
          console.log(totalWages);
          this.setState({
            totalWages: totalWages,
          });
        } else {
          this.setState({
            payoutDetailsArray: [],
            shiftData: {},
            feedback_data: [],
            totalWages: 0,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  getOrdersPartThree = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_post_event_organiser_report_three",
          email: email,
          password: password,
          event_id: selectedEventId,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "fetch_post_event_organiser_report_three");
        // if (dataResponse.success == true) {
        //   this.setState({
        //     vendors_in_event: dataResponse.accepted_vendors_list.map(
        //       (item) => ({
        //         vendor_id: item.accepted_outlets,
        //         vendor__title: item.accepted_outlets__title,
        //       })
        //     ),
        //     // vendors_in_event:dataResponse.list_of_vendors_in_event,
        //     social_media: dataResponse.social_media,
        //     social_media_images: dataResponse.social_media_images,
        //     marketing_summary: dataResponse.marketing,
        //     accepted_vendors_list: dataResponse.accepted_vendors_list,
        //     pending_list: dataResponse.pending_list,
        //     rejected_vendors_list: dataResponse.rejected_vendors_list,
        //     // feedback_data: dataResponse.feedback_data,
        //     feedback_data: [
        //       {
        //         feedback_lists: [
        //           {
        //             feedback_question: "123",
        //             feedback_answer: "123",
        //             feedback_id: 123,
        //           },
        //         ],
        //       },
        //     ],
        //   });
        //   this.setState({
        //     payoutDetailsArray: dataResponse.payout_details,
        //     shiftData: dataResponse.shifts_data,
        //   });

        //   function calculateTotalCost(shiftsData) {
        //     let totalCost = 0;
        //     shiftsData.forEach((shift) => {
        //       if (
        //         shift.shift_data &&
        //         typeof shift.shift_data.total_cost === "number"
        //       ) {
        //         totalCost += shift.shift_data.total_cost;
        //       }
        //     });
        //     return totalCost;
        //   }
        //   const totalWages = calculateTotalCost(dataResponse.shifts_data);
        //   console.log(totalWages);
        //   this.setState({
        //     totalWages: totalWages,
        //   });
        // } else {
        //   this.setState({
        //     payoutDetailsArray: [],
        //     shiftData: {},
        //     feedback_data: [],
        //     totalWages: 0,
        //   });
        // }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_post_event_organiser_report",
          email: email,
          password: password,
          //   vendor_id: selectedVendorId,
          event_id: selectedEventId,
          // selected_start_order_id: selectedStartOrderId,
          // selected_end_order_id: selectedEndOrderId,
          // selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    this.setState({
      prSummary: "",
      serviceProviders: [],
      selectionCriteria: "",
      eoi_details: {},
    });
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "fetch_post_event_organiser_report");
        if (dataResponse.success == true) {
          const payoutDetailsArray = dataResponse.payout_details;
          const cumulativeDetails = payoutDetailsArray.reduce(
            (accumulator, current) => {
              accumulator.total_wtt_payout += current.total_wtt_payout || 0;
              accumulator.wtt_card_sales += current.wtt_card_sales || 0;
              accumulator.wtt_cash_sales += current.wtt_cash_sales || 0;
              accumulator.wtt_voucher_sales += current.wtt_voucher_sales || 0;
              accumulator.total_wtt_card_commission +=
                current.total_wtt_card_commission || 0;
              accumulator.total_wtt_cash_commission +=
                current.total_wtt_cash_commission || 0;
              accumulator.total_wtt_voucher_commission +=
                current.total_wtt_voucher_commission || 0;
              accumulator.total_gst += current.total_gst || 0;

              return accumulator;
            },
            {
              total_wtt_payout: 0,
              wtt_card_sales: 0,
              wtt_cash_sales: 0,
              wtt_voucher_sales: 0,
              total_wtt_card_commission: 0,
              total_wtt_cash_commission: 0,
              total_wtt_voucher_commission: 0,
              total_gst: 0,
            }
          );
          const sortedSalesByVendor = Object.entries(
            dataResponse.sales_by_vendor_obj
          )
            .sort((a, b) => b[1] - a[1])
            .reduce((acc, [vendor, sales]) => {
              acc[vendor] = sales;
              return acc;
            }, {});
          const sortedMealFreq = dataResponse.meal_freq.sort(
            (a, b) => b.price - a.price
          );

          this.setState({
            payoutDetailsArray: dataResponse.payout_details,
            peak_food_time_hour: dataResponse.peak_food_time_hour,
            total_sales_for_peak_hour: dataResponse.total_sales_for_peak_hour,
            total_event_sales: dataResponse.total_event_sales,
            total_stock_purchased: dataResponse.total_stock_purchased,
            average_spend: dataResponse.average_spend,
            ratings_from_organiser: dataResponse.ratings_from_organiser,
            event_expenses: dataResponse.event_expenses,
            forecastData: dataResponse.weather_info,
            payoutDetails: cumulativeDetails,
            payoutArray: dataResponse.payout_details,
            meal_freq: sortedMealFreq,
            stock_freq: dataResponse.stock_freq,
            sales_by_vendor_obj: sortedSalesByVendor,
            total_event_cash_sales: dataResponse.total_event_cash_sales,
            total_event_card_sales: dataResponse.total_event_card_sales,
            total_event_surge_charge_sales:
              dataResponse.total_event_surge_charge_sales,
            total_event_transaction_fees_sales:
              dataResponse.total_event_transaction_fees_sales,
            sales_by_hour: dataResponse.sales_by_hour,
          });
          this.getOrdersPartTwo();
          // this.getOrdersPartThree();
        } else {
          this.setState({
            payoutDetailsArray: [],
            peak_food_time_hour: "",
            total_sales_for_peak_hour: 0,
            total_event_sales: 0,
            total_stock_purchased: 0,
            average_spend: 0,
            ratings_from_organiser: [],
            event_expenses: [],
            forecastData: {},
            payoutDetails: {},
            payoutArray: [],
            meal_freq: {},
            stock_freq: {},
            sales_by_vendor_obj: {},
            total_event_cash_sales: 0,
            total_event_card_sales: 0,
            total_event_surge_charge_sales: 0,
            total_event_transaction_fees_sales: 0,
            sales_by_hour: {},
          });
        }

        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];

        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  //   Get Hourly

  getHourlySales = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isHourlyGraphLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_hourly_sales",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "get_hourly_sales");
        if (dataResponse.success == true) {
          this.setState({
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isHourlyGraphLoading: false });
        this.getMealFrequency();
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });

        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleShow = () => {
    this.setState({ showWeatherModal: true });
  };

  handleClose = () => {
    this.setState({ showWeatherModal: false });
  };

  handleShowSeelctionCriteria = () => {
    this.setState({ showSelectionCriteria: true });
  };

  handleCloseSeelctionCriteria = () => {
    this.setState({ showSelectionCriteria: false });
  };
  handleShowPR = () => {
    this.setState({ showPR: true });
  };

  handleClosePR = () => {
    this.setState({ showPR: false });
  };

  handleOpenReport = () => {
    console.log("opening model");
    this.setState({ modalOpen: true });
  };

  handleCloseReport = () => {
    this.setState({ modalOpen: false });
  };
  handleCheckboxChange = (event) => {
    this.setState({
      selectedOptions: {
        ...this.state.selectedOptions,
        [event.target.name]: event.target.checked,
      },
    });
  };

  handleSelectAll = (event) => {
    const newState = Object.keys(this.state.selectedOptions).reduce(
      (acc, key) => {
        acc[key] = event.target.checked;
        return acc;
      },
      {}
    );
    this.setState({ selectedOptions: newState });
  };

  confirmSelection = () => {
    this.setState({ readyToDownload: true });
  };
  prepareEventReportProps() {
    const {
      selectedOptions,
      shiftData,
      feedback_data,
      ratings_from_organiser,
      peak_food_time_hour,
      total_sales_for_peak_hour,
      total_event_sales,
      total_stock_purchased,
      average_spend,
      payoutDetails,
      forecastData,
      selectedVendorName,
      selectedEventName,
      startDate,
      endDate,
      meal_freq,
      stock_freq,
      sales_by_vendor_obj,
      prSummary,
      serviceProviders,
      selectionCriteria,
      eoi_details,
    } = this.state;

    const props = {};
    props.eoi_details = eoi_details;
    if (selectedOptions.SelectionCriteria)
      props.selection_criteria = selectionCriteria;
    if (selectedOptions.ServiceProviders)
      props.service_providers = serviceProviders;
    if (selectedOptions.PrSummary) props.prSummary = prSummary;
    if (selectedOptions.WageDetails) {
      props.data = shiftData;
      props.payoutDetails = payoutDetails;
    }
    if (selectedOptions.SocialMedia) {
      props.socialMedia = this.state.social_media;
      props.socialMediaImages = this.state.social_media_images;
    }
    if (selectedOptions.Marketing)
      props.marketing = this.state.marketing_summary;
    if (selectedOptions.VendorsSummary) {
      props.acceptedVendors = this.state.accepted_vendors_list;
      props.pendingVendors = this.state.pending_list;
      props.rejectedVendors = this.state.rejected_vendors_list;
    }

    if (selectedOptions.Feedback) props.feedbackData = feedback_data;
    if (selectedOptions.Ratings) props.ratings = ratings_from_organiser;
    props.peak_food_time_hour = peak_food_time_hour;
    props.total_sales_for_peak_hour = total_sales_for_peak_hour;
    props.total_event_sales = total_event_sales;
    if (selectedOptions.TotalStockPurchased)
      props.total_stock_purchased = total_stock_purchased;
    props.average_spend = average_spend;
    if (selectedOptions.ForecastData) props.forecastData = forecastData;
    props.selectedVendorName = selectedVendorName;
    props.selectedEventName = selectedEventName;
    props.start_date = startDate;
    props.end_date = endDate;
    if (selectedOptions.Meals) props.meal_freq = meal_freq;
    if (selectedOptions.Stock) props.stock_freq = stock_freq;
    if (selectedOptions.SalesByVendors)
      props.sales_by_vendor_obj = sales_by_vendor_obj;

    return props;
  }

  formatLabel = (label) => {
    return label
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  renderModal = () => (
    <>
      <Modal show={this.state.modalOpen} onHide={this.handleCloseReport}>
        <Modal.Header closeButton>
          <Modal.Title>Select Properties for Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            {Object.keys(this.state.selectedOptions).map((option, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedOptions[option]}
                    onChange={this.handleCheckboxChange}
                    name={option}
                  />
                }
                label={this.formatLabel(option)}
                key={index}
              />
            ))}
            <FormControlLabel
              control={<Checkbox onChange={this.handleSelectAll} />}
              label="Select All"
            />
            <Button onClick={this.confirmSelection} variant="contained">
              Confirm
            </Button>
            <Button
              onClick={this.handleCloseReport}
              style={{ marginLeft: "10px" }}
            >
              Close
            </Button>
          </FormGroup>
          {this.state.readyToDownload && (
            <PDFDownloadLink
              document={
                <EventReportSales {...this.prepareEventReportProps()} />
              }
              fileName="event-report-sales.pdf"
            >
              {({ loading }) =>
                loading ? "Preparing document..." : "Download Now"
              }
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>
    </>
  );

  getMealFrequency = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }

    this.setState({ isMealFreqLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_by_vendor_graphs",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      timeout: 120000,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse, "get_sales_by_vendor_graphs");
        if (dataResponse.success == true) {
          this.setState({
            meal_freq: dataResponse.meal_freq,
          });
          // var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isMealFreqLoading: false });
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
    });
    window.location.reload();
  };

  setProviders = (providers) => {
    this.setState({
      serviceProviders: providers,
    });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isOrganiserLoggedIn,
    } = this.state;
    return (
      <>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isOperatorLoggedIn={isOperatorLoggedIn}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></AdminNavBar>
        {this.renderHome()}
      </>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      peak_food_time_hour,
      total_sales_for_peak_hour,
      total_event_sales,
      total_stock_purchased,
      average_spend,
      feedback_data,
      ratings_from_organiser,
      event_expenses,
      selectedEvent,
      vendors,
      selectedVendorId,
      total_sales,
      payoutArray,
      totalCashOrders,
      totalCardOrders,
      forecastData,
      payoutDetails,
      shiftData,
      payoutDetailsArray,
      meal_freq,
      stock_freq,
      sales_by_vendor_obj,
      total_event_cash_sales,
      total_event_card_sales,
      total_event_surge_charge_sales,
      total_event_transaction_fees_sales,
      sales_by_hour,
      totalWages,
      selectedEventId,
    } = this.state;
    const chartDataHour = {
      labels: Object.keys(sales_by_hour).map((hour) => `${hour}:00`),
      datasets: [
        {
          label: "Sales",
          data: Object.values(sales_by_hour),
          backgroundColor: [
            "#2959a5",
            "#ef3e6d",
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
          borderWidth: 1,
        },
      ],
    };

    // Define chart options
    const chartOptionsHour = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Sales by Hour",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    // Prepare chart data
    const chartData = {
      labels: Object.keys(sales_by_vendor_obj),
      datasets: [
        {
          label: "Sales",
          data: Object.values(sales_by_vendor_obj),
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Sales by Vendor",
          font: {
            size: 20,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          label: function(tooltips, data) {
            return "$" + tooltips.yLabel;
          },
        },
      },
    };

    const { showWeatherModal } = this.state;
    const Item = styled(Paper)(({ theme }) => ({
      // backgroundColor: "rgba(41, 89, 165, 0.3)",
      backgroundColor: "#2959a54d",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex", // Align items horizontally
      justifyContent: "center", // Center items horizontally
    }));
    let new_total_orders = totalCashOrders + totalCardOrders;
    const averageOrder =
      Number(total_sales).toFixed(2) / Number(new_total_orders).toFixed(2);
    const setNewRating = (data) => {
      this.setState({ selectedRating: data * 2 });
    };
    return (
      <div className="expenses-container">
        {!isLoading ? (
          <>
            <React.Fragment>
              <CssBaseline />
              <Container>
                <div
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    backgroundColor: "#113a7a",
                    color: "#113a7a",
                  }}
                ></div>
                <h2
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#f1f1f1",
                  }}
                >
                  Event Report
                </h2>
                <Grid style={{ backgroundColor: "#fff", borderRadius: "20px" }}>
                  <Grid item xs={12}>
                    <Item>
                      <div className="icon-container">
                        <CalendarMonthIcon
                          sx={{ fontSize: 40, color: "#ef3e6d" }}
                        />
                      </div>
                      <div className="autocomplete-container">
                        <Autocomplete
                          options={events}
                          getOptionLabel={(option) => `${option.name}`}
                          sx={{ width: this.state.width }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Event"
                              variant="standard"
                            />
                          )}
                          onChange={(e, newValue) => {
                            const selectedEventId = newValue ? newValue.id : "";
                            const selectedEventName = newValue
                              ? newValue.name
                              : "";
                            const startDate = newValue
                              ? newValue.start_date
                              : "";
                            const endDate = newValue ? newValue.end_date : "";
                            this.setState(
                              {
                                selectedEventId,
                                selectedEventName,
                                startDate,
                                endDate,
                              },
                              () => {
                                this.getOrders(selectedEventId);
                              }
                            );
                            saveToLocalStorage(
                              "selectedEventId",
                              selectedEventId
                            );
                            saveToLocalStorage("startDate", startDate);
                            saveToLocalStorage("endDate", endDate);
                          }}
                          value={
                            events.find(
                              (event) => event.id === this.state.selectedEventId
                            ) || null
                          }
                        />
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                {this.state.eoi_details &&
                this.state.eoi_details.eoi_event_name ? (
                  <div
                    style={{
                      borderRadius: "20px",
                      overflow: "hidden",
                      height: "200px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "bold",
                        color: "#2959a5",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Event Details
                      <SummarizeIcon
                        style={{ marginLeft: "0.5rem", color: green[500] }}
                      />
                    </h2>
                    <div
                      style={{
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div>
                        <p>
                          <b>Event Name:</b>{" "}
                          {this.state.eoi_details.eoi_event_name}
                        </p>
                        <p>
                          <b>Event Location:</b>{" "}
                          {this.state.eoi_details.site_area ?? "N/A"}
                        </p>
                        {this.state.eoi_details &&
                        this.state.eoi_details.event_start_date_time ? (
                          <p>
                            <b>Event Start Date/ Time:</b>{" "}
                            {moment(
                              this.state.eoi_details.event_start_date_time
                            ).format("DD MMMM YYYY [at] h:mm A")}
                          </p>
                        ) : null}
                        {this.state.eoi_details &&
                        this.state.eoi_details.event_end_date_time ? (
                          <p>
                            <b>Event End Date/ Time:</b>{" "}
                            {moment(
                              this.state.eoi_details.event_end_date_time
                            ).format("DD MMMM YYYY [at] h:mm A")}
                          </p>
                        ) : null}
                      </div>

                      <div>
                        <p>
                          <b>Organiser:</b>{" "}
                          {(this.state.eoi_details.event_organiser_first_name ??
                            "") +
                            " " +
                            (this.state.eoi_details.event_organiser_last_name ??
                              "")}
                        </p>
                        <p>
                          <b>ABN:</b>{" "}
                          {this.state.eoi_details.event_organiser_abn_number ??
                            "N/A"}
                        </p>
                        <p>
                          <b>Services:</b>{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {this.state.eoi_details.services ?? "N/A"}
                          </span>
                        </p>
                        <p>
                          <b>Water Access:</b>{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {this.state.eoi_details.event_water_access ?? "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {/* <Col>
                    <Card
                      onClick={this.handleShow}
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Event Summary
                          <SummarizeIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Modal
                          show={this.state.salesBreakdown}
                          onHide={this.handleCloseSalesBreakdown}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title
                              style={{ width: "100%", textAlign: "center" }}
                            >
                              Sales Breakdown
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {total_event_cash_sales &&
                              total_event_cash_sales !== 0 && (
                                <p>
                                  Total Cash Sales: $
                                  {Number(total_event_cash_sales).toFixed(2)}
                                </p>
                              )}
                            {total_event_card_sales &&
                              total_event_card_sales !== 0 && (
                                <p>
                                  Total Card Sales: $
                                  {Number(total_event_card_sales).toFixed(2)}
                                </p>
                              )}
                            {total_event_transaction_fees_sales &&
                              total_event_transaction_fees_sales !== 0 && (
                                <p>
                                  Transaction Fee: $
                                  {Number(
                                    total_event_transaction_fees_sales
                                  ).toFixed(2)}
                                </p>
                              )}
                            {total_event_surge_charge_sales &&
                              total_event_surge_charge_sales !== 0 && (
                                <p>
                                  Surcharge: $
                                  {Number(
                                    total_event_surge_charge_sales
                                  ).toFixed(2)}
                                </p>
                              )}
                            <Bar
                              data={chartDataHour}
                              options={chartOptionsHour}
                            />
                          </Modal.Body>
                        </Modal>
                      </Card.Body>
                    </Card>
                  </Col> */}
                  <Col>
                    <Card
                      onClick={this.handleShow}
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Service Providers{" "}
                          <ElectricBoltIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <ServiceProvidersModal
                          event_id={this.state.selectedEventId}
                          setProviders={this.setProviders}
                          service_providers={this.state.serviceProviders}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      onClick={this.handleShow}
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Selection Criteria{" "}
                          <ElectricBoltIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <SelectionCriteriaModal
                          event_id={selectedEventId}
                          criteriaData={this.state.selectionCriteria}
                          show={this.state.showSelectionCriteria}
                          handleClose={this.handleCloseSeelctionCriteria}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Summary of PR{" "}
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <PRModal
                          event_id={selectedEventId}
                          prData={this.state.prSummary}
                          show={this.state.showPR}
                          handleClose={this.handleClosePR}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Total Event Sales
                          <AttachMoneyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>

                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${total_event_sales}
                          </span>
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowSalesBreakdown}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.salesBreakdown}
                      onHide={this.handleCloseSalesBreakdown}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sales Breakdown
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {total_event_cash_sales &&
                          total_event_cash_sales !== 0 && (
                            <p>
                              Total Cash Sales: $
                              {Number(total_event_cash_sales).toFixed(2)}
                            </p>
                          )}
                        {total_event_card_sales &&
                          total_event_card_sales !== 0 && (
                            <p>
                              Total Card Sales: $
                              {Number(total_event_card_sales).toFixed(2)}
                            </p>
                          )}
                        {total_event_transaction_fees_sales &&
                          total_event_transaction_fees_sales !== 0 && (
                            <p>
                              Transaction Fee: $
                              {Number(
                                total_event_transaction_fees_sales
                              ).toFixed(2)}
                            </p>
                          )}
                        {total_event_surge_charge_sales &&
                          total_event_surge_charge_sales !== 0 && (
                            <p>
                              Surcharge: $
                              {Number(total_event_surge_charge_sales).toFixed(
                                2
                              )}
                            </p>
                          )}
                        <Bar data={chartDataHour} options={chartOptionsHour} />
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Peak Sales Hour{" "}
                          <TrendingUpIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          The highest sales occur at{" "}
                          <span
                            style={{
                              color: green[500],
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {peak_food_time_hour}
                          </span>
                          , generating a total of{" "}
                          <span
                            style={{
                              color: green[500],
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            ${total_sales_for_peak_hour}
                          </span>
                          .
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      onClick={this.handleShow}
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Weather Details{" "}
                          <WbSunnyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <WeatherForecastModal
                          forecastData={forecastData}
                          show={showWeatherModal}
                          handleClose={this.handleClose}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Average Spend{" "}
                          <FunctionsIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${average_spend.toFixed(2)}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Sales By Items
                          <FastfoodIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        {/* <Card.Text>
                          {"$"}
                          {total_event_sales}
                        </Card.Text> */}
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowMealFreq}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.mealFreq}
                      onHide={this.handleCloseMealFreq}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Meal Order Frequency
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {meal_freq.map((meal, index) => (
                              <tr key={index}>
                                <td>{meal.name}</td>
                                <td>{meal.price}</td>
                                <td>{meal.count}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table> */}
                        <MenuAccordion items={this.state.meal_freq} />
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Sales By Vendors{" "}
                          <LocalShippingIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowSalesByVendors}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.salesByVendors}
                      onHide={this.handleCloseSalesByVendors}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sales By Vendors
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Bar data={chartData} options={chartOptions} />
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Vendor Name</th>
                              <th>Total Sales</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(sales_by_vendor_obj).length > 0 ? (
                              Object.entries(sales_by_vendor_obj)
                                .sort((a, b) => b[1] - a[1]) // Sort the entries by sales in descending order
                                .map(([vendor, sales]) => (
                                  <tr key={vendor}>
                                    <td>{vendor}</td>
                                    <td>{sales.toLocaleString()}</td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan="2">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Sales Insights
                          <InsightsIcon
                            style={{
                              marginLeft: "0.5rem",
                              color: green[500],
                            }}
                          />
                        </Card.Title>

                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${total_event_sales}
                          </span>
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowSalesBreakdown}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.salesBreakdown}
                      onHide={this.handleCloseSalesBreakdown}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sales Breakdown
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {total_event_cash_sales &&
                          total_event_cash_sales !== 0 && (
                            <p>
                              Total Cash Sales: $
                              {Number(total_event_cash_sales).toFixed(2)}
                            </p>
                          )}
                        {total_event_card_sales &&
                          total_event_card_sales !== 0 && (
                            <p>
                              Total Card Sales: $
                              {Number(total_event_card_sales).toFixed(2)}
                            </p>
                          )}
                        {total_event_transaction_fees_sales &&
                          total_event_transaction_fees_sales !== 0 && (
                            <p>
                              Transaction Fee: $
                              {Number(
                                total_event_transaction_fees_sales
                              ).toFixed(2)}
                            </p>
                          )}
                        {total_event_surge_charge_sales &&
                          total_event_surge_charge_sales !== 0 && (
                            <p>
                              Surcharge: $
                              {Number(total_event_surge_charge_sales).toFixed(
                                2
                              )}
                            </p>
                          )}
                        <Bar data={chartDataHour} options={chartOptionsHour} />
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Accepted Vendors
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {this.state.accepted_vendors_list.length}
                          </span>
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleShowAcceptedVendors}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.showAcceptedVendors}
                      onHide={this.handleCloseAcceptedVendors}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Accepted Vendors
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.accepted_vendors_list &&
                            this.state.accepted_vendors_list.length > 0 ? (
                              this.state.accepted_vendors_list.map(
                                (outlet, index) => (
                                  <tr key={index}>
                                    <td>{outlet.accepted_outlets}</td>
                                    <td>{outlet.accepted_outlets__title}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Pending Vendors
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {this.state.pending_list.length}
                          </span>
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleShowPendingVendors}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.showPendingVendors}
                      onHide={this.handleClosePendingVendors}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Pending Vendors
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.pending_list &&
                            this.state.pending_list.length > 0 ? (
                              this.state.pending_list.map((outlet, index) => (
                                <tr key={index}>
                                  <td>{outlet.vendor_outlets}</td>
                                  <td>{outlet.vendor_outlets__title}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Rejected Vendors
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {this.state.rejected_vendors_list.length}
                          </span>
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleShowRejectedVendors}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.showRejectedVendors}
                      onHide={this.handleCloseRejectedVendors}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Rejected Vendors
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.rejected_vendors_list &&
                            this.state.rejected_vendors_list.length > 0 ? (
                              this.state.rejected_vendors_list.map(
                                (outlet, index) => (
                                  <tr key={index}>
                                    <td>{outlet.rejected_outlets}</td>
                                    <td>{outlet.rejected_outlets__title}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Total Payout{" "}
                          <ContactlessIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {payoutDetails &&
                              payoutDetails.total_wtt_payout !== undefined && (
                                <div>{payoutDetails.total_wtt_payout}</div>
                              )}
                          </span>
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowSalesModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Modal
                    show={this.state.salesModal}
                    onHide={this.handleCloseSalesModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Payout Details
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {payoutDetails &&
                        payoutDetails.wtt_card_sales !== undefined && (
                          <p>
                            Cash Sales: $
                            {Number(payoutDetails.wtt_card_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_cash_sales !== undefined && (
                          <p>
                            Cash Sales: $
                            {Number(payoutDetails.wtt_cash_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_voucher_sales !== undefined && (
                          <p>
                            Voucher Sales: $
                            {Number(payoutDetails.wtt_voucher_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_card_commission !==
                          undefined && (
                          <p>
                            Card Commission: $
                            {Number(
                              payoutDetails.total_wtt_card_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_cash_commission !==
                          undefined && (
                          <p>
                            Cash Commission: $
                            {Number(
                              payoutDetails.total_wtt_cash_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_voucher_commission !==
                          undefined && (
                          <p>
                            Voucher Commission: $
                            {Number(
                              payoutDetails.total_wtt_voucher_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_card_sales !== undefined &&
                        payoutDetails.wtt_cash_sales !== undefined &&
                        payoutDetails.wtt_voucher_sales !== undefined && (
                          <p>
                            Total Revenue: $
                            {Number(
                              payoutDetails.wtt_card_sales +
                                payoutDetails.wtt_cash_sales +
                                payoutDetails.wtt_voucher_sales
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_gst !== undefined && (
                          <p>
                            Total GST: $
                            {Number(payoutDetails.total_gst).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_payout !== undefined && (
                          <p>
                            Payout: $
                            {Number(payoutDetails.total_wtt_payout).toFixed(2)}
                          </p>
                        )}
                      {payoutArray.map((payout, index) => (
                        <Accordion
                          key={payout.id}
                          style={{ marginBottom: "10px" }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{
                              backgroundColor: "#2959a5",
                              color: "white",
                            }}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                          >
                            <Typography>
                              Payout {index + 1} - {payout.vendor__title} $
                              {Number(payout.total_wtt_payout).toFixed(2)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component={"span"}>
                              <p>
                                Cash Sales: $
                                {Number(payout.wtt_cash_sales).toFixed(2)}
                              </p>
                              <p>
                                Card Sales: $
                                {Number(payout.wtt_card_sales).toFixed(2)}
                              </p>
                              <p>
                                Voucher Sales: $
                                {Number(payout.wtt_voucher_sales).toFixed(2)}
                              </p>
                              <p>
                                Card Commission: $
                                {Number(
                                  payout.total_wtt_card_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Cash Commission: $
                                {Number(
                                  payout.total_wtt_cash_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Voucher Commission: $
                                {Number(
                                  payout.total_wtt_voucher_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Total GST: $
                                {Number(payout.total_gst).toFixed(2)}
                              </p>
                              <p>
                                Total Payout: $
                                {Number(payout.total_wtt_payout).toFixed(2)}
                              </p>
                              {["1", "2", "3", "4", "5"].map((num) => {
                                if (
                                  payout[`label_misc_charges${num}`] &&
                                  payout[`gross_misc_charges${num}`]
                                ) {
                                  return (
                                    <div key={num}>
                                      <h6>
                                        {payout[`label_misc_charges${num}`]}
                                      </h6>
                                      <p>
                                        Gross: $
                                        {Number(
                                          payout[`gross_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                      <p>
                                        GST: $
                                        {Number(
                                          payout[`gst_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                      <p>
                                        Net: $
                                        {Number(
                                          payout[`net_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Modal.Body>
                  </Modal>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Stock Purchase{" "}
                          <InventoryIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${total_stock_purchased}
                          </span>
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowStockFreq}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.stockFreq}
                      onHide={this.handleCloseStockFreq}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Stock Orders
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stock_freq && stock_freq.length > 0 ? (
                              stock_freq.map((meal, index) => (
                                <tr key={index}>
                                  <td>{meal.name}</td>
                                  <td>{meal.price}</td>
                                  <td>{meal.count}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Wage Bill{" "}
                          <PaymentsIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${Number(totalWages).toFixed(2)}{" "}
                          </span>
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowBillModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.billModal}
                      onHide={this.handleCloseBillModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Bill Details
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h4 style={{ textAlign: "center", margin: "0 auto" }}>
                          Total Cost: {Number(totalWages).toFixed(2)}
                        </h4>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h5>Cost By Day</h5>
                          <MuiTooltip title="This is the daily breakdown total wage cost for the vendor in event.">
                            <InfoIcon
                              className="question-mark"
                              sx={{ height: "15px", marginLeft: "8px" }} // Adjust margin as needed for spacing
                            />
                          </MuiTooltip>
                        </div>
                        <VendorAccordion
                          shiftData={this.state.shiftData}
                          sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h5>Cost By Hour</h5>
                          <MuiTooltip title="This is the hourly breakdown total wage cost for the vendor in event.">
                            <InfoIcon
                              className="question-mark"
                              sx={{ height: "15px", marginLeft: "8px" }} // Adjust margin as needed for spacing
                            />
                          </MuiTooltip>
                        </div>
                        <VendorCostAccordion
                          shiftData={this.state.shiftData}
                          sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                        />
                        {/* {this.renderCostByHourChart()} */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h5>Cost By Staff</h5>
                          <MuiTooltip title="This is the pie chart of wage breakdown of each staffs to the total wages for a vendor.">
                            <InfoIcon
                              className="question-mark"
                              sx={{ height: "15px", marginLeft: "8px" }} // Adjust margin as needed for spacing
                            />
                          </MuiTooltip>
                        </div>
                        <VendorCostPerUserAccordion
                          shiftData={this.state.shiftData}
                          sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                        />
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Event Expenses{" "}
                          <AttachMoneyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowEventExpensesModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.eventExpensesModal}
                      onHide={this.handleCloseEventExpenses}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Event Expenses
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Sub Total</th>
                              <th>Is GST Applied</th>
                              <th>GST</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {event_expenses.length != 0 &&
                              event_expenses.expenses_list.map(
                                (expense, index) => (
                                  <tr key={index}>
                                    <td>{expense.title}</td>
                                    <td>{expense.sub_total}</td>
                                    <td>
                                      {expense.is_gst_applied ? "Yes" : "No"}
                                    </td>
                                    <td>{expense.gst}</td>
                                    <td>{expense.total}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Gross Earnings{" "}
                          <AccountBalanceIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowCompareModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.compareModal}
                      onHide={this.handleCloseCompareModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sales Compared to Expenditure
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>Total Sales: ${total_event_sales}</p>

                        <p>
                          Total Wages: ${Number(totalWages).toFixed(2)} (
                          {(
                            (Number(totalWages) / Number(total_event_sales)) *
                            100
                          ).toFixed(2)}
                          % of Sales)
                        </p>

                        <p>
                          Total Stock Purchased: $
                          {Number(total_stock_purchased).toFixed(2)} (
                          {(
                            (Number(total_stock_purchased) /
                              Number(total_event_sales)) *
                            100
                          ).toFixed(2)}
                          % of Sales)
                        </p>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Event Summary{" "}
                          <SummarizeIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowEventSummaryModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.eventSummaryModal}
                      onHide={this.handleCloseEventSummaryModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Event Summary
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Total Expenses for the Event:{" "}
                          {event_expenses.total_expenses_for_the_event}
                        </p>
                        <p>Total Site Fees: {event_expenses.total_site_fees}</p>
                        <p>
                          Operator: {event_expenses.operator__first_name}{" "}
                          {event_expenses.operator__last_name}
                        </p>
                        <p>Timestamp: {event_expenses.timestamp}</p>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Social Media and Marketing
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "space-evenly",
                            gap: 20,
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              Social Media Posts:{" "}
                              {this.state.social_media.length}
                            </span>
                            <p
                              style={{
                                color: "#2959a5",
                                cursor: "pointer",
                              }}
                              onClick={this.handleShowSocialMediaPosts}
                            >
                              See Social Media Posts
                            </p>
                          </div>
                          <div>
                            <span
                              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              Marketing Reports:{" "}
                              {this.state.marketing_summary.length}
                            </span>
                            <p
                              style={{
                                color: "#2959a5",
                                cursor: "pointer",
                              }}
                              onClick={this.handleShowMarketingSummary}
                            >
                              See Marketing Summary
                            </p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.showSocialMediaPostsModal}
                      onHide={this.handleCloseSocialMediaPosts}
                    >
                      <Modal.Header>
                        <Modal.Title>
                          Promote your event on social media
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <SocialMediaModal
                          selectedEventId={this.state.selectedEventId}
                          onClose={this.handleCloseSocialMediaPosts}
                        />
                      </Modal.Body>
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Social Media Posts
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ListGroup>
                          {this.state.social_media.map((post, index) => (
                            <ListGroup.Item key={index}>
                              <div style={{ padding: 16 }}>
                                <p style={{ fontStyle: "italic" }}>
                                  "{post.summary_of_social_media}"
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2959a5",
                                    }}
                                  >
                                    {post.written_by__first_name}{" "}
                                    {post.written_by__last_name}
                                  </p>
                                  <p style={{ color: "#333" }}>
                                    {post.date_written}
                                  </p>
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <Modal.Header>
                          <Modal.Title
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            Posts Gallery
                          </Modal.Title>
                        </Modal.Header>
                        {this.state.social_media_images ? (
                          <ListGroup
                            style={{
                              maxWidth: "100%",
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              gap: 10,
                            }}
                          >
                            {this.state.social_media_images.map(
                              (post, index) => (
                                <ListGroup.Item key={post.id}>
                                  {/* <div style={{ padding: 16 }}>
                                <p style={{ fontStyle: "italic" }}>
                                  "{post.summary_of_social_media}"
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2959a5",
                                    }}
                                  >
                                    {post.written_by__first_name}{" "}
                                    {post.written_by__last_name}
                                  </p>
                                  <p style={{ color: "#333" }}>
                                    {post.date_written}
                                  </p>
                                </div>
                              </div> */}
                                  <div>
                                    <img
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        objectCover: "cover",
                                      }}
                                      src={AWS_URL + "/" + post.imagefield}
                                      alt={post.id}
                                    />
                                  </div>
                                </ListGroup.Item>
                              )
                            )}
                          </ListGroup>
                        ) : null}
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={this.state.showMarketingSummary}
                      onHide={this.handleCloseMarketingSummary}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Marketing Conclusion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MarketingConclusionModal
                          selectedEventId={this.state.selectedEventId}
                          onClose={this.handleCloseMarketingSummary}
                        />
                      </Modal.Body>

                      <Modal.Header>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Marketing Summary
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ListGroup>
                          {this.state.marketing_summary.map((post, index) => (
                            <ListGroup.Item key={index}>
                              <div style={{ padding: 16 }}>
                                <p style={{ fontStyle: "italic" }}>
                                  "{post.summary_of_marketing}"
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2959a5",
                                    }}
                                  >
                                    {post.written_by__first_name}{" "}
                                    {post.written_by__last_name}
                                  </p>
                                  <p style={{ color: "#333" }}>
                                    {post.date_written}
                                  </p>
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  {/* <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Social Media Promotion{" "}
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleSocialMediaFeedback}
                          >
                            Post Social Media Promotion
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
                {/* <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Marketing Report{" "}
                          <AssessmentIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleMarketingConclusionModal}
                          >
                            Write Marketing Conclusion
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Marketing Summary{" "}
                          <AssessmentIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {this.state.marketing_summary.length}
                          </span>
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleShowMarketingSummary}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.showMarketingSummary}
                      onHide={this.handleCloseMarketingSummary}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Marketing Conclusion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MarketingConclusionModal
                          selectedEventId={this.state.selectedEventId}
                          onClose={this.handleCloseMarketingSummary}
                        />
                      </Modal.Body>

                      <Modal.Header>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Marketing Summary
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ListGroup>
                          {this.state.marketing_summary.map((post, index) => (
                            <ListGroup.Item key={index}>
                              <div style={{ padding: 16 }}>
                                <p style={{ fontStyle: "italic" }}>
                                  "{post.summary_of_marketing}"
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2959a5",
                                    }}
                                  >
                                    {post.written_by__first_name}{" "}
                                    {post.written_by__last_name}
                                  </p>
                                  <p style={{ color: "#333" }}>
                                    {post.date_written}
                                  </p>
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row> */}
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Feedback From Vendor{" "}
                          <FeedbackIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleTruckModalShow}
                          >
                            Ask Truck Manager for feedback
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReport
                            data={shiftData}
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            total_sales_for_peak_hour={
                              total_sales_for_peak_hour
                            }
                            total_event_sales={total_event_sales}
                            total_stock_purchased={total_stock_purchased}
                            average_spend={average_spend}
                            payoutDetails={payoutDetailsArray}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                            meal_freq={this.state.meal_freq}
                            stock_freq={this.state.stock_freq}
                            sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                          />
                        }
                        fileName="event-report.pdf"
                      >
                        {({ loading }) => (
                          <span
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReportWithoutCost
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                            meal_freq={this.state.meal_freq}
                            stock_freq={this.state.stock_freq}
                            sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                          />
                        }
                        fileName="event-report-without-cost.pdf"
                      >
                        {({ loading }) => (
                          <span
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report without Cost"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReportSales
                            data={shiftData}
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            total_sales_for_peak_hour={
                              total_sales_for_peak_hour
                            }
                            total_event_sales={total_event_sales}
                            total_stock_purchased={total_stock_purchased}
                            average_spend={average_spend}
                            payoutDetails={payoutDetailsArray}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                            meal_freq={this.state.meal_freq}
                            stock_freq={this.state.stock_freq}
                            sales_by_vendor_obj={this.state.sales_by_vendor_obj}
                          />
                        }
                        fileName="event-report-sales.pdf"
                      >
                        {({ loading }) => (
                          <span
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report with Sales"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={this.handleOpenReport}
                    style={{ backgroundColor: "#ef3e6d", color: "#ffffff" }}
                  >
                    Configure and Download Report
                  </Button>
                  {this.renderModal()}
                </div>
              </Container>
            </React.Fragment>
            <Footer />
          </>
        ) : (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background
              zIndex: 9999, // Ensure it covers everything
            }}
          >
            <img src={require("../../assets/img/loading.gif")} alt="Loading" />
          </div>
        )}
        <Modal show={this.state.showEventModal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ask Event Organisers for Ratings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleEventOrganiserReminderFormSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Event Organiser Name"
                  value={this.state.event_organiser_name}
                  onChange={(e) =>
                    this.setState({ event_organiser_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Event Organiser email"
                  value={this.state.event_organiser_email}
                  onChange={(e) =>
                    this.setState({ event_organiser_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Event Organiser phone number"
                  value={this.state.event_organiser_phone}
                  onChange={(e) =>
                    this.setState({ event_organiser_phone: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showTruckModal}
          onHide={this.handleTruckModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Feedbacks from Vendor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {feedback_data.map((feedback, index) => (
                <ListGroup.Item key={index}>
                  {/* <strong>{feedback.time_stamp}</strong> */}
                  <ul>
                    {feedback.feedback_lists.map((item, i) => (
                      <li key={i}>
                        <strong>
                          {item.feedback_question}:<br></br>
                        </strong>{" "}
                        {item.feedback_answer}
                      </li>
                    ))}
                  </ul>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Modal.Header closeButton>
              <Modal.Title>Ask Truck Managers for Feedback</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Truck Manager Name"
                  value={this.state.truck_manager_name}
                  onChange={(e) =>
                    this.setState({ truck_manager_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Truck Manager Email"
                  value={this.state.truck_manager_email}
                  onChange={(e) =>
                    this.setState({ truck_manager_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Truck Manager Phone Number"
                  value={this.state.truck_manager_phone}
                  onChange={(e) =>
                    this.setState({ truck_manager_phone: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        {/* <Modal
          show={this.state.showSocialMediaFeedbackModal}
          onHide={this.handleSocialMediaFeedbackModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Promote your event on social media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialMediaModal
              selectedEventId={this.state.selectedEventId}
              onClose={this.handleSocialMediaFeedbackModalClose}
            />
          </Modal.Body>
        </Modal> */}

        <Modal
          show={this.state.showMarketingConclusionModal}
          onHide={this.handleMarketingConclusionModalClose}
        >
          <Modal.Body></Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(EventOrganiserPostEventReport, {
    fallback: <ErrorFallbackComponent />,
  })
);
