import React, { useState } from "react";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import EventInformation from "./EventInformation";
import ExpressionOfIntrest from "./ExpressionOfIntrest";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import { useDispatch } from "react-redux";
import { createEOIEvent } from "../../../actions/EventOrganiser/action";

const EOIPage = ({ handleClose, isOrganiser, nextStep }) => {
  console.log(isOrganiser);
  console.log(nextStep);
  const [openSuccess, setOpenSuccess] = useState(true);
  const [openFail, setOpenFail] = useState(true);
  const dispatch = useDispatch();
  const url = config.BASE_URI + apiPaths.expression;

  const formik = useFormik({
    initialValues: {
      eventNumberOf10Amp: "",
      eventNumberOf15Amp: "",
      eventNumberOf20Amp: "",
      eventNumberOf32Amp: "",
      waterAccess: "",
      bumpInDateTime: "",
      bumpOutDateTime: "",
      eventStartDateTime: "",
      eventEndDateTime: "",
      firstName: "",
      lastName: "",
      service: "",

      eventName: "",
      eventLogo: [],
      email: "",
      numberOfEvents: "",
      numberOfVendors: "",
      abnNumber: "",
      eventContract: [],
      siteArea: "",
      eventLocation: "",
      contactNumber: "",
      eventFile: [],
    },
    initialTouched: {
      eventNumberOf10Amp: false,
      eventNumberOf15Amp: false,
      eventNumberOf20Amp: false,
      eventNumberOf32Amp: false,
      waterAccess: false,
      bumpInDateTime: false,
      bumpOutDateTime: false,
      eventStartDateTime: false,
      eventEndDateTime: false,
      firstName: false,
      lastName: false,
      service: false,

      email: false,
      eventName: false,
      contactNumber: false,
      siteArea: false,
      eventLocation: false,
      abnNumber: false,
      eventFile: false,
      eventLogo: false,
      eventContract: false,
      numberOfVendors: false,
      numberOfEvents: false,
    },

    validate: (values) => {
      const errors = {};

      console.log(values);

      if (!isOrganiser) {
        if (!values.siteArea || values.siteArea.length === 0) {
          errors.siteArea = "*Site Area is Required";
        }
        if (!values.abnNumber || values.abnNumber.length === 0) {
          errors.abnNumber = "* ABN Bumber is Required";
        }
        if (values.abnNumber.length > 11) {
          errors.abnNumber = " *Please enter correct ABN Number";
        }
        if (!values.contactNumber || values.contactNumber.length === 0) {
          errors.contactNumber = "*Last Name is Required";
        }

        if (!values.eventLocation || values.eventLocation.length === 0) {
          errors.eventLocation = "*Company Location is Required";
        }

        if (!values.eventName || values.eventName.length === 0) {
          errors.eventName = "*Event Name is Required";
        }
        if (!values.email || values.email.length === 0) {
          errors.email = "*Email ID is Required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = "*Please enter valid email address";
        }
        if (!values.numberOfEvents || values.numberOfEvents.length === 0) {
          errors.numberOfEvents = "*Number of events is Required";
        }

        if (values.numberOfEvents < 0) {
          errors.numberOfEvents = "*Event number cannot be negative ";
        }

        if (!values.numberOfVendors || values.numberOfVendors.length === 0) {
          errors.numberOfVendors = "*Number of Vendors is Required";
        }

        if (values.numberOfVendors < 0) {
          errors.numberOfVendors = "*Vendor number cannot be negative ";
        }

        if (!values.eventFile || values.eventFile.length === 0) {
          errors.eventFile = "*Organisation terms and conditions is Required";
        }

        if (!values.eventLogo || values.eventLogo.length === 0) {
          errors.eventLogo = "*Organisation logo is Required";
        }
        if (!values.eventContract || values.eventContract.length === 0) {
          errors.eventContract = "*Organisation contract orm is Required";
        }
        return errors;
      } else {
        if (!values.waterAccess || values.waterAccess.length === 0) {
          errors.waterAccess = " * Watter access requirement is required";
        }
        if (!values.siteArea || values.siteArea.length === 0) {
          errors.siteArea = " * Site Area is required";
        }

        if (!values.firstName || values.firstName.length === 0) {
          errors.firstName = " * First Name is required";
        }
        if (!values.lastName || values.lastName.length === 0) {
          errors.lastName = " * last Name is required";
        }

        if (!values.service || values.service.length === 0) {
          errors.service = " * Service is required";
        }

        if (
          !values.eventStartDateTime ||
          values.eventStartDateTime.length === 0
        ) {
          errors.eventStartDateTime = " *Event Time is required";
        }

        if (!values.eventEndDateTime || values.eventEndDateTime.length === 0) {
          errors.eventEndDateTime = " *Event Time is required";
        }

        if (!values.bumpInDateTime || values.bumpInDateTime.length === 0) {
          errors.bumpInDateTime = " *Bump in date time is required";
        }
        if (!values.bumpOutDateTime || values.bumpOutDateTime.length === 0) {
          errors.bumpOutDateTime = " *Bump in date time is required";
        }

        if (!values.siteArea || values.siteArea.length === 0) {
          errors.siteArea = "*Site Area is Required";
        }

        if (!values.abnNumber || values.abnNumber.length === 0) {
          errors.abnNumber = "* ABN Bumber is Required";
        }
        if (values.abnNumber.length > 11) {
          errors.abnNumber = " *Please enter correct ABN Number";
        }
        if (!values.contactNumber || values.contactNumber.length === 0) {
          errors.contactNumber = "*Last Name is Required";
        }

        if (!values.eventName || values.eventName.length === 0) {
          errors.eventName = "*Event Name is Required";
        }
        if (!values.email || values.email.length === 0) {
          errors.email = "*Email is Required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = "*Please enter valid email address";
        }

        if (!values.eventFile || values.eventFile.length === 0) {
          errors.eventFile = "*Organisation terms and conditions is Required";
        }

        if (!values.eventLogo || values.eventLogo.length === 0) {
          errors.eventLogo = "*Organisation logo is Required";
        }
        return errors;
      }
    },

    onSubmit: (values) => {
      const raw = {
        organisation_terms_and_conditions: values.eventFile[0],
        organisation_contract_form: values.eventContract[0],
        organisation_logo: values.eventLogo[0],
        organisation_name: values.eventName,
        event_site_area: values.siteArea,
        organisation_email: values.email,
        organisation_abn_number: values.abnNumber,
        event_site_location: values.eventLocation.formatted_address,
        organisation_phone_number: values.contactNumber,
        organisation_description: values.description,
        number_of_events_planned: values.numberOfEvents,
        number_of_vendors_required: values.numberOfVendors,
      };
      const formData = new FormData();
      Object.entries(raw).map((item) => {
        formData.append(`${item[0]}`, item[1]);
      });

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      if (!isOrganiser) {
        try {
          fetch(url, requestOptions).then((response) => {
            console.log(response);
            if (response.status == 200) {
              alert("Application Successful");
              handleClose();
              setOpenSuccess(true);
            }
          });
        } catch (error) {
          console.log(error);
          handleClose();
          setOpenFail(true);
        }
      } else {
        dispatch(createEOIEvent(values));
        nextStep();
      }
    },

    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Container>
      {/* <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenSuccess(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        Application Successed!
      </Alert> */}
      <form onSubmit={formik.handleSubmit}>
        {isOrganiser ? (
          <ExpressionOfIntrest formik={formik} />
        ) : (
          <EventInformation formik={formik} getOrganiserStatus={isOrganiser} />
        )}

        <Button
          type="submit"
          variant="contained"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {!isOrganiser ? <>SUBMIT APPLICATION</> : <>NEXT</>}
        </Button>
      </form>
    </Container>
  );
};

export default EOIPage;
