import React from "react";

export const CheckBox = (props) => {
  return (
    <li
      style={{ listStyle: "none", textAlign: "left", flexDirection: "column" }}
    >
      <span>
        <input
          style={{}}
          key={props[0].id}
          onClick={props.handleCheckComboElement}
          type="checkbox"
          checked={props[0].isChecked}
          value={props[0].value}
        />{" "}
        {props[0].value}
      </span>
      <span style={{ float: "right" }}>${props[0].price}</span>
    </li>
  );
};

export default CheckBox;
