import React, { Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Footer from "../../components/UI/Footer";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockAdminScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    disapprovedReason: "",
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  fetchPendingOrderFromServer = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_admin_pending",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          console.log(result.data);
          this.setState({ pendingOrder: result.data });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again");
      });
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isAccountantLoggedIn === "true" ||
      isStockAdminLoggedIn === "true"
    ) {
      this.fetchPendingOrderFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  disapprovOrder = (orderId) => {
    const {
      disapprovedReason,
      email,
      password,
      superAdminEmail,
      superAdminPassword,
    } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var data = {};
    if (email != "" && password != "") {
      data = {
        email: email,
        password: password,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    } else if (superAdminEmail != "" && superAdminPassword != "") {
      data = {
        email: superAdminEmail,
        password: superAdminPassword,
        orderId: orderId,
        reason: disapprovedReason,
        query_type: "admin_disapprove_order",
      };
    }
    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Disapproved Succesfully");
          window.location.href = "/stock-admin";
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again!");
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const { pendingOrder } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Pending Orders</h3>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Order Date and Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Stall Name
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Sub Total
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              GST
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col"></Col>
          </Row>
          {pendingOrder.map((item, i) => {
            return (
              <>
                <Accordion key={"main" + item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Order Number:</b>{" "}
                            {item.id}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_date + " " + item.order_time}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.vendor__title}
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Sub Total:</b>$
                            {item.sub_total}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">GST:</b>${item.gst}
                          </span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span>
                            <b className="mobile-only">Total:</b>${item.total}
                            {Number(item.delivery_charge) > 0
                              ? "(inc below min order fee of $" +
                                Number(item.delivery_charge).toFixed(2) +
                                ")"
                              : null}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span>
                            <b className="mobile-only">Status:</b>
                            {item.approved
                              ? "Approved on " +
                                item.approved_date +
                                " " +
                                item.approved_time
                              : "Pending"}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={item.id}
                      style={{ padding: 20, backgroundColor: "#eff2f5" }}
                    >
                      <>
                        <Row className="table-header-row">
                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Id
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Product Name
                          </Col>
                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-header-col"
                          >
                            Qty
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Price
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Sub Total
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            GST
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            lg={2}
                            className="table-header-col"
                          >
                            Total
                          </Col>
                        </Row>
                        {item.orderData.map((product, i) => {
                          return (
                            <>
                              <Row key={"orderData" + i} className="table-row">
                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span className="desktop-only">
                                    {product.id}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  {product.name}
                                </Col>

                                <Col
                                  xs={12}
                                  md={1}
                                  lg={1}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Qty:</b>{" "}
                                    {product.qty}
                                  </span>
                                </Col>

                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Price:</b>{" "}
                                    {product.price}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Sub Total:</b>{" "}
                                    {Number(product.sub_total) > 0
                                      ? Number(product.sub_total).toFixed(2)
                                      : product.total}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">GST:</b>{" "}
                                    {product.gst}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="table-col"
                                >
                                  <span>
                                    <b className="mobile-only">Total:</b>$
                                    {product.total}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <Row className="button-row">
                          <Col xs={12}>
                            <Button
                              onClick={() => {
                                window.location.href =
                                  "/stock-admin-order-edit:" + item.id;
                              }}
                            >
                              Review and Approve
                            </Button>
                          </Col>
                        </Row>
                        <Row className="button-row">
                          <Col xs={6}>OR</Col>
                        </Row>
                        <Row className="button-row">
                          <Col xs={12}>
                            <Form.Group controlId={"formBasicEmail " + item.id}>
                              <Form.Label>Reason for disapproving</Form.Label>
                              <Form.Control
                                type="text"
                                value={this.state.disapprovedReason}
                                onChange={(e) => {
                                  this.setState({
                                    disapprovedReason: e.target.value,
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Button
                              onClick={() => {
                                var result = window.confirm(
                                  "Are you sure? This will disapprove the order. You won't be able to change it."
                                );
                                if (result) {
                                  this.disapprovOrder(item.id);
                                }
                              }}
                            >
                              Disapprove
                            </Button>
                          </Col>
                        </Row>
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
              </>
            );
          })}
        </Container>
        <Footer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
