import React, { useEffect, useState } from "react";
import AdminNavBar from "../../components/UI/AdminNavBar";
//import ReactPaginate from 'react-paginate'

import "./style.css";
import orders from "./orders.js";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  Table,
} from "react-bootstrap";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
function EventOrganiserOrdersScreen() {
  const itemsPerPage = 20;

  const items = orders;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffSet = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffSet);
  };

  return (
    <div>
      <AdminNavBar
        isSuperAdminLoggedIn="false"
        isOperatorLoggedIn="false"
        isOrganiserLoggedIn="true"
        logout="true"
      ></AdminNavBar>

      <div className="m-4">
        <Row>
          <Col xs="12" lg="3">
            <Card bg={"primary"} lassName="mb-2" text="white">
              <Card.Body>
                <Card.Title> Filters </Card.Title>
                <Card.Text>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Select Vendor:</Form.Label>
                    <Form.Control as="select" size="sm">
                      <option value={0}>All</option>
                      <option value={0}>Real OG</option>
                      <option value={0}>Mary and the Lamb</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Select Day:</Form.Label>
                    <Form.Control as="select" size="sm">
                      <option value={0}>27th May</option>
                      <option value={0}>26th May</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row className="table-header-row">
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Order #
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Order Date
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Order Time
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Customer Name
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Outlet Name
              </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Payment Receipt Number
              </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Status
              </Col>
            </Row>
            {currentItems.map((item, i) => {
              return (
                <>
                  <Accordion key={item.id}>
                    <>
                      <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Order #:</b> {item.id}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Order Date:</b>
                              {item.order_date}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Order Time:</b>
                              {item.order_time}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Customer Name:</b>
                              {item.customer_name}
                            </span>
                            <br></br>
                            <span>
                              <b className="mobile-only">Customer Phone:</b>
                              {item.customer__phone}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Vendor Name:</b>
                              {item.vendor}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">
                                Payment Receipt Number:
                              </b>
                              {item.payment_receipt_number}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="mobile-only">
                            <span>
                              <b className="mobile-only">Order Status:</b>
                            </span>
                          </Col>
                          {/* <Col xs={12} md={1} lg={1} className="table-col" style={{ paddingLeft: 2, paddingRight: 2 }}>
                                                            {availableStatus && availableStatus.length > 0 ?
                                                                <>
                                                                    {isLoadingChangeOrderStatus ?
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="sr-only">Loading...</span>
                                                                            </Spinner>
                                                                        </div>
                                                                        :
                                                                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                                                            <Form.Control as="select" size="sm" custom onChange={(e) => { this.onChangeOrderStatus(e, item) }} defaultValue={item.status}>
                                                                                {availableStatus.map((status, i) => {
                                                                                    return (
                                                                                        <option key={i} value={status}>{status}</option>

                                                                                    )
                                                                                })}
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    }
                                                                </>
                                                                : null}


                                                        </Col> */}
                          <Col xs={12} md={2} lg={2} className="mobile-only">
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: "#f25b4b",
                                borderRadius: 40,
                                marginBottom: 10,
                                color: "white",
                              }}
                            >
                              <b className="mobile-only">
                                Click here to see more details
                              </b>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey={item.id}
                        style={{ padding: 20, backgroundColor: "#eff2f5" }}
                      >
                        <>
                          {item.meal &&
                            item.meal.map((meal, j) => {
                              return (
                                <Row>
                                  <Col
                                    xs={6}
                                    md={6}
                                    lg={6}
                                    className="table-col"
                                    style={{ border: "none" }}
                                  >
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        {meal.meal__name}
                                      </Col>
                                    </Row>
                                    {meal.subitem != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          {meal.subitem}
                                        </Col>
                                      </Row>
                                    ) : null}
                                    {meal.special_request != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          {meal.special_request}
                                        </Col>
                                      </Row>
                                    ) : null}
                                    {meal.combo_array &&
                                      meal.combo_array.map((combo) => {
                                        return (
                                          <Row>
                                            <Col xs={12} md={12} lg={12}>
                                              {combo.name}
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                  </Col>
                                  <Col
                                    xs={3}
                                    md={3}
                                    lg={3}
                                    className="table-col"
                                    style={{ border: "none" }}
                                  >
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        x{meal.quantity}
                                      </Col>
                                    </Row>
                                    {meal.subitem != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          <p></p>
                                        </Col>
                                      </Row>
                                    ) : null}
                                    {meal.special_request != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          <p></p>
                                        </Col>
                                      </Row>
                                    ) : null}

                                    {meal.combo_array &&
                                      meal.combo_array.map((combo) => {
                                        return (
                                          <Row>
                                            <Col xs={12} md={12} lg={12}>
                                              x{meal.quantity}
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                  </Col>
                                  <Col
                                    xs={3}
                                    md={3}
                                    lg={3}
                                    className="table-col"
                                    style={{ border: "none" }}
                                  >
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        ${Number(meal.meal__price).toFixed(2)}
                                      </Col>
                                    </Row>
                                    {meal.subitem != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          <p></p>
                                        </Col>
                                      </Row>
                                    ) : null}
                                    {meal.special_request != "" ? (
                                      <Row style={{ padding: 20 }}>
                                        <Col xs={12} md={12} lg={12}>
                                          <p></p>
                                        </Col>
                                      </Row>
                                    ) : null}

                                    {meal.combo_array &&
                                      meal.combo_array.map((combo) => {
                                        return (
                                          <Row>
                                            <Col xs={12} md={12} lg={12}>
                                              ${Number(combo.price).toFixed(2)}
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                  </Col>
                                </Row>
                              );
                            })}
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Sub Total
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            ></Col>

                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              ${Number(item.sub_total).toFixed(2)}
                            </Col>
                          </Row>
                          {item.delivery_charge &&
                          Number(item.delivery_charge) > 0 ? (
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={6}
                                className="table-header-col"
                                style={{ border: "none" }}
                              >
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    Delivery Charges
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                xs={3}
                                md={3}
                                lg={3}
                                className="table-header-col"
                                style={{ border: "none" }}
                              ></Col>
                              <Col
                                xs={3}
                                md={3}
                                lg={3}
                                className="table-header-col"
                                style={{ border: "none" }}
                              >
                                ${Number(item.delivery_charge).toFixed(2)}
                              </Col>
                            </Row>
                          ) : null}
                          {item.discount && Number(item.discount) > 0 ? (
                            <Row>
                              <Col
                                xs={6}
                                md={6}
                                lg={6}
                                className="table-header-col"
                                style={{ border: "none" }}
                              >
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    Discount
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                xs={3}
                                md={3}
                                lg={3}
                                className="table-header-col"
                                style={{ border: "none" }}
                              ></Col>
                              <Col
                                xs={3}
                                md={3}
                                lg={3}
                                className="table-header-col"
                                style={{ border: "none" }}
                              >
                                ${Number(item.discount).toFixed(2)}
                              </Col>
                            </Row>
                          ) : null}
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Total
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            ></Col>
                            <Col
                              xs={3}
                              md={3}
                              lg={3}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              ${Number(item.payment_total_amount).toFixed(2)}
                            </Col>
                          </Row>
                          <hr></hr>

                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Customer Name
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              {item.customer_name}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Customer Phone
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              {item.customer__phone}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Customer Email
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              {item.customer__email}
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Order Type
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="table-header-col"
                              style={{ border: "none" }}
                            >
                              {item.order_type}
                            </Col>
                          </Row>
                        </>
                      </Accordion.Collapse>
                    </>
                  </Accordion>
                </>
              );
            })}
          </Col>
        </Row>
      </div>
      <Row>
        <Col></Col>
        <Col>
          {/* <ReactPaginate
                        breakLabel=".."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"

                    /> */}
        </Col>
      </Row>
    </div>
  );
}
export default Sentry.withErrorBoundary(EventOrganiserOrdersScreen, {
  fallback: <ErrorFallbackComponent />,
});
