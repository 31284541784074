import React, { Component } from "react";
import Footer from "../../components/UI/Footer";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import Data from "./Data.js";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { AWS_URL } from "../../assets/Constants";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

class ProfilePage extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {}

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { events, isLoading } = this.state;
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    return (
      <div>
        <ResponsiveNavBar
          showBack={false}
          backUrl={""}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        {!isLoading ? (
          <>
            <Container>
              <h3 style={{ textAlign: "center" }}>Profile</h3>
              <div className="form-style-5 content">
                <legend>
                  <span className="number">Customer Details</span>
                </legend>
                <Data />
              </div>
            </Container>
            <Footer />
          </>
        ) : (
          <>
            <div style={{ textAlign: "center" }}>
              <img src={require("../../assets/img/loading.gif")}></img>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(ProfilePage, {
    fallback: <ErrorFallbackComponent />,
  })
);
