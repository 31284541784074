import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import * as Sentry from "@sentry/react";
import { apiPaths } from "../../services/apiPath";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import api from "../../services/api";

import config from "../../services/apiConfig";
import moment from "moment";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/UI/Button/index";
import {
  sendSms,
  loginPhone,
  getUserProfile,
  updateCartTicket,
  updateCartTotal,
  updateOrderType,
  updateOrderLater,
  updateOrderLocation,
  changeTab,
  calculateDelivery,
  getDelivery,
  updateDeliveryCharges,
  orderStatus,
  getPromoCode,
  applyPromoCode,
  updateOrderDate,
  updateOrderTime,
  validatePromoCode,
  updateSelectedVendor,
  clearSelectedVendor,
  logout,
  updateUser,
} from "../../actions";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { applyToken } from "../../services/api";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@material-ui/core/TextField";
import { Bar, Pie } from "react-chartjs-2";
import { ShareSocial } from "react-share-social";

class PollScreen extends Component {
  state = {
    question:
      "Vote for your favorite cusine and we will send a truck of same cuisine that is the winner!",
    availableCuisines: [
      {
        name: "Mexican",
      },
      {
        name: "Greek",
      },
      {
        name: "American",
      },
      {
        name: "Italian",
      },
    ],
    availableCuisinesLabels: ["Mexican", "Greek", "American", "Italian"],
    availableCuisinesVotes: [0, 0, 0, 0],
    selectedPollId: -1,
    votingEndDate: "",
    selectedCuisine: "",
  };

  componentDidMount() {
    var pollId = window.location.href.substring(
      window.location.href.lastIndexOf(":") + 1
    );
    this.setState({ selectedPollId: pollId }, () => {
      this.getVotingData(pollId);
    });
  }

  getVotingData = (pollId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-voting-data",
          poll_id: pollId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            availableCuisines: response.availableCuisines,
            availableCuisinesLabels: response.availableCuisinesLabels,
            availableCuisinesVotes: response.availableCuisinesVotes,
            votingEndDate: response.voting_end_date,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  _onOptionChange(option) {
    this.setState({
      selectedCuisine: option,
    });
  }

  submiteVote = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "cast-vote",
          poll_id: this.state.selectedPollId,
          cuisine: this.state.selectedCuisine,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          alert("Thank you! Your vote has been casted successfully!");
          window.location.reload();
        }
      });
  };

  render() {
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    const {
      question,
      availableCuisines,
      availableCuisinesLabels,
      availableCuisinesVotes,
      votingEndDate,
      selectedPollId,
    } = this.state;

    var diff = moment(votingEndDate, "DD MMM YYYY hh:mm A").diff(
      moment(new Date()),
      "minute"
    );
    return (
      <div>
        <ResponsiveNavBar
          showBack={false}
          backUrl={"/book-a-truck"}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        <Container>
          <Row style={{ marginTop: 30 }}>
            <Col xs={12} md={12} lg={12}>
              <h3>
                Share the page with your friends and family and ask them to
                vote.
              </h3>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <p>
                Voting is open till {votingEndDate}. You can check the live
                results on the same page.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <p>{question}</p>
                </Col>
              </Row>
              <Row>
                {availableCuisines.map((item) => {
                  return (
                    <Col xs={12} md={12} lg={12}>
                      <InputGroup>
                        <InputGroup.Radio
                          aria-label="Radio button for following text input"
                          checked={this.state.selectedCuisine === item.name}
                          onClick={(e) => {
                            this._onOptionChange(item.name);
                          }}
                        />
                        <FormControl
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          aria-label="Text input with radio button"
                          value={item.name}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  );
                })}
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} lg={12}>
                  <Button
                    style={{ width: "50%", margin: "auto", marginBottom: 20 }}
                    variant="primary"
                    onClick={() => {
                      if (diff >= 0) {
                        this.submiteVote();
                      }
                    }}
                  >
                    {diff >= 0 ? "Submit" : "Voting Closed"}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Share it with your friends</h4>
                  <ShareSocial
                    style={{ padding: 0 }}
                    url={window.location.href}
                    socialTypes={["facebook", "email", "linkedin", "twitter"]}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <Bar
                data={{
                  labels: availableCuisinesLabels,
                  datasets: [
                    {
                      label: "Voting Results",
                      data: availableCuisinesVotes,
                      backgroundColor: this.getBackgroundColor(
                        availableCuisinesLabels.length
                      ),
                      borderWidth: 1,
                    },
                  ],
                }}
                height={400}
                width={600}
                type={"horizontalBar"}
                options={{
                  indexAxis: "y",
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(PollScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
