import React, {useState} from 'react'

const TableBody = ({ sortedTableData, columns }) => {
    
    return (
     <tbody>
      {sortedTableData.map((data) => {
       return (
        <tr key={data.id}>
         {columns.map(({ accessor }) => {
          const tData = data[accessor] ? data[accessor] : "——";

          if(accessor === 'percentage') 
            return <td key={accessor}>{tData}%</td>
          
          if(accessor === 'price')
            return <td key={accessor}>${tData}</td>

          return <td key={accessor}>{tData}</td>;
         })}
        </tr>
       );
      })}
     </tbody>
    );
   };

   export default TableBody