import React, { useEffect } from "react";
import "./App.css";
import DashboardScreen from "./screens/DashboardScreen/";
import { connect } from "react-redux";
import { firebaseConfig } from "./services/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "./screens/ErrorFallBackScreen";
import { useHistory, useLocation } from "react-router-dom"; // Import useLocation
import {
  matchRoutes, // Import matchRoutes
} from "react-router-config";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const App = (props) => {
  const history = useHistory();
  Sentry.init({
    dsn:
      "https://223503d580f24804bc9d03108e49156d@o4505108117782528.ingest.sentry.io/4505108131741696",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  useEffect(() => {
    // Log a page_view event when the component mounts
    logEvent("page_view", {
      page_path: history.location.pathname,
    });
  }, [history]);

  window.location.href = "/events";
  return <div>Redirecting...</div>;
};
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    location: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    profileModalToggle: state.appReducer.profileModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    products: state.appReducer.products,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(App, { fallback: <ErrorFallbackComponent /> })
);
