import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const EditableForm = ({ selectedVendorList, selectedEventId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [formDataPreview, setFormDataPreview] = useState({});

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      eventType: "", // Provide default value as needed
      eventDate: null,
      eventStartTime: "", // Format "HH:MM"
      bumpInEndTime: "",
      runSheetStatus: "", // Provide default value as needed
      location: "",
      eventAttendance: "",
      isFullDayEvent: false,
      eventServiceFee: 0,
      areExtraItemsAllowed: false,
      bumpInDate: null,
      bumpInStartTime: "",
      bumpInEndTime: "",
      bumpOutDate: null,
      bumpOutStartTime: "",
      bumpOutEndTime: "",
      truckManager: "",
      siteContact: "",
      siteContactNumber: "",
      extendCutoffBy: 0,
      power: "",
      otherNotes: "",
      truckManagerEmail: "",
      beverageNotes: "",
      vouchersNote: "",
      bumpInOutNotes: "",
      multipleDayTradingOptions: "",
      multipleDayTradingTimeOptions: "",
      firstAdditionalDocumentName: "",
      secondAdditionalDocumentName: "",
      thirdAdditionalDocumentName: "",
    },
  });

  function convertTo12HourFormat(time) {
    if (!time || time.split(":").length !== 2) {
      return ""; // Return an empty string or a default value if the input is invalid
    }

    const [hours24, minutes] = time.split(":");
    if (isNaN(parseInt(hours24, 10)) || isNaN(parseInt(minutes, 10))) {
      return ""; // Return an empty string or a default value if hours or minutes are not numbers
    }

    const hours = parseInt(hours24, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12; // Convert "0" to "12"

    return `${hours12}:${minutes} ${ampm}`;
  }
  const onSubmit = async (data) => {
    data.bumpOutStartTime = convertTo12HourFormat(data.bumpOutStartTime);
    data.bumpInStartTime = convertTo12HourFormat(data.bumpInStartTime);
    data.bumpOutEndTime = convertTo12HourFormat(data.bumpOutEndTime);
    data.bumpInEndTime = convertTo12HourFormat(data.bumpInEndTime);
    setFormDataPreview(data);
    setOpenDialog(true);
  };
  const formatKey = (key) => {
    // Split the camelCase string into words and capitalize the first letter of each word
    const formatted = key
      .replace(/([A-Z])/g, " $1") // Insert space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim(); // Remove any leading/trailing whitespace

    return formatted;
  };
  const handleDialogConfirm = async () => {
    // Close the dialog
    setOpenDialog(false);

    const formData = new FormData();
    const data = formDataPreview;

    // Append text and number fields to FormData, formatting dates with moment
    Object.keys(data).forEach((key) => {
      if (
        key === "bumpInDate" ||
        key === "bumpOutDate" ||
        key === "eventDate"
      ) {
        // Check if the value is not null or undefined and is a valid date
        if (data[key] && moment(data[key]).isValid()) {
          // Format and append date fields
          formData.append(key, moment(data[key]).format("DD MMM YYYY"));
        } else {
          // If the date is not set or invalid, append an empty string
          formData.append(key, "");
        }
      } else if (!(data[key] instanceof FileList)) {
        // Append non-file fields directly
        formData.append(key, data[key]);
      }
    });
    formData.append("selectedVendorList", JSON.stringify(selectedVendorList));
    formData.append("selectedEventId", selectedEventId);
    formData.append(
      "eventOrganiserResponsible",
      JSON.parse(getFromLocalStorage("Organiser"))[0].id
    );

    // Append file inputs to FormData
    if (
      data.firstAdditionDocumentOption &&
      data.firstAdditionDocumentOption.length > 0
    ) {
      formData.append(
        "firstAdditionDocumentOption",
        data.firstAdditionDocumentOption[0]
      );
    }
    if (
      data.secondAdditionDocumentOption &&
      data.secondAdditionDocumentOption.length > 0
    ) {
      formData.append(
        "secondAdditionDocumentOption",
        data.secondAdditionDocumentOption[0]
      );
    }
    if (
      data.thirdAdditionDocumentOption &&
      data.thirdAdditionDocumentOption.length > 0
    ) {
      formData.append(
        "thirdAdditionDocumentOption",
        data.thirdAdditionDocumentOption[0]
      );
    }
    console.log(formData);

    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.createRunsheet,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle response
      if (response.data.success) {
        alert("Runsheets created successfully!");
        // Optionally, handle additional success actions like redirecting
      } else {
        alert("Error please try again!");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("Error please try again!");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        {/* Dynamic form fields rendered here */}
        <select {...register("eventType")} className="form-field">
          <option value="Food Truck In My Street">
            Food Truck In My Street
          </option>
          <option value="Private Function">Private Function</option>
          <option value="Public Event">Public Event</option>
          <option value="Special Event">Special Event</option>
        </select>
        <div className="form-field">
          <label htmlFor="eventDate" className="form-label">
            Event Date:
          </label>
          <div className="date-picker-container">
            <Controller
              name="eventDate"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select event date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  dateFormat="dd MMM yyyy"
                />
              )}
            />
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="eventStartTime" className="form-label">
            Event Start Time:
          </label>
          <input
            type="time"
            id="eventStartTime"
            {...register("eventStartTime")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpInEndTime" className="form-label">
            Event End Time:
          </label>
          <input
            type="time"
            id="bumpInEndTime"
            {...register("bumpInEndTime")}
            className="form-input"
          />
        </div>
        <select {...register("runSheetStatus")} className="form-field">
          <option value="Job pending: Enquiry made - awaiting confirmation from the client">
            Job pending: Enquiry made - awaiting confirmation from the client
          </option>
          <option value="Job confirmed - awaiting client payment">
            Job confirmed - awaiting client payment
          </option>
          <option value="Job confirmed - awaiting Runsheet information">
            Job confirmed - awaiting Runsheet information
          </option>
          <option value="Job confirmed - Runsheet complete - ready to print">
            Job confirmed - Runsheet complete - ready to print
          </option>
          <option value="Job confirmed - Runsheet complete and payment received - ready to print">
            Job confirmed - Runsheet complete and payment received - ready to
            print
          </option>
          <option value="Job Cancelled by organiser">
            Job Cancelled by organiser
          </option>
          <option value="Job cancelled by client">
            Job Cancelled by client
          </option>
        </select>
        <div className="form-field">
          <label htmlFor="location" className="form-label">
            Location:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter Location here..."
            rows="4"
            cols="75"
            id="location"
            name="location"
            {...register("location")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="eventAttendance" className="form-label">
            Event Attendance:
          </label>
          <input
            type="tel"
            id="eventAttendance"
            {...register("eventAttendance")}
            className="form-input"
          />
        </div>
        <div className="form-checkbox-container">
          <label htmlFor="isFullDayEvent" className="form-label">
            Is this a Full Day Event?:
          </label>
          <input
            type="checkbox"
            id="isFullDayEvent"
            {...register("isFullDayEvent")}
            className="form-checkbox"
          />
        </div>
        <div className="form-field">
          <label htmlFor="eventServiceFee" className="form-label">
            Event Service Fee:
          </label>
          <input
            type="tel"
            id="eventServiceFee"
            {...register("eventServiceFee")}
            className="form-input"
          />
        </div>
        <div className="form-checkbox-container">
          <label htmlFor="areExtraItemsAllowed" className="form-label">
            Are Extra Items Allowded?:
          </label>
          <input
            type="checkbox"
            id="areExtraItemsAllowed"
            {...register("areExtraItemsAllowed")}
            className="form-checkbox"
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpInDate" className="form-label">
            Bump In Date:
          </label>
          <Controller
            name="bumpInDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                placeholderText="Select bump in date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd MMM yyyy"
                className="form-input" // Apply the same class as other inputs
              />
            )}
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpInStartTime" className="form-label">
            Bump In Start Time:
          </label>
          <input
            type="time"
            id="bumpInStartTime"
            {...register("bumpInStartTime")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpInEndTime" className="form-label">
            Bump In End Time:
          </label>
          <input
            type="time"
            id="bumpInEndTime"
            {...register("bumpInEndTime")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpOutDate" className="form-label">
            Bump Out Date:
          </label>
          <Controller
            name="bumpOutDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                placeholderText="Select bump out date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd MMM yyyy"
                className="form-input" // Apply the same class as other inputs
              />
            )}
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpOutStartTime" className="form-label">
            Bump Out Start Time:
          </label>
          <input
            type="time"
            id="bumpOutStartTime"
            {...register("bumpOutStartTime")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="bumpOutEndTime" className="form-label">
            Bump Out End Time:
          </label>
          <input
            type="time"
            id="bumpOutEndTime"
            {...register("bumpOutEndTime")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="truckManager" className="form-label">
            Truck Manager:
          </label>
          <input
            type="text"
            id="truckManager"
            {...register("truckManager")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="siteContact" className="form-label">
            Site Contact:
          </label>
          <input
            type="text"
            id="siteContact"
            {...register("siteContact")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="siteContactNumber" className="form-label">
            Site Contact Number:
          </label>
          <input
            type="tel"
            id="siteContactNumber"
            {...register("siteContactNumber")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="extendCutoffBy" className="form-label">
            Extend cut off by:
          </label>
          <input
            type="tel"
            id="extendCutoffBy"
            {...register("extendCutoffBy")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="power" className="form-label">
            Power:
          </label>
          <input
            type="text"
            id="power"
            {...register("power")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="otherNotes" className="form-label">
            Other Notes:
          </label>
          <textarea
            id="otherNotes"
            {...register("otherNotes")}
            className="form-textarea"
            placeholder="Enter other notes here..."
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="truckManagerEmail" className="form-label">
            Truck Manager Email:
          </label>
          <input
            type="email"
            id="truckManagerEmail"
            {...register("truckManagerEmail")}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label htmlFor="beverageNotes" className="form-label">
            Beverage Notes:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter beverage notes here..."
            rows="4"
            cols="75"
            id="beverageNotes"
            name="beverageNotes"
            {...register("beverageNotes")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="vouchersNote" className="form-label">
            Vouchers Note:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter vouchers note here..."
            rows="4"
            cols="75"
            id="vouchersNote"
            name="vouchersNote"
            {...register("vouchersNote")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="bumpInOutNotes" className="form-label">
            Bump In/Out Notes:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter bump in/out notes here..."
            rows="4"
            cols="75"
            id="bumpInOutNotes"
            name="bumpInOutNotes"
            {...register("bumpInOutNotes")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="multipleDayTradingOptions" className="form-label">
            Multiple Day Trading Options:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter multiple day trading options here..."
            rows="4"
            cols="75"
            id="multipleDayTradingOptions"
            name="multipleDayTradingOptions"
            {...register("multipleDayTradingOptions")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="multipleDayTradingTimeOptions" className="form-label">
            Multiple Day Trading Time Options:
          </label>
          <textarea
            className="form-textarea"
            placeholder="Enter multiple day trading time options here..."
            rows="4"
            cols="75"
            id="multipleDayTradingTimeOptions"
            name="multipleDayTradingTimeOptions"
            {...register("multipleDayTradingTimeOptions")}
          ></textarea>
        </div>
        <div className="form-field">
          <label htmlFor="firstAdditionDocumentOption" className="form-label">
            First addition document Name:
          </label>
          <input
            placeholder="Document Name:"
            type="text"
            id="firstAdditionalDocumentName"
            name="firstAdditionalDocumentName"
            {...register("firstAdditionalDocumentName")}
          />
        </div>
        <div className="form-field">
          <label htmlFor="firstAdditionDocumentOption" className="form-label">
            First addition document option:
          </label>
          <input
            className="form-input"
            type="file"
            id="firstAdditionDocumentOption"
            name="firstAdditionDocumentOption"
            {...register("firstAdditionDocumentOption")}
          />
        </div>
        <div className="form-field">
          <label htmlFor="secondAdditionDocumentOption" className="form-label">
            Second addition document Name:
          </label>
          <input
            placeholder="Document Name:"
            type="text"
            id="secondAdditionalDocumentName"
            name="secondAdditionalDocumentName"
            {...register("secondAdditionalDocumentName")}
          />
        </div>
        <div className="form-field">
          <label htmlFor="secondAdditionDocumentOption" className="form-label">
            Second addition document option:
          </label>
          <input
            className="form-input"
            type="file"
            id="secondAdditionDocumentOption"
            name="secondAdditionDocumentOption"
            {...register("secondAdditionDocumentOption")}
          />
        </div>
        <div className="form-field">
          <label htmlFor="thirdAdditionDocumentOption" className="form-label">
            Third addition document Name:
          </label>
          <input
            placeholder="Document Name:"
            type="text"
            id="thirdAdditionalDocumentName"
            name="thirdAdditionalDocumentName"
            {...register("thirdAdditionalDocumentName")}
          />
        </div>
        <div className="form-field">
          <label htmlFor="thirdAdditionDocumentOption" className="form-label">
            Third addition document option:
          </label>
          <input
            className="form-input"
            type="file"
            id="thirdAdditionDocumentOption"
            name="thirdAdditionDocumentOption"
            {...register("thirdAdditionDocumentOption")}
          />
        </div>
        <div className="button-container">
          <button
            type="submit"
            style={{
              backgroundColor: "#2959a5",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              cursor: "pointer",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Submit
          </button>
        </div>
      </form>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to generate runsheets with the following
            details?
          </DialogContentText>
          <div style={{ marginTop: 16 }}>
            {Object.entries(formDataPreview).map(([key, value]) => {
              // Format the key to a more readable form
              const formattedKey = formatKey(key);

              // Handle different data types
              let displayValue = value;
              if (Array.isArray(value)) {
                // For arrays, join the elements into a string
                displayValue = value.join(", ");
              } else if (value instanceof Date) {
                // For date objects, format the date
                displayValue = moment(value).format("DD MMM YYYY");
              } else if (typeof value === "boolean") {
                // For booleans, convert to Yes/No
                displayValue = value ? "Yes" : "No";
              } else if (
                key.includes("DocumentOption") &&
                typeof value === "object"
              ) {
                // For file inputs
                displayValue = "(binary data)";
              }

              // Skip null, undefined, or empty string values
              if (value === null || value === undefined || value === "") {
                return null;
              }

              return (
                <div key={key} style={{ marginBottom: 8 }}>
                  <strong>{formattedKey}:</strong> {displayValue}
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDialogConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditableForm;
