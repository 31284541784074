import React, { Component } from "react";
import Container from "@mui/material/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseIcon from "@mui/icons-material/Close";
import VendorCateringAddScreen from "../VendorCateringAddScreen";
import VendorCateringEditScreen from "../VendorCateringEditScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import EditableForm from "./EditableForm";
import { getFromLocalStorage, saveToLocalStorage } from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

class OperatorDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator_id: null,
      isLoading: false,
      tempPackDetails: [],
      showVendorCateringAddScreen: false,
      showVendorCateringEditScreen: false,
      mealProp: [],
      packDetails: [],
      isLoading: false,
      selectedBrandMeals: [],
      tempSelectedBrandMeals: [],
      selectedVendorId: -1,
      selectedVendorName: "",
    };
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.getDocuments();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  getDocuments() {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_vendor_documents",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            this.setState({
              packDetails: dataResponse.document_details,
              tempPackDetails: dataResponse.document_details,
            });
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  }

  getCateringPack() {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_catering_packages",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            this.setState({
              packDetails: dataResponse.menu_details,
              tempPackDetails: dataResponse.menu_details,
            });
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      packDetails,
      selectedBrandMeals,
      isLoading,
      selectedVendorId,
    } = this.state;
    return (
      <div>
        <VendorNavBar />
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          {isLoading == false ? (
            <Row>
              <Col
                xs={12}
                md={3}
                lg={3}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderStyle: "solid",
                  borderColor: "black",
                  padding: 0,
                }}
              >
                <div>
                  <h3
                    style={{
                      padding: 20,
                      borderBottomWidth: 1,
                      borderTopWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                  >
                    Trucks
                  </h3>
                </div>
                {packDetails &&
                  packDetails.map((item) => {
                    return (
                      <div
                        key={item.stalls__id}
                        style={
                          selectedVendorId == item.stalls__id
                            ? {
                                backgroundColor: "white",
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                            : {
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                        }
                        onClick={() => {
                          console.log(item);
                          this.setState(
                            {
                              selectedBrandMeals: item.documents,
                              tempSelectedBrandMeals: item.documents,
                              selectedVendorId: item.stalls__id,
                              selectedVendorName: item.stalls__title,
                              showVendorCateringAddScreen: false,
                              showVendorCateringEditScreen: false,
                            },
                            () =>
                              saveToLocalStorage(
                                "selectedVendorId",
                                item.stalls__id
                              )
                          );
                        }}
                      >
                        {item.stalls__title}
                      </div>
                    );
                  })}
              </Col>
              <Col xs={12} md={9} lg={9} className="mobile-padding">
                <Row className="mobile-only">
                  <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <h4>
                      <b>selectedVendorName</b>
                    </h4>
                  </Col>
                </Row>
                {this.state.showVendorCateringAddScreen && (
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 1, float: "right" }}
                    onClick={() =>
                      this.setState({ showVendorCateringAddScreen: false })
                    }
                  />
                )}
                {this.state.showVendorCateringAddScreen && (
                  <VendorCateringAddScreen
                    stallId={this.state.selectedVendorId}
                  />
                )}
                {this.state.showVendorCateringEditScreen && (
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 1, float: "right" }}
                    onClick={() =>
                      this.setState({ showVendorCateringEditScreen: false })
                    }
                  />
                )}
                {this.state.showVendorCateringEditScreen && (
                  <VendorCateringEditScreen
                    stallId={this.state.selectedVendorId}
                    menu={this.state.mealProp}
                  />
                )}
                {console.log(selectedBrandMeals)}
                {selectedBrandMeals && selectedBrandMeals.length > 0 ? (
                  <Row>
                    <EditableForm selectedBrandMeals={selectedBrandMeals[0]} />
                  </Row>
                ) : (
                  <div>No results</div>
                )}
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
}

export default Sentry.withErrorBoundary(OperatorDocuments, {
  fallback: <ErrorFallbackComponent />,
});
