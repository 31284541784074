import React from "react";
import { Form, Accordion, Card } from "react-bootstrap";
import SelectMenus from "../SelectMenu";
import "../../style.css";

const WaterRequirements = (props) => {
  const { formik, viewOnly } = props;

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        style={{ color: formik.errors.waterAccess ? "red" : "black" }}
      >
        3. WATER REQUIREMENTS
        {formik.touched.waterAccess && formik.errors.waterAccess ? (
          <div style={{ color: "red", fontWeight: "bold" }}>
            {"Information Missing"}
          </div>
        ) : null}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            {!viewOnly ? (
              <div className="title-heading">
                <Form.Label>
                  <h6>Water Access*</h6>
                  {formik.touched.waterAccess && formik.errors.waterAccess ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.waterAccess}
                    </div>
                  ) : null}
                </Form.Label>
                <SelectMenus
                  values={[
                    "",
                    "Not Required",
                    "Self sufficient",
                    "Bucket/Barrel fill",
                    "Hose connection",
                  ]}
                  labels={[
                    "Please Select",
                    "Not Required",
                    "Self Sufficient",
                    "Bucket / Barrel Fill",
                    "Hose Connection",
                  ]}
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"waterAccess"}
                  defaultValue={formik.values.waterAccess}
                />
                <Form.Text
                  style={{ color: "blue", fontSize: "1.2rem" }}
                  className="font-weight-bold"
                >
                  Please note, retail cannot apply for Water Access
                </Form.Text>
              </div>
            ) : (
              <Form.Text
                style={{ color: "blue", fontSize: "1.2rem" }}
                className="font-weight-bold"
              >
                Water Requirement: {formik.values.waterAccess}
              </Form.Text>
            )}
          </Form.Group>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default WaterRequirements;
