import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../store";
import "./SearchBar.css";

const SearchBar = ({
  eventList,
  placeHolder,
  setFilteredEvents,
  selectedEventId,
  selectedEventSearch,
}) => {
  const [inputValue, setInputValue] = useState(selectedEventSearch);
  const handleChange = (value) => {
    setInputValue(value);
    searchData(value);
  };
  useEffect(() => {
    setInputValue(selectedEventSearch); // Set the input value to the selected event ID
  }, [selectedEventSearch]);

  const searchData = (value) => {
    console.log(eventList);
    const result = eventList.filter((user) => {
      return (
        value &&
        user &&
        user.name &&
        user.name.toLowerCase().includes(value.toLowerCase())
      );
    });
    console.log(result);
    if (result.length === 0) {
      setFilteredEvents(eventList);
    } else {
      setFilteredEvents(result);
    }
  };

  return (
    <div className="input-wrapper">
      <FaSearch id="search-icon" />
      <input
        placeholder={placeHolder}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
