import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import CloseIcon from "@mui/icons-material/Close";
import Media from "react-bootstrap/Media";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import AdminMenuAddScreen from "../AdminMenuAddScreen";
import AdminMenuEditScreen from "../AdminMenuEditScreen";
import Image from "react-bootstrap/Image";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
class AdminEventMenu extends Component {
  state = {
    vendorData: [],
    selectedEventId: 0,
    selectedVendorId: -1,
    tempMenuDetails: [],
    menuDetails: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    availableStatus: ["processing", "rejected", "approved", "Finalized Paid"],
    selectedBookingNewStatus: "",
    selectedBooking: null,
    isOpenBookingStatusChangeModal: false,
    isOpenBookingStatusChangeModal: false,
    isLoadingChangeBookingStatus: false,
    isLoadingChangeBookingStatus: false,
    isLoadingSendingVendorRequest: false,
    searchBooking: "",
    events: [],
    tempBookingRequests: [],
    vendorCateringPacks: [],
    selectedCateringPack: {},
    requestSent: [],
    isOpenCustomerEmailDetails: false,
    requestSentIds: [],
    isLoadingVendor: false,
    searchVendor: "",
    searchNonCuisineVendor: "",
    vendorListSearchDisplay: "none",
    nonCuisineVendorListSearchDisplay: "none",
    selectedCuisine: "",
    adminNotesChanged: [],
    isLoading: false,
    requestState: "all",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
  };

  getAllEvents = () => {
    this.setState({ isLoading: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "all_events",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.events, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.success == true) {
          const sortedEvents = result.events.sort((b, a) =>
            moment(
              moment(a.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
            ).diff(
              moment(moment(b.start_date, "DD MMM YYYY").format("YYYY-MM-DD")),
              "days"
            )
          );

          this.setState({ events: sortedEvents });
          console.log(sortedEvents);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getAllVendors = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ vendorData: vendorList });
      return;
    }
    let applyVendor = [];

    vendorList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.vendor_outlets;

      fetch(vendorUrl, {
        method: "GET",
        redirect: "follow",
      }).then((response) =>
        response
          .json()
          .then((responseData) => {
            let isIdPresent = false;
            if (applyVendor) {
              for (let i = 0; i < applyVendor.length; i++) {
                if (applyVendor[i].id === responseData.id) {
                  isIdPresent = true;
                  break;
                }
              }
              if (!isIdPresent) {
                applyVendor.push(responseData);
              }
            }
          })
          .then((response) => {
            this.setState({ vendorData: applyVendor });
          })
      );
    });
  };
  getEventMenu = (id) => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_event_menu",
          email: email,
          password: password,
          event_id: id,
        },
      },
    });
    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({
            menuDetails: dataResponse.menu_details,
            tempMenuDetails: dataResponse.menu_details,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isStockAdminLoggedIn,
      isAccountantLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getAllEvents();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      selectedVendorId,
      requestState,
      tempBookingRequests,
      searchBooking,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
      menuDetails,
      selectedBrandMeals,
      selectedEventId,
      selectedVendorName,
      events,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Select Event</Form.Label>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedEventId}
                    onChange={(e) => {
                      this.setState(
                        { selectedEventId: "" + e.target.value },
                        () => {
                          this.getEventMenu(e.target.value);
                        }
                      );
                      saveToLocalStorage(
                        "selectedEventId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {events && events.length == 1 ? (
                      <option value={events[0].id}>{events[0].name}</option>
                    ) : (
                      <>
                        <option value={0}>Select Vendor</option>
                        {events &&
                          events.map((e) => {
                            return <option value={"" + e.id}>{e.name}</option>;
                          })}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Row>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  style={{
                    backgroundColor: "#f1f1f1",
                    borderRightWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    borderStyle: "solid",
                    borderColor: "black",
                    padding: 0,
                  }}
                >
                  <div>
                    <h3
                      style={{
                        padding: 20,
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                    >
                      Trucks
                    </h3>
                  </div>
                  {menuDetails &&
                    menuDetails.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          style={
                            selectedVendorId == item.id
                              ? {
                                  backgroundColor: "white",
                                  cursor: "pointer",
                                  padding: 20,
                                  borderBottomWidth: 1,
                                  borderTopWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRightWidth: 0,
                                  borderColor: "black",
                                  borderStyle: "solid",
                                }
                              : {
                                  cursor: "pointer",
                                  padding: 20,
                                  borderBottomWidth: 1,
                                  borderTopWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRightWidth: 0,
                                  borderColor: "black",
                                  borderStyle: "solid",
                                }
                          }
                          onClick={() => {
                            this.setState(
                              {
                                selectedBrandMeals: item.meals,
                                tempSelectedBrandMeals: item.meals,
                                selectedVendorId: item.id,
                                selectedVendorName: item.title,
                                showAdminMenuAddScreen: false,
                                showAdminMenuEditScreen: false,
                              },
                              () =>
                                saveToLocalStorage("selectedVendorId", item.id)
                            );
                          }}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                </Col>
                <Col xs={12} md={9} lg={9} className="mobile-padding">
                  <Row className="mobile-only">
                    <Col
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center" }}
                    >
                      <h4>
                        <b>selectedVendorName</b>
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            this.onSearch(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={2} lg={2}>
                      <Button
                        style={{ margin: 10 }}
                        onClick={() => {
                          if (this.state.selectedVendorId == -1) {
                            alert(
                              "Please select ateleast 1 brand before adding the menu"
                            );
                          } else {
                            this.setState({ showAdminMenuAddScreen: true });
                          }
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                  {this.state.showAdminMenuAddScreen && (
                    <CloseIcon
                      className="plzHover"
                      fontSize="large"
                      style={{ margin: 1, float: "right" }}
                      onClick={() =>
                        this.setState({ showAdminMenuAddScreen: false })
                      }
                    />
                  )}
                  {this.state.showAdminMenuAddScreen && (
                    <AdminMenuAddScreen
                      stallId={this.state.selectedVendorId}
                      eventId={parseInt(this.state.selectedEventId)}
                    />
                  )}
                  {this.state.showAdminMenuEditScreen && (
                    <CloseIcon
                      className="plzHover"
                      fontSize="large"
                      style={{ margin: 1, float: "right" }}
                      onClick={() =>
                        this.setState({ showAdminMenuEditScreen: false })
                      }
                    />
                  )}
                  {this.state.showAdminMenuEditScreen && (
                    <AdminMenuEditScreen
                      stallId={this.state.selectedVendorId}
                      eventId={this.state.selectedEventId}
                      menu={this.state.mealProp}
                    />
                  )}
                  {selectedBrandMeals && selectedBrandMeals.length > 0 ? (
                    <Row>
                      {selectedBrandMeals.map((meal, i) => {
                        console.log("Meal:", meal);
                        return (
                          <Col xs={12} md={12} lg={12}>
                            <Media
                              key={i}
                              style={{
                                padding: 10,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }}
                            >
                              <Image
                                style={{ width: 120 }}
                                src={
                                  meal.meal_image &&
                                  meal.meal_image != "" &&
                                  meal.meal_image != null &&
                                  meal.meal_image != "NULL"
                                    ? AWS_URL + "/" + meal.meal_image
                                    : require("../../assets/img/empty_logo.png")
                                }
                              ></Image>
                              <Media.Body style={{ marginLeft: 10 }}>
                                <Row>
                                  <Col xs={12} md={6} lg={6}>
                                    <h4>{meal.meal_name}</h4>
                                  </Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    style={{ padding: 0 }}
                                  >
                                    <h4>
                                      ${Number(meal.meal_price).toFixed(2)}
                                    </h4>
                                  </Col>
                                  <Col xs={6} md={4} lg={4}>
                                    <h4>{meal.status}</h4>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={6} lg={6}></Col>
                                  <Col
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    style={{ padding: 0 }}
                                  ></Col>
                                  <Col xs={6} md={4} lg={4}>
                                    <Button
                                      onClick={() => {
                                        console.log(meal);
                                        console.log("meal");
                                        this.setState({
                                          showAdminMenuEditScreen: true,
                                          mealProp: { meal },
                                        });
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </Col>
                                </Row>
                              </Media.Body>
                            </Media>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <div>No results</div>
                  )}
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminEventMenu, {
    fallback: <ErrorFallbackComponent />,
  })
);
