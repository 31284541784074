import React, { useState } from "react";
import { Form } from "react-bootstrap";

const CheckButton = (props) => {
  const {
    formStateHandler,
    formTouchedHandler,
    fieldName,
    otherFunction,
    defaultChecked,
    isDelivery,
  } = props;
  const [isChecked, setChecked] = useState(defaultChecked);
  return (
    <Form.Check
      inline
      name={fieldName}
      type="checkbox"
      id={fieldName}
      onChange={() => {
        setChecked(!isChecked);
        formStateHandler(fieldName, !isChecked);
        if (otherFunction) {
          otherFunction(!isChecked);
        }
      }}
      onClick={() => formTouchedHandler(fieldName, true)}
      defaultChecked={defaultChecked}
    />
  );
};

export default CheckButton;
