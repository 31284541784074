import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import { getFromLocalStorage } from "../../../store";
import "./style.css";

const TransferStocksModal = () => {
  const TRANSFER_TYPES = [
    { label: "Truck to Warehouse", value: "truck-to-warehouse" },
    { label: "Truck to Event Storeroom", value: "truck-to-event-storeroom" },
    {
      label: "Event Store Room to Warehouse",
      value: "event-store-room-to-warehouse",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [storeRooms, setStoreRooms] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [transferType, setTransferType] = useState("");
  const [eventId, setEventId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [storeRoomId, setStoreRoomId] = useState("");

  const [storeRoomStockList, setStoreRoomStockList] = useState([]);

  const [vendorStockList, setVendorStockList] = useState([]);

  const handleClose = () => {
    // setFetchedEvents([]);
    // setVendorId([]);

    setStoreRooms([]);
    setTransferType("");
    setEventId("");
    setVendorId("");
    setStoreRoomId("");
    setStoreRoomStockList([]);
    setVendorStockList([]);
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  const getAllEvents = () => {
    // // setIsLoading(true);
    //     const operator = JSON.parse(getFromLocalStorage("Operator"));
    //     const operatorId = operator[0].id;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email:
            getFromLocalStorage("email") != "undefined" &&
            getFromLocalStorage("email") != "" &&
            getFromLocalStorage("email") != null
              ? getFromLocalStorage("email")
              : "",
          password:
            getFromLocalStorage("password") != "undefined" &&
            getFromLocalStorage("password") != "" &&
            getFromLocalStorage("password") != null
              ? getFromLocalStorage("password")
              : "",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        //     // setIsLoading(false);
        setIsLoading(false);
        if (dataResponse.success == true) {
          setFetchedEvents(dataResponse?.events ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        //     // setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };

  const getStoreRooms = (event_id) => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    const operatorId = operator[0].id;

    // setIsLoading(true);
    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    setIsLoading(true);
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_event_storerooms",
          operator_id: operatorId ? operatorId : null,
          event_id: Number(event_id),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        // setIsLoading(false);
        if (dataResponse.success == true) {
          setStoreRooms(dataResponse.storerooms);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
        // setIsLoading(false);
      });
  };

  const getStoreRoomStock = (store_room_id) => {
    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    setIsLoading(true);
    var storeRoomRaw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_stock_in_storeroom",
          operator_id: operatorData ? operatorData.id : null,
          storeroom_id: Number(store_room_id),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: storeRoomRaw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          setStoreRoomStockList(
            dataResponse.storerooms_stocklist.map((item) => ({
              ...item,
              selected_stock: 0,
              selected: false,
            }))
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const getVendorStock = (vendor_id) => {
    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    setIsLoading(true);
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_truck_stocklist",
          vendor_id: Number(vendor_id),
          operator_id: operatorData ? operatorData.id : null,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          setVendorStockList(
            dataResponse.data.map((item) => ({
              ...item,
              stock_count: item.stock_count ? item.stock_count : 0,
              selected_stock: 0,
              selected: false,
            }))
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  useEffect(() => {
    if (showModal) getAllEvents();
  }, [showModal]);

  const clearState = () => {
    setTransferType("");
    setEventId("");
    setVendorId("");
    setStoreRoomId("");

    setStoreRoomStockList([]);
    setVendorStockList([]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let items = [];
    if (transferType === "event-store-room-to-warehouse") {
      items = storeRoomStockList
        .filter((item) => item.selected)
        .map((item) => {
          return {
            stocklist_id: item.id,
            stock_item_name: item.stocklist_item__name,
            count: item.selected_stock ?? 0,
          };
        });
    } else {
      items = vendorStockList
        .filter((item) => item.selected)
        .map((item) => {
          return {
            stocklist_id: item.id,
            stock_item_name: item.name,
            count: item.selected_stock ?? 0,
          };
        });
    }

    const operatorData =
      JSON.parse(getFromLocalStorage("Operator")) &&
      JSON.parse(getFromLocalStorage("Operator")).length
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const body = {
      query_type: "transfer_stock",
      transfer_type: transferType,
      event_id: Number(eventId),
      operator_id: operatorData ? operatorData.id : null,
      transfer_stock_data: items,
    };
    if (
      transferType === "truck-to-event-storeroom" ||
      transferType === "truck-to-warehouse"
    )
      body.vendor_id = Number(vendorId);
    if (
      transferType === "truck-to-event-storeroom" ||
      transferType === "event-store-room-to-warehouse"
    )
      body.event_store_room_id = Number(storeRoomId);

    var raw = JSON.stringify({
      payload: {
        body: body,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          clearState();
          alert("Transfer Request has been submitted successfully.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col></Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button variant="primary" onClick={handleShow}>
            Transfer Stocks
          </Button>
        </Col>
      </Row>
      <Modal
        dialogClassName="transfer-modal"
        scrollable
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Stocks</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "relative" }}>
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 1)", // Optional: Add a semi-transparent background
                zIndex: 9999, // Ensure it covers everything
              }}
            >
              <img
                src={require("../../../assets/img/loading.gif")}
                alt="Loading"
              />
            </div>
          ) : null}
          <form className="transfer-form-container" onSubmit={handleSubmit}>
            <div className="transfer-form-item">
              <label>Select Transfer Type:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  const val = e.target.value;
                  setTransferType(val);
                  setEventId("");
                  setVendorId("");
                  setStoreRoomId("");

                  setStoreRoomStockList([]);
                  setVendorStockList([]);

                  if (
                    val === "truck-to-warehouse" ||
                    val === "truck-to-event-storeroom"
                  )
                    fetchVendorIds();
                }}
                value={transferType || ""}
              >
                <option value="" disabled>
                  Select Transfer type
                </option>
                {TRANSFER_TYPES.map((type) => (
                  <option key={type?.value} value={type?.value}>
                    {type?.label || ""}
                  </option>
                ))}
              </select>
            </div>
            <div className="transfer-form-item">
              <label>Select Event:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  setEventId(e.target.value);
                  // if (
                  //   val === "truck-to-event-storeroom" ||
                  //   val === "event-store-room-to-warehouse"
                  // )
                  getStoreRooms(e.target.value);
                }}
                value={eventId || ""}
              >
                <option value="">Select Event</option>
                {fetchedEvents.map((event, index) => (
                  <option key={index} value={event?.id}>
                    {event?.name || ""}
                  </option>
                ))}
              </select>
            </div>
            {eventId &&
            (transferType === "truck-to-warehouse" ||
              transferType === "truck-to-event-storeroom") ? (
              <div className="transfer-form-item">
                <label>Select Vendor:</label>
                <select
                  style={{ flex: 1 }}
                  onChange={(e) => {
                    setVendorId(e.target.value);
                    if (
                      transferType === "truck-to-warehouse" ||
                      transferType === "truck-to-event-storeroom"
                    )
                      getVendorStock(e.target.value);
                  }}
                  value={vendorId || ""}
                >
                  <option value="" disabled>
                    Select Vendor
                  </option>
                  {vendors.map((vendor, index) => (
                    <option key={index} value={vendor?.user_ptr_id}>
                      {vendor?.title}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            {eventId &&
            (transferType === "truck-to-event-storeroom" ||
              transferType === "event-store-room-to-warehouse") ? (
              <div className="transfer-form-item">
                <label>Select Store Room:</label>
                <select
                  style={{ flex: 1 }}
                  onChange={(e) => {
                    setStoreRoomId(e.target.value);
                    if (transferType === "event-store-room-to-warehouse")
                      getStoreRoomStock(e.target.value);
                  }}
                  value={storeRoomId || ""}
                >
                  <option value="" disabled>
                    Select Store Room
                  </option>
                  {storeRooms.map((storeRoom, index) => (
                    <option key={index} value={storeRoom.id}>
                      {storeRoom?.store_room_name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            {storeRoomStockList && storeRoomStockList.length ? (
              <div>
                <Row className="table-header-row">
                  <Col xs={12} md={1} lg={1}>
                    {/* <Form.Check type="checkbox" id={"all"} />
                     */}
                    <input
                      onChange={(e) => {
                        if (e.target.checked === true)
                          setStoreRoomStockList((st) => {
                            return st.map((item) => ({
                              ...item,
                              selected: true,
                            }));
                          });
                        // setStoreRoomSelectedStock(() => {
                        //   return storeRoomStockList.map((item) => ({
                        //     stocklist_id: item.id,
                        //     stock_item_name: item.stocklist_item__name,
                        //     count: item.selected_stock ?? 0,
                        //   }));
                        // });
                        else {
                          setStoreRoomStockList((st) => {
                            return st.map((item) => ({
                              ...item,
                              selected: false,
                            }));
                          });
                        }
                      }}
                      type="checkbox"
                      id="all"
                      className="transfer-check"
                      name="all"
                    />
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Stock ID
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Stock Name
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Quantity Type
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Stock Quantity
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Available Stock Quantity
                  </Col>
                </Row>
                {storeRoomStockList.map((item, index) => (
                  <>
                    <Row key={item.id} className="table-row">
                      <Col xs={12} md={1} lg={1}>
                        <input
                          onChange={(e) => {
                            if (e.target.checked === true)
                              setStoreRoomStockList((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],
                                    selected: true,
                                  };
                                }

                                return [...temp];
                              });
                            else {
                              setStoreRoomStockList((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],
                                    selected: false,
                                  };
                                }

                                return [...temp];
                              });
                            }
                          }}
                          checked={item.selected}
                          type="checkbox"
                          id={item.id}
                          className="transfer-check"
                          name={item.id}
                        />
                        {/* <Form.Check
                          onChange={(e) => {
                            if (e.target.checked === true)
                              setStoreRoomSelectedStock((st) => {
                                return [
                                  ...st,
                                  {
                                    stocklist_id: item.id,
                                    stock_item_name: item.stocklist_item__name,
                                    count: item.selected_stock ?? 0,
                                  },
                                ];
                              });
                            else {
                              setStoreRoomSelectedStock((st) => {
                                return st.filter(
                                  (stItem) =>
                                    Number(stItem.stocklist_id) !==
                                    Number(item.id)
                                );
                              });
                            }
                          }}
                          type="checkbox"
                          id={item.id}
                        /> */}
                      </Col>
                      <Col xs={12} md={1} lg={1} className="table-col">
                        {item.id}
                      </Col>

                      <Col xs={12} md={3} lg={3} className="table-col">
                        {item.stocklist_item__name}
                      </Col>
                      <Col xs={12} md={3} lg={3} className="table-col">
                        {item.stocklist_item__qty_type__qty_type}
                      </Col>

                      <Col xs={6} md={2} lg={2} className="table-col">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ padding: 4, borderRadius: 0 }}
                            variant="primary"
                            aria-label="add"
                            size="small"
                            onClick={() => {
                              setStoreRoomStockList((st) => {
                                const tempArr = [...st];
                                if (
                                  tempArr[index].selected_stock <
                                  tempArr[index].stock_count
                                )
                                  tempArr[index].selected_stock += 1;
                                return [...tempArr];
                              });
                            }}
                          >
                            <AddIcon fontSize="inherit" />
                          </Button>

                          <input
                            style={{
                              padding: 4,
                              textAlign: "center",
                            }}
                            value={item.selected_stock}
                            onChange={(e) => {
                              setStoreRoomStockList((st) => {
                                const tempArr = [...st];
                                tempArr[index].selected_stock = Number(
                                  e.target.value
                                );
                                return [...tempArr];
                              });
                            }}
                          />

                          <Button
                            style={{ padding: 4, borderRadius: 0 }}
                            variant="primary"
                            aria-label="remove"
                            size="small"
                            onClick={() => {
                              setStoreRoomStockList((st) => {
                                const tempArr = [...st];
                                if (tempArr[index].selected_stock > 0)
                                  tempArr[index].selected_stock -= 1;
                                return [...tempArr];
                              });
                            }}
                          >
                            <RemoveIcon fontSize="inherit" />
                          </Button>
                        </div>
                      </Col>
                      <Col xs={12} md={2} lg={2} className="table-col">
                        {item.stock_count}
                      </Col>
                    </Row>
                  </>
                ))}
              </div>
            ) : null}
            {vendorStockList && vendorStockList.length ? (
              <div>
                <Row className="table-header-row">
                  <Col xs={12} md={1} lg={1}>
                    {/* <Form.Check type="checkbox" id={"all"} />
                     */}
                    <input
                      onChange={(e) => {
                        if (e.target.checked === true)
                          setVendorStockList((st) => {
                            return st.map((item) => ({
                              ...item,
                              selected: true,
                            }));
                          });
                        else {
                          setVendorStockList((st) => {
                            return st.map((item) => ({
                              ...item,
                              selected: false,
                            }));
                          });
                        }
                      }}
                      type="checkbox"
                      id="all"
                      className="transfer-check"
                      name="all"
                    />
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Stock ID
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Stock Name
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Quantity Type
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Stock Quantity
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Available Stock Quantity
                  </Col>
                </Row>
                {vendorStockList.map((item, index) => (
                  <>
                    <Row key={item.id} className="table-row">
                      <Col xs={12} md={1} lg={1}>
                        <input
                          onChange={(e) => {
                            if (e.target.checked === true)
                              setVendorStockList((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],
                                    selected: true,
                                  };
                                }

                                return [...temp];
                              });
                            else {
                              setVendorStockList((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],
                                    selected: false,
                                  };
                                }

                                return [...temp];
                              });
                            }
                          }}
                          checked={item.selected}
                          type="checkbox"
                          id={item.id}
                          className="transfer-check"
                          name={item.id}
                        />
                      </Col>
                      <Col xs={12} md={1} lg={1} className="table-col">
                        {item.id}
                      </Col>

                      <Col xs={12} md={3} lg={3} className="table-col">
                        {item.name}
                      </Col>
                      <Col xs={12} md={3} lg={3} className="table-col">
                        {item.qty_type__qty_type}
                      </Col>

                      <Col xs={6} md={2} lg={2} className="table-col">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ padding: 4, borderRadius: 0 }}
                            variant="primary"
                            aria-label="add"
                            size="small"
                            onClick={() => {
                              setVendorStockList((st) => {
                                const tempArr = [...st];
                                if (
                                  tempArr[index].selected_stock <
                                  tempArr[index].stock_count
                                )
                                  tempArr[index].selected_stock += 1;
                                return [...tempArr];
                              });
                            }}
                          >
                            <AddIcon fontSize="inherit" />
                          </Button>

                          <input
                            style={{
                              padding: 4,
                              textAlign: "center",
                            }}
                            value={item.selected_stock}
                            onChange={(e) => {
                              setVendorStockList((st) => {
                                const tempArr = [...st];
                                tempArr[index].selected_stock = Number(
                                  e.target.value
                                );
                                return [...tempArr];
                              });
                            }}
                          />

                          <Button
                            style={{ padding: 4, borderRadius: 0 }}
                            variant="primary"
                            aria-label="remove"
                            size="small"
                            onClick={() => {
                              setVendorStockList((st) => {
                                const tempArr = [...st];
                                if (tempArr[index].selected_stock > 0)
                                  tempArr[index].selected_stock -= 1;
                                return [...tempArr];
                              });
                            }}
                          >
                            <RemoveIcon fontSize="inherit" />
                          </Button>
                        </div>
                      </Col>
                      <Col xs={12} md={2} lg={2} className="table-col">
                        {item.stock_count}
                      </Col>
                    </Row>
                  </>
                ))}
              </div>
            ) : null}

            {/* <button className="form-button" type="submit">
              Submit
            </button> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TransferStocksModal;
