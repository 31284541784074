import React, { Component } from "react";
import { connect } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import Container from "react-bootstrap/Container";
import * as Sentry from "@sentry/react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

class StockPortalSupplierCatelogueList extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    stockList: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    hasGSTChecked: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    availableStatus: ["enabled", "disabled"],
    pricesArray: [],
    isUpdatingStockList: false,
    statusArray: [],
    kitchacoLocations: [],
    kitchacoLocation:
      getFromLocalStorage("kitchacoLocation") != "undefined" &&
      getFromLocalStorage("kitchacoLocation") != "" &&
      getFromLocalStorage("kitchacoLocation") != null
        ? getFromLocalStorage("kitchacoLocation")
        : 1,
    allLocationsArray: [],
    updatedMessage: [],
    showUpdateConfirmation: false,
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stock_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.vendors });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Oops, Something went wrong! Please try again later");
      });
  }

  fetchStockListFromServer = () => {
    const {
      email,
      password,
      kitchacoLocation,
      superAdminEmail,
      superAdminPassword,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // var raw = ''
    // if(email!='' && password !=''){
    //   raw = JSON.stringify({ "email": email, "password": password, "query_type": "get_admin_stock_list", "kitchaco_location": parseInt(kitchacoLocation) });

    // }

    var raw = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "get_admin_stock_list",
          kitchaco_location: parseInt(kitchacoLocation),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          var tempGSTArray = [];
          var tempExpiryArray = [];
          var tempPriceArray = [];
          var tempStatusArray = [];
          for (var i = 0; i < result.data.length; i++) {
            tempGSTArray[i] = result.data[i].has_gst;
            tempExpiryArray[i] = result.data[i].has_expiry;
            tempPriceArray[i] = result.data[i].price;
            tempStatusArray[i] = result.data[i].status;
          }
          this.setState({
            stockList: result.data,
            hasGSTChecked: tempGSTArray,
            hasExpiryChecked: tempExpiryArray,
            pricesArray: tempPriceArray,
            statusArray: tempStatusArray,
          });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Oops, Something went wrong! Please try again later");
      });
  };

  containsObject = (obj, list) => {
    if (list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].stock_list_id == obj.stock_list_id) {
          return true;
        }
      }
    }
    return false;
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  onChangeKitchacoLocation(e) {
    this.setState({ kitchacoLocation: e.target.value }, () => {
      saveToLocalStorage("kitchacoLocation", this.state.kitchacoLocation);
      window.location.reload();
    });
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn, isStockAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.getKitchacoLocationData();
      this.fetchStockListFromServer();
    } else {
      window.location.href = "/admin";
    }
  }
  updateStockList = () => {
    const {
      hasGSTChecked,
      hasExpiryChecked,
      pricesArray,
      stockList,
      statusArray,
      allLocationsArray,
      email,
      password,
      superAdminEmail,
      superAdminPassword,
    } = this.state;
    this.setState({ isUpdatingStockList: true });
    var stockData = [];
    for (var key in pricesArray) {
      var all_loctions = false;
      if (allLocationsArray[key]) {
        all_loctions = allLocationsArray[key];
      }
      var obj = {
        id: stockList[key].id,
        qty_type_id: stockList[key].qty_type__id,
        name: stockList[key].name,
        price: pricesArray[key],
        has_expiry: hasExpiryChecked[key],
        has_gst: hasGSTChecked[key],
        status: statusArray[key],
        change_all_locations: all_loctions,
      };
      stockData.push(obj);
    }

    var data = {};
    if (email != "" && password != "") {
      data = {
        email: email,
        password: password,
        stockData: stockData,
        query_type: "update_admin_stock_list",
      };
    } else if (superAdminEmail != "" && superAdminPassword != "") {
      data = {
        email: superAdminEmail,
        password: superAdminPassword,
        stockData: stockData,
        query_type: "update_admin_stock_list",
      };
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isUpdatingStockList: false });
        if (result.success) {
          this.setState({
            updatedMessage: result.message,
            showUpdateConfirmation: true,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isUpdatingStockList: false });
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
        {this.renderUpdateConfirmation()}
        <Container></Container>
      </div>
    );
  }

  renderHome() {
    const {
      stockList,
      availableStatus,
      hasGSTChecked,
      hasExpiryChecked,
      pricesArray,
      isUpdatingStockList,
      statusArray,
      kitchacoLocations,
      kitchacoLocation,
    } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Stock List</h3>
              <Row>
                <Col xs={12}>
                  <h6>
                    1. Any disbaled items will not show up on the customer's
                    ordering platform{" "}
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h6>
                    2. Any item on which GST is enabled, 10% price would be
                    added onto the price listed below and shown as GST
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h6>
                    3. If any item is updated when the Change All Locations
                    checkbox is selected then all items having the same name and
                    qty type will be updated.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  {kitchacoLocations && kitchacoLocations.length > 0 ? (
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>
                        Vendors {"(" + kitchacoLocations.length + ")"}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        onChange={this.onChangeKitchacoLocation.bind(this)}
                        defaultValue={kitchacoLocation}
                      >
                        <option value={0}>All</option>
                        {kitchacoLocations.map((location, i) => {
                          return (
                            <option
                              key={i}
                              value={kitchacoLocations[i].user_ptr_id}
                            >
                              {kitchacoLocations[i].title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col">
              ID
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Name
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Qty Type
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              WTT
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Has Expiry
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Has GST
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Status
            </Col>
          </Row>
          {stockList.map((item, i) => {
            return (
              <div key={i}>
                <Row className="table-row">
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">ID:</b> {item.id}
                    </span>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <Row>
                      <Col xs={12} md={2} lg={2}>
                        <span>
                          <b className="mobile-only">Name:</b>
                          {item.name}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={2} lg={2}>
                        <span>
                          <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                              type="checkbox"
                              label="Change All Vendors"
                              defaultChecked={hasExpiryChecked[i]}
                              onChange={this.onChangeAllLocations.bind(this, i)}
                            />
                          </Form.Group>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">Qty Type:</b>
                      {item.qty_type__qty_type}
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    md={1}
                    lg={1}
                    className="table-col"
                    style={{ padding: 0 }}
                  >
                    <span>
                      <b className="mobile-only">Price:</b>
                      <Form.Group>
                        <Form.Control
                          type="number"
                          placeholder="Price"
                          id={"price_" + i}
                          value={pricesArray[i]}
                          name={pricesArray[i]}
                          onChange={this.handleChangePrice.bind(this, i)}
                        />
                      </Form.Group>
                    </span>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <span>
                      <b className="mobile-only">WTT:</b>
                      {item.kitchaco__location}
                    </span>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">Par:</b>
                      {item.par}
                    </span>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">Has Expiry:</b>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          defaultChecked={hasExpiryChecked[i]}
                          onChange={this.onChangeHasExpiry.bind(this, i)}
                        />
                      </Form.Group>
                    </span>
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <span>
                      <b className="mobile-only">Has GST:</b>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          defaultChecked={hasGSTChecked[i]}
                          onChange={this.onChangeHasGST.bind(this, i)}
                        />
                      </Form.Group>
                    </span>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <span>
                      <b className="mobile-only">Status:</b>
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          onChange={(e) => {
                            this.onChangeStockListStatus(e, i);
                          }}
                          defaultValue={statusArray[i]}
                        >
                          {availableStatus.map((status, i) => {
                            return (
                              <option key={i} value={status}>
                                {status}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </span>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Row className="button-row">
            <Col xs={12}>
              <Button
                onClick={() => {
                  if (!isUpdatingStockList) {
                    this.updateStockList();
                  }
                }}
              >
                {!isUpdatingStockList ? "Update" : "Loading..."}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderUpdateConfirmation() {
    const { showUpdateConfirmation, updatedMessage } = this.state;
    return (
      <Modal
        show={showUpdateConfirmation}
        onHide={() => {
          this.setState({ showUpdateConfirmation: false }, () => {
            window.location.reload();
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The following shows only those items for which check all locations
            was checked. All the other indvidual items have also been updated
            succesfully!
          </p>
          {updatedMessage.map((item) => {
            return (
              <p>
                {Object.keys(item)} updated at {Object.values(item)} location
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    );
  }

  onChangeStockListStatus = (e, i) => {
    this.setState({
      statusArray: { ...this.state.statusArray, [i]: e.target.value },
    });
  };

  onChangeHasExpiry = (i, e) => {
    this.setState({
      hasExpiryChecked: {
        ...this.state.hasExpiryChecked,
        [i]: e.target.checked,
      },
    });
  };

  onChangeHasGST = (i, e) => {
    this.setState({
      hasGSTChecked: { ...this.state.hasGSTChecked, [i]: e.target.checked },
    });
  };

  handleChangePrice = (i, e) => {
    this.setState({
      pricesArray: { ...this.state.pricesArray, [i]: e.target.value },
    });
  };

  onChangeAllLocations = (i, e) => {
    this.setState({
      allLocationsArray: {
        ...this.state.allLocationsArray,
        [i]: e.target.checked,
      },
    });
  };
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockPortalSupplierCatelogueList, {
    fallback: <ErrorFallbackComponent />,
  })
);
