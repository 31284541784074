import React, { useState } from "react";
import Footer from "../../components/UI/Footer";
import { Container } from "react-bootstrap";
import "./style.css";

import Data from "./Data.js";

import AdminNavBar from "../../components/UI/AdminNavBar/index.js";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store/index.js";

const OrganiserProfileScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOrganiserLoggedIn, setIsOrganiserLoggedIn] = useState(() =>
    getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
    getFromLocalStorage("isOrganiserLoggedIn") != "" &&
    getFromLocalStorage("isOrganiserLoggedIn") != null
      ? getFromLocalStorage("isOrganiserLoggedIn")
      : "false"
  );
  const [vendorName, setVendorName] = useState(() =>
    getFromLocalStorage("vendorName") != "undefined" &&
    getFromLocalStorage("vendorName") != "" &&
    getFromLocalStorage("vendorName") != null
      ? getFromLocalStorage("vendorName")
      : ""
  );

  const logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    setIsOrganiserLoggedIn(false);
    setVendorName("");
    window.location.reload();
  };

  return (
    <>
      <AdminNavBar
        style={{ margin: "auto" }}
        isSuperAdminLoggedIn={false}
        isOrganiserLoggedIn={isOrganiserLoggedIn}
        logout={logout}
        vendorName={vendorName}
      ></AdminNavBar>
      {isLoading ? (
        <>
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        </>
      ) : isOrganiserLoggedIn == "false" ? (
        (window.location.href = "/organiser")
      ) : (
        <>
          <>
            <Container>
              <h3 style={{ textAlign: "center" }}>Profile</h3>
              <div className="form-style-5 content">
                <legend>
                  <span className="number">Organiser Details</span>
                </legend>
                <Data />
              </div>
            </Container>
            <Footer />
          </>
        </>
      )}
    </>
  );
};
export default OrganiserProfileScreen;
