export const apiPaths = {
  onboarding: "/api/v1/onboarding-screens/",
  login: {
    sendCode: "/api/v1/send-sms/",
    verifyNumber: "/api/v1/login-phone/",
    createUser: "/api/v1/customers/",
    tokenAuth: "/api/v1/login-facebook/",
  },
  profile: "/api/v1/customers/",
  logOut: "/api/v1/logout/",
  explore: {
    trucksList: "/api/v1/vendors/",
    filterList: "/api/v1/vendor-filters/",
    orders: "/api/v1/orders/",
    cateringPackages: "/api/v1/catering-package",
    events: "/api/v1/public-event/",
    combo: "/api/v1/meal-combo/",
    delivery: "/api/v1/delivery-charge/",
    location: "/api/v1/kitchaco-location/",
  },
  calculateDelivery: "/api/v1/calculate-distance/",
  payment: "/api/v1/payment/",
  promoCode: "/api/v1/promo-code/",
  deliveryDriver: "/api/v1/delivery-driver/",
  trackDriver: "/api/v1/driver-delivery-journey/",
  publicEvent: "/api/v1/public-event/",
  getAllOrders: "/api/v1/get-all-orders/",
  bookATruck: "/api/v1/book-a-truck/",
  adminData: "/api/v1/admin-data/",
  // vendorData: "/api/v1/operator-data/",
  signSubscription: "/api/v1/operator-subscription-contract",
  operatorData: "/api/v1/operator-data",
  organiserData: "/api/v1/organiser-data",
  organiserProfile: "/api/v1/organiser-profile",
  uploadOperatorDocuments: "/api/v1/upload-operator-documents",
  signContract: "/api/v1/operator-sign-contract",
  createRunsheet: "/api/v1/admin-create-runsheets",
  editRunsheet: "/api/v1/admin-edit-runsheets",

  operatorStockData: "/api/v1/operator-stock-data",

  shareLink: "/api/v1/share",
  shareCateringLink: "/api/v1/share-catering",
  sharableLinkStall: "/api/v1/sharable-stall-link/",
  UploadStatementOfTrades: "/api/v1/upload-sot",
  UploadPublicLiablity: "/api/v1/upload-public-liablity",
  eoiApplicationForm: "/api/v1/eoi-application-form",
  stockData: "/api/v1/stock-admin-data",
  customerEvents: "/api/v1/events/",
  events: "/api/v1/customer-events/",
  preEventsChecklist: "/pre-event-checklist",
  operatorSubscription: "/api/v1/operator-subscription",

  postEventsChecklist: "/post-event-checklist",
  expression: "/api/v1/event-organiser-application/",
  supplierApplication: "/api/v1/stock-supplier-application/",
  vendorDocuments: "/api/v1/vendor-documents/",
  vendorApplication: "/api/v1/vendor-application/",
  expressionInterest: "/api/v1/expression-of-intrest/",
  creatingVendors: "/api/v1/add-stalls/",
  updateVendors: "/api/v1/update-stalls/",
  salesReport: "/api/v1/sales-report/",
  incidentLog: "/api/v1/incident-log",
  updateElectricalEquipment: "/api/v1/electrical-equipment/",
  electricalEquipment: "/api/v1/electrical-equipment/",
};
