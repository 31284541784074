import React, { Component } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getFromLocalStorage } from "../../store";
import Modal from "react-bootstrap/Modal";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import Footer from "../../components/UI/Footer";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import "./style.css";

class StockTransferRequestScreen extends Component {
  state = {
    transferRequests: [],
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") !== "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") !== "" &&
      getFromLocalStorage("isAccountantLoggedIn") !== null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== "" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    selectedItems: {},
    rejectionDetails: {},
    showModal: false,
    currentRequestId: null,
    combinedRejectionMessage: "",  // State for the combined rejection message
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
      isEventCoordinatorLoggedIn,
    } = this.state;
    
    // Redirect if the user is not logged in
    if (
      isSuperAdminLoggedIn !== "true" &&
      isAccountantLoggedIn !== "true" &&
      isStockAdminLoggedIn !== "true" &&
      isEventCoordinatorLoggedIn !== "true"
    ) {
      window.location.href = "/admin";
    } else {
      this.fetchTransferRequests();
    }
  }

  fetchTransferRequests = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_transfer_requests",
          event_id: 1329, // Replace with the actual event_id if needed
          operator_id: 3, // Replace with the actual operator_id if needed
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ transferRequests: result.transfer_requests_details });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again");
      });
  };

  handleSelectItems = (requestId) => {
    // Open modal for item selection
    this.setState({
      showModal: true,
      currentRequestId: requestId,
      selectedItems: {},
      rejectionDetails: {},
      combinedRejectionMessage: "",  // Reset combined rejection message
    });
  };

  handleApproveOrReject = () => {
    const { selectedItems, rejectionDetails, currentRequestId, transferRequests, combinedRejectionMessage } = this.state;

    // Get the transfer request details
    const transferRequest = transferRequests.find(request => request.id === currentRequestId);
    if (!transferRequest) return;

    // Prepare items data for approval/rejection
    const items = transferRequest.transfer_stock_items.map(item => {
      const isRejected = selectedItems[item.id] === "rejected";
      return {
        transfer_request_id: currentRequestId,
        stocklist_id: item.stocklist__id,
        transfer_count: item.transfer_count,
        status: selectedItems[item.id],
        rejected_quantity: isRejected ? rejectionDetails[item.id]?.quantity || 0 : 0,
      };
    });

    // Split items into approved and rejected lists
    const approvedItems = items.filter(item => item.status === "approved");
    const rejectedItems = items.filter(item => item.status === "rejected");

    if (approvedItems.length > 0) {
      this.approveSelectedItems(approvedItems);
    }
    if (rejectedItems.length > 0) {
      this.rejectSelectedItems(rejectedItems, combinedRejectionMessage);
    }

    this.setState({ showModal: false });
  };

  approveSelectedItems = (approvedItems) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "approve_transfer_request",
          items: approvedItems, // Pass the selected items with necessary details
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Selected Items Approved Successfully");
          this.fetchTransferRequests();
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again");
      });
  };

  rejectSelectedItems = (rejectedItems, combinedRejectionMessage) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "disapprove_transfer_request",
          items: rejectedItems, // Include items in disapproval request
          disapproval_message: combinedRejectionMessage, // Combined rejection message
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Selected Items Disapproved Successfully");
          this.fetchTransferRequests();
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Error! Please try again!");
      });
  };

  handleItemStatusChange = (itemId, status, itemName) => {
    // Automatically update the combined rejection message
    let autoGeneratedMessage = this.state.combinedRejectionMessage;
    
    if (status === "rejected") {
      autoGeneratedMessage += `\n- Item: ${itemName}, Quantity: ${this.state.rejectionDetails[itemId]?.quantity || 0}`;
    } else {
      autoGeneratedMessage = autoGeneratedMessage.replace(new RegExp(`\\n- Item: ${itemName}.*`, 'g'), '');
    }

    this.setState((prevState) => ({
      selectedItems: {
        ...prevState.selectedItems,
        [itemId]: status
      },
      rejectionDetails: {
        ...prevState.rejectionDetails,
        [itemId]: {
          ...prevState.rejectionDetails[itemId],
          quantity: status === "rejected" ? prevState.rejectionDetails[itemId]?.quantity || 0 : 0,
        }
      },
      combinedRejectionMessage: autoGeneratedMessage.trim(),
    }));
  };

  handleRejectedQuantityChange = (itemId, quantity, itemName) => {
    // Update the quantity in the combined message
    let updatedMessage = this.state.combinedRejectionMessage.replace(new RegExp(`(\\n- Item: ${itemName}, Quantity: )\\d+`, 'g'), `$1${quantity}`);

    this.setState((prevState) => ({
      rejectionDetails: {
        ...prevState.rejectionDetails,
        [itemId]: {
          ...prevState.rejectionDetails[itemId],
          quantity
        }
      },
      combinedRejectionMessage: updatedMessage,
    }));
  };

  handleCombinedRejectionMessageChange = (e) => {
    this.setState({ combinedRejectionMessage: e.target.value });
  };

  render() {
    const { transferRequests, showModal, selectedItems, rejectionDetails, combinedRejectionMessage } = this.state;

    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={this.state.isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={this.state.isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={this.state.isAccountantLoggedIn}
          isStockAdminLoggedIn={this.state.isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Transfer Requests</h2>
            </Col>
          </Row>
          {transferRequests.map((request) => {
            return (
              <Accordion key={request.id}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={request.id}>
                    <Row>
                      <Col xs={12} md={4}>
                        {request.event__name} - {request.vendor__title}
                      </Col>
                      <Col xs={12} md={4}>
                        Requested By: {request.requested_by}
                      </Col>
                      <Col xs={12} md={4}>
                        Status: {request.transfer_status}
                      </Col>
                    </Row>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={request.id}>
                    <Card.Body>
                      <h5>Transfer Items</h5>
                      {request.transfer_stock_items.map((item) => (
                        <Row key={item.id}>
                          <Col xs={6}>{item.stock_item_name}</Col>
                          <Col xs={6}>Quantity: {item.transfer_count}</Col>
                        </Row>
                      ))}
                      <Row>
                        <Col xs={12}>
                          <Button
                            variant="primary"
                            onClick={() => this.handleSelectItems(request.id)}
                          >
                            Review Items
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })}
        </Container>
        <Footer />

        {/* Modal for approving/rejecting specific items */}
        <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Approve/Reject Transfer Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.transferRequests.find(request => request.id === this.state.currentRequestId)?.transfer_stock_items.map((item) => (
              <div key={item.id}>
                <Form.Label>{item.stock_item_name} (Qty: {item.transfer_count})</Form.Label>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Approve"
                    name={`itemStatus${item.id}`}
                    checked={selectedItems[item.id] === "approved"}
                    onChange={() => this.handleItemStatusChange(item.id, "approved", item.stock_item_name)}
                  />
                  <Form.Check
                    type="radio"
                    label="Reject"
                    name={`itemStatus${item.id}`}
                    checked={selectedItems[item.id] === "rejected"}
                    onChange={() => this.handleItemStatusChange(item.id, "rejected", item.stock_item_name)}
                  />
                </Form.Group>
                {selectedItems[item.id] === "rejected" && (
                  <div>
                    <Form.Group>
                      <Form.Label>Rejected Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        value={rejectionDetails[item.id]?.quantity || 0}
                        onChange={(e) => this.handleRejectedQuantityChange(item.id, e.target.value, item.stock_item_name)}
                      />
                    </Form.Group>
                  </div>
                )}
              </div>
            ))}
            <Form.Group>
              <Form.Label>Combined Disapproval Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={combinedRejectionMessage}
                onChange={this.handleCombinedRejectionMessageChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleApproveOrReject}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect()(Sentry.withErrorBoundary(StockTransferRequestScreen, {
  fallback: <ErrorFallbackComponent />,
}));
