import React from "react";
import { Row, Form, Col, Accordion, Card } from "react-bootstrap";
import NumberField from "../NumberField";
import "../../style.css";

const PowerRequirements = (props) => {
  const { formik, viewOnly } = props;

  return (
      <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        4. POWER REQUIREMENTS
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          {!viewOnly ? (
                    <div className="title-heading">
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        <h6># of 10 Amp Outlet/s </h6>
                        {formik.touched.numberOf10Amp && formik.errors.numberOf10Amp ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.numberOf10Amp}
                          </div>
                        ) : null}
                      </Form.Label>
                      <Col sm={7}>
                        <NumberField
                          formStateHandler={formik.setFieldValue}
                          formTouchedHandler={formik.setFieldTouched}
                          fieldName={"numberOf10Amp"}
                          defaultValue={formik.values.numberOf10Amp}
                        />
                      </Col>
                    </Form.Group>
        
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        <h6># of 15 Amp Outlet/s </h6>
                        {formik.touched.numberOf15Amp && formik.errors.numberOf15Amp ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.numberOf15Amp}
                          </div>
                        ) : null}
                      </Form.Label>
                      <Col sm={7}>
                        <NumberField
                          formStateHandler={formik.setFieldValue}
                          formTouchedHandler={formik.setFieldTouched}
                          fieldName={"numberOf15Amp"}
                          defaultValue={formik.values.numberOf15Amp}
                        />
                      </Col>
                    </Form.Group>
        
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        <h6># of 20 amps 3 phase Outlet/s </h6>
                        {formik.touched.numberOf20Amp && formik.errors.numberOf20Amp ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.numberOf20Amp}
                          </div>
                        ) : null}
                      </Form.Label>
                      <Col sm={7}>
                        <NumberField
                          formStateHandler={formik.setFieldValue}
                          formTouchedHandler={formik.setFieldTouched}
                          fieldName={"numberOf20Amp"}
                          defaultValue={formik.values.numberOf20Amp}
                        />
                      </Col>
                    </Form.Group>
        
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        <h6># of 32 Amp 3 phase Outlet/s </h6>
                        {formik.touched.numberOf32Amp && formik.errors.numberOf32Amp ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.numberOf32Amp}
                          </div>
                        ) : null}
                      </Form.Label>
                      <Col sm={7}>
                        <NumberField
                          formStateHandler={formik.setFieldValue}
                          formTouchedHandler={formik.setFieldTouched}
                          fieldName={"numberOf32Amp"}
                          defaultValue={formik.values.numberOf32Amp}
                        />
                      </Col>
                    </Form.Group>
        
                    <Form.Text
                      style={{ color: "blue", fontSize: "1.2rem" }}
                      className="font-weight-bold"
                    >
                      Note: - Some events disallow catering operators to bring
                      generators on site & must use event power.
                    </Form.Text>
                  </div>
  
          ) : (
            <div className="title-heading">
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                <h6># of 10 Amp Outlet/s </h6>
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"numberOf10Amp"}
                  defaultValue={formik.values.numberOf10Amp}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                <h6># of 15 Amp Outlet/s </h6>
                {formik.touched.numberOf15Amp && formik.errors.numberOf15Amp ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.numberOf15Amp}
                  </div>
                ) : null}
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"numberOf15Amp"}
                  defaultValue={formik.values.numberOf15Amp}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                <h6># of 20 amps 3 phase Outlet/s </h6>
                {formik.touched.numberOf20Amp && formik.errors.numberOf20Amp ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.numberOf20Amp}
                  </div>
                ) : null}
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"numberOf20Amp"}
                  defaultValue={formik.values.numberOf20Amp}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                <h6># of 32 Amp 3 phase Outlet/s </h6>
                {formik.touched.numberOf32Amp && formik.errors.numberOf32Amp ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.numberOf32Amp}
                  </div>
                ) : null}
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  formStateHandler={formik.setFieldValue}
                  formTouchedHandler={formik.setFieldTouched}
                  fieldName={"numberOf32Amp"}
                  defaultValue={formik.values.numberOf32Amp}
                  readOnly={true}
                  style={{ color: 'gray', backgroundColor: '#f5f5f5' }}
                />
              </Col>
            </Form.Group>

            <Form.Text
              style={{ color: "blue", fontSize: "1.2rem" }}
              className="font-weight-bold"
            >
              Note: - Some events disallow catering operators to bring
              generators on site & must use event power.
            </Form.Text>
          </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default PowerRequirements;
