import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer/index.js";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store/index.js";
import StarRating from "../../components/StarRating.js";
import Select from "react-select";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  ListGroup,
  Table,
  Modal,
} from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import config from "../../services/apiConfig.js";
import { isEmpty } from "./utils.js";
import WTTImage from "../../assets/img/EVENT_REPORT.png";
import { Bar, Pie } from "react-chartjs-2";
import { apiPaths } from "../../services/apiPath.js";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../ErrorFallBackScreen/index.js";
import TableData from "../../components/UI/ItemsSoldTable/index.js";
// import WeatherForecastModal from "./WeatherForecastModal";
import Chart from "chart.js/auto";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ShowChartIcon from "@mui/icons-material/ShowChart";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import WeatherForecastModal from "../OperatorPostEventFeedbackScreen/WeatherForecastModal.js";
import AdminNavBar from "../../components/UI/AdminNavBar/index.js";

class AdminSubaccount extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    event_organiser_email: "",
    event_organiser_phone: "",
    truck_manager_email: "",
    truck_manager_phone: "",
    event_organiser_name: "",
    truck_manager_name: "",
    showEventModal: false,
    showTruckModal: false,
    showFeedbackModal: false,
    showAddStakeholderModal: false,
    stakeholder_first_name: "",
    stakeholder_last_name: "",
    stakeholder_email: "",
    stakeholder_phone: "",
    stakeholder_type: "",
    site_area: [],
    events_for_organiser: [],
    site_area_list: [],
    stalls_linked: [],
    events_list: [],
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    phoneNumberValidEventOrganiser: true,
    showWeatherModal: false,
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    salesModal: false,
    billModal: false,
    compareModal: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    forecastData: {},
    payoutDetails: {},
    payoutDetailsArray: [],
    shiftData: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    total_event_sales: 0,
    total_stock_purchased: 0,
    average_spend: 0,
    feedback_data: [],
    ratings_from_organiser: [],
    event_expenses: [],
    total_orders: 0,

    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    peak_food_time_hour: "",
    total_sales_for_peak_hour: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    payoutArray: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };
  handleShowSalesModal = () => {
    this.setState({ salesModal: true });
  };
  handleCloseSalesModal = () => {
    this.setState({ salesModal: false });
  };
  handleShowBillModal = () => {
    this.setState({ billModal: true });
  };
  handleCloseBillModal = () => {
    this.setState({ billModal: false });
  };
  handleShowCompareModal = () => {
    this.setState({ compareModal: true });
  };
  handleCloseCompareModal = () => {
    this.setState({ compareModal: false });
  };
  handleSiteAreaChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ site_area: selectedIds });
  };
  handleEventsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ events_for_organiser: selectedIds });
  };
  handleVendorsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ stalls_linked: selectedIds });
  };

  componentDidMount() {
    this.getSiteAreas();
    this.getEvents();
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleButtonClick = () => {
    this.setState({ showEventModal: true });
  };
  handleFeedbackButtonClick = () => {
    this.setState({ showFeedbackModal: true });
  };

  handleModalClose = () => {
    this.setState({ showEventModal: false });
  };
  handleTruckModalClose = () => {
    this.setState({ showTruckModal: false });
  };
  handleTruckModalShow = () => {
    this.setState({ showTruckModal: true });
  };
  handleFeedbackModalClose = () => {
    this.setState({ showFeedbackModal: false });
  };
  handleFeedbackModalShow = () => {
    this.setState({ showFeedbackModal: true });
  };
  handleAddStakeholderModalClose = () => {
    this.setState({ showAddStakeholderModal: false });
  };
  handleAddStakeholderModalShow = () => {
    this.setState({ showAddStakeholderModal: true });
  };
  handleAddStakeholderButtonClick = () => {
    this.setState({ showAddStakeholderModal: true });
  };
  handleStakeholderTypeChange = (e) => {
    this.setState({ stakeholder_type: e.target.value });
  };

  handleEventOrganiserReminderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_reminder_to_event_organisers_for_feedback",
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          organiser_name: this.state.event_organiser_name,
          email: this.state.event_organiser_email,
          phone: this.state.event_organiser_phone,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Truck Manager");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // Close the modal
  };

  handleAddStakeholderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "create_admin_user",
          stakeholder_first_name: this.state.stakeholder_first_name,
          stakeholder_last_name: this.state.stakeholder_last_name,
          email: this.state.stakeholder_email,
          stakeholder_type: this.state.stakeholder_type,
          events_for_organiser: this.state.events_for_organiser,
          stalls_linked: this.state.stalls_linked,
          site_area: this.state.site_area,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse);
          //this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Stakeholder");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getSiteAreas = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_site_areas",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ site_area_list: dataResponse.vendors }, () => {
            this.setState({ selectedVendorId: selectedVendorId }, () => {
              saveToLocalStorage("selectedVendorId", selectedVendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  getEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events_list: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  handleShow = () => {
    this.setState({ showWeatherModal: true });
  };

  handleClose = () => {
    this.setState({ showWeatherModal: false });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isSuperAdminLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
            // operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            console.log(dataResponse.vendors);
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      peak_food_time_hour,
      total_sales_for_peak_hour,
      total_event_sales,
      total_stock_purchased,
      average_spend,
      feedback_data,
      ratings_from_organiser,
      event_expenses,
      selectedEvent,
      vendors,
      selectedVendorId,
      total_sales,
      payoutArray,
      totalCashOrders,
      totalCardOrders,
      forecastData,
      payoutDetails,
      shiftData,
      payoutDetailsArray,
    } = this.state;
    const siteAreaOptions = this.state.site_area_list.map((site) => ({
      value: site.id,
      label: site.site_name,
    }));
    const eventsOptions = this.state.events_list.map((site) => ({
      value: site.id,
      label: site.name,
    }));
    const vendorsOptions = this.state.vendors.map((site) => ({
      value: site.user_ptr_id,
      label: site.title,
    }));

    const { showWeatherModal } = this.state;
    const Item = styled(Paper)(({ theme }) => ({
      // backgroundColor: "rgba(41, 89, 165, 0.3)",
      backgroundColor: "#2959a54d",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex", // Align items horizontally
      justifyContent: "center", // Center items horizontally
    }));
    let new_total_orders = totalCashOrders + totalCardOrders;
    const averageOrder =
      Number(total_sales).toFixed(2) / Number(new_total_orders).toFixed(2);
    const setNewRating = (data) => {
      this.setState({ selectedRating: data * 2 });
    };
    return (
      <div className="expenses-container" style={{ backgroundColor: "white" }}>
        {!isLoading ? (
          <>
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            ></div>
            <Container
              style={{
                padding: "20px",
                maxWidth: "600px",
                backgroundColor: "#f1f1f1",
              }}
            >
              <h2>Add User Account</h2>
              <Form onSubmit={this.handleAddStakeholderFormSubmit}>
                <Form.Group controlId="formAddStakeholderFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={this.state.stakeholder_first_name}
                    onChange={(e) =>
                      this.setState({ stakeholder_first_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAddStakeholderLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={this.state.stakeholder_last_name}
                    onChange={(e) =>
                      this.setState({ stakeholder_last_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAddStakeholderEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={this.state.stakeholder_email}
                    onChange={(e) =>
                      this.setState({ stakeholder_email: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStakeholderType">
                  <Form.Label>User Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.stakeholder_type}
                    onChange={this.handleStakeholderTypeChange}
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="organiser">Organiser</option>
                    <option value="operator">Operator</option>
                    <option value="accountant">Accountant</option>
                    <option value="stock_admin">Stock Admin</option>
                    <option value="marketing">Marketing</option>
                    <option value="bdm">BDM</option>
                  </Form.Control>
                </Form.Group>
                {this.state.stakeholder_type === "organiser" && (
                  <>
                    <Form.Group controlId="formSiteArea">
                      <Form.Label>Site Area</Form.Label>
                      <Select
                        isMulti
                        value={siteAreaOptions.filter((option) =>
                          this.state.site_area.includes(option.value)
                        )}
                        onChange={this.handleSiteAreaChange}
                        options={siteAreaOptions}
                        placeholder="Select Site Area"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formEvents">
                      <Form.Label>Events</Form.Label>
                      <Select
                        isMulti
                        value={eventsOptions.filter((option) =>
                          this.state.events_for_organiser.includes(option.value)
                        )}
                        onChange={this.handleEventsChange}
                        options={eventsOptions}
                        placeholder="Select Events"
                        required
                      />
                    </Form.Group>
                  </>
                )}
                {this.state.stakeholder_type === "operator" && (
                  <>
                    <Form.Group controlId="formStallsLinked">
                      <Form.Label>Linked Stalls</Form.Label>
                      <Select
                        isMulti
                        value={vendorsOptions.filter((option) =>
                          this.state.stalls_linked.includes(option.value)
                        )}
                        onChange={this.handleVendorsChange}
                        options={vendorsOptions}
                        placeholder="Select Vendors"
                        required
                      />
                    </Form.Group>
                  </>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    backgroundColor: "#2959a5",
                    borderColor: "#2959a5",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "20px",
                    color: "#fff",
                  }}
                >
                  Create Account
                </Button>
              </Form>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminSubaccount, {
    fallback: <ErrorFallbackComponent />,
  })
);
