import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ImageUploader from "react-images-upload";
import moment from "moment";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

function SocialMediaModal({ selectedEventId, onClose }) {
  console.log(selectedEventId);
  const [summary, setSummary] = useState("");
  const [selectedAdminUser, setSelectedAdminUser] = useState("");
  const [adminUsers, setAdminUsers] = useState([]);
  const [mediaImage, setMediaImage] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const getAdminUsers = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_users",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setAdminUsers(response.admin_user || []);
          console.log(response.admin_user);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const postMediaFeedback = async (event) => {
    event.preventDefault();
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "social-media-feedback",
          summary: summary,
          selected_admin_user: selectedAdminUser,
          event_id: selectedEventId,
          media_image: mediaImage,
          time_stamp: moment(new Date()).format("DD MMM YYYY hh:mm A"),
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          alert("Social Media Feedback was added successfully");
          onClose();
        } else {
          alert(
            "Adding Social Media Feedback was unsuccessful. Please try again later."
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert(
          "Adding Social Media Feedback was unsuccessful. Please try again later."
        );
      });
  };

  const handleSummaryChange = (event) => {
    setSummary(event.target.value);
  };

  const handleAdminUserChange = (event) => {
    setSelectedAdminUser(event.target.value);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setMediaImage(pictureDataURLs);
  };

  useEffect(() => {
    getAdminUsers();
  }, []);

  return (
    <Container>
      <div className="form-style-5">
        <form onSubmit={postMediaFeedback}>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleAdminUserChange}>
                <option value="" disabled>
                  Select admin user
                </option>
                {adminUsers &&
                  adminUsers.length > 0 &&
                  adminUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.first_name} {user.last_name}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10, marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Summary:"
                type="text"
                rows="4"
                cols="75"
                value={summary}
                onChange={handleSummaryChange}
              ></textarea>
            </Col>
          </Row>
          <label htmlFor="logoFile">Upload the Media Images:</label>
          <ImageUploader
            withIcon={true}
            buttonText="Choose images"
            onChange={onDrop}
            withPreview={true}
            imgExtension={[".jpg", ".jpeg", ".png"]}
            maxFileSize={5242880}
            label={"Upload media images. Max img size 5mb"}
            buttonStyles={{
              backgroundColor: "#2859a5",
              color: "white",
              fontWeight: "unset",
            }}
          />
          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Sentry.withErrorBoundary(SocialMediaModal, {
  fallback: <ErrorFallbackComponent />,
});
