import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function EventOrganiserVendorsPieChart({
  vendorName,
  vendorData,
  isBool,
}) {
  const itemsLabel = [];
  const itemsData = [];

  const getTableData = () => {
    vendorData.map((data) => {
      itemsLabel.push(data.name);
      itemsData.push(data.price);
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      title: {
        display: true,
        text: "Vendor Item Sales",
        font: {
          size: 20,
          top: 20,
        },
        align: "center",
      },
    },
  };

  const optionsForAll = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      title: {
        display: true,
        text: "Vendor Total Sales",
        font: {
          size: 20,
          top: 20,
        },
        align: "center",
      },
    },
  };

  const data = {
    labels: itemsLabel,
    datasets: [
      {
        data: itemsData,
        backgroundColor: [
          "#00876c",
          "#c6d175",
          "#f4e07f",
          "#f4c266",
          "#f1a255",
          "#ec824d",
          "#e2614d",
          "#d43d51",
          "#439b6d",
          "#6fae6e",
          "#99c06f",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataForAll = {
    labels: vendorName,
    datasets: [
      {
        data: vendorData,
        backgroundColor: [
          "#00876c",
          "#c6d175",
          "#f4e07f",
          "#f4c266",
          "#f1a255",
          "#ec824d",
          "#e2614d",
          "#d43d51",
          "#439b6d",
          "#6fae6e",
          "#99c06f",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {getTableData()}
      {isBool ? (
        <Pie data={dataForAll} options={optionsForAll} />
      ) : (
        <Pie data={data} options={options} />
      )}
    </>
  );
}
