import React, { useState, useEffect } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Backdrop from "@mui/material/Backdrop";
import { Box, Modal, Fade, Typography, Tooltip } from "@mui/material";
import "./style.css";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  FormControl,
  InputGroup,
  Container,
  Row,
  Col,
  Form,
  Card,
  Spinner,
  Table,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import CardMedia from "@mui/material/CardMedia";
import "react-phone-input-2/lib/style.css";
import { AWS_URL } from "../../../assets/Constants";
import { ItemTypes } from "../constant/Constant";
import { useDrop } from "react-dnd";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import RegModal from "./RegModal";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReadMoreReact from "read-more-react";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../../store";
import wedding from "../../../assets/img/Wedding.png";
import birthday from "../../../assets/img/Birthday_Party.png";
import corporate from "../../../assets/img/Corporate.png";
import engagement from "../../../assets/img/Engagement_Party.png";
import eofy from "../../../assets/img/EOFY_Funtion.png";
import market from "../../../assets/img/market.png";
import xmas from "../../../assets/img/Xmas_Party.png";
import sports from "../../../assets/img/sports.png";
import { set } from "date-fns";
import * as Sentry from "@sentry/react";
const applySuccess = () => {
  alert("Catering Application submitted successfully!");
  localStorage.removeItem("vendors_arr");
};

const style = {
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "550px",
  height: "550px",
  overflow: "auto",
  padding: "20px",
};

const ComfirmationComponent = ({
  openNotice,
  setopenNotice,
  vendorList,
  event,
  selectedPack,
  setVendorList,
  isSingleDayEvent,
}) => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const handleConfirm = () => {
    // Handle the confirm action here
    setopenNotice(false);
  };
  const handleCancel = () => {
    setopenNotice(false);
  };
  const handleAgreeTerms = (event) => {
    setAgreeTerms(event.target.checked);
  };
  const termsAndConditionsLink =
    "http://amfvg.com.au/terms-and-conditions-of-use/";
  return (
    <Dialog
      open={openNotice}
      onClose={() => setopenNotice(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to add the food truck catering menu to this
          Catering Job?
        </DialogContentText>
        {JSON.parse(getFromLocalStorage("vendorsShowArr")) &&
          JSON.parse(getFromLocalStorage("vendorsShowArr")).map(
            (item, index) => (
              <div key={index}>
                <h4>{item.vendor_name}</h4>
                <ul>
                  {item.pack_name_description.map((description, index) => (
                    <li key={index}>{description}</li>
                  ))}
                </ul>
              </div>
            )
          )}

        <FormControlLabel
          control={
            <Checkbox checked={agreeTerms} onChange={handleAgreeTerms} />
          }
          label={
            <span>
              Do you agree to the{" "}
              <a
                href={termsAndConditionsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
              ?
            </span>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            var vendors_arr = JSON.parse(getFromLocalStorage("vendors_arr"));
            let emptyPackageFound = false;
            for (const obj of vendors_arr) {
              if (obj.package_ids.length === 0) {
                emptyPackageFound = true;
                break;
              }
            }
            if (emptyPackageFound) {
              alert("Please select packages for all the trucks");
            } else if (vendors_arr.length === 0) {
              alert("Please Select Vendors for the catering event");
            } else {
              var url = config.BASE_URI + apiPaths.operatorData;
              let myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              const vendorId = [];
              vendorList.map((item) => {
                vendorId.push(item.id);
              });

              const raw = JSON.stringify({
                payload: {
                  body: {
                    query_type: "place_application_for_catering_pac",
                    booking_request_id: event.eventDetails.id,
                    operator_id: JSON.parse(getFromLocalStorage("Operator"))[0]
                      .id,
                    vendors_arr: JSON.parse(getFromLocalStorage("vendors_arr")),
                  },
                },
              });
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };
              try {
                fetch(url, requestOptions).then((response) => {
                  if (response.status == 200) {
                    applySuccess();
                    setopenNotice(false);
                  }
                });
              } catch (error) {
                Sentry.captureException(error);
                console.error(error);
              }
            }
          }}
          disabled={!agreeTerms}
        >
          Confirm
        </Button>
        <Button onClick={() => setopenNotice(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
const ApplyModal = ({ open, onClose, vendors = [], handleSelectVendor }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{"Vendors"}</DialogTitle>
    <DialogContent>
      {vendors.length > 0 ? (
        vendors.map((vendor, index) => (
          <Paper key={index} elevation={12} style={{ margin: 10, padding: 10 }}>
            <div>{vendor.title}</div>
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => {
                handleSelectVendor(vendor);
                onClose();
              }}
            >
              Select
            </Button>
          </Paper>
        ))
      ) : (
        <div>No vendors available</div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);
const CateringCardMobile = (props) => {
  const { event, onDrop, keyIndex, removeVendor, vendors } = props;
  const url = config.devUrl + apiPaths.operatorData;
  const [vendorList, setVendorList] = useState([]);
  const [openNotice, setopenNotice] = useState(false);
  const [droppedVendor, setDroppedVendor] = useState(12);
  const [cateringPack, setCateringPack] = useState([]);
  const [selectedPack, setSelectedPack] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPackDescription, setselectedPackDescription] = useState([]);
  const [vendorr, setVendorr] = useState("");
  const [packageNamess, setPackageNamess] = useState("");
  const [packageId, setPackageId] = useState("");
  const [vendors_arr, setVendors_arr] = useState([]);
  const [vendorsShowArr, setVendorsShowArr] = useState([]);
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");
  const handleCardClose = () => {
    const newVendor = { vendor_id: packageId, package_ids: selectedPackages };
    const newDetails = {
      vendor_name: title,
      pack_name_description: selectedPackDescription,
    };
    setVendors_arr((prevVendors) => [...prevVendors, newVendor]);
    setVendorsShowArr((prevVendors) => [...prevVendors, newDetails]);
  };

  useEffect(() => {
    saveToLocalStorage("vendors_arr", JSON.stringify(vendors_arr));
    saveToLocalStorage("vendorsShowArr", JSON.stringify(vendorsShowArr));
    setShowDetails(false);
    setSelectedPackages([]);
    setselectedPackDescription([]);
  }, [vendors_arr]);
  const getCateringPack = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_catering_packages",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          setCateringPack(dataResponse.menu_details);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  useEffect(() => {
    if (droppedVendor) {
      const vendor1 = cateringPack.filter(
        (stalls) => stalls.stalls__id == droppedVendor
      );
      if (vendor1) {
        setSelectedPack(vendor1.meals);
      }
    }
    const vendor = cateringPack.find(
      (item) => item.stalls__id == droppedVendor
    );
    let packageNamess = vendor
      ? vendor.meals.map((meal) => {
          return {
            package_name: meal.package_name,
            cuisine: meal.cuisine,
            id: meal.id,
            package_description: meal.package_description,
            package_people: meal.package_people,
            package_price: meal.package_price,
          };
        })
      : [];
    setVendorr(vendorr);
    setPackageNamess(packageNamess);
    setPackageId(vendor && vendor.stalls__id);
    setTitle(vendor && vendor.stalls__title);
  }, [droppedVendor]);
  useEffect(() => {
    setVendorList(event.vendorCatering);
  }, [event]);
  useEffect(() => {
    getCateringPack();
  }, []);
  const handleCanDrop = (item) => {
    if (vendorList && vendorList.some((vendor) => vendor.id === item.id)) {
      return false;
    } else {
      return true;
    }
  };
  function isDropSuccessful(monitor) {
    const dropResult = monitor.getDropResult();
    setTimeout(() => {
      setDroppedVendor(getFromLocalStorage("droppedVendor"));
    }, 350);
    setTimeout(() => {
      setShowDetails(true);
    }, 1000);
    return dropResult && dropResult.success;
  }
  function handleSelectPackage(packageName) {
    const selectedPackage = packageName.id;
    const pack = [];
    const package_id_arr = [];
    package_id_arr.push(packageName.id);
    pack.push(packageName.package_name);
    setSelectedPackages([...selectedPackages, packageName.package_name]);
    setselectedPackDescription([
      ...selectedPackDescription,
      packageName.package_description,
    ]);
  }
  const [{ isOver, canDrop, dropSuccess }, drop] = useDrop({
    accept: "stallItem",
    canDrop: (item) => handleCanDrop(item), //TODO: It should check if the truck has applied to this event
    drop: (item, monitor) => {
      onDrop(item);
      const isSuccessful = isDropSuccessful(monitor);
      if (isSuccessful) {
        setShowDetails(true);
        setDroppedVendor(getFromLocalStorage("droppedVendor"));
      }
      return { success: isSuccessful };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropSuccess: monitor.getDropResult()?.success,
    }),
  });

  const handlePackSelect = (id, description) => {
    if (selectedPackages.includes(id)) {
      setSelectedPackages(selectedPackages.filter((d) => d !== id));
      setselectedPackDescription(
        selectedPackDescription.filter((d) => d !== description)
      );
    } else {
      setSelectedPackages([...selectedPackages, id]);
      setselectedPackDescription([...selectedPackDescription, description]);
    }
  };
  const handleDeleteVendor = (vendorCatering) => {
    const updatedVendorsArr = vendors_arr.filter(
      (vendor) => vendor.vendor_id !== vendorCatering.id
    );
    const updatedVendorsArrD = vendorsShowArr.filter(
      (vendor) => vendor.vendor_name !== vendorCatering.title
    );
    setVendors_arr(updatedVendorsArr);
    setVendorsShowArr(updatedVendorsArrD);
  };

  return (
    <>
      <div>
        <Card
          style={{
            display: "flex",
            justifyItems: "center",
            backgroundColor: "#f3f1ef",
          }}
          ref={drop}
        >
          <Card.Body
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>{event.eventDetails.customer_event_type}</h3>
            {event.eventDetails.customer_event_type === "Birthday Party" ? (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={birthday}
              ></img>
            ) : event.eventDetails.customer_event_type == "Engagement Party" ||
              event.eventDetails.customer_event_type == "Wedding" ? (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={wedding}
              ></img>
            ) : event.eventDetails.customer_event_type === "Sporting Event" ? (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={sports}
              ></img>
            ) : event.eventDetails.customer_event_type ==
              "Corporate Function" ? (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={corporate}
              ></img>
            ) : event.eventDetails.customer_event_type ==
              "Christmas Function" ? (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={xmas}
              ></img>
            ) : (
              <img
                style={{ marginTop: 10, height: 250 }}
                className="d-block w-100"
                src={eofy}
              ></img>
            )}
            <strong>Number Of People: </strong>
            <h5>{event.eventDetails.number_of_guests}</h5>
            <strong>Date: </strong>
            <h5>
              {event.eventDetails.start_date +
                "-" +
                event.eventDetails.end_date}
            </h5>
            <strong>Time: </strong>
            <h5>
              {event.eventDetails.start_time +
                "-" +
                event.eventDetails.end_time}
            </h5>
            <strong>Location: </strong>
            <h5>
              {event.eventDetails.location.includes(", Australia") &&
              event.eventDetails.location.split(", Australia")[0].includes(",")
                ? event.eventDetails.location.split(",")[1]
                : event.eventDetails.location}
            </h5>
            <strong>Cusine Type: </strong>
            <h5>{event.eventDetails.cuisine}</h5>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showDetails}
              onClose={handleCardClose}
            >
              <Box sx={style}>
                {packageNamess &&
                  packageNamess.map((packageName) => (
                    <>
                      <Tooltip
                        title={`Details: ${packageName.package_description}`}
                      >
                        <button
                          onClick={() =>
                            handlePackSelect(
                              packageName.id,
                              packageName.package_description
                            )
                          }
                          className={
                            selectedPackages.includes(packageName.id)
                              ? "green butt"
                              : "butt"
                          }
                        >
                          {packageName.package_name +
                            " for " +
                            packageName.package_people +
                            " people - $ " +
                            packageName.package_price}
                        </button>
                      </Tooltip>
                    </>
                  ))}
                <Button
                  color="primary"
                  variant="contained"
                  style={{ margin: 10 }}
                  onClick={handleCardClose}
                >
                  Save
                </Button>
              </Box>
            </Modal>
          </Card.Body>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setOpenModal(true)}
            >
              Apply
            </Button>
            <ApplyModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              vendors={vendors}
              handleSelectVendor={(vendor) => {
                onDrop(vendor);
                setShowDetails(true);
                setDroppedVendor(getFromLocalStorage("droppedVendor"));
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "auto",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              textAlign: "center",
              overflowX: "scroll",
            }}
          >
            {vendorList &&
              vendorList.length > 0 &&
              vendorList.map((vendor, index) => {
                return (
                  <div>
                    <Paper elevation={12} key={index} style={{ margin: 10 }}>
                      <CardMedia
                        onClick={() => setShowModal(true)}
                        style={{
                          width: 100,
                          height: 80,
                          overflow: "hidden",
                        }}
                        image={vendor.logo}
                        title="vendor_logo"
                      />
                      <RegModal
                        show={showModal}
                        handleClose={handleClose}
                        itemDetail={vendor}
                        viewOnly={true}
                      />
                      <DeleteForeverOutlinedIcon
                        onClick={() => {
                          handleDeleteVendor(vendor);
                          removeVendor(keyIndex, vendor);
                        }}
                        style={{
                          position: "relative",
                          top: "0px",
                          right: "0px",
                        }}
                      />
                    </Paper>
                  </div>
                );
              })}
          </div>
          <ComfirmationComponent
            openNotice={openNotice}
            setopenNotice={setopenNotice}
            vendorList={vendorList}
            event={event}
            setVendorList={setVendorList}
            url={url}
            selectedPack={selectedPackages}
          />
        </Card>
        {vendorList.length != 0 && (
          <div className="submit-button">
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setopenNotice(true)}
            >
              Submit Application
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default CateringCardMobile;
