import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SortIcon from "@mui/icons-material/Sort";

class MenuAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupedItems: this.groupItems(props.items),
      sortOrder: {}, // Track sort order for each group
    };
  }

  predefinedCategories = [
    "Coffee",
    "Pepsi",
    "Burger",
    "Chocolate",
    "Chips",
    "Water",
    "Sunkist",
    "Solo",
    "Croissant",
    "Tea",
    "Lemonade",
  ];

  groupItems(items) {
    const groups = {};

    items.forEach((item) => {
      const key = this.getKeyword(item.name);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    });

    // Sort items within each group by price in descending order
    Object.keys(groups).forEach((key) => {
      groups[key].sort((a, b) => b.price - a.price);
    });

    return groups;
  }

  getKeyword(name) {
    const normalizedName = name.toLowerCase();
    const foundCategory = this.predefinedCategories.find((category) =>
      normalizedName.includes(category.toLowerCase())
    );

    // Check if the found category is a number between 1 and 20
    if (
      foundCategory &&
      /^\d+$/.test(foundCategory) &&
      parseInt(foundCategory) >= 1 &&
      parseInt(foundCategory) <= 20
    ) {
      return "Other";
    }

    return foundCategory || "Other";
  }

  toggleSortOrder = (key) => {
    this.setState((prevState) => {
      const newOrder = prevState.sortOrder[key] === "asc" ? "desc" : "asc";
      const sortedItems = prevState.groupedItems[key].sort((a, b) =>
        newOrder === "asc" ? a.price - b.price : b.price - a.price
      );

      return {
        groupedItems: {
          ...prevState.groupedItems,
          [key]: sortedItems,
        },
        sortOrder: {
          ...prevState.sortOrder,
          [key]: newOrder,
        },
      };
    });
  };

  renderGroup(key, items) {
    if (items.length === 0) {
      return null; // Do not render this group if it's empty
    }
    const totalPrice = items.reduce((acc, item) => acc + item.price, 0);
    const totalCount = items.reduce((acc, item) => acc + item.count, 0);
    const sortOrder = this.state.sortOrder[key] || "desc";

    return (
      <Accordion key={key} style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ backgroundColor: "#2959a5", color: "white" }}
        >
          <Typography>
            {key} - ${totalPrice.toFixed(2)} - {totalCount} Nos.
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              this.toggleSortOrder(key);
            }}
          >
            <SortIcon />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table striped bordered hover>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="right">{item.count}</TableCell>
                    <TableCell align="right">
                      ${item.price.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const { groupedItems } = this.state;

    // Sort the groups by total price, keep "Other" at the bottom
    const sortedGroups = Object.entries(groupedItems)
      .map(([key, items]) => {
        const totalPrice = items.reduce((acc, item) => acc + item.price, 0);
        return { key, items, totalPrice };
      })
      .sort((a, b) => {
        if (a.key === "Other") return 1;
        if (b.key === "Other") return -1;
        return b.totalPrice - a.totalPrice;
      });

    return (
      <div>
        {sortedGroups.map(({ key, items }) => this.renderGroup(key, items))}
      </div>
    );
  }
}

export default MenuAccordion;
