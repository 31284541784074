import React, { Component } from "react";
import NavBar from "../../components/UI/NavBar/index";
import MapComponent from "../../components/MapComponent";
import FloatingPanel from "../../components/UI/FloatingPanel";
import ExploreComponent from "../../components/ExploreComponent";
import CartComp from "../../components/CartComponent";
import FloatingFilterButton from "../../components/UI/FloatingFilterButton";
import {
  store,
  getFromLocalStorage,
  deleteFromLocalStorage,
} from "../../store/index";
import {
  getAllVendors,
  getAllFilters,
  getAllLocations,
  changeTab,
  logout,
} from "../../actions/index";
import { applyToken } from "../../services/api";

class DashboardScreen extends Component {
  componentWillMount() {
    deleteFromLocalStorage("location", new Array());
  }

  componentDidMount() {
    const { appReducer } = store.getState();
    const { vendorData, filters, location } = appReducer;
    const { dispatch } = this.props.props;
    applyToken(getFromLocalStorage("token"));
    dispatch(getAllVendors());
    if (vendorData.length == 0) {
      dispatch(getAllVendors());
    }
    dispatch(getAllFilters());

    if (Object.keys(filters).length === 0 && filters.constructor === Object) {
      dispatch(getAllFilters());
    }
  }
  render() {
    const {
      selectedTab,
      dispatch,
      filters,
      selectedCategory,
      loggedIn,
      phone,
      smsSent,
      loginModalToggle,
    } = this.props.props;
    const { appReducer } = store.getState();
    const { vendorData, location, products } = appReducer;
    let displayComponent;
    if (selectedTab === "map") {
      displayComponent = (
        <div>
          <FloatingPanel></FloatingPanel>
          <MapComponent
            vendorData={vendorData}
            dispatch={dispatch}
          ></MapComponent>
        </div>
      );
    } else if (selectedTab === "explore") {
      displayComponent = (
        <div>
          <ExploreComponent
            selectedCategory={selectedCategory}
            dispatch={dispatch}
          ></ExploreComponent>
        </div>
      );
    } else if (selectedTab === "cart") {
      displayComponent = (
        <div>
          <CartComp
            selectedCategory={selectedCategory}
            dispatch={dispatch}
          ></CartComp>
        </div>
      );
    }
    return (
      <div>
        <NavBar
          selectedTab={selectedTab}
          expand="lg"
          dispatch={dispatch}
          loggedIn={loggedIn}
          phone={phone}
          smsSent={smsSent}
          loginModalToggle={loginModalToggle}
          kitchacoLocation={location}
          cartProducts={products}
        ></NavBar>
        {/* <FloatingFilterButton></FloatingFilterButton> */}
        {displayComponent}
      </div>
    );
  }
}

export default DashboardScreen;
