import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

const FileUploader = (props) => {
  const {
    allowMultiple,
    formStateHandler,
    formTouchedHandler,
    fieldName,
    allowFileTypeValidation,
    acceptedFileTypes,
    defaultValue,
  } = props;
  const [files, setFiles] = useState(defaultValue);
  return (
    <div onClick={() => formTouchedHandler(fieldName, true)}>
      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={allowMultiple}
        onupdatefiles={(fileItems) => {
          formStateHandler(
            fieldName,
            fileItems.map((fileItem) => fileItem.file)
          );
          setFiles(fileItems.map((fileItem) => fileItem.file));
        }}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        allowFileTypeValidation={allowFileTypeValidation}
        acceptedFileTypes={acceptedFileTypes}
      />
    </div>
  );
};

export default FileUploader;
